export const State_Territory = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "American Samoa", value: "AS" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Lowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "CM" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Trust Territories", value: "TT" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];


export const productDetailsPointsData={
  bpa_free:"Bpa Free",
  dairy_free:"Dairy Free",
  gluten_free:"Gluten Free",
  soy_free:"Soy Free",
  carrageenan_free:"Carrageenan Free",
  non_gmo:"Non Gmo",
  vegan:"Vegan",
  kitnityot:"Kitnityot",
  kosherforpassover:"Kosher For Passover",
  natural:"Natural",
  naturalprocessing:"Natural Processing",
  noartificialing:"Noartificialing",
  noeggs:"No Eggs",
  nomsg:"No Msg",
  nosugaradded:"No Sugaradded",
  paleofriendly:"Paleo Friendly",
  pareve:"Pareve",
  pasyisroel:"Pas Yisroel",
  sulfitefree:"Sulfite Free",
  yoshon:"Yoshon",
  cholovyisroel:"Cholov Yisroel",
  organic:"Organic"
}


export const cateringData=[
  {
    "id": 1,
    "name": "Vegetable Spring Rolls",
    "description": "Freshly prepared vegetable spring rolls served with sweet chili sauce",
    "price": 12.99,
    "category": "Appetizers",
    "dietaryLabels": ["Vegetarian"],
    "presentation": "Vegetarian-friendly",
    "recommendedServingSize": "6 rolls per serving",
    "customizationOptions": ["Choice of sauce (sweet chili, soy-ginger)"],
    "servingEquipment": "Small plates",
    "storageInstructions": "Refrigerate in an airtight container",
    "servingSuggestions": "Pair with Thai-style salad",
    "rating": "4.5/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 2,
    "name": "Caprese Salad",
    "description": "Fresh mozzarella, ripe tomatoes, and basil drizzled with balsamic glaze",
    "price": 9.99,
    "category": "Appetizers",
    "dietaryLabels": ["Vegetarian", "Gluten-free"],
    "presentation": "Vegetarian-friendly, Gluten-free",
    "recommendedServingSize": "Serves 2-4",
    "customizationOptions": ["Add prosciutto for an additional charge"],
    "servingEquipment": "Salad plates",
    "storageInstructions": "Keep refrigerated until serving",
    "servingSuggestions": "Serve as a light appetizer or alongside grilled meats",
    "rating": "4.7/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 3,
    "name": "Mediterranean Mezze Platter",
    "description": "A colorful assortment of hummus, tzatziki, tabbouleh, olives, and pita bread",
    "price": 24.99,
    "category": "Appetizers",
    "dietaryLabels": ["Vegetarian", "Vegan"],
    "presentation": "Vegetarian-friendly, Vegan-friendly",
    "recommendedServingSize": "Ideal for sharing among 4-6 guests",
    "customizationOptions": ["Add falafel for an additional charge"],
    "servingEquipment": "Platter and small bowls",
    "storageInstructions": "Refrigerate dips; keep pita bread covered to prevent drying",
    "servingSuggestions": "Pair with a selection of fresh vegetables and grilled halloumi",
    "rating": "4.8/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 4,
    "name": "Beef Tenderloin Medallions",
    "description": "Grilled beef tenderloin medallions seasoned with garlic butter and fresh herbs",
    "price": 32.99,
    "category": "Main Course",
    "dietaryLabels": ["Gluten-free"],
    "presentation": "Gluten-free",
    "recommendedServingSize": "4-6 medallions per serving",
    "customizationOptions": ["Choice of side dishes (roasted potatoes, steamed vegetables)"],
    "servingEquipment": "Dinner plates and utensils",
    "storageInstructions": "Refrigerate any leftovers promptly",
    "servingSuggestions": "Serve with a red wine reduction sauce",
    "rating": "4.9/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 5,
    "name": "Chocolate Fountain",
    "description": "A cascading fountain of rich melted chocolate served with assorted fruits, marshmallows, and cookies for dipping",
    "price": 29.99,
    "category": "Desserts",
    "dietaryLabels": [],
    "presentation": "Indulgent dessert experience",
    "recommendedServingSize": "Ideal for events with 20-30 guests",
    "customizationOptions": ["Add nuts or sprinkles for an additional charge"],
    "servingEquipment": "Fountain, skewers, and serving platters",
    "storageInstructions": "Keep the chocolate fountain running for the duration of the event",
    "servingSuggestions": "Offer a variety of dipping options for guests to enjoy",
    "rating": "5/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 6,
    "name": "Chicken Satay",
    "description": "Grilled chicken skewers marinated in a blend of coconut milk and Thai spices",
    "price": 16.99,
    "category": "Appetizers",
    "dietaryLabels": ["Gluten-free"],
    "presentation": "Gluten-free",
    "recommendedServingSize": "2 skewers per serving",
    "customizationOptions": ["Choice of peanut or sweet chili sauce"],
    "servingEquipment": "Skewers and dipping bowls",
    "storageInstructions": "Refrigerate any leftover skewers",
    "servingSuggestions": "Serve alongside jasmine rice and cucumber salad",
    "rating": "4.6/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 7,
    "name": "Seafood Paella",
    "description": "Traditional Spanish rice dish cooked with a variety of seafood and flavored with saffron and paprika",
    "price": 36.99,
    "category": "Main Course",
    "dietaryLabels": ["Shellfish-free"],
    "presentation": "Shellfish-free",
    "recommendedServingSize": "Generous servings for 4-6 guests",
    "customizationOptions": ["Add chorizo for an additional charge"],
    "servingEquipment": "Large paella pan",
    "storageInstructions": "Refrigerate leftovers and reheat gently before serving",
    "servingSuggestions": "Garnish with lemon wedges and fresh parsley",
    "rating": "4.7/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 8,
    "name": "Vegetable Stir-Fry",
    "description": "Freshly stir-fried vegetables tossed in a savory soy-ginger sauce",
    "price": 14.99,
    "category": "Main Course",
    "dietaryLabels": ["Vegetarian", "Vegan"],
    "presentation": "Vegetarian-friendly, Vegan-friendly",
    "recommendedServingSize": "Serves 2-3",
    "customizationOptions": ["Add tofu or tempeh for protein"],
    "servingEquipment": "Large wok or skillet",
    "storageInstructions": "Refrigerate any leftovers and reheat before serving",
    "servingSuggestions": "Serve over steamed rice or noodles",
    "rating": "4.5/5",
    "image": "https://th.bing.com/th/id/OIP.velkQWEiJSPu7rmUOuYbdwAAAA?rs=1&pid=ImgDetMain"
  },
  {
    "id": 9,
    "name": "Cheese Platter",
    "description": "An assortment of gourmet cheeses served with crackers, nuts, and dried fruits",
    "price": 19.99,
    "category": "Appetizers",
    "dietaryLabels": ["Vegetarian"],
    "presentation": "Vegetarian-friendly",
    "recommendedServingSize": "Perfect for sharing among 4-6 guests",
    "customizationOptions": ["Add charcuterie for an additional charge"],
    "servingEquipment": "Cheese board and cheese knives",
    "storageInstructions": "Keep cheeses refrigerated until serving",
    "servingSuggestions": "Pair with a selection of wines",
    "rating": "4.8/5",
    "image": "cheese_platter.jpg"
  },
  {
    "id": 10,
    "name": "Tiramisu",
    "description": "Classic Italian dessert made with layers of espresso-soaked ladyfingers and mascarpone cheese, dusted with cocoa powder",
    "price": 8.99,
    "category": "Desserts",
    "dietaryLabels": [],
    "presentation": "Decadent dessert choice",
    "recommendedServingSize": "Individual servings",
    "customizationOptions": ["Add a shot of liqueur for an extra kick"],
    "servingEquipment": "Dessert plates and forks",
    "storageInstructions": "Refrigerate any leftovers",
    "servingSuggestions": "Serve chilled with a dusting of cocoa powder",
    "rating": "4.9/5",
    "image": "tiramisu.jpg"
  }
]
