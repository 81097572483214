import React, { Component } from "react";
import { Dialog, Modal, Typography } from "@mui/material";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CategoryCard from "../../HomePage/CategoryCard";
import CustomPreloader from "../Loader/CustomPreloader";
import {
  PaymentCardGradient,
  ScrollColorTypography,
  SuggestedCartSection,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../UiDesign";
import CategorycardNoimg from "./CategorycardNoimg";

class SubCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_inner: false,
      open_subDetails: false,
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      SubSubCategoryName: "",
      store_Data:JSON.parse(localStorage.getItem("Store_address")),
      subCategoryTrigger: true,
    };
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    this.handleSubInnerCloseDialog = this.handleSubInnerCloseDialog.bind(this);
    this.handleInnerInnerCloseDialog =
      this.handleInnerInnerCloseDialog.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({ SubCategoryDetails: nextProps.SubCategoryDetails,SubSubCategoryDetails: nextProps.SubSubCategoryDetails});
    if (this.props.SubCategoryDetails !== nextProps.SubCategoryDetails) {
      this.setState({ SubCategoryDetails: nextProps.SubCategoryDetails });
    }
    if (this.props.SubSubCategoryDetails !== nextProps.SubSubCategoryDetails) {
      this.setState({
        SubSubCategoryDetails: nextProps.SubSubCategoryDetails.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      });
    }
    // if(this.props.open_subDetails !== nextProps.open_subDetails) {
    //   this.setState({open_subDetails: nextProps.open_subDetails})
    // }
    if (this.props.SubSubCategoryName !== nextProps.SubSubCategoryName) {
      this.setState({ SubSubCategoryName: nextProps.SubSubCategoryName });
    }

    // if(this.props.open_inner !== nextProps.open_inner) {
    //   this.setState({open_inner: nextProps.open_inner})
    // }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false, open_subDetails: false });
  }
  bindnewgorupeddata = (category, index) => {
    
    let mycategory = [...category];
    let tempdata = structuredClone(mycategory[index]);
    mycategory.splice(index, 1);
    mycategory.splice(0, 0, tempdata);
    
    return mycategory;
  };
  bindCategorygroupdata = (urlcategoryid) => {
    let data = this.props.tree_data;
    let categorydata;
    if (Array.isArray(data)) {
      categorydata = [...data];
      categorydata.sort((a, b) => a.name.localeCompare(b.name));
    } else categorydata = structuredClone(data);
    let parentdata;
    let newdata;
    for (let i = 0; i < data?.length; i++) {
      if (data[i].id === +urlcategoryid) {
        parentdata = data[i];
        newdata = [data[i]];
        break;
      }
      if (data[i].has_child === true && data[i]?.childItem?.length > 0) {
        for (let k = 0; k < data[i].childItem.length; k++) {
          if (data[i].childItem[k].id === +urlcategoryid) {
            parentdata = structuredClone(data[i]);
            
            newdata = this.bindnewgorupeddata(parentdata.childItem, k);
            
            // newdata.push(data[i]);
            break;
          }

          if (
            data[i].childItem[k].has_child === true &&
            data[i]?.childItem[k]?.childItem?.length > 0
          ) {
            for (let j = 0; j < data[i].childItem[k].childItem.length; j++) {
              if (data[i].childItem[k].childItem[j].id === +urlcategoryid) {
                parentdata = structuredClone(data[i].childItem[k]);
                newdata = this.bindnewgorupeddata(parentdata.childItem, j);
                
                // newdata.push(parentdata);
                break;
              }
            }
          }
        }
      }
    }
    return { category: newdata, parentdata };
  };
  handleSubInnerOpenDialog(
    category_id,
    subsubcategory_name,
    subsubCategory,
    has_child,
    level,
    parent_id,
    data
  ) {
    if (has_child === true) {
      this.props.FetchSubCategoryList(category_id, level, parent_id);

      this.setState({
        open_subDetails: true,
        SubSubCategoryName: subsubcategory_name,
        subCategoryTrigger: true,
      });
    } else {
      let categorygroup = this.bindCategorygroupdata(category_id);
      this.props.parentIds.push(category_id + "" + level);
      this.props.parent_onlyid.push(category_id);
      this.props.parent_level.push(parent_id);
      if (this.props.history.history) {
        if (this.props.pageName && this.props.pageName === "listing_page") {
          this.redirectToListingPageFunction(
            category_id,
            parent_id,
            categorygroup,
            data
          );
        } else {
          this.redirectToPathListingPage(
            category_id,
            parent_id,
            categorygroup,
            data
          );
        }
      } else {
        this.props.history.push({
          pathname: "/listing/" + category_id,
          state: {
            category_id: category_id,
            parent_id: parent_id,
            parentIds: this.props.parentIds,
            parent_onlyid: this.props.parent_onlyid,
            parent_level: this.props.parent_level,
            CategoryDetails: this.props.CategoryDetails,
            selectedgroupcat: categorygroup,
            openallCategory: false,
            customCategoryMsg: data?.category_message,
          },
        });
      }
    }
  }
  handleSubInnerCloseDialog() {
    let parentIds = this.props.parentIds;
    let parent_onlyid = this.props.parent_onlyid;
    let SubCategoryDetails = this.state.SubCategoryDetails;
    for (let i = 0; i < parent_onlyid.length; i++) {
      if (SubCategoryDetails && SubCategoryDetails.length > 0) {
        if (parent_onlyid[i] === SubCategoryDetails[0].parent_id) {
          this.props.FetchSubCategoryList(parent_onlyid[i], 0);
        } else if (SubCategoryDetails[0].has_child === true) {
          this.props.FetchSubCategoryList(SubCategoryDetails[0].parent_id, 0);
        } else {
          this.props.FetchSubCategoryList(parent_onlyid[0], 0);
        }
      } else {
        console.log("else");
        if (parent_onlyid.length === 3) {
          this.props.FetchSubCategoryList(parent_onlyid[1], 0);
        } else {
          this.props.FetchSubCategoryList(parent_onlyid[0], 0);
        }
      }
    }
    this.setState({
      open_subDetails: false,
      SubSubCategoryName: "",
      subCategoryTrigger: false,
      open_inner: false,
    });
  }
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }

  componentDidMount() {
    // this.FetchCategoryTreeView();
    // if (this.props.open_inner === true) {
    //   this.setState({
    //     subCategoryTrigger: false
    //   });
    // } else {
    //    this.setState({
    //     subCategoryTrigger: true
    //   });
    // }
    // console.log('open_inner_sub_cat',this.props.open_inner);
  }

  handleInnerInnerCloseDialog() {
    this.setState({ open_subDetails: false });
    // this.props.handleInnerCloseDialog();
    this.props.handleOpenDialog();
  }

  redirectToListingPageFunction = (
    category_id,
    parent_id,
    categorygroup,
    data
  ) => {
    // this.setState({ open_subDetails: false });
    this.props.history.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: parent_id,
        parentIds: this.props.parentIds,
        parent_onlyid: this.props.parent_onlyid,
        parent_level: this.props.parent_level,
        CategoryDetails: this.props.CategoryDetails,
        selectedgroupcat: categorygroup,
        customCategoryMsg: data?.category_message,
      },
    });
    this.props.redirectingListingPage(category_id);
    this.handleInnerInnerCloseDialog();
    this.props.handleCloseDialog();
  };

  redirectToPathListingPage = (category_id, parent_id, categorygroup, data) => {
    
    this.props.history.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: parent_id,
        parentIds: this.props.parentIds,
        parent_onlyid: this.props.parent_onlyid,
        parent_level: this.props.parent_level,
        CategoryDetails: this.props.CategoryDetails,
        selectedgroupcat: categorygroup,
        openallCategory: false,
        customCategoryMsg: data?.category_message,
      },
    });
  };

  redirectUrl = (category_id, parent_id, level, data) => {
    console.log("hitt cat");
    let categorygroup = this.bindCategorygroupdata(category_id);

    this.props.parentIds.push(category_id + "" + level);
    this.props.parent_onlyid.push(category_id);
    this.props.parent_level.push(parent_id);
    this.setState({ open_subDetails: false });
    this.props.handleCloseDialog();
    if (this.props && this.props.history && this.props.history.history) {
      if (this.props.pageName && this.props.pageName === "listing_page") {
        this.redirectToListingPageFunction(
          category_id,
          parent_id,
          categorygroup,
          data
        );
      } else {
        this.redirectToPathListingPage(
          category_id,
          parent_id,
          categorygroup,
          data
        );
      }
    } else {
      this.props.history.push({
        pathname: "/listing/" + category_id,
        state: {
          category_id: category_id,
          parent_id: parent_id,
          parentIds: this.props.parentIds,
          parent_onlyid: this.props.parent_onlyid,
          parent_level: this.props.parent_level,
          categoryviewdata: categorygroup.groupedData,
          selectedgroupcat: categorygroup.ungroupedData,
          openallCategory: false,
          customCategoryMsg: data?.category_message,
        },
      });
    }
  };

  addDefaultSubCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  render() {
    const {
      // SubCategoryDetails,
      SubcategoryName,
      subCategoryLoader,
    } = this.props;
    const {
      SubSubCategoryName,
      SubCategoryDetails,
      SubSubCategoryDetails,
      store_Data,
    } = this.state;

    return (window.innerWidth <= 900 ?
      <Modal
      aria-labelledby="form-dialog-title"
      open={this.props.open_inner}
      onClose={this.props.handleInnerCloseDialog}
      // className="modal_main"
    >
      <ScrollColorTypography component={"div"} className="dialog_wrapper">
        <div className="menu_head">
          <div className="menu_title">
            <div className="specified_details">
              <ul>
                <TypographyTextColor
                  component={"li"}
                  Color="other"
                  onClick={this.props.handleOpenDialog}
                >
                  All
                </TypographyTextColor>
                {/* <li onClick={this.props.handleOpenDialog}>All</li> */}
                <TypographyTextColor
                  component={"li"}
                  Color="heading"
                  onClick={() =>
                    this.props.SubSubCategoryName !== undefined
                      ? this.handleSubInnerCloseDialog()
                      : ""
                  }
                >
                  {SubcategoryName}
                </TypographyTextColor>
                {/* <li
                  onClick={() =>
                    this.props.SubSubCategoryName !== undefined
                      ? this.handleSubInnerCloseDialog()
                      : ""
                  }
                >
                  {SubcategoryName}
                </li> */}
                {this.props.SubSubCategoryName !== undefined &&
                this.props.SubSubCategoryName !== "" ? (
                  <TypographyTextColor component={"li"} Color="heading">
                    {this.props.SubSubCategoryName}
                  </TypographyTextColor>
                ) : (
                  // <li>{this.props.SubSubCategoryName}</li>
                  ""
                )}
              </ul>
            </div>
          </div>
          <div
            className="close_button"
            onClick={this.props.handleInnerCloseDialog}
          >
            <span className="icon-close"></span>
          </div>
        </div>

        <div className="menu_body">
          <div className="product-category-container p-0">
            {subCategoryLoader ? (
              <div className="loader_wrapper">
                <CustomPreloader />
              </div>
            ) : SubCategoryDetails && SubCategoryDetails.length > 0 ? (
              SubCategoryDetails.map((row, index) => {
                return (
                  <div
                    className="product-category-modal"
                    onClick={this.handleSubInnerOpenDialog.bind(
                      this,
                      row.id,
                      row.name,
                      true,
                      row.has_child,
                      row.level,
                      row.parent_id,
                      row
                    )}
                    key={index}
                  >
                    {store_Data?.is_sub_class_img === true ? (
                      <CategoryCard category={row} />
                    ) : (
                     <CategorycardNoimg name={row.name}/>
                    )}
                  </div>
                );
              })
            ) : (
              ""
            )}
            <Modal
              open={this.state.open_subDetails}
              onClose={this.handleSubInnerCloseDialog}
             
            >
              <ScrollColorTypography
                component={"div"}
                className="dialog_wrapper"
              >
                <div className="menu_head">
                  <div className="menu_title">
                    <div className="specified_details">
                      <ul>
                        <TypographyTextColor
                          component={"li"}
                          Color="other"
                          onClick={this.handleInnerInnerCloseDialog}
                        >
                          All
                        </TypographyTextColor>
                        <TypographyTextColor
                          component={"li"}
                          Color="other"
                          onClick={this.handleSubInnerCloseDialog}
                        >
                          {SubcategoryName}
                        </TypographyTextColor>
                        <TypographyTextColor
                          component={"li"}
                          Color="heading"
                          onClick={this.handleSubInnerCloseDialog}
                        >
                          {this.state.subCategoryTrigger === true
                            ? SubSubCategoryName
                            : ""}
                        </TypographyTextColor>
                        {/* <li onClick={this.handleInnerInnerCloseDialog}>
                          All
                        </li> */}
                        {/* <li onClick={this.handleSubInnerCloseDialog}>
                          {SubcategoryName}
                        </li> */}
                        {/* <li>
                          {this.state.subCategoryTrigger === true
                            ? SubSubCategoryName
                            : ""}
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="close_button"
                    onClick={this.handleSubInnerCloseDialog}
                  >
                    <span className="icon-close"></span>
                  </div>
                </div>

                <div className="menu-head-mobile d-block d-lg-none">
                  <ul className="list-inline mb-0">
                    <li
                      className="list-inline-item"
                      onClick={this.handleSubInnerCloseDialog}
                    >
                      <ArrowBackIosIcon />
                    </li>
                    <li className="list-inline-item font-weight-bold">
                      {SubSubCategoryName}
                    </li>
                  </ul>
                </div>
                <div className="menu_body">
                  <div className="product-category-container p-0">
                    {SubSubCategoryDetails && SubSubCategoryDetails.length > 0
                      ? SubSubCategoryDetails.map((row, index) => {
                          return (
                            <div
                              className="product-category-modal"
                              onClick={() =>
                                this.redirectUrl(
                                  row.id,
                                  row.parent_id,
                                  row.level,
                                  row
                                )
                              }
                              key={index}
                            >
                              {/* <CategoryCard category={row} /> */}
                              {store_Data?.is_sub_class_img === true ? (
                      <CategoryCard category={row} />
                    ) : (
                     <CategorycardNoimg name={row.name}/>
                    )}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </ScrollColorTypography>
            </Modal>
          </div>
        </div>
      </ScrollColorTypography>
    </Modal>
      :
      <Dialog
       aria-labelledby="form-dialog-title"
        maxWidth="lg"
        // fullWidth={true}
        open={this.props.open_inner}
        onClose={this.props.handleInnerCloseDialog}
        // className="modal_main"
      >
        <ScrollColorTypography component={"div"} className="dialog_wrapper">
          <div className="menu_head">
            <div className="menu_title">
              <div className="specified_details">
                <ul>
                  <TypographyTextColor
                    component={"li"}
                    Color="other"
                    onClick={this.props.handleOpenDialog}
                  >
                    All
                  </TypographyTextColor>
                  {/* <li onClick={this.props.handleOpenDialog}>All</li> */}
                  <TypographyTextColor
                    component={"li"}
                    Color="heading"
                    onClick={() =>
                      this.props.SubSubCategoryName !== undefined
                        ? this.handleSubInnerCloseDialog()
                        : ""
                    }
                  >
                    {SubcategoryName}
                  </TypographyTextColor>
                  {/* <li
                    onClick={() =>
                      this.props.SubSubCategoryName !== undefined
                        ? this.handleSubInnerCloseDialog()
                        : ""
                    }
                  >
                    {SubcategoryName}
                  </li> */}
                  {this.props.SubSubCategoryName !== undefined &&
                  this.props.SubSubCategoryName !== "" ? (
                    <TypographyTextColor component={"li"} Color="heading">
                      {this.props.SubSubCategoryName}
                    </TypographyTextColor>
                  ) : (
                    // <li>{this.props.SubSubCategoryName}</li>
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div
              className="close_button"
              onClick={this.props.handleInnerCloseDialog}
            >
              <span className="icon-close"></span>
            </div>
          </div>

          <div className="menu_body">
            <div className="product-category-container p-0">
              {subCategoryLoader ? (
                <div className="loader_wrapper">
                  <CustomPreloader />
                </div>
              ) : SubCategoryDetails && SubCategoryDetails.length > 0 ? (
                SubCategoryDetails.map((row, index) => {
                  return (
                    <div
                      className="product-category-modal"
                      onClick={this.handleSubInnerOpenDialog.bind(
                        this,
                        row.id,
                        row.name,
                        true,
                        row.has_child,
                        row.level,
                        row.parent_id,
                        row
                      )}
                      key={index}
                    >
                      {store_Data?.is_sub_class_img === true ? (
                        <CategoryCard category={row} />
                      ) : (
                       <CategorycardNoimg name={row.name}/>
                      )}
                    </div>
                  );
                })
              ) : (
                ""
              )}
              <Dialog
                open={this.state.open_subDetails}
                onClose={this.handleSubInnerCloseDialog}
                // className="modal_main"
                maxWidth="lg"
              >
                <ScrollColorTypography
                  component={"div"}
                  className="dialog_wrapper"
                >
                  <div className="menu_head">
                    <div className="menu_title">
                      <div className="specified_details">
                        <ul>
                          <TypographyTextColor
                            component={"li"}
                            Color="other"
                            onClick={this.handleInnerInnerCloseDialog}
                          >
                            All
                          </TypographyTextColor>
                          <TypographyTextColor
                            component={"li"}
                            Color="other"
                            onClick={this.handleSubInnerCloseDialog}
                          >
                            {SubcategoryName}
                          </TypographyTextColor>
                          <TypographyTextColor
                            component={"li"}
                            Color="heading"
                            onClick={this.handleSubInnerCloseDialog}
                          >
                            {this.state.subCategoryTrigger === true
                              ? SubSubCategoryName
                              : ""}
                          </TypographyTextColor>
                          {/* <li onClick={this.handleInnerInnerCloseDialog}>
                            All
                          </li> */}
                          {/* <li onClick={this.handleSubInnerCloseDialog}>
                            {SubcategoryName}
                          </li> */}
                          {/* <li>
                            {this.state.subCategoryTrigger === true
                              ? SubSubCategoryName
                              : ""}
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="close_button"
                      onClick={this.handleSubInnerCloseDialog}
                    >
                      <span className="icon-close"></span>
                    </div>
                  </div>

                  <div className="menu-head-mobile d-block d-lg-none">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item"
                        onClick={this.handleSubInnerCloseDialog}
                      >
                        <ArrowBackIosIcon />
                      </li>
                      <li className="list-inline-item font-weight-bold">
                        {SubSubCategoryName}
                      </li>
                    </ul>
                  </div>
                  <div className="menu_body">
                    <div className="product-category-container p-0">
                      {SubSubCategoryDetails && SubSubCategoryDetails.length > 0
                        ? SubSubCategoryDetails.map((row, index) => {
                            return (
                              <div
                                className="product-category-modal"
                                onClick={() =>
                                  this.redirectUrl(
                                    row.id,
                                    row.parent_id,
                                    row.level,
                                    row
                                  )
                                }
                                key={index}
                              >
                                {/* <CategoryCard category={row} /> */}
                                {store_Data?.is_sub_class_img === true ? (
                        <CategoryCard category={row} />
                      ) : (
                       <CategorycardNoimg name={row.name}/>
                      )}
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </ScrollColorTypography>
              </Dialog>
            </div>
          </div>
        </ScrollColorTypography>
      </Dialog>
    );
  }
}
export default SubCategoryModal;
