import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fab, Snackbar, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { OtpVerifing, ResendOtpApi } from "../actions/profile/profileAction";
import { AutoLoginApplication } from "../actions/login/loginAction";
import { Redirect } from "react-router-dom";
import {
  generateDeviceId,
  handlePasteOTP,
  sweetAlertBox,
} from "../utils/renderUtils/renderUtil";
import LOGO_IMAGE from "../assets/images/logo.png";
import ApiUrls from "../utils/apiUtils/apiUrl";

class VerifyMobileNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown: 45,
      otp: "",
      success: false,
      error: false,
      inputvalue: "",
      mobile_number: "",
      email_address: "",
      password: "",
      generateOtp: ["", "", "", "", "", ""],
      logo: "",
      showPopup: false,
    };
    this.timer = setInterval(() => this.tick(), props.timeout || 1000);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputRefs = Array(6).fill(React.createRef());
  }

  tick() {
    const current = this.state.countdown;
    if (current === 0) {
      this.transition();
    } else {
      this.setState({ countdown: current - 1 });
    }
  }

  transition() {
    clearInterval(this.timer);
  }

  handleSubmit(value, dispatch) {
    let Device_id = generateDeviceId();
    let formValues = {
      phone: this.state.mobile_number.replace(/-/g, ""),
      otp: value,
      device_id: Device_id,
    };
    return OtpVerifing(formValues, dispatch)
      .then((res) => {
        let storevalue = JSON.parse(localStorage.getItem("Store_address"));
        if (res.errors) {
          if (res.errors.data) {
            this.createSuccessMsg("error", res.errors.data);
          } else {
            throw new SubmissionError(res.errors);
          }
        } else if (storevalue.is_user_must_be_activate === false) {
          this.createSuccessMsg("success", res.message);
          this.handleAutologin(this.state.email_address, this.state.password);
        } else {
          this.setState({ showPopup: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAutologin = (email_address, password, dispatch) => {
    let formValues = {
      username: email_address,
      password: password,
    };
    return AutoLoginApplication(formValues, dispatch).then((res) => {
      if (res.status === 200) {
        this.RedirectToSuccessMessages();
      }
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
      addressOpne: false,
    });
  };

  ResendOtp = (e, dispatch) => {
    e.preventDefault();
    let Device_id = generateDeviceId();
    let generateOtp = this.state.generateOtp;
    let formValues = {
      phone: this.state.mobile_number.replace(/-/g, ""),
      device_id: Device_id,
    };
    return ResendOtpApi(formValues, dispatch)
      .then((res) => {
        if (res.errors) {
          if (res.errors.data) {
            this.createSuccessMsg("error", res.errors.data.errors);
          } else {
            throw new SubmissionError(res.errors);
          }
        } else {
          this.createSuccessMsg("success", res.message);
          this.setState({ countdown: 45 });
          this.tick();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  RedirectToSuccessMessages = () => {
    setTimeout(() => {
      this.props.history.push({
        pathname: "/",
      });
    }, 500);
  };

  createSuccessMsg = (type, msg) => {
    let generateOtp = this.state.generateOtp;
    if (type === "error") {
      if (msg && msg.otp) {
        sweetAlertBox("Attention!!", "Please enter OTP value", "info");
        // this.setState({
        //   error: "Please enter OTP value",
        //   success: false,
        // });
      } else if (msg && msg.phone) {
        sweetAlertBox("Attention!!", "Phone! " + msg.phone[0], "info");
        // this.setState({
        //   error: "Phone! " + msg.phone[0],
        //   success: false,
        // });
      } else if (msg && msg.id) {
        sweetAlertBox("Attention!!", msg.id[0], "info");
        // this.setState({
        //   error: msg.id[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Attention!!", msg.message, "info");
        // this.setState({
        //   error: msg.message,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
    generateOtp = ["", "", "", "", "", ""];
    this.setState({ generateOtp });
  };

  componentDidMount() {
    //document.title = "Verify page";
    let logo = this.state.logo;
    let mobile_number = "";
    let email_address = "";
    let password = "";
    if (this.props.location && this.props.location.state !== undefined) {
      mobile_number = this.props.location.state.mobile;
      email_address = this.props.location.state.email;
      password = this.props.location.state.password;
    }
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.setState({
      logo: logo,
      mobile_number: mobile_number,
      email_address: email_address,
      password: password,
    });
  }

  handleChange = (e, index, field) => {
    e.preventDefault();
    const { maxLength, value, name } = e.target;
    let generateOtp = this.state.generateOtp;
    generateOtp[index] = e.target.value;

    const [fieldName, fieldIndex] = name.split("_");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    var Inputresult = generateOtp.join("");
    if (Inputresult.length === 6) {
      this.handleSubmit(Inputresult);
    }

    this.setState({ generateOtp });
  };

  onChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ inputvalue: e.target.value });
    }
  }
  handlePaste = (e) => {
    console.log("hitt");
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.match(/^[0-9]{6}$/)) {
      const newOtp = pasteData.split("");
      this.setState({ generateOtp: newOtp }, () => {
        // Focus on the last input field after updating the state
        this[`inputRef_${5}`].focus();
      });
    }
    e.preventDefault();
  };
  render() {
    const { success, error } = this.state;
    return (
      <div>
        {localStorage.getItem("isLogin") === "true" ? (
          <Redirect to="/" />
        ) : this.props.location && this.props.location.state === undefined ? (
          <Redirect to="/create-account" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div to="/" className="header-pop">
                  <Link to="/">
                    <img
                      src={
                        this.state.logo
                          ? this.state.logo[0] === "/"
                            ? ApiUrls.BASH_URL + "/media/" + this.state.logo
                            : this.state.logo
                          : LOGO_IMAGE
                      }
                      // src={LOGO_IMAGE}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="login-page">
                  {!this.state.showPopup ? (
                    <div className="login-form">
                      <div className="login-head">
                        <h4>Verify Your Mobile Number</h4>
                        <span className="sub-head">
                          Enter OTP code sent to your number{" "}
                          {this.state.mobile_number}
                        </span>
                      </div>
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="user-input verify-mobile">
                          {[...Array(6)].map((x, i) => (
                            <input
                              type="text"
                              value={this.state.generateOtp[i]}
                              name={`otp_${i + 1}`}
                              className="verify-otp"
                              maxLength={1}
                              ref={(input) => (this[`inputRef_${i}`] = input)} // Assign ref directly
                              onChange={(e) =>
                                this.handleChange(e, i, `otp_${i + 1}`)
                              }
                              autoFocus={i === 0 ? true : false}
                              onKeyPress={(event) => {
                                if (
                                  !/[0-9]/.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                } else if (i < 5 && /[0-9]/.test(event.key)) {
                                  // Move focus to the next input if a number key is pressed (excluding the last input)
                                  this[`inputRef_${i + 1}`].focus();
                                }
                              }}
                              onKeyDown={(event) => {
                                if (
                                  !/[0-9]/.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                } else if (event.key === "Backspace" && i > 0) {
                                  // Allow backspace only if it's not the first input
                                  this[`inputRef_${i - 1}`].focus();
                                }
                              }}
                              onPaste={this.handlePaste}
                            />
                          ))}
                        </div>
                        <div className="bttn-fill">
                          {this.state.countdown > 0 ? (
                            <p className="login-txt">
                              Resend OTP in &nbsp;{" "}
                              <span>{` 00 : ${Number(
                                this.state.countdown
                              )}`}</span>
                            </p>
                          ) : (
                            <p className="login-txt">
                              <Link
                                href="javascript:void(0);"
                                onClick={(e) => this.ResendOtp(e)}
                              >
                                Resend OTP
                              </Link>
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="login-head" style={{ padding: "25px" }}>
                      <h4>
                        Your account has been under process. We will get back to
                        you soon.
                      </h4>
                      <div className="cart_checkout">
                        <div className="bttn-fill">
                          <Link to="/" className="bttn_grn">
                            Go to Home
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {success ? (
              <Snackbar
                open={this.state.success}
                onClose={this.handleClose}
                autoHideDuration={1000}
                className="success"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        <p>{success}</p>
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
            {error ? (
              <Snackbar
                open={this.state.error}
                onClose={this.handleClose}
                autoHideDuration={1000}
                className="error"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        <p>{error}</p>
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "VerifyMobileNo" })(VerifyMobileNo)
);
