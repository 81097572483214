import React, { createElement } from "react";
import { logout } from "../../utils/renderUtils/renderUtil";
import { Link } from "react-router-dom";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import { userDashboardMenu } from "./Header/utils";
import * as Icons from "react-icons/fa";
import {
  TypographyHoverTextColor,
  TypographySecondaryColor,
  TypographyTextColor,
} from "./UiDesign";
const ToggleLoginMenu = (props) => {
  return (
    <>
      {" "}
      <Link>
        <div className="header-section">
          <div
            className="profile"
            onClick={() => {
              localStorage.getItem("isLogin") === "true"
                ? props.redirectToProfile()
                : props.redirectToLogin();
            }}
          >
            <span className="icon-account"></span>
          </div>
          <div className="name-section">
            {localStorage.getItem("listOpen") === "true" ? (
              <div onClick={() => props.redirectToProfile()}>
                <TypographyTextColor
                            component="span"
                            Color="heading">
                  {" "}
                  {localStorage.getItem("name")
                    ? JSON.parse(localStorage.getItem("name")).name
                    : ""}
                </TypographyTextColor>
                <br></br>
                <TypographyTextColor
                            component="p"
                            Color="other">
                  {" "}
                  {localStorage.getItem("email")
                    ? JSON.parse(localStorage.getItem("email")).email.length >
                      25
                      ? JSON.parse(localStorage.getItem("email")).email.slice(
                          0,
                          25
                        ) + "..."
                      : JSON.parse(localStorage.getItem("email")).email
                    : ""}
                </TypographyTextColor>
              </div>
            ) : (
              <div onClick={() => props.redirectToLogin()}>
                <TypographyTextColor
                            component="p"
                            Color="heading">Login or Register</TypographyTextColor>
              </div>
            )}

            <div
              className="close"
              onClick={() => {
                localStorage.getItem("isLogin") === "true"
                  ? props.redirectToProfile()
                  : props.redirectToLogin();
              }}
            >
              <span className="icon-left-arrow"></span>
            </div>
          </div>
        </div>
      </Link>
      <div className="inner-section">
        <ul className="topmenu">
          {props.top_menu && props.top_menu.length > 0
            ? props.top_menu.map((row, index) => {
                return (
                  <li key={index} style={{ padding: "5px 30px" }}>
                    <TypographyHoverTextColor
                      Color="category"
                      className={
                        row === "SPECIALS"
                          ? props.activeClass
                          : row === "WEEKLY"
                          ? props.weekendactiveclass
                          : row === "COVID19"
                          ? props.covid19activeClass
                          : row === "NEW ITEM"
                          ? props.newItemactiveclass
                          : ""
                      }
                      onClick={() => {
                        if (props.pageName === "listing_page")
                          props.rediectToSamePageForspacial(
                            row,
                            props.pageName
                          );
                        else {
                          props.history.push({
                            pathname: "/listing/campaign=" + row,
                            state: {
                              campaign: "campaign",
                              special_filter_value: row,
                              slotName: props.slotName,
                            },
                          });
                          if (row === "SPECIALS") window.location.reload();
                        }
                      }}
                    >
                      {row}
                    </TypographyHoverTextColor>
                  </li>
                );
              })
            : ""}
          {localStorage.getItem("pdf_data_view") &&
          JSON.parse(localStorage.getItem("pdf_data_view")).link !== null ? (
            <li>
              {" "}
              <Link
                onClick={() => {
                  props.OpenDialogPdfPopup();
                }}
                style={{
                  color: "#3d9b7b",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {localStorage.getItem("pdf_data_view")
                  ? JSON.parse(localStorage.getItem("pdf_data_view")).name
                  : ""}
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
        {localStorage.getItem("listOpen") === "true" ? (
          <ul className="topmenu menu-items">
            {userDashboardMenu.map((val, i) => {
              return (
                <li>
                  <Link
                    to={val.url === "" ? "" : "/" + val.url}
                    onClick={() => (val.url === "" ? logout() : "")}
                  >
                    <TypographySecondaryColor
                      className={val.iconType === "fa" ? val.iconName : ""}
                    >
                      {val.iconType === "react"
                        ? createElement(Icons[val.iconName])
                        : ""}
                    </TypographySecondaryColor>

                    <TypographyTextColor component="span" Color="other">
                      {val.name}
                    </TypographyTextColor>
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
      {localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) !== null ? (
        <div className="inner-section">
          <ul className="topmenu">
            <li>
              {" "}
              <TypographySecondaryColor
                Fonttype="heading"
                onClick={() => {
                  localStorage.removeItem(
                    "UserDetail" +
                      JSON.parse(localStorage.getItem("Store_address"))?.id
                  );

                  logout();
                }}
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Log out @Sales rep
              </TypographySecondaryColor>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ToggleLoginMenu;
