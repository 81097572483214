import React, { useState } from "react";
import {
  Checkbox,
  FormGroup,
  Dialog,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Button,
  FormControlLabel,
  IconButton,
  DialogTitle,
  Radio,
  TextField,
} from "@mui/material";
import { SecondaryButton, SecondaryCancelButton, TypographyTextColor } from "../UiDesign";
const ProductNote = (props) => {
  return (
    <Dialog
      open={props.setOpen}
      onClose={() => props.CloseDialog("Cancel")}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      className="popUp"
    >
      <DialogTitle
        style={{
          padding: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          {" "}
          <TypographyTextColor component="h4" Color="heading">Add note</TypographyTextColor>
          <span className="float-right">
            <IconButton onClick={() => props.CloseDialog("Cancel")}>
              <span
                class="icon-close"
                style={{ color: "#389978", fontSize: "18px" }}
              ></span>
            </IconButton>
          </span>
        </div>
      </DialogTitle>
      <DialogContent className="p-0">
        <div className="popup">
          <div className="boxshadow rounded-0 py-3 px-3">
            <div className="form-group mb-4">
              <TypographyTextColor Color="heading" className="font-weight-bold">Product Note</TypographyTextColor>
              <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          name="product_note"
          onChange={(e) => props.prodcutNote(e)}
          className="w-100"
          placeholder="I would like my shopper to..."
          value={props.productNoteItemNote}
                      
        />
              {/* <TextareaAutosize
                name="product_note"
                onChange={(e) => props.prodcutNote(e)}
                minRows={3}
                fullWidth
                variant="outlined"
                className="w-100"
                aria-label="maximum height"
                placeholder="Add Notes or backup"
                value={props.productNoteItemNote}
              /> */}
            </div>

            <TypographyTextColor component="h6" Color="heading" className="font-weight-bold">Backup Instruction</TypographyTextColor>
            <TypographyTextColor Color="other">Your choice in case the item is out of stock</TypographyTextColor>

            <FormGroup>
              <FormControlLabel
                value="Do not substitute"
                control={<Radio checked={props.doNotSubstitute} />}
                label={<TypographyTextColor Color="other" component="span">Do not substitute</TypographyTextColor>}
                onChange={(e) => props.backupInstractTion(e.target.value)}
              />
              <FormControlLabel
                value="Substitute with similar"
                control={<Radio checked={props.substituteWithSimilar} />}
                label={<TypographyTextColor Color="other" component="span">Substitute with similar</TypographyTextColor>}
                onChange={(e) => props.backupInstractTion(e.target.value)}
              />
              <FormControlLabel
                value="Call me"
                control={<Radio checked={props.callMe} />}
                label={<TypographyTextColor Color="other" component="span">Call me</TypographyTextColor>}
                onChange={(e) => props.backupInstractTion(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <SecondaryCancelButton
          className="popbtncancel"
          onClick={() => props.CloseDialog("Cancel")}
          Fonttype="other"
        >
          Cancel
        </SecondaryCancelButton>
        <SecondaryButton
          className="popbtn"
          onClick={() => props.CloseDialog("Add_note")}
          Fonttype="other"
        >
          Add Note
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProductNote;
