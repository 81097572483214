import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { productDetailsPointsData } from "../../../utils/renderUtils/dbData";
import { TypographyTextColor } from "../UiDesign";
const ItemDetailDescription = (props) => {
  const { ProductDetails } = props;
  return (
    <>
      <div className="details_sub_desc">
        <ul>
          {Object.keys(productDetailsPointsData).map((oneKey, i) => {
            return ProductDetails[oneKey] === true ? (
              <li key={i}>
                <AiFillCheckCircle
                  style={{ color: "#03c003", fontSize: "1.2rem" }}
                />
                <TypographyTextColor
                  Color="other"
                  component="span"
                  className="ml-1"
                >
                  {productDetailsPointsData[oneKey]}
                </TypographyTextColor>
              </li>
            ) : (
              <></>
            );
          })}
          {ProductDetails && ProductDetails.kosher1 !== "" ? (
            <li>
              {" "}
              <AiFillCheckCircle
                style={{ color: "#03c003", fontSize: "1.2rem" }}
              />
              <TypographyTextColor
                Color="other"
                component="span"
                className="ml-1"
              >
                {ProductDetails.kosher1}
              </TypographyTextColor>
            </li>
          ) : (
            ""
          )}
          {ProductDetails && ProductDetails.kosher2 !== "" ? (
            <li>
              {" "}
              <AiFillCheckCircle
                style={{ color: "#03c003", fontSize: "1.2rem" }}
              />
              <TypographyTextColor
                Color="other"
                component="span"
                className="ml-1"
              >
                {ProductDetails.kosher2}
              </TypographyTextColor>
            </li>
          ) : (
            ""
          )}

          {ProductDetails && ProductDetails.kosher3 !== "" ? (
            <li>
              {" "}
              <AiFillCheckCircle
                style={{ color: "#03c003", fontSize: "1.2rem" }}
              />
              <TypographyTextColor
                Color="other"
                component="span"
                className="ml-1"
              >
                {ProductDetails.kosher3}
              </TypographyTextColor>
            </li>
          ) : (
            ""
          )}
          {ProductDetails && ProductDetails.kosher4 !== "" ? (
            <li>
              {" "}
              <AiFillCheckCircle
                style={{ color: "#03c003", fontSize: "1.2rem" }}
              />
              <TypographyTextColor
                Color="other"
                component="span"
                className="ml-1"
              >
                {ProductDetails.kosher4}
              </TypographyTextColor>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
};

export default ItemDetailDescription;
