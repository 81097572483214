import React, { useState, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CategoryIcon from "@mui/icons-material/Category";
import { BsListColumnsReverse } from "react-icons/bs";
import {
  TypographyBgColor,
  TypographyFontColor,
  TypographyTextColor,
} from "../../UiDesign";
import { SpeedDial } from "@mui/lab";
import { FaPlus } from "react-icons/fa";
import { TbZoomQuestion } from "react-icons/tb";
const BottomMobNav = (props) => {
  const { CartCount } = props;
  return (
    <div className="mobi-bottom-navigation">
      <BottomNavigation
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: "0px",
          zIndex: 99,
        }}
        showLabels
      >
        {props.history?.location?.pathname !== "/cart" &&
        props.history?.location?.pathname !== "/payment" ? (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            style={{ position: "absolute", bottom: "4rem", right: 16 }}
            icon={<TbZoomQuestion style={{ fontSize: "30px" }} />}
            onClick={props.handleUnlistedModal}
          ></SpeedDial>
        ) : (
          <></>
        )}
        <BottomNavigationAction
          label={
            <TypographyTextColor component="span" Color="other">
              Home
            </TypographyTextColor>
          }
          icon={<HomeIcon />}
          onClick={() => {
            props.history.push({ pathname: "/" });
            window.location.reload();
          }}
        />
        <BottomNavigationAction
          label={
            <TypographyTextColor component="span" Color="other">
              List
            </TypographyTextColor>
          }
          icon={
            <img
              src="/lists.svg"
              style={{ width: "23px", opacity: 0.8, padding: "2px" }}
            />
          }
          onClick={() => {
            if (localStorage.getItem("isLogin") === "true") {
              props.history.push({ pathname: "/favouritelist" });
            } else props.OpenLoginPoup();
          }}
        />
        <BottomNavigationAction
          label={
            <TypographyTextColor
              component="span"
              Color="other"
              style={{
                position: "absolute",
                bottom: 0,
                left: '3px',
                textWrap: "nowrap",
              }}
            >
               Products
            </TypographyTextColor>
          }
          icon={
            <TypographyBgColor
              style={{
                padding: "15px 18px",
                position: "relative",
                top: "-25px",
                borderRadius: "100%",
              }}
              component="span"
            >
              <BsListColumnsReverse
                style={{ fontSize: "20px", color: "#fff" }}
              />
            </TypographyBgColor>
          }
          onClick={() => props.history.push({ pathname: "/category" })}
        />

        <BottomNavigationAction
          label={
            <TypographyTextColor component="span" Color="other">
              Orders
            </TypographyTextColor>
          }
          icon={<span className="icon-box" style={{ fontSize: "18px" }}></span>}
          onClick={() => {
            if (localStorage.getItem("isLogin") === "true") {
              props.history.push({ pathname: "/orders" });
            } else props.OpenLoginPoup();
          }}
        />
        <BottomNavigationAction
          label={
            <TypographyFontColor
              border={0}
              component={"span"}
              className="amount"
              Fonttype="other"
            >
              $
              {CartCount && CartCount.price
                ? Number(CartCount.price).toFixed(2)
                : 0}
            </TypographyFontColor>
          }
          icon={
            <span>
              <span
                className="icon-supermarket cat_icn"
                style={{ fontSize: "18px" }}
              ></span>
              {CartCount && CartCount.item > 0 ? (
                <TypographyBgColor component={"div"} className="count_rount">
                  {CartCount ? CartCount.item : "0"}
                </TypographyBgColor>
              ) : (
                ""
              )}
            </span>
          }
          onClick={() => {
            if (props.history.location.pathname !== "/cart")
              // this.showCart();
              props.history.push({
                pathname: "/cart",
              });
          }}
        />
      </BottomNavigation>
    </div>
  );
};

export default BottomMobNav;
