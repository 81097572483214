import React from "react";
import { Link } from "react-router-dom";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import { Button, Typography } from "@mui/material";
import { addDefaultImageSrc, refineImgurl } from "../../../utils/renderUtils/renderUtil";
import ProductNoteChipsview from "../Header/ProductNoteChipsview";
import {
  LabelPrimaryColor,
  SaleLabelySecondaryColor,
  ScrollColorTypography,
  SecondaryButton,
  TypographySecondaryBgColor,
  TypographySecondaryBorder,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../UiDesign";
const CartlistItem = (props) => {
  const{web_customize}=props
  const addDefaultSubCategorySrc = (ev) => {
    ev.target.src = NO_IMAGE;
  };

  const { cartItemList, CartCount } = props;
  return (
    <div class="sidenav"  style={{
      top:
        web_customize !== null &&
        web_customize?.top_marquee !== null &&
        web_customize?.top_marquee !== "" &&
        window.screen.availWidth > 900
          ? "7.1rem"
          : "",
    }}>
      <section class="cart_side" >
        <div class="collapsable">
          <ScrollColorTypography
            component={"div"}
            className="cart-listing-details"
          >
            <div class="cart_main_wrap">
              {cartItemList && cartItemList.length > 0 ? (
                cartItemList.map((row, index) => {
                  return (
                    <div class="cart_sub_details">
                      <div class="cart_details">
                        <div class="count_wrapper">
                          <Typography
                            component={TypographySecondaryBgColor}
                            className="count_box"
                          >
                            <SecondaryButton
                              className="count_btn"
                              onClick={() =>
                                props.AddCountForcartList(
                                  row.product_id,
                                  row.qty,
                                  row.is_case,
                                  "icon-plus",
                                  row.id,
                                  row.container_type
                                )
                              }
                            >
                              <span class="icon-plus"></span>
                            </SecondaryButton>
                            <Typography
                              component={TypographySecondaryBorder}
                              className="count_no"
                            >
                              {/* {row.qty > 0 ? row.qty : 1} */}
                              {props.headerinputField === false ? (
                                <input
                                  type="text"
                                  id={row.product_id}
                                  value={row.qty > 0 ? row.qty : 1}
                                  onChange={(e) =>
                                    props.AddCountForcartList(
                                      row.product_id,
                                      e.target.value,
                                      row.is_case,
                                      "icon-input",
                                      row.id,
                                      row.container_type
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  id={row.product_id}
                                  value={row.qty}
                                  onBlur={props.changeheaderinputFieldValue}
                                  onChange={(e) =>
                                    props.AddCountForcartList(
                                      row.product_id,
                                      e.target.value,
                                      row.is_case,
                                      "icon-input",
                                      row.id,
                                      row.container_type
                                    )
                                  }
                                />
                              )}
                            </Typography>
                            <SecondaryButton
                              className="count_btn"
                              onClick={() =>
                                props.AddCountForcartList(
                                  row.product_id,
                                  row.qty,
                                  row.is_case,
                                  "icon-minus",
                                  row.id,
                                  row.container_type
                                )
                              }
                            >
                              <span class="icon-minus"></span>
                            </SecondaryButton>
                          </Typography>
                          <div class="line_count">
                            <Typography
                              component={SaleLabelySecondaryColor}
                              className="product_wrapper_cart"
                            >
                              {row.qty > 0 ? row.qty : 1}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={
                            props.extraCharges?.is_show_out_of_stock === true &&
                            row.out_of_stock === true
                              ? "smll_img_search out_of_stock_content position-relative"
                              : "smll_img_search"
                          }
                        >
                           
                          <img
                            className=""
                            src={refineImgurl(row.image , {
                              h: 50,
                              w: 50,
                            })}
                            // src={row.image ? row.image : NO_IMAGE}
                            alt=""
                            onError={(e) => addDefaultImageSrc(e)}
                          />
                          {props.extraCharges?.is_show_out_of_stock === true &&
                          row.out_of_stock === true ? (
                            <div className="out-of-stock">
                              <LabelPrimaryColor
                                component={"span"}
                                border={2}
                                className="text"
                                style={{ fontSize: "6px" }}
                              >
                                Out Of Stock
                              </LabelPrimaryColor>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          class="cart_desc"
                          onClick={() =>
                            props.redirectToDetailsPageAfterClick(
                              row.product_name,
                              row.slug,
                              row.product_id
                            )
                          }
                        >
                          <div class="cart_desc_name">
                            <TypographyTextColor Color={"product"}>
                              {row.product_name}
                              {row.container_type
                                ? " (" + row.container_type + ") "
                                : ""}
                            </TypographyTextColor>
                          </div>
                          <div class="cart_details_count">
                            <TypographyTextColor
                              component="span"
                              Color="other"
                              className="prod_pieces mr-2"
                            >
                              {row.product_id === null
                                ? ""
                                : 
                                  `$` +
                                  row.unit_price?.toFixed(2)+(
                                    row.is_by_weight === true
                                      ? "/ LB  "
                                      :row.is_case?
                                      "/Case of "+row.case_quantity
                                      :"/pc."
                                  )}
                            </TypographyTextColor>
                            <TypographyTextColor
                              component="span"
                              Color="other"
                              className="prod_cost"
                            >
                              {row.product_id === null
                                ? "0.00"
                                : 
                                  `$` +
                                  (row.total
                                    ? Number(row.total)?.toFixed(2)
                                    : 0)}
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                      {row.note ? (
                        <div className="note_box">
                          <p>
                            <ProductNoteChipsview
                              ProductDetailsnotes={row.note}
                            />
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="sideemtpyCartMsg">
                  There are no items in cart !
                </div>
              )}
            </div>
          </ScrollColorTypography>
          <div class="cart_items_total">
            <div class="sub_total">
              <TypographyTextColor
                component="div"
                Color="heading"
                className="sub_desc"
              >
                Subtotal
              </TypographyTextColor>
              <TypographyTextColor
                component="div"
                Color="other"
                className="sub_total_count"
              >
                $
                {CartCount && CartCount.price > 0
                  ? Number(CartCount.price).toFixed(2)
                  : 0}
              </TypographyTextColor>
            </div>
            {CartCount && CartCount.item > 0 ? (
              <div class="cart_checkout">
                <div class="bttn-fill">
                  {/* <SecondaryButton>test</SecondaryButton> */}
                  <SecondaryButton
                    Fonttype="other"
                    className="bttn_grn"
                    onClick={() => props.handleCheckout()}
                  >
                    Checkout
                  </SecondaryButton>
                </div>
                <div class="bttn-fill1 text-center">
                  <Button
                    className="bttn_grn"
                    onClick={(e) => props.handleOpnDialog(e)}
                  >
                    <TypographyTextFontFamily component="span" type="other">
                      Empty Cart
                    </TypographyTextFontFamily>
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CartlistItem;
