import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import CategoriesModal from "./common/Category/categoriesModal";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import { reduxForm, Field } from "redux-form";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import { connect } from "react-redux";
import ApiUrls from "../utils/apiUtils/apiUrl";
import {
  favoriteLevelListFunctionlity,
  AddFavoriteLevelFunctionlity,
  SaveAddtoCartShoopingList,
} from "../actions/favorite/favoriteAction";
import DeliveryPopup from "./delivery_popup/delivery_popup";
import {
  OrderAddressAndDelivery,
  GetSettingsData,
} from "../actions/profile/profileAction";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AiOutlineFieldTime, AiFillEdit } from "react-icons/ai";
import EBTLOGO from "../assets/images/logo-snap.png";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextareaAutosize,
  Snackbar,
  IconButton,
  ClickAwayListener,
  Grow,
  Typography,
  Divider,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Backdrop,
} from "@mui/material";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
  GuestLoginAthentication,
  AddToCartFunctionlity,
  SubCategeriesListView,
  PopularProductsList,
  CategoryTreeViewApi,
} from "../actions/home/homeAction";

import CircularProgress from "@mui/material/CircularProgress";
import LoderImage from "../assets/images/more.png";
import {
  CheckOutFunctionlity,
  CartListFunctionlity,
  CardOrderSummaryFunctionlity,
  DeleteCrtIemFunctionlity,
  RemoveCartFunctionlity,
  CheckMinimumOrderAPi,
  GetExtraChargesData,
  GetCardSavedapi,
  CartOrderFunctionlity,
} from "../actions/cart/cartAction";
import CreateFavorite from "./common/ProductCard/createFavorite";
import {
  commonPageTitel,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  renderSelect2Input,
  RefreshApiToken,
  getSchedule_zip,
  addDefaultImageSrc,
  convertTodate,
  getSchedule_zip_Cart,
  sweetAlertBox,
  getCreditCardType,
  slugifyStr,
  checkImageUrls,
  SinglecheckImageUrls,
  refineImgurl,
} from "../utils/renderUtils/renderUtil";
import Address from "./deliveryaddress/Address";
import Loginpopup from "./login/Loginpopup";
import CustomPreloader from "./common/Loader/CustomPreloader";
import ProductNoteChipsview from "./common/Header/ProductNoteChipsview";
import ItemCard from "./common/ProductCard/ItemCard";
import ItemLoaderCard from "./common/Loader/ItemLoaderCard";
import {
  LabelPrimaryColor,
  PrimaryBorderButton,
  ScrollColorTypography,
  SecondaryButton,
  SuggestedCartSection,
  TypographyBgColor,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "./common/UiDesign";
import CartListing from "./common/Cart/Mobile/CartListing";
import OrderSummarySkeleton from "./common/Skeleton/OrderSummarySkeleton";
import CommonSkeleton from "./common/Skeleton/CommonSkeleton";
import PaymentSection from "./common/PaymentSection";
import {
  InitiateEBT,
  fetchEBTCard,
  fetchEBTbalance,
} from "../actions/order/orderAction";
import CardsavePopup from "./common/CardsavePopup";

const steps = ["Address", "Payment", "Order"];
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      setUrl: "",
      cartCategoryList: [],
      cartItemList: [],
      pageName: "cart_page",
      itemCount: 0,
      CardOrderSummary: [],
      SubCategoryList: [{ label: "All", value: 0 }],
      TotalItemCount: 0,
      CategoryDetails: [],
      CartCount: [],
      TotalPayment: 0,
      OriginalPayment: 0,
      SubTotal: 0,
      listLoading: false,
      cartSubtotalLoader: false,
      listIndex: 0,
      orderSummeryLoader: true,
      cartLoader: true,
      openDialog: false,
      TotalQty: 0,
      ItemIndex: 0,
      disabled: false,
      finalCartCategoryList: [],
      setOpenCartNote: false,
      productCartNote: "",
      backupCartInstraction: "",
      backupCartInstractioArr: [
        "Do not subsite",
        "Subsite other brand",
        "Call me",
      ],
      CategoryOption: [],
      setOpen: false,
      favoriteLevelData: [],
      success: false,
      error: false,
      inputField: false,
      tipForPacker: "0.00",
      tipForDeliveryboy: "0.00",
      donation: "0.00",
      subtotal_price: 0,
      is_general_note: false,
      general_note: "",
      openSuccessModal_msg: "",
      logo: "",
      extraCharges: "",
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      zipCode: "",
      setOpenAddresspopup: false,
      setOpenLoginPopup:
        (localStorage.getItem("isLogin") &&
          localStorage.getItem("isLogin") === "true") ||
        localStorage.getItem("Guest_userid") !== null
          ? false
          : true,
      selectedAddress: "",
      setOpenDeliveryPopup: false,
      slotName: "",
      setOpenslotpupup: false,
      not_available_on_zip: [],
      isShowAddnote: false,
      additonalPickup: "",
      mainLoader: true,
      emailSubscribe: "",
      ActiveSlotData: JSON.parse(localStorage.getItem("slot")),
      Inactiveproduct: [],
      PopularProductList: [],
      chack_is_case: [],
      popularLimit:
        document.getElementById("cart-section")?.offsetWidth < 1600 ? 4 : 6,
      propularProductTotal: 0,
      popularPage: 1,
      selectedslotPopupData:
        JSON.parse(localStorage.getItem("slot"))?.schedule === 1
          ? "delivery"
          : JSON.parse(localStorage.getItem("slot"))?.schedule === 2
          ? "pickup"
          : "shipping",

      storeAddress_data: JSON.parse(localStorage.getItem("Store_address")),
      accessToken: "",
      MobileViewOrdersummary: false,
      EBTPayment: 0,
      ebtisSelected:
        localStorage.getItem("selectedPaymentoption") !== null
          ? parseInt(localStorage.getItem("selectedPaymentoption"))
          : "",
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      openStoreMsg: false,
      tree_data: [],
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      cardData: [],
      ebtAmount: 0,
      ebtorderAmount: 0,
      originalebtorderAmount: 0,
      ebtcardNumber: "",
      isebtpayment: false,
      activeStep: 1,
      creditData: { isPaidByCredit: false, paymentAmount: 0 },
      paymentOption:
        localStorage.getItem("PaymentOptionVisibility") === "no_option"
          ? "cash_payment"
          : localStorage.getItem("selectedPaymentoption") !== null
          ? "ebt"
          : "",
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
      paymentLoader: false,
      tips_amount: 0,
      cardType: "",
    };
    this.categoryOpen = this.categoryOpen.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }
  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  CloseSlotDialog = (type, selectedslot) => {
    if (type === "close") {
      this.setState({ setOpenDeliveryPopup: false });
    } else {
      this.openAddressPopup(selectedslot);
    }
  };
  handlerestrictByOnhand = (data) => {
    for (let i = 0; i < data.length; i++) {
      this.DeleteCartItem(
        data[i].product_id,
        data[i].is_case,
        data[i].id,
        data[i].container_type,
        i === data.length - 1
      );
    }
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // if(window.innerWidth>900)
    document.body.style.overflow = "hidden";

    // localStorage.removeItem("selectedPaymentoption");
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    const accessToken = localStorage.getItem("access_token");
    let activePathname = this.props.history.location?.pathname
      ? this.props.history.location?.pathname
      : "";
    //;
    let setUrl;

    if (JSON.parse(localStorage.getItem("isLogin")) === false) {
      setUrl = "/login/?order=" + activePathname;
    } else {
      setUrl = "/delivery-address";
    }
    this.setState({ setUrl: setUrl, logo: logo, accessToken: accessToken });
    document.title = commonPageTitel(
      JSON.parse(localStorage.getItem("access_token"))
    );
    if (
      accessToken &&
      localStorage.getItem("isLogin") === "false" &&
      localStorage.getItem("Guest_userid") === null
    ) {
      this.HitGuestApiToken();
    } else {
      this.FetchCartItemList(this.state.listIndex);
      this.FetchCategoryList();
      this.FetchCartOrderSummaryList();
      this.FetchSubCategory();
      this.ExtraChargesFunctiolity();
      this.FetchPopularProductList(1);
      this.FetchCategoryTreeView();
      this.getSavedCardapi();
      this.fetchebtdata();
      this.fetchEBTCardData();

      if (
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") === "open" &&
        localStorage.getItem("slot") === null
      ) {
        this.setState({ setOpenDeliveryPopup: true });
      }
    }
  }
  fetchEBTCardData = () => {
    this.setState({ mainLoader: true });
    fetchEBTCard().then((res) => {
     
      this.setState({ mainLoader: false });
      if (res.status === 200) {
        this.setState({ EBTcarddata: res.data?.status });
      } else if (res.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.fetchEBTCardData();
            }
          );
        }
      }
    });
  };
  fetchebtdata = () => {
    if (localStorage.getItem("refnum") !== null) {
      let formvalues = {
        refnum: localStorage.getItem("refnum"),
      };
      this.setState({ mainLoader: true });
      fetchEBTbalance(formvalues, this.props.dispatch).then((res) => {
        if (res.status === 200) {
          let ebtorderamt = this.state.ebtorderAmount;
          let ebtBalance = res.data.result?.xRemainingBalanceEBTFS;
          let credits = this.state.creditData;
          if (ebtorderamt <= ebtBalance)
            this.setState({ ebtAmount: ebtBalance });
          else {
            this.setState({
              ebtAmount: ebtBalance,
              ebtorderAmount: ebtBalance,
              originalebtorderAmount: ebtBalance,
            });
          }
          let TotalPayment = this.state.TotalPayment;
          let finalPrice = (
            parseFloat(TotalPayment) - parseFloat(this.state.ebtorderAmount)
          ).toFixed(2);
          if (
            credits?.customerCreditStatus?.availableCredit &&
            parseFloat(credits?.customerCreditStatus?.availableCredit) >=
              finalPrice
          )
            credits.paymentAmount = finalPrice;
          else
            credits.paymentAmount =
              credits.customerCreditStatus.availableCredit;
          this.setState({
            paymentOption: "ebt",
            isebtpayment: true,
            creditData: credits,
            activeStep: 2,
          });
        } else {
          this.setState({ ebtAmount: 0, paymentOption: "", ebtisSelected: "" });
          localStorage.removeItem("selectedPaymentoption");
        }
      });
      this.setState({ mainLoader: false });
    }
  };
  getSavedCardapi = () => {
    this.setState({ mainLoader: true });
    GetCardSavedapi().then((res) => {
      this.setState({ mainLoader: false });
      let cardData = [];
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.getSavedCardapi();
            }
          );
        }
      } else if (res.data && res.data.status) {
        cardData = res.data.status;
        cardData.map((ele) => {
          ele.isSelected = false;
          ele.cvv = "";
        });
        this.setState({ cardData });
      }
    });
  };
  HitGuestApiToken = () => {
    let formValues = {};
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
      logo = res.data && res.data.logo ? res.data.logo : "";
      this.setState({ logo: logo });

      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchCartOrderSummaryList();
      this.FetchSubCategory();
      this.ExtraChargesFunctiolity();
      this.FetchPopularProductList(1);
      this.getSavedCardapi();
      this.fetchebtdata();
      this.fetchEBTCardData();
    });
  };

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      } else if (res.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.ExtraChargesFunctiolity();
            }
          );
        }
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  CartFunctionlity = () => {
    let formValues = {};
    let activePathname = this.props.history.location?.pathname
      ? this.props.history.location?.pathname
      : "";
    CheckOutFunctionlity(formValues, this.props.dispatch).then((res) => {
      let setUrl;
      if (res.errors && res.errors._error.status === 400) {
        setUrl = "/login/?order=" + activePathname;
      } else {
        setUrl = "/delivery-address";
      }
      this.setState({ setUrl: setUrl });
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  createSuccessMsg = (type, msg) => {
    this.setState({ paymentLoader: false });
    if (type === "error") {
      if (msg && msg.user_id) {
        sweetAlertBox("Attention!!", msg.user_id[0], "info");

        this.setState({
          // error: msg.user_id[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.cart) {
        sweetAlertBox("Attention!!", msg.cart[0], "info");

        this.setState({
          // error: msg.cart[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.subtotal) {
        sweetAlertBox("Attention!!", msg.subtotal[0], "info");

        this.setState({
          // error: msg.subtotal[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.error && msg.error.length > 0) {
        sweetAlertBox("Attention!!", msg.error[0], "info");

        this.setState({
          // error: msg.error[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.quantity) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else if (msg && msg.general_note) {
        sweetAlertBox("Attention!!", "General note may not blunk", "info");
        // this.setState({
        //   error: "General note may not blunk",
        //   success: false,
        // });
      } else if (msg && msg.address_id) {
        sweetAlertBox("Attention!!", msg.address_id[0], "info");

        // this.setState({
        //   error: msg.address_id[0],
        //   success: false,
        // });
      } else if (msg && msg.cart) {
        sweetAlertBox("Attention!!", msg.cart[0], "info");

        // this.setState({
        //   error: msg.cart[0],
        //   success: false,
        // });
      } else if (msg && msg.product_id) {
        sweetAlertBox(
          "Attention!!",
          "This item is not available at this time",
          "info"
        );

        // this.setState({
        //   error: "This item is not available at this time",
        //   success: false,
        // });
      } else if (msg && msg.error) {
        sweetAlertBox("Attention!!", msg.error[0], "info");

        // this.setState({
        //   error: msg.error[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");

        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  FetchCategoryTreeView = () => {
    let formValues = getSchedule_zip();

    CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
      let TreeViewIteamsHome = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        TreeViewIteamsHome = res.data.result;
        var tree_data = this.onbuildForTreeData(TreeViewIteamsHome, null);
        tree_data.sort(function (a, b) {
          return a.cat_order - b.cat_order;
        });

        CategoryOption.push({ label: "All Categories", value: "" });
        for (let i = 0; i < tree_data.length; i++) {
          CategoryOption.push({
            label: tree_data[i].name,
            value: tree_data[i].id,
          });
        }
        this.setState({
          TreeViewIteamsHome: TreeViewIteamsHome,
          CategoryListLoader: false,
          tree_data: tree_data,
          CategoryOption,
        });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryTreeView();
            }
          );
        }
      }
    });
  };

  FetchCartItemList = (listIndex, loader = true) => {
    let formValues = getSchedule_zip_Cart();
    formValues.zipCode =
      localStorage.getItem("frontend_animation") !== "open"
        ? formValues.zipCode
        : "";
    formValues.scheduleType =
      localStorage.getItem("frontend_animation") !== "open"
        ? formValues.scheduleType
        : "";
    this.setState({
      listIndex: listIndex,
      cartLoader: loader,
    });

    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let cartItemList = [];
      let finalData = [];
      let CartCount = [];
      let general_note = "";
      let is_general_note = this.state.is_general_note;
      let TotalItemCount = 0;
      let SubTotal = 0;
      let inactiveproduct = [];

      if (res.data && res.data.result) {
        const response = res.data.result;
        response.forEach((item) => {
          if (!item.is_active) inactiveproduct.push(item.id);

          let categoryIndex = finalData.findIndex(
            (cat) => cat.category_id === item.category_id
          );

          if (categoryIndex > -1) {
            finalData[categoryIndex].items.push(item);
          } else {
            finalData.push({
              category_id: item.category_id,
              category_name: item.category_name,
              items: [item],
            });
          }

          SubTotal += item.total * item.qty;
          TotalItemCount += item.length;
        });

        general_note =
          res.data.general_note && res.data.general_note.length > 0
            ? res.data.general_note[0].general_note
            : "";

        is_general_note =
          res.data.general_note && res.data.general_note.length > 0;

        this.setState({
          TotalItemCount: TotalItemCount,
          SubTotal: SubTotal.toFixed(2),
          general_note: general_note,
          is_general_note: is_general_note,
          Inactiveproduct: inactiveproduct,
          not_available_on_zip: res.data.not_available_on_zip,
        });
      } else if (res.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, this, (myThis) => {
            myThis.FetchCartItemList(listIndex);
          });
        }
      }

      if (res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      }

      let addressdata =
        localStorage.getItem("SelectedAddress") !== undefined &&
        localStorage.getItem("SelectedAddress") !== "undefined"
          ? JSON.parse(localStorage.getItem("SelectedAddress"))
          : "";

      if (addressdata !== null) {
        this.setState({ selectedAddress: addressdata });
      } else if (
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup" &&
        finalData.length !== 0 &&
        JSON.parse(localStorage.getItem("slot"))?.schedule === 3 &&
        (localStorage.getItem("isLogin") === "true" ||
          localStorage.getItem("Guest_userid") !== null)
      ) {
        this.setState({ setOpenAddresspopup: true });
      }

      // const promises = finalData.flatMap((category) =>
      //   category.items.map((item) => {
      //     let localUrl = item?.cloudinary_images_local
      //     let globalUrl =item?.cloudinary_images_global 
      //     let currentUrl =
      //       item.image !== null
      //         ? item.image[0] === "/"
      //           ? `${ApiUrls.BASH_URL}/media${item.image}`
      //           : item.image
      //         : NO_IMAGE;

      //     return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
      //       item.image = url.url;
      //       item.valid = url.valid;
      //       return item;
      //     });
      //   })
      // );

      // Promise.all(promises).then((updatedItems) => {
        // finalData.forEach((category) => {
        //   category.items = category.items.map((item) =>
        //     updatedItems.find((updatedItem) => updatedItem.id === item.id)
        //   );
        // });

        this.setState({
          cartCategoryList: finalData,
          finalCartCategoryList: finalData,
          cartItemList: cartItemList,
          cartLoader: false,
          CartCount: CartCount,
          mainLoader: false,
        });
      // });
    });
  };

  UpdatePopularCartItemFunctionlity = async (
    product_id,
    added_quantity,
    is_case,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      container_type: typeof container_type === "string" ? container_type : "",
    };

    let PopularProductList = this.state.PopularProductList;

    let SubTotal = 0;
    this.setState({ disabled: true });
    await UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res?.errors?.status === 400) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.UpdatePopularCartItemFunctionlity(
              product_id,
              added_quantity,
              is_case,
              container_type
            );
          });
        }
      } else {
        let productList = this.state.PopularProductList;
        let indx = productList.map((ele) => ele.id).indexOf(product_id);
        if (indx > -1) {
          var added_qtyInd =
            productList[indx]["added_quantity_is_case"].indexOf(is_case);
          if (added_qtyInd > -1) {
            productList[indx]["added_quantity"][added_qtyInd] = added_quantity;
          } else {
            productList[indx]["added_quantity"].push(added_quantity);
            productList[indx]["added_quantity_is_case"].push(is_case);
          }
        }
        this.setState({ PopularProductCart: productList, disabled: false });
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
      }
    });
  };
  UpdateCartItemFunctionlity = (
    product_id,
    category_id,
    quantity,
    is_case,
    index,
    note,
    container_type
  ) => {
    let formValue = {};
    if (product_id !== null)
      formValue = {
        product_id: product_id,
        category_id: category_id,
        quantity: quantity,
        is_case: is_case,
        note: note,
        container_type: container_type,
      };
    else {
      formValue = {
        quantity: quantity,
        is_case: false,
        category_id: category_id,
        is_unlist_item: true,
        cart_id: index,
      };
    }
    let cartCategoryList = this.state.cartCategoryList;
    let CartCount = this.state.CartCount;
    this.setState({
      cartSubtotalLoader: true,
      listIndex: index,
      cartLoader: true,
    });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.UpdateCartItemFunctionlity(
                formValue.product_id,
                formValue.category_id,
                formValue.quantity,
                formValue.is_case,
                formValue.note,
                formValue.container_type
              );
            }
          );
        }
      } else if (res.errors && res.errors.status === 400) {
        this.createSuccessMsg("error", res.errors.data.errors);
        for (let i = 0; i < cartCategoryList.length; i++) {
          for (let j = 0; j < cartCategoryList[i].items.length; j++) {
            if (cartCategoryList[i].items[j].id === index) {
              cartCategoryList[i].items[j].total = res.subtotal;
              cartCategoryList[i].items[j].qty = quantity;
              cartCategoryList[i].items[j].disabled = false;
            }
          }
        }
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
      } else {
        for (let i = 0; i < cartCategoryList.length; i++) {
          for (let j = 0; j < cartCategoryList[i].items.length; j++) {
            if (cartCategoryList[i].items[j].id === index) {
              cartCategoryList[i].items[j].total = res.subtotal;
              cartCategoryList[i].items[j].disabled = false;
            }
          }
        }
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
        let productList = this.state.PopularProductList;
        let indx = productList.map((ele) => ele.id).indexOf(product_id);
        if (indx > -1) {
          var added_qtyInd =
            productList[indx]["added_quantity_is_case"].indexOf(is_case);
          if (added_qtyInd > -1) {
            productList[indx]["added_quantity"][added_qtyInd] = quantity;
          } else {
            productList[indx]["added_quantity"].push(quantity);
            productList[indx]["added_quantity_is_case"].push(is_case);
          }
        }
        this.setState({ PopularProductCart: productList });
      }
      this.setState({
        cartSubtotalLoader: false,
        cartCategoryList,
        cartLoader: false,
        CartCount: CartCount,
      });
    });
  };

  increaseItemCount = (
    e,
    product_id,
    category_id,
    is_case,
    index,
    note,
    container_type
  ) => {
    let SubTotal = 0;
    let cartCategoryList = this.state.cartCategoryList;
    for (let i = 0; i < cartCategoryList.length; i++) {
      for (let j = 0; j < cartCategoryList[i].items.length; j++) {
        if (cartCategoryList[i].items[j].id === index) {
          cartCategoryList[i].items[j].qty =
            cartCategoryList[i].items[j].qty + 1;
          cartCategoryList[i].items[j].disabled = true;
          this.UpdateCartItemFunctionlity(
            product_id,
            category_id,
            cartCategoryList[i].items[j].qty,
            is_case,
            index,
            note,
            container_type
          );
        }
      }
    }
    // this.setState({ cartCategoryList });
  };

  countItemDecrease = (
    e,
    product_id,
    category_id,
    is_case,
    index,
    note,
    container_type
  ) => {
    let cartCategoryList = this.state.cartCategoryList;
    let TotalQty = 0;
    let ItemIndex = 0;

    for (let i = 0; i < cartCategoryList.length; i++) {
      for (let j = 0; j < cartCategoryList[i].items.length; j++) {
        if (cartCategoryList[i].items[j].id === index) {
          cartCategoryList[i].items[j].qty =
            cartCategoryList[i].items[j].qty - 1;
          cartCategoryList[i].items[j].disabled = true;
          TotalQty = cartCategoryList[i].items[j].qty;
          ItemIndex = cartCategoryList[i].items[j].id;
        }
      }
    }
   
    if (ItemIndex === index) {
      if (TotalQty > 0) {
        this.UpdateCartItemFunctionlity(
          product_id,
          category_id,
          TotalQty,
          is_case,
          index,
          note,
          container_type,
          ItemIndex
        );
      } else {
        this.DeleteCartItem(product_id, is_case, index, container_type, false);
      }
    }
    this.setState({
      TotalQty: TotalQty,
      ItemIndex: ItemIndex,
      cartCategoryList,
    });
  };

  DeleteCartItem = (
    product_id,
    is_case,
    index,
    container_type,
    processed_order = false
  ) => {
    let cartCategoryList = this.state.cartCategoryList;
    let formValue = {};
    if (product_id !== null)
      formValue = {
        product_id: product_id,
        is_case: is_case,
        container_type: container_type ? container_type : "",
      };
    else {
      formValue = {
        cart_id: index,
        is_case: is_case,
        container_type: container_type ? container_type : "",
      };
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data && res.status === 200) {
        let cartCategoryList = this.state.cartCategoryList;
        for (let i = 0; i < cartCategoryList.length; i++) {
          for (let j = 0; j < cartCategoryList[i].items.length; j++) {
            if (cartCategoryList[i].items[j].id === index) {
              cartCategoryList[i].items.splice(j, 1);
            }
          }
        }
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
        this.FetchPopularProductList(this.state.popularPage);
        if (processed_order) {
          if (localStorage.getItem("frontend_animation") !== "open") {
            const months = [
              "jan",
              "feb",
              "mar",
              "apr",
              "may",
              "jun",
              "jul",
              "aug",
              "sep",
              "oct",
              "nov",
              "dec",
            ];
            let slotData = localStorage.getItem("slot")
              ? JSON.parse(localStorage.getItem("slot"))
              : "";
            const selecteddate = slotData.datetime?.slice(
              3,
              slotData.datetime.indexOf(" ", 4)
            );
            const year = slotData?.actualdate?.slice(-4);
            const month =
              months.indexOf(slotData.datetime?.slice(0, 3).toLowerCase()) + 1;
            const datecurr = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            this.OrderAddressAndDeliveryPost(
              this.state.selectedAddress,
              slotData?.schedule === 2 ? true : false
            );
            if (window.screen.availWidth > 900) this.FinalOrderCardknoxVerify();
            else
              this.props.history.push({
                pathname: "/payment",
                state: {
                  delivery_option:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      this.state.selectedAddress?.pickup_type ===
                        "no_date_time")
                      ? "8:00-20:00"
                      : slotData?.schedule === 2 &&
                        this.state.selectedAddress?.pickup_type ===
                          "one_date_time"
                      ? this.state.selectedAddress?.pickup_time
                      : slotData.datetime
                          ?.slice(selecteddate.toString().length > 1 ? 6 : 5)
                          .trim(), // 10:00-12:00
                  delivery_type:
                    slotData.schedule === 1
                      ? "delivery"
                      : slotData.schedule === 2
                      ? "pickup"
                      : "shipping", // pickup, delivery
                  delivery_datetime:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      this.state.selectedAddress?.pickup_type ===
                        "no_date_time")
                      ? `${datecurr[2]}-${datecurr[0]}-${datecurr[1]}`
                      : slotData?.schedule === 2 &&
                        this.state.selectedAddress?.pickup_type ===
                          "one_date_time"
                      ? this.state.selectedAddress.pickup_date
                      : `${year}-${month}-${selecteddate?.trim()}`, // 2020-5-28
                  tipForPacker: this.state.tipForPacker,
                  tipForDeliveryboy: this.state.tipForDeliveryboy,
                  donation: this.state.donation,
                  TotalPayment: this.state.TotalPayment,
                  extra_charges_name:
                    this.state.CardOrderSummary.extra_charges_name,
                  extra_charges_price:
                    this.state.CardOrderSummary.extra_charges_price,

                  //additional_pickup_address:this.state.additonalPickup
                },
              });
          } else
            this.OrderAddressAndDeliveryPost(
              this.state.selectedAddress,
              this.state.additonalPickup
            );
        }
      } else if (res.errors && res.errors.state === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.DeleteCartItem(
                product_id,
                is_case,
                index,
                container_type,
                processed_order
              );
            }
          );
        }
      }
      this.setState({ cartCategoryList, SubCategoryList: [] });
    });
  };
  openAddCountPopularProduct = (
    product_id,
    added_quantity,
    is_case,
    addTocartQty = 1,
    container_type
  ) => {
    added_quantity = parseInt(added_quantity);
    addTocartQty = parseInt(addTocartQty);
    if (added_quantity === 1) {
      this.AddToCartPopularFunctionlity(product_id, is_case);
    } else if (added_quantity > 0 && addTocartQty >= 1) {
      this.UpdatePopularCartItemFunctionlity(
        product_id,
        added_quantity,
        is_case,
        container_type
      );
    } else {
      this.DeleteCartItem(product_id, added_quantity, is_case);
    }
    this.setState({ inputField: false });
  };

  openAddCount = (
    product_id,
    category_id,
    added_quantity,
    is_case,
    index,
    note,
    container_type
  ) => {
    if (added_quantity === "") {
      this.setState({ inputField: true });
      var el = document.getElementById(`${product_id}`);
      el.value = "";
      var event = new Event("input", { target: el, bubbles: true });
      return el ? el.dispatchEvent(event) : false;
    }
    added_quantity = parseInt(added_quantity);
    if (added_quantity > 0) {
      this.UpdateCartItemFunctionlity(
        product_id,
        category_id,
        added_quantity,
        is_case,
        index,
        note,
        container_type
      );
    } else {
      this.DeleteCartItem(product_id, is_case, index, container_type);
    }
    this.setState({ inputField: false });
  };

  changeInputBoxValue = () => {
    this.setState({ inputField: false });
  };

  removeCartFunctionlity = () => {
    let formValue = {};
    window.scrollTo(0, 0);
    RemoveCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res) {
        // this.FetchCartCount();
        localStorage.removeItem("slot");
        localStorage.removeItem("SelectedAddress");
        this.FetchCartOrderSummaryList();
        this.FetchCartItemList(this.state.listIndex);
        this.setState({
          cartCategoryList: [],
          CardOrderSummary: [],
          TotalItemCount: 0,
          openDialog: false,
          SubCategoryList: [],
        });
        // window.location.reload();
      }
    });
  };

  handleRemoveAlertFunctionlity = () => {
    this.setState({ openDialog: false });
  };

  FetchSubCategory = () => {
    let formValues = getSchedule_zip_Cart();
    formValues.zipCode =
      localStorage.getItem("frontend_animation") !== "open"
        ? formValues.zipCode
        : "";
    formValues.scheduleType =
      localStorage.getItem("frontend_animation") !== "open"
        ? formValues.scheduleType
        : "";
    let subCategoryList = [];
    let finalData = [];

    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            if (finalData.length === 0) {
              let mydata = {
                category_id: respone[i]["category_id"],
                category_name: respone[i]["category_name"],
                items: [respone[i]],
              };
              finalData.push(mydata);
            } else {
              var ind = finalData.findIndex(
                (p) => p.category_id === respone[i]["category_id"]
              );
              if (ind > -1) {
                finalData[ind]["items"].push(respone[i]);
              } else {
                let mydata = {
                  category_id: respone[i]["category_id"],
                  category_name: respone[i]["category_name"],
                  items: [respone[i]],
                };
                finalData.push(mydata);
              }
            }
          }

          subCategoryList.push({ label: "All", value: 0 });
          for (let j = 0; j < finalData.length; j++) {
            subCategoryList.push({
              label: finalData[j].category_name,
              value: finalData[j].category_id,
            });
          }
        }
      }
      this.setState({
        SubCategoryList: subCategoryList,
      });
    });
  };

  redirectToPages = () => {
    this.CartFunctionlity();
  };

  redirectToListing = () => {
    
    var addGenaralnote = setTimeout(
      this.AddToCartFunctionlity(
        0,
        1,
        false,
        this.state.is_general_note,
        this.state.general_note,
        ""
      ),
      1000
    );
    this.props.history.push({
      pathname: "/",
    });
  };

  FetchCartOrderSummaryList = (loader = true) => {
    let formdata = getSchedule_zip_Cart();
    let formValues = {
      limit: 7,
    };
    formValues = { ...formValues, ...formdata };
    this.setState({ orderSummeryLoader: loader });
    let TotalPayment = 0;
    let EBTPayment = 0;
    CardOrderSummaryFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let cardOrderSummar = [];
        if (res.data && res.data.result) {
          cardOrderSummar = res.data.result;
          if (cardOrderSummar.subtotal === 0) {
            cardOrderSummar.delivery_charge = 0;
          }
          let extracharges =
            cardOrderSummar.extra_charges_name !== "" &&
            cardOrderSummar.extra_charges_name !== null &&
            cardOrderSummar.extra_charges_price !== 0
              ? cardOrderSummar.extra_charges_price
              : 0;
          EBTPayment = cardOrderSummar.ebt_subtotal;
          if (cardOrderSummar.subtotal === 0) TotalPayment = 0;
          else {
            TotalPayment = (
              cardOrderSummar.subtotal +
              cardOrderSummar.shipping_price +
              cardOrderSummar.Estimated_tax +
              cardOrderSummar.delivery_charge +
              extracharges
            ).toFixed(2);
          }
        } else if (res.errors?._error?.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchCartOrderSummaryList();
              }
            );
          }
        }
        this.setState({
          CardOrderSummary: cardOrderSummar,
          TotalPayment: TotalPayment,
          OriginalPayment: TotalPayment,
          EBTPayment: EBTPayment,
          ebtorderAmount: cardOrderSummar.ebt_subtotal,
          originalebtorderAmount: cardOrderSummar.ebt_subtotal,
        });
        this.setState({ orderSummeryLoader: false });
      }
    );
  };
  allowOnlyNumericsOrDigits = (e) => {
    console.log(this.state, e.target.value);
    if (this.state.originalebtorderAmount >= e.target.value)
      this.setState({ ebtorderAmount: e.target.value });
    if (parseFloat(e.target.value) === parseFloat(this.state.total_price)) {
      let data = this.state.cardData;
      data.map((val) => {
        val.isSelected = false;
      });
      this.setState({
        paymentOption: "ebt",
        chooseCashPayment: false,
        chooseCardPayment: false,
        cardDetailsDesiable: false,
        cardData: data,
      });
    }
  };
  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image =ele?.cloudinary_cat_images_global
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (res.errors && res.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  handleOpnDialog = () => {
    this.setState({ openDialog: true, openSuccessModal_msg: "" });
  };

  handleCloseOpnDialog = () => {
    this.setState({ openDialog: false });
  };
  SortByCategory = (value) => {
    let cartCategoryList = this.state.finalCartCategoryList;
    if (value.value === 0) {
      this.setState({ cartCategoryList: cartCategoryList });
    } else {
      var CatInd = cartCategoryList.findIndex(
        (p) => p.category_id === value.value
      );
      let finalCartCategoryList = cartCategoryList[CatInd];
      this.setState({ cartCategoryList: [finalCartCategoryList] });
    }
  };

  redirectToDetailsPage = (product_name, slug, product_id, addTocart_btn) => {
    this.props.history.push({
      pathname: "/product/" + slug,
      state: {
        addTocart_btn: addTocart_btn,
      },
    });
  };

  onEditModalOpen = (value) => {
    let backupCartInstractioArr = this.state.backupCartInstractioArr;
    let backupCartInstraction = this.state.backupCartInstraction;
    let productCartNote = this.state.productCartNote;
    let final_value = "";

    if (value) {
      final_value = value.split(",");
    }
    if (final_value.length > 0) {
      if (backupCartInstractioArr.includes(final_value[0].trim())) {
        backupCartInstraction = final_value[0];
        final_value.splice(0, 1);
      }
      productCartNote = final_value.length >= 1 ? final_value.join() : "";
    }

    this.setState({
      setOpenCartNote: true,
      productCartNote: productCartNote,
      backupCartInstraction,
    });
  };

  prodcutNote = (event) => {
    this.setState({ productCartNote: event.target.value });
  };
  backupInstractTion = (value) => {
    this.setState({ backupCartInstraction: value });
  };

  OpenDialog = () => {
    this.setState({ setOpenCartNote: true });
  };
  CloseDialog = (value_icon) => {
    let cartCategoryList = this.state.cartCategoryList;
    let productCartNote = this.state.productCartNote;
    let backupCartInstraction = this.state.backupCartInstraction;

    for (let i = 0; i < cartCategoryList.length; i++) {
      for (let j = 0; j < cartCategoryList[i].items.length; j++) {
        if (
          productCartNote &&
          backupCartInstraction &&
          value_icon === "Add_note"
        ) {
          cartCategoryList[i].items[j].note =
            backupCartInstraction + "," + productCartNote;
        } else if (backupCartInstraction && value_icon === "Add_note") {
          cartCategoryList[i].items[j].note = backupCartInstraction;
        } else if (productCartNote && value_icon === "Add_note") {
          cartCategoryList[i].items[j].note = productCartNote;
        } else if (
          productCartNote &&
          backupCartInstraction &&
          value_icon === "Cancel"
        ) {
          cartCategoryList[i].items[j].note =
            backupCartInstraction + "," + productCartNote;
        } else if (backupCartInstraction && value_icon === "Cancel") {
          cartCategoryList[i].items[j].note = backupCartInstraction;
        } else if (productCartNote && value_icon === "Cancel") {
          cartCategoryList[i].items[j].note = productCartNote;
        } else {
          cartCategoryList[i].items[j].note = "";
        }

        if (cartCategoryList[i].items[j].note && value_icon === "Add_note") {
          this.UpdateCartItemFunctionlity(
            cartCategoryList[i].items[j].product_id,
            cartCategoryList[i].category_id,
            cartCategoryList[i].items[j].qty,
            cartCategoryList[i].items[j].is_case,
            cartCategoryList[i].items[j].id,
            cartCategoryList[i].items[j].note,
            cartCategoryList[i].items[j].container_type
          );
        }
      }
      this.setState({ cartCategoryList, setOpenCartNote: false });
    }
  };

  FetchFavoriteLevelList = () => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: "",
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelData = [];
        if (res.data && res.data.result) {
          favoriteLevelData = res.data.result;
        }
        this.setState({ favoriteLevelData: favoriteLevelData });
      }
    );
  };

  OpenShoppingListDialog = () => {
    this.setState({ setOpen: true });
  };
  CloseListDialog = () => {
    this.setState({ setOpen: false });
  };

  addFavoiteLevelFunctionlity = (favoriteLevel) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          this.FetchFavoriteLevelList();
        }
      }
    );
  };

  saveAddtoCartFunctionlity = (favoriteLevel_id) => {
    let formValues = {
      favoritelabel_id: favoriteLevel_id.toString(),
    };
    let cartCategoryList = this.state.cartCategoryList;
    if (cartCategoryList && cartCategoryList.length > 0) {
      SaveAddtoCartShoopingList(formValues, this.props.dispatch).then((res) => {
        if (res.errors && res.errors.state === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.saveAddtoCartFunctionlity(favoriteLevel_id);
              }
            );
          }
        } else {
          this.FetchFavoriteLevelList();
        }
      });
    } else {
      this.createSuccessMsg("error", "Please add at least one item");
    }
  };

  onTipForPackerChange = (e, value) => {
    const re = /^[+]?\d*(?:[.]\d*)?$/;
    let tipForPacker = this.state.tipForPacker;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    let donation = this.state.donation;

    if (re.test(value)) {
      tipForPacker = value;
      this.setState({ tipForPacker: tipForPacker });
    } else if (value == "") {
      this.setState({ tipForPacker: 0 });
    }
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );

    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
      tips_amount:
        Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation),
    });
  };
  onCardHandleChange = (e) => {
    let { name, value } = e.target;
    let cardType = this.state.cardType;
    if (name === "cardNumber") {
      cardType = getCreditCardType(value);
    } else if (name === "cardMonth" || name === "cardYear") {
      value =
        value.length !== 3
          ? value.replace(/\D/, "")
          : value.slice(0, 2).padStart(2, "0");
    } else if (name === "cardCVV") {
      value =
        value.length !== 5
          ? value.replace(/\D/, "")
          : value.slice(0, 4).padStart(2, "0");
    } else if (name === "isChecked") {
      value = e.target.checked;
    }

    this.setState({
      cardDetailData: { ...this.state.cardDetailData, [name]: value },
      cardType,
    });
  };
  handleCloseCardPopup = () => {
    this.setState({
      paymentOption: "",
      isNewcard: false,
      activeStep: 1,
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
    });
  };
  handleEBTcardno = (e) => {
    let { name, value } = e.target;
    if (name === "isChecked")
      this.setState({ isebtCardSave: e.target.checked });
    else {
      let values =
        e.target.value.toString().length !== 20
          ? e.target.value.toString().replace(/\D/, "")
          : e.target.value.toString().slice(0, 4).padStart(2, "0");
      this.setState({ ebtcardNumber: values });
    }
  };

  redirectToAddressPage = (setUrl) => {
    this.AddToCartFunctionlity(
      0,
      1,
      false,
      this.state.is_general_note,
      this.state.general_note,
      setUrl
    );
    if (localStorage.getItem("frontend_animation") === "no_delivery_pickup") {
      if (this.state.Inactiveproduct && this.state.Inactiveproduct.length > 0)
        this.createSuccessMsg(
          "error",
          "There are some items which is not available"
        );
      else {
        if (window.screen.availWidth > 900) this.FinalOrderCardknoxVerify();
        else
          this.props.history.push({
            pathname: "/payment",
            state: {
              tipForPacker: this.state.tipForPacker,
              tipForDeliveryboy: this.state.tipForDeliveryboy,
              donation: this.state.donation,
              TotalPayment: this.state.TotalPayment,
              extra_charges_name:
                this.state.CardOrderSummary.extra_charges_name,
              extra_charges_price:
                this.state.CardOrderSummary.extra_charges_price,
            },
          });
      }
    } else if (
      this.state.selectedAddress === "" &&
      this.state.selectedslotPopupData === ""
    ) {
      this.createSuccessMsg("error", "Please select the slot");
      this.setState({ setOpenDeliveryPopup: true });
    } else if (this.state.selectedAddress === "") {
      this.setState({ setOpenAddresspopup: true });
    } else if (
      this.state.not_available_on_zip &&
      this.state.not_available_on_zip.length > 0
    )
      this.createSuccessMsg(
        "error",
        "There are some items which is not available on selected zipcode"
      );
    else if (
      this.state.Inactiveproduct &&
      this.state.Inactiveproduct.length > 0
    )
      this.createSuccessMsg(
        "error",
        "There are some items which is not available"
      );
    else this.CheckMinimumOrderForCart(setUrl);
  };

  setGeneralNotes = () => {
    this.AddToCartFunctionlity(
      0,
      1,
      false,
      this.state.is_general_note,
      this.state.general_note
    );
  };
  AddToCartFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    is_general_note,
    general_note,
    setUrl
  ) => {
    let formValue = {};
    if (product_id !== null)
      formValue = {
        product_id: product_id,
        quantity: 1,
        is_case: is_case,
        container_type: "",
        note: "",
        is_general_note: true,
        general_note: general_note,
      };
    else {
      formValue = {
        quantity: this.state.unListedItem.qty,
        is_case: false,
        unlist_product_name: this.state.unListedItem.productDetails,
        is_unlist_item: true,
      };
    }
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddToCartFunctionlity(
                product_id,
                added_quantity,
                is_case,
                is_general_note,
                general_note,
                setUrl
              );
            }
          );
        }
      } else {
        let productList = this.state.PopularProductList;
        let indx = productList.map((ele) => ele.id).indexOf(product_id);
        if (indx > -1) {
          var added_qtyInd =
            productList[indx]["added_quantity_is_case"].indexOf(is_case);
          if (added_qtyInd > -1) {
            productList[indx]["added_quantity"][added_qtyInd] = 1;
          } else {
            productList[indx]["added_quantity"].push(1);
            productList[indx]["added_quantity_is_case"].push(is_case);
          }
        }
        this.setState({ PopularProductCart: productList });
      }
    });
  };
  AddToCartPopularFunctionlity = (product_id, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: 1,
      is_case: is_case,
      container_type: "",
    };
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddToCartFunctionlity(
                product_id,

                is_case
              );
            }
          );
        }
      } else {
        let productList = this.state.PopularProductList;
        let indx = productList.map((ele) => ele.id).indexOf(product_id);
        if (indx > -1) {
          var added_qtyInd =
            productList[indx]["added_quantity_is_case"].indexOf(is_case);
          if (added_qtyInd > -1) {
            productList[indx]["added_quantity"][added_qtyInd] = 1;
          } else {
            productList[indx]["added_quantity"].push(1);
            productList[indx]["added_quantity_is_case"].push(is_case);
          }
        }
        this.setState({ PopularProductCart: productList });
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
      }
    });
  };

  CheckMinimumOrderForCart = (setUrl) => {
    let formValues = {};
    CheckMinimumOrderAPi(formValues, this.props.dispatch).then((res) => {
      if (res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.CheckMinimumOrderForCart(setUrl);
            }
          );
        }
      } else if (res?.data?.errors) {
        // this.createSuccessMsg('error', res.errors._error.data.errors)
        this.checkMinimumOrderModal(res?.data?.errors?.subtotal[0]);
      } else {
        const months = [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ];
        let slotData = localStorage.getItem("slot")
          ? JSON.parse(localStorage.getItem("slot"))
          : "";
        const date = new Date();
        
        const selecteddate = slotData.datetime?.slice(
          3,
          slotData.datetime.indexOf(" ", 4)
        );
        const year = slotData?.actualdate?.slice(-4);
        const month =
          months.indexOf(slotData.datetime?.slice(0, 3).toLowerCase()) + 1;
        const datecurr = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        let cartList = this.state.cartCategoryList;
        let data = [];

        // for (let i = 0; i < cartList.length; i++) {
        //   let items = cartList[i].items;
        //   for (let j = 0; j < items.length; j++) {
        //     if (items[j].out_of_stock) {
        //       data.push(items[j]);
        //     }
        //   }
        // }
        // if (
        //   this.state.storeAddress_data.restrict_by_onhand &&
        //   data.length > 0
        // ) {
        //   this.handlerestrictByOnhand(data);
        // } else {

          if (localStorage.getItem("frontend_animation") !== "open") {
            this.OrderAddressAndDeliveryPost(
              this.state.selectedAddress,
              slotData?.schedule === 2 ? true : false
            );
            if (window.screen.availWidth > 900) this.FinalOrderCardknoxVerify();
            else
              this.props.history.push({
                pathname: "/payment",
                state: {
                  delivery_option:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      this.state.selectedAddress?.pickup_type ===
                        "no_date_time")
                      ? "8:00-20:00"
                      : slotData?.schedule === 2 &&
                        this.state.selectedAddress?.pickup_type ===
                          "one_date_time"
                      ? this.state.selectedAddress?.pickup_time
                      : slotData.datetime
                          ?.slice(selecteddate.toString().length > 1 ? 6 : 5)
                          .trim(), // 10:00-12:00
                  delivery_type:
                    slotData.schedule === 1
                      ? "delivery"
                      : slotData.schedule === 2
                      ? "pickup"
                      : "shipping", // pickup, delivery
                  delivery_datetime:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      this.state.selectedAddress?.pickup_type ===
                        "no_date_time")
                      ? `${datecurr[2]}-${datecurr[0]}-${datecurr[1]}`
                      : slotData?.schedule === 2 &&
                        this.state.selectedAddress?.pickup_type ===
                          "one_date_time"
                      ? this.state.selectedAddress.pickup_date
                      : `${year}-${month}-${selecteddate?.trim()}`, // 2020-5-28
                  tipForPacker: this.state.tipForPacker,
                  tipForDeliveryboy: this.state.tipForDeliveryboy,
                  donation: this.state.donation,
                  TotalPayment: this.state.TotalPayment,
                  extra_charges_name:
                    this.state.CardOrderSummary.extra_charges_name,
                  extra_charges_price:
                    this.state.CardOrderSummary.extra_charges_price,

                  //additional_pickup_address:this.state.additonalPickup
                },
              });
          } else
            this.OrderAddressAndDeliveryPost(
              this.state.selectedAddress,
              this.state.additonalPickup
            );
        }
      // }
    });
  };

  checkMinimumOrderModal = (error_msg) => {
    sweetAlertBox("Attention!!", error_msg, "info");
    // this.setState({
    //   openSuccessModal_msg: error_msg,
    //   openDialog: true,
    // });
  };

  onTipForDeliveryBoy = (e, value) => {
    const re = /^[+]?\d*(?:[.]\d*)?$/;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForPacker = this.state.tipForPacker;
    let donation = this.state.donation;
    if (re.test(value)) {
      tipForDeliveryboy = value;
      this.setState({ tipForDeliveryboy: tipForDeliveryboy });
    } else if (value == "") {
      this.setState({ tipForDeliveryboy: 0 });
    }
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );
    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
      tips_amount:
        Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation),
    });
  };

  onTipForDonation = (e, value) => {
    const re = /^[+-]?\d*(?:[.]\d*)?$/;
    let donation = this.state.donation;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForPacker = this.state.tipForPacker;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    if (re.test(value)) {
      donation = value;
      this.setState({ donation: donation });
    } else if (value == "") {
      this.setState({ donation: 0 });
    }
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );
    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
      tips_amount:
        Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation),
    });
  };

  setTextAreaValue = (value) => {
    let is_general_note = this.state.is_general_note;
    let general_note = this.state.general_note;
    if (value) {
      is_general_note = true;
      general_note = value;
    } else {
      is_general_note = false;
      general_note = "";
    }

    this.setState({
      is_general_note: is_general_note,
      general_note: general_note,
    });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parentIds,
      category_id,
      level
    );
    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(category_id, level);
            }
          );
        }
      }
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  handleSlotName = (data) => {};
  CloseAddressDialog = () => {
    this.setState({ setOpenAddresspopup: false });
  };

  CloseLoginDialog = (cleardata) => {
    if (cleardata) {
      this.setState({ selectedAddress: "" });
    }
    if (
      localStorage.getItem("isLogin") === "false" &&
      localStorage.getItem("Guest_userid") === null
    ) {
      this.props.history.push({
        pathname: "/",
      });
    } else {
      let addressdata =
        localStorage.getItem("SelectedAddress") !== undefined &&
        localStorage.getItem("SelectedAddress") !== "undefined"
          ? JSON.parse(localStorage.getItem("SelectedAddress"))
          : "";

      if (
        addressdata !== null &&
        localStorage.getItem("isLogin") === "true" &&
        cleardata
      ) {
        this.setState({ selectedAddress: addressdata });
      }
      if (
        localStorage.getItem("frontend_animation") === "open" &&
        localStorage.getItem("slot") === null
      )
        this.setState({
          setOpenLoginPopup: false,
          setOpenAddresspopup: false,
          setOpenDeliveryPopup: true,
        });
      else if (
        localStorage.getItem("frontend_animation") === "no_delivery_pickup"
      ) {
        this.setState({
          setOpenLoginPopup: false,
          setOpenAddresspopup: false,
          setOpenDeliveryPopup: false,
        });
      } else
        this.setState({
          setOpenLoginPopup: false,
          setOpenAddresspopup: this.state.selectedAddress === "" ? true : false,
          setOpenDeliveryPopup: false,
        });
      this.FetchCartItemList(this.state.listIndex);
      this.FetchCartOrderSummaryList();
      this.ExtraChargesFunctiolity();
      // this.FetchSettingData();
    }
  };
  BacktoListing = () => {
    this.setState({ MobileViewOrdersummary: false });
  };
  setAddress = (data, additonalPickup) => {
    let selectedaddress = this.state.selectedAddress;
    let setOpentslotpopup = this.state.setOpenslotpupup;
    let setOpenAddresspopup = this.state.setOpenAddresspopup;
    let setOpenDeliveryPopup = this.state.setOpenDeliveryPopup;
    const frontend_animation = localStorage.getItem("frontend_animation");
    let scheduleSlot = this.state.selectedslotPopupData;
    selectedaddress = data;
    if (scheduleSlot === "pickup" || scheduleSlot === "delivery") {
      setOpenAddresspopup = false;

      if (
        data.pickup_type === "no_date_time" ||
        data.pickup_type === "one_date_time"
      ) {
        this.setSlotNamedata(
          data.pickup_type === "no_date_time"
            ? "Store Pick Up "
            : "Store Pick Up " +
                convertTodate(data.pickup_date) +
                " " +
                data.pickup_time,
          data.zipcode,
          2,
          "",
          ""
        );
        localStorage.setItem("SelectedAddress", JSON.stringify(data));
        setOpentslotpopup = false;
        setOpenDeliveryPopup = false;
        this.FetchCartItemList(this.state.listIndex);
        this.FetchCartOrderSummaryList();
      } else {
        setOpentslotpopup = true;
      }
    } else if (scheduleSlot === "shipping") {
      setOpenAddresspopup = false;
      this.setSlotNamedata("Shipping ", data.zipcode, 3, "", "");
      localStorage.setItem("SelectedAddress", JSON.stringify(data));
      setOpenDeliveryPopup = false;
      this.FetchCartOrderSummaryList();
    }
    this.setState({
      selectedAddress: selectedaddress,
      setOpenslotpupup: setOpentslotpopup,
      setOpenAddresspopup: setOpenAddresspopup,
      setOpenDeliveryPopup: setOpenDeliveryPopup,
      additonalPickup: additonalPickup,
    });
  };

  openAddressPopup = (selectedslot) => {
    this.setState({
      setOpenAddresspopup: true,
      selectedslotPopupData: selectedslot,
    });
  };
  openAddressPopupMobile = () => {
    this.setState({
      setOpenAddresspopup: true,
    });
  };
  openDeliveryPickup = () => {
    this.setState({ setOpenDeliveryPopup: true });
  };
  setSlotNamedata = (data, zipCode, scheduleType, dataTime, actualdate) => {
    localStorage.setItem(
      "slot",
      JSON.stringify({
        data: data,
        zipCode: zipCode,
        schedule: scheduleType,
        datetime: dataTime,
        actualdate: actualdate,
      })
    );
    localStorage.setItem(
      "SelectedAddress",
      JSON.stringify(this.state.selectedAddress)
    );

    this.setState({
      ActiveSlotData: {
        data: data,
        zipCode: zipCode,
        schedule: scheduleType,
        datetime: dataTime,
        actualdate: actualdate,
      },
    });
    if (scheduleType === 1) this.FetchCartOrderSummaryList();
    this.setState({ slotName: data });
    this.setState({ setOpenDeliveryPopup: false });
  };
  closeSlotpupupStatus = () => {
    this.setState({ setOpenslotpupup: false });
  };
  OrderAddressAndDeliveryPost = (address, additonalPickup) => {
    let slotData = localStorage.getItem("slot")
      ? JSON.parse(localStorage.getItem("slot"))
      : "";
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    const date = new Date();
    const selecteddate = slotData.datetime?.slice(
      3,
      slotData.datetime?.indexOf(" ", 4)
    );
    const currDate = date.getDate();
    const year = slotData.actualdate?.slice(-4);
    const month =
      months.indexOf(slotData.datetime?.slice(0, 3).toLowerCase()) + 1;
    const sameday = currDate.toString() === selecteddate?.trim();
    const datecurr = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
      .format(new Date())
      .split("/");
    if (slotData === "") {
      this.createSuccessMsg("error", "Please select any specific slot");
      this.openDeliveryPickup();
    } else {
      let formValue = {
        address_id:
          address.id && address?.isUserAddress === undefined ? address.id : 0,
        delivery_option:
          slotData.schedule === 3 ||
          slotData.datetime === "Instant" ||
          (slotData?.schedule === 2 && address?.pickup_type === "no_date_time")
            ? "8:00-20:00"
            : slotData?.schedule === 2 &&
              address?.pickup_type === "one_date_time"
            ? address?.pickup_time
            : slotData.datetime
                ?.slice(selecteddate.toString().length > 1 ? 6 : 5)
                .trim(), // 10:00-12:00
        delivery_type:
          slotData.schedule === 1
            ? "delivery"
            : slotData.schedule === 2
            ? "pickup"
            : "shipping", // pickup, delivery
        delivery_datetime:
          slotData.schedule === 3 ||
          slotData.datetime === "Instant" ||
          (slotData?.schedule === 2 && address?.pickup_type === "no_date_time")
            ? `${datecurr[2]}-${datecurr[0]}-${datecurr[1]}`
            : slotData?.schedule === 2 &&
              address?.pickup_type === "one_date_time"
            ? address.pickup_date
            : `${year}-${month}-${selecteddate?.trim()}`, // 2020-5-28
        sameday_delivery: sameday, //true,false
        confirm_another_day_delivery: !sameday, //true,false
        additional_pickup_address: true,
        is_guest: localStorage.getItem("Guest_userid") !== null,
        guest_user_id: localStorage.getItem("Guest_userid"),
        pickup_type:
          address?.pickup_type === null ? "schedule" : address.pickup_type,
        is_user_address: address?.isUserAddress ? true : false,
        instant_delivery: slotData.datetime === "Instant",
      };

      OrderAddressAndDelivery(formValue, this.props.dispatch).then((res) => {
        if (res.errors && res.errors.status === 400) {
          if (res.errors.data) {
            this.setState({ selectedAddress: "" });
            this.createSuccessMsg("error", res.errors.data.errors);
          }
        } else if (res.errors && res.errors.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.OrderAddressAndDeliveryPost(address, additonalPickup);
              }
            );
          }
        } else if (res.errors && res.errors.status === 405) {
          this.setState({ openDialog: true, dialogMsg: res.errors.data.alert });
          this.chnangeConfirmDayDelivery();
        } else if (res.status === 200 && res.data) {
          if (localStorage.getItem("frontend_animation") !== "open") {
            this.CloseAddressDialog();
          }
          if (localStorage.getItem("frontend_animation") === "open") {
            const datecurr = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              .format(new Date())
              .split("/");
            if (window.screen.availWidth > 900) this.FinalOrderCardknoxVerify();
            else {
              let data = {
                pathname: "/payment",
                state: {
                  delivery_option:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      address?.pickup_type === "no_date_time")
                      ? "8:00-20:00"
                      : slotData?.schedule === 2 &&
                        address?.pickup_type === "one_date_time"
                      ? address?.pickup_time
                      : slotData.datetime
                          ?.slice(selecteddate.toString().length > 1 ? 6 : 5)
                          .trim(), // 10:00-12:00
                  delivery_type:
                    slotData.schedule === 1
                      ? "delivery"
                      : slotData.schedule === 2
                      ? "pickup"
                      : "shipping", // pickup, delivery
                  delivery_datetime:
                    slotData.schedule === 3 ||
                    (slotData?.schedule === 2 &&
                      address?.pickup_type === "no_date_time")
                      ? `${datecurr[2]}-${datecurr[0]}-${datecurr[1]}`
                      : slotData?.schedule === 2 &&
                        address?.pickup_type === "one_date_time"
                      ? address.pickup_date
                      : `${year}-${month}-${selecteddate?.trim()}`, // 2020-5-28
                  tipForPacker: this.state.tipForPacker,
                  tipForDeliveryboy: this.state.tipForDeliveryboy,
                  donation: this.state.donation,
                  pickup_type:
                    address?.pickup_type === null
                      ? "schedule"
                      : address.pickup_type,
                  extra_charges_name:
                    this.state.CardOrderSummary.extra_charges_name,
                  extra_charges_price:
                    this.state.CardOrderSummary.extra_charges_price,
                  // TotalPayment: this.state.TotalPayment
                },
              };
              this.props.history.push(data);
            }
          }
        }
      });
    }
  };
  handleresetAddress = () => {
    console.log("reset");
    this.setState({ selectedAddress: "" });
  };
  OpenLoginPoup = () => {
    this.setState({ setOpenLoginPopup: true });
  };
  FetchPopularProductList = (page) => {
    this.setState({ propularProductLoader: true });
    let formValues = getSchedule_zip();
    formValues.limit =
      document.getElementById("cart-section")?.offsetWidth < 1600 ? 4 : 9;
    formValues.page = page;
    this.setState({
      propularProductLoader: true,
      popularLimit: formValues.limit,
    });
    PopularProductsList(formValues, this.props.dispatch).then((res) => {
      let popularProducts = [];
      let total = 0;
      if (res.data && res.data.result) {
        popularProducts = res.data.result;
        total = res.data.total;
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchPopularProductList(page);
            }
          );
        }
      }
      this.setState({
        PopularProductList: popularProducts,
        propularProductLoader: false,
        popularPage: page,
        propularProductTotal: total,
      });
    });
  };

  handlesetAddress = (data) => {
    localStorage.setItem("SelectedAddress", JSON.stringify(data));
    this.setState({
      selectedAddress: data,
    });
  };
  chosePaymentOption = (value, isNewcard) => {
    let chooseCardPayment;
    let chooseCashPayment;
    let chooseEBTPayment = false;
    let data = this.state.cardData;
    let cardDetailsDesiable = this.state.cardDetailsDesiable;
    let credits = this.state.creditData;
    if (
      parseFloat(this.state.ebtorderAmount) ===
        parseFloat(this.state.TotalPayment) &&
      this.state.ebtisSelected !== ""
    ) {
      return;
    } else if (
      credits.isPaidByCredit &&
      parseFloat(credits.paymentAmount) === parseFloat(this.state.TotalPayment)
    ) {
      return;
    }
    if (value === "ebt") {
      chooseCashPayment = false;
      chooseCardPayment = false;
      cardDetailsDesiable = false;
      chooseEBTPayment = true;
    } else if (value === "cash_payment" || value === "pickup_pay") {
      chooseCashPayment = true;
      chooseCardPayment = false;
      cardDetailsDesiable = true;
    } else {
      chooseCashPayment = false;
      chooseCardPayment = true;
      cardDetailsDesiable = false;
      this.setState({ selectedCardData: "" });
    }

    if (isNewcard || value === "cash_payment") {
      data.map((val) => {
        val.isSelected = false;
      });
    }
    this.setState({
      paymentOption: value,
      chooseCashPayment,
      chooseCardPayment,
      cardDetailsDesiable,
      isNewcard: isNewcard,
      cardData: data,
      chooseEBTPayment,
      activeStep: 2,
    });
  };
  handleSelectCard = (id, key, value = "") => {
    let data = this.state.cardData;
    let selectedData = "";
    let cardDetails = true;
    if (
      parseFloat(this.state.ebtorderAmount) ===
        parseFloat(this.state.TotalPayment) &&
      this.state.ebtisSelected !== ""
    ) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        // if (data[i]["isSelected"] === id && key === "isSelected") {
        //   data[i][key] = "";
        // } else
        data[i][key] =
          key === "isSelected"
            ? id
            : value.length !== 5
            ? value.replace(/\D/, "")
            : value.slice(0, 4).padStart(2, "0");
        selectedData = data[i];
        cardDetails = data[i].isSelected;
      } else data[i][key] = "";
    }
    this.setState({
      cardData: data,
      selectedCardData: selectedData,
      cardDetailsDesiable: cardDetails,
      chooseCashPayment: false,
      chooseCardPayment: false,
      paymentOption: "card_payment",
      activeStep: 2,
    });
  };
  FinalOrderCardknoxVerify = () => {
    this.setState({
      paymentLoader: true,
    });

    let CardOrderSummary = this.state.CardOrderSummary;

    if (
      (this.state.cartItemList.every((ele) => ele.product_id === null) &&
        this.state.TotalPayment <= 0) ||
      this.state.paymentOption === "cash_payment" ||
      (CardOrderSummary.ebt_subtotal > 0 &&
        this.state.TotalPayment === CardOrderSummary.ebt_subtotal &&
        this.state.isShowEBT &&
        this.state.paymentOption === "ebt") ||
      (this.state.is_show_customer_credit &&
        this.state.creditData.isPaidByCredit &&
        parseFloat(this.state.creditData.paymentAmount) ===
          parseFloat(this.state.TotalPayment)) ||
      (this.state.isebtpayment &&
        this.state.creditData.isPaidByCredit &&
        parseFloat(
          parseFloat(this.state.ebtorderAmount) +
            parseFloat(this.state.creditData.paymentAmount)
        ).toFixed(2) == parseFloat(this.state.TotalPayment))
    ) {
      this.FinalOrderSaveFunction("");
    } else if (this.state.paymentOption !== "") {
      let formValues = {};
      const carddata = this.state.selectedCardData;
      const cardD = this.state.cardDetailData;
      if (
        this.state.selectedCardData === "" &&
        cardD.cardNumber !== undefined &&
        cardD.cardNumber !== ""
      ) {
        formValues = {
          card_number: cardD.cardNumber.split(" ").join(""),
          expire_date: cardD.cardMonth + cardD.cardYear,
          cvv: cardD.cardCVV,
          card_holder_name: cardD?.cardHolderName ? cardD?.cardHolderName : "",
          amount: this.state.TotalPayment,
          invoice: Date.parse(new Date()),
        };
      } else if (carddata.token !== undefined && carddata.token !== "") {
        formValues = {
          card_number: carddata.token,
          expire_date: carddata.card_exp_date,
          cvv: carddata.cvv,
          card_holder_name: carddata?.cardHolderName
            ? carddata?.cardHolderName
            : "",
          amount: this.state.TotalPayment,
          invoice: Date.parse(new Date()),
          credit_card_id: carddata.id,
        };
        if (carddata.isauthorize === false)
          formValues = { ...formValues, isauthorize: true };
      } else {
        this.createSuccessMsg("error", "Please select payment method");
        return;
      }

      if (
        this.props.selectedCardData === "" &&
        this.props.cardDetailData.isChecked
      ) {
        this.handleSavingCarddetails(formValues);
      } else {
        this.FinalOrderSaveFunction("");
      }
    } else {
      this.createSuccessMsg("error", "Please select payment method");
    }
  };
  FinalOrderSaveFunction = (xToken = "") => {
    let paymentoption = this.state.paymentOption;
    paymentoption =
      paymentoption +
      (this.props?.customercreditData?.isPaidByCredit ? ",C_Credit" : "");

    if (this.state.isebtpayment) {
      if (paymentoption.split(",").includes("ebt") === false)
        paymentoption = paymentoption + ",ebt";
    }
    const carddata = this.state.selectedCardData;
    const cardD = this.state.cardDetailData;
    let formValues = {
      subtotal_price: this.state.subtotal_price,
      total_price: Number(this.state.TotalPayment).toFixed(2),
      discount: this.state.CardOrderSummary.discount,
      shipping_charges: this.state.CardOrderSummary.shipping_price,
      tax: this.state.CardOrderSummary.Estimated_tax,
      tip_for_packer: this.state.tipForPacker ? this.state.tipForPacker : 0,
      tip_for_delivery_boy: this.state.tipForDeliveryboy
        ? this.state.tipForDeliveryboy
        : 0,
      donation: this.state.donation ? this.state.donation : 0,
      payment_method:
        this.state.cartItemList.every((ele) => ele.product_id === null) &&
        this.state.TotalPayment <= 0
          ? "cash_payment"
          : paymentoption,
      transaction_id:
        this.state.paymentOption === "cash_payment"
          ? null
          : xToken !== ""
          ? xToken
          : null,
      transaction_type: "credit",

      delivery_charges: this.state.delivery_charge,
      no_pic_del:
        localStorage.getItem("frontend_animation") === "no_delivery_pickup"
          ? true
          : false,
      is_guest: localStorage.getItem("Guest_userid") !== null,
      guest_user_id: localStorage.getItem("Guest_userid"),
      ebt_total_price:
        localStorage.getItem("selectedPaymentoption") !== null
          ? this.state.ebtorderAmount
          : 0,
      other_total_price:
        (this.state.ebtorderAmount !== 0 &&
          this.state.isShowEBT &&
          this.state.isebtpayment) ||
        this.state?.customercreditData?.isPaidByCredit
          ? (
              parseFloat(this.state.TotalPayment) -
              parseFloat(this.state.ebtorderAmount) -
              (this.state?.customercreditData?.isPaidByCredit
                ? parseFloat(this.state?.customercreditData?.paymentAmount)
                : 0)
            )?.toFixed(2)
          : localStorage.getItem("selectedPaymentoption") === null ||
            this.state.paymentOption !== "ebt"
          ? parseFloat(this.state.TotalPayment).toFixed(2)
          : 0,
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      extra_charges_price: this.state.CardOrderSummary?.extra_charges_price,
      invoice: Date.parse(new Date()),
      customer_credit_price: this.state?.customercreditData?.isPaidByCredit
        ? this.state?.customercreditData?.paymentAmount
        : 0,
    };
    if (this.state.paymentOption === "card_payment") {
      let cardinfo = {};
      if (cardD.cardNumber !== undefined && cardD.cardNumber !== "") {
        cardinfo = {
          cvv: cardD.cardCVV,
          card_holder_name: cardD?.cardHolderName ? cardD?.cardHolderName : "",
          expire_date: cardD.cardMonth + cardD.cardYear,
          transaction_id: cardD.cardNumber.split(" ").join(""),
          exp: cardD.cardMonth + cardD.cardYear,
        };
      } else if (carddata.token !== undefined && carddata.token !== "") {
        cardinfo = {
          cvv: carddata.cvv,
          card_holder_name: carddata?.cardHolderName
            ? carddata?.cardHolderName
            : "",
          expire_date: carddata.card_exp_date,
          transaction_id: carddata.token,
        };
      }
      formValues = { ...formValues, ...cardinfo };
    }
    let extrachar = {};
    if (
      this.state.CardOrderSummary.extra_charges_price !== null &&
      this.state.CardOrderSummary.extra_charges_price !== 0 &&
      this.state.CardOrderSummary.extra_charges_name !== null &&
      this.state.CardOrderSummary.extra_charges_name !== ""
    ) {
      extrachar = {
        extra_charges_price:
          this.state.CardOrderSummary.extra_charges_price !== null
            ? this.state.CardOrderSummary.extra_charges_price
            : 0,
        extra_charges_name: this.state.CardOrderSummary.extra_charges_name,
      };
    }
    formValues = { ...formValues, ...extrachar };
    if (
      localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) !== null
    ) {
      formValues = {
        ...formValues,
        ...{
          sales_rap_id: parseInt(
            JSON.parse(
              localStorage.getItem(
                "UserDetail" +
                  JSON.parse(localStorage.getItem("Store_address"))?.id
              )
            )?.user_id
          ),
        },
      };
    }

    this.ConfiramCartOrderFunctionlity(formValues);
  };
  ConfiramCartOrderFunctionlity = (formValues) => {
    CartOrderFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.ConfiramCartOrderFunctionlity(formValues);
            }
          );
        }
      } else if (res.data && res.status === 200) {
        this.setState({ paymentLoader: false, order_id: res.data.order_id });
        localStorage.removeItem("selectedPaymentoption");

        this.props.history.push({
          pathname: "/order-success",
          state: {
            order_id: res.data.order_id,
            delivery_option: this.state.deliveryOption,
            guest_user_id: localStorage.getItem("Guest_userid"),
          },
        });
      } else {
        this.createSuccessMsg("error", res.errors.data.errors);
      }
    });
  };
  handleaddebtCard = (cardNumber, iscardsave, isSetref = true) => {
    let formvalue = {
      cardnum: cardNumber?.replace(/\s/g, ""),
      is_card_save: iscardsave,
    };
    this.setState({ mainLoader: true, paymentLoader: true });

    InitiateEBT(formvalue, this.props.dispatch).then((res) => {
      if (res.status === 200) {
        if (res.data.result?.xStatus === "Approved") {
          let xAccuID = res.data.result?.xAccuID;
          let xPinPadURL =
            ApiUrls.BASH_URL +
            "/customer/account/redirect/" +
            JSON.parse(localStorage.getItem("Store_address"))?.id +
            "/false"; //window.location.href//res.data.result?.xPinPadURL;
          this.setState({ xAccuID, xPinPadURL });
          localStorage.setItem("refnum", res.data.result?.xRefNum);
          // if (isSetref)
          //   localStorage.setItem(
          //     "selectedPaymentoption",
          //     res.data.result?.xRefNum
          //   );
          document.getElementById("ebtsubmitbtn").click();
        } else this.createSuccessMsg("error", res.data.message);
      } else if (res?.errors?._error?.status === 400) {
        this.setState({ mainLoader: false });
        this.createSuccessMsg("error", "Please enter correct EBT card no.");
      }
    });
  };
  EnableEditBalance = () => {
    this.setState({
      isebtpayment: !this.stateisebtpayment,
    });
  };
  FetchCallAllAPi = () => {
    this.FetchCartItemList(this.state.listIndex, false);
    this.FetchCategoryList();
    this.FetchCartOrderSummaryList(false);
    this.FetchSubCategory();
    this.FetchCategoryTreeView();
  };
  render() {
    const {
      setUrl,
      cartCategoryList,
      CardOrderSummary,
      CategoryDetails,
      TotalPayment,
      CartCount,
      cartSubtotalLoader,
      listIndex,
      CategoryOption,
      favoriteLevelData,
      not_available_on_zip,
      extraCharges,
      selectedAddress,
      Inactiveproduct,
      PopularProductList,
      chack_is_case,
      storeAddress_data,
      isShowEBT,
      web_customize,
    } = this.state;

    var self = this;
    return (
      <ScrollColorTypography
        component="div"
        className="main-content"
        id="cart-section"
      >
        <Header
          FetchCallAllAPi={() => this.FetchCallAllAPi()}
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartOrderSummaryList={() => this.FetchCartOrderSummaryList()}
          FetchCartItemList={(listIndex) =>
            this.FetchCartItemList(this.state.listIndex)
          }
          FetchCartCount={(listIndex) =>
            this.FetchCartItemList(this.state.listIndex)
          }
          logo={this.state.logo}
          extraCharges={this.state.extraCharges}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
          handleSlotNameData={(data) => this.handleSlotName(data)}
          openAddressPopup={() => this.openAddressPopup()}
          OpenLoginPoup={() => this.OpenLoginPoup()}
          tree_data={this.state.tree_data}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        <SubCategoryModal
          open_inner={this.state.open_inner}
          SubcategoryName={this.state.SubcategoryName}
          // handleInnerOpenDialog={this.handleInnerOpenDialog}
          handleInnerCloseDialog={this.handleInnerCloseDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleOpenDialog={this.handleOpenDialog}
          SubCategoryDetails={this.state.SubCategoryDetails}
          history={this.props.history}
          pageName={this.state.pageName}
          SubSubCategoryDetails={this.state.SubSubCategoryDetails}
          subCategoryLoader={this.state.subCategoryLoader}
          FetchSubCategoryList={(id, level) =>
            this.FetchSubCategoryList(id, level)
          }
          parentIds={this.state.parentIds}
          parent_onlyid={this.state.parent_onlyid}
          parent_level={this.state.parent_level}
        />
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={(cleardata = false) =>
            this.CloseLoginDialog(cleardata)
          }
          isShowclose={true}
          history={this.props.history}
        />
        {this.state.setOpenAddresspopup ? (
          <Address
            setOpenAddresspopup={this.state.setOpenAddresspopup}
            CloseDialog={() => this.CloseAddressDialog()}
            setSelectedAddress={(data, additonalPickup) =>
              this.setAddress(data, additonalPickup)
            }
            openSlotpopup={() => this.openSlotpopup}
            selectedAddress={this.state.selectedAddress}
            selectedslotPopupData={this.state.selectedslotPopupData}
            handlesetAddress={(data) => this.handlesetAddress(data)}
          />
        ) : (
          <></>
        )}
        <DeliveryPopup
          setOpenDeliveryPopup={this.state.setOpenDeliveryPopup}
          CloseDialog={(type, selectedslot) =>
            this.CloseSlotDialog(type, selectedslot)
          }
          setSlotName={(data, zipCode, scheduleType, dataTime, actualdate) =>
            this.setSlotNamedata(
              data,
              zipCode,
              scheduleType,
              dataTime,
              actualdate
            )
          }
          openDeliveryPickup={this.openDeliveryPickup}
          history={this.props.history}
          openAddressPopup={this.openAddressPopup}
          setOpenslotpupup={this.state.setOpenslotpupup}
          setSelectedAddress={this.state.selectedAddress}
          closeSlotpupupStatus={() => this.closeSlotpupupStatus()}
          resetAddress={() => this.handleresetAddress()}
          selectedslotPopupData={this.state.selectedslotPopupData}
          createSuccessMsg={(type, msg) => this.createSuccessMsg(type, msg)}
        />
        {this.state.paymentLoader && (
          <Backdrop
            sx={{
              background: "#ffffff47",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          ></Backdrop>
        )}

        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            {" "}
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="cart_main">
              <div className="cart_wrapper">
                <div
                  className="cartdetails"
                  style={{
                    marginLeft: 0,
                    // storeAddress_data?.related_product_show_or_not ===
                    //   false || PopularProductList.length === 0
                    //   ? 0
                    //   : "",
                  }}
                >
                  {cartCategoryList &&
                  cartCategoryList.length > 0 &&
                  window.screen.availWidth > 900 ? (
                    <>
                      {" "}
                      <div className="px-5 ml-5">
                        <Stepper
                          activeStep={this.state.activeStep}
                          className="px-5 py-2"
                        >
                          {steps.map((label) => (
                            <Step key={label} >
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                      <div class="row mb-3 mt-1" style={{ gap: "1rem" }}>
                        <Paper
                          className="col-3 ml-3 mb-3 mb-sm-0"
                          elevation={4}
                        >
                          {localStorage.getItem("frontend_animation") !==
                            "no_delivery_pickup" &&
                          cartCategoryList.length > 0 ? (
                            <div className=" pt-2">
                              <div
                                className={`order_summ ${
                                  selectedAddress?.addressCount === 1
                                    ? " py-2"
                                    : ""
                                }`}
                              >
                                {" "}
                                <TypographyTextColor
                                  Color="heading"
                                  style={{
                                    // fontWeight: "bolder",
                                    fontSize: "1.5rem",
                                    letterSpacing: "1px",
                                  }}
                                  className="d-flex align-items-center justify-content-between  text-uppercase"
                                  component="h5"
                                >
                                  {localStorage.getItem("slot") &&
                                  JSON.parse(localStorage.getItem("slot"))
                                    ?.schedule === 1
                                    ? "Delivery "
                                    : JSON.parse(localStorage.getItem("slot"))
                                        ?.schedule === 2
                                    ? "Pickup "
                                    : "Shipping "}
                                  Address
                                </TypographyTextColor>
                                {this.state.selectedAddress?.pickup_type ===
                                "one_date_time" ? (
                                  <div style={{ marginLeft: "-5px" }}>
                                    <span>
                                      <AiOutlineFieldTime
                                        style={{ color: "red" }}
                                      />
                                    </span>
                                    <span>
                                      {convertTodate(
                                        this.state.selectedAddress?.pickup_date
                                      )}
                                      &nbsp;({" "}
                                      {this.state.selectedAddress?.pickup_time}{" "}
                                      )
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div
                                className={`order_summ ${
                                  selectedAddress?.addressCount === 1
                                    ? " py-2"
                                    : ""
                                }`}
                              >
                                {localStorage.getItem("slot") &&
                                JSON.parse(localStorage.getItem("slot"))
                                  ?.datetime ? (
                                  <TypographyTextColor
                                    Color="other"
                                    component="h5"
                                    className="d-flex justify-content-between align-items-center px-2"
                                  >
                                    <div>
                                      <AiOutlineFieldTime
                                        style={{
                                          color: "red",
                                          fontSize: "1.5rem",
                                        }}
                                        className="mr-1"
                                      />
                                      {
                                        JSON.parse(localStorage.getItem("slot"))
                                          .datetime
                                      }
                                    </div>
                                    {selectedAddress?.addressCount === 1 ? (
                                      ""
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          this.setState({
                                            setOpenDeliveryPopup: true,
                                          })
                                        }
                                      >
                                        <AiFillEdit />
                                      </IconButton>
                                    )}
                                  </TypographyTextColor>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {selectedAddress ? (
                                <div className="m-auto py-3 px-2">
                                  <div className="d-flex justify-content-between">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="b"
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      {selectedAddress.name
                                        ? selectedAddress.name
                                        : selectedAddress.first_name +
                                          " " +
                                          selectedAddress.last_name}
                                    </TypographyTextColor>
                                    {selectedAddress?.addressCount === 1 ? (
                                      ""
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          this.setState({
                                            setOpenAddresspopup: true,
                                          })
                                        }
                                      >
                                        <AiFillEdit />
                                      </IconButton>
                                    )}
                                  </div>
                                  <TypographyTextFontFamily
                                    component="div"
                                    type="other"
                                  >
                                    {selectedAddress?.address &&
                                    selectedAddress?.address !== ""
                                      ? selectedAddress.address
                                      : selectedAddress.street_name !== "" &&
                                        selectedAddress.street_name !==
                                          undefined
                                      ? selectedAddress.street_name
                                      : "" + " "}{" "}
                                  </TypographyTextFontFamily>
                                  <TypographyTextFontFamily
                                    component="div"
                                    type="other"
                                    style={{ display: "flex", gap: "5px" }}
                                  >
                                    <span
                                      style={{
                                        display:
                                          selectedAddress?.city_name !== null &&
                                          selectedAddress?.city_name !== ""
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {selectedAddress.city_name + ", "}{" "}
                                    </span>
                                    <span
                                      style={{
                                        display:
                                          selectedAddress?.state_name !==
                                            null &&
                                          selectedAddress?.state_name !== ""
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {selectedAddress.state_name}
                                    </span>
                                    <span
                                      style={{
                                        display:
                                          selectedAddress?.zipcode !== null &&
                                          selectedAddress?.zipcode !== ""
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {selectedAddress.zipcode}
                                    </span>
                                  </TypographyTextFontFamily>
                                  <TypographyTextFontFamily
                                    component="div"
                                    type="other"
                                  >
                                    {selectedAddress?.pickup_message !== "" &&
                                    selectedAddress?.pickup_message !== null &&
                                    selectedAddress?.pickup_message !==
                                      "undefined" &&
                                    selectedAddress?.pickup_message !==
                                      undefined ? (
                                      <div
                                        className="cust_home"
                                        // style={{ maxWidth: "190px" }}
                                      >
                                        <span
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={selectedAddress.pickup_message}
                                        >
                                          Message:{" "}
                                          {selectedAddress?.pickup_message
                                            .length > 50
                                            ? selectedAddress?.pickup_message.slice(
                                                0,
                                                50
                                              ) + "..."
                                            : selectedAddress.pickup_message}{" "}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </TypographyTextFontFamily>
                                </div>
                              ) : (
                                ""
                              )}
                              {CartCount && CartCount.item > 0 ? (
                                <>
                                  <Divider />
                                  <div className="p-2">
                                    {this.state.isShowAddnote ? (
                                      <>
                                        <ClickAwayListener
                                          onClickAway={() => {
                                            if (this.state.general_note === "")
                                              this.setState({
                                                isShowAddnote: false,
                                              });
                                          }}
                                        >
                                          <TextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={3}
                                            name="product_note"
                                            onChange={(e) =>
                                              this.setTextAreaValue(
                                                e.target.value
                                              )
                                            }
                                            // style={{ width: 275 }}
                                            className="w-100 mt-2"
                                            placeholder="Notes..."
                                            onBlur={() =>
                                              this.setGeneralNotes()
                                            }
                                            value={this.state.general_note}
                                            autoFocus={true}
                                          />
                                        </ClickAwayListener>
                                      </>
                                    ) : (
                                      <TypographySecondaryColor
                                        className="addNotes"
                                        onClick={() =>
                                          this.setState({ isShowAddnote: true })
                                        }
                                        Fonttype="other"
                                      >
                                        <NoteAddIcon /> Add note
                                      </TypographySecondaryColor>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Paper>
                        <Paper className="col" elevation={4}>
                          <PaymentSection
                            web_customize={web_customize}
                            ebtorderAmount={this.state.ebtorderAmount}
                            isShowEBT={this.state.isShowEBT}
                            paymentOption={this.state.paymentOption}
                            chooseEBTPayment={this.state.chooseEBTPayment}
                            EBTcarddata={this.state.EBTcarddata}
                            ebtisSelected={this.state.ebtisSelected}
                            ebtAmount={this.state.ebtAmount}
                            isebtpayment={this.state.isebtpayment}
                            originalebtorderAmount={
                              this.state.originalebtorderAmount
                            }
                            is_show_customer_credit={
                              this.state.is_show_customer_credit
                            }
                            creditData={this.state.creditData}
                            chooseCardPayment={this.state.chooseCardPayment}
                            cardData={this.state.cardData}
                            total_price={this.state.TotalPayment}
                            tips_amount={this.state.tips_amount}
                            chooseCashPayment={this.state.chooseCashPayment}
                            chosePaymentOption={(value, isnewCard) =>
                              this.chosePaymentOption(value, isnewCard)
                            }
                            handleSelectCard={(id, key, value) =>
                              this.handleSelectCard(id, key, value)
                            }
                            handleaddebtCard={(cardnumber, iscardsave) =>
                              this.handleaddebtCard(cardnumber, iscardsave)
                            }
                            allowOnlyNumericsOrDigits={
                              this.allowOnlyNumericsOrDigits
                            }
                            EnableEditBalance={() => this.EnableEditBalance()}
                          />
                        </Paper>
                        <Paper className="col-2" elevation={4}>
                          <div
                            className="bttn-fill main_cart_bttn d-flex mb-3 align-items-baseline justify-content-between"
                            onClick={() => {
                              if (this.state.activeStep===2&&
                                cartCategoryList?.length > 0 &&
                                this.state.paymentLoader === false
                              )
                                this.redirectToAddressPage(setUrl);
                            }}
                          >
                            <SecondaryButton
                              className="bttn_grn"
                              style={{opacity:this.state.activeStep===2&&
                                cartCategoryList?.length > 0 ?1:0.6}}
                              disabled={this.state.activeStep===2&&
                                cartCategoryList?.length > 0 ? false : true
                              }
                              Fonttype="other"
                            >
                              {this.state.paymentLoader ? (
                                <CircularProgress
                                  style={{
                                    display: "flex",
                                    color: "#fff",
                                    position: "relative",
                                    right: "-40%",
                                    "& > * + *": {
                                      marginLeft: 2,
                                    },
                                  }}
                                />
                              ) : (
                                " Place Order $" +
                                (TotalPayment > 0 ? TotalPayment : "0.00")
                              )}
                            </SecondaryButton>
                            {/* <div className="cart_total">
                          <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              
                            </TypographyTextColor>
                            </div> */}
                          </div>
                          <Divider />

                          <div className="order_summ">
                            <TypographyTextColor Color="heading" component="h5">
                              Order Summary
                            </TypographyTextColor>
                          </div>
                          <div className="summary_details">
                            {this.state.orderSummeryLoader ? (
                              [...Array(5)].map((x) => <OrderSummarySkeleton />)
                            ) : (
                              <>
                                <ul>
                                  <li>
                                    <div className="summ_det_desc">
                                      <TypographyTextColor
                                        Color="heading"
                                        component="span"
                                      >
                                        Subtotal
                                      </TypographyTextColor>
                                    </div>
                                    <div className="summ_rate">
                                      <TypographyTextColor
                                        Color="other"
                                        component="span"
                                      >
                                        {CardOrderSummary
                                          ? CardOrderSummary.unit
                                          : ""}{" "}
                                        {CardOrderSummary &&
                                        CardOrderSummary.subtotal > 0
                                          ? // isShowEBT
                                            //   ? Number(
                                            //       CardOrderSummary.subtotal -
                                            //         CardOrderSummary.ebt_subtotal
                                            //     ).toFixed(2)
                                            //   :
                                            Number(
                                              CardOrderSummary.subtotal
                                            ).toFixed(2)
                                          : "0.00"}
                                      </TypographyTextColor>
                                    </div>
                                  </li>
                                  {isShowEBT &&
                                  CardOrderSummary?.ebt_subtotal > 0 ? (
                                    <li style={{ fontSize: "12px" }}>
                                      <div className="summ_det_desc">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          SNAP Eligible
                                        </TypographyTextColor>
                                      </div>
                                      <div className="summ_rate">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {CardOrderSummary
                                            ? CardOrderSummary.unit
                                            : ""}{" "}
                                          {CardOrderSummary &&
                                          CardOrderSummary.ebt_subtotal > 0
                                            ? Number(
                                                CardOrderSummary.ebt_subtotal
                                              ).toFixed(2)
                                            : "0.00"}
                                        </TypographyTextColor>
                                      </div>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {CardOrderSummary.discount === 0 ? (
                                    <></>
                                  ) : (
                                    <li>
                                      <div className="summ_det_desc">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="span"
                                        >
                                          Discount
                                        </TypographyTextColor>
                                      </div>
                                      <div className="summ_rate">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                          className="discount_rate"
                                        >
                                          {CardOrderSummary
                                            ? CardOrderSummary.unit
                                            : ""}{" "}
                                          {CardOrderSummary &&
                                          CardOrderSummary.discount > 0
                                            ? Number(
                                                CardOrderSummary.discount
                                              ).toFixed(2)
                                            : "0.00"}
                                        </TypographyTextColor>
                                      </div>
                                    </li>
                                  )}
                                  {this.state.ActiveSlotData?.schedule === 3 &&
                                  cartCategoryList.length > 0 ? (
                                    <li>
                                      <div className="summ_det_desc">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="span"
                                        >
                                          Shipping Charge
                                        </TypographyTextColor>
                                      </div>
                                      <div className="summ_rate">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {CardOrderSummary
                                            ? CardOrderSummary.unit
                                            : ""}{" "}
                                          {CardOrderSummary &&
                                          CardOrderSummary.shipping_price > 0
                                            ? Number(
                                                CardOrderSummary.shipping_price
                                              ).toFixed(2)
                                            : "0.00"}
                                        </TypographyTextColor>
                                      </div>
                                    </li>
                                  ) : (
                                    <></>
                                  )}

                                  <li>
                                    <div className="summ_det_desc">
                                      <TypographyTextColor
                                        Color="heading"
                                        component="span"
                                      >
                                        Estimated Tax
                                      </TypographyTextColor>
                                    </div>
                                    <div className="summ_rate">
                                      <TypographyTextColor
                                        Color="other"
                                        component="span"
                                      >
                                        {CardOrderSummary
                                          ? CardOrderSummary.unit
                                          : ""}{" "}
                                        {CardOrderSummary &&
                                        CardOrderSummary.Estimated_tax > 0
                                          ? Number(
                                              CardOrderSummary.Estimated_tax
                                            ).toFixed(2)
                                          : "0.00"}
                                      </TypographyTextColor>
                                    </div>
                                  </li>
                                  {CardOrderSummary.delivery_charge === 0 ? (
                                    <></>
                                  ) : (
                                    <li>
                                      <div className="summ_det_desc">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="span"
                                        >
                                          Delivery Charges
                                        </TypographyTextColor>
                                      </div>
                                      <div className="summ_rate">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {CardOrderSummary
                                            ? CardOrderSummary.unit
                                            : ""}
                                          {CardOrderSummary
                                            ? Number(
                                                CardOrderSummary.delivery_charge
                                              ).toFixed(2)
                                            : "0.00"}{" "}
                                        </TypographyTextColor>
                                      </div>
                                    </li>
                                  )}
                                  {CardOrderSummary.extra_charges_name !== "" &&
                                  CardOrderSummary.extra_charges_name !==
                                    null &&
                                  CardOrderSummary.extra_charges_price !== 0 &&
                                  cartCategoryList.length > 0 ? (
                                    <li>
                                      <div className="summ_det_desc">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {CardOrderSummary.extra_charges_name}
                                        </TypographyTextColor>
                                      </div>
                                      <div className="summ_rate">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {CardOrderSummary
                                            ? CardOrderSummary.unit
                                            : ""}{" "}
                                          {CardOrderSummary &&
                                          CardOrderSummary.extra_charges_price >
                                            0
                                            ? Number(
                                                CardOrderSummary.extra_charges_price
                                              ).toFixed(2)
                                            : "0.00"}
                                        </TypographyTextColor>
                                      </div>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                                {this.state.extraCharges.tip_for_packer ===
                                  false &&
                                this.state.extraCharges.tip_for_delivery_boy ===
                                  false &&
                                this.state.extraCharges.donation === false ? (
                                  <></>
                                ) : (
                                  <ul className="tip_don">
                                    {this.state.extraCharges.tip_for_packer ===
                                    true ? (
                                      <li>
                                        <div className="summ_det_desc">
                                          <TypographyTextColor
                                            Color="heading"
                                            component="span"
                                          >
                                            Tip for Packer
                                          </TypographyTextColor>
                                        </div>
                                        <div className="summ_rate">
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                            className="dollar"
                                          >
                                            ${" "}
                                          </TypographyTextColor>
                                          <input
                                            type="text"
                                            value={this.state.tipForPacker}
                                            onChange={(e) =>
                                              this.onTipForPackerChange(
                                                e,
                                                e.target.value
                                              )
                                            }
                                            onFocus={(e) => e.target.select()}
                                          />
                                        </div>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.extraCharges
                                      .tip_for_delivery_boy === true ? (
                                      <li>
                                        <div className="summ_det_desc">
                                          <TypographyTextColor
                                            Color="heading"
                                            component="span"
                                          >
                                            Delivery Tip
                                          </TypographyTextColor>
                                        </div>
                                        <div className="summ_rate">
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                            className="dollar"
                                          >
                                            ${" "}
                                          </TypographyTextColor>
                                          <input
                                            type="text"
                                            value={this.state.tipForDeliveryboy}
                                            // onBlur={() => this.onTipForPackerBlur()}
                                            onChange={(e) =>
                                              this.onTipForDeliveryBoy(
                                                e,
                                                e.target.value
                                              )
                                            }
                                            onFocus={(e) => e.target.select()}
                                            className="auto-fill"
                                          />
                                        </div>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.extraCharges.donation ===
                                    true ? (
                                      <li>
                                        <div className="summ_det_desc">
                                          <TypographyTextColor
                                            Color="heading"
                                            component="span"
                                            className="d-flex align-items-center"
                                          >
                                            Donation(
                                            <a
                                              href={
                                                this.state.extraCharges
                                                  .donation_url
                                              }
                                              target="_blank"
                                            >
                                              <TypographyFontColor
                                                border={0}
                                                component={"span"}
                                                className="change-add"
                                              >
                                                {" "}
                                                {
                                                  this.state.extraCharges
                                                    .donation_name
                                                }
                                              </TypographyFontColor>
                                            </a>
                                            )
                                          </TypographyTextColor>
                                        </div>
                                        <div className="summ_rate">
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                            className="dollar"
                                          >
                                            ${" "}
                                          </TypographyTextColor>
                                          <input
                                            type="text"
                                            value={this.state.donation}
                                            // onBlur={() => this.onTipForPackerBlur()}
                                            onChange={(e) =>
                                              this.onTipForDonation(
                                                e,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                )}
                              </>
                            )}
                          </div>
                          {/* <div className="summ_total">
                          <div className="cart_total">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Total
                            </TypographyTextColor>
                          </div>
                          <div className="total_price">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              ${TotalPayment > 0 ? TotalPayment : "0.00"}
                            </TypographyTextColor>
                          </div>
                        </div> */}
                        </Paper>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {window.screen.availWidth > 900 ? (
                    <Divider
                      className="m-2"
                      style={{
                        background: web_customize?.TextColor?.heading,
                        height: "2px",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {storeAddress_data?.related_product_show_or_not &&
                  PopularProductList.length > 0&&window.screen.availWidth > 900 ? (
                    <>
                      <SuggestedCartSection
                        component={"div"}
                        className="p-3 pb-5"
                        id="popular-product-section"
                      >
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ gap: "10px" }}
                        >
                          <TypographyTextColor
                            Color="heading"
                            component="h5"
                            style={{ fontSize: "1.5rem" }}
                          >
                            {localStorage.getItem("name") !== "undefined" &&
                            localStorage.getItem("name") !== null
                              ? JSON.parse(
                                  localStorage.getItem("name")
                                )?.name?.split(" ")[0] + ", "
                              : ""}
                            We have some suggestions just for you
                          </TypographyTextColor>
                          <div className="d-flex" style={{ gap: "5px" }}>
                            {this.state.popularPage > 1 ? (
                              <div>
                                <PrimaryBorderButton
                                  size="small"
                                  onClick={() =>
                                    this.FetchPopularProductList(
                                      this.state.popularPage - 1
                                    )
                                  }
                                >
                                  <ArrowForwardIosIcon
                                    style={{ transform: "rotate(180deg)" }}
                                  />
                                </PrimaryBorderButton>
                              </div>
                            ) : (
                              <></>
                            )}
                            {this.state.propularProductTotal <
                            this.state.popularLimit * this.state.popularPage ? (
                              <></>
                            ) : (
                              <div>
                                <PrimaryBorderButton
                                  size="small"
                                  onClick={() =>
                                    this.FetchPopularProductList(
                                      this.state.popularPage + 1
                                    )
                                  }
                                >
                                  {" "}
                                  <ArrowForwardIosIcon />
                                </PrimaryBorderButton>
                              </div>
                            )}
                          </div>
                        </div>
                        {PopularProductList.length > 0 &&
                        this.state.propularProductLoader === false ? (
                          <>
                            <div className="popular-container">
                              {PopularProductList.map((row, index) => {
                                var c_i_case = chack_is_case[row.id]
                                  ? chack_is_case[row.id]
                                  : false;
                                var check_added_quantity_is_case =
                                  row.added_quantity_is_case.indexOf(c_i_case);
                                var check_is_case_flag = false;
                                if (check_added_quantity_is_case > -1) {
                                  check_is_case_flag = true;
                                } else {
                                  check_is_case_flag = true;
                                  row.added_quantity_is_case.push(c_i_case);
                                  row.added_quantity.push(0);
                                  check_added_quantity_is_case =
                                    row.added_quantity_is_case.indexOf(
                                      c_i_case
                                    );
                                }
                                return (
                                  <ItemCard
                                    extraCharges={this.state.extraCharges}
                                    check_is_case_flag={check_is_case_flag}
                                    check_added_quantity_is_case={
                                      check_added_quantity_is_case
                                    }
                                    itemData={row}
                                    favoriteLevelListData={
                                      this.state.favoriteLevelData
                                    }
                                    index={index}
                                    removeFromfavouriteProduct={(
                                      favoriot_id,
                                      product_id
                                    ) =>
                                      this.removeFromfavouriteProduct(
                                        favoriot_id,
                                        product_id
                                      )
                                    }
                                    addFavoiteLevelFunctionlity={(
                                      value,
                                      product_id
                                    ) =>
                                      this.addFavoiteLevelFunctionlity(
                                        value,
                                        product_id
                                      )
                                    }
                                    history={this.state.history}
                                    activePageName={this.state.activePageName}
                                    addFavoiteProductFunctionlity={(
                                      favoritelabel_id,
                                      id,
                                      added_quantity,
                                      added_quantity_is_case
                                    ) =>
                                      this.addFavoiteProductFunctionlity(
                                        favoritelabel_id,
                                        id,
                                        added_quantity,
                                        added_quantity_is_case
                                      )
                                    }
                                    FetchFavoriteLevelList={(product_id) =>
                                      this.FetchFavoriteLevelList(product_id)
                                    }
                                    HandelChangeTrueWishList={() =>
                                      this.HandelChangeTrueWishList()
                                    }
                                    handlePopOpen={(
                                      id,
                                      slug,
                                      next,
                                      prev,
                                      index
                                    ) =>
                                      this.redirectToDetailsPage("", slug, id)
                                    }
                                    openAddCount={(
                                      id,
                                      added_quantity,
                                      added_quantity_is_case,
                                      containertype
                                    ) =>
                                      this.openAddCountPopularProduct(
                                        id,
                                        added_quantity,
                                        added_quantity_is_case,
                                        containertype
                                      )
                                    }
                                    disabled={this.state.disabled}
                                    inputField={this.state.inputField}
                                    changeInputBoxValue={() =>
                                      this.changeInputBoxValue()
                                    }
                                    ProductCaseFunctionlity={(id, event) =>
                                      this.ProductCaseFunctionlity(id, event)
                                    }
                                  />
                                );
                              })}
                            </div>
                          </>
                        ) : this.state.propularProductLoader ? (
                          <div className="popular-container">
                            {[...Array(this.state.popularLimit)].map((x, i) => (
                              <Grow
                                in={true}
                                {...{ timeout: 1000 }}
                                className="items-container"
                              >
                                <div className="items">
                                  {" "}
                                  <ItemLoaderCard />
                                </div>
                              </Grow>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </SuggestedCartSection>
                      <Divider
                        className="m-2"
                        style={{
                          background: web_customize?.TextColor?.heading,
                          height: "2px",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {window.screen.availWidth > 900 ? (
                    <div className="row">
                      <Paper elevation={4} className="p-3 col">
                        <div className="cart_items">
                          <div className="cart_header">
                            <TypographyTextColor
                              Color="heading"
                              className="cart_desc"
                              component="div"
                            >
                              My Cart{" "}
                              <span>
                                {CartCount ? CartCount.item : ""} Items
                              </span>
                            </TypographyTextColor>
                          </div>
                          <div className="cart_bttn">
                            <div className="empty_cart">
                              <PrimaryBorderButton
                                className="bttn_grn"
                                variant="extended"
                                color="primary"
                                style={{ zIndex: 9 }}
                                onClick={() => this.handleOpnDialog()}
                                Fonttype="other"
                              >
                                Empty Cart
                              </PrimaryBorderButton>
                            </div>
                          </div>
                          <div className="cart_sort">
                            <div className="card_sort_wrapper">
                              <Field
                                name="category_id"
                                size="small"
                                colors="primary"
                                component={renderSelect2Input}
                                options={this.state.SubCategoryList}
                                className="outLine"
                                type="text"
                                rootClass=""
                                onChange={this.SortByCategory.bind(this)}
                                placeholder="Filter..."
                              />
                            </div>
                          </div>
                          <CreateFavorite
                            addFavoiteLevelFunctionlity={(value) =>
                              this.addFavoiteLevelFunctionlity(value)
                            }
                            favoriteLevelData={favoriteLevelData}
                            history={this.props.history}
                            pageName={this.state.pageName}
                            addFavoiteProductFunctionlity={(favoritelabel_id) =>
                              this.saveAddtoCartFunctionlity(favoritelabel_id)
                            }
                            product_id=""
                            FetchFavoriteLevelList={(product_id) =>
                              this.FetchFavoriteLevelList(product_id)
                            }
                            cartCategoryListlength={cartCategoryList.length}
                          />
                          <div className="total_cart_amt">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Total:{" "}
                              {CardOrderSummary ? CardOrderSummary.unit : "$"}
                              {CardOrderSummary && CardOrderSummary.subtotal
                                ? Number(CardOrderSummary.subtotal).toFixed(2)
                                : 0}
                            </TypographyTextColor>
                            <div className="universial_note">
                              {/* {this.state.isShowAddnote ? (
                              <>
                                <ClickAwayListener
                                  onClickAway={() => {
                                    if (this.state.general_note === "")
                                      this.setState({ isShowAddnote: false });
                                  }}
                                >
                                  <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={1}
                                    placeholder="Notes"
                                    value={this.state.general_note}
                                    style={{minHeight:'40px',height:'auto' }}
                                    onChange={(e) =>
                                      this.setTextAreaValue(e.target.value)
                                    }
                                    onBlur={() => this.setGeneralNotes()}
                                    autoFocus={true}
                                  />
                                </ClickAwayListener>
                              </>
                             ) : (
                              <TypographySecondaryColor
                              className="addNotes"
                              onClick={() =>
                                this.setState({ isShowAddnote: true })
                              }
                              Fonttype="other"
                            >
                              <NoteAddIcon /> Add note
                            </TypographySecondaryColor>
                            )}  */}
                            </div>
                          </div>
                        </div>

                        <ScrollColorTypography
                          component="div"
                          className="cart_inner_head"
                          style={{ overflow: "overlay", maxHeight: "80vh" }}
                        >
                          <table>
                            <tbody>
                              {cartCategoryList &&
                              cartCategoryList.length > 0 ? (
                                <tr>
                                  <td className="cart_item">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Item Description
                                    </TypographyTextColor>
                                  </td>
                                  <td
                                    className="cart_quantity"
                                    style={{
                                      width:
                                        storeAddress_data?.related_product_show_or_not ===
                                          false ||
                                        PopularProductList.length === 0
                                          ? ""
                                          : "200px",
                                    }}
                                  >
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Quantity
                                    </TypographyTextColor>
                                  </td>
                                  <td
                                    className="cart_unit"
                                    style={{
                                      width:
                                        storeAddress_data?.related_product_show_or_not ===
                                          false ||
                                        PopularProductList.length === 0
                                          ? ""
                                          : "125px",
                                    }}
                                  >
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Unit Price
                                    </TypographyTextColor>
                                  </td>
                                  <td></td>
                                  <td
                                    className="cart_unit"
                                    style={{
                                      width:
                                        storeAddress_data?.related_product_show_or_not ===
                                          false ||
                                        PopularProductList.length === 0
                                          ? ""
                                          : "125px",
                                    }}
                                  >
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Subtotal
                                    </TypographyTextColor>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {this.state.listLoading ? (
                                <tr>
                                  <td colSpan="4">
                                    <CircularProgress
                                      style={{
                                        display: "flex",
                                        color: "#3d9b7b",
                                        position: "relative",
                                        margin: "0 auto",
                                        "& > * + *": {
                                          marginLeft: 2,
                                        },
                                      }}
                                    />
                                  </td>
                                </tr>
                              ) : cartCategoryList &&
                                cartCategoryList.length > 0 ? (
                                cartCategoryList.map(function (row, index) {
                                  return [
                                    <tr key={row.category_id}>
                                      <td colSpan="4" className="sub_head_cart">
                                        <div className="cart_subheader">
                                          <TypographyTextColor
                                            Color="category"
                                            component="span"
                                          >
                                            {" "}
                                            {row.category_name}
                                          </TypographyTextColor>
                                        </div>
                                      </td>
                                    </tr>,
                                    row.items && row.items.length > 0
                                      ? row.items.map(function (item, key) {
                                          return (
                                            <tr
                                              key={item.id}
                                              data-rowid={item.id}
                                            >
                                              <td>
                                                <div className="cart_inner_details">
                                                  <div className="cart_inner_item">
                                                    <div
                                                      className={
                                                        (extraCharges?.is_show_out_of_stock ===
                                                          true &&
                                                          item.out_of_stock ===
                                                            true) ||
                                                        (Inactiveproduct &&
                                                          Inactiveproduct.length >
                                                            0 &&
                                                          Inactiveproduct.includes(
                                                            item.id
                                                          ))
                                                          ? "smll_img out_of_stock_content"
                                                          : "smll_img"
                                                      }
                                                      onClick={() =>
                                                        self.redirectToDetailsPage(
                                                          item.product_name,
                                                          item.slug,
                                                          item.product_id
                                                        )
                                                      }
                                                      style={{
                                                        position:
                                                          item.out_of_stock ===
                                                          true
                                                            ? "relative"
                                                            : "",
                                                      }}
                                                    >
                                                 
                                                      <img
                                                       src={refineImgurl(item.image , {
                                                      
                                                        w: 100,
                                                      })}
                                                        // src={
                                                        //   item.image
                                                        //     ? item.image
                                                        //     : NO_IMAGE
                                                        // }
                                                        alt=""
                                                        onError={(e) =>
                                                          addDefaultImageSrc(e)
                                                        }
                                                        style={{
                                                          filter:
                                                            extraCharges?.is_show_out_of_stock ===
                                                              true &&
                                                            item.out_of_stock ===
                                                              true
                                                              ? "blur(5px)"
                                                              : "none",
                                                        }}
                                                      />

                                                      {extraCharges?.is_show_out_of_stock ===
                                                        true &&
                                                      item.out_of_stock ===
                                                        true ? (
                                                        <div className="out-of-stock">
                                                          <LabelPrimaryColor
                                                            component={"span"}
                                                            border={2}
                                                            className="text"
                                                          >
                                                            Out Of Stock
                                                          </LabelPrimaryColor>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                    <div className="cart_description">
                                                      <TypographyTextColor
                                                        Color="product"
                                                        component="span"
                                                        onClick={() =>
                                                          self.redirectToDetailsPage(
                                                            item.product_name,
                                                            item.slug,
                                                            item.product_id
                                                          )
                                                        }
                                                      >
                                                        {item.product_name}
                                                      </TypographyTextColor>

                                                      {item.is_case === true ? (
                                                        <div className="case">
                                                          <TypographyBgColor
                                                            component="span"
                                                            className="casetextincart"
                                                          >
                                                            Case
                                                          </TypographyBgColor>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <br />
                                                      {not_available_on_zip &&
                                                      not_available_on_zip.length >
                                                        0 &&
                                                      not_available_on_zip.includes(
                                                        item.id
                                                      ) ? (
                                                        <span className="ProductNotavail_zipTitle">
                                                          * This item is not
                                                          available on this
                                                          zipcode.
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <br />
                                                      {Inactiveproduct &&
                                                      Inactiveproduct.length >
                                                        0 &&
                                                      Inactiveproduct.includes(
                                                        item.id
                                                      ) ? (
                                                        <span className="ProductNotavail_zipTitle">
                                                          * This item is not
                                                          available.
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}

                                                      <ProductNoteChipsview
                                                        ProductDetailsnotes={
                                                          item.note
                                                        }
                                                      />
                                                      <h6>
                                                        {item.qty > 0 &&
                                                        item.is_by_weight ===
                                                          true &&
                                                        item.estimate_weight_per_piece >
                                                          0
                                                          ? "est. " +
                                                            Number(
                                                              item.estimate_weight_per_piece *
                                                                item.qty
                                                            ).toFixed(2) +
                                                            " LB / $" +
                                                            Number(
                                                              item.estimate_weight_per_piece *
                                                                item.qty *
                                                                item.unit_price
                                                            ).toFixed(2)
                                                          : ""}
                                                        {item.container_type
                                                          ? item.container_type
                                                          : ""}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  width:
                                                    storeAddress_data?.related_product_show_or_not ===
                                                      false ||
                                                    PopularProductList.length ===
                                                      0
                                                      ? ""
                                                      : "200px",
                                                }}
                                              >
                                                <div className="cart_price_details">
                                                  <div className=" textrow">
                                                    <Typography
                                                      component={
                                                        TypographySecondaryBgColor
                                                      }
                                                      className="count_box"
                                                    >
                                                      <button
                                                        className="count_btn"
                                                        onClick={(e) =>
                                                          self.countItemDecrease(
                                                            e.currentTarget
                                                              .value,
                                                            item.product_id,
                                                            item.category_id,
                                                            item.is_case,
                                                            item.id,
                                                            item.note,
                                                            item.container_type
                                                          )
                                                        }
                                                        disabled={item.disabled}
                                                      >
                                                        {item.disabled ? (
                                                          <img
                                                            src={LoderImage}
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <span className="icon-minus"></span>
                                                        )}
                                                      </button>
                                                      <div className="count_no">
                                                        {/* {item.qty > 0 ? item.qty : 0} */}
                                                        {self.state
                                                          .inputField ===
                                                        false ? (
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            id={item.product_id}
                                                            value={
                                                              item.qty > 0
                                                                ? item.qty
                                                                : 0
                                                            }
                                                            onChange={(e) =>
                                                              self.openAddCount(
                                                                item.product_id,
                                                                item.category_id,
                                                                e.target.value,
                                                                item.is_case,
                                                                item.id,
                                                                item.note,
                                                                item.container_type
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            id={item.product_id}
                                                            defaultValue={
                                                              item.qty > 0
                                                                ? item.qty
                                                                : 0
                                                            }
                                                            onBlur={() =>
                                                              self.changeInputBoxValue()
                                                            }
                                                            onChange={(e) =>
                                                              self.openAddCount(
                                                                item.product_id,
                                                                item.category_id,
                                                                e.target.value,
                                                                item.is_case,
                                                                item.id,
                                                                item.note,
                                                                item.container_type
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      <button
                                                        className={
                                                          "count_btn count" +
                                                          item.id
                                                        }
                                                        value={item.id}
                                                        entry={index}
                                                        onClick={(e) =>
                                                          self.increaseItemCount(
                                                            e.currentTarget
                                                              .value,
                                                            item.product_id,
                                                            item.category_id,
                                                            item.is_case,
                                                            item.id,
                                                            item.note,
                                                            item.container_type
                                                          )
                                                        }
                                                        disabled={item.disabled}
                                                      >
                                                        {item.disabled ? (
                                                          <img
                                                            src={LoderImage}
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <span className="icon-plus"></span>
                                                        )}
                                                      </button>
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                className="unit_price"
                                                style={{
                                                  width:
                                                    storeAddress_data?.related_product_show_or_not ===
                                                      false ||
                                                    PopularProductList.length ===
                                                      0
                                                      ? ""
                                                      : "125px",
                                                }}
                                              >
                                                <TypographyTextColor
                                                  Color="other"
                                                  component="span"
                                                >
                                                  $
                                                  {item.special_price > 0
                                                    ? item.special_price
                                                    : Number(
                                                        item.unit_price
                                                      ).toFixed(2)}
                                                  {item.is_by_weight === true
                                                    ? " / LB"
                                                    : ""}
                                                </TypographyTextColor>
                                              </td>
                                              <td>
                                                {" "}
                                                {self.state?.isShowEBT &&
                                                item.is_ebt ? (
                                                  <img
                                                    src={EBTLOGO}
                                                    style={{
                                                      width: "45px",
                                                      padding: "5px",
                                                    }}
                                                    alt="ebtlogo"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td
                                                className="unit_price"
                                                style={{
                                                  width:
                                                    storeAddress_data?.related_product_show_or_not ===
                                                      false ||
                                                    PopularProductList.length ===
                                                      0
                                                      ? ""
                                                      : "125px",
                                                }}
                                              >
                                                {cartSubtotalLoader &&
                                                listIndex === item.id ? (
                                                  <div className="d-flex justify-content-end">
                                                    <CommonSkeleton
                                                      width="80px"
                                                      height="50px"
                                                    />
                                                  </div>
                                                ) : (
                                                  <TypographyTextColor
                                                    Color="other"
                                                    component="span"
                                                  >
                                                    $
                                                    {item.total > 0
                                                      ? Number(
                                                          item.total
                                                        ).toFixed(2)
                                                      : "0.00"}
                                                  </TypographyTextColor>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      : "",
                                  ];
                                })
                              ) : (
                                <div className="emtpyCartMsg">
                                  There are no items!
                                </div>
                              )}
                            </tbody>
                          </table>
                        </ScrollColorTypography>
                      </Paper>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="cart_inner_head">
                    <CartListing
                      selectedAddress={selectedAddress}
                      self={self}
                      cartCategoryList={cartCategoryList}
                      Inactiveproduct={Inactiveproduct}
                      MobileViewOrdersummary={this.state.MobileViewOrdersummary}
                      CardOrderSummary={CardOrderSummary}
                      TotalPayment={TotalPayment}
                      orderSummeryLoader={this.state.orderSummeryLoader}
                      ActiveSlotData={this.state.ActiveSlotData}
                      extraCharges={this.state.extraCharges}
                      tipForPacker={this.state.tipForPacker}
                      donation={this.state.donation}
                      tipForDeliveryboy={this.state.tipForDeliveryboy}
                      redirectToListing={() => this.redirectToListing()}
                      handleOpnDialog={() => this.handleOpnDialog()}
                      onTipForDonation={(e, value) =>
                        this.onTipForDonation(e, value)
                      }
                      onTipForDeliveryBoy={(e, value) =>
                        this.onTipForDeliveryBoy(e, value)
                      }
                      onTipForPackerChange={(e, value) =>
                        this.onTipForPackerChange(e, value)
                      }
                      openAddressPopupMobile={() =>
                        this.openAddressPopupMobile()
                      }
                      BacktoListing={() => this.BacktoListing()}
                      DeleteCartItem={(
                        product_id,
                        is_case,
                        id,
                        container_type
                      ) =>
                        this.DeleteCartItem(
                          product_id,
                          is_case,
                          id,
                          container_type
                        )
                      }
                      isShowEBT={this.state.isShowEBT}
                    />
                  </div>
                </div>
                {/* <div className="cart_main_order"> */}

                <div className="cart_order_bttn">
                  <div className="bttn-fill main_cart_bttn"></div>

                  <div className="mobile_cart_order">
                    <div className="order_rate">
                      <TypographyTextColor Color="heading" component="p">
                        ${TotalPayment}
                      </TypographyTextColor>
                      {/* <TypographyFontColor component={"span"} border={0}>
                          DETAILS
                        </TypographyFontColor> */}
                    </div>
                    <div
                      className="bttn-fill"
                      onClick={() => this.redirectToPages()}
                    >
                      {CartCount && CartCount.item > 0 ? (
                        <div
                          onClick={() => {
                            if (this.state.MobileViewOrdersummary) {
                              // this.handleOrderPayment()
                              this.redirectToAddressPage(setUrl);
                            } else {
                              this.setState({ MobileViewOrdersummary: true });
                            }
                          }}
                        >
                          <SecondaryButton
                            component={Link}
                            className="bttn_grn"
                            disabled={TotalPayment > 0 ? false : true}
                            Fonttype="other"
                          >
                            {this.state.MobileViewOrdersummary
                              ? "Payment"
                              : "Checkout"}
                          </SecondaryButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </section>
            <Footer emailSubscribe={this.state.emailSubscribe} />
          </div>
        )}
        <Snackbar
          open={this.state.success ? this.state.success : this.state.error}
          onClose={this.handleClose}
          autoHideDuration={800}
          className={this.state.success ? "success" : "error"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <i className="icon-close"></i>
              </IconButton>
            </React.Fragment>
          }
          message={
            <React.Fragment>
              {this.state.success ? (
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{this.state.success}</p>
                  </div>
                </div>
              ) : (
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{this.state.error}</p>
                  </div>
                </div>
              )}
            </React.Fragment>
          }
        />

        {cartCategoryList.length > 0 ? (
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.handleCloseOpnDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.state.openSuccessModal_msg ? (
              <>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {this.state.openSuccessModal_msg !== ""
                      ? this.state.openSuccessModal_msg
                      : ""}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleCloseOpnDialog()}
                    color="primary"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure want to remove all your added items?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleCloseOpnDialog()}
                    color="primary"
                  >
                    Disagree
                  </Button>
                  <Button
                    onClick={() => this.removeCartFunctionlity()}
                    color="primary"
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        ) : (
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.handleCloseOpnDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your cart is empty.Please add some item.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleRemoveAlertFunctionlity()}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <form
          action="https://test.paysecure.acculynk.net/PINpad"
          method="post"
          style={{ display: "none", opacity: 0 }}
        >
          <label>AccuId:</label>
          <input
            type="text"
            id="AccuId"
            name="AccuId"
            value={this.state.xAccuID}
          />
          <label>AccuReturnURL:</label>
          <input
            type="text"
            id="AccuReturnURL"
            name="AccuReturnURL"
            value={this.state.xPinPadURL}
          />
          <label>AccuLanguage:</label>
          <input
            type="text"
            id="AccuLanguage"
            name="AccuLanguage"
            value="en-US"
          />
          <input type="submit" value="Submit" id="ebtsubmitbtn" />
        </form>
        <CardsavePopup
          createSuccessMsg={(type, msg) => this.createSuccessMsg(type, msg)}
          FinalOrderCardknoxVerify={() => this.redirectToAddressPage(setUrl)}
          cardType={this.state.cardType}
          onCardHandleChange={(e) => this.onCardHandleChange(e)}
          cardDetailData={this.state.cardDetailData}
          isopen={this.state.isNewcard}
          handleClose={() => this.handleCloseCardPopup()}
          paymentLoader={this.state.paymentLoader}
          type="paymentpage"
          paymentOption={this.state.paymentOption}
          ebtcardNumber={this.state.ebtcardNumber}
          handleEBTcardno={(e) => this.handleEBTcardno(e)}
          isebtCardSave={this.state.isebtCardSave}
          handleaddebtCard={(cardnumber, iscardsave) =>
            this.handleaddebtCard(cardnumber, iscardsave)
          }
        />
      </ScrollColorTypography>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(reduxForm({ form: "Cart" })(Cart));
