import { Chip } from "@mui/material";
import React from "react";

const ProductNoteChipsview = (props) => {
  const { ProductDetailsnotes } = props;
  return (
    <>
    
      {ProductDetailsnotes===null||ProductDetailsnotes===undefined||ProductDetailsnotes===""?"":ProductDetailsnotes.split("~")[0] === "" ? (
        <Chip
          label={ProductDetailsnotes.split("~")[1]}
          size={props.size?props.size:"small"}
          variant="outlined"
        />
      ) : ProductDetailsnotes.split("~").length ===
        1 ? (
        <Chip
          label={ProductDetailsnotes}
          size="small"
          variant="outlined"
        />
      ) : (
        <>
          {" "}
          <Chip
            label={
              ProductDetailsnotes.split("~")[0]
            }
            size="small"
            variant="outlined"
            style={{ marginRight: '6px' }}
          />
          <Chip
            label={
              ProductDetailsnotes.split("~")[1]
            }
            size="small"
            variant="outlined"
          />
        </>
      )}
    </>
  );
};

export default ProductNoteChipsview;
