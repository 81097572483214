import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as Icons from "react-icons/fa";
import DefaultImg from "../../assets/images/grocery-store.png";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import { Typography } from "@mui/material";
import {
  ScrollColorTypography,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColorDimmed,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "./UiDesign";

const sideIconFixedStyle = {
  overflow: "auto",
  position: "fixed",
  height: "100vh",
};
const MoblieViewCategoryListing = (props) => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const { CategoryDetails,store_Data } = props;

  const handleChange = (panel) => {
    if (expanded === panel) setExpanded("");
    else setExpanded(panel);
  };
  const handleSelectedCategory = (data) => {
    setSelectedCategory(data);
  };

  return (
    <ScrollColorTypography component="div" className="mob-accordiancategory d-flex">
      <div
        className="mob-category-icon-container"
        style={selectedCategory !== null ? sideIconFixedStyle : {}}
      >
        {selectedCategory !== null ? (
          <div className="newCategory-item-icon">
            <ChevronRightIcon
              onClick={() => {
                handleSelectedCategory(null);
              }}
              style={{
                transform: " rotate(180deg)",
                fontSize: "2.5rem",
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {CategoryDetails?.length > 0 ? (
          CategoryDetails.map((val, i) => {
            return (
              <Typography
                component={
                  selectedCategory !== null && selectedCategory?.id === val.id
                    ? TypographyPrimaryBgColorDimmed
                    : "div"
                }
                className="newCategory-item-icon"
                key={i}
                onClick={() => {
                  if (val.has_child===true) {
                    handleSelectedCategory(val);
                  } else {
                    props.FetchProduct(val?.id, val.has_child, val);
                  }
                }}
                style={{
                  borderRight:
                    selectedCategory !== null ? "1px solid #e0e0e0" : "",
                }}
              >
                {val.icon_image === null || val.icon_image === "" ? (
                  store_Data?.is_show_category_icon===true?
                  <img
                    src={DefaultImg}
                    alt="default"
                    style={{
                      width: "100%",
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />:<div style={{ width: "30px",height:'30px'}}></div>
                ) : Icons[val.icon_image] === undefined ? (
                  <img
                    src={val.icon_image?val.icon_image[0]==="/"||val.icon_image?.startsWith("upload")?ApiUrls.BASH_URL + "/media/" + val.icon_image:val.icon_image:""}
                    alt="image1"
                    style={{
                      width: "100%",
                      height: "40px",
                      mixBlendMode: "multiply",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  React.createElement(Icons[val.icon_image], {
                    style: { fontSize: "2rem" },
                  })
                )}
              </Typography>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <div
        className="mob-category-list"
        style={{ marginLeft: selectedCategory !== null ? "90px" : "" }}
      >
        {selectedCategory === null && CategoryDetails?.length > 0 ? (
          CategoryDetails.map((val, i) => {
            return (
              <TypographyTextColor
                component="div"
                Color="category"
                className="newCategory-item"
                onClick={() => {
                  if (val.has_child===true) {
                    handleSelectedCategory(val);
                  } else {
                    props.FetchProduct(val?.id, val.has_child, val);
                  }
                }}
              >
                <span>{val.name}</span>
                {val.has_child===true ? <ChevronRightIcon /> : <></>}
              </TypographyTextColor>
            );
          })
        ) : (
          <></>
        )}
        {selectedCategory !== null ? (
          <>
            <ul>
              <li style={{ justifyContent: "flex-start" }}>
                <TypographyTextColor
                  component="div"
                  Color="category"
                  onClick={() =>
                    props.FetchProduct(
                      selectedCategory?.id,
                      selectedCategory.has_child,
                      selectedCategory
                    )
                  }
                >
                  {" "}
                  {selectedCategory.name}
                </TypographyTextColor>
              </li>
              {selectedCategory.has_child===true ? (
                selectedCategory.childItem.map((ele, idx) => {
                  return (
                    <>
                      {ele.has_child===true ? (
                        <>
                          <TypographyTextColor
                            component="li"
                            Color="category"
                            key={idx}
                            onClick={() => handleChange(ele.name)}
                          >
                            <span>{ele.name}</span>
                            <span
                              style={{
                                transform:
                                  expanded === ele.name
                                    ? " rotate(180deg)"
                                    : "",
                              }}
                            >
                              <ExpandMoreIcon />
                            </span>
                          </TypographyTextColor>
                          {expanded === ele.name ? (
                            <ul style={{ background: "#dedede61" }}>
                              {/* <TypographyTextColor
                                component="li"
                                Color="category"
                                className="ml-3"
                                onClick={() =>
                                  props.FetchProduct(
                                    ele?.id,
                                    ele.has_child,
                                    selectedCategory
                                  )
                                }
                              >
                                {ele.name}
                              </TypographyTextColor> */}
                              {ele.childItem.map((val, index) => {
                                return (
                                  <TypographyTextColor
                                    component="li"
                                    Color="category"
                                    className="ml-3"
                                    key={index}
                                    onClick={() =>
                                      props.FetchProduct(
                                        val?.id,
                                        val.has_child,
                                        ele
                                      )
                                    }
                                  >
                                    {val.name}
                                  </TypographyTextColor>
                                );
                              })}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <TypographyTextColor
                          component="li"
                          Color="category"
                          key={idx}
                          onClick={() =>
                            props.FetchProduct(
                              ele?.id,
                              ele.has_child,
                              selectedCategory
                            )
                          }
                        >
                          {ele.name}
                        </TypographyTextColor>
                      )}
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </>
        ) : (
          <></>
        )}
      </div>
    </ScrollColorTypography>
  );
};

export default MoblieViewCategoryListing;
