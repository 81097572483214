import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { TbZoomQuestion } from "react-icons/tb";
import {
  SecondaryButton,
  TypographySecondaryBgColor,
  TypographyTextColor,
} from "../UiDesign";
const CantFinditem = (props) => {
  const {
    unListedItem,
    handleChangeInput,
    handleSubmitUnlistedItem,
    handleUnlistedModal,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={unListedItem?.isOpen}
        onClose={handleUnlistedModal}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogContent>
          <span
            role="button"
            className="icon-close d-flex justify-content-end"
            onClick={() => handleUnlistedModal()}
          ></span>
          <div className="mb-4">
            <div className="d-flex justify-content-center">
              <TbZoomQuestion style={{ fontSize: "4rem" }} />
            </div>
            <TypographyTextColor
              Color="other"
              component="h5"
              className="text-center"
            >
              Can't find item
            </TypographyTextColor>
            <TypographyTextColor
              Color="heading"
              component="h5"
              className="text-center"
            >
              Add it here!
            </TypographyTextColor>
          </div>

          <div>
            <div>
              <TextField
                id="outlined-multiline-static"
                // label="Multiline"
                multiline
                rows={5}
                // defaultValue="Default Value"
                name="productDetails"
                onChange={(e) => handleChangeInput(e, "input")}
                className="w-100"
                placeholder="Please add to my order..."
                value={unListedItem?.productDetails}
                autoFocus={true}
              />
              {/* <TextareaAutosize
                aria-label="minimum height"
                minRows={5}
                placeholder="Enter product details"
                className="w-100"
                name="productDetails"
                value={unListedItem?.productDetails}
                // style={{ width: 275 }}
                onChange={(e) => handleChangeInput(e, "input")}
                // onBlur={() => this.setGeneralNotes()}
                autoFocus={true}
              /> */}
            </div>
            <div className="cart_price_details">
              <div className="d-flex justify-content-center mt-3">
                <Typography
                  component={TypographySecondaryBgColor}
                  className="count_box"
                  style={{ height: "2.5rem" }}
                >
                  <button
                    className="count_btn"
                    onClick={(e) => handleChangeInput(e, "minus")}
                    // disabled={item.disabled}
                  >
                    <span className="icon-minus"></span>
                    {/* )} */}
                  </button>
                  <div className="count_no">
                    <input
                      type="text"
                      className="form-control"
                      // id={item.product_id}
                      value={unListedItem?.qty}
                      // onBlur={() =>
                      //   self.changeInputBoxValue()
                      // }
                      onChange={(e) => handleChangeInput(e, "input")}
                    />
                    {/* )} */}
                  </div>
                  <button
                    className={"count_btn count"}
                    // value={item.id}
                    // entry={index}
                    onClick={(e) => handleChangeInput(e, "plus")}
                    // disabled={item.disabled}
                  >
                    <span className="icon-plus"></span>
                    {/* )} */}
                  </button>
                </Typography>
              </div>
              <SecondaryButton
                className={`bttn_grn w-100 my-3 ${
                  unListedItem?.qty === 0 || unListedItem?.productDetails === ""
                    ? " disabled-btn"
                    : ""
                }`}
                disabled={
                  unListedItem?.qty > 0 && unListedItem?.productDetails !== ""
                    ? false
                    : true
                }
                Fonttype="other"
                // style={{ color: "#fff" }}
                onClick={() => {
                  if (unListedItem?.qty === 0) {
                    props.createSuccessMsg(
                      "error",
                      "Please add atleast 1 quantity"
                    );
                  } else handleSubmitUnlistedItem();
                }}
              >
                Add to cart
              </SecondaryButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CantFinditem;
