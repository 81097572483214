import React from "react";
import {
  LabelPrimaryColor,
  TypographyBorder,
  TypographyTextColor,
} from "../UiDesign";
import { Typography } from "@mui/material";
import { formatPhoneNumber } from "../../../utils/renderUtils/renderUtil";

const SearchableSelect = (props) => {

  const {
    openSearchCustomer,
    productSearchLoaer,
    handleLineupCustomer,
    onhandleChangeCustomer,
    customerSearchTxt,
    customerSearchList,
    redirectToLogin,
    onhandleSearchContainer,
    customerAddressView,
    handleBindAddress,
  } = props;
  const webStyle={
    width:'30%',
    padding:'3px',
    borderRadius:'10px'
  }
  const highlightMatch = (text) => {
    const regex = new RegExp(`(${customerSearchTxt})`, 'gi');
    if(text!==null)
   {
    let data=text.toString().split(regex).map((part, index) => (
      regex.test(part) ? <mark key={index}>{part}</mark> : part
    ));
    return data}
    else return text
  };
  return (
    <>
      <Typography
        component={TypographyBorder}
        className="form-group inputBox d-flex "
        style={ window.screen.availWidth <= 900 ? {} : webStyle }
      >
        {" "}
        <input
          type="text"
          autoComplete="off"
          className={`form-control blink-cursor border-0`}
          id="search_input"
          aria-describedby={"simple-popover"}
          value={customerSearchTxt}
          onChange={(e) => onhandleChangeCustomer(e)}
          placeholder={"search by phone/name..."}
        />
        <LabelPrimaryColor
          border={0}
          //   onClick={() => this.searchProductByName()}
          className="d-flex align-items-center pr-2"
        >
          <span
            class={`${
              customerSearchTxt === "" ? "icon-search" : "icon-close"
            } label-icon`}
            style={{ fontWeight: "bolder" }}
            onClick={() => onhandleSearchContainer(false, true)}
          ></span>
        </LabelPrimaryColor>
        {openSearchCustomer === true&&customerSearchTxt!=="" ? (
          <>
            <div
              className="search-results d-none d-lg-block d-md-block"
              style={{ minWidth: "150px", width: "100%", overflow: "auto" }}
            >
              <div className="search-body">
                {productSearchLoaer ? (
                  //   <CustomPreloader />
                  "Loading..."
                ) : (
                  <div>
                    <ul className="customer-list">
                      {customerSearchList.length > 0 ? (
                        customerSearchList.map((ele, i) => {
                          return (
                            <li
                              key={i}
                              onClick={() => handleLineupCustomer(ele.phone)}
                              // onMouseEnter={() => handleBindAddress(ele)}
                              // onMouseLeave={() => handleBindAddress("")}
                            >
                              <div className="px-3 py-1">
                                {/* <TypographyTextColor
                                  component={"div"}
                                  Color="heading"
                                  style={{
                                    color: "GrayText",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {ele.label}<br/>
                                  <span
                                    style={{
                                      color: "GrayText",
                                      fontSize: "0.7rem",
                                    }}
                                  >
                                    ({ele.subLabel})
                                  </span>
                                </TypographyTextColor> */}
                                <div className="cust_address cust_delv_add">
                                  <div className="cust_home">
                                    <TypographyTextColor
                                      component={"div"}
                                      Color="heading"
                                    >
                                      {highlightMatch(ele.name)}
                                    </TypographyTextColor>

                                    <TypographyTextColor
                                      component={"div"}
                                      Color="other"
                                    >
                                      {highlightMatch(ele?.address)}
                                    </TypographyTextColor>
                                    <TypographyTextColor
                                      component={"div"}
                                      Color="other"
                                    >
                                      {ele?.street_name
                                        ? "APT/FLOOR - "
                                        : ""}
                                         {ele?.street_name
                                        ? 
                                        <>{highlightMatch(ele?.street_name)}</>
                                        : ""}
                                    </TypographyTextColor>

                                    <div>
                                      <TypographyTextColor
                                        component={"span"}
                                        Color="other"
                                      >
                                        {ele?.city_name !== null
                                          ? highlightMatch(ele.city_name) 
                                          : ""}
                                           {ele?.city_name !== null
                                          ?  ", "
                                          : ""}
                                      </TypographyTextColor>
                                      <TypographyTextColor
                                        component={"span"}
                                        Color="other"
                                      >
                                        {ele?.state_name !==
                                        null
                                          ? highlightMatch(ele.state_name)
                                          : ""}
                                        {ele?.zipcode !== null
                                          ? " " + highlightMatch(ele.zipcode)
                                          : ""}
                                      </TypographyTextColor>
                                    </div>
                                  </div>
                                  <TypographyTextColor
                                    Color="other"
                                    className="cust_mobile"
                                    component="div"
                                  >
                                    {formatPhoneNumber(
                                      ele.phone
                                    )}
                                  </TypographyTextColor>

                                 
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <li
                          onClick={() => {
                            handleBindAddress("");
                            redirectToLogin(true);
                            onhandleSearchContainer(false, true);
                          }}
                        >
                          <div className="px-4 py-1">
                            <TypographyTextColor
                              component={"div"}
                              Color="heading"
                            >
                              Add New Customer
                            </TypographyTextColor>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* {customerAddressView !== "" ? (
              <div className="side-address-panel">
                <div className="cust_address cust_delv_add text-center">
                  <div className="cust_home">
                    <TypographyTextColor component={"div"} Color="heading">
                      {customerAddressView.name}
                    </TypographyTextColor>

                    <TypographyTextColor component={"div"} Color="other">
                      {customerAddressView?.address}
                    </TypographyTextColor>
                    <TypographyTextColor component={"div"} Color="other">
                      {customerAddressView?.street_name
                        ? "APT/FLOOR - " + customerAddressView?.street_name
                        : ""}
                    </TypographyTextColor>

                    <div>
                      <TypographyTextColor component={"span"} Color="other">
                        {customerAddressView?.city_name !== null
                          ? customerAddressView.city_name + ", "
                          : ""}
                      </TypographyTextColor>
                      <TypographyTextColor component={"span"} Color="other">
                        {customerAddressView?.state_name !== null
                          ? customerAddressView.state_name
                          : ""}
                        {customerAddressView?.zipcode !== null
                          ? " " + customerAddressView.zipcode
                          : ""}
                      </TypographyTextColor>
                    </div>
                  </div>
                  <TypographyTextColor
                    Color="other"
                    className="cust_mobile"
                    component="div"
                  >
                    {formatPhoneNumber(customerAddressView.phone)}
                  </TypographyTextColor>

                  <div></div>
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </>
        ) : (
          ""
        )}
      </Typography>
    </>
  );
};

export default SearchableSelect;
