import React, { Component, createElement } from "react";
import { addDefaultImageSrc } from "../../../../utils/renderUtils/renderUtil";
import { Link } from "react-router-dom";
import NO_IMAGE from "../../../../assets/images/image-coming-soon.jpg";
import { styled } from "@mui/material/styles";
import { Divider, Fade, IconButton, Typography } from "@mui/material";
import {
  LabelPrimaryColor,
  SaleLabelySecondaryColor,
  SecondaryButton,
  TextFormating,
  TypographyBorder,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBorder,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../../UiDesign";
import { AiOutlineDelete, AiOutlineMinus } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  height: "100%",
});
const NumberDisplay = ({ number }) => {
  return number.replace(/\.0(?!\d)/g, "");
};
export default class ItemCardMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      web_config: JSON.parse(localStorage.getItem("web_customize")),
    };
  }
  render() {
    const { web_config } = this.state;

    return (
      <div
        categoty={this.props.categoryIndx}
        className={
          this.props.extraCharges.is_show_out_of_stock === true &&
          this.props.itemData.out_of_stock === true
            ? "items out_of_stock_items mb-2"
            : this.props.extraCharges.is_show_out_of_stock === true &&
              this.props.itemData.out_of_stock_case === true &&
              this.props.itemData.added_quantity_is_case[
                this.props.check_added_quantity_is_case
              ] === true
            ? "items out_of_stock_items mb-2"
            : "items mb-2"
        }
        onClick={(e) =>
          this.props.handlePopOpen(
            this.props.itemData.id,
            this.props.itemData.slug,
            false,
            false,
            this.props.index,
            this.props?.categoryIndx
          )
        }
      >
        {this.props.itemData?.offer_tag !== 0 &&
        this.props.itemData?.offer_tag !== null ? (
          <div className="items-discount">
            {this.props.itemData?.offer_tag
              ? this.props.itemData?.offer_tag + "% OFF"
              : 0 + "% OFF"}
          </div>
        ) : (
          ""
        )}

        {this.props.itemData.is_special === true ? (
          <div
            className="newsalee"
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              this.props.handlePopOpen(
                this.props.itemData.id,
                this.props.itemData.slug,
                false,
                false,
                this.props.index,
                this.props?.categoryIndx
              )
            }
          >
            <Typography component={SaleLabelySecondaryColor}>SALE</Typography>
          </div>
        ) : (
          ""
        )}
        <div
          className="imgbox"
          onClick={(e) =>
            this.props.handlePopOpen(
              this.props.itemData.id,
              this.props.itemData.slug,
              false,
              false,
              this.props.index,
              this.props?.categoryIndx
            )
          }
        >
         
          <img
            onError={(e) => addDefaultImageSrc(e)}
            src={
              this.props.itemData.main_image
                ? this.props.itemData.main_image
                : NO_IMAGE
            }
            alt=""
          />
          {this.props.extraCharges.is_show_out_of_stock === true &&
          this.props.itemData.out_of_stock === true ? (
            <div className="out-of-stock">
              <LabelPrimaryColor component={"span"} border={2} className="text">
                Out Of Stock
              </LabelPrimaryColor>
            </div>
          ) : this.props.extraCharges.is_show_out_of_stock === true &&
            this.props.itemData.out_of_stock_case === true &&
            this.props.itemData.added_quantity_is_case[
              this.props.check_added_quantity_is_case
            ] === true ? (
            <div className="out-of-stock">
              <LabelPrimaryColor component={"span"} border={2} className="text">
                Out Of Stock
              </LabelPrimaryColor>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="item_details card-body">
          <Link to>
            <div
            // onClick={(e) =>
            //   this.props.handlePopOpen(
            //     this.props.itemData.id,
            //     this.props.itemData.slug,
            //     false,
            //     false,
            //     this.props.index,
            //     this.props?.categoryIndx
            //   )
            // }
            >
              <TextFormating
                Color="product"
                component={"div"}
                className="textbox"
              >
                <p>
                  {web_config?.TextTransform?.main === "none"
                    ? this.props.itemData.name
                    : this.props.itemData.name.toLowerCase()}
                </p>
              </TextFormating>
              {this.props.itemData.is_special === true &&this.props.itemData.special_max>0 ? (
                  <div className="items-type">   <TypographyTextColor
                      component="p"
                      Color="other"
                    >
                      {`Max ${this.props.itemData.special_max}`}
                    </TypographyTextColor>
                    </div>
                  ) : (
                    <></>
                  )}
              {this.props.itemData.package?.trim() !== "" ||
              this.props.itemData.brand_name !== null ||
              (this.props.check_is_case_flag &&
                this.props.itemData.added_quantity[
                  this.props.check_added_quantity_is_case
                ]) ? (
                <div className="items-type">
                  <TypographyTextColor
                    component="p"
                    Color="other"
                    className="mb-1 brand-name"
                  >
                    {this.props.itemData.brand_name
                      ? this.props.itemData.brand_name +
                        `${
                          this.props.itemData.package !== " " &&
                          this.props.itemData.package !== "0.0 0" &&
                          this.props.itemData.package !== "0.0 " &&
                          window.screen.availWidth < 700
                            ? " | "
                            : ""
                        }`
                      : ""}
                    {this.props.itemData.package &&
                    this.props.itemData.package !== "0.0 0" &&
                    this.props.itemData.package !== "0.0 " &&
                    window.screen.availWidth < 700 ? (
                      <>
                        <NumberDisplay number={this.props.itemData.package} />
                      </>
                    ) : (
                      ""
                    )}
                  </TypographyTextColor>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Link>
          <div className="text-left">
            <TypographyTextColor
              component={"div"}
              Color="other"
              className="bottom-package"
            >
              {" "}
              {this.props.itemData.package &&
              this.props.itemData.package !== "0.0 0" &&
              this.props.itemData.package !== "0.0 " &&
              this.props.itemData.package.trim() !== "" &&
              window.screen.availWidth > 700 ? (
                <>
                  <NumberDisplay number={this.props.itemData.package} />
                </>
              ) : (
                ""
              )}
            </TypographyTextColor>
            <div>
              {this.props.itemData.is_special === true &&
              this.props.itemData.is_parent === false ? (
                <>
                  <TypographyTextColor
                    Color="other"
                    component="span"
                    style={{ textDecoration: "line-through" }}
                  >
                    {"$" + Number(this.props.itemData.today_price).toFixed(2)}
                  </TypographyTextColor>
                </>
              ) : (
                ""
              )}
              <div>
                <Typography
                  component={
                    this.props.itemData.is_special === true
                      ? TypographySecondaryColor
                      : TypographyTextColor
                  }
                  Color="other"
                  Fonttype="other"
                  className="bottom-price"
                >
                  {this.props.itemData.added_quantity_is_case[
                    this.props.check_added_quantity_is_case
                  ] === true
                    ? "$" + Number(this.props.itemData.case_price).toFixed(2)
                    : this.props.itemData.is_special === true
                    ? this.props.itemData.special_quantity > 0
                      ? this.props.itemData.special_quantity +
                        "/$" +
                        Number(this.props.itemData.special_price).toFixed(2)
                      : "$" +
                        Number(this.props.itemData.special_price).toFixed(2)
                    : "$" + Number(this.props.itemData.today_price).toFixed(2)}
                  {this.props.itemData.is_by_weight === true ? " / LB  " : ""}
                  {/* </h6> */}
                </Typography>
              </div>
              {this.props.itemData.is_offer === true ? (
                this.props.itemData.added_quantity_is_case[
                  this.props.check_added_quantity_is_case
                ] === true ? (
                  <span>
                    {this.props.itemData.unit !== ""
                      ? this.props.itemData.unit
                      : "$"}
                    {Number(
                      this.props.itemData.case_quantity *
                        this.props.itemData.today_price
                    ).toFixed(2)}
                  </span>
                ) : (
                  <span>
                    {this.props.itemData.unit !== ""
                      ? this.props.itemData.unit
                      : "$"}
                    {Number(this.props.itemData.original_price).toFixed(2)}
                  </span>
                )
              ) : (
                ""
              )}
            </div>
            {this.props.extraCharges.is_show_out_of_stock === true &&
            this.props.itemData.out_of_stock === true &&
            this.props.restrictByOnhand === true ? (
              ""
            ) : this.props.itemData.is_case === true ? (
              <div>
                <TypographyPrimaryBgColorDimmed
                  component={"div"}
                  border={1}
                  color="primary"
                  role="button"
                  style={{ width: "fit-content" }}
                  className="button-addtocart button-plusminus case-box d-flex rounded"
                  onClick={(event) => {
                    event.stopPropagation();
                    console.log('hitt');
                    this.props.ProductCaseFunctionlity(
                      this.props.itemData.id,
                      !this.props.itemData.added_quantity_is_case[
                        this.props.check_added_quantity_is_case
                      ]
                    );
                  }}
                >
                  <TypographyFontColor
                    component={"span"}
                    border={0}
                    className="ml-1"
                    style={{ padding: "4px" }}
                  >
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        className="mr-1"
                        checked={
                          this.props.itemData.added_quantity_is_case[
                            this.props.check_added_quantity_is_case
                          ] === true
                            ? true
                            : false
                        }
                        style={{ transform: "scale(1.3)" }}
                      />
                      <span> Case of {this.props.itemData.case_quantity}</span>
                    </div>
                  </TypographyFontColor>
                </TypographyPrimaryBgColorDimmed>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.props.extraCharges.is_show_out_of_stock === true &&
          this.props.itemData.out_of_stock === true &&
          this.props.restrictByOnhand === true ? (
            ""
          ) : this.props.itemData?.addtocart_show === true &&
            this.props.itemData?.instoreonly === false ? (
            <div className={"singleAddtocartmob"}>
              {this.props.itemData.added_quantity[
                this.props.check_added_quantity_is_case
              ] === 0 ? (
                <SecondaryButton
                  Fonttype="other"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.openAddCount(
                      this.props.itemData.id,
                      this.props.itemData.added_quantity[
                        this.props.check_added_quantity_is_case
                      ] + 1,
                      this.props.itemData.added_quantity_is_case[
                        this.props.check_added_quantity_is_case
                      ],
                      this.props?.categoryIndx,
                      this.props.itemData.added_quantity[
                        this.props.check_added_quantity_is_case
                      ]
                    );
                  }}
                >
                  Add
                </SecondaryButton>
              ) : (
                <TypographySecondaryBorder border={2} className="count_boxmob">
                  <button
                    className="count_btnmobminus"
                    style={{ borderTopRightRadius: "9px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.openAddCount(
                        this.props.itemData.id,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ] + 1,
                        this.props.itemData.added_quantity_is_case[
                          this.props.check_added_quantity_is_case
                        ],
                        this.props?.categoryIndx,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ]
                      );
                    }}
                    disabled={this.props.itemData?.disabled}
                  >
                    {this.props.itemData?.disabled ? (
                      <TypographySecondaryColor size={20} component="span">
                        ...
                      </TypographySecondaryColor>
                    ) : (
                      <TypographySecondaryColor
                        size={20}
                        component="span"
                        className="icon-plus"
                      ></TypographySecondaryColor>
                    )}
                  </button>

                  <div className="count_nomob">
                    {this.props.inputField === false ? (
                      <input
                        type="text"
                        style={{ borderRadius: "0px" }}
                        className="form-control value"
                        id={this.props.itemData.id}
                        value={
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ] > 0
                            ? this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ]
                            : 0
                        }
                        onChange={(e) => {
                          e.stopPropagation();
                          this.props.openAddCounttext(
                            this.props.itemData.id,
                            e.target.value,
                            this.props.itemData.added_quantity_is_case[
                              this.props.check_added_quantity_is_case
                            ],
                            this.props?.categoryIndx,
                            this.props.itemData.added_quantity[
                              this.props.check_added_quantity_is_case
                            ]
                          );
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control defaultValue"
                        id={this.props.itemData.id}
                        style={{ borderRadius: "0px" }}
                        defaultValue={
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ] > 0
                            ? this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ]
                            : 0
                        }
                        onBlur={() => this.props.changeInputBoxValue()}
                        onChange={(e) => {
                          e.stopPropagation();
                          this.props.openAddCounttext(
                            this.props.itemData.id,
                            e.target.value,
                            this.props.itemData.added_quantity_is_case[
                              this.props.check_added_quantity_is_case
                            ],
                            this.props?.categoryIndx,
                            this.props.itemData.added_quantity[
                              this.props.check_added_quantity_is_case
                            ]
                          );
                        }}
                      />
                    )}
                  </div>
                  <button
                    className="count_btnmobminus"
                    style={{ borderBottomRightRadius: "9px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.openAddCount(
                        this.props.itemData.id,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ] - 1,
                        this.props.itemData.added_quantity_is_case[
                          this.props.check_added_quantity_is_case
                        ],
                        this.props?.categoryIndx,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ]
                      );
                    }}
                    disabled={this.props.itemData?.disabled}
                  >
                    <TypographySecondaryColor component="span" size={25}>
                      {this.props.itemData?.disabled === true
                        ? "..."
                        : createElement(
                            this.props.itemData.added_quantity[
                              this.props.check_added_quantity_is_case
                            ] > 1
                              ? AiOutlineMinus
                              : MdDeleteOutline
                          )}
                    </TypographySecondaryColor>
                  </button>
                </TypographySecondaryBorder>
              )}
            </div>
          ) : this.props.itemData.instoreonly === false ? (
            <div className={"singleAddtocartmob"}>
              <div className="add_tocartmob">
                <SecondaryButton
                  style={{ width: "10%", fontSize: "12px" }}
                  onClick={(e) =>
                    this.props.handlePopOpen(
                      this.props.itemData.id,
                      this.props.itemData.slug,
                      false,
                      false,
                      this.props.index,
                      this.props?.categoryIndx
                    )
                  }
                  Fonttype="other"
                >
                  Select
                </SecondaryButton>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
