import React from "react";
import { addDefaultImageSrc } from "../../utils/renderUtils/renderUtil";
import { TypographyTextFontFamily } from "../common/UiDesign";
import ApiUrls from "../../utils/apiUtils/apiUrl";
function CategoryCard(props) {
  const { category } = props;
  return (
    <div className="newcategory" style={{ cursor: "pointer" }}>
      <div className="cat-img">
      
        <img
          onError={(e) => addDefaultImageSrc(e)}
          className="img-responsive"
          src={
            category.image ?category.image[0]==="/"? ApiUrls.BASH_URL + "/media" + category.image:category.image : ""
          }
          alt=""
          style={{transform:"scale("+category?.scale+")"}}
        />
        <a
          style={{
            background:
              category?.image !== null ||
              (localStorage.getItem("product_no_image") !== "" &&
                localStorage.getItem("product_no_image") !== null)
                ? "none"
                : "",
          }}
        ></a>
      </div>

      <TypographyTextFontFamily
        type="category"
        className="text-center font-weight-bolder ow-anywhere py-2 px-1"
      >
        {category.name}
      </TypographyTextFontFamily>
    </div>
  );
}

export default CategoryCard;
