import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Snackbar,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  sufixIconInputForProfile,
  radioButton,
  renderHiddenField,
  sufixIconInputForaddressMobile,
  commonPageTitel,
  tConvert,
  renderNumberInputForZipCode,
  renderSelect3Input,
  RefreshApiToken,
  getSchedule_zip,
  convertTodate,
  sweetAlertBox,
} from "../../utils/renderUtils/renderUtil";
import { AiOutlineFieldTime } from "react-icons/ai";
import {
  CountryListView,
  GetallAddress,
  OrderAddressAndDelivery,
  GetAddressDetailsByID,
  SetDefaultAddress,
  StateListView,
  GetGuestAddress,
  GetProfileDetails,
} from "../../actions/profile/profileAction";
import {
  CartListFunctionlity,
  GetExtraChargesData,
} from "../../actions/cart/cartAction";
import {
  GetSlotForDeliveryAndPickup,
  RestrictDeliveryApi,
} from "../../actions/order/orderAction";
import deliveryaddressSubmit from "./deliveryaddressSubmit";
import CircularProgress from "@mui/material/CircularProgress";
import { GuestStoreAddress } from "../../actions/home/homeAction";
import { State_Territory } from "../../utils/renderUtils/dbData";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  PrimaryBorderButton,
  PrimaryButton,
  SecondaryButton,
  TypographyFontColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../common/UiDesign";
import profileSubmit from "../profileSubmit";

class Address extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    date.setDate(date.getDate() + 1);
    const minDateValue = date.toISOString().slice(0, 10);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      addressTab: false,
      city: [],
      state: [],
      countryList: [],
      success: false,
      error: false,
      addressDetails: [],
      CategoryDetails: [],
      SubCategoryDetails: [],
      pageName: "delivery-address",
      address_id: null,
      delivery_option: "",
      CartCount: [],
      addressLoder: false,
      addressShowHide: false,
      choseDeliveryOptions: "delivery",
      selecteddate: minDateValue,
      chooseSlot: "same_day",
      slotsArray: [],
      store_address: "",
      showsamedayslot: false,
      showchoosediffdateslot: false,
      showpickupsamedayslot: false,
      showpickupchoosediffdateslot: false,
      choosePickupSlot: "same_day",
      moreOption: false,
      chooseDefaultSlot: true,
      chooseDefaultSlotForPickup: false,
      CategoryOption: [],
      cartLoader: false,
      openDialog: false,
      dialogMsg: "",
      confirm_another_day_delivery: false,
      DeliveryAndPickupTimeSlot: [],
      deliveryRadio: true,
      pickupRadio: false,

      tipForPacker:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.state
          ? this.props.history.location.state.tipForPacker
          : "0.00",
      tipForDeliveryboy:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.state
          ? this.props.history.location.state.tipForDeliveryboy
          : "0.00",
      donation:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.state
          ? this.props.history.location.state.donation
          : "0.00",
      TotalPayment:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.state
          ? this.props.history.location.state.TotalPayment
          : 0,
      OriginalPayment: 0,
      hiddenCityList: [],
      footerDetails: [],
      storeData: [],
      zipCode: "",
      TillTime: [],
      StartTime: [],
      processTime: [],
      slotData: [],
      logo: "",
      restrict_delivery: "",
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      SubcategoryName: [],
      extraCharges: [],
      seletectedstate: {},
      allstate: [],
      setOpenNewAddresspopup: false,
      additonalPickup: false,
      getAddresDetails: [],
      editAdressid:''
    };
 
  }

  componentDidMount() {
    //;
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    var samedate = new Date().toISOString().substring(0, 10);
    let schedule_type = this.props.selectedslotPopupData;

    let selectedAddress = JSON.parse(localStorage.getItem("selectedAddress"));
    this.setState({
      logo: logo,
      allstate: State_Territory,
      store_address: schedule_type,
      address_id: selectedAddress?.id,
      zipCode: selectedAddress?.zipcode,
    });
    // if (accessToken && isLogin === "true") {
    this.FetchAddress(schedule_type);
    // }
  }

  handelOpenAddressDialog = () => {
    this.setState({ setOpenNewAddresspopup: true });

    this.props.change("apartment_name", "");
    this.props.change("street_name", "");
    this.props.change("zipcode", "");
    if (localStorage.getItem("Guest_userid") === null) {
      this.props.change("mobile_no", "");
      this.props.change("first_name", "");
      this.props.change("last_name", "");
    } else {
      this.props.change("mobile_no", this.state.addressDetails[0]?.phone);
      this.props.change("first_name", this.state.addressDetails[0]?.first_name);
      this.props.change("last_name", this.state.addressDetails[0]?.last_name);
    }
    this.props.change("alternate_mobile", "");
    this.props.change("delivery_remark", "");
    this.props.change("address_type", "Home");
    this.props.change("state_id", "");
    this.props.change("city_id", "");
  };

  GetDeliveryAndPickupTimeSlot = (
    schedule_type,
    selecteddate,
    zone,
    chooseSlot = ""
  ) => {
    let formValue = {};
    let chooseDefaultSlot = this.state.chooseDefaultSlot;
    let chooseDefaultSlotForPickup = this.state.chooseDefaultSlotForPickup;
    let showsamedayslot = this.state.showsamedayslot;
    let showchoosediffdateslot = this.state.showchoosediffdateslot;
    let showpickupsamedayslot = "";
    let showpickupchoosediffdateslot = "";
    let choosePickupSlot = chooseSlot;

    formValue = {
      schedule_type: schedule_type === "delivery" ? 1 : 2,
      date: selecteddate,
      zipcode: zone,
      frontend: false,
    };
    GetSlotForDeliveryAndPickup(formValue, this.props.dispatch).then((res) => {
      let slotData = [];
      if (res.data && res.data.slot) {
        slotData = res.data.slot;
        if (slotData.length > 0 && chooseSlot === "same_day") {
          chooseDefaultSlot = true;
          chooseDefaultSlotForPickup = false;
          showsamedayslot = true;
          showchoosediffdateslot = false;
          chooseSlot = "same_day";
          showpickupsamedayslot = true;
          showpickupchoosediffdateslot = false;
          choosePickupSlot = "same_day";
        } else {
          chooseDefaultSlot = false;
          chooseDefaultSlotForPickup = true;
          showsamedayslot = false;
          showchoosediffdateslot = true;
          chooseSlot = "";
          choosePickupSlot = "";
          showpickupsamedayslot = false;
          showpickupchoosediffdateslot = true;
        }
      } else if (res.errors && res.errors._error && res.errors._error.status) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.GetDeliveryAndPickupTimeSlot(
                formValue.schedule_type,
                formValue.date,
                formValue.zone
              );
            }
          );
        }
      }

      this.createSlot(slotData);
      this.setState({
        slotData: slotData,
        chooseDefaultSlot,
        chooseDefaultSlotForPickup,
        showsamedayslot,
        showchoosediffdateslot,
        chooseSlot,
        selecteddate: this.state.selecteddate,
        showpickupsamedayslot: showpickupsamedayslot,
        showpickupchoosediffdateslot: showpickupchoosediffdateslot,
      });
    });
  };


  createSlot = (slotData) => {
    var slotsArray = [];
    var FinalTimeSlot = "";
    for (let i = 0; i < slotData.length; i++) {
      FinalTimeSlot =
        tConvert(slotData[i].Start_time) +
        " - " +
        tConvert(slotData[i].Till_time);

      slotsArray.push({
        label: FinalTimeSlot,
        value: FinalTimeSlot,
      });
    }
    if (slotsArray && slotsArray.length === 0) {
      slotsArray.push({
        label: "No more slot available",
        value: "",
      });
    }
    let delivery_option = this.state.delivery_option;
    if (slotsArray && slotsArray.length > 0) {
      delivery_option = slotsArray[0].value;
    }

    this.setState({ slotsArray: slotsArray, delivery_option });
  };

  FetchAddress = (schedule_type) => {
    let addressDetails = [];
    let zipCode = "";
    let additionaladdress = [];
    this.setState({ addressLoder: true, chooseSlot: "same_day" });
    let selectedAddress =
      localStorage.getItem("SelectedAddress") !== undefined &&
      localStorage.getItem("SelectedAddress") !== "undefined"
        ? JSON.parse(localStorage.getItem("SelectedAddress"))
        : "";

    if (schedule_type === "pickup") {
      let formValue = {};
      GuestStoreAddress(formValue, this.props.dispatch).then((res) => {
        if (res.data && res.data.result) {
          addressDetails = res.data.additional_address;
          let selectedindx = 0;
          if (addressDetails.length > 0) {
            for (let i = 0; i < addressDetails.length; i++) {
              if (selectedAddress?.id === addressDetails[i].id) {
                addressDetails[i].checked = true;
                selectedindx = i;
              } else {
                addressDetails[i].checked = false;
              }
              if (addressDetails[i].checked === true) {
                zipCode = addressDetails[i].zipcode;
              }
            }
            zipCode = addressDetails[0].zipcode;
          }
          this.setState({
            addressDetails: addressDetails,
            address_id:addressDetails.length===1?addressDetails[0].id:
              schedule_type === "" || selectedAddress === null
                ? addressDetails?.length > 0 && addressDetails[selectedindx]?.id
                  ? addressDetails[selectedindx].id
                  : 0
                : selectedAddress.id,
            addressLoder: false,
            zipCode: zipCode,
          });
          if (addressDetails.length === 1) {
            addressDetails[0].checked = true;
            addressDetails[0].addressCount = 1;
            this.setState({ address_id: addressDetails[0].id });
            this.handleSubmitAddress();
          }
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchAddress(schedule_type);
              }
            );
          }
        }
      });
    } else {
      if (localStorage.getItem("Guest_userid") === null) {
        let formValue = {
          store_address: schedule_type === "pickup",
        };
        GetallAddress(formValue, this.props.dispatch).then((res) => {
          if (res.data && res.data.result) {
            addressDetails = res.data.result;
            additionaladdress = res.data.additional_address;
            let selectedindx = 0;
            if (addressDetails.length > 0) {
              for (let i = 0; i < addressDetails.length; i++) {
                if (selectedAddress?.id === addressDetails[i].id) {
                  addressDetails[i].checked = true;
                  selectedindx = i;
                } else if (schedule_type === "pickup") {
                  addressDetails[i].checked = true;
                } else {
                  addressDetails[i].checked = false;
                }
                if (addressDetails[i].checked === true) {
                  zipCode = addressDetails[i].zipcode;
                }
              }
              if (additionaladdress && additionaladdress.length > 0)
                addressDetails = [...addressDetails, ...additionaladdress];
            

              if (
                addressDetails.length > 0 &&
                addressDetails.some((ele) => ele?.checked)===false
              )
                addressDetails[0].checked = true;

                if(this.state.editAdressid===this.props.selectedAddress?.id &&this.state.editAdressid!==""){
                  let findAddressIndex = addressDetails.findIndex(
                    (p) => p.id === this.state.editAdressid
                  );
                  this.props.handlesetAddress(addressDetails[findAddressIndex])
                }
                console.log(schedule_type,selectedAddress,
                  addressDetails.length===1?addressDetails[0].id:
                  schedule_type === "" || selectedAddress === null
                    ? addressDetails &&
                      addressDetails.length > 0 &&
                      addressDetails[selectedindx].id
                      ? addressDetails[selectedindx].id
                      : 0
                    : selectedAddress.id);
              this.setState({
                addressDetails: addressDetails,
                address_id:addressDetails.length===1?addressDetails[0].id:
                  schedule_type === "" || selectedAddress === null
                    ? addressDetails &&
                      addressDetails.length > 0 &&
                      addressDetails[selectedindx].id
                      ? addressDetails[selectedindx].id
                      : 0
                    : selectedAddress.id,
                addressLoder: false,
                zipCode: zipCode,
                editAdressid:''
              });
            }
             else this.FetchProfileDetails("");
          } else if (
            res.errors &&
            res.errors._error &&
            res.errors._error.status === 401
          ) {
            const refreshToken = JSON.parse(
              localStorage.getItem("access_token")
            );
            let myThis = this;
            if (refreshToken) {
              RefreshApiToken(
                refreshToken.refresh_token,
                myThis,
                function (myThis) {
                  myThis.FetchAddress(formValue.store_address);
                }
              );
            }
          } else {
            this.setState({
              addressDetails: addressDetails,
              address_id: null,
              addressLoder: false,
              zipCode: zipCode,
            });
          }
        });
      } else {
        this.fetchGuestAddress(schedule_type);
      }
    }
  };
  FetchProfileDetails = (schedule_type) => {
    let formValues = {};
    let profileDetails = [];
    let zipCode = "";
    let selectedAddress =
      localStorage.getItem("SelectedAddress") !== undefined &&
      localStorage.getItem("SelectedAddress") !== "undefined"
        ? JSON.parse(localStorage.getItem("SelectedAddress"))
        : "";
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;

        profileDetails.checked = true;
        zipCode = profileDetails.zipcode;
        profileDetails.first_name = profileDetails.firstName;
        profileDetails.last_name = profileDetails.lastName;
        profileDetails.isUserAddress = true;
        // console.log(typeof profileDetails.phone);
        // let profileValues={
        //   mobile_number:profileDetails.phone,
        //   hidden_mobile_number:profileDetails.phone,
        //   profile_alternate_mobile:profileDetails.alternate_phone===null?0:profileDetails.alternate_phone,
        //   profile_first_name:profileDetails.firstName,
        //   profile_last_name:profileDetails.lastName,
        //   email_address:profileDetails.email,
        //   address:profileDetails.address,
        //   profile_street_name:profileDetails.street_name===null?"":profileDetails.street_name,
        //   profile_city_name:profileDetails.city_name===null?"":profileDetails.city_name,
        //   profile_state_name:profileDetails.state_name===null?"":{value:profileDetails.state_name},
        //   profile_zipcode:profileDetails.zipcode,
        //   profile_delivery_remark:profileDetails.delivery_remark===null?"":profileDetails.delivery_remark,
        // }
        // profileSubmit('',profileValues,"","")
   
        this.setState({
          addressDetails: [profileDetails],
          address_id:
            schedule_type === "" || selectedAddress === null
              ? profileDetails?.id
                ? profileDetails.id
                : 0
              : selectedAddress.id,
          addressLoder: false,
          zipCode: zipCode,
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails(schedule_type);
            }
          );
        }
      }
    });
  };
  fetchGuestAddress = () => {
    let addressDetails = [];
    let zipCode = "";
    let formdata = { id: parseInt(localStorage.getItem("Guest_userid")) };
    GetGuestAddress(formdata, this.props.dispatch).then((res) => {
      if (res.result) {
        addressDetails = [res.result];

        if (addressDetails.length === 0 || addressDetails[0].zipcode === null) {
          this.setState({ addressDetails: addressDetails });

          this.handelOpenAddressDialog();
        } else {
          addressDetails[0].checked = true;
          zipCode = addressDetails[0]?.zipcode;
          addressDetails[0].id = formdata.id;
        }

        this.setState({
          getAddresDetails: res.result,
          seletectedstate: {
            label: addressDetails[0]?.state_name,
            value: addressDetails[0]?.state_name,
          },
          addressDetails: addressDetails,
          address_id: formdata.id,
          addressLoder: false,
          zipCode: zipCode,
        });
        // this.props.CloseDialog()
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.fetchGuestAddress(formdata.id);
            }
          );
        }
      } else {
        this.setState({
          addressDetails: addressDetails,
          address_id: formdata.id,
          addressLoder: false,
          zipCode: zipCode,
        });
      }
    });
  };

  handleCloseOpnDialog = () => {
    this.setState({ openDialog: false });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg.zipcode && msg.zipcode[0]) {
        sweetAlertBox('Attention!!',msg.zipcode[0],"info")
        // this.setState({
        //   error: msg.zipcode[0],
        //   success: false,
        // });
      } else if (msg.address_id && msg.address_id[0]) {
        sweetAlertBox('Attention!!',msg.address_id[0],"info")
        // this.setState({
        //   error: msg.address_id[0],
        //   success: false,
        // });
      } else if (msg.address_type && msg.address_type[0]) {
        sweetAlertBox('Attention!!',"Address type is not a valid choice","info")
        // this.setState({
        //   error: "Address type is not a valid choice",
        //   success: false,
        // });
      } else if (msg.delivery_option && msg.delivery_option[0]) {
        sweetAlertBox('Attention!!',"Please select a delivery slot","info")
        // this.setState({
        //   error: "Please select a delivery slot",
        //   success: false,
        // });
      } else if (msg.errors && msg.errors.id[0]) {
        sweetAlertBox('Attention!!',msg.errors.id[0],"info")
        // this.setState({
        //   error: msg.errors.id[0],
        //   success: false,
        // });
      } else if (msg.phone && msg.phone[0]) {
        sweetAlertBox('Attention!!',"Please enter 10 digit valid Phone no","info")
        // this.setState({
        //   error: "Please enter 10 digit valid Phone no",
        //   success: false,
        // });
      } else if (msg.alternate_phone && msg.alternate_phone[0]) {
        sweetAlertBox('Attention!!',"Please enter 10 digit valid Alternate no","info")
        // this.setState({
        //   error: "Please enter 10 digit valid Alternate no",
        //   success: false,
        // });
      } else if (msg.first_name && msg.first_name[0]) {
        sweetAlertBox('Attention!!',"Ensure First name should have atleast 2 letters","info")
      
        // this.setState({
        //   error: "Ensure First name should have atleast 2 letters",
        //   success: false,
        // });
      } else if (msg.city && msg.city[0]) {
        sweetAlertBox('Attention!!',"City is required","info")
      
        // this.setState({
        //   error: "City is required",
        //   success: false,
        // });
      } else if (msg && msg.error) {
        sweetAlertBox('Attention!!',msg.error[0],"info")
      
        // this.setState({
        //   error: msg.error[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox('Error!!',msg,"error")
      
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      if (localStorage.getItem("Guest_userid") === null)
        this.FetchAddress(this.state.store_address);
      else this.fetchGuestAddress();
      sweetAlertBox('Success!!',msg,"success",2000,false)
      this.setState({
        // error: false,
        // success: msg,
        setOpenNewAddresspopup: false,
      
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  onChangeAddress(i, address_id, zipcode) {
    
    let addressDetails = this.state.addressDetails;
    let additonalPickup = false;
    for (let i = 0; i < addressDetails.length; i++) {
      if (
        addressDetails[i].id === address_id &&
        addressDetails[i].zipcode === zipcode
      ) {
        if (this.state.store_address === 2)
          i === 0 ? (additonalPickup = false) : (additonalPickup = true);
        addressDetails[i].checked = true;
      } else {
        addressDetails[i].checked = false;
      }
    }

    this.setState({
      addressDetails,
      address_id: address_id,
      zipCode: addressDetails[i].zipcode,
      additonalPickup: additonalPickup,
    });
  }

  OrderAddressAndDeliveryPost = () => {
    let deliveryDatetime;
    let samedayDelivery;
    if (this.state.choseDeliveryOptions === "pickup") {
      if (this.state.choosePickupSlot === "same_day") {
        var pickupnewdate = new Date().toISOString().substring(0, 10);
        deliveryDatetime = pickupnewdate;
        samedayDelivery = true;
      } else {
        deliveryDatetime = this.state.selecteddate;
        samedayDelivery = false;
      }
    } else {
      if (this.state.chooseSlot === "same_day") {
        var newdate = new Date().toISOString().substring(0, 10);
        deliveryDatetime = newdate;
        samedayDelivery = true;
      } else {
        deliveryDatetime = this.state.selecteddate;
        samedayDelivery = false;
      }
    }

    let formValue = {
      address_id: this.state.address_id,
      delivery_option: this.state.delivery_option,
      delivery_type: this.state.choseDeliveryOptions,
      delivery_datetime: deliveryDatetime.substring(0, 10),
      sameday_delivery: samedayDelivery,
      confirm_another_day_delivery: this.state.confirm_another_day_delivery,
    };
    OrderAddressAndDelivery(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400) {
        if (res.errors.data) {
          this.createSuccessMsg("error", res.errors.data.errors);
        }
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.OrderAddressAndDeliveryPost();
            }
          );
        }
      } else if (res.errors && res.errors.status === 405) {
        this.setState({ openDialog: true, dialogMsg: res.errors.data.alert });
        this.chnangeConfirmDayDelivery();
      } else if (res.status === 200 && res.data) {
        this.props.history.push({
          pathname: "/payment",
          state: {
            delivery_option: this.state.choseDeliveryOptions,
            tipForPacker: this.state.tipForPacker,
            tipForDeliveryboy: this.state.tipForDeliveryboy,
            donation: this.state.donation,
            // TotalPayment: this.state.TotalPayment
          },
        });
      }
    });
  };

  chnangeConfirmDayDelivery = () => {
    this.setState({ confirm_another_day_delivery: true });
  };

  agreeDeliverySlot = (value) => {
    if (value === "agree" && this.state.confirm_another_day_delivery === true) {
      this.OrderAddressAndDeliveryPost();
    }
  };

  OnClickMoreOption = (address_id) => {
    let AddressDetails = this.state.addressDetails;
    for (let i = 0; i < AddressDetails.length; i++) {
      if (AddressDetails[i].id === address_id) {
        AddressDetails[i].moreOption = !AddressDetails[i].moreOption;
      } else {
        AddressDetails[i].moreOption = false;
      }
      this.setState({ addressDetails: AddressDetails });
    }
  };

  editAddress = (address_id) => {
    console.log(this.props.selectedAddress);
    if (this.state.addressDetails.some((val) => val.checked)) {
      let AddressDetails = this.state.addressDetails;
      let findAddressIndex = AddressDetails.findIndex(
        (p) => p.id === address_id
      );
      if (findAddressIndex > -1) {
        AddressDetails[findAddressIndex].moreOption = false;
      }
      if (localStorage.getItem("Guest_userid") === null)
        this.FetchAddressDetails(address_id);
      else {
        this.FillAllFieldValue(AddressDetails.id);
      }
      this.setState({
        setOpenNewAddresspopup: true,
        addressDetails: AddressDetails,
        seletectedstate: {},
        editAdressid:address_id
      });
    } else this.createSuccessMsg("error", "Please select the address");
  };

  FetchAddressDetails = (address_id) => {
    let formValue = {
      id: address_id,
    };
    GetAddressDetailsByID(formValue, this.props.dispatch).then((res) => {
      let getAddresDetails = [];
      if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddressDetails(address_id);
            }
          );
        }
      } else if (res.data && res.data.result) {
        getAddresDetails = res.data.result;
      }
      this.setState({
        getAddresDetails: getAddresDetails,
        seletectedstate: {
          label: getAddresDetails.state_name,
          value: getAddresDetails.state_name,
        },
      });
      this.FillAllFieldValue(address_id);
    });
  };

  FillAllFieldValue = (address_id) => {
    let getAddresDetails = this.state.getAddresDetails;
    
    this.props.change("address_id", address_id);
    this.props.change("first_name", getAddresDetails.first_name);
    this.props.change("last_name", getAddresDetails.last_name);
    this.props.change("apartment_name", getAddresDetails.appartment_name);
    this.props.change("street_name", getAddresDetails.street_name);
    this.props.change("city_id", getAddresDetails.city_name);
    // this.props.change("hidden_city_id", getAddresDetails.city);
    this.props.change("state_id", {
      label: getAddresDetails.state_name,
      value: getAddresDetails.state_name,
    });
    this.props.change("zipcode", getAddresDetails.zipcode);
    this.props.change("mobile_no", getAddresDetails.phone);
    this.props.change("alternate_mobile", getAddresDetails.alternate_phone);
    this.props.change("delivery_remark", getAddresDetails.delivery_remark);
    this.props.change("address_type", getAddresDetails.address_type);
  };

  setDefaultAddress = (i, address_id) => {
    let formValue = {
      address_id: address_id,
    };
    let addressDetails = this.state.addressDetails;
    SetDefaultAddress(formValue, this.props.dispatch).then((res) => {
      if (res && res.data) {
        let findAddressIndex = addressDetails.findIndex(
          (p) => p.id === address_id
        );
        if (findAddressIndex > -1) {
          addressDetails[findAddressIndex].moreOption = false;
        }
        this.createSuccessMsg("success", res.data.message);
      }
      this.setState({
        checked: i,
        address_id: address_id,
        addressDetails,
      });
    });
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return null;
  };

  handleChnageState = (e) => {
    this.setState({
      seletectedstate: { label: e.value, value: e.value },
    });
  };
  handleSubmitAddress = () => {
    let address = "";

    if (this.state.addressDetails.some((val) => val.checked)) {
      if (this.props.selectedslotPopupData === "pickup") {
        address = this.state.addressDetails.filter(
          (ele) => ele.id === this.state.address_id
        );
        this.props.setSelectedAddress(address[0], this.state.additonalPickup);
      } else {
        if ((
          this.state.address_id === null ||
          this.state.address_id === undefined)&& this.state.addressDetails.length>1
        ) {
          this.createSuccessMsg("error", "Please select address");
        } else {
          if(this.state.addressDetails.length>1)
        {  address = this.state.addressDetails.filter(
            (ele) => ele.id === this.state.address_id
          );
          address=address.length===0?[this.state.addressDetails[0]]:address
console.log(this.state.address_id,this.state.addressDetails);
          this.props.setSelectedAddress(address[0], this.state.additonalPickup);}
          else{
            this.props.setSelectedAddress(this.state.addressDetails[0], this.state.additonalPickup);
          }
        }
      }
      
    } else {
      this.createSuccessMsg("error", "Please select address");
      // this.createSuccessMsg("error", "Please select address");
    }
  };
  CloseDialog = () => {
    this.setState({ setOpenNewAddresspopup: false });
  };
  render() {
    const {
      success,
      error,
      addressDetails,
     
    } = this.state;
    const { handleSubmit, selectedslotPopupData } = this.props;
    let selector = this;

    let optionTemplate = [];
    if (this.state.slotsArray && this.state.slotsArray.length > 0) {
      optionTemplate = this.state.slotsArray.map((v) => (
        <option value={v.value}>{v.label}</option>
      ));
    }

    return (
      <>
        {this.state.addressLoder || this.state.setOpenNewAddresspopup ? (
          <></>
        ) : (
          <Dialog
            open={this.props.setOpenAddresspopup}
            onClose={() => this.props.CloseDialog()}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            className="popUp"
            fullWidth="true"
          >
            <DialogTitle class="p-0" id="scroll-dialog-title">
              <div className="header px-2 py-2">
                <span className="pop_close">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      this.props.CloseDialog();
                    }}
                  >
                    <span class="icon-close"></span>
                  </a>
                </span>
              </div>
              {selectedslotPopupData === "delivery" ||
              selectedslotPopupData === "shipping" ? (
                <div class="address-pop-title">
                  <TypographyTextColor Color="heading" component="h4">
                    {selectedslotPopupData === "shipping"
                      ? "Shipping Address"
                      : "Delivery Address"}
                  </TypographyTextColor>
                  {window.screen.availWidth <= 900 ? (
                    <div className="d-flex">
                      {localStorage.getItem("Guest_userid") === null ? (
                        <span
                          className="new_add"
                          onClick={this.handelOpenAddressDialog.bind()}
                          style={{ marginRight: "5px" }}
                        >
                          <AddIcon></AddIcon>
                        </span>
                      ) : (
                        <></>
                      )}
                      <span
                        className="new_add"
                        onClick={() => this.editAddress(this.state.address_id)}
                      >
                        <EditIcon></EditIcon>
                      </span>
                    </div>
                  ) : (
                    <div>
                      {localStorage.getItem("Guest_userid") === null ? (
                        <PrimaryBorderButton
                          className="new_add"
                          onClick={this.handelOpenAddressDialog.bind()}
                          style={{ marginRight: "5px" }}
                          Fonttype="other"
                        >
                          <span className="icon-plus"></span>Add
                        </PrimaryBorderButton>
                      ) : (
                        <></>
                      )}
                      <PrimaryBorderButton
                        Fonttype="other"
                        className="new_add"
                        onClick={() => this.editAddress(this.state.address_id)}
                      >
                        Edit
                      </PrimaryBorderButton>
                    </div>
                  )}
                </div>
              ) : selectedslotPopupData === "pickup" ? (
                <TypographyTextColor Color="heading" component="h4">
                  Pickup Address
                </TypographyTextColor>
              ) : (
                <></>
              )}
            </DialogTitle>
            <DialogContent className="p-0">
              <div className="main-content">
                <div>
                  <section className="cart_main">
                    <div className="cart_wrapper show">
                      <div className="delivery_wrapper">
                        <div>
                          <div>
                            {selectedslotPopupData === "delivery" ||
                            selectedslotPopupData === "shipping" ? (
                              <div>
                                <div className="delivery_add deilivery_new_add">
                                  {this.state.addressLoder ? (
                                    <CircularProgress
                                      style={{
                                        display: "flex",
                                        color: "#3d9b7b",
                                        position: "relative",
                                        right: "-35%",
                                        "& > * + *": {
                                          marginLeft: 2,
                                        },
                                      }}
                                    />
                                  ) : addressDetails &&
                                    addressDetails.length > 0 ? (
                                    addressDetails.map((row, index) => {
                                      return (
                                        <div className="address_user">
                                          <div className="cust_add">
                                            <div className="cust_usr_name">
                                              <FormControlLabel
                                                style={{
                                                  margin: "0px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    checked={
                                                      row.checked === true
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                }
                                                label={
                                                  <TypographyTextColor
                                                    Color="heading"
                                                    component="h5"
                                                    className="m-0"
                                                  >
                                                    {" "}
                                                    {row.first_name +
                                                      " " +
                                                      row.last_name}
                                                    <TypographyFontColor
                                                      border={0}
                                                      className="user_defaulit_add"
                                                    >
                                                      {row.isdefault === true
                                                        ? "(Default)"
                                                        : ""}
                                                    </TypographyFontColor>
                                                  </TypographyTextColor>
                                                }
                                                onChange={this.onChangeAddress.bind(
                                                  this,
                                                  index,
                                                  row.id,
                                                  row.zipcode
                                                )}
                                                labelPlacement="right"
                                              />
                                            </div>

                                            <div
                                              className="cust_address cust_delv_add"
                                              style={{ marginLeft: "30px" }}
                                            >
                                              <div className="cust_home"></div>
                                              <TypographyTextFontFamily
                                                component="div"
                                                type="other"
                                                className="cust_home"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {row.street_name
                                                  ? row.street_name
                                                  : ""}
                                                {row.appartment_name ? (
                                                  <>
                                                    <br />
                                                    {"APT/FLOOR - " +
                                                      row.appartment_name}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                <br />
                                                {row.city_name +
                                                  ", " +
                                                  row.state_name +
                                                  " " +
                                                  row.zipcode}
                                                {/* {row.appartment_name + `${row.appartment_name? "," : ''}` + row.street_name + ", " + row.city_name +  ", " + row.state_name + ", " + row.zipcode} */}
                                              </TypographyTextFontFamily>
                                              <TypographyTextColor
                                                Color="other"
                                                className="cust_mobile"
                                                style={{ fontSize: "15px" }}
                                                component="div"
                                              >
                                                {this.formatPhoneNumber(
                                                  row.phone
                                                )}
                                                {row.alternate_phone
                                                  ? " / " +
                                                    this.formatPhoneNumber(
                                                      row.alternate_phone
                                                    )
                                                  : ""}
                                              </TypographyTextColor>
                                              <TypographyTextColor
                                                Color="other"
                                                className="cust_mobile"
                                                style={{ fontSize: "15px" }}
                                                component="div"
                                              >
                                                {row.delivery_remark
                                                  ? "Delivery Remark : " +
                                                    row.delivery_remark
                                                  : ""}
                                              </TypographyTextColor>
                                            </div>
                                          </div>
                                          <div style={{ marginLeft: "30px" }}>
                                            {localStorage.getItem(
                                              "Guest_userid"
                                            ) === null && index !== 0 ? (
                                              <div className="add_more_opt">
                                                <span
                                                  className="icon-more"
                                                  onClick={() =>
                                                    this.OnClickMoreOption(
                                                      row.id
                                                    )
                                                  }
                                                ></span>
                                                {row.moreOption === true ? (
                                                  <div class="sub_add_details">
                                                    <ul>
                                                      <li>
                                                        <Link
                                                          className="bttn_grn"
                                                          onClick={() =>
                                                            this.setDefaultAddress(
                                                              index,
                                                              row.id
                                                            )
                                                          }
                                                        >
                                                          Default
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    "No data found"
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="">
                                <div className="delivery_add deilivery_new_add">
                                  {this.state.addressLoder ? (
                                    <CircularProgress
                                      style={{
                                        display: "flex",
                                        color: "#3d9b7b",
                                        position: "relative",
                                        right: "-35%",
                                        "& > * + *": {
                                          marginLeft: 2,
                                        },
                                      }}
                                    />
                                  ) : addressDetails &&
                                    addressDetails.length > 0 ? (
                                    addressDetails.map((row, index) => {
                                      return (
                                        <div className="address_user">
                                          <div className="cust_add">
                                            <div className="cust_usr_name">
                                              <FormControlLabel
                                                style={{
                                                  margin: "0px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    checked={
                                                      row.checked === true
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                }
                                                label={
                                                  <TypographyTextColor
                                                    Color="heading"
                                                    component="h5"
                                                    className="m-0"
                                                  >
                                                    {row.name}
                                                  </TypographyTextColor>
                                                }
                                                onChange={this.onChangeAddress.bind(
                                                  this,
                                                  index,
                                                  row.id,
                                                  row.zipcode
                                                )}
                                                labelPlacement="right"
                                              />
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                              {row?.pickup_type ===
                                              "one_date_time" ? (
                                                <div
                                                  style={{ marginLeft: "12px" }}
                                                >
                                                  <span>
                                                    <AiOutlineFieldTime
                                                      style={{ color: "red" }}
                                                    />
                                                  </span>
                                                  <span>
                                                    {convertTodate(
                                                      row.pickup_date
                                                    )}
                                                    &nbsp;( {row.pickup_time} )
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}

                                              <div className="cust_address cust_delv_add">
                                                <div
                                                  className="cust_home"
                                                 
                                                >
                                                  <span
                                                    style={{
                                                      display:
                                                        row?.address !== null &&
                                                        row?.address !== ""
                                                          ? "block"
                                                          : "none",
                                                    }}
                                                  >
                                                     {row?.address !== null ? (
                                                      <>
                                                        {row.address} <br />
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      gap: "5px",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        display:
                                                          row?.city_name !==
                                                            null &&
                                                          row?.city_name !== ""
                                                            ? "block"
                                                            : "none",
                                                      }}
                                                    >
                                                      {row?.city_name !== null
                                                        ? row.city_name + ", "
                                                        : ""}
                                                    </span>
                                                    <span>
                                                      {row?.state_name !== null
                                                        ? row.state_name
                                                        : ""}
                                                      {row?.zipcode !== null
                                                        ? " " + row.zipcode
                                                        : ""}
                                                    </span>
                                                  </div>
                                                </div>
                                                <TypographyTextColor
                                                  Color="other"
                                                  className="cust_mobile"
                                                  component="div"
                                                >
                                                  {this.formatPhoneNumber(
                                                    row.phone
                                                  )}
                                                </TypographyTextColor>

                                                <div>
                                                  {" "}
                                                  {row?.pickup_message !== "" &&
                                                  row?.pickup_message !==
                                                    null &&
                                                  row?.pickup_message !==
                                                    "undefined" ? (
                                                    <div className="cust_home cust_pickup_msg">
                                                      <TypographyTextColor
                                                        Color="other"
                                                        component="span"
                                                      >
                                                        {row.pickup_message}
                                                      </TypographyTextColor>
                                                      <br />
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    "Sorry, currently we are not serving pickups"
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {success ? (
                          <Snackbar
                            open={this.state.success}
                            autoHideDuration={1000}
                            className="success"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            onClose={() => this.handleClose()}
                            action={
                              <React.Fragment>
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  color="inherit"
                                  onClick={this.handleClose}
                                >
                                  <i className="icon-close"></i>
                                </IconButton>
                              </React.Fragment>
                            }
                            message={
                              <React.Fragment>
                                <div className="row gutter-8">
                                  <div className="col-auto">
                                    <i className="icon-closer"></i>
                                  </div>
                                  <div className="col">
                                    <p>{success}</p>
                                  </div>
                                </div>
                              </React.Fragment>
                            }
                          />
                        ) : (
                          ""
                        )}
                        {error ? (
                          <Snackbar
                            open={this.state.error}
                            autoHideDuration={800}
                            className="error"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            action={
                              <React.Fragment>
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  color="inherit"
                                  onClick={this.handleClose}
                                >
                                  <i className="icon-close"></i>
                                </IconButton>
                              </React.Fragment>
                            }
                            message={
                              <React.Fragment>
                                <div className="row gutter-8">
                                  <div className="col-auto">
                                    <i className="icon-closer"></i>
                                  </div>
                                  <div className="col">
                                    <p>{error}</p>
                                  </div>
                                </div>
                              </React.Fragment>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </section>
                </div>

                <Dialog
                  open={this.state.openDialog}
                  onClose={() => this.handleCloseOpnDialog()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {this.state.dialogMsg}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleCloseOpnDialog()}
                      color="primary"
                    >
                      Disagree
                    </Button>
                    <Button
                      onClick={() => this.agreeDeliverySlot("agree")}
                      color="primary"
                      autoFocus
                    >
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </DialogContent>
            {addressDetails.length > 0 ? (
              <DialogActions class="ac-button">
                {" "}
                <PrimaryButton
                  Fonttype="other"
                  onClick={this.handleSubmitAddress}
                >
                  OK
                </PrimaryButton>
              </DialogActions>
            ) : (
              <></>
            )}
          </Dialog>
        )}

        <Dialog
          open={this.state.setOpenNewAddresspopup}
          onClose={this.CloseDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp"
        >
          {" "}
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <TypographyTextColor Color="heading" component="h4">
                Add new address
              </TypographyTextColor>

              <IconButton
                onClick={() => {
                  if (localStorage.getItem("Guest_userid") !== null) {
                    this.props.CloseDialog();
                  }
                  this.CloseDialog();
                }}
              >
                <span
                  class="icon-close"
                  style={{ color: "#389978", fontSize: "18px" }}
                ></span>
              </IconButton>
            </div>{" "}
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="container-fluid mt-2">
              <form
                onSubmit={handleSubmit(deliveryaddressSubmit.bind(null, this))}
              >
                <div className="row">
                  <div className="col-md-6 mb-3 mb-lg-0">
                    <div className="user_input">
                      <Field
                        name="address_id"
                        component={renderHiddenField}
                        type="hidden"
                      />
                      <Field
                        name="first_name"
                        label="First Name"
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        type="text"
                        size="small"
                        rootClass=""
                        required={true}
                        // disabled={localStorage.getItem("Guest_userid") !== null}
                        validate={[
                          required({
                            message: "First Name is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="user_input">
                      <Field
                        name="last_name"
                        label="Last Name"
                        // disabled={localStorage.getItem("Guest_userid") !== null}
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        type="text"
                        size="small"
                        rootClass=""
                        required={true}
                        validate={[
                          required({
                            message: "Last Name is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="user_input">
                      <Field
                        name="street_name"
                        label="Address"
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        type="text"
                        size="small"
                        rootClass=""
                        required={true}
                        validate={[
                          required({
                            message: "Address is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="user_input">
                      <Field
                        name="apartment_name"
                        label="APT/FLOOR"
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        type="text"
                        size="small"
                        rootClass=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <div className="user_input">
                      <Field
                        name="city_id"
                        size="small"
                        label="City"
                        placeholder="City"
                        component={sufixIconInputForProfile}
                        required={true}
                        validate={[
                          required({
                            message: "City is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <div className="user_input">
                      <Field
                        autoFocus={true}
                        name="state_id"
                        size="small"
                        colors="primary"
                        label="State"
                        component={renderSelect3Input}
                        options={this.state.allstate}
                        onChange={this.handleChnageState}
                        Value={this.state.seletectedstate}
                        className="outLine"
                        type="text"
                        required={true}
                        validate={[
                          required({
                            message: "State is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="user_input">
                      <Field
                        name="zipcode"
                        label="Zipcode"
                        id="outlined-basic"
                        component={renderNumberInputForZipCode}
                        size="small"
                        rootClass=""
                        required={true}
                        validate={[
                          required({
                            message: "Zipcode is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="user_input">
                      <Field
                        component={sufixIconInputForaddressMobile}
                        name="mobile_no"
                        variant="outlined"
                        label="Mobile No."
                        id="outlined-basic"
                        color="primary"
                        position="start"
                        disabled={localStorage.getItem("Guest_userid") !== null}
                        placeholder="Mobile No."
                        className="fileds"
                        size="small"
                        required={true}
                        validate={[
                          required({
                            message: "Mobile No. is required.",
                          }),
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="user_input">
                      <Field
                        component={sufixIconInputForaddressMobile}
                        name="alternate_mobile"
                        variant="outlined"
                        id="outlined-basic"
                        label="Alternate Mobile No."
                        color="primary"
                        position="start"
                        placeholder="Alternate Mobile No."
                        className="fileds"
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="user_input">
                      <Field
                        name="delivery_remark"
                        label="Delivery Remarks"
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        type="text"
                        size="small"
                        rootClass=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Field name="address_type" component={radioButton}>
                      <Radio value="Home" label="Home" />
                      <Radio value="Work" label="Work" />
                      <Radio value="Other" label="Others" />
                      <Radio value="Summer Home" label="Summer Home" />
                    </Field>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="cart_bttn delivery_add_bttn"
                      style={{ display: "block" }}
                    >
                      <div className="empty_cart cont_shop">
                        <SecondaryButton
                          Fonttype="other"
                          type="submit"
                          className="bttn_grn"
                        >
                          Save
                        </SecondaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
        {this.state.error ? (
          <Snackbar
            open={this.state.error}
            onClose={this.handleClose}
            autoHideDuration={1000}
            className="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <i className="icon-close"></i>
                </IconButton>
              </React.Fragment>
            }
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{this.state.error}</p>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "Address" })(Address)
);
