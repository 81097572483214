import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { route_url_pages } from "./Preset";
import { PrivateRoute } from "./PrivateRoute";
import NewHome from "../components/new_home";
import Login from "../components/login/login";
import ForgotPassword from "../components/forgot-password";
import CreateAccount from "../components/createaccount/create-account";
import VerifyMobileNo from "../components/verify-mobileNo";
import Successful from "../components/successful";
import Listing from "../components/listing";
import DetailsPage from "../components/details_page";
import Profile from "../components/profile";
import Address from "../components/my-address";
import Wishlist from "../components/wishlist";
import FavoriteList from "../components/favorite_list";
import ShoppingList from "../components/shopping_list";
import Settings from "../components/settings";
import Orders from "../components/orders";
import Cart from "../components/cart";

import PaymentPage from "../components/payment";
import OrderSuccessful from "../components/order-successful";
import OrderDetails from "../components/order-details";
import ResetPassword from "../components/login/resetPassword";
import NotFound from "../components/not-found";
import CommonManagementSystem from "../components/cms";
import SuccessfulChangePassword from "../components/SuccessfulChangePassword";
import PaymentMethodlist from "../components/PaymentMethodlist";
import MobileCategoryList from "../components/common/Category/Mobile/MobileCategoryList";
import { GetCustomizationSettings } from "../actions/home/homeAction";
import { Redirect } from "react-router";
import WebFont from "webfontloader";

const Routes = () => {
  const [webCustomize, setWebCustomize] = useState(
    localStorage.getItem("web_customize")
  );
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  useEffect(() => {
    let intervalId;
    let Web_Font = JSON.parse(webCustomize)?.TextFontFamily;
    // if (performance.navigation.type === 1)
    localStorage.removeItem("setFontFamily");
    if (isIntervalRunning) {
      intervalId = setInterval(() => {
        if (
          (localStorage.getItem("setFontFamily") !== "true" ||
            localStorage.getItem("setFontFamily") === null) &&
          Web_Font?.category !== undefined&&Web_Font?.category !== null
        ) {
          console.log('set fonts');
          localStorage.setItem("setFontFamily", true);
          WebFont.load({
            google: {
              families: [
                Web_Font.category,
                Web_Font.heading,
                Web_Font.other,
                Web_Font.product,
              ],
            },
          });
          setIsIntervalRunning(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isIntervalRunning]);

  return (
    <Router>
      <Switch>
        <Route
          exact={true}
          path={route_url_pages.landing_page}
          component={NewHome}
          restricted={false}
        />
        <Route
          path={route_url_pages.login}
          render={(props) => (
            <Login user={localStorage.getItem("isLogin")} {...props} />
          )}
        />
        <Route
          path={route_url_pages.loginSalesrep}
          render={(props) => (
            <Login user={localStorage.getItem("isLogin")} {...props} />
          )}
        />
        <Route
          path={route_url_pages.forgetpassword}
          component={ForgotPassword}
        />
        <Route
          path={route_url_pages.create_account}
          component={CreateAccount}
        />
        <Route path={route_url_pages.verifynumber} component={VerifyMobileNo} />
        <Route path={route_url_pages.successmsg} component={Successful} />
        <Route
          path={route_url_pages.listing_page}
          render={() => <Redirect to="/" />}
          exact
        />
        <Route
          path={route_url_pages.listing_page_Catgory}
          component={Listing}
          exact
        />
       
        <Route
          exact={true}
          path={route_url_pages.details_page}
          component={DetailsPage}
        />
        ;
        <PrivateRoute
          exact={true}
          path={route_url_pages.profile}
          component={Profile}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.address}
          component={Address}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.orders}
          component={Orders}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.paymentmethod}
          component={PaymentMethodlist}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.wishlist}
          component={Wishlist}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.favoritelist}
          component={FavoriteList}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.shoppinglist}
          component={ShoppingList}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.settings}
          component={Settings}
        />
        <Route path={route_url_pages.cart} component={Cart} />
        <PrivateRoute
          exact={true}
          path={route_url_pages.payment}
          component={PaymentPage}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.order_success}
          component={OrderSuccessful}
        />
        <PrivateRoute
          exact={true}
          path={route_url_pages.order_details}
          component={OrderDetails}
        />
        <Route
          exact={true}
          path={route_url_pages.forget_password}
          component={ResetPassword}
        />
        <Route
          exact={true}
          path={route_url_pages.notfound}
          component={NotFound}
        />
        <Route
          exact={true}
          path={route_url_pages.after_forget_password}
          component={SuccessfulChangePassword}
        />
        <Route
          exact={true}
          path={route_url_pages.category}
          component={MobileCategoryList}
        />
        <Route
          exact={true}
          path={route_url_pages.cms_page}
          component={CommonManagementSystem}
        />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
};

export default Routes;
