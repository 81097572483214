import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import * as React from "react";
import "../../../assets/css/StoreMessage.css";
import { TypographyTextColor } from "../UiDesign";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StoreMessagePopup = (props) => {
    const {openStoreMsg,handleCloseStorePopup}=props
  const [storeData, setStoreData] = React.useState(
    JSON.parse(localStorage.getItem("Store_address"))
  );

  return (
    <React.Fragment>
      <Dialog
        open={openStoreMsg}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"sm"}
        //   onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>  <div
                className="d-flex justify-content-end p-1"
                onClick={() => props.handleClosePopup()}
              >
                <span className="icon-close" role="button"></span>
              </div></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div class="main-wrapper">
              <div class="signboard-wrapper">
                <div class="signboard">CLOSED</div>
                <div class="string"></div>
                <div class="pin pin1"></div>
                <div class="pin pin2"></div>
                <div class="pin pin3"></div>
              </div>
            </div>
            <TypographyTextColor
              Color="heading"
              component="div"
              className="text-center mt-5"
            >
              {storeData?.store_message}
            </TypographyTextColor>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStorePopup} color="primary" variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default StoreMessagePopup;
