import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApiUrls from "../utils/apiUtils/apiUrl";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import LOGO_IMAGE from "../assets/images/logo.png";
import { Redirect } from "react-router-dom";


class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo:''
    };
  }
componentDidMount(){
  const accessToken = localStorage.getItem("access_token");
  const accessTokenForLogo =
      accessToken !== "undefined"
        ? JSON.parse(localStorage.getItem("access_token"))
        : "";
   let LogoNav = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.setState({logo:LogoNav})
}
  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      this.setState({
        error: msg,
        success: false,
      });
    } else {
      this.setState({
        error: false,
        success: true,
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  render() {
    return (
      <div>
        {localStorage.getItem("isLogin") === "true" ? (
          <Redirect to="/" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div className="header-pop">
                  <Link to="/">
                    <img src={ this.state.logo
                    ?this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                    : LOGO_IMAGE} alt="" />
                  </Link>
                </div>
                <div className="login-page">
                  <div className="login-form">
                    <div className="login-head">
                      <h4>Oops! Page Not Found</h4>
                    </div>
                    <div className="bttn-fill">
                      <Link to="/" className="bttn_grn">
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "NotFound" })(NotFound)
);
