import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Paper,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import {
  TypographyFontColor,
  TypographyTextColor,
} from "../UiDesign";
import { capitalize } from "../../../utils/renderUtils/renderUtil";

const FilterButton = (props) => {
  const {
    BrandProduct,
    flavor,
    kosher,
    selectedSortedValue,
    sortingData,
    fruitType,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <TypographyFontColor
        role="button"
        aria-describedby={id}
        onClick={(e) => {
          if (!open) {
            handleClick(e);
          } else handleClose();
        }}
        className="refine-btn d-flex"
        border={0}
      >
        {open ? (
          <span
            class="icon-close"
            style={{ fontSize: "0.9rem", width: "30px" }}
          ></span>
        ) : (
          // <Typography component={TypographyFontColor}>
          <TuneIcon />
          // </Typography>
        )}
     
      </TypographyFontColor>
      <Popover
        style={{ zIndex: 999 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <Typography>
            <Grid container style={{flexWrap:'nowrap'}}>
              <Grid
                item
                style={{
                  borderRight: "1px solid #77889980",
                  padding: "1.2rem",
                  maxWidth: "220px",
                }}
              >
                <TypographyTextColor
                  Color="heading"
                  style={{ fontWeight: 600 }}
                  component="h6"
                >
                  Sort By
                </TypographyTextColor>
                <ul className="filter-panel">
                  {sortingData.map((ele, i) => {
                    return (
                      <li key={i}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={ele.value}
                                name="radio-buttons"
                                inputProps={{ "aria-label": ele.value }}
                                checked={selectedSortedValue === ele.value}
                                onChange={() =>
                                  props.handleChangeSorting(ele.value)
                                }
                              />
                            }
                            label={
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                {ele.name}&nbsp;
                              </TypographyTextColor>
                            }
                          />
                        </FormControl>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
              {BrandProduct.length > 0 ? (
                <Grid
                  item
                  style={{
                    borderRight: "1px solid #77889980",
                    padding: "1.2rem",
                  }}
                >
                  <TypographyTextColor
                    Color="heading"
                    style={{ fontWeight: 600 }}
                    component="h6"
                  >
                    Brand
                  </TypographyTextColor>
                  <ul
                    className="filter-panel"
                    style={{
                      minWidth: BrandProduct.length > 12 ? "290px" : "100%",
                    }}
                  >
                   
                    {BrandProduct.map((ele, i) => {
                      return (
                        <li key={i}>
                          <FormControl>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={
                                    "checkboxChecked" in ele
                                      ? ele.checkboxChecked
                                      : false
                                  }
                                  onChange={() =>
                                    props.handleChangeCheckbox(
                                      ele.brand_id,
                                      ele.brand__name
                                    )
                                  }
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={
                                <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.brand__name)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                              }
                            />

                          
                          </FormControl>
                        </li>
                      );
                    })}
                 
                  </ul>
                </Grid>
              ) : (
                <></>
              )}
              {flavor.length > 0 ? (
                <Grid
                  item
                  style={{
                    borderRight: "1px solid #77889980",
                    padding: "1.2rem",
                  }}
                >
                  <TypographyTextColor
                    Color="heading"
                    style={{ fontWeight: 600 }}
                    component="h6"
                  >
                    Flavor
                  </TypographyTextColor>
                  <ul
                    className="filter-panel"
                    style={{ minWidth: flavor.length > 12 ? "290px" : "100%" }}
                  >
                    {flavor.map((ele, i) => {
                      return (
                        <li key={i}>
                          <FormControl>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={
                                    "checkboxChecked" in ele
                                      ? ele.checkboxChecked
                                      : false
                                  }
                                  onChange={() =>
                                    props.handleChangeCheckboxForFlover(
                                      ele.flavor
                                    )
                                  }
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={
                                <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.flavor)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                              }
                            />

                            {/* <div className="count">
                                          {row.item_count}
                                        </div> */}
                          </FormControl>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              ) : (
                <></>
              )}

              {kosher.length > 0 ? (
                <Grid
                  item
                  style={{
                    borderRight: "1px solid #77889980",
                    padding: "1.2rem",
                  }}
                >
                  <TypographyTextColor
                    Color="heading"
                    style={{ fontWeight: 600 }}
                    component="h6"
                  >
                    Kosher
                  </TypographyTextColor>
                  <ul
                    className="filter-panel"
                    style={{ minWidth: kosher.length > 12 ? "290px" : "100%" }}
                  >
                    {kosher.map((ele, i) => {
                      return (
                        <li key={i}>
                          <FormControl>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={
                                    "checkboxChecked" in ele
                                      ? ele.checkboxChecked
                                      : false
                                  }
                                  onChange={() =>
                                    props.handleChangeCheckboxForKosher(
                                      ele.kosher.trim()
                                    )
                                  }
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={
                                <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.kosher)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                              }
                            />

                            {/* <div className="count">
                                          {row.item_count}
                                        </div> */}
                          </FormControl>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              ) : (
                <></>
              )}
              {fruitType && fruitType.length > 0 ? (
                <Grid
                  item
                  style={{
                    borderRight: "1px solid #77889980",
                    padding: "1.2rem",
                  }}
                >
                  <TypographyTextColor
                    Color="heading"
                    style={{ fontWeight: 600 }}
                    component="h6"
                  >
                    Type
                  </TypographyTextColor>
                  <ul
                    className="filter-panel"
                    style={{
                      minWidth: fruitType.length > 12 ? "290px" : "100%",
                    }}
                  >
                    {fruitType.map((ele, i) => {
                      return (
                        <li key={i}>
                          <FormControl>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={
                                    "checkboxChecked" in ele
                                      ? ele.checkboxChecked
                                      : false
                                  }
                                  onChange={() =>
                                    props.handleChangeCheckboxForProductType(
                                      ele.product_type
                                    )
                                  }
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={
                                <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.product_type)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                              }
                            />

                            {/* <div className="count">
                                            {row.item_count}
                                          </div> */}
                          </FormControl>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Typography>
        </Paper>
      </Popover>
    </div>
  );
};

export default FilterButton;
