import React from "react";
import { Route, Redirect } from "react-router-dom";
// import Layout from './../components/common/Layout';
import { userAuthValivate } from "../actions/login/loginAction";

const checkIsLogged = () => {
  if (JSON.parse(localStorage.getItem("isLogin")) === true) {
    userAuthValivate().then((res) => {
      console.log(res);
    });
  }
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // checkIsLogged();
      return JSON.parse(localStorage.getItem("isLogin")) === true || localStorage.getItem("Guest_userid")!==null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
