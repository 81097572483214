export const userDashboardMenu = [
  {
    name: "My Profile",
    url: "address",
    iconName: "icon-user-main",
    iconType: "fa",
  },
  { name: "Orders", url: "orders", iconName: "icon-box", iconType: "fa" },
  {
    name: "My Lists",
    url: "favouritelist",
    iconName: "FaRegClipboard",
    iconType: "react",
  },
  {
    name: "Payment Method",
    url: "paymentmethod",
    iconName: "FaRegCreditCard",
    iconType: "react",
  },
  {
    name: "Settings",
    url: "settings",
    iconName: "icon-setting",
    iconType: "fa",
  },
  { name: "Logout", url: "", iconName: "icon-logout", iconType: "fa" },
];
