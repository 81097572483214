import React from "react";
import {
  TypographyPrimaryBgColorDimmed,
  TypographyTextColor,
} from "./UiDesign";
import { Divider, Typography } from "@mui/material";
import VISA from "../../assets/images/visa.png";
import MASTERCARD from "../../assets/images/mastercard.png";
import AMEX from "../../assets/images/amex.png";
import DISCOVER from "../../assets/images/discover.png";
import EBTLOGO from "../../assets/images/logo-snap.png";
import { MdVerifiedUser } from "react-icons/md";
import { BsCashStack } from "react-icons/bs";
const PaymentSection = (props) => {
  const {
    ebtorderAmount,
    isShowEBT,
    paymentOption,
    chooseEBTPayment,
    EBTcarddata,
    ebtisSelected,
    ebtAmount,
    isebtpayment,
    originalebtorderAmount,
    is_show_customer_credit,
    creditData,
    chooseCardPayment,
    cardData,
    total_price,
    tips_amount,
    chooseCashPayment,
    web_customize
  } = props;
  return (
    <div className="p-2 order_summ">
      <TypographyTextColor
        Color="heading"
        style={{ fontSize: "1.5rem" }}
        component="h5"
      >
        PAYMENT METHOD
      </TypographyTextColor>
      <div className="row row-cols-2">
        {ebtorderAmount !== 0 && isShowEBT ? (
          <div className="col">
            {EBTcarddata?.length > 0
              ? EBTcarddata.map((val, i) => {
                  return (
                    <>
                      <Typography
                        key={i}
                        component={
                          ebtisSelected === val.id
                            ? TypographyPrimaryBgColorDimmed
                            : "div"
                        }
                        border={0}
                        className={`card-items py-3`}
                      >
                        <div
                          className="d-flex justify-content-between pl-2 flex-wrap"
                          onClick={() => {
                            localStorage.setItem(
                              "selectedPaymentoption",
                              val.id
                            );
                            props.handleaddebtCard(val.token, false, false);
                          }}
                        >
                          <div
                            style={{
                              width:
                                window.screen.availWidth <= 700 ? "100%" : "",
                            }}
                            // className="p-1"
                          >
                            <input
                              type="radio"
                              style={{ transform: "scale(1.5)" }}
                              checked={ebtisSelected === val.id}
                              name="isSelected"
                            />
                            <span className="card-label">
                              <TypographyTextColor
                                Color="heading"
                                component="strong"
                                className="ml-2"
                              >
                                SNAP
                              </TypographyTextColor>
                              <TypographyTextColor
                                Color="other"
                                component="span"
                                className="mx-3"
                              >
                                {val.card_number}
                              </TypographyTextColor>
                            </span>
                          </div>
                        </div>
                      </Typography>
                    </>
                  );
                })
              : ""}
            <Typography
              component={
                paymentOption === "ebt" && chooseEBTPayment
                  ? TypographyPrimaryBgColorDimmed
                  : "div"
              }
              border={0}
              className={`card-items`}
            >
              <div
                className="d-flex justify-content-between pl-2"
                onClick={(e) => {
                  props.chosePaymentOption("ebt", true);
                }}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.5)" }}
                    checked={paymentOption === "ebt" && chooseEBTPayment}
                    name="card payment"
                  />
                  <TypographyTextColor
                    Color="other"
                    component="span"
                    className="ml-2 card-label"
                  >
                    SNAP (EBT)
                  </TypographyTextColor>
                  <div className="card-items">
                    <img
                      src={EBTLOGO}
                      style={{ width: "30px" }}
                      alt="MASTERCARD"
                      // className="mr-2"
                    />
                  </div>
                </div>
              </div>
            </Typography>

            {ebtorderAmount !== 0 ? (
              <>
                {ebtAmount !== 0 ? (
                  <div style={{ top: "10px", position: "relative" }}>
                    <TypographyTextColor
                      Color="other"
                      component="span"
                      className="ml-2 mt-2 card-label"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={isebtpayment}
                        onClick={(e) => props.EnableEditBalance()}
                        style={{ transform: "scale(1.5)" }}
                      />
                      EBT balance is ${ebtAmount}, you can pay{" "}
                      <input
                        style={{ width: "100px" }}
                        value={ebtorderAmount}
                        // onFocus={(e) => {
                        //   e.stopPropagation();
                        //   this.handleSelectCard(
                        //     val.id,
                        //     "cvv",
                        //     e.target.value
                        //   );
                        // }}
                        onChange={(e) => {
                          props.allowOnlyNumericsOrDigits(e);
                        }}
                        type="text"
                        disabled={!isebtpayment}
                      />
                    </TypographyTextColor>
                    <div style={{ color: "red" }}>
                      * you can only pay ${originalebtorderAmount}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
            <Divider className="m-2"  style={{background:web_customize?.TextColor?.heading,height:'2px'}} />
          </div>
        ) : (
          <></>
        )}

        {is_show_customer_credit &&
        creditData?.customerCreditStatus?.hasCredit ? (
          <div className="col">
            <div style={{ top: "10px", position: "relative" }}>
              <TypographyTextColor
                Color="other"
                component="span"
                className="ml-2 mt-2 card-label"
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={creditData.isPaidByCredit}
                  onClick={() => {
                    let data = creditData;
                    data.isPaidByCredit = !data.isPaidByCredit;
                    console.log(data);
                    if (data.isPaidByCredit)
                      this.setState({ paymentOption: "C_Credit" });
                    this.setState({
                      creditData: data,
                    });
                  }}
                  style={{ transform: "scale(1.5)" }}
                />
                Available Credit is $
                {creditData?.customerCreditStatus?.availableCredit}, you can pay{" "}
                <input
                  style={{ width: "100px" }}
                  value={creditData.paymentAmount}
                  onChange={(e) => {
                    this.allowOnlyNumericsOrDigitscredits(e);
                  }}
                  type="text"
                  disabled={!creditData.isPaidByCredit}
                />
              </TypographyTextColor>
            </div>
          </div>
        ) : (
          <></>
        )}

        {localStorage.getItem("PaymentOptionVisibility") &&
        (localStorage.getItem("PaymentOptionVisibility") === "only_card" ||
          localStorage.getItem("PaymentOptionVisibility") === "cod_card") ? (
          <div className="col">
            {cardData?.length > 0 ? (
              <div>
                {cardData.map((val) => {
                  return (
                    <Typography
                      component={
                        val.isSelected ? TypographyPrimaryBgColorDimmed : "div"
                      }
                      border={0}
                      className={`card-items`}
                    >
                      <div
                        className="d-flex flex-wrap pl-2"
                        onClick={(e) => {
                          props.handleSelectCard(val.id, "isSelected");
                        }}
                      >
                        <div
                          style={{
                            width:
                              window.screen.availWidth <= 700 ? "100%" : "",
                          }}
                        >
                          <input
                            type="radio"
                            style={{ transform: "scale(1.5)" }}
                            checked={val.isSelected === val.id}
                            name="isSelected"
                          />
                          <span className="card-label">
                            <TypographyTextColor
                              Color="heading"
                              component="strong"
                              className="ml-2"
                            >
                              {val.card_type}
                            </TypographyTextColor>
                            <TypographyTextColor
                              Color="other"
                              component="span"
                              className="mx-3"
                            >
                              {val.card_number}
                            </TypographyTextColor>
                          </span>
                          {/* <div> */}
                          <img
                            src={
                              val.card_type === "Visa"
                                ? VISA
                                : val.card_type === "MasterCard"
                                ? MASTERCARD
                                : val.card_type === "Amex"
                                ? AMEX
                                : DISCOVER
                            }
                            class="card-img"
                            alt=""
                          />
                          {val.isauthorize && (
                            <MdVerifiedUser
                              style={{
                                color: "#008000",
                                fontSize: "20px",
                              }}
                            />
                          )}
                          {/* </div> */}
                          <TypographyTextColor
                            Color="other"
                            component="span"
                            className="ml-1"
                          >
                            {(isebtpayment && ebtAmount !== 0) ||
                            (is_show_customer_credit &&
                              creditData.isPaidByCredit)
                              ? "($ " +
                                Math.abs(
                                  parseFloat(total_price) +
                                    tips_amount -
                                    ((isebtpayment
                                      ? parseFloat(ebtorderAmount)
                                      : 0) +
                                      (creditData.isPaidByCredit &&
                                      creditData?.paymentAmount !== ""
                                        ? parseFloat(creditData?.paymentAmount)
                                        : 0))
                                ).toFixed(2) +
                                ")"
                              : ""}
                          </TypographyTextColor>
                        </div>
                        <TypographyTextColor
                          Color="other"
                          component="div"
                          style={{
                            alignSelf: "center",
                            marginLeft:
                              window.screen.availWidth <= 700 ? "25px" : "",
                          }}
                        >
                          {val?.card_holder_name === null
                            ? "Name"
                            : val.card_holder_name}
                        </TypographyTextColor>
                      </div>
                      {val.isSelected && (
                        <TypographyTextColor
                          Color="other"
                          component="div"
                          className="px-5"
                        >
                          Enter CVV :{" "}
                          <input
                            name="cvv"
                            style={{ width: "100px" }}
                            value={val.cvv}
                            onFocus={(e) => {
                              e.stopPropagation();
                              props.handleSelectCard(
                                val.id,
                                "cvv",
                                e.target.value
                              );
                            }}
                            onChange={(e) => {
                              props.handleSelectCard(
                                val.id,
                                "cvv",
                                e.target.value
                              );
                            }}
                            type="text"
                            min-length="3"
                            max-length="4"
                            autoComplete={false}
                          />
                        </TypographyTextColor>
                      )}
                    </Typography>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            <Typography
              component={
                paymentOption === "card_payment" && chooseCardPayment
                  ? TypographyPrimaryBgColorDimmed
                  : "div"
              }
              border={0}
              className={`card-items py-3`}
            >
              <div
                className="d-flex justify-content-between pl-2"
                onClick={(e) => {
                  props.chosePaymentOption("card_payment", true);
                }}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.5)" }}
                    checked={
                      paymentOption === "card_payment" && chooseCardPayment
                    }
                    name="card payment"
                  />
                  <TypographyTextColor
                    Color="other"
                    component="span"
                    className="ml-2 card-label"
                  >
                    credit or debit card
                  </TypographyTextColor>
                  <div className="card-items">
                    <img src={VISA} alt="visa" className="mr-2" />
                    <img src={MASTERCARD} alt="MASTERCARD" className="mr-2" />
                    <img src={AMEX} alt="AMEX" className="mr-2" />
                    <img src={DISCOVER} alt="DISCOVER" />
                  </div>
                </div>

                {chooseCardPayment === "card_payment" && (
                  <div className="px-5">Enter CVV :</div>
                )}
              </div>
            </Typography>
          </div>
        ) : (
          <></>
        )}

        {/* <Divider className="mt-2" />
         */}
        <div className="col">
          {localStorage.getItem("PaymentOptionVisibility") &&
          (localStorage.getItem("PaymentOptionVisibility") === "no_option" ||
            localStorage.getItem("PaymentOptionVisibility") === "cod_card") ? (
            <>
              {" "}
              <Typography
                component={
                  (paymentOption === "cash_payment" ||
                    paymentOption === "pickup_pay") &&
                  chooseCashPayment
                    ? TypographyPrimaryBgColorDimmed
                    : "div"
                }
                border={0}
                className={`card-items py-3`}
              >
                <div
                  className="d-flex justify-content-between pl-2"
                  onClick={(e) => {
                    props.chosePaymentOption("cash_payment", false);
                  }}
                >
                  <div
                    style={{
                      width: window.screen.availWidth <= 700 ? "100%" : "",
                    }}
                    // className="p-1"
                  >
                    <input
                      type="checkbox"
                      style={{ transform: "scale(1.5)" }}
                      checked={
                        (paymentOption === "cash_payment" ||
                          paymentOption === "pickup_pay") &&
                        chooseCashPayment
                      }
                      name="cash payment"
                    />
                    <TypographyTextColor
                      Color="other"
                      component="span"
                      className="mx-2 card-label"
                    >
                      Cash on delivery&nbsp;
                      <BsCashStack
                        className="pl-1"
                        style={{
                          fontSize: "2rem",
                          color: "green",
                        }}
                      />
                      <span className="ml-1">
                        {" "}
                        {(isebtpayment && ebtAmount !== 0) ||
                        (is_show_customer_credit && creditData.isPaidByCredit)
                          ? "($ " +
                            Math.abs(
                              total_price +
                                tips_amount -
                                ((isebtpayment
                                  ? parseFloat(ebtorderAmount)
                                  : 0) +
                                  (creditData.isPaidByCredit &&
                                  creditData?.paymentAmount !== ""
                                    ? parseFloat(creditData?.paymentAmount)
                                    : 0))
                            ).toFixed(2) +
                            ")"
                          : ""}
                      </span>
                    </TypographyTextColor>
                  </div>
                </div>
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSection;
