import React, { Component } from "react";
import { Modal, CircularProgress } from "@mui/material";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import SubCategoryModal from "../Category/SubCategoryModal";
import { SubCategeriesListView } from "../../../actions/home/homeAction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RefreshLogin } from "../../../actions/login/loginAction";
import { CommonLogoutFunctionlity } from "../../../utils/renderUtils/renderUtil";
import CategoryCard from "../../HomePage/CategoryCard";
import { ScrollColorTypography, TypographyTextColor } from "../UiDesign";

class CategoriesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_inner: false,
      open_subDetails: false,
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      SubcategoryName: "",
      LoaderForSubCategory: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
    };
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    this.handleSubInnerOpenDialog = this.handleSubInnerOpenDialog.bind(this);
    this.handleSubInnerCloseDialog = this.handleSubInnerCloseDialog.bind(this);
    this.handleInnerInnerCloseDialog =
      this.handleInnerInnerCloseDialog.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.SubCategoryDetails !== nextProps.SubCategoryDetails) {
      this.setState({ SubCategoryDetails: nextProps.SubCategoryDetails });
    }
    if (this.props.SubSubCategoryDetails !== nextProps.SubSubCategoryDetails) {
      this.setState({ SubSubCategoryDetails: nextProps.SubSubCategoryDetails });
    }

    if (this.props.parentIds !== nextProps.parentIds) {
      this.setState({ parentIds: nextProps.parentIds });
    }
    if (this.props.parent_onlyid !== nextProps.parent_onlyid) {
      this.setState({ parent_onlyid: nextProps.parent_onlyid });
    }
    if (this.props.parent_level !== nextProps.parent_level) {
      this.setState({ parent_level: nextProps.parent_level });
    }
  }
  bindCategorygroupdata = (data, urlcategoryid) => {
    console.log(data);
    let categorydata = structuredClone(data);
    if (Array.isArray(categorydata))
      categorydata.sort((a, b) => a.name.localeCompare(b.name));

    for (let i = 0; i < categorydata.length; i++) {
      if (
        categorydata[i].id === +urlcategoryid &&
        categorydata[i].has_child === false
      ) {
        return { category: [categorydata[i]], parent: categorydata[i] };
      }
    }
  };
  handleInnerOpenDialog(
    e,
    subcategory_name,
    has_child,
    level,
    parent_id,
    data
  ) {
    let categorygroup = this.bindCategorygroupdata(
      this.props.CategoryDetails,
      e
    );
    if (has_child === true) {
      this.props.history.history.push({
        ...this.props.history.history.location.state,
        state: {
          parentCategorydata: data,
          category_id: e,
          parent_id: parent_id,
        },
      });

      this.setState({
        open_inner: level === 0 ? true : false,
        SubcategoryName: subcategory_name,
        open_subDetails: false,
      });
      if (this.props.pageName === "home_page") {
        this.props.FetchSubCategoryList(e, level, parent_id);
      } else {
        this.FetchSubAndSubsubCategoryList(e, level, parent_id);
      }
    } else if (has_child === false && this.props.pageName === "listing_page") {
      this.props.history.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.props.parentIds,
          parent_onlyid: this.props.parent_onlyid,
          parent_level: this.props.parent_level,
          CategoryDetails: this.props.CategoryDetails,
          selectedgroupcat: categorygroup,
          customCategoryMsg: data?.category_message,
        },
      });
      this.props.redirectingListingPage(e);
    } else {
      this.props.history.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
          selectedgroupcat: categorygroup,
          customCategoryMsg: data?.category_message,
        },
      });
    }
  }
  handleInnerCloseDialog() {
    this.setState({ open_inner: false, open_subDetails: false });
  }

  handleSubInnerOpenDialog() {
    this.setState({ open_subDetails: true });
  }
  handleSubInnerCloseDialog() {
    this.setState({ open_subDetails: false });
  }

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        // this.FetchCategoryList();
        // this.FetchCartItemList();
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    CommonLogoutFunctionlity();
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    this.setState({ open_inner: false, open_subDetails: false });
    this.props.history.history.push({
      pathname: "/",
    });
  };

  FetchSubAndSubsubCategoryList = (category_id, level, parent_id) => {
    // console.log('category_id', category_id);
    // console.log('level', level);
    // console.log('parent_id', parent_id);
    let formValues = {
      ids: category_id,
      limit: 100,
    };

    if (category_id === this.state.parent_onlyid[0]) {
      this.setState({
        parentIds: [this.state.parentIds[0]],
        parent_onlyid: [this.state.parent_onlyid[0]],
        parent_level: [this.state.parent_level[0]],
      });
    } else this.commonFetchSubCategory(category_id, level, parent_id);
    this.setState({ LoaderForSubCategory: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryDetails = [];
      let subsubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryDetails = res.data.result;
        } else {
          subsubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubAndSubsubCategoryList(
                formValues.ids,
                level,
                parent_id
              );
            }
          );
        }
      }
      // console.log('subCategoryDetails', subCategoryDetails);
      // console.log('subsubCategoryDetails', subsubCategoryDetails);
      this.setState({
        SubCategoryDetails: subCategoryDetails,
        SubSubCategoryDetails: subsubCategoryDetails,
        LoaderForSubCategory: false,
        // open_inner: level === 1? false: true,
      });
    });
  };

  commonFetchSubCategory = (category_id, level, parent_id) => {
    let selectedIndex = this.state.parentIds.indexOf(category_id);
    let selectIndexForOnlyParentID =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndex = this.state.parent_level.indexOf(level);
    let newParentIDSelect = [];
    let newParentonlyIDSelect = [];
    let newParentLevelSelect = [];

    if (selectIndexForOnlyParentID === -1) {
      newParentonlyIDSelect = newParentonlyIDSelect.concat(
        this.state.parent_onlyid,
        category_id
      );
    } else if (selectIndexForOnlyParentID === 0) {
      newParentonlyIDSelect = newParentonlyIDSelect.concat(
        this.state.parent_onlyid.slice(1)
      );
    } else if (
      selectIndexForOnlyParentID ===
      this.state.parent_onlyid.length - 1
    ) {
      newParentonlyIDSelect = newParentonlyIDSelect.concat(
        this.state.parent_onlyid.slice(0, -1)
      );
    } else if (selectIndexForOnlyParentID > 0) {
      newParentonlyIDSelect = newParentonlyIDSelect.concat(
        this.state.parent_onlyid.slice(0, selectIndexForOnlyParentID)
      );
    }
    if (selectParentLevelIndex === -1) {
      newParentLevelSelect = newParentLevelSelect.concat(
        this.state.parent_level,
        parent_id
      );
    } else if (selectParentLevelIndex === 0) {
      newParentLevelSelect = newParentLevelSelect.concat(
        this.state.parent_level.slice(1)
      );
    } else if (selectParentLevelIndex === this.state.parent_level.length - 1) {
      newParentLevelSelect = newParentLevelSelect.concat(
        this.state.parent_level.slice(0, -1)
      );
    } else if (selectParentLevelIndex > 0) {
      newParentLevelSelect = newParentLevelSelect.concat(
        this.state.parent_level.slice(0, selectParentLevelIndex)
      );
    }
    if (selectedIndex === -1) {
      newParentIDSelect = newParentIDSelect.concat(
        this.state.parentIds,
        category_id + "" + level
      );
    } else if (selectedIndex === 0) {
      newParentIDSelect = newParentIDSelect.concat(
        this.state.parentIds.slice(1)
      );
    } else if (selectedIndex === this.state.parentIds.length - 1) {
      newParentIDSelect = newParentIDSelect.concat(
        this.state.parentIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newParentIDSelect = newParentIDSelect.concat(
        this.state.parentIds.slice(0, selectedIndex)
      );
    }

    this.setState({
      parentIds: newParentIDSelect,
      parent_onlyid: newParentonlyIDSelect,
      parent_level: newParentLevelSelect,
    });
  };

  handleInnerInnerCloseDialog() {
    this.setState({
      open_inner: false,
      open_subDetails: false,
      parent_onlyid: [],
    });
  }

  addDefaultCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  handleOpenDialog() {
    this.setState({ open_inner: false, parent_onlyid: [] });
  }

  render() {
    const { CategoryDetails, categoryLoader } = this.props;
    const { open_subDetails } = this.state;
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.handleCloseDialog}
        className="modal_main"
      >
        <ScrollColorTypography component={"div"} className="dialog_wrapper">
          <div className="menu_head">
            <div className="menu_title">
              <div className="specified_details">
                <ul>
                  <TypographyTextColor
                    component={"li"}
                    Color="other"
                    style={{ cursor: "default" }}
                    // onClick={() => this.handleOpenDialog()}
                  >
                    All
                  </TypographyTextColor>
                </ul>
              </div>
            </div>
            <div
              className="close_button"
              onClick={this.props.handleCloseDialog}
            >
              <span className="icon-close"></span>
            </div>
          </div>
          <div className="menu-head-mobile d-block d-lg-none">
            <ul className="list-inline mb-0">
              <li
                className="list-inline-item"
                onClick={this.props.handleCloseDialog}
              >
                <ArrowBackIosIcon />
              </li>
              <li className="list-inline-item font-weight-bold">
                All Categories
              </li>
            </ul>
          </div>
          <div className="menu_body">
            <div className="product-category-container p-0">
              {categoryLoader ? (
                <CircularProgress
                  style={{
                    display: "flex",
                    color: "#3d9b7b",
                    position: "relative",
                    right: "-35%",
                    "& > * + *": {
                      marginLeft: 2,
                    },
                  }}
                />
              ) : CategoryDetails && CategoryDetails.length > 0 ? (
                CategoryDetails.sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => a.cat_order - b.cat_order)
                  .map((row, index) => {
                    return (
                      <div
                        key={index}
                        className="product-category-modal"
                        onClick={this.handleInnerOpenDialog.bind(
                          this,
                          row.id,
                          row.name,
                          row.has_child,
                          row.level,
                          row.parent_id,
                          row
                        )}
                      >
                        <CategoryCard category={row} />
                      </div>
                    );
                  })
              ) : (
                ""
              )}
              <SubCategoryModal
                open_inner={this.state.open_inner}
                handleOpenDialog={this.handleOpenDialog}
                SubcategoryName={this.state.SubcategoryName}
                handleInnerOpenDialog={this.handleInnerOpenDialog}
                handleInnerCloseDialog={this.handleInnerCloseDialog}
                SubCategoryDetails={this.state.SubCategoryDetails}
                history={this.props.history}
                open_subDetails={open_subDetails}
                pageName={this.props.pageName}
                handleCloseDialog={this.props.handleCloseDialog}
                redirectingListingPage={(category_id) =>
                  this.props.redirectingListingPage(category_id)
                }
                subCategoryLoader={this.state.LoaderForSubCategory}
                SubSubCategoryDetails={this.state.SubSubCategoryDetails}
                FetchSubCategoryList={(id, level, parent_id) =>
                  this.FetchSubAndSubsubCategoryList(id, level, parent_id)
                }
                parentIds={
                  this.props.pageName === "home_page"
                    ? this.props.parentIds
                    : this.state.parentIds
                }
                parent_onlyid={
                  this.props.pageName === "home_page"
                    ? this.props.parent_onlyid
                    : this.state.parent_onlyid
                }
                parent_level={
                  this.props.pageName === "home_page"
                    ? this.props.parent_level
                    : this.state.parent_level
                }
                CategoryDetails={
                  this.props.pageName === "home_page"
                    ? this.props.CategoryDetails
                    : this.state.CategoryDetails
                }
                tree_data={this.props.tree_data}
              />
              {/* }  */}
            </div>
          </div>
        </ScrollColorTypography>
      </Modal>
    );
  }
}
export default CategoriesModal;
