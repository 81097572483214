import React, { Component } from "react";
import { Link } from "react-router-dom";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import LoderImage from "../../../assets/images/more.png";
import SubCategoryModal from "../Category/SubCategoryModal";
import {
  AddToCartFunctionlity,
  UpdateCartFunctionlity,
  WishListFunctionlity,
  SubCategeriesListView,
} from "../../../actions/home/homeAction";
import {
  Modal,
  Checkbox,
  FormGroup,
  Dialog,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  IconButton,
  Grid,
  Typography,
  Chip,
  TextField,
} from "@mui/material";
import { DeleteCrtIemFunctionlity } from "../../../actions/cart/cartAction";

import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateFavorite from "../ProductCard/createFavorite";
import {
  AddFavoriteLevelFunctionlity,
  favoriteLevelListFunctionlity,
  AddFavoriteProductFunctionlity,
  RemoveFavouriteProduct,
} from "../../../actions/favorite/favoriteAction";
import Loginpopup from "../../login/Loginpopup";
import CommonAlertBox from "../CommonAlertBox";

import {
  RefreshApiToken,
  SinglecheckImageUrls,
  slugifyStr,
  sweetAlertBox,
} from "../../../utils/renderUtils/renderUtil";
import ReactSlick from "./ReactSlick";
import ItemDetailDescription from "../ProductDetail/ItemDetailDescription";
import ProductNoteChipsview from "../Header/ProductNoteChipsview";
import {
  LabelPrimaryColor,
  PrimaryButton,
  SaleLabelySecondaryColor,
  ScrollColorTypography,
  SecondaryButton,
  SecondaryCancelButton,
  TextFormating,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../UiDesign";
import CustomPreloader from "../Loader/CustomPreloader";
import CategoriesModal from "../Category/categoriesModal";

class ItemDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: false,
      OpenQtyCount: false,
      AddtoCartBtn: this.props.AddtoCartBtn === true ? true : false,
      itemCount: 0,
      open_inner: false,
      open_subDetails: false,
      SubCategoryDetails: [],
      SubcategoryName: "",
      next_info: false,
      pre_info: false,
      note: "",
      error: false,
      success: false,
      case_is_check: [],
      ProductImages: [],
      setOpenItemNote: false,
      productNoteItemNote: "",
      backupItemInstraction: "",
      backupItemInstractioArr: [
        "Do not substitute",
        "Substitute with similar",
        "Call me",
      ],
      favoriteLevelDataForItem: [],
      targetvalue: "",
      pro_item_details_ind: this.props.pro_item_details_ind,
      disabled: false,
      SubSubCategoryName: "",
      SubSubCategoryList: [],
      doNotSubstitute: false,
      substituteWithSimilar: false,
      callMe: false,
      open: false,
      setOpenLoginPopup: false,
      categoryNames: "",
      wishlistState: false,
      web_config: JSON.parse(localStorage.getItem("web_customize")),
      restrictByOnhand: JSON.parse(localStorage.getItem("Store_address"))
        ?.restrict_by_onhand,
      isShowItemCode: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_show_item_code,
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    // this.handleSubInnerCloseDialog = this.handleSubInnerCloseDialog.bind(this);
  }

  addFavoiteLevelFunctionlity = (favoriteLevel, product_id) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          this.FetchFavoriteLevelList(product_id);
        }
      }
    );
  };

  OpenLoginPoup = () => {
    this.setState({ setOpenLoginPopup: true });
  };
  FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: product_id,
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelDataForItem = [];
        if (res.data && res.data.result) {
          favoriteLevelDataForItem = res.data.result;
        }
        this.setState({ favoriteLevelDataForItem: favoriteLevelDataForItem });
      }
    );
  };
  removeFromfavouriteProduct = (favoriteproduct_id, product_id) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.join(","),
    };

    RemoveFavouriteProduct(formValues, this.props.dispatch).then((res) => {
      let productData = this.props.ProductDetails;

      if (productData.id === product_id) {
        productData.wishlist = true;
      } else {
        productData.wishlist = false;
      }
      this.setState({ wishlistState: false });
      productData.favoriot_id = [];
    });
  };

  addFavoiteProductFunctionlity = (favoritelabel_id, product_id) => {
    if (favoritelabel_id !== "") {
      let formValues = {
        product_id: this.props.ProductDetails.id,
        favoritelabel_id: favoritelabel_id ? favoritelabel_id.toString() : "",
        quantity: this.props.ProductDetails.added_quantity[
          this.props.pro_item_details_ind
        ]
          ? this.props.ProductDetails.added_quantity[
              this.props.pro_item_details_ind
            ]
          : 0,
        is_case: this.props.ProductDetails.added_quantity_is_case[
          this.props.pro_item_details_ind
        ]
          ? this.props.ProductDetails.added_quantity_is_case[
              this.props.pro_item_details_ind
            ]
          : false,
        note: this.props.ProductDetails.note[this.props.pro_item_details_ind]
          ? this.props.ProductDetails.note[this.props.pro_item_details_ind]
          : "",
        container_type: this.props.container_type
          ? this.props.container_type
          : "",
      };
      AddFavoriteProductFunctionlity(formValues, this.props.dispatch).then(
        (res) => {
          if (res.errors && res.errors.status === 400) {
            this.createSuccessMsg("error", res.errors.data.errors);
            this.props.ProductDetails.wishlist = false;
          } else {
            if (
              this.props.ProductDetails.id === product_id &&
              favoritelabel_id != ""
            ) {
              this.props.ProductDetails.wishlist = true;
              this.setState({
                wishlistState: true,
              });
            } else {
              this.props.ProductDetails.wishlist = false;
              this.setState({
                wishlistState: false,
              });
            }

            this.props.ProductDetails.favoriot_id = res.favorite_ids;
            this.createSuccessMsg("success", res.message);
          }
        }
      );
    }
  };

  openAddQuantityCount = (values, quantity, added_qty_is_case, is_parent) => {
    
    if (quantity > 0) {
      if (values.original_price === 0 && is_parent) {
        this.createSuccessMsg("error", "Please select the item");
      } else {
        if (
          localStorage.getItem("frontend_animation") &&
          localStorage.getItem("frontend_animation") ===
            "must_login_add_to_cart" &&
          localStorage.getItem("isLogin") === "false"
        ) {
          this.OpenLoginPoup();
        } else if (
          localStorage.getItem("slot") === undefined ||
          (localStorage.getItem("slot") === null &&
            localStorage.getItem("frontend_animation") &&
            localStorage.getItem("frontend_animation") !== "open" &&
            localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
        ) {
          this.props.openisslotDeliverypopupState_compwill();
        } else {
          let product_id = values.id;
          this.AddToCartFunctionlity(product_id, quantity, added_qty_is_case);
        }
      }
    }
  };

  componentDidMount() {
    console.log(this.props);

    // this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  CloseLoginDialog = () => {
    this.setState({ setOpenLoginPopup: false });
  };
  filterCategoryBreadcumbs = (data) => {
    let categoryData = this.props.categoryNames;
    let urlcategoryid = this.props.category_id;
    let finalcatData = [];
    if (urlcategoryid === "" || urlcategoryid?.length === 0)
      finalcatData = categoryData[0];
    else {
      for (let i = 0; i < categoryData?.length; i++) {
        for (let j = 0; j < categoryData[i].length; j++) {
          if (
            categoryData[i][j]?.category_id?.toString() ===
            urlcategoryid?.toString()
          ) {
            finalcatData = categoryData[i];
            break;
          }
        }
      }
    }
    console.log(finalcatData, categoryData);
    if (finalcatData?.length === 0 || finalcatData === undefined)
      finalcatData = categoryData[0];

    this.setState({ categoryNames: finalcatData });
  };
  checkingImageUrl = async (data, currentImage) => {
    let localUrl = data?.cloudinary_images_local;
    // CLOUD_URL +
    // "local/" +
    // JSON.parse(localStorage.getItem("Store_address"))?.id +
    // "/product/" +
    // slugifyStr(data.item_code);
    let globalUrl = data?.cloudinary_images_global; //CLOUD_URL + "global/product/" + slugifyStr(data.item_code);

    let globalPromises = [];
    let localPromises = [];

    for (let i = 0; i < localUrl?.length; i++) {
      let finalLocalUrl = localUrl[i];
      localPromises.push(
        SinglecheckImageUrls(finalLocalUrl).then((isValid) => {
          if (isValid === false && i === 0) return 1;
          if (isValid) {
            return {
              url: finalLocalUrl,
              name: i === 0 ? data.item_code : `${data.item_code}_${i}`,
            };
          } else {
            return null;
          }
        })
      );
    }
    for (let i = 0; i < globalUrl?.length; i++) {
      let finalGlobalUrl = globalUrl[i];
      globalPromises.push(
        SinglecheckImageUrls(finalGlobalUrl).then((isValid) => {
          if (isValid) {
            return {
              url: finalGlobalUrl,
              name: i === 0 ? data.item_code : `${data.item_code}_${i}`,
            };
          } else {
            return null;
          }
        })
      );
    }

    const globalResults = await Promise.all(globalPromises);
    const localResults = await Promise.all(localPromises);
    let localData = [];
    const globalData = globalResults.filter((result) => result !== null);
    if (localResults.some((x) => x === 1)) localData = [];
    else localData = localResults.filter((result) => result !== null);

    console.log("Global Data:", globalData);
    console.log("Local Data:", localData);
    let iscloudinary = false;
    let allUrl = [];
    if (localData.length > 0) {
      allUrl = localData.map((x) => x.url);
      iscloudinary = true;
    } else if (globalData.length > 0) {
      allUrl = globalData.map((x) => x.url);
      iscloudinary = true;
    } else {
      allUrl = currentImage?.split(",");
      iscloudinary = false;
    }

    this.setState({ ProductImages: allUrl, iscloudinary });
  };

  componentWillReceiveProps(nextProps) {
    let ProductImages = [];
    if (
      this.props.ProductDetails &&
      this.props.ProductDetails.image &&
      this.props.ProductDetails.image !== ""
    ) {
      let selecteditem = [];

      selecteditem = nextProps?.ProductListData?.filter(
        (ele) => ele.id === nextProps?.ProductDetails.id
      );
      if (selecteditem?.length !== 0 && selecteditem !== undefined)
        this.filterCategoryBreadcumbs(...selecteditem);
      // ProductImages =
      // this.checkingImageUrl(
      //   this.props.ProductDetails,
      //   nextProps.ProductDetails.image
      // );
      // if (nextProps.ProductDetails?.cloudinary_images?.length > 0)
      //   ProductImages = nextProps.ProductDetails.cloudinary_images;
      // else
       ProductImages = nextProps.ProductDetails.image.split(",").filter(x=>x!=="");
       if(ProductImages.length===0)
        ProductImages = [NO_IMAGE];
    } else {
      ProductImages = [NO_IMAGE];
    }
    this.setState({ ProductImages: ProductImages });
  }

  openAddToCart = (added_quantity, added_qty_is_case) => {
    added_quantity = parseInt(added_quantity);
    var added_qtyInd =
      this.props.ProductDetails["added_quantity_is_case"].indexOf(
        added_qty_is_case
      );
    if (added_quantity >= 0) {
      this.props.commonFunctionForContainer(
        added_qtyInd,
        added_quantity,
        added_qty_is_case
      );
    } else {
      this.props.AddtoCartBtn = true;
    }
    this.UpdateCartQty(
      this.props.ProductDetails,
      added_quantity,
      added_qty_is_case
    );
  };

  increaseItemCount = (added_quantity, added_qty_is_case, container_type) => {
    added_quantity = parseInt(added_quantity);
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.OpenLoginPoup();
      // this.props.history.history.push({ pathname: "/login" });
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.props.openisslotDeliverypopupState_compwill();
    } else {
      this.UpdateCartQty(
        this.props.ProductDetails,
        added_quantity,
        added_qty_is_case,
        container_type
      );
    }
  };

  AddToCartFunctionlity = (product_id, quantity, added_qty_is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: added_qty_is_case,
      note: this.state.note,
      container_type: this.props.container_type,
    };
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.AddToCartFunctionlity(
              product_id,
              quantity,
              added_qty_is_case
            );
          });
        }
      } else if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res && res.status === 200) {
        var added_qtyInd =
          this.props.ProductDetails["added_quantity_is_case"].indexOf(
            added_qty_is_case
          );
        this.props.FetchCartCount();
        this.props.commonFunctionForContainer(
          added_qtyInd,
          quantity,
          added_qty_is_case
        );
      }
    });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    product_name,
    quantity,
    is_case,
    btn_icons,
    slug,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: is_case,
      slug: slug,
      note: note,
      container_type: this.props.container_type,
    };
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        if (quantity >= 1) {
          var added_qtyInd =
            this.props.ProductDetails["added_quantity_is_case"].indexOf(
              is_case
            );
          this.props.FetchCartCount();
          this.props.commonFunctionForContainer(
            added_qtyInd,
            quantity,
            is_case
          );
          this.setState({ itemCount: this.state.itemCount + 1 });
        }
      }
      this.setState({ disabled: false });
    });
  };

  redirectDetailsPage = (ProductDetails) => {
    if (this.props.pageName === "details_page") {
      this.props.redirectToDetailsPage(
        ProductDetails.id,
        ProductDetails.slug,
        "",
        ""
      );
    } else {
      this.props.history.history.push({
        pathname: "/product/" + ProductDetails.slug,
        state: {
          // addTocart_btn: this.state.AddtoCartBtn,
          special_filter: this.props.special_filter,
          special_filter_value: this.props.special_filter_value,
          category_id: this.props.category_id,
          selectedChilditem: this.props.selectedChilditem,
          listingFormValues: this.props.listingFormValues,
          ProductListData: this.props.ProductListData,
          selectedProductindex: this.props.selectedProductindex,
          total: this.props.totalProduct,
          categoryNames: this.state.categoryNames,
        },
      });
    }
  };

  deleteCartItem = (
    product_id,
    product_name,
    added_quantity,
    added_quantity_is_case,
    btn_icons,
    slug
  ) => {
    let formValue = {
      product_id: product_id,
      is_case: added_quantity_is_case,
      container_type: this.props.container_type,
    };
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.props.hendleAddtoCartBtn();
        this.props.FetchCartCount();
        if (btn_icons === "close-btn") {
          this.props.handlePopClose(this.state.itemCount);
        } else {
          // console.log('done');
          // this.props.history.history.push({
          //   pathname: "/product/" + slug,
          //   state: {
          //     // addTocart_btn: this.state.AddtoCartBtn,
          //     special_filter: this.props.special_filter,
          //     special_filter_value: this.props.special_filter_value,
          //   },
          // });
        }
      }
    });
  };

  UpdateCartQty = (
    values,
    added_quantity,
    added_quantity_is_case,
    container_type,
    btn_icons
  ) => {
    let product_id = values.id;
    let slug = values.slug;
    let quantity = added_quantity;
    let product_name_lowercase = values.name.toLowerCase();
    let product_name = product_name_lowercase.replace(/\s/g, "-");
    let is_case = added_quantity_is_case;
    let note = values.note[0];
    if (added_quantity >= 1) {
      this.UpdateCartItemFunctionlity(
        product_id,
        product_name,
        quantity,
        is_case,
        btn_icons,
        slug,
        note
      );
    } else {
      this.deleteCartItem(
        product_id,
        product_name,
        added_quantity,
        added_quantity_is_case,
        btn_icons,
        slug
      );
    }
  };

  handlePopClose(e, values, added_quantity, added_quantity_is_case, btn_icons) {
    let product_id = values.id;
    let slug = values.slug;
    let quantity = added_quantity;
    let product_name_lowercase = values.name.toLowerCase();
    let product_name = product_name_lowercase.replace(/\s/g, "-");
    let is_case = added_quantity_is_case;
    if (added_quantity > 0) {
      this.UpdateCartItemFunctionlity(
        product_id,
        product_name,
        quantity,
        is_case,
        btn_icons,
        slug
      );
    } else {
      this.deleteCartItem(
        product_id,
        product_name,
        added_quantity,
        added_quantity_is_case,
        btn_icons,
        slug
      );
    }
    // this.setState({ AddtoCartBtn: this.props.AddtoCartBtn });
  }

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {
      
    });
  };

  HandelChangeFalseWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    this.setState({ isLogin: login_status });
    if (this.props.ProductDetails.id === product_id) {
      this.props.ProductDetails.wishlist = value;
    }

    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      // this.props.history.history.push({
      //   pathname: "/login",
      // });
      this.OpenLoginPoup();
    }
  };

  HandelChangeTrueWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    if (this.props.ProductDetails.id === product_id) {
      this.props.ProductDetails.wishlist = value;
    }
    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      // this.props.history.history.push({
      //   pathname: "/login",
      // });
      this.OpenLoginPoup();
    }
  };

  FetchSubCategoryList = (category_id, level, parent_id) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      }
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryList: SubSubCategoryDetails,
      });
    });
  };

  handleInnerOpenDialog(
    e,
    parent_id,
    subcategory_name,
    has_child,
    cslug,
    all_data
  ) {
    let SubcategoryName = this.state.SubcategoryName;
    let SubSubCategoryName = this.state.SubSubCategoryName;
    if (all_data.length === 2) {
      for (let i = 0; i < all_data.length; i++) {
        if (all_data[i].category_name === subcategory_name) {
          SubcategoryName = all_data[i].category_name;
        } else {
          SubSubCategoryName =
            parent_id != null ? all_data[i].category_name : "";
        }
      }
    } else {
      SubcategoryName = all_data[0].category_name;
      SubSubCategoryName = parent_id != null ? all_data[1].category_name : "";
    }
    if (has_child === true) {
      this.setState({
        open_inner: true,
        SubcategoryName: SubcategoryName,
        SubSubCategoryName: SubSubCategoryName,
        open_subDetails: false,
      });
      this.FetchSubCategoryList(e, 0, parent_id);
    } else {
      this.props.history.history.push({
        pathname: "/listing",
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false, open_subDetails: false });
  }

  addnote = (value) => {
    if (value) {
      this.props.ProductDetails.note = value;
      this.setState({ note: value });
    } else {
      this.setState({ note: "" });
    }
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg.quantity && msg.quantity) {
        this.props.ProductDetails.note[this.props.pro_item_details_ind] = "";
        sweetAlertBox(
          "Attention!!",
          msg.quantity[0] === "This field is required."
            ? "At least 1 Quantity is required"
            : msg.quantity[0],
          "info"
        );

        this.setState({
          // error:
          //   msg.quantity[0] === "This field is required."
          //     ? "At least 1 Quantity is required"
          //     : msg.quantity[0],
          // success: false,
          productNoteItemNote: "",
          backupItemInstraction: "",
        });
      } else if (msg.container_type && msg.container_type) {
        sweetAlertBox("Attention!!", msg.container_type[0], "info");

        // this.setState({
        //   error: msg.container_type[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");

        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  OpenDialog = () => {
    if (
      this.props.ProductDetails.added_quantity[
        this.props.pro_item_details_ind
      ] === undefined
    )
      this.createSuccessMsg("error", "Atleast 1 Quantity is required");
    else this.setState({ setOpenItemNote: true, productNoteItemNote: "" });
  };
  CloseDialog = (value_icon) => {
    let productNote = this.state.productNoteItemNote;
    let mainNote = this.props.ProductDetails.note[0];
    if (value_icon === "Cancel") {
      this.setState({ setOpenItemNote: false });
    } else {
      if (
        productNote === "" &&
        this.state.doNotSubstitute === false &&
        this.state.substituteWithSimilar === false &&
        this.state.callMe === false
      ) {
        this.createSuccessMsg("error", "Please enter product note");
      } else {
        mainNote =
          mainNote === "" || mainNote === null ? "" : mainNote.split("~");
        
        if (mainNote.length > 1) {
          if (productNote !== "") {
            mainNote[0] = productNote;
          }
          
          if (this.state.doNotSubstitute) {
            mainNote[1] = "Do not substitute";
          } else if (this.state.substituteWithSimilar)
            mainNote[1] = "Substitute with similar";
          else if (this.state.callMe) mainNote[1] = "Call me";
          else mainNote.pop();
          mainNote = mainNote.join("~");
        } else {
          if (productNote !== "") {
            mainNote = productNote;
          }
          if (this.state.doNotSubstitute) {
            mainNote = mainNote + "~" + "Do not substitute";
          } else if (this.state.substituteWithSimilar)
            mainNote = mainNote + "~" + "Substitute with similar";
          else if (this.state.callMe) mainNote = mainNote + "~" + "Call me";
        }

        this.props.ProductDetails.note[this.props.pro_item_details_ind] =
          mainNote;
        this.UpdateCartItemFunctionlity(
          this.props.ProductDetails.id,
          this.props.ProductDetails.name,
          this.props.ProductDetails.added_quantity[
            this.props.pro_item_details_ind
          ],
          this.props.ProductDetails.added_quantity_is_case[
            this.props.pro_item_details_ind
          ],
          "icon",
          this.props.ProductDetails.slug,
          this.props.ProductDetails.note[this.props.pro_item_details_ind]
        );
        this.setState({
          setOpenItemNote: false,
          doNotSubstitute: false,
          substituteWithSimilar: false,
          callMe: false,
        });
      }
    }
  };

  prodcutNote = (event) => {
    this.setState({ productNoteItemNote: event.target.value });
  };
  backupInstractTion = (value) => {
    let doNotSubstitute = this.state.doNotSubstitute;
    let substituteWithSimilar = this.state.substituteWithSimilar;
    let callMe = this.state.callMe;
    let backupItemInstraction = this.state.backupItemInstraction;
    if (value === "Do not substitute") {
      doNotSubstitute = !doNotSubstitute;
      substituteWithSimilar = false;
      callMe = false;
    } else if (value === "Substitute with similar") {
      substituteWithSimilar = !substituteWithSimilar;
      doNotSubstitute = false;
      callMe = false;
    } else {
      callMe = !callMe;
      doNotSubstitute = false;
      substituteWithSimilar = false;
    }
    if (doNotSubstitute === true) {
      backupItemInstraction = "Do not substitute";
    } else if (substituteWithSimilar === true) {
      backupItemInstraction = "Substitute with similar";
    } else if (callMe === true) {
      backupItemInstraction = "Call me";
    } else {
      backupItemInstraction = "";
    }

    this.setState({
      backupItemInstraction,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
    });
  };

  onEditModalOpen = (value) => {
    let backupItemInstraction = this.state.backupItemInstraction;
    let productNoteItemNote = this.state.productNoteItemNote;
    let doNotSubstitute = this.state.doNotSubstitute;
    let substituteWithSimilar = this.state.substituteWithSimilar;
    let callMe = this.state.callMe;

    productNoteItemNote = this.props.ProductDetails.note[0].split("~")[0];
    let selectBacknote = this.props.ProductDetails.note[0].split("~")[1];
    if (selectBacknote === "Do not substitute") {
      doNotSubstitute = true;
    } else {
      doNotSubstitute = false;
    }
    if (selectBacknote === "Substitute with similar") {
      substituteWithSimilar = true;
    } else {
      substituteWithSimilar = false;
    }

    if (selectBacknote === "Call me") {
      callMe = true;
    } else {
      callMe = false;
    }
    this.setState({
      setOpenItemNote: true,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
      productNoteItemNote: productNoteItemNote,
      backupItemInstraction,
    });
  };

  handleChangeRadio = (value) => {
    this.setState({ targetvalue: value });
  };

  handelpopupClose = (
    product_id,
    added_quantity,
    added_quantity_is_case,
    is_case
  ) => {
    this.props.handlePopClose(
      product_id,
      added_quantity,
      added_quantity_is_case,
      is_case
    );
    this.setState({
      doNotSubstitute: false,
      substituteWithSimilar: false,
      callMe: false,
    });
  };
  handleOpenDialog() {
    this.setState({ open: true, open_inner: false, parent_onlyid: [] });
  }

  handleCloseDialog() {
    this.setState({ open: false });
  }
  addDefaultSubCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }
  handleClosePopup = () => {
    let { ProductDetails, Parent_item } = this.props;
    
    if (ProductDetails.is_parent) {
      this.handelpopupClose(
        Parent_item?.id,
        Parent_item?.added_quantity &&
          Parent_item?.added_quantity[this.props.pro_item_details_ind] > 0
          ? Parent_item?.added_quantity[this.props.pro_item_details_ind]
          : 0,
        Parent_item?.added_quantity_is_case &&
          Parent_item?.added_quantity_is_case[
            this.props.pro_item_details_ind
          ] === true
          ? true
          : false,
        Parent_item?.is_case ? true : false
      );
    } else {
      this.handelpopupClose(
        ProductDetails.id,
        ProductDetails.added_quantity &&
          ProductDetails.added_quantity[this.props.pro_item_details_ind] > 0
          ? ProductDetails.added_quantity[this.props.pro_item_details_ind]
          : 0,
        ProductDetails.added_quantity_is_case &&
          ProductDetails.added_quantity_is_case[
            this.props.pro_item_details_ind
          ] === true
          ? true
          : false,
        ProductDetails.is_case ? true : false
      );
    }
  };

  render() {
    const { ProductDetails, productDetailsLoader, prev_rec, next_rec } =
      this.props;
    const {
      categoryNames,
      SubCategoryDetails,
      SubcategoryName,
      open_subDetails,
      error,
      setOpenItemNote,
      favoriteLevelDataForItem,
      isShowItemCode,
      web_config,
    } = this.state;
    const { rimProps, rsProps, containerValue, CategoryDetails } = this.props;

    let msgstr = "Available on ";
    if (
      this.props.ProductDetails &&
      this.props.ProductDetails.availableondays
    ) {
      let dow = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let dowarr = this.props.ProductDetails.availableondays
        ? this.props.ProductDetails.availableondays.split(",")
        : "";
      dowarr = dowarr === "" ? "" : Array.from(new Set(dowarr));
      let finaldowarr = "";
      let dowarrlength = dowarr.length;

      for (let j = 0; j < dowarrlength; j++) {
        finaldowarr = dowarr[j] - 1;
        if (finaldowarr >= 0 && finaldowarr <= 6) {
          if (j == 0) {
            msgstr += dow[finaldowarr];
          } else if (j < dowarrlength - 1) {
            msgstr += ", " + dow[finaldowarr];
          } else {
            msgstr += " and " + dow[finaldowarr];
          }
        }
      }
    }

    let optionTemplate = [];
    if (this.props.container && this.props.container.length > 0) {
      optionTemplate = this.props.container.map((v) => (
        <option value={v.value}>{v.label}</option>
      ));
    }

    return (
      <>
        <Modal
          open={this.props.open}
          onClose={() => this.handleClosePopup()}
          className="modal_main modal_quick"
        >
          <ScrollColorTypography
            component={"div"}
            className="dialog_wrapper dialog_view"
          >
            <div className="menu_head">
              <div className="menu_title">
                <div className="specified_details">
                  <ul>
                    <TypographyTextColor
                      Color="other"
                      component="li"
                      onClick={this.props.handleOpenDialog}
                    >
                      All
                    </TypographyTextColor>
                    {categoryNames && categoryNames.length > 0
                      ? categoryNames.map((row, index) => {
                          return index === categoryNames.length - 1 ? (
                            <TypographyTextColor
                              component="li"
                              Color="heading"
                              onClick={
                                index === 2 && categoryNames.length === 3
                                  ? ""
                                  : index === 1 && categoryNames.length === 2
                                  ? ""
                                  : index === 0 && categoryNames.length === 1
                                  ? ""
                                  : this.handleInnerOpenDialog.bind(
                                      this,
                                      row.category_id,
                                      row.parent_id,
                                      row.category_name,
                                      true,
                                      row.cslug,
                                      categoryNames
                                    )
                              }
                            >
                              {row ? row.category_name : ""}
                            </TypographyTextColor>
                          ) : (
                            <TypographyTextColor
                              Color="other"
                              component="li"
                              onClick={
                                index === 2 && categoryNames.length === 3
                                  ? ""
                                  : index === 1 && categoryNames.length === 2
                                  ? ""
                                  : index === 0 && categoryNames.length === 1
                                  ? ""
                                  : this.handleInnerOpenDialog.bind(
                                      this,
                                      row.category_id,
                                      row.parent_id,
                                      row.category_name,
                                      true,
                                      row.cslug,
                                      categoryNames
                                    )
                              }
                            >
                              {row ? row.category_name : ""}
                            </TypographyTextColor>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
              <div
                className="close_button"
                onClick={() => this.handleClosePopup()}
              >
                <span className="icon-close"></span>
              </div>
            </div>
            {productDetailsLoader ? (
              <div
                style={{ height: "60%" }}
                className="d-flex align-items-center"
              >
                <CustomPreloader />
              </div>
            ) : ProductDetails ? (
              <div className="details_main">
                <div className="navBttns">
                  {prev_rec === true ? (
                    <div
                      className="leftNav"
                      onClick={() => this.props.preProductItem(true, false)}
                    >
                      <span
                        class="icon-left-arrow"
                        style={{ background: "white" }}
                      ></span>
                    </div>
                  ) : (
                    ""
                  )}
                  {next_rec === true ? (
                    <div
                      className="rightNav"
                      onClick={() => this.props.nextProductItem(false, true)}
                    >
                      <span
                        class="icon-left-arrow"
                        style={{ background: "white" }}
                      ></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="details_img_caro">
                  <div className="likecart">
                    <CreateFavorite
                      addFavoiteLevelFunctionlity={(value, product_id) =>
                        this.addFavoiteLevelFunctionlity(value, product_id)
                      }
                      favoriteLevelData={favoriteLevelDataForItem}
                      activePageName={this.props.activePageName}
                      history={this.props.history}
                      addFavoiteProductFunctionlity={(favoritelabel_id) =>
                        this.addFavoiteProductFunctionlity(
                          favoritelabel_id,
                          ProductDetails.id
                        )
                      }
                      wishlist={
                        this?.props?.ProductDetails?.wishlist &&
                        this.state.wishlistState
                          ? true
                          : false
                      }
                      product_id={this.props.ProductDetails.id}
                      FetchFavoriteLevelList={(product_id) =>
                        this.FetchFavoriteLevelList(product_id)
                      }
                      removeFromfavouriteProduct={(favoriot_id, product_id) =>
                        this.removeFromfavouriteProduct(favoriot_id, product_id)
                      }
                      favoriot_id={this?.props?.ProductDetails?.favoriot_id}
                    />
                  </div>
                  {ProductDetails.is_special === true ? (
                    <div className="newsalee">
                      <Typography component={SaleLabelySecondaryColor}>
                        SALE{" "}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  <ReactSlick
                    ProductImages={this.state.ProductImages}
                    ProductDetails={ProductDetails}
                    extraCharges={this.props.extraCharges}
                  />

                  {this.props.extraCharges.is_show_out_of_stock === true &&
                  ProductDetails.out_of_stock === true ? (
                    <div className="out-of-stock" style={{ left: 0 }}>
                      <LabelPrimaryColor
                        component={"span"}
                        border={2}
                        className="text"
                        style={{ fontSize: "20px" }}
                      >
                        Out Of Stock
                      </LabelPrimaryColor>
                    </div>
                  ) : this.props.extraCharges.is_show_out_of_stock === true &&
                    ProductDetails.out_of_stock_case === true &&
                    this.props.chack_is_case === true ? (
                    <div className="out-of-stock" style={{ left: 0 }}>
                      <LabelPrimaryColor
                        component={"span"}
                        border={2}
                        className="text"
                        style={{ fontSize: "20px" }}
                      >
                        Out Of Stock
                      </LabelPrimaryColor>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="details_desc_wrapper">
                  <div className="details_head_wrapper">
                    <div
                    // className={
                    //   ProductDetails?.is_ebt===false&&ProductDetails?.brand_name === null &&
                    //   (ProductDetails?.package === null ||
                    //     ProductDetails?.package?.trim() === "" ||
                    //     ProductDetails?.package === "0.0 0" ||
                    //     ProductDetails?.package === "0.0 ")||

                    //     ? "details_head"
                    //     : ""
                    // }
                    >
                      {" "}
                      {ProductDetails?.sub_description !== "" &&
                      ProductDetails?.sub_description !== null &&
                      ProductDetails?.sub_description !== undefined ? (
                        <div className="item-subdes-container">
                          <TypographyPrimaryBgColorDimmed
                            border={0}
                            component="span"
                            style={{ position: "initial", padding: "5px 10px" }}
                          >
                            {ProductDetails?.sub_description}
                          </TypographyPrimaryBgColorDimmed>
                        </div>
                      ) : (
                        <></>
                      )}
                      <TextFormating
                        component={"h3"}
                        Color="product"
                        className={
                          ProductDetails && ProductDetails.brand_name === null
                            ? "product_empty_brand"
                            : "product_empty_brand"
                        }
                      >
                        {ProductDetails?.name !== undefined
                          ? web_config?.TextTransform?.main === "none"
                            ? ProductDetails.name
                            : ProductDetails.name.toLowerCase()
                          : ""}
                      </TextFormating>
                      <div className="details_type">
                        <TypographyTextColor
                          component="p"
                          Color="other"
                          className="mb-1 brand-name"
                        >
                          {ProductDetails && ProductDetails.brand_name
                            ? ProductDetails.brand_name +
                              `${
                                ProductDetails?.package.trim() !== "" &&
                                ProductDetails?.package !== "0.0 0" &&
                                ProductDetails?.package !== "0.0 "
                                  ? " | "
                                  : ""
                              }`
                            : ""}
                          {ProductDetails &&
                          ProductDetails?.package !== "0.0 0" &&
                          ProductDetails?.package !== "0.0 " &&
                          this.props.itemData?.package?.trim() !== ""
                            ? ProductDetails?.package?.replace(/\.0(?!\d)/g, "")
                            : ""}

                          {(ProductDetails.package?.trim() !== "" ||
                            ProductDetails.brand_name !== null) &&
                          isShowItemCode
                            ? " | "
                            : ""}
                          {isShowItemCode ? ProductDetails.item_code : <></>}
                        </TypographyTextColor>
                      </div>
                    </div>
                  </div>
                  {this.props.child_items &&
                  this.props.child_items.length > 0 &&
                  ProductDetails.is_parent &&
                  this.props.selectedChilditem === "" ? (
                    <></>
                  ) : (
                    <div className="details_pricerow">
                      {ProductDetails && ProductDetails.availableondays ? (
                        <h7 style={{ color: "#f57f18" }}>{msgstr}</h7>
                      ) : (
                        ""
                      )}

                      {ProductDetails.original_price !== 0 ? (
                        <Typography
                          component={
                            ProductDetails.is_special === true
                              ? TypographySecondaryColor
                              : TypographyTextColor
                          }
                          Color="other"
                          Fonttype="other"
                          className={
                            ProductDetails.is_by_weight === true &&
                            ProductDetails.estimate_weight_per_piece > 0
                              ? "details_pricerow_height"
                              : ""
                          }
                        >
                          {this.props.chack_is_case === true
                            ? "$" + Number(ProductDetails.case_price).toFixed(2)
                            : ProductDetails.is_special === true
                            ? ProductDetails.special_quantity > 0
                              ? ProductDetails.special_quantity +
                                "/$" +
                                Number(ProductDetails.special_price).toFixed(2)
                              : "$" +
                                Number(ProductDetails.special_price).toFixed(2)
                            : "$" +
                              Number(ProductDetails.today_price).toFixed(
                                2
                              )}{" "}
                          {ProductDetails &&
                          ProductDetails.is_by_weight === true &&
                          this.props.chack_is_case === false
                            ? "/ LB"
                            : ""}
                          {ProductDetails.is_special === true ? (
                            <TypographyTextColor component="span" Color="other">
                              {"$" +
                                Number(ProductDetails.today_price).toFixed(2)}
                            </TypographyTextColor>
                          ) : (
                            ""
                          )}
                          <h6>
                            <h6>
                              {this.props.chack_is_case
                                ? "($" +
                                  (
                                    ProductDetails.case_price /
                                    ProductDetails.case_quantity
                                  ).toFixed(2)
                                : ""}
                              {ProductDetails &&
                              ProductDetails.is_by_weight === true &&
                              this.props.chack_is_case
                                ? "/ LB each)"
                                : ProductDetails &&
                                  ProductDetails.is_by_weight === false &&
                                  this.props.chack_is_case
                                ? " each)"
                                : ""}{" "}
                            </h6>
                            {ProductDetails.is_by_weight === true &&
                            ProductDetails.estimate_weight_per_piece > 0
                              ? "est. " +
                                Number(
                                  ProductDetails.added_quantity.length > 0
                                    ? ProductDetails.estimate_weight_per_piece *
                                        ProductDetails.added_quantity[
                                          this.props.pro_item_details_ind
                                        ]
                                    : ProductDetails.estimate_weight_per_piece *
                                        1
                                ).toFixed(2) +
                                " LB / $" +
                                Number(
                                  ProductDetails.added_quantity.length > 0
                                    ? ProductDetails.estimate_weight_per_piece *
                                        ProductDetails.added_quantity[
                                          this.props.pro_item_details_ind
                                        ] *
                                        ProductDetails.today_price
                                    : ProductDetails.estimate_weight_per_piece *
                                        1 *
                                        ProductDetails.today_price
                                ).toFixed(2)
                              : ""}

                            {ProductDetails.is_by_weight === true &&
                            containerValue !== ""
                              ? "est. $" +
                                Number(
                                  ProductDetails.today_price *
                                    containerValue *
                                    ProductDetails.added_quantity[
                                      this.props.pro_item_details_ind
                                    ]
                                ).toFixed(2)
                              : ""}
                            {ProductDetails.is_by_weight === true &&
                            containerValue !== ""
                              ? "  ( $" +
                                Number(
                                  ProductDetails.today_price *
                                    containerValue *
                                    1
                                ).toFixed(2) +
                                "/" +
                                containerValue +
                                " LB ) "
                              : ""}
                          </h6>
                          {ProductDetails.is_special === true &&ProductDetails.special_max>0 ? (
                                  <TypographyTextColor
                                    component="h2"
                                    Color="heading"
                                  >
                                  You can add {ProductDetails.special_max} quantity on special price in your cart.
                                  </TypographyTextColor>
                                ) : (
                                  ""
                                )}
                          {ProductDetails.is_offer ? (
                            <>
                              {ProductDetails.original_price === 0 ? (
                                <></>
                              ) : (
                                <span>
                                  {ProductDetails.is_offer
                                    ? ProductDetails.unit
                                    : ""}
                                  {ProductDetails.is_offer
                                    ? ProductDetails.added_quantity_is_case[
                                        this.props.pro_item_details_ind
                                      ] === true
                                      ? Number(
                                          ProductDetails.case_quantity *
                                            ProductDetails.today_price
                                        ).toFixed(2)
                                      : Number(
                                          ProductDetails.original_price
                                        ).toFixed(2)
                                    : ""}
                                </span>
                              )}

                              <span className="price_off">
                                {ProductDetails.is_offer
                                  ? ProductDetails.offer_tag + "%"
                                  : ""}
                                {ProductDetails &&
                                ProductDetails.special_quantity > 0 &&
                                ProductDetails.special_price > 0 &&
                                ProductDetails.offer_msg === "B"
                                  ? " (Price $" +
                                    (
                                      ProductDetails.special_price /
                                      ProductDetails.special_quantity
                                    ).toFixed(2) +
                                    " applied at Minimum 1 quantity"
                                  : ProductDetails &&
                                    ProductDetails.special_quantity > 0 &&
                                    ProductDetails.special_price > 0 &&
                                    ProductDetails.offer_msg === "M"
                                  ? "(Price $" +
                                    (
                                      ProductDetails.special_price /
                                      ProductDetails.special_quantity
                                    ).toFixed(2) +
                                    " applied at Minimum " +
                                    ProductDetails.special_quantity +
                                    " quantity)"
                                  : ProductDetails &&
                                    ProductDetails.special_quantity > 0 &&
                                    ProductDetails.special_price > 0 &&
                                    ProductDetails.offer_msg === "P"
                                  ? "(Price $" +
                                    (
                                      ProductDetails.special_price /
                                      ProductDetails.special_quantity
                                    ).toFixed(2) +
                                    " applied at the Pack of " +
                                    ProductDetails.special_quantity +
                                    " quantity and Maximum " +
                                    ProductDetails.special_max +
                                    " pack)"
                                  : ""}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Typography>
                      ) : (
                        <p className="details_pricerow_height"></p>
                      )}
                    </div>
                  )}
                  <div className="container-section">
                    {this.props.container && this.props.containerLength > 3 ? (
                      <select
                        value={this.props.containerValue}
                        class="form-select"
                        id="exampleFormControlSelect1"
                        aria-label="Default select example"
                        onChange={(e) =>
                          this.props.changeConatinerType(e, "select")
                        }
                      >
                        {optionTemplate}
                      </select>
                    ) : (
                      <RadioGroup
                        value={this.props.containerValue}
                        aria-label="gender"
                        name="customized-radios"
                      >
                        {this.props.container && this.props.container.length > 0
                          ? this.props.container.map((row, index) => {
                              return (
                                <FormControlLabel
                                  value={row.value}
                                  onChange={(e) =>
                                    this.props.changeConatinerType(
                                      e,
                                      "radio",
                                      row.label
                                    )
                                  }
                                  control={<Radio color="primary" />}
                                  label={row.label}
                                />
                              );
                            })
                          : ""}
                      </RadioGroup>
                    )}
                  </div>
                  {this.props.extraCharges.is_show_out_of_stock === true &&
                  ProductDetails.out_of_stock === true &&
                  this.state.restrictByOnhand === true ? (
                    ""
                  ) : (
                    <div className="details_head_wrapper">
                      {this.props.child_items &&
                      this.props.child_items.length > 0 &&
                      ProductDetails.is_parent ? (
                        <select
                          value={this.props.selectedChilditem}
                          //value={this.props.containerValue}
                          class="form-select"
                          id="exampleFormControlSelect2"
                          aria-label="Default select example"
                          onChange={(e) =>
                            this.props.changeChilditemdata(e, "select")
                          }
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <option selected="true" value="" disabled={true}>
                            Select Item
                          </option>
                          {this.props.child_items.map((val) => (
                            <option value={val.id}>{val.name}</option>
                          ))}
                        </select>
                      ) : (
                        <></>
                      )}

                      {this.props.child_items &&
                      this.props.child_items.length > 0 &&
                      ProductDetails.is_parent &&
                      this.props.selectedChilditem === "" ? (
                        <></>
                      ) : this.props.AddtoCartBtn === true &&
                        ProductDetails.instoreonly === false &&
                        (ProductDetails.is_parent ||
                          ProductDetails.is_case === false) &&
                        (ProductDetails.added_quantity.length === 0 ||
                          ProductDetails.added_quantity_is_case.length ===
                            0) ? (
                        <div
                          className="add_cart"
                          onClick={() =>
                            this.openAddQuantityCount(
                              ProductDetails,
                              ProductDetails.added_quantity &&
                                ProductDetails.added_quantity[
                                  this.props.pro_item_details_ind
                                ] > 0
                                ? ProductDetails.added_quantity[
                                    this.props.pro_item_details_ind
                                  ]
                                : 1,
                              this.props.chack_is_case === true ? true : false,
                              ProductDetails.is_parent
                            )
                          }
                        >
                          <SecondaryButton Fonttype="other">
                            Add To Cart
                          </SecondaryButton>
                        </div>
                      ) : ProductDetails.instoreonly === false ? (
                        <div className=" textrow">
                          <Typography
                            component={TypographySecondaryBgColor}
                            className="count_box"
                          >
                            <button
                              className="count_btn"
                              onClick={() =>
                                this.openAddToCart(
                                  ProductDetails.added_quantity &&
                                    ProductDetails.added_quantity[
                                      this.props.pro_item_details_ind
                                    ] > 0
                                    ? ProductDetails.added_quantity[
                                        this.props.pro_item_details_ind
                                      ] - 1
                                    : 0,
                                  this.props.chack_is_case === true
                                    ? true
                                    : false,
                                  ProductDetails.container_type &&
                                    ProductDetails.container_type[
                                      this.props.pro_item_details_ind
                                    ]
                                    ? ProductDetails.container_type[
                                        this.props.pro_item_details_ind
                                      ]
                                    : ""
                                )
                              }
                            >
                              {this.state.disabled ? (
                                <img
                                  className="loaderImg"
                                  src={LoderImage}
                                  alt=""
                                />
                              ) : (
                                <span className="icon-minus"></span>
                              )}
                            </button>
                            <div className="count_no">
                              {this.props.inputField === false ? (
                                <input
                                  type="text"
                                  className="count form-control"
                                  name="quentity"
                                  id={ProductDetails.id}
                                  value={
                                    this.props.pro_item_details_ind > -1
                                      ? ProductDetails.added_quantity[
                                          this.props.pro_item_details_ind
                                        ]
                                      : 0
                                  }
                                  onChange={(e) =>
                                    this.props.addQuentityForaddTocart(
                                      e.target.value,
                                      this.props.chack_is_case === true
                                        ? true
                                        : false,
                                      ProductDetails.container_type &&
                                        ProductDetails.container_type[
                                          this.props.pro_item_details_ind
                                        ]
                                        ? ProductDetails.container_type[
                                            this.props.pro_item_details_ind
                                          ]
                                        : ""
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="count form-control"
                                  name="quentity"
                                  id={ProductDetails.id}
                                  defaultValue={
                                    this.props.pro_item_details_ind > -1
                                      ? ProductDetails.added_quantity[
                                          this.props.pro_item_details_ind
                                        ]
                                      : 0
                                  }
                                  onBlur={this.props.changeInputBoxValue}
                                  onChange={(e) =>
                                    this.props.addQuentityForaddTocart(
                                      e.target.value,
                                      this.props.chack_is_case === true
                                        ? true
                                        : false,
                                      ProductDetails.container_type &&
                                        ProductDetails.container_type[
                                          this.props.pro_item_details_ind
                                        ]
                                        ? ProductDetails.container_type[
                                            this.props.pro_item_details_ind
                                          ]
                                        : ""
                                    )
                                  }
                                />
                              )}
                            </div>
                            <button
                              className="count_btn"
                              onClick={this.increaseItemCount.bind(
                                this,
                                ProductDetails.added_quantity &&
                                  ProductDetails.added_quantity[
                                    this.props.pro_item_details_ind
                                  ] > 0
                                  ? ProductDetails.added_quantity[
                                      this.props.pro_item_details_ind
                                    ] + 1
                                  : 1,
                                this.props.chack_is_case === true
                                  ? true
                                  : false,
                                ProductDetails.container_type &&
                                  ProductDetails.container_type[
                                    this.props.pro_item_details_ind
                                  ]
                                  ? ProductDetails.container_type[
                                      this.props.pro_item_details_ind
                                    ]
                                  : ""
                              )}
                              disabled={this.state.disabled}
                            >
                              {this.state.disabled ? (
                                <img
                                  className="loaderImg"
                                  src={LoderImage}
                                  alt=""
                                />
                              ) : (
                                <span className="icon-plus"></span>
                              )}
                            </button>
                          </Typography>
                          {ProductDetails.is_case === true &&
                          ProductDetails.is_parent === false ? (
                            <TypographyPrimaryBgColorDimmed
                              component={"div"}
                              border={1}
                              color="primary"
                              className="case-cnt"
                              onClick={() =>
                                this.props.ProductCaseFunctionlityForItem(
                                  !this.props.chack_is_case
                                )
                              }
                            >
                              <TypographyFontColor
                                component={"span"}
                                border={0}
                                className="case-box"
                              >
                                <div className="case-line">
                                  <input
                                    type="checkbox"
                                    className="case-checkbox"
                                    checked={
                                      this.props.chack_is_case === true
                                        ? true
                                        : false
                                    }
                                  />
                                  Case of {ProductDetails.case_quantity}
                                </div>
                                {/* <input
                                type="checkbox"
                                name="vehicle1"
                                checked={
                                  this.props.chack_is_case === true
                                    ? true
                                    : false
                                }
                              
                              />
                              <label for="vehicle1">
                                Case of{" "}
                                <span>
                                  {ProductDetails.case_quantity}
                                </span>
                              </label> */}
                              </TypographyFontColor>
                            </TypographyPrimaryBgColorDimmed>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div className="add_cart">
                          <SecondaryButton
                            Fonttype="other"
                            disabled="true"
                            style={{ opacity: "0.7" }}
                          >
                            IN STORE ONLY
                          </SecondaryButton>
                        </div>
                      )}
                      <div className="note_box">
                        {ProductDetails.note &&
                        ProductDetails.note[this.props.pro_item_details_ind] &&
                        ProductDetails.added_quantity[
                          this.props.pro_item_details_ind
                        ] > 0 ? (
                          <>
                            <Grid
                              container
                              spacing={1}
                              className="align-items-baseline"
                            >
                              <Grid item display={"flex"} alignItems={"center"}>
                                <ProductNoteChipsview
                                  size="medium"
                                  ProductDetailsnotes={
                                    ProductDetails.note[
                                      this.props.pro_item_details_ind
                                    ]
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <TypographySecondaryBgColorDimmed
                                  border={1}
                                  color="secondary"
                                  className="notebtn-action"
                                >
                                  {" "}
                                  <EditIcon
                                    onClick={(value) =>
                                      this.onEditModalOpen(
                                        ProductDetails.note[
                                          this.props.pro_item_details_ind
                                        ]
                                      )
                                    }
                                    style={{ fontSize: "1.3rem" }}
                                  />
                                </TypographySecondaryBgColorDimmed>
                              </Grid>
                              <Grid item>
                                <TypographySecondaryBgColorDimmed
                                  border={1}
                                  color="secondary"
                                  className="notebtn-action"
                                >
                                  {" "}
                                  <DeleteIcon
                                    style={{ fontSize: "1.3rem" }}
                                    onClick={() => {
                                      this.props.ProductDetails.note[
                                        this.props.pro_item_details_ind
                                      ] = "";
                                      this.UpdateCartItemFunctionlity(
                                        this.props.ProductDetails.id,
                                        this.props.ProductDetails.name,
                                        this.props.ProductDetails
                                          .added_quantity[
                                          this.props.pro_item_details_ind
                                        ],
                                        this.props.ProductDetails
                                          .added_quantity_is_case[
                                          this.props.pro_item_details_ind
                                        ],
                                        "icon",
                                        this.props.ProductDetails.slug,
                                        this.props.ProductDetails.note[
                                          this.props.pro_item_details_ind
                                        ]
                                      );
                                      this.setState({
                                        setOpenItemNote: false,
                                        doNotSubstitute: false,
                                        substituteWithSimilar: false,
                                        callMe: false,
                                      });
                                    }}
                                  />
                                </TypographySecondaryBgColorDimmed>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Typography
                            component={TypographySecondaryColor}
                            className="addNotes"
                            Fonttype="other"
                            onClick={() => this.OpenDialog()}
                          >
                            {" "}
                            <NoteAddIcon /> Add note
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}

                  {(ProductDetails &&
                    (ProductDetails.bpa_free === true ||
                      ProductDetails.dairy_free === true ||
                      ProductDetails.gluten_free === true ||
                      ProductDetails.soy_free === true ||
                      ProductDetails.carrageenan_free === true ||
                      ProductDetails.non_gmo === true ||
                      ProductDetails.vegan === true ||
                      ProductDetails.kitnityot === true ||
                      ProductDetails.kosherforpassover === true ||
                      ProductDetails.natural === true ||
                      ProductDetails.kosher1 !== "" ||
                      ProductDetails.kosher2 !== "" ||
                      ProductDetails.naturalprocessing === true ||
                      ProductDetails.noartificialing === true ||
                      ProductDetails.noeggs === true ||
                      ProductDetails.nosugaradded === true ||
                      ProductDetails.paleofriendly ||
                      ProductDetails.pareve === true ||
                      ProductDetails.pasyisroel === true ||
                      ProductDetails.sulfitefree === true ||
                      ProductDetails.yoshon === true ||
                      ProductDetails.cholovyisroel === true ||
                      ProductDetails.cholovyisroel === true ||
                      ProductDetails.organic === true ||
                      ProductDetails.kosher3 !== "" ||
                      ProductDetails.kosher4 !== "")) ||
                  ProductDetails?.about_product !== "" ||
                  ProductDetails?.ingredient !== "" ||
                  ProductDetails?.is_parent ? (
                    <>
                      {" "}
                      <div className="details_desc">
                        <ItemDetailDescription
                          ProductDetails={ProductDetails}
                        />
                      </div>
                      <div
                        className="view_details mb-5"
                        onClick={() => this.redirectDetailsPage(ProductDetails)}
                      >
                        <PrimaryButton Fonttype="other" className="bttn_grn">
                          View Details
                        </PrimaryButton>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <Dialog
              open={setOpenItemNote}
              onClose={() => this.CloseDialog("Cancel")}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              className="popUp"
            >
              <DialogContent className="p-0">
                <div className="popup">
                  <div
                    className="header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TypographyTextColor Color="heading" component="h4">
                      {" "}
                      Add note or backup
                    </TypographyTextColor>
                    <span className="float-right">
                      <IconButton
                        style={{ color: "#389978", fontSize: "18px" }}
                        onClick={() => this.CloseDialog("Cancel")}
                      >
                        <span class="icon-close"></span>
                      </IconButton>
                    </span>
                  </div>
                  <div className="boxshadow rounded-0 py-3 px-3">
                    <div className="form-group mb-4">
                      <TypographyTextColor
                        Color="heading"
                        className="font-weight-bold"
                      >
                        Product Note
                      </TypographyTextColor>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="product_note"
                        onChange={(e) => this.prodcutNote(e)}
                        className="w-100"
                        placeholder="I would like my shopper to..."
                        value={this.state.productNoteItemNote}
                      />
                      {/* <TextareaAutosize
                        name="product_note"
                        onChange={(e) => this.prodcutNote(e)}
                        minRows={3}
                        fullWidth
                        variant="outlined"
                        className="w-100"
                        aria-label="maximum height"
                        placeholder="Add Notes"
                        value={this.state.productNoteItemNote}
                        maxLength={280}
                      /> */}
                    </div>
                    <TypographyTextColor
                      Color="heading"
                      component="h6"
                      className="font-weight-bold"
                    >
                      Backup Instruction
                    </TypographyTextColor>
                    <TypographyTextColor Color="other">
                      Your choice in case the item is out of stock
                    </TypographyTextColor>

                    <FormGroup>
                      <FormControlLabel
                        value="Do not substitute"
                        control={<Radio checked={this.state.doNotSubstitute} />}
                        label={
                          <TypographyTextColor Color="other" component="span">
                            Do not substitute
                          </TypographyTextColor>
                        }
                        onChange={(e) =>
                          this.backupInstractTion(e.target.value)
                        }
                      />
                      <FormControlLabel
                        value="Substitute with similar"
                        control={
                          <Radio checked={this.state.substituteWithSimilar} />
                        }
                        label={
                          <TypographyTextColor Color="other" component="span">
                            Substitute with similar
                          </TypographyTextColor>
                        }
                        onChange={(e) =>
                          this.backupInstractTion(e.target.value)
                        }
                      />
                      <FormControlLabel
                        value="Call me"
                        control={<Radio checked={this.state.callMe} />}
                        label={
                          <TypographyTextColor Color="other" component="span">
                            Call me
                          </TypographyTextColor>
                        }
                        onChange={(e) =>
                          this.backupInstractTion(e.target.value)
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <SecondaryCancelButton
                  className="popbtncancel"
                  onClick={() => this.CloseDialog("Cancel")}
                  Fonttype="other"
                >
                  Cancel
                </SecondaryCancelButton>
                <SecondaryButton
                  className="popbtn"
                  onClick={() => this.CloseDialog("Add_note")}
                  Fonttype="other"
                >
                  Add Note
                </SecondaryButton>
              </DialogActions>
            </Dialog>

            {CategoryDetails.length > 0 ? (
              <CategoriesModal
                open={this.state.open}
                handleOpenDialog={this.handleOpenDialog}
                handleCloseDialog={this.handleCloseDialog}
                CategoryDetails={CategoryDetails}
                SubCategoryDetails={SubCategoryDetails}
                history={this.props.history}
                pageName={this.props.pageName}
                redirectingListingPage={(category_id) =>
                  this.props.redirectingListingPage(category_id)
                }
              />
            ) : (
              ""
            )}

            <SubCategoryModal
              open_inner={this.state.open_inner}
              SubcategoryName={SubcategoryName}
              SubSubCategoryName={this.state.SubSubCategoryName}
              SubSubCategoryDetails={this.state.SubSubCategoryList}
              handleInnerOpenDialog={this.handleInnerOpenDialog}
              handleInnerCloseDialog={this.handleInnerCloseDialog}
              handleOpenDialog={this.handleOpenDialog}
              SubCategoryDetails={SubCategoryDetails}
              history={this.props.history}
              open_subDetails={open_subDetails}
              FetchSubCategoryList={(id, level, parent_id) =>
                this.FetchSubCategoryList(id, level, parent_id)
              }
              pageName={this.props.pageName}
              parentIds={this.props.parentIds}
              parent_onlyid={this.props.parent_onlyid}
              parent_level={this.props.parent_level}
              redirectingListingPage={(category_id) =>
                this.props.redirectingListingPage(category_id)
              }
              handleCloseDialog={this.handleCloseDialog}
              tree_data={this.props.tree_data}
            />
            <CommonAlertBox
              error={this.state.error}
              handleClose={this.handleClose}
            />

            <Snackbar
              open={this.state.success}
              onClose={this.handleClose}
              autoHideDuration={3000}
              className={"success"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <i className="icon-close"></i>
                  </IconButton>
                </React.Fragment>
              }
              message={
                <React.Fragment>
                  <div className="row gutter-8">
                    <div className="col-auto">
                      <i className="icon-closer"></i>
                    </div>
                    <div className="col">
                      <p>{this.state.success}</p>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </ScrollColorTypography>
        </Modal>
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={this.CloseLoginDialog}
          isShowclose={true}
          history={this.props.history.history}
        />
      </>
    );
  }
}

export default ItemDetailsModal;
