import React from "react";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  sufixIconInputButtonTwo, 
  sufixIconInputForProfile, 
  renderSelect2Input, 
  renderAsyncSelect2Input, 
  renderPhoneValidatorInputRegister,
  CommonLogoutFunctionlity,renderNumberInputForZipCode,getSchedule_zip,RefreshApiToken, sweetAlertBox, commonPageTitel
  } from "../utils/renderUtils/renderUtil";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import {CountryListView,GetProfileDetails,StateListView, GetallAddress,GetSettingsData} from "../actions/profile/profileAction";
import profileSubmit from "./profileSubmit";
import { required } from "redux-form-validators";
import { Snackbar } from "@mui/material";
import { CategeriesListView, UpdateCartFunctionlity } from "../actions/home/homeAction";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {CartListFunctionlity, DeleteCrtIemFunctionlity} from "../actions/cart/cartAction";
import { SecondaryButton } from "./common/UiDesign";
import CustomPreloader from "./common/Loader/CustomPreloader";
const searchAPIDebounced = AwesomeDebouncePromise(CountryListView, 1000);
const searchAPIForStateDebounced = AwesomeDebouncePromise(StateListView, 1000);

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      feildTypeNewPassword: true,
      feildTypePassword: true,
      feildTypeConfirmPassword: true,
      city: [],
      state: [],
      countryList: [],
      allCities: [],
      pageName: "profile_page",
      profileDetails: [],
      password: "",
      confirmPassword: "",
      msgPassworderror: false,
      msgPasswordsuccess: false,
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      success: false,
      error: false,
      mobileViewHide: true,
      profileSubmitLoader: false,
      profilecartItemList: [],
      CategoryOption: [],
      desiableTextField: true,
      showHideDivSection: true,
      addressDetails: [],
      emailSubscribe:'',
      ProfileLoader:false
    };
    this.handleOpenCategoryDialog = this.handleOpenCategoryDialog.bind(this);
    this.handleCloseCategoryDialog = this.handleCloseCategoryDialog.bind(this);
  }

  EnableAllfields = () => {
    this.setState({desiableTextField: false, showHideDivSection: false})
  }

  handleOpenCategoryDialog() {
    this.setState({ open: true });
  }
  handleCloseCategoryDialog() {
    this.setState({ open: false });
  }

  changeFeildTypeNewPassword = () => {
    this.setState({
      feildTypeNewPassword: !this.state.feildTypeNewPassword,
    });
  };

  changeFeildTypePassword = () => {
    this.setState({
      feildTypePassword: !this.state.feildTypePassword,
    });
  };

  changeFeildTypeConfirmPassword = () => {
    this.setState({
      feildTypeConfirmPassword: !this.state.feildTypeConfirmPassword,
    });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
    
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
        
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
     this.setState({emailSubscribe:settingData})
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const accessToken =localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    if (accessToken && isLogin === "true") {
      document.title = commonPageTitel(
        JSON.parse(localStorage.getItem("access_token"))
      );
      this.getCountrylistDebounce();
      this.FetchSettingData()
      this.getStateListDebounce();
      this.FetchProfileDetails();
      this.FetchCategoryList();
      this.FetchCartItemList();
    } 
  }

  FetchAddress = () => {
    let formValue = { store_address: "" };
    GetallAddress(formValue, this.props.dispatch).then((res) => {
      let addressDetails = [];
      if (res.data && res.data.result) {
        addressDetails = res.data.result;
      } else if(res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem('access_token'));
        let myThis = this;
        if(refreshToken) {
          this.RefreshApiToken(refreshToken.refresh_token,myThis, function (myThis) {
            myThis.FetchAddress()
          });
        }
      }
      this.setState({
        addressDetails: addressDetails,
      });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if(res.data) { 
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis)
      }else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    CommonLogoutFunctionlity();
    this.props.history.push({
      pathname: "/",
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({cartLoader : true})
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let profilecartItemList = [];
      let CartCount = [];
      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            profilecartItemList.push(respone[i]);
            SubTotal += Number(respone[i].total_price);
          }
        }
      } else if(res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem('access_token'));
        let myThis = this;
        if(refreshToken) {
          this.RefreshApiToken(refreshToken.refresh_token,myThis, function (myThis) {
            myThis.FetchCartItemList()
          });
        }
      }
      if(res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2)
      } 
      this.setState({
        profilecartItemList: profilecartItemList,
        SubTotal: SubTotal.toFixed(2),
        cartListOpen: true,
        CartCount: CartCount,
        cartLoader : false,
      });
    });
  };

  FetchCategoryList = () => {
    let formValues=getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let CategoryDetails = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        CategoryDetails = res.data.result;
      } else if(res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem('access_token'));
        let myThis = this;
        if(refreshToken) {
          this.RefreshApiToken(refreshToken.refresh_token,myThis, function (myThis) {
            myThis.FetchCategoryList()
          });
        }
      }
      CategoryOption.push({ label: "All Categories", value: '' });
      if(CategoryDetails && CategoryDetails.length > 0){
        for (let i = 0; i < CategoryDetails.length; i++) {
          CategoryOption.push({
            label: CategoryDetails[i].name,
            value: CategoryDetails[i].id,
          })
        }
      }
      
      this.setState({ CategoryDetails: CategoryDetails, CategoryOption });
    });
  };
  handleSetPassword = (event) => {
    if (event.target.value.length > 5 && event.target.value.length < 16) {
      this.setState({
        msgPasswordsuccess:
          "Password minimum 6 character and maximum 16 character",
          password: event.target.value
      });
    } else {
      this.setState({ msgPasswordsuccess: false });
    }
  };

  handleSetConfirmPassword = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({
        msgPassworderror: "Passwords do not match",
        confirmPassword: event.target.value,
        msgPasswordsuccess: false
      });
    } else {
      this.setState({ msgPassworderror: false, msgPasswordsuccess: "Password match" });
    }
  };

  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    this.setState({ProfileLoader:true})
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
        this.props.change("first_name", profileDetails.firstName);
        this.props.change("last_name", profileDetails.lastName || profileDetails.lastName !== 'undefined'? profileDetails.lastName : '');
        this.props.change("address", profileDetails.address || profileDetails.address !== 'undefined'? profileDetails.address : '');
        this.props.change("city_id", {
          label: profileDetails.city.name,
          value: profileDetails.city.id,
        });
        this.props.change("state_id", {
          label: profileDetails.state.name,
          value: profileDetails.state.id,
        });
        this.setState({ProfileLoader:false})
        this.props.change("zipcode", profileDetails.zipcode);
        this.props.change("email_address", profileDetails.email);
        this.props.change("mobile_number", profileDetails.phone);
        this.props.change("alternate_mobile", profileDetails.alternate_phone || profileDetails.alternate_phone !== 'undefined' ? profileDetails.alternate_phone: '');
        this.props.change("street_name", profileDetails.street_name || profileDetails.street_name !== 'undefined' ? profileDetails.street_name : '');
        this.props.change("delivery_remark", profileDetails.delivery_remark || profileDetails.delivery_remark !== 'undefined' ? profileDetails.delivery_remark:'');
      } else if(res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem('access_token'));
        let myThis = this;
        if(refreshToken) {
          this.RefreshApiToken(refreshToken.refresh_token,myThis, function (myThis) {
            myThis.FetchProfileDetails()
          });
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  getCountrylistDebounce = async () => {
    let formValues = {
      search: "",
      limit: 200,
      page: 1,
      state_id: ''
    };
    const result = await searchAPIDebounced(formValues);
    if(result.data && result.data.result.length > 0) {
      if (result.data.result && result.data.result.length > 0) {
      let citylist = [];
      let statelist = [];
      let countrylist = [];
      countrylist.push({
        label: result.data.result[0].country.name,
        value: result.data.result[0].country.id,
      });
      for(let i = 0; i < result.data.result.length; i++) {
        citylist.push({
          label: result.data.result[i].name,
          value: result.data.result[i].id,
        });
        let StateIndex = statelist.findIndex(
          (obj) => obj.value ===result.data.result[i].state.id
        );
        if(StateIndex < 0 ) {
          statelist.push({
            label: result.data.result[i].state.name,
            value: result.data.result[i].state.id,
          });
        }
      }
      this.setState({
          city: citylist,
          state: statelist,
          countryList: countrylist,
          allCities: result.data.result,
        });
      }
    }
  };

  getStateListDebounce = async () => {
    let formValues = {
      search: "",
      limit: '',
      page: 1,
      state_id: ''
    };
    const result = await searchAPIForStateDebounced(formValues);
    if( result.data && result.data.result) {
      if (result.data.result.length > 0) {
        let statelist = [];
        for (let i = 0; i < result.data.result.length; i++) {
          statelist.push({
            label: result.data.result[i].name,
            value: result.data.result[i].id,
          });
        }
        this.setState({
          state: statelist,
        });
      }
    }
  };

  fetchCity = async (input) => {
    let formValues = {
      search: input,
      limit: 100,
      page: 1,
      state_id: ''
    };
    const result = await searchAPIDebounced(formValues);
    if(result) {
      const formatted = result.data.result.map((i) => {
        return Object.assign(
          {},
          {
            value: i.id,
            label: i.name,
          }
        );
      });
      this.setState({allCities:  result.data.result})
      return formatted;
    }
  };

  fetchState =async (input) => {
    let formValues = {
      search: input,
      limit: 10,
      page: 1,
    };
    const result = await searchAPIForStateDebounced(formValues);
    const formatted = result.data.result.map((i) => {
      return Object.assign(
        {},
        {
          value: i.id,
          label: i.name,
        }
      );
    });
    return formatted;
  };

  fetchCountry = (input) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    let formValues = {
      search: input,
      limit: 10,
      page: 1,
    };
    return CountryListView(formValues, this.props.dispatch).then((res) => {
      const formatted = res.data.result.map((i) => {
        console.log(i);
        return Object.assign(
          {},
          {
            value: i.country.id,
            label: i.country.name,
          }
        );
      });
      return formatted;
    });
  };

  selectCityCountry = (e) => {
    let allCities = this.state.allCities;
    let RequiredStateId = allCities.findIndex(
      (obj) => obj.id === e.value
    );
    let StatResult = allCities[RequiredStateId];
    if(StatResult) {
      this.props.change("state_id", {
        value: StatResult.state.id,
        label:StatResult.state.name,
      });
    }
  };

  selectStateCity = async (e) => {
    let formValues = {
      search: "",
      limit: '',
      page: 1,
      state_id: e.value
    }
    this.props.change("city_id", {
      value: '',
      label:'',
    });
    const result = await searchAPIDebounced(formValues);
    if( result.data && result.data.result) {
      const respone = result.data.result;
      if (respone.length > 0) {
        let citylist = [];
        for (let i = 0; i < respone.length; i++) {
          citylist.push({
            label: respone[i].name,
            value: respone[i].id,
          });
        }
        this.setState({city: citylist})
      }
    }
  }

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg.email && msg.email[0]) {
        sweetAlertBox('Attention!!',msg.email[0],"info")
        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      }else if(msg.zipcode && msg.zipcode[0]) {
        sweetAlertBox('Attention!!',msg.zipcode[0],"info")
        // this.setState({
        //   error: msg.zipcode[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox('Attention!!',msg.phone[0],"info")
        // this.setState({
        //   error: msg.phone[0],
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox('Success!!',"Successful Update Account","success",2000,false)
      this.setState({
        // error: false,
        // success: true,
        showHideDivSection: true,
      });
    }
  };

  redirectToPage = () => {
    this.setState({showHideDivSection : true})
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
      addressTab: false,
    });
  };

  UpdateCartItemFunctionlity = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
    };
    let profilecartItemList = this.state.profilecartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if(res.errors && res.errors.status === 400 && res.errors.data){
        this.createSuccessMsg('error',res.errors.data.errors)
      }else{
        for (let i = 0; i < profilecartItemList.length; i++) {
          if (profilecartItemList[i].product_id === product_id) {
            profilecartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(profilecartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({ disabled: false,profilecartItemList, SubTotal: SubTotal});
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case) => {
      let formValue = {
          product_id: product_id,
          is_case:is_case,
      };
      if(isNaN(added_quantity)) {
          added_quantity = 0
      }
      DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
          if(res.data) {
          this.FetchCartItemList();
          }
      });
  };

  AddCountForShoppingcartList = (product_id, added_quantity,added_qty_is_case, icons, index) => {
    let SubTotal = 0;
    let profilecartItemList = this.state.profilecartItemList;
    
    if (icons === "icon-plus") {
      for (let i = 0; i < profilecartItemList.length; i++) {
        if (profilecartItemList[i].id === index) {
          profilecartItemList[i].qty = profilecartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            profilecartItemList[i].qty,
            profilecartItemList[i].is_case,
          );
        }
      }
    } else {
      for (let i = 0; i < profilecartItemList.length; i++) {
        if (profilecartItemList[i].id === index) {
          profilecartItemList[i].qty = profilecartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              profilecartItemList[i].qty,
              profilecartItemList[i].is_case,
            );
          } else {
            this.deleteCartItem(product_id, profilecartItemList[i].qty,profilecartItemList[i].is_case);
          }
        }
      }
    }
    this.setState({ profilecartItemList: profilecartItemList });
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode,  match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }

  
  render() {
    const { handleSubmit, invalid } = this.props;
    const {msgPassworderror, msgPasswordsuccess, profilecartItemList,success, error, CategoryDetails, CartCount, CategoryOption, profileDetails} = this.state;
    const { selector } = this;
    return (
      <div className="main-content">
          <div
          className="main_containerLoader"
          style={{ display: this.state.ProfileLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          cartItemList={profilecartItemList}
          handleOpenDialog={this.handleOpenCategoryDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartCount = {()=>this.FetchCartItemList()}
          AddCountForcartList={(product_id,added_quantity,is_case,icons,index)=> this.AddCountForShoppingcartList(product_id,
            added_quantity,
            is_case,
            icons,
            index)}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenCategoryDialog}
          handleCloseDialog={this.handleCloseCategoryDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        <div className="main-data cart_data">
          <section className="user_details">
            <div className="user_main_wrapper">
              <div className="menuOuter">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  openDetailsMenu={() => this.openDetailsMenu()}
                />
              </div>
              <div className="user_details_desc user_detail_new">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cust_head_main cust_hide">
                      <h4>
                        {" "}
                        <ArrowBackIosIcon
                          onClick={() => this.closeDetailsMenu()}
                          className="d-lg-none"
                        />{" "}
                        Edit Profile
                      </h4>
                      <Link
                          to
                          className="new_add d-md-block"
                          onClick={()=> this.EnableAllfields()}
                        >
                          <span className="icon-plus"></span>Edit
                        </Link>
                    </div>
                  </div>
                </div>
                {this.state.showHideDivSection === true ? 
                <div className="row">
                <div className="col-md-12">
                  <div className="address_user address_rw">
                    <div className="cust_add">
                      <div className="cust_usr_name">
                        <h5>
                          {(profileDetails.firstName ? profileDetails.firstName : "") +" " +(profileDetails.lastName ? profileDetails.lastName : "")}
                          {/* <span className="user_defaulit_add">
                            {row.isdefault === true
                              ? "(Default)"
                              : ""}
                          </span> */}
                        </h5>
                      </div>
                      <div className="cust_address">
                        <div className="cust_home"></div>
                        <div className="cust_home">
                          {/* {profileDetails ? profileDetails.address:'' + `${profileDetails && profileDetails.address? "," : ''}` + profileDetails ? profileDetails.street_name : ''+ ", " + profileDetails && profileDetails.city? profileDetails.city.name : '' + ", " + profileDetails && profileDetails.state ? profileDetails.state.name: ''  + ", " + profileDetails? profileDetails.zipcode : ''} */}
                          {profileDetails.address ? profileDetails.address + ', ': '' }
                          {profileDetails.street_name ? profileDetails.street_name + ' ': '' }
                          {/* {profileDetails && profileDetails.city? profileDetails.city.name+ ', ' : ''} */}
                          {/* {profileDetails && profileDetails.state ? profileDetails.state.name + ', ': ''} */}
                          {profileDetails.zipcode? profileDetails.zipcode : ''}
                        </div>
                        <div className="cust_mobile">
                          {profileDetails.phone ? 'Mobile : ' + this.formatPhoneNumber(profileDetails.phone) + ' / ' : ''}
                          {profileDetails.alternate_phone ? this.formatPhoneNumber(profileDetails.alternate_phone): ''}
                        </div>
                        <div className="cust_mobile">
                          {profileDetails.delivery_remark ? 'Delivery Remark : ' + profileDetails.delivery_remark + ', ': '' }
                        </div>
                      </div>
                    </div>
                    {/* <div className="add_more_opt">
                      <span
                        className="icon-more"
                        onClick={() =>
                          this.OnClickMoreOption(row.id)
                        }
                      ></span>
                    </div> */}
                  </div>
                </div>
              </div>
              :
              <form onSubmit={handleSubmit(profileSubmit.bind(null, this))}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          name="first_name"
                          label="Full Name"
                          id="outlined-basic"
                          component={sufixIconInputForProfile}
                          type="text"
                          size="small"
                          rootClass=""
                          required={true}
                          disabled={this.state.desiableTextField}
                          validate={[
                            required({ message: "Full Name is required." }),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          name="address"
                          label="Apartment Name & House No."
                          id="outlined-basic"
                          component={sufixIconInputForProfile}
                          type="text"
                          size="small"
                          rootClass="user_main_input"
                          disabled={this.state.desiableTextField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          name="street_name"
                          label="Street Name, Area Name"
                          id="outlined-basic"
                          component={sufixIconInputForProfile}
                          type="text"
                          size="small"
                          rootClass="user_main_input"
                          required={true}
                          disabled={this.state.desiableTextField}
                          validate={[
                            required({ message: "Street Name, Area Name is required." }),
                          ]}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          name="address"
                          label="Apartment Name & House No."
                          id="outlined-basic"
                          component={sufixIconInputForProfile}
                          type="text"
                          size="small"
                          rootClass="user_main_input"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row gutter-2">
                        <div className="col-md-5 col-5">
                          <div className="user_input mb-3 mb-lg-0">
                            <Field
                              name="city_id"
                              size="small"
                              label="City"
                              component={renderAsyncSelect2Input}
                              initialOptions={this.state.city}
                              fetchOptions={this.fetchCity}
                              onChange={this.selectCityCountry.bind(selector)}
                              required={true}
                              // blurInputOnSelect ={false}
                              isDisabled={this.state.desiableTextField}
                              validate={[
                                required({
                                  message: "City is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-3">
                          <div className="user_input mb-3 mb-lg-0">
                            <Field
                              name="state_id"
                              size="small"
                              label="State"
                              colors="primary"
                              component={renderSelect2Input}
                              options={this.state.state}
                              fetchOptions={this.fetchState}
                              onChange={this.selectStateCity.bind(selector)}
                              className="outLine"
                              type="text"
                              rootClass=""
                              required={true}
                              isDisabled={this.state.desiableTextField}
                              validate={[
                                required({
                                  message: "State is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-4">
                          <div className="user_input mb-3 mb-lg-0">
                          <Field
                            name="zipcode"
                            label="Zipcode"
                            id="outlined-basic"
                            component={renderNumberInputForZipCode}
                           
                            size="small"
                            rootClass=""
                            required={true}
                            disabled={this.state.desiableTextField}
                            InputProps={{ inputProps: { min: 0, max: 5 } }}
                            validate={[
                              required({
                                message: "Zipcode is required.",
                              }),
                            ]}
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-lg-0">
                      <div className="user_input">
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          component={renderPhoneValidatorInputRegister}
                          name="mobile_number"
                          variant="outlined"
                          label="Mobile No."
                          id="outlined-basic"
                          color="primary"
                          position="start"
                          placeholder="Mobile No."
                          className="fileds"
                          size="small"
                          required={true}
                          disabled={this.state.desiableTextField}
                          validate={[
                            required({ message: "Mobile No. is required." }),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          component={renderPhoneValidatorInputRegister}
                          name="alternate_mobile"
                          variant="outlined"
                          id="outlined-basic"
                          label="Alternate Mobile No."
                          position="start"
                          placeholder="Alternate Mobile No."
                          className="fileds"
                          size="small"
                          disabled={this.state.desiableTextField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input">
                        <Field
                          name="email_address"
                          label="Email Address"
                          id="outlined-basic"
                          component={sufixIconInputForProfile}
                          type="text"
                          size="small"
                          rootClass=""
                          required={true}
                          disabled={this.state.desiableTextField}
                          validate={[
                            required({ message: "Email Address is required." }),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user_input">
                      <Field
                        name="delivery_remark"
                        label="Delivery Remarks"
                        id="outlined-basic"
                        component={sufixIconInputForProfile}
                        disabled={this.state.desiableTextField}
                        type="text"
                        size="small"
                        rootClass=""
                      />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="cust_head_main cust_mob_head">
                        <h4>Change Password</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input change_pwd">
                        <Field
                          name="password"
                          label="Password"
                          component={sufixIconInputButtonTwo}
                          type={
                            this.state.feildTypePassword ? "password" : "text"
                          }
                          icon={
                            this.state.feildTypePassword
                              ? "icon-visibility icn-usr"
                              : "icon-invisible icn-usr"
                          }
                          changeFeildTypeFunction={this.changeFeildTypePassword}
                          rootClass=""
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user_input change_pwd">
                        <Field
                          name="new_password"
                          label="New Password"
                          component={sufixIconInputButtonTwo}
                          type={
                            this.state.feildTypeNewPassword
                              ? "password"
                              : "text"
                          }
                          icon={
                            this.state.feildTypeNewPassword
                              ? "icon-visibility icn-usr"
                              : "icon-invisible icn-usr"
                          }
                          changeFeildTypeFunction={
                            this.changeFeildTypeNewPassword
                          }
                          rootClass=""
                          size="small"
                          onChange={(e) => this.handleSetPassword(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user_input change_pwd">
                        <Field
                          name="confirm_new_password"
                          label="Confirm New Password"
                          component={sufixIconInputButtonTwo}
                          type={
                            this.state.feildTypeConfirmPassword
                              ? "password"
                              : "text"
                          }
                          icon={
                            this.state.feildTypeConfirmPassword
                              ? "icon-visibility icn-usr"
                              : "icon-invisible icn-usr"
                          }
                          changeFeildTypeFunction={
                            this.changeFeildTypeConfirmPassword
                          }
                          rootClass=""
                          size="small"
                          onChange={(e) => this.handleSetConfirmPassword(e)}
                        />
                      </div>
                      {msgPassworderror ? (
                        <div className="alert alert-danger mt-3" role="alert">
                          {msgPassworderror}
                        </div>
                      ) : (
                        ""
                      )}
                      {msgPasswordsuccess ? (
                        <div className="alert alert-success mt-3" role="alert">
                          {msgPasswordsuccess}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bttn-fill bttn_user">
                        <button
                          className="bttn_grn bttn_trans"
                          onClick={() => this.redirectToPage()}
                        >
                          Cancel
                        </button>
                        <SecondaryButton className="bttn_grn"  disabled={invalid}>
                          Save Changes
                        </SecondaryButton>
                      </div>
                    </div>
                  </div>
                </form>
              }
              </div>
            </div>
          </section>
      <Footer emailSubscribe={this.state.emailSubscribe}/>
        </div>
        {success ? (
          <Snackbar
            open={this.state.success}
            onClose={this.handleClose}
            autoHideDuration={1000}
            className="success"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <h6>Successful Update Account</h6>
                    <p>{success}</p>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
        {error ? (
          <Snackbar
            open={this.state.error}
            onClose={this.handleClose}
            autoHideDuration={1000}
            className="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{error}</p>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "Profile" })(Profile)
);
