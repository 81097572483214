import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import RouterComponents from "./routes/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./assets/css/Main.scss";
import "./assets/css/new-home.css";
import { store } from "./store/store";
import ErrorBoundary from "../src/components/common/ErrorBoundary";
import { GetCustomizationSettings } from "./actions/home/homeAction";
import WebFont from "webfontloader";
import { ScrollColorTypography } from "./components/common/UiDesign";
import CustomPreloader from "./components/common/Loader/CustomPreloader";
window.$name = "modalOpen";
window.$name2 = "modalTwoOpen";
window.$name3 = "modalThreeOpen";

function App() {
  const [setting, setSetting] = React.useState({
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  });
  const [isLoading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const fetchFonts = (fontFamily) => {
    return fontFamily;
  };
  const FetchSetting = () => {
    setLoading(true);

    GetCustomizationSettings().then((res) => {
      let data;

      data = {
        primary: {
          main: res?.sdata?.primary_color
            ? res?.sdata?.primary_color
            : "#F57F18",
        },
        secondary: {
          main: res?.sdata?.secondary_color
            ? res?.sdata?.secondary_color
            : "#409a78",
        },
        TextTransform: {
          main: res?.sdata?.description_font_case
            ? res?.sdata?.description_font_case
            : "uppercase",
        },

        TextColor: {
          category: res?.sdata?.category_text_color
            ? res?.sdata?.category_text_color
            : "#000000",
          heading: res?.sdata?.heading_text_color
            ? res?.sdata?.heading_text_color
            : "#000000",
          product: res?.sdata?.product_text_color
            ? res?.sdata?.product_text_color
            : "#000000",
          other: res?.sdata?.text_color ? res?.sdata?.text_color : "#000000",
        },
        TextFontFamily: {
          category: res?.sdata?.category_text_family
            ? fetchFonts(res?.sdata?.category_text_family)
            : "",
          heading: res?.sdata?.heading_text_family
            ? fetchFonts(res?.sdata?.heading_text_family)
            : "",
          product: res?.sdata?.product_text_family
            ? fetchFonts(res?.sdata?.product_text_family)
            : "",
          other: res?.sdata?.font_family
            ? fetchFonts(res?.sdata?.font_family)
            : "",
        },
        searchPlaceholder:
          res?.sdata !== null && res?.sdata?.search_placeholder !== null
            ? res.sdata.search_placeholder
            : "",
        top_marquee: res?.sdata?.top_marquee,
        HomePage_setting: res?.home_page_data,
      };
      localStorage.setItem("web_customize", JSON.stringify(data));
      let Web_Font = data?.TextFontFamily;
      if (localStorage.getItem("setFontFamily") !== "true") {
        localStorage.setItem("setFontFamily", true);
        let data = [];
        Object.keys(Web_Font).map((ele) => {
          if (Web_Font[ele] !== ""&&Web_Font[ele] !== null) data.push(Web_Font[ele]);
          else data.push("Arial, Helvetica, sans-serif")
        });
        WebFont.load({
          google: {
            families: data,
          },
        });
      }
      setSetting(data);
      if (intervalId) {
        clearInterval(intervalId);
      }
      const newIntervalId = setInterval(() => {
        setLoading(false);
        clearInterval(newIntervalId); // Clear the interval after one execution
      }, 1500);

      setIntervalId(newIntervalId);
    });
  };
  React.useEffect(() => {
    FetchSetting();
  }, []);
  const theme = createMuiTheme({
    palette: {
      ...setting,
    },
    typography: {
      useNextVariants: true,
    },
  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ErrorBoundary>
            {isLoading && (
              <div
                className="main_containerLoader"
                style={{ display: "flex",background:'#fff' }}
              >
                <CustomPreloader />
              </div>
            )}
            <ScrollColorTypography>
              <RouterComponents history={Router} />
            </ScrollColorTypography>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
