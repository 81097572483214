import React, { Component, createElement } from "react";
import { Link } from "react-router-dom";
import CUSTOMER_IMAGE from "../../../assets/images/customer_image.png";
import { GuestLoginAthentication } from "../../../actions/home/homeAction";
import CircularProgress from "@mui/material/CircularProgress";
import {
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../UiDesign";
import { Typography } from "@mui/material";
import { userDashboardMenu } from "./utils";
import * as Icons from "react-icons/fa";
class UserDetailsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_url: "",
      menu_url2: "",
      user_name: "",
      email_id: "",
      listOpen: false,
    };
  }

  componentDidMount() {
    let user_name;
    let email_id;
    if (
      JSON.parse(localStorage.getItem("access_token")) &&
      JSON.parse(localStorage.getItem("isLogin")) === true
    ) {
      user_name = JSON.parse(localStorage.getItem("name")).name;
      email_id = JSON.parse(localStorage.getItem("email")).email;
    }

    let menu_url2 = "";

    if (this.props.history.location.state) {
      menu_url2 = this.props.history.location.state.pageName;
    }

    let path_arr = this.props.history.location.pathname.split("/");
    let menu_url = path_arr[1];

    this.setState({ menu_url, menu_url2 });
    this.setState({ user_name: user_name, email_id: email_id });
  }

  HitGuestApiToken = () => {
    let formValues = {};
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
      this.FetchCartCount();
    });
  };

  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    localStorage.removeItem("isWebsiteVisited");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    this.HitGuestApiToken();

    this.props.history.push("/");
    window.location.reload();
  };
  handleDropDownCloselist = () => {
    this.setState({
      anchorEllist: null,
    });
  };

  backToRedirctage = () => {
    this.props.history.push("/");
  };

  render() {
    const { menu_url } = this.state;

    const hide = () => {
      document.getElementsByClassName("hide")[0].classList.toggle("hideMenu");
    };

    return (
      <>
        <div className="user_details_menu d-none d-lg-block">
          <TypographySecondaryBgColorDimmed dimmedRatio={100} className="cust_details">
            <div className="cust_image">
              <img
                src={CUSTOMER_IMAGE}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="cust_user_details">
              {this.props.profileDetails.firstName === undefined ? (
                <div className="loader_wrapper">
                  <CircularProgress
                    style={{
                      display: "flex",
                      color: "#fff",
                      position: "relative",
                      marginLeft: "100px",
                      "& > * + *": {
                        marginLeft: 5,
                      },
                    }}
                  />
                </div>
              ) : (
                <ul>
                  {this.props.profileDetails ? (
                    <TypographyTextColor
                      Color="other"
                      component="li"
                      className="cust_user_name"
                    >
                      {this.props.profileDetails.firstName +
                        " " +
                        this.props.profileDetails.lastName}
                    </TypographyTextColor>
                  ) : (
                    ""
                  )}
                  {this.props.profileDetails ? (
                    <TypographyTextColor Color="other" component="li">
                      {this.props.profileDetails.email}
                    </TypographyTextColor>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            </div>
          </TypographySecondaryBgColorDimmed>
          <div className="cust_main_details">
            <ul>
              {userDashboardMenu.map((val, i) => {
                return (
                  <li key={i}>
                    <Link
                      to={val.url === "" ? "" : "/" + val.url}
                      onClick={() => (val.url === "" ? this.logout() : "")}
                    >
                      <Typography
                        component={
                          menu_url === val.url
                            ? TypographySecondaryColor
                            : TypographyTextColor
                        }
                        Color="other"
                        Fonttype="other"
                      >
                        <span
                          className={
                            val.iconType === "fa" ? val.iconName : "mr-0"
                          }
                        >
                          {val.iconType === "fa"
                            ? ""
                            : createElement(Icons[val.iconName])}
                        </span>
                        {val.name}
                      </Typography>{" "}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default UserDetailsMenu;
