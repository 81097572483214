import { SubmissionError } from "redux-form";
import {
  CreateNewAddress,
  Updateaddress,
  CreateNewGuestAddress
} from "../../actions/profile/profileAction";
import { RefreshApiToken } from "../../utils/renderUtils/renderUtil";
const deliveryaddressSubmit = (e, values, dispatch, props) => {
  let mobile_number;
  let alternate_phone;
  if (typeof values.mobile_no === "number") {
    mobile_number = values.mobile_no;
  } else {
    mobile_number = values.mobile_no.replace(/-/g, "");
  }

  if (typeof values.alternate_mobile === "number") {
    alternate_phone = values.alternate_mobile;
  } else {
    if (values.alternate_mobile) {
      alternate_phone = values.alternate_mobile.replace(/-/g, "");
    } else {
      alternate_phone = 0;
    }
  }
  var formValues = {
    first_name: values.first_name ? values.first_name : "",
    last_name: values.last_name ? values.last_name : "",
    callingcode: 1,
    phone: mobile_number,
    alternate_phone: alternate_phone,
    appartment_name: values.apartment_name ? values.apartment_name : "",
    street_name: values.street_name ? values.street_name : "",
    delivery_remark: values.delivery_remark ? values.delivery_remark : "",
    address_type: values.address_type ? values.address_type : "",
    city_name: values.city_id ? values.city_id : "",
    state_name: values.state_id ? values.state_id.value : "",
    zipcode: values.zipcode,
  };

  if (values.zipcode.replace(/_/g, "").length === 5) {
    if(localStorage.getItem("Guest_userid")===null)
  {  if (values && values.address_id > 0) {
      formValues.id = values.address_id;
      return Updateaddress(formValues, dispatch)
        .then((res) => {
       
          handleSumbitData(res,e, values, dispatch, props);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return CreateNewAddress(formValues, dispatch)
        .then((res) => {
        
          handleSumbitData(res,e, values, dispatch, props);
        })
        .catch((err) => {
          console.log(err);
        });
    }}
    else{
      formValues.id=localStorage.getItem("Guest_userid")
      return CreateNewGuestAddress(formValues, dispatch)
        .then((res) => {
         
           handleSumbitData(res,e, values, dispatch, props);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    e.createSuccessMsg("error", "Please enter valid zipcode");
  }
};

const handleSumbitData=(res,e, values, dispatch, props)=>{
  if (res.errors && res.errors.status === 401) {
    const refreshToken = JSON.parse(localStorage.getItem("access_token"));
    let myThis = this;
    if (refreshToken) {
      RefreshApiToken(
        refreshToken.refresh_token,
        myThis,
         () =>{
          deliveryaddressSubmit(
            e, values, dispatch, props
          );
        }
      );
    }
  } else if (res.errors && res.errors.status === 400) {
    if (res.errors.data) {
      e.createSuccessMsg("error", res.errors.data.errors);
    } else {
      throw new SubmissionError(res.errors.data.errors);
    }
  }  else {
    e.createSuccessMsg("success", res.message);
  }
}

export default deliveryaddressSubmit;
