import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import  headerReducer  from "./headerreducer";

export const createRootReducer = (history) =>
  combineReducers({
    routing: connectRouter(history),
    form: formReducer,
    headerData: headerReducer,
    // register: registerReducer,
    // dashheaderinfoinfo: dashheaderReducer,
    // patientreducer: patientReducer,
    // assistantInfo: assistantReducer,
  });
