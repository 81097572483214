import React, { Component } from "react";
import {
  Fab,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  sufixIconInputButton,
  sufixIconInput,
  sufixIconInputForaddressMobile,
  sufixIconInputForProfile,
  renderNumberInputForZipCode,
  renderHiddenField,
  renderSelect3Input,
  sweetAlertBox,
} from "../../utils/renderUtils/renderUtil";
import { required } from "redux-form-validators";
import { GetallAddress } from "../../actions/profile/profileAction";
import loginPageSubmit from "../login/loginSubmit";
import Commonsnackbar from "../common/commonsnackbar";
import {
  PrimaryBorderButton,
  SecondaryButton,
  SecondaryCancelButton,
  TypographyFontColor,
  TypographySecondaryColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../common/UiDesign";
import { State_Territory } from "../../utils/renderUtils/dbData";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showForgetPassword: true,
      feildType: true,
      rememberMeValue: false,
      success: false,
      error: false,
      logo: "",
      guestLogin: false,
      openDialog: false,
      isSignup: false,
    };
  }

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      sweetAlertBox("Error!!", msg, "error");
      // this.setState({
      //   error: msg,
      //   success: false,
      // });
    } else {
      this.setState({
        // error: false,
        // success: true,
        guestLogin: false,
      });
      this.props.reset();
    }
  };

  changeFeildType = () => {
    this.setState({
      feildType: !this.state.feildType,
    });
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  selectRememberCheckbox = (e) => {
    this.setState({ rememberMeValue: e.target.checked });
  };

  componentDidMount() {
    this.setState({ allstate: State_Territory });
  }

  redirecttoUrl = (url) => {
    this.props.history.push({
      pathname: url,
    });
  };

  handleDialogueBox = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  redirectToHomepage = () => {
    this.FetchAddress();
    this.props.CloseLoginDialog("signin");
  };
  FetchAddress = () => {
    let formValue = { store_address: "" };
    GetallAddress(formValue, this.props.dispatch).then((res) => {
      let addressDetails = [];
      let zipcode = "";
      if (res.data && res.data.result) {
        addressDetails = res.data.result;
        for (let index = 0; index < addressDetails.length; index++) {
          if (addressDetails[index].isdefault) {
            zipcode = addressDetails[index].zipcode;
            break;
          }
        }
        localStorage.setItem("defaultAddzone", zipcode);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddress();
            }
          );
        }
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { guestLogin } = this.state;
    return (
      <div>
        <Dialog
          open={this.props.setOpenLoginPopup}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp"
          onClose={() => this.props.CloseLoginDialog("close")}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <TypographyTextColor component={"h4"} Color="heading">
                {guestLogin
                  ? "Guest Checkout"
                  : !this.state.isSignup
                  ? "Sign In"
                  : "Sign Up"}
              </TypographyTextColor>
              {this.props.isShowclose ? (
                <IconButton
                  onClick={() => this.props.CloseLoginDialog("close")}
                >
                  <span
                    class="icon-close"
                    style={{ color: "#389978", fontSize: "18px" }}
                  ></span>
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="main-content">
              <div className="container-fluid p-0">
                <div
                  className="login-form pt-0"
                  style={{ width: this.state.isSignup ? "450px" : "" }}
                >
                  <form
                    onSubmit={handleSubmit(loginPageSubmit.bind(null, this))}
                  >
                    {localStorage.getItem(
                      "UserDetail" +
                        JSON.parse(localStorage.getItem("Store_address"))?.id
                    ) === null ||
                    JSON.parse(
                      localStorage.getItem(
                        "UserDetail" +
                          JSON.parse(localStorage.getItem("Store_address"))?.id
                      )
                    )?.isUserSalesrep === false ? (
                      <>
                        <div className="login-head">
                          <TypographyTextColor
                            component="span"
                            Color="other"
                            className="sub-head user_desc"
                          >
                            Welcome! Please{" "}
                            {guestLogin
                              ? "continue as a guest user"
                              : "login to your account"}
                          </TypographyTextColor>
                        </div>
                        {guestLogin ? (
                          <>
                            <div className="user-input">
                              <Field
                                name="first_name"
                                label="First Name"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: (
                                      <TypographyTextFontFamily type="other">
                                        "First Name is required."
                                      </TypographyTextFontFamily>
                                    ),
                                  }),
                                ]}
                              />
                            </div>
                            <div className="user-input">
                              <Field
                                name="last_name"
                                label="Last Name"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Last Name is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="user-input">
                          <Field
                            name={guestLogin ? "email" : "username"}
                            label={
                              guestLogin
                                ? "Email Address"
                                : "Email Address/Mobile No."
                            }
                            id="outlined-basic"
                            component={sufixIconInput}
                            type="text"
                            size="small"
                            rootClass=""
                            required={true}
                            validate={[
                              required({
                                message: guestLogin
                                  ? "Email is required"
                                  : "Email/Username is required.",
                              }),
                            ]}
                          />
                        </div>
                        <div className="user-input">
                          <Field
                            name={guestLogin ? "phone" : "password"}
                            label={guestLogin ? "Mobile No" : "Password"}
                            component={
                              guestLogin
                                ? sufixIconInputForaddressMobile
                                : sufixIconInputButton
                            }
                            type={
                              this.state.feildType && !guestLogin
                                ? "password"
                                : "text"
                            }
                            icon={
                              guestLogin
                                ? null
                                : this.state.feildType
                                ? "icon-visibility icn-usr"
                                : "icon-invisible icn-usr"
                            }
                            changeFeildTypeFunction={this.changeFeildType}
                            rootClass=""
                            size="small"
                            required={true}
                            validate={[
                              required({
                                message: guestLogin
                                  ? "Mobile No is required."
                                  : "Password is required.",
                              }),
                            ]}
                          />
                        </div>
                        <div className="user-input d-flex justify-content-between">
                          {guestLogin ? (
                            <></>
                          ) : (
                            <TypographyFontColor
                              role="button"
                              border={0}
                              onClick={() =>
                                this.redirecttoUrl("/forgot-password")
                              }
                              Fonttype={"other"}
                            >
                              Forgot Password?
                            </TypographyFontColor>
                          )}
                        </div>
                        <div className="bttn-fill">
                          <SecondaryButton
                            className="bttn_grn"
                            variant="extended"
                            type="submit"
                            color="primary"
                            Fonttype="other"
                          >
                            {guestLogin ? "Continue" : "Sign In"}
                          </SecondaryButton>
                          {localStorage.getItem("allow_guest_user") ===
                          "true" ? (
                            <Typography
                              component={TypographySecondaryColor}
                              style={{
                                border: "none",
                                marginTop: "20px",
                                fontWeight: "bolder",
                              }}
                              className="bttn_grn bttn_trans"
                              variant="extended"
                              type="submit"
                              color="primary"
                              Fonttype="other"
                              onClick={() => {
                                this.setState({ guestLogin: !guestLogin });
                              }}
                            >
                              {guestLogin
                                ? "Go with Login"
                                : "Continue as Guest"}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          <TypographyTextColor
                            // className="user_desc"
                            Color="other"
                            Fonttype="other"
                          >
                            New Customer? Register&nbsp;
                            <TypographySecondaryColor
                              Fonttype="other"
                              onClick={() =>
                                this.redirecttoUrl("/create-account")
                              }
                              component={"a"}
                              role="button"
                             style={{textDecoration:"underline"}}
                            >
                              here
                            </TypographySecondaryColor>
                          </TypographyTextColor>
                         
                        </div>
                      </>
                    ) : (
                      <>
                        {this.state.isSignup ? (
                          <></>
                        ) : (
                          <div className="login-head">
                            <TypographyTextColor
                              component="span"
                              Color="other"
                              className="sub-head user_desc"
                            >
                              Sign in the customer by their phone no.
                            </TypographyTextColor>
                          </div>
                        )}
                        {this.state.isSignup ? (
                          <div className="d-flex mt-3" style={{ gap: "1rem" }}>
                            <div>
                              <div className="user-input">
                                <Field
                                  name="first_name"
                                  label="First Name"
                                  id="outlined-basic"
                                  component={sufixIconInput}
                                  type="text"
                                  size="small"
                                  rootClass=""
                                  required={true}
                                  validate={[
                                    required({ message: "First is required." }),
                                  ]}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="user-input">
                                <Field
                                  name="last_name"
                                  label="Last Name"
                                  id="outlined-basic"
                                  component={sufixIconInput}
                                  type="text"
                                  size="small"
                                  rootClass=""
                                  required={true}
                                  validate={[
                                    required({ message: "Last is required." }),
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="user-input">
                          <Field
                            name={"username"}
                            label={"Mobile No."}
                            id="outlined-basic"
                            component={sufixIconInputForaddressMobile}
                            type="text"
                            size="small"
                            rootClass=""
                            required={true}
                            validate={[
                              required({
                                message: "Mobile No. is required.",
                              }),
                            ]}
                          />
                        </div>
                        {this.state.isSignup ? (
                          <>
                            <div className="user-input">
                              <Field
                                name="email_address"
                                label="Email Address"
                                id="outlined-basic"
                                component={sufixIconInput}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Email Address is required.",
                                  }),
                                ]}
                              />
                            </div>
                            <div
                              className="user-input"
                              style={{ marginBottom: "10px" }}
                            >
                              <Field
                                component={sufixIconInputForaddressMobile}
                                name="alternate_phone"
                                variant="outlined"
                                id="outlined-basic"
                                label="Alternate Mobile No."
                                color="primary"
                                position="start"
                                placeholder="Alternate Mobile No."
                                className="fileds"
                                size="small"
                              />
                            </div>

                            <div className="user-input">
                              <Field
                                name="address"
                                label="Address"
                                id="outlined-basic"
                                component={sufixIconInput}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({ message: "Address is required." }),
                                ]}
                              />
                            </div>
                            <div
                              className="user-input"
                              style={{ marginBottom: "10px" }}
                            >
                              <Field
                                name="street_name"
                                label="APT/FLOOR"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                              />
                            </div>
                            <div className="row gutter-2">
                              <div className="col-md-4">
                                <div className="user_input">
                                  <Field
                                    name="city_name"
                                    size="small"
                                    label="City"
                                    placeholder="City"
                                    component={sufixIconInput}
                                    // initialOptions={this.state.city}
                                    // fetchOptions={this.fetchCity}
                                    // onChange={this.selectCityCountry.bind(
                                    //   this
                                    // )}
                                    required={true}
                                    isClearable={true}
                                    validate={[
                                      required({
                                        message: "City is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="user_input">
                                  <Field
                                    name="hidden_city_id"
                                    component={renderHiddenField}
                                    type="hidden"
                                  />
                                  <Field
                                    autoFocus={true}
                                    name="state_name"
                                    size="small"
                                    label="State"
                                    component={renderSelect3Input}
                                    options={this.state.allstate}
                                    onChange={this.handleChnageState}
                                    Value={this.state.state}
                                    className="outLine"
                                    type="text"
                                    required={true}
                                    validate={[
                                      required({
                                        message: "State is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="user_input">
                                  <Field
                                    name="zipcode"
                                    label="Zipcode"
                                    id="outlined-basic"
                                    component={renderNumberInputForZipCode}
                                    size="small"
                                    rootClass=""
                                    required={true}
                                    validate={[
                                      required({
                                        message: "Zipcode is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="bttn-fill">
                          <Fab
                            className="bttn_grn"
                            variant="extended"
                            type="submit"
                            color="primary"
                            style={{ maxWidth: "100%" }}
                          >
                            {this.state.isSignup ? "Sign Up" : "Sign In"}
                          </Fab>

                          <SecondaryCancelButton
                            style={{ maxWidth: "100%" }}
                            onClick={() => {
                              this.setState({ isSignup: !this.state.isSignup });
                            }}
                            className="bttn_grn bttn_trans"
                          >
                            {this.state.isSignup ? "Go Back" : "Add Customer"}
                          </SecondaryCancelButton>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
              {this.state.success !== false || this.state.error !== false ? (
                <Commonsnackbar
                  success={this.state.success}
                  handleClose={this.handleClose}
                  error={this.state.error}
                />
              ) : (
                ""
              )}
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.openDialog}
          onClose={() => this.handleDialogueBox()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This user is not exist do you want to add this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button
                onClick={() => this.handleDialogueBox()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button> */}
            <Button
              onClick={() => this.handleDialogueBox()}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(reduxForm({ form: "Login" })(Login));
