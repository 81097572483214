import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import ApiUrls from "../utils/apiUtils/apiUrl";
import {
  AllOrdersApi,
  ReorderCallApi,
  ReorderCartCallApi,
} from "../actions/order/orderAction";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
} from "../actions/home/homeAction";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import {
  GetProfileDetails,
  GetSettingsData,
} from "../actions/profile/profileAction";
import {
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  FormControlLabel,
  TableRow,
} from "@mui/material";
import Pagination from "react-js-pagination";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  commonPageTitel,
  postfullTimeFormat,
  sendDateFormat,
  commonSelectedId,
  getSchedule_zip,
  RefreshApiToken,
  addDefaultImageSrc,
  sweetAlertBox,
  checkImageUrls,
  SinglecheckImageUrls,
  refineImgurl,
} from "../utils/renderUtils/renderUtil";
import CustomPreloader from "./common/Loader/CustomPreloader";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  ScrollColorTypography,
  SecondaryButton,
  TypographyFontColor,
  TypographyHoverBgColor,
  TypographySecondaryBgColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "./common/UiDesign";
class Orders extends Component {
  constructor(props) {
    super(props);
    let params = this.props.location.search;
    var page = params.page ? params.page : 1;
    var limit = params.limit ? params.limit : 10;
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      ordersList: [],
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      pageName: "orders_page",
      mobileViewHide: true,
      activePage: page,
      limit: limit,
      totalSize: "",
      CategoryOptionOrder: [],
      orderCartItemList: [],
      headerinputField: false,
      logo: "",
      profileDetails: [],
      success: false,
      error: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      isslotDeliverypopupState_compwill: false,
      slotName: "",
      mainLoader: true,
      reorderPopup: false,
      ReorderProductDetails: [],
      selected: [],
      selectedIds: [],
      emailSubscribe: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    this.props.history.push({
      pathname: "/listing/" + e,
      state: {
        category_id: e,
        parent_id: parent_id,
      },
    });
  }

  FetchOrderList = (limit, page) => {
    let formValues = {
      limit: limit,
      page: page,
    };
    AllOrdersApi(formValues, this.props.dispatch).then((res) => {
      let ordersList = [];
      let totalSize = "";
      if (res.data && res.data.result) {
        ordersList = res.data.result;
        totalSize = res.data.total;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchOrderList(formValues.limit, formValues.page);
            }
          );
        }
      }
      this.setState({
        ordersList: ordersList,
        totalSize: totalSize,
        activePage: page,
        limit: limit,
        mainLoader: false,
      });
    });
  };

  handlePageChange(pageNumber) {
    this.FetchOrderList(this.state.limit, pageNumber);
    window.scroll(0, 0);
    var url = "?page=" + pageNumber + "&limit=" + this.state.limit;
    this.props.history.push(url);
  }

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOptionOrder = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOptionOrder.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOptionOrder.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOptionOrder });
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      let orderCartItemList = [];
      if (res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }
      if (res.data && res.data.result) {
        const respone = res.data.result;

        if (respone.length > 0) {
        
            if (respone.length > 0) {
              respone.forEach((item) => {
                orderCartItemList.push(item);
                SubTotal += Number(item.total_price);
              });
            }
          }

            if (res.data.total > 0) {
              CartCount.item = res.data.total;
              CartCount.price = Number(res.data.total_subtotal).toFixed(2);
            }

            this.setState({
              SubTotal: SubTotal.toFixed(2),
              orderCartItemList,
              cartListOpen: true,
              CartCount: CartCount,
              cartLoader: false,
            });
          // });
        
      }
      this.setState({
        cartListOpen: true,
        cartLoader: false,
      });
    });
  };

  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }

  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    if (accessToken && isLogin === "true") {
      this.FetchOrderList(this.state.limit, this.state.activePage);
      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchSettingData();
      this.FetchProfileDetails();
      this.ExtraChargesFunctiolity();
    }
    this.setState({ logo: logo });
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    localStorage.removeItem("slot");
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    // this.HitGuestApiToken();

    this.props.history.push({
      pathname: "/",
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  reoderFunctionlity = (order_id) => {
    let formValues = {
      order_id: order_id,
      for_reorder: true,
    };

    if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({
        isslotDeliverypopupState_compwill: true,
        orderId: order_id,
      });
    } else {
      this.setState({ mainLoader: true });
      ReorderCallApi(formValues, this.props.dispatch).then((res) => {
        if (res && res.errors && res.errors._error) {
          this.createSuccessMsg("error", res.errors._error.data.message);
        } else {
          let data = res.data.result;
          // const promises = data?.map((ele) => {
          //   let localUrl = ele?.cloudinary_images_local;
          //   let globalUrl = ele?.cloudinary_images_global;
          //   let currentUrl =
          //     ele.image !== null
          //       ? ele.image[0] === "/"
          //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
          //         : ele.image
          //       : NO_IMAGE;

          //   return checkImageUrls(localUrl, globalUrl, currentUrl).then(
          //     (url) => {
          //       ele.image = url.url;
          //       ele.valid = url.valid;
          //       return ele;
          //     }
          //   );
          // });
          // Promise.all(promises).then((updatedCartItems) => {
            data.forEach((element) => {
              element.checked = true;
            });

            this.setState({
              reorderPopup: true,
              ReorderProductDetails: data,
              orderId: order_id,
              mainLoader: false,
            });
          // });
        }
      });
    }
  };
  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let orderCartItemList = this.state.orderCartItemList;
    if (icons === "icon-plus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          orderCartItemList[i].qty = orderCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            orderCartItemList[i].qty,
            orderCartItemList[i].is_case,
            orderCartItemList[i].note,
            orderCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          orderCartItemList[i].qty = orderCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].note,
              orderCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              orderCartItemList[i].qty,
              added_qty_is_case,
              orderCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          // orderCartItemList[i].qty = added_quantity;
          orderCartItemList[i].qty = added_quantity
            ? added_quantity
            : orderCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].note,
              orderCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].container_type
            );
          }
        }
      }
      // this.setState({headerinputField: false});
    }
    this.setState({
      orderCartItemList: orderCartItemList,
      headerinputField: false,
    });
  };

  AddCountForreorder = (
    product_id,
    icons,
    added_quantity,
    out_of_stock,
    order_product_id
  ) => {
    let orderCartItemList = this.state.ReorderProductDetails;
    if (icons === "icon-plus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (
          product_id !== null &&
          orderCartItemList[i].product_id === product_id
        ) {
          console.log("if");
          orderCartItemList[i].qty = orderCartItemList[i].qty + 1;
          orderCartItemList[i].total =
            orderCartItemList[i].qty * orderCartItemList[i].unit_price;
          if (orderCartItemList[i].qty === 1)
            this.handleClick(product_id, out_of_stock, false, order_product_id);
        } else if (orderCartItemList[i].order_product_id === order_product_id) {
          console.log("else");
          orderCartItemList[i].qty = orderCartItemList[i].quantity + 1;
          orderCartItemList[i].quantity = orderCartItemList[i].quantity + 1;
          orderCartItemList[i].total = 0;
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (
          product_id !== null &&
          orderCartItemList[i].product_id === product_id
        ) {
          if (orderCartItemList[i].qty > 0) {
            orderCartItemList[i].qty = orderCartItemList[i].qty - 1;
            orderCartItemList[i].total =
              orderCartItemList[i].qty * orderCartItemList[i].unit_price;
            if (orderCartItemList[i].qty === 0)
              this.handleClick(product_id, out_of_stock);
          }
        } else if (orderCartItemList[i].order_product_id === order_product_id) {
          console.log("else");
          orderCartItemList[i].qty = orderCartItemList[i].quantity - 1;
          orderCartItemList[i].quantity = orderCartItemList[i].quantity - 1;
          orderCartItemList[i].total = 0;
        }
      }
    } else {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (
          product_id !== null &&
          orderCartItemList[i].product_id === product_id
        ) {
          if (added_quantity > 0) {
            added_quantity = parseInt(added_quantity);

            orderCartItemList[i].qty = added_quantity;

            orderCartItemList[i].total =
              orderCartItemList[i].qty * orderCartItemList[i].unit_price;
            if (orderCartItemList[i].qty >= 1)
              orderCartItemList[i].checked = true;
          } else {
            orderCartItemList[i].qty = 0;
            orderCartItemList[i].total =
              orderCartItemList[i].qty * orderCartItemList[i].unit_price;
            if (orderCartItemList[i].qty === 0)
              orderCartItemList[i].checked = false;
          }
        } else if (orderCartItemList[i].order_product_id === order_product_id) {
          if (added_quantity > 0) {
            added_quantity = parseInt(added_quantity);

            orderCartItemList[i].qty = added_quantity;
            orderCartItemList[i].quantity = added_quantity;

            orderCartItemList[i].total = 0;
            if (orderCartItemList[i].quantity >= 1)
              orderCartItemList[i].checked = true;
          } else {
            orderCartItemList[i].quantity = 0;
            orderCartItemList[i].qty = 0;
            orderCartItemList[i].total = 0;
            if (orderCartItemList[i].quantity === 0)
              orderCartItemList[i].checked = false;
          }
        }
      }
    }
    if (product_id === null) {
      console.log(orderCartItemList);
      this.setState({ ReorderProductDetails: orderCartItemList });
    }
    this.setState({
      orderCartItemList: orderCartItemList,
      headerinputField: false,
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  UpdateCartItemFunctionlity = async (
    product_id,
    added_quantity,
    is_case,
    note,
    container_type
  ) => {
    console.log(product_id);
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: note ? note : "",
      container_type: container_type ? container_type : "",
    };
    let orderCartItemList = this.state.orderCartItemList;
    let SubTotal = 0;
    // this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        // for (let i = 0; i < orderCartItemList.length; i++) {
        //   if (orderCartItemList[i].product_id === product_id) {
        //     orderCartItemList[i].total = res.subtotal;
        //   }
        //   SubTotal += Number(orderCartItemList[i].total);
        // }
      }
      // this.setState({ disabled: false, orderCartItemList, SubTotal: SubTotal });
    });
  };
  deleteCartItem = (product_id, added_quantity, is_case, container_type) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };
  closeisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: false });
  };
  handleSlotName = (data) => {
    this.setState({ slotName: data });
  };
  handleCloseReorder = () => {
    this.setState({ reorderPopup: false });
  };
  addDefaultCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  handleReorder = () => {
    let orderCartItemList = this.state.ReorderProductDetails;
    let productData = [];
    let status = false;
    for (let i = 0; i < orderCartItemList.length; i++) {
      console.table();
      if (orderCartItemList[i].checked === true) {
        productData.push(orderCartItemList[i]);
        status = true;
      }
    }
    if (status) {
      this.setState({ mainLoader: true });
      let formValues = {
        user_id: 0,
        login_status: true,
        product_data: [{ result: productData }],
      };
      ReorderCartCallApi(formValues).then((res) => {
        if (res.errors && res.errors.status === 400 && res.errors.data) {
          this.createSuccessMsg("error", res.errors.data.errors);
        } else {
          this.props.history.push("/cart");
        }
      });
    } else {
      this.createSuccessMsg("error", "Please select atleast one item");
    }
  };

  handleClick = (id, out_of_stock, out_of_stock_case, order_product_id) => {
    const { selected } = this.state;
    let ProductDetails = this.state.ReorderProductDetails;
    let newSelected = [];
    const selectedIndex = selected.indexOf(id);
    let resultSelected;
    if (
      this.state.extraCharges.is_show_out_of_stock === true &&
      out_of_stock === true
    ) {
      resultSelected = [];
    } else {
      resultSelected = commonSelectedId(
        newSelected,
        selectedIndex,
        selected,
        id === null ? order_product_id : id
      );
    }
    var filtered = resultSelected.filter(function (el) {
      return el != "";
    });

    let checkBrandID = 0;
    if (id === null)
      checkBrandID = ProductDetails.findIndex(
        (p) => p.order_product_id === order_product_id
      );
    else checkBrandID = ProductDetails.findIndex((p) => p.product_id === id);

    ProductDetails[checkBrandID]["checked"] =
      !ProductDetails[checkBrandID]["checked"];
    if (ProductDetails[checkBrandID]["checked"] === false)
      ProductDetails[checkBrandID]["qty"] = 0;
    else {
      ProductDetails[checkBrandID]["qty"] = 1;
      ProductDetails[checkBrandID].total =
        ProductDetails[checkBrandID].unit_price;
    }

    this.setState({
      selected: filtered,
      ReorderProductDetails: ProductDetails,
    });
  };
  selectAll = (e) => {
    let selected = this.state.selectedIds;

    const tempData = this.state.ReorderProductDetails.map((el) => ({
      ...el,
      checked: e,
    }));

    for (let i = 0; i < tempData.length; i++) {
      if (
        this.state.extraCharges.is_show_out_of_stock === true &&
        tempData[i].out_of_stock === true
      ) {
        selected = [];
      } else {
        selected.push(tempData[i].id);
      }
    }

    if (e === false) {
      selected = [];
      for (let i = 0; i < tempData.length; i++) {
        tempData[i].qty = 0;
        tempData[i].total = tempData[i].qty * tempData[i].unit_price;
      }
    } else {
      for (let i = 0; i < tempData.length; i++) {
        tempData[i].qty = 1;
        tempData[i].total = tempData[i].qty * tempData[i].unit_price;
      }
    }
    this.setState({
      ReorderProductDetails: tempData,
      selected: selected,
      selectedIds: selected,
    });
  };
  render() {
    const {
      ordersList,
      CategoryDetails,
      orderCartItemList,
      CartCount,
      CategoryOptionOrder,
      profileDetails,
      ReorderProductDetails,
      web_customize,
    } = this.state;
    return (
      <ScrollColorTypography component="div" className="main-content">
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOptionOrder={CategoryOptionOrder}
          cartItemList={orderCartItemList}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartCount={() => this.FetchCartItemList()}
          extraCharges={this.state.extraCharges}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index,
            container_type
          ) =>
            this.AddCountForShoppingcartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            )
          }
          changeheaderinputFieldValue={() => this.changeheaderinputFieldValue()}
          headerinputField={this.state.headerinputField}
          logo={this.state.logo}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
          isslotDeliverypopupState_compwill={
            this.state.isslotDeliverypopupState_compwill
          }
          closeisslotDeliverypopupState_compwill={() =>
            this.closeisslotDeliverypopupState_compwill()
          }
          handleSlotNameData={(data) => this.handleSlotName(data)}
          reoderFunctionlity={(orderid) => this.reoderFunctionlity(orderid)}
          orderId={this.state.orderId}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />

        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="user_details user_order">
              <div className="user_main_wrapper">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  openDetailsMenu={() => this.openDetailsMenu()}
                  profileDetails={this.state.profileDetails}
                />
                <div className="user_details_desc_order order_main_wrapper">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 p-0">
                        <div className="cust_head_main cust_hide">
                          <TypographyTextColor Color="heading" component="h4">
                            Orders
                          </TypographyTextColor>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 p-0">
                        {ordersList && ordersList.length > 0 ? (
                          ordersList.map((row, index) => {
                            return (
                              <div
                              role="button"
                                className="order_link"
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: `/order-details`,
                                    state: { order_id: row.id },
                                  });
                                }}
                              >
                                <div className="order_main">
                                  <div className="order_inner_details">
                                    <div className="order-details orderWidth">
                                      <div className="order_head order_mob_head">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          ORD{row.id}
                                        </TypographyTextColor>
                                      </div>
                                      <div className="details_order d-flex align-items-center">
                                        <TypographyFontColor
                                          Fonttype="other"
                                          border={0}
                                          className={
                                            row.status === "delivered"
                                              ? "order_status order_delivered"
                                              : row.status === "cancel"
                                              ? "order_status order_cancelled"
                                              : row.status === "shipped"
                                              ? "order_status order_shipped "
                                              : "order_status"
                                          }
                                        >
                                          {row.status === "pending"
                                            ? "Ordered"
                                            : row.status
                                                .charAt(0)
                                                .toUpperCase() +
                                              row.status.slice(1)}
                                        </TypographyFontColor>
                                        <TypographyTextFontFamily
                                          component="span"
                                          type="other"
                                        >
                                          ({sendDateFormat(row.created_date)}
                                          &nbsp;
                                          {postfullTimeFormat(row.created_date)}
                                          )
                                        </TypographyTextFontFamily>
                                      </div>
                                    </div>
                                    <div className="order-details order_web orderWidth">
                                      <div className="order_head">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {row.delivery_type === "pickup"
                                            ? "Pickup Slot"
                                            : row.delivery_type === "delivery"
                                            ? "Delivery Slot"
                                            : row.delivery_type === "shipping"
                                            ? "Shipping"
                                            : "No delivey and pickup"}
                                        </TypographyTextColor>
                                      </div>
                                      {(row.delivery_type === "pickup" &&
                                        row.pickup_type !== "no_date_time") ||
                                      row.delivery_type === "delivery" ? (
                                        <TypographyTextFontFamily
                                          type="other"
                                          className="details_order"
                                        >
                                          <span>
                                            (
                                            {row.delivery_date_time
                                              ? row.delivery_date_time.split(
                                                  "-"
                                                )[1] +
                                                "/" +
                                                row.delivery_date_time.split(
                                                  "-"
                                                )[2] +
                                                "/" +
                                                row.delivery_date_time.split(
                                                  "-"
                                                )[0]
                                              : ""}
                                            ),
                                          </span>
                                          <span>
                                            {row.shipping_type
                                              ? " (" + row.shipping_type + ")"
                                              : ""}
                                          </span>
                                        </TypographyTextFontFamily>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="order-details order_web">
                                      <div className="order_head">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          ${row.total_price}
                                        </TypographyTextColor>
                                      </div>
                                      <div className="details_order">
                                        <TypographyTextFontFamily
                                          type="other"
                                          component="span"
                                        >
                                          {row.no_of_product} items
                                        </TypographyTextFontFamily>
                                      </div>
                                    </div>
                                    <div className="order-det_mob">
                                      <div className="order-details">
                                        <div className="order_head">
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                          >
                                            {row.delivery_type === "pickup"
                                              ? "Pickup Slot"
                                              : row.delivery_type === "delivery"
                                              ? "Delivery Slot"
                                              : row.delivery_type === "shipping"
                                              ? "Shipping"
                                              : "No delivey and pickup"}
                                          </TypographyTextColor>
                                        </div>
                                        {row.delivery_type === "pickup" ||
                                        row.delivery_type === "delivery" ? (
                                          <div className="details_order">
                                            <span>
                                              (
                                              {row.delivery_date_time
                                                ? row.delivery_date_time.split(
                                                    "-"
                                                  )[1] +
                                                  "/" +
                                                  row.delivery_date_time.split(
                                                    "-"
                                                  )[2] +
                                                  "/" +
                                                  row.delivery_date_time.split(
                                                    "-"
                                                  )[0]
                                                : ""}
                                              ),
                                            </span>
                                            <span>
                                              {row.shipping_type
                                                ? " (" + row.shipping_type + ")"
                                                : ""}
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="order-details">
                                        <div className="order_head">
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                          >
                                            ${row.total_price}
                                          </TypographyTextColor>
                                        </div>
                                        <div className="details_order">
                                          <span>{row.no_of_product} items</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="order_again">
                                    <div className="bttn-fill main_cart_bttn">
                                      <SecondaryButton
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.reoderFunctionlity(row.id);
                                        }}
                                        className="bttn_grn btn_conti_shop"
                                        disabled={false}
                                        Fonttype="other"
                                      >
                                        Reorder
                                      </SecondaryButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="emtpyCartMsg">
                            There is no order. Please make a order
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.totalSize && this.state.totalSize > 0 ? (
                      <div className="pagination">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.totalSize}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          prevPageText="Prev"
                          firstPageText="First"
                          lastPageText="Last"
                          nextPageText="Next"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <Dialog
                    open={this.state.reorderPopup}
                    onClose={() => this.handleCloseReorder()}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth={true}
                    className="reorderpopup"
                  >
                    <DialogTitle id="scroll-dialog-title">
                      <div className="w-100 d-flex justify-content-between">
                        <TypographyTextColor Color="heading" component="span">
                          Item Detail
                        </TypographyTextColor>
                        <div className="header px-2 py-2">
                          <span className="float-right">
                            <a
                              href="javascript:void(0);"
                              onClick={() => this.handleCloseReorder()}
                            >
                              <span class="icon-close"></span>
                            </a>
                          </span>
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent className="p-0">
                      <div className="tbl-main shoplist">
                        <div className="table-box">
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <FormControlLabel
                                    color="primary"
                                    control={
                                      <Checkbox
                                        color="primary"
                                        name="checkedC"
                                        checked={ReorderProductDetails.every(
                                          (el) => el.checked
                                        )}
                                        onChange={(e) =>
                                          this.selectAll(e.target.checked)
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <TypographyTextColor
                                    Color="heading"
                                    component="h6"
                                  >
                                    Quantity
                                  </TypographyTextColor>
                                </TableCell>
                                <TableCell className="text-center">
                                  <TypographyTextColor
                                    Color="heading"
                                    component="h6"
                                  >
                                    {" "}
                                    Name
                                  </TypographyTextColor>
                                </TableCell>

                                <TableCell>
                                  {" "}
                                  <TypographyTextColor
                                    Color="heading"
                                    component="h6"
                                  >
                                    Price
                                  </TypographyTextColor>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <TypographyTextColor
                                    Color="heading"
                                    component="h6"
                                  >
                                    Total
                                  </TypographyTextColor>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ReorderProductDetails &&
                              ReorderProductDetails.length > 0
                                ? ReorderProductDetails.map((row, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell data-label="action">
                                          <FormControlLabel
                                            color="primary"
                                            control={
                                              <Checkbox
                                                name="checkedC"
                                                color="primary"
                                                checked={
                                                  this.state.extraCharges
                                                    .is_show_out_of_stock ===
                                                    true &&
                                                  row.out_of_stock === true
                                                    ? false
                                                    : "checked" in row
                                                    ? row.checked
                                                    : false
                                                }
                                                onChange={() =>
                                                  this.handleClick(
                                                    row.product_id,
                                                    row.out_of_stock,
                                                    row.out_of_stock_case,
                                                    row.is_case
                                                  )
                                                }
                                              />
                                            }
                                          />
                                        </TableCell>
                                        <TableCell data-label="Quantity">
                                          <div className="casecard">
                                            <TypographySecondaryBgColor className="count_box">
                                              <button
                                                class="count_btn"
                                                onClick={() =>
                                                  this.AddCountForreorder(
                                                    row.product_id,
                                                    "icon-minus",
                                                    row.qty,
                                                    row.out_of_stock,
                                                    row.order_product_id
                                                  )
                                                }
                                              >
                                                <span class="icon-minus"></span>
                                              </button>
                                              <div class="count_no">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    this.AddCountForreorder(
                                                      row.product_id,
                                                      "input",
                                                      e.target.value,
                                                      row.out_of_stock,
                                                      row.order_product_id
                                                    )
                                                  }
                                                  value={
                                                    row.product_id === null
                                                      ? row.quantity
                                                      : row.qty > 0
                                                      ? row.qty
                                                      : 0
                                                  }
                                                ></input>
                                              </div>
                                              <button
                                                class="count_btn"
                                                onClick={() =>
                                                  this.AddCountForreorder(
                                                    row.product_id,
                                                    "icon-plus",
                                                    row.qty,
                                                    row.out_of_stock,
                                                    row.order_product_id
                                                  )
                                                }
                                              >
                                                <span class="icon-plus"></span>
                                              </button>
                                            </TypographySecondaryBgColor>
                                            {row.is_case === true ? (
                                              <div className="case">
                                                <span className="casetextincart">
                                                  Case
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          data-label="Name"
                                          className="tname"
                                          // onClick={() =>
                                          //   this.redirectToDetailsPageAfterClick(
                                          //     row.product_name,
                                          //     row.slug,
                                          //     row.product_id
                                          //   )
                                          // }
                                        >
                                          <div class="wh-list">
                                            <img
                                              onError={(e) =>
                                                addDefaultImageSrc(e)
                                              }
                                              className="img-responsive mr-3"
                                              // src={
                                              //   row.image ? row.image : NO_IMAGE
                                              // }
                                              src={refineImgurl(row.image , {
                                               
                                                w: 100,
                                              })}
                                              alt=""
                                              onClick={() =>
                                                this.redirectToDetailsPageAfterClick(
                                                  row.product_name,
                                                  row.slug,
                                                  row.product_id
                                                )
                                              }
                                            />
                                            {this.state.extraCharges
                                              .is_show_out_of_stock === true &&
                                            row.out_of_stock === true ? (
                                              <div className="out-of-stock-user">
                                                <span className="text">
                                                  Out Of Stock
                                                </span>
                                              </div>
                                            ) : this.state.extraCharges
                                                .is_show_out_of_stock ===
                                                true &&
                                              row.out_of_stock_case === true &&
                                              row.is_case === true ? (
                                              <div className="out-of-stock-user">
                                                <span className="text">
                                                  Out Of Stock
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div
                                              class={
                                                this.state.extraCharges
                                                  .is_show_out_of_stock ===
                                                  true &&
                                                row.out_of_stock === true
                                                  ? "out-stock-title"
                                                  : ""
                                              }
                                            >
                                              <TypographyTextColor
                                                Color="product"
                                                component="div"
                                              >
                                                {row.product_id === null
                                                  ? row.unlist_product_name
                                                  : row.product_name}
                                              </TypographyTextColor>
                                              {row.note === "" ||
                                              row.note === null ? (
                                                <></>
                                              ) : (
                                                <div className="note_box">
                                                  {" "}
                                                  <p className="note">
                                                    {row.note.split("~")[0] ===
                                                    ""
                                                      ? row.note.split("~")[1]
                                                      : row.note
                                                          .split("~")
                                                          .join(", ")}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </TableCell>

                                        <TableCell
                                          data-label="Price"
                                          style={{ width: "150px" }}
                                        >
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                          >
                                            {" "}
                                            $
                                            {row.product_id === null
                                              ? "0.00"
                                              : Number(row.unit_price).toFixed(
                                                  2
                                                )}
                                          </TypographyTextColor>
                                        </TableCell>
                                        <TableCell
                                          data-label="Total"
                                          style={{ width: "150px" }}
                                        >
                                          <TypographyTextColor
                                            Color="other"
                                            component="span"
                                          >
                                            {" "}
                                            $
                                            {row.product_id === null
                                              ? "0.00"
                                              : Number(
                                                  row.qty * row.unit_price
                                                ).toFixed(2)}
                                          </TypographyTextColor>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                : ""}
                            </TableBody>
                          </Table>
                        </div>
                      </div>

                      <DialogActions>
                        <div className="d-flex align-items-center justify-content-end py-3 shop-btn">
                          <SecondaryButton
                            variant="contained"
                            onClick={() => this.handleReorder()}
                            className="text-white mx-2"
                            Fonttype="other"
                          >
                            Add to Cart
                          </SecondaryButton>
                        </div>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                  {this.state.success ? (
                    <Snackbar
                      open={this.state.success}
                      onClose={this.handleClose}
                      autoHideDuration={6000}
                      className="success"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      action={
                        <React.Fragment>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={this.handleClose}
                          >
                            <i className="icon-close"></i>
                          </IconButton>
                        </React.Fragment>
                      }
                      message={
                        <React.Fragment>
                          <div className="row gutter-8">
                            <div className="col-auto">
                              <i className="icon-closer"></i>
                            </div>
                            <div className="col">
                              <p>{this.state.success}</p>
                              {/* <p>Login failed. Invalid username or password</p> */}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    />
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <Snackbar
                      open={this.state.error}
                      onClose={this.handleClose}
                      autoHideDuration={6000}
                      className="error"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      action={
                        <React.Fragment>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={this.handleClose}
                          >
                            <i className="icon-close"></i>
                          </IconButton>
                        </React.Fragment>
                      }
                      message={
                        <React.Fragment>
                          <div className="row gutter-8">
                            <div className="col-auto">
                              <i className="icon-closer"></i>
                            </div>
                            <div className="col">
                              <p>{this.state.error}</p>
                              {/* <p>Login failed. Invalid username or password</p> */}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
            <Footer emailSubscribe={this.state.emailSubscribe} />
          </div>
        )}
      </ScrollColorTypography>
    );
  }
}

export default Orders;
