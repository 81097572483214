import React, { Component } from "react";
import { Link } from "react-router-dom";

class SuccessfulChangePassword extends Component {
  render() {
    return (
      <div className="main-content">
        <div className="container-fluid p-0">
          <div className="login-main">
            <div className="header-pop">
              <Link to="/">
                <img src={`${process.env.IMAGE_PATH_URL}logo.png`} alt="" />
              </Link>
            </div>
            <div className="login-page">
              <div className="login-form">
                <div className="logo_success">
                  <span className="icon-tick"></span>
                </div>
                <div className="login-head">
                  <h4>Password Change Successfully</h4>
                  <span className="sub-head">
                    Your new password has been successfully change
                  </span>
                </div>
                <div className="bttn-fill">
                  <Link to="/login" className="bttn_grn">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessfulChangePassword;
