import { Skeleton } from '@mui/material'
import React from 'react'

const OrderSummarySkeleton = () => {
  return (
    <div className='d-flex justify-content-between'> <Skeleton
    animation="wave"
    width={100}
    height={50}
  />
  <Skeleton
    animation="wave"
    width={100}
    height={50}
  /></div>
  )
}

export default OrderSummarySkeleton