import React from "react";
import { Link } from "react-router-dom";
import { Tabs } from "@mui/material";
const CustomChips = (props) => {
  const {
    filterByType,
    max_price,
    min_price,
    brandName,
    filterByFlavor,
    filterByKosher,
    customChipsData,
  } = props;
  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      style={{ minHeight: "36px" }}
    >
      {customChipsData !== "" && customChipsData !== undefined ? (
        <div className="chipblock">
          {customChipsData}
          <Link to onClick={() => props.closecustomChips()}>
            <span className="icon-Close"></span>
          </Link>
        </div>
      ) : (
        ""
      )}
      {filterByType && filterByType.length > 0
        ? filterByType.map((row, index) => {
            return (
              <div className="chipblock"  key={index}>
                {row}
                <Link
                  to
                  onClick={() => props.handleChangeCheckboxForProductType(row)}
                >
                  <span className="icon-Close"></span>
                </Link>
              </div>
            );
          })
        : ""}
      {max_price > 0 ? (
        <div className="chipblock">
          ${min_price} - ${max_price}
          <Link to onClick={() => props.closePriceRange()}>
            <span className="icon-Close"></span>
          </Link>
        </div>
      ) : (
        ""
      )}
      {brandName && brandName.length > 0 && customChipsData === ""
        ? brandName.map((row, index) => {
            return (
              <div className="chipblock"  key={index}>
                {row.brand_name}
                <Link
                  to
                  onClick={() =>
                    props.handleChangeCheckbox(row.brand_id, row.brand_name)
                  }
                >
                  <span className="icon-Close"></span>
                </Link>
              </div>
            );
          })
        : ""}
      {filterByFlavor && filterByFlavor.length > 0
        ? filterByFlavor.map((row, index) => {
            return (
              <div className="chipblock"  key={index}>
                {row}
                <Link
                  to
                  onClick={() => props.handleChangeCheckboxForFlover(row)}
                >
                  <span className="icon-Close"></span>
                </Link>
              </div>
            );
          })
        : ""}
      {filterByKosher && filterByKosher.length > 0
        ? filterByKosher.map((row, index) => {
            return (
              <div className="chipblock" key={index}>
                {row}
                <Link
                  to
                  onClick={() => props.handleChangeCheckboxForKosher(row)}
                >
                  <span className="icon-Close"></span>
                </Link>
              </div>
            );
          })
        : ""}
    </Tabs>
  );
};

export default CustomChips;
