import React, { Component, createElement } from "react";
import LoderImage from "../../../assets/images/more.png";
import CreateFavorite from "./createFavorite";
import { addDefaultImageSrc, refineImgurl } from "../../../utils/renderUtils/renderUtil";
import { Link } from "react-router-dom";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import { styled } from "@mui/material/styles";
import { Divider, Fade, IconButton, Typography } from "@mui/material";
import {
  LabelPrimaryColor,
  SaleLabelySecondaryColor,
  SecondaryButton,
  TextFormating,
  TypographyBorder,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographySecondaryBorder,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../UiDesign";
import { AiOutlineDelete, AiOutlineMinus } from "react-icons/ai";
import { BsPlusSquareFill } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  height: "100%",
});
const NumberDisplay = ({ number }) => {
  return number.replace(/\.0(?!\d)/g, "");
};
export default class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      web_config: JSON.parse(localStorage.getItem("web_customize")),
    };
  }

  render() {
    const { web_config } = this.state;
    return (
      <div
        className={"items-container " + this.props.className}
        categoty={this.props.categoryIndx}
        style={{
          flexBasis: this.props.cardWidth !== "" ? this.props.cardWidth : "",
          maxWidth: this.props.cardWidth !== "" ? this.props.cardWidth : "",
          transition: "all 0.7s ease-in-out",
        }}
      >
        <div
          className={
            this.props.extraCharges.is_show_out_of_stock === true &&
            this.props.itemData.out_of_stock === true
              ? "items card out_of_stock_items"
              : this.props.extraCharges.is_show_out_of_stock === true &&
                this.props.itemData.out_of_stock_case === true &&
                this.props.itemData.added_quantity_is_case[
                  this.props.check_added_quantity_is_case
                ] === true
              ? "items card out_of_stock_items"
              : "items card"
          }
          style={
            {
              // minWidth:
              //   this.props.cardWidth !== ""
              //     ? this.props.cardWidth
              //     : "",
              // maxWidth:this.props.cardWidth==="185px"?this.props.cardWidth:""
            }
          }
        >
          {this.props.itemData?.offer_tag !== 0 &&
          this.props.itemData?.offer_tag !== null ? (
            <div className="items-discount">
              {this.props.itemData?.offer_tag
                ? this.props.itemData?.offer_tag + "% OFF"
                : 0 + "% OFF"}
            </div>
          ) : (
            ""
          )}
          {window.screen.availWidth <= 700 ? (
            <></>
          ) : (
            <>
              {this.props.itemData?.sub_description !== "" &&
              this.props.itemData?.sub_description !== null &&
              this.props.itemData?.sub_description !== undefined &&
              window.screen.availWidth >= 900 ? (
                <div className="item-subdes-container">
                  <TypographyPrimaryBgColorDimmed border={0} component="span">
                    {this.props.itemData?.sub_description}
                  </TypographyPrimaryBgColorDimmed>
                </div>
              ) : (
                <></>
              )}
              <div className="likecart">
                <CreateFavorite
                  removeFromfavouriteProduct={(favoriot_id, product_id) =>
                    this.props.removeFromfavouriteProduct(
                      favoriot_id,
                      product_id,
                      this.props?.categoryIndx
                    )
                  }
                  addFavoiteLevelFunctionlity={(value, product_id) =>
                    this.addFavoiteLevelFunctionlity(value, product_id)
                  }
                  favoriteLevelData={this.props.favoriteLevelListData}
                  history={this.props.history}
                  activePageName={this.props.activePageName}
                  addFavoiteProductFunctionlity={(favoritelabel_id) =>
                    this.props.addFavoiteProductFunctionlity(
                      favoritelabel_id,
                      this.props.itemData.id,
                      this.props.itemData.added_quantity[
                        this.props.check_added_quantity_is_case
                      ],
                      this.props?.categoryIndx,
                      this.props.itemData.added_quantity_is_case[
                        this.props.check_added_quantity_is_case
                      ]
                    )
                  }
                  wishlist={this.props.itemData?.wishlist}
                  product_id={this.props.itemData.id}
                  HandelChangeTrueWishList={() =>
                    this.HandelChangeTrueWishList()
                  }
                  FetchFavoriteLevelList={(product_id) =>
                    this.FetchFavoriteLevelList(product_id)
                  }
                  favoriot_id={this.props.itemData.favoriot_id}
                />
              </div>
            </>
          )}
          {this.props.itemData.is_special === true ? (
            <div
              className="newsalee"
              style={{ cursor: "pointer" }}
              onClick={(e) =>
                this.props.handlePopOpen(
                  this.props.itemData.id,
                  this.props.itemData.slug,
                  false,
                  false,
                  this.props.index,
                  this.props?.categoryIndx
                )
              }
            >
              <Typography component={SaleLabelySecondaryColor} Fonttype="other">
                SALE
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div
            className="imgbox"
            onClick={(e) =>
              this.props.handlePopOpen(
                this.props.itemData.id,
                this.props.itemData.slug,
                false,
                false,
                this.props.index,
                this.props?.categoryIndx
              )
            }
            style={{
              height: this.props?.cardSize === "big" ? "250px" : "",
              maxHeight: this.props?.cardSize === "big" ? "250px" : "",
              transition: "all 0.7s ease-in-out",
            }}
          >
           
            <img
              onError={(e) => addDefaultImageSrc(e)}
              src={refineImgurl(this.props.itemData.main_image, {
                w: 200,
              })}
              alt=""
            />
           
            {this.props.extraCharges.is_show_out_of_stock === true &&
            this.props.itemData.out_of_stock === true ? (
              <div className="out-of-stock">
                <LabelPrimaryColor
                  component={"span"}
                  border={2}
                  className="text"
                >
                  Out Of Stock
                </LabelPrimaryColor>
              </div>
            ) : this.props.extraCharges.is_show_out_of_stock === true &&
              this.props.itemData.out_of_stock_case === true &&
              this.props.itemData.added_quantity_is_case[
                this.props.check_added_quantity_is_case
              ] === true ? (
              <div className="out-of-stock">
                <LabelPrimaryColor
                  component={"span"}
                  border={2}
                  className="text"
                >
                  Out Of Stock
                </LabelPrimaryColor>
              </div>
            ) : (
              ""
            )}

            {this.props.extraCharges.is_show_out_of_stock === true &&
            this.props.itemData.out_of_stock === true &&
            this.props?.restrictByOnhand === true ? (
              ""
            ) : (
              <div className="item-addtocart-container">
                {this.props.itemData.is_case === true ? (
                  <TypographyPrimaryBgColorDimmed
                    component={"div"}
                    border={1}
                    color="primary"
                    role="button"
                    className="button-addtocart button-plusminus case-box"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.ProductCaseFunctionlity(
                        this.props.itemData.id,
                        !this.props.itemData.added_quantity_is_case[
                          this.props.check_added_quantity_is_case
                        ]
                      );
                    }}
                  >
                    <TypographyFontColor
                      component={"span"}
                      border={0}
                      className="d-flex"
                    >
                      <div className="case-line">
                        <input
                          type="checkbox"
                          className="case-checkbox"
                          checked={
                            this.props.itemData.added_quantity_is_case[
                              this.props.check_added_quantity_is_case
                            ] === true
                              ? true
                              : false
                          }
                        />
                        Case of {this.props.itemData.case_quantity}
                      </div>
                    </TypographyFontColor>
                  </TypographyPrimaryBgColorDimmed>
                ) : (
                  ""
                )}
                {this.props.itemData?.addtocart_show === true &&
                this.props.itemData?.instoreonly === false ? (
                  this.props.itemData.added_quantity[
                    this.props.check_added_quantity_is_case
                  ] === 0 ? (
                    <TypographySecondaryColor
                      component="button"
                      className="button-addtocart"
                      size={30}
                      onClick={(e) => {
                        this.props.openAddCount(
                          this.props.itemData.id,
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ] + 1,
                          this.props.itemData.added_quantity_is_case[
                            this.props.check_added_quantity_is_case
                          ],
                          this.props?.categoryIndx,
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ]
                        );
                        e.stopPropagation();
                      }}
                    >
                      <BsPlusSquareFill style={{ fontSize: "25px" }} />
                    </TypographySecondaryColor>
                  ) : (
                    <>
                      <TypographySecondaryBgColorDimmed
                        border={0}
                        dimmedRatio={60}
                        component="div"
                        className="button-plusminus"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <IconButton
                          size="small"
                          style={{
                            borderRadius: "6px",
                            color: "#fff",
                            padding: "4px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!this.props.itemData?.disabled)
                              this.props.openAddCount(
                                this.props.itemData.id,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ] - 1,
                                this.props.itemData.added_quantity_is_case[
                                  this.props.check_added_quantity_is_case
                                ],
                                this.props?.categoryIndx,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ]
                              );
                          }}
                        >
                          {" "}
                          {this.props.itemData?.disabled ? (
                            <img
                              className="loaderImg"
                              src={LoderImage}
                              alt=""
                            />
                          ) : (
                            createElement(
                              this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ] > 1
                                ? AiOutlineMinus
                                : MdDeleteOutline,
                              {
                                style: { fontSize: "20px" },
                              }
                            )
                          )}
                        </IconButton>
                        {this.props.inputField === false ? (
                          <input
                            type="text"
                            id={this.props.itemData.id}
                            value={
                              this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ] > 0
                                ? this.props.itemData.added_quantity[
                                    this.props.check_added_quantity_is_case
                                  ]
                                : 0
                            }
                            onChange={(ev) => {
                              ev.stopPropagation();
                              this.props.openAddCounttext(
                                this.props.itemData.id,
                                ev.target.value,
                                this.props.itemData.added_quantity_is_case[
                                  this.props.check_added_quantity_is_case
                                ],
                                this.props?.categoryIndx,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ]
                              );
                            }}
                            onClick={(ev) => ev.stopPropagation()}
                          />
                        ) : (
                          <input
                            type="text"
                            id={this.props.itemData.id}
                            defaultValue={
                              this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ] > 0
                                ? this.props.itemData.added_quantity[
                                    this.props.check_added_quantity_is_case
                                  ]
                                : 0
                            }
                            onBlur={() => this.props.changeInputBoxValue()}
                            onChange={(ev) => {
                              ev.stopPropagation();
                              this.props.openAddCounttext(
                                this.props.itemData.id,
                                ev.target.value,
                                this.props.itemData.added_quantity_is_case[
                                  this.props.check_added_quantity_is_case
                                ],
                                this.props?.categoryIndx,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ]
                              );
                            }}
                            onClick={(ev) => ev.stopPropagation()}
                          />
                        )}{" "}
                        <IconButton
                          size="small"
                          style={{
                            borderRadius: "6px",
                            color: "#fff",
                            padding: "4px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!this.props.itemData?.disabled)
                              this.props.openAddCount(
                                this.props.itemData.id,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ] + 1,
                                this.props.itemData.added_quantity_is_case[
                                  this.props.check_added_quantity_is_case
                                ],
                                this.props?.categoryIndx,
                                this.props.itemData.added_quantity[
                                  this.props.check_added_quantity_is_case
                                ]
                              );
                          }}
                        >
                          {this.props.itemData?.disabled ? (
                            <img
                              className="loaderImg"
                              src={LoderImage}
                              alt=""
                            />
                          ) : (
                            <BiPlus style={{ fontSize: "20px" }} />
                          )}
                        </IconButton>
                      </TypographySecondaryBgColorDimmed>
                    </>
                  )
                ) : this.props.itemData.instoreonly === false ? (
                  <TypographySecondaryBgColor
                    border={0}
                    dimmedRatio={100}
                    component="div"
                    className="button-addtocart button-plusminus justify-content-center"
                  >
                    Select
                  </TypographySecondaryBgColor>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="item_details card-body">
            <Link to>
              <div
                onClick={(e) =>
                  this.props.handlePopOpen(
                    this.props.itemData.id,
                    this.props.itemData.slug,
                    false,
                    false,
                    this.props.index,
                    this.props?.categoryIndx
                  )
                }
              >
                <TextFormating
                  Color="product"
                  component={"div"}
                  className="textbox"
                  style={{fontSize: this.props?.cardSize === "big" ? "21px" : ""}}
                >
                  <p>
                    {web_config?.TextTransform?.main === "none"
                      ? this.props.itemData.name
                      : this.props.itemData.name.toLowerCase()}
                  </p>
                </TextFormating>
                {this.props.itemData.is_special === true &&this.props.itemData.special_max>0 ? (
                  <div className="items-type">   <TypographyTextColor
                      component="p"
                      Color="other"
                    >
                      {`Max ${this.props.itemData.special_max}`}
                    </TypographyTextColor>
                    </div>
                  ) : (
                    <></>
                  )}
                {this.props.itemData.package?.trim() !== "" ||
                this.props.itemData.brand_name !== null ||
                (this.props.check_is_case_flag &&
                  this.props.itemData.added_quantity[
                    this.props.check_added_quantity_is_case
                  ]) ? (
                  <div className="items-type">
                    <TypographyTextColor
                      component="p"
                      Color="other"
                      className="mb-1 brand-name"
                    >
                      {this.props.itemData.brand_name
                        ? this.props.itemData.brand_name +
                          `${
                            this.props.itemData.package !== " " &&
                            this.props.itemData.package !== "0.0 0" &&
                            this.props.itemData.package !== "0.0 " &&
                            window.screen.availWidth < 700
                              ? " | "
                              : ""
                          }`
                        : ""}
                      {this.props.itemData.package &&
                      this.props.itemData.package !== "0.0 0" &&
                      this.props.itemData.package !== "0.0 " &&
                      window.screen.availWidth < 700 ? (
                        <>
                          <NumberDisplay number={this.props.itemData.package} />
                        </>
                      ) : (
                        ""
                      )}
                    </TypographyTextColor>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Link>
          </div>

          <div className="price card-footer">
            <div className="price-container">
              <TypographyTextColor
                component={"div"}
                Color="other"
                className="bottom-package"
              >
                {" "}
                {this.props.itemData.package &&
                this.props.itemData.package !== "0.0 0" &&
                this.props.itemData.package !== "0.0 " &&
                this.props.itemData.package.trim() !== "" &&
                window.screen.availWidth > 700 ? (
                  <>
                    <NumberDisplay number={this.props.itemData.package} />
                  </>
                ) : (
                  ""
                )}
              </TypographyTextColor>

              <div className="text-right">
                {this.props.itemData.is_special === true &&
                this.props.itemData.is_parent === false ? (
                  <>
                    <TypographyTextColor component="span" Color="other">
                      {"$" + Number(this.props.itemData.today_price).toFixed(2)}
                    </TypographyTextColor>
                  </>
                ) : (
                  ""
                )}
                <div>
                  <Typography
                    component={
                      this.props.itemData.is_special === true
                        ? TypographySecondaryColor
                        : TypographyTextColor
                    }
                    Color="other"
                    Fonttype="other"
                    className="bottom-price"
                  >
                    {this.props.itemData.added_quantity_is_case[
                      this.props.check_added_quantity_is_case
                    ] === true
                      ? "$" + Number(this.props.itemData.case_price).toFixed(2)
                      : this.props.itemData.is_special === true
                      ? this.props.itemData.special_quantity > 0
                        ? this.props.itemData.special_quantity +
                          "/$" +
                          Number(this.props.itemData.special_price).toFixed(2)
                        : "$" +
                          Number(this.props.itemData.special_price).toFixed(2)
                      : "$" +
                        Number(this.props.itemData.today_price).toFixed(2)}
                    {this.props.itemData.is_by_weight === true ? " / LB  " : ""}
                    {/* </h6> */}
                  </Typography>
                </div>
                {this.props.itemData.is_offer === true ? (
                  this.props.itemData.added_quantity_is_case[
                    this.props.check_added_quantity_is_case
                  ] === true ? (
                    <span>
                      {this.props.itemData.unit !== ""
                        ? this.props.itemData.unit
                        : "$"}
                      {Number(
                        this.props.itemData.case_quantity *
                          this.props.itemData.today_price
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span>
                      {this.props.itemData.unit !== ""
                        ? this.props.itemData.unit
                        : "$"}
                      {Number(this.props.itemData.original_price).toFixed(2)}
                    </span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {this.props.itemData?.addtocart_show === true &&
          this.props.itemData?.instoreonly === false ? (
            <div className={"singleAddtocartmob"}>
              {this.props.itemData.is_case === false &&
              this.props.itemData.added_quantity[
                this.props.check_added_quantity_is_case
              ] === 0 ? (
                <SecondaryButton
                  onClick={() =>
                    this.props.openAddCount(
                      this.props.itemData.id,
                      this.props.itemData.added_quantity[
                        this.props.check_added_quantity_is_case
                      ] + 1,
                      this.props.itemData.added_quantity_is_case[
                        this.props.check_added_quantity_is_case
                      ],
                      this.props?.categoryIndx,
                      this.props.itemData.added_quantity[
                        this.props.check_added_quantity_is_case
                      ]
                    )
                  }
                >
                  Add
                </SecondaryButton>
              ) : (
                <TypographySecondaryBorder border={2} className="count_boxmob">
                  <button
                    className="count_btnmobminus"
                    style={{ borderTopRightRadius: "9px" }}
                    onClick={() =>
                      this.props.openAddCount(
                        this.props.itemData.id,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ] + 1,
                        this.props.itemData.added_quantity_is_case[
                          this.props.check_added_quantity_is_case
                        ],
                        this.props?.categoryIndx,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ]
                      )
                    }
                    disabled={this.props.itemData?.disabled}
                  >
                    {this.props.itemData?.disabled ? (
                      <TypographySecondaryColor size={20} component="span">
                        ...
                      </TypographySecondaryColor>
                    ) : (
                      <TypographySecondaryColor
                        size={20}
                        component="span"
                        className="icon-plus"
                      ></TypographySecondaryColor>
                    )}
                  </button>

                  <div className="count_nomob">
                    {this.props.inputField === false ? (
                      <input
                        type="text"
                        style={{ borderRadius: "0px" }}
                        className="form-control value"
                        id={this.props.itemData.id}
                        value={
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ] > 0
                            ? this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ]
                            : 0
                        }
                        onChange={(e) =>
                          this.props.openAddCounttext(
                            this.props.itemData.id,
                            e.target.value,
                            this.props.itemData.added_quantity_is_case[
                              this.props.check_added_quantity_is_case
                            ],
                            this.props?.categoryIndx,
                            this.props.itemData.added_quantity[
                              this.props.check_added_quantity_is_case
                            ]
                          )
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control defaultValue"
                        id={this.props.itemData.id}
                        style={{ borderRadius: "0px" }}
                        defaultValue={
                          this.props.itemData.added_quantity[
                            this.props.check_added_quantity_is_case
                          ] > 0
                            ? this.props.itemData.added_quantity[
                                this.props.check_added_quantity_is_case
                              ]
                            : 0
                        }
                        onBlur={() => this.props.changeInputBoxValue()}
                        onChange={(e) =>
                          this.props.openAddCounttext(
                            this.props.itemData.id,
                            e.target.value,
                            this.props.itemData.added_quantity_is_case[
                              this.props.check_added_quantity_is_case
                            ],
                            this.props?.categoryIndx,
                            this.props.itemData.added_quantity[
                              this.props.check_added_quantity_is_case
                            ]
                          )
                        }
                      />
                    )}
                  </div>
                  <button
                    className="count_btnmobminus"
                    style={{ borderBottomRightRadius: "9px" }}
                    onClick={() =>
                      this.props.openAddCount(
                        this.props.itemData.id,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ] - 1,
                        this.props.itemData.added_quantity_is_case[
                          this.props.check_added_quantity_is_case
                        ],
                        this.props?.categoryIndx,
                        this.props.itemData.added_quantity[
                          this.props.check_added_quantity_is_case
                        ]
                      )
                    }
                    disabled={this.props.itemData?.disabled}
                  >
                    <TypographySecondaryColor component="span" size={25}>
                      {this.props.itemData?.disabled === true ? (
                        "..."
                      ) : (
                        <AiOutlineDelete />
                      )}
                    </TypographySecondaryColor>
                  </button>
                </TypographySecondaryBorder>
              )}
            </div>
          ) : this.props.itemData.instoreonly === false ? (
            <div className={"singleAddtocartmob"}>
              <div className="add_tocartmob">
                <SecondaryButton
                  style={{ width: "10%", fontSize: "12px" }}
                  onClick={(e) =>
                    this.props.handlePopOpen(
                      this.props.itemData.id,
                      this.props.itemData.slug,
                      false,
                      false,
                      this.props.index,
                      this.props?.categoryIndx
                    )
                  }
                >
                  Select
                </SecondaryButton>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
