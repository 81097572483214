import React from "react";
import ItemLoaderCard from "../common/Loader/ItemLoaderCard";
import ItemCard from "../common/ProductCard/ItemCard";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { TypographyTextColor } from "../common/UiDesign";
import ItemCardMob from "../common/ProductCard/Mobile/ItemCardMob";
import { AiFillCaretRight } from "react-icons/ai";
import { Breadcrumbs } from "@mui/material";
import ApiUrls from "../../utils/apiUtils/apiUrl";
const CommonProductview = (props) => {
  const isMobile=!(window.screen.availWidth > 900)
  const renderSubcategory = () => {
    let category_id = props?.productCategory?.category_id;
    let treeData = props?.tree_data;
    let category = treeData.filter((x) => x.id === category_id);
    if (category.length > 0) {
      return (
        <>
          <Breadcrumbs maxItems={10} separator="  " aria-label="breadcrumb">
            {props.subItem}
          </Breadcrumbs>
        </>
      );
    }
  };
  return (
    <section className="items-content">
      <div className="header-cnt">
        <div className="header-details">
          <TypographyTextColor
            component={"h4"}
            Color="heading"
            role="button"
            onClick={() => props.handleChangeRouteForDeal()}
          >
            {props.viewTitle}
          </TypographyTextColor>
          {((props?.SettingLandingPage?.show_sub_category&&isMobile===false)||
          (props?.SettingLandingPage?.mobile_show_sub_category&&isMobile===true)) ? (
            renderSubcategory()
          ) : (
            <></>
          )}
        </div>
        <div
          className="show_all"
          onClick={() => props.handleChangeRouteForDeal()}
        >
          <TypographyTextColor component={Link} Color="other">
            See All
          </TypographyTextColor>
        </div>
      </div>

      <div className="items-details item_listing_details item_listing_homepage">
        {props?.showNavigationBtn && props.productData?.page !== 1 ? (
          <div className="d-flex align-items-center">
            {" "}
            <AiFillCaretRight
              style={{
                transform: "rotate(180deg)",
                color: "#696969",
                fontSize: window.screen.availWidth < 900 ? "18px" : "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("hitt");
                props.prevProductItemCategorywise(props.productData);
              }}
            />{" "}
          </div>
        ) : (
          <></>
        )}
        {props.ProductLoader
          ? [...Array(window.screen.availWidth <= 760 ? 6 : 7)].map((x, i) => (
              <div className="items" style={{ width: "inherit" }}>
                <ItemLoaderCard />
              </div>
            ))
          : props.ProductList.length > 0
          ? props.ProductList.map((row, index) => {
              var c_i_case = props.chack_is_case[row.id]
                ? props.chack_is_case[row.id]
                : false;
              var check_added_quantity_is_case =
                row.added_quantity_is_case.indexOf(c_i_case);
              var check_is_case_flag = false;
              if (check_added_quantity_is_case > -1) {
                check_is_case_flag = true;
              } else {
                check_is_case_flag = true;
                row.added_quantity_is_case.push(c_i_case);
                row.added_quantity.push(0);
                check_added_quantity_is_case =
                  row.added_quantity_is_case.indexOf(c_i_case);
              }
              return (
                // <Zoom

                //                 // style={{
                //                 //   transitionDelay: `${100 * (index + 1)}ms`,

                //                 // }}
                //                 in={true}
                //               >
                <ItemCard
                  extraCharges={props.extraCharges}
                  check_is_case_flag={check_is_case_flag}
                  check_added_quantity_is_case={check_added_quantity_is_case}
                  itemData={row}
                  favoriteLevelListData={props.favoriteLevelData}
                  index={index}
                  removeFromfavouriteProduct={(favoriot_id, product_id) =>
                    props.removeFromfavouriteProduct(favoriot_id, product_id)
                  }
                  addFavoiteLevelFunctionlity={(value, product_id) =>
                    props.addFavoiteLevelFunctionlity(value, product_id)
                  }
                  history={props.history}
                  activePageName={props.activePageName}
                  addFavoiteProductFunctionlity={(
                    favoritelabel_id,
                    id,
                    added_quantity,
                    added_quantity_is_case
                  ) =>
                    props.addFavoiteProductFunctionlity(
                      favoritelabel_id,
                      id,
                      added_quantity,
                      added_quantity_is_case
                    )
                  }
                  FetchFavoriteLevelList={(product_id) =>
                    props.FetchFavoriteLevelList(product_id)
                  }
                  HandelChangeTrueWishList={() =>
                    props.HandelChangeTrueWishList()
                  }
                  handlePopOpen={(id, slug, next, prev, index) =>
                    props.handlePopOpen(id, slug, next, prev, index)
                  }
                  openAddCount={(
                    id,
                    added_quantity,
                    added_quantity_is_case,
                    containertype
                  ) =>
                    props.openAddCount(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      containertype
                    )
                  }
                  disabled={props.disabled}
                  inputField={props.inputField}
                  changeInputBoxValue={() => props.changeInputBoxValue()}
                  ProductCaseFunctionlity={(id, event) =>
                    props.ProductCaseFunctionlity(id, event)
                  }
                  cardWidth={props.cardWidth}
                  restrictByOnhand={props.restrictByOnhand}
                />
                // </Zoom>
              );
            })
          : ""}
        {props?.showNavigationBtn &&
        props.productData?.limit * props.productData?.page <
          props.productData?.total ? (
          <div className="d-flex align-items-center">
            {" "}
            <AiFillCaretRight
              style={{
                color: "#696969",
                fontSize: window.screen.availWidth < 900 ? "18px" : "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("hitt");
                props.nextProductItemCategorywise(props.productData);
              }}
            />{" "}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="items-details item_listing_details item_listing_homepage_mob">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={true}
        >
          {props.ProductLoader
            ? [...Array(window.screen.availWidth <= 760 ? 5 : 7)].map(
                (x, i) => (
                  <div className="items">
                    {" "}
                    <ItemLoaderCard />
                  </div>
                )
              )
            : props.ProductList.length > 0
            ? props.ProductList.map((row, index) => {
                var c_i_case = props.chack_is_case[row.id]
                  ? props.chack_is_case[row.id]
                  : false;
                var check_added_quantity_is_case =
                  row.added_quantity_is_case.indexOf(c_i_case);
                var check_is_case_flag = false;
                if (check_added_quantity_is_case > -1) {
                  check_is_case_flag = true;
                } else {
                  check_is_case_flag = true;
                  row.added_quantity_is_case.push(c_i_case);
                  row.added_quantity.push(0);
                  check_added_quantity_is_case =
                    row.added_quantity_is_case.indexOf(c_i_case);
                }
                return (
                  <ItemCardMob
                    extraCharges={props.extraCharges}
                    check_is_case_flag={check_is_case_flag}
                    check_added_quantity_is_case={check_added_quantity_is_case}
                    itemData={row}
                    favoriteLevelListData={props.favoriteLevelData}
                    index={index}
                    removeFromfavouriteProduct={(favoriot_id, product_id) =>
                      props.removeFromfavouriteProduct(favoriot_id, product_id)
                    }
                    addFavoiteLevelFunctionlity={(value, product_id) =>
                      props.addFavoiteLevelFunctionlity(value, product_id)
                    }
                    history={props.history}
                    activePageName={props.activePageName}
                    addFavoiteProductFunctionlity={(
                      favoritelabel_id,
                      id,
                      added_quantity,
                      added_quantity_is_case
                    ) =>
                      props.addFavoiteProductFunctionlity(
                        favoritelabel_id,
                        id,
                        added_quantity,
                        added_quantity_is_case
                      )
                    }
                    FetchFavoriteLevelList={(product_id) =>
                      props.FetchFavoriteLevelList(product_id)
                    }
                    HandelChangeTrueWishList={() =>
                      props.HandelChangeTrueWishList()
                    }
                    handlePopOpen={(id, slug, next, prev, index) =>
                      props.handlePopOpen(id, slug, next, prev, index)
                    }
                    openAddCount={(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      containertype
                    ) =>
                      props.openAddCount(
                        id,
                        added_quantity,
                        added_quantity_is_case,
                        containertype
                      )
                    }
                    disabled={props.disabled}
                    inputField={props.inputField}
                    changeInputBoxValue={() => props.changeInputBoxValue()}
                    ProductCaseFunctionlity={(id, event) =>
                      props.ProductCaseFunctionlity(id, event)
                    }
                  />
                );
              })
            : ""}
        </Carousel>
      </div>
    </section>
  );
};

export default CommonProductview;
