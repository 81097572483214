import React from "react";
import { TypographyTextColor } from "./UiDesign";

const AddressViewBlock = (props) => {
  const { selectedAddress } = props;
  return (
    <>
      <TypographyTextColor Color="heading" component="b">
        {selectedAddress?.name
          ? selectedAddress.name
          : selectedAddress?.first_name + " " + selectedAddress?.last_name}
      </TypographyTextColor>

      <div>
        {selectedAddress?.address && selectedAddress?.address !== ""
          ? selectedAddress?.address
          : selectedAddress?.street_name !== "" &&
            selectedAddress?.street_name !== undefined
          ? selectedAddress?.street_name
          : "" + " "}{" "}
      </div>
      <div style={{ display: "flex", gap: "5px",justifyContent:
                  window.screen.availWidth >= 700 ? "left" : "center", }}>
        <span
          style={{
            display:
              selectedAddress?.city_name !== null &&
              selectedAddress?.city_name !== ""
                ? "block"
                : "none",
          }}
        >
          {selectedAddress?.city_name + ", "}{" "}
        </span>
        <span
          style={{
            display:
              selectedAddress?.state_name !== null &&
              selectedAddress?.state_name !== ""
                ? "block"
                : "none",
          }}
        >
          {selectedAddress?.state_name}{" "}
        </span>
        <span
          style={{
            display:
              selectedAddress?.zipcode !== null &&
              selectedAddress?.zipcode !== ""
                ? "block"
                : "none",
          }}
        >
          {selectedAddress?.zipcode}
        </span>
      </div>
      <div>
        {selectedAddress?.pickup_message !== "" &&
        selectedAddress?.pickup_message !== null &&
        selectedAddress?.pickup_message !== "undefined" &&
        selectedAddress?.pickup_message !== undefined ? (
          <div className="cust_home">
            <span
              data-toggle="tooltip"
              data-placement="top"
              title={selectedAddress.pickup_message}
            >
              Message:{" "}
              {selectedAddress?.pickup_message.length > 50
                ? selectedAddress?.pickup_message.slice(0, 50) + "..."
                : selectedAddress.pickup_message}{" "}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AddressViewBlock;
