import React, { createElement } from "react";
import { TypographyBgColor, TypographyHoverFontColor } from "./UiDesign";
import DefaultImg from "../../assets/images/grocery-store.png";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import * as Icons from "react-icons/fa";
import Masonry from "react-responsive-masonry";
// import { Masonry } from "@mui/lab";
const SideCategoryView = (props) => {
  const { categoryviewdata, web_customize } = props;
  return (
    <div className="new-categoryView">
      <TypographyHoverFontColor
        Color={"heading"}
        onClick={() =>
          props.FetchProduct(
            categoryviewdata?.id,
            categoryviewdata,
            categoryviewdata.category_message
          )
        }
        className="d-flex align-items-center "
      >
        {categoryviewdata?.icon_image === null ||
        categoryviewdata?.icon_image === "" ? (
          web_customize?.is_show_category_icon === true ? (
            <img
              src={DefaultImg}
              alt="default"
              style={{ width: "50px", objectFit: "contain" }}
            />
          ) : (
            <></>
          )
        ) : Icons[categoryviewdata?.icon_image] === undefined ? (
          <img
            src={
              categoryviewdata?.icon_image
                ?categoryviewdata?.icon_image[0]==="/"? ApiUrls.BASH_URL + "/media/" + categoryviewdata?.icon_image:categoryviewdata?.icon_image
                : ""
            }
            alt="image1"
            style={{ width: "50px", mixBlendMode: "multiply" }}
          />
        ) : (
          createElement(Icons[categoryviewdata?.icon_image], {
            style: { fontSize: "2.3rem" },
          })
        )}

        <span className="ml-2">{categoryviewdata?.name}</span>

        {window.screen.availWidth > 900 && window.screen.availWidth < 1050 ? (
          <span
            onClick={(e) => {
              props.handleClosefulldialoague();
              e.stopPropagation();
            }}
            className="icon-close"
            style={{ position: " absolute", right: "20px" }}
          ></span>
        ) : (
          <></>
        )}
      </TypographyHoverFontColor>

      <div class="menu-wraper-inner">

        <Masonry columnsCount={4}>
          {categoryviewdata?.childItem?.length > 0 ? (
            categoryviewdata?.childItem.map((ele, i) => {
              return (
                <>
                  {ele.has_child === true ? (
                    <>
                      <div
                        className="cat-item"
                        key={i}
                        onClick={() =>
                          props.FetchProduct(
                            ele?.id,
                            categoryviewdata,
                            ele.category_message
                          )
                        }
                        style={{
                          display:
                            categoryviewdata?.childItem?.length > 1
                              ? "flex"
                              : "block",
                        }}
                      >
                        <TypographyHoverFontColor Color={"category"}>
                          {ele.name}
                        </TypographyHoverFontColor>
                        <ul
                          style={{ marginLeft: "10px" }}
                          className="subSub-class"
                        >
                          {ele.childItem.map((val, ind) => {
                            return (
                              <li
                                key={ind}
                                onClick={(e) => {
                                  props.FetchProduct(
                                    val?.id,
                                    ele,
                                    val.category_message
                                  );
                                  e.stopPropagation();
                                }}
                              >
                                <TypographyHoverFontColor
                                  Color={"category"}
                                  className="subSub-item"
                                >
                                  {" "}
                                  {val.name}
                                </TypographyHoverFontColor>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      <li
                        className="cat-item"
                        key={i}
                        onClick={() =>
                          props.FetchProduct(
                            ele?.id,
                            categoryviewdata,
                            ele.category_message
                          )
                        }
                        style={{
                          display:
                            categoryviewdata?.childItem?.length > 1
                              ? "flex"
                              : "block",
                        }}
                      >
                        <TypographyHoverFontColor Color={"category"}>
                          {" "}
                          {ele.name}
                        </TypographyHoverFontColor>
                      </li>
                    </>
                  )}
                </>
              );
            })
          ) : (
            <></>
          )}
        </Masonry>
        {/* <ul
          className="testul"
          style={{
            display:
              categoryviewdata?.childItem?.length > 1
                ? "inline-block"
                : "inline-flex",
          }}
        >
          {categoryviewdata?.childItem?.length > 0 ? (
            categoryviewdata?.childItem.map((ele, i) => {
              return (
                <>
                  {ele.has_child===true ? (
                    <>
                      <li
                        className="cat-item"
                        key={i}
                        onClick={() =>
                          props.FetchProduct(
                            ele?.id,
                            categoryviewdata,
                            ele.category_message
                          )
                        }
                        style={{
                          display:
                            categoryviewdata?.childItem?.length > 1
                              ? "flex"
                              : "block",
                        }}
                      >
                        <TypographyHoverFontColor Color={"category"}>
                          {ele.name}
                        </TypographyHoverFontColor>
                        <ul
                          style={{ marginLeft: "10px" }}
                          className="subSub-class"
                        >
                          {ele.childItem.map((val, ind) => {
                            return (
                              <li
                                key={ind}
                                onClick={(e) => {
                                  props.FetchProduct(
                                    val?.id,
                                    ele,
                                    val.category_message
                                  );
                                  e.stopPropagation();
                                }}
                              >
                                <TypographyHoverFontColor
                                  Color={"category"}
                                  className="subSub-item"
                                >
                                  {" "}
                                  {val.name}
                                </TypographyHoverFontColor>
                              </li>
                            );
                          })}
                        </ul>
                     </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="cat-item"
                        key={i}
                        onClick={() =>
                          props.FetchProduct(
                            ele?.id,
                            categoryviewdata,
                            ele.category_message
                          )
                        }
                        style={{
                          display:
                            categoryviewdata?.childItem?.length > 1
                              ? "flex"
                              : "block",
                        }}
                      >
                        <TypographyHoverFontColor Color={"category"}>
                          {" "}
                          {ele.name}
                        </TypographyHoverFontColor>
                      </li>
                    </>
                  )}
                </>
              );
            })
          ) : (
            <></>
          )}
        </ul> */}
      </div>
    </div>
  );
};

export default SideCategoryView;
