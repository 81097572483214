import React, { useRef, useEffect, useState } from "react";
import { GetCustomizationSettings } from "../../../actions/home/homeAction";
import { Typewriter, useTypewriter } from "react-simple-typewriter";
const InputBox = (props) => {
  const [animationActive, setAnimationActive] = useState(true);
  const [text] = useTypewriter({
    words: props.placeholder,
    loop: 0,
    typeSpeed: 50,
    cursorStyle: "|",
    cursorBlinking: true,
    cursor: true,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
    active: animationActive,
  });
  return (
    <input
      type="text"
      autoComplete="off"
      className={`form-control blink-cursor`}
      id="search_input"
      aria-describedby={"simple-popover"}
      value={props.searchTextValue}
      onChange={(e) => props.searchText(e.target.value)}
      onKeyPress={(event) => props.enterPressed(event)}
      onFocus={(e) => {
        props.handleFocusSearchbox(e);
        setAnimationActive(false);
      }}
      onBlur={(e) => {
        props.handleBlurSearchbox(e);
        setAnimationActive(true);
      }}
      placeholder={animationActive ? text : ""}
    />
  );
};
const SearchInputBox = (props) => {
  const [placeholder, setPlaceholder] = useState([]);

  // const handleinitiate = () => {
  //   FetchSetting();
  // };
  const FetchSetting = () => {
    let data = localStorage.getItem("web_customize");
    let accesstoken = JSON.parse(localStorage.getItem("access_token"));
    if (data !== null) {
      setPlaceholder(
        data === ""
          ? ["Search " + props.commonPageTitel(accesstoken)]
          : JSON?.parse(data)?.searchPlaceholder?.split(",")
      );
    }
  };
  useEffect(() => {
    FetchSetting();
  }, [localStorage.getItem("web_customize")]);

  return placeholder?.length > 0 ? (
    <InputBox
      placeholder={placeholder}
      handleBlurSearchbox={(e) => props.handleBlurSearchbox(e)}
      handleFocusSearchbox={(e) => props.handleFocusSearchbox(e)}
      enterPressed={(e) => props.enterPressed(e)}
      searchTextValue={props.searchTextValue}
      searchText={(value) => props.searchText(value)}
    />
  ) : (
    <></>
  );
};

export default SearchInputBox;
