import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import * as React from "react";
import CreateFavorite from "../../ProductCard/createFavorite";
import {
  AddFavoriteProductFunctionlity,
  RemoveFavouriteProduct,
  favoriteLevelListFunctionlity,
} from "../../../../actions/favorite/favoriteAction";
import ReactSlick from "../ReactSlick";
import {
  LabelPrimaryColor,
  SaleLabelySecondaryColor,
  ScrollColorTypography,
  SecondaryButton,
  SecondaryCancelButton,
  TextFormating,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../../UiDesign";
import {
  RefreshApiToken,
  fromBinary,
  getSchedule_zip,
} from "../../../../utils/renderUtils/renderUtil";
import ItemDetailDescription from "../../ProductDetail/ItemDetailDescription";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductNoteChipsview from "../../Header/ProductNoteChipsview";
import { AiOutlineClose } from "react-icons/ai";
import CustomPreloader from "../../Loader/CustomPreloader";

import {
  AddToCartFunctionlity,
  PopularProductsList,
  UpdateCartFunctionlity,
} from "../../../../actions/home/homeAction";
import { DeleteCrtIemFunctionlity } from "../../../../actions/cart/cartAction";
import ItemCardMob from "../../ProductCard/Mobile/ItemCardMob";
import NoImage from "../../../../assets/images/image-coming-soon.jpg";
export default function DetailpageMob(props) {
  const {
    ProductDetails,
    itemDetailsmobModal,
    pathname,
    history,
    containerValue,
    prev_rec,
    next_rec,
    container,
    extraCharges,
    child_items,
    selectedChilditem,
    chack_is_case,
    pro_item_details_ind,
    containerLength,
    container_type,
    AddtoCartBtn,
    inputField,
    productDetailsLoader,
    PopularProductList,
  } = props;

  const [detailData, setDetaildata] = React.useState({
    favoriteLevelDataForItem: [],
    web_config: JSON.parse(localStorage.getItem("web_customize")),
    itemCount: 0,
    wishlist: false,
    setOpenItemNote: false,
    productNoteItemNote: "",
    doNotSubstitute: false,
    substituteWithSimilar: false,
    callMe: false,
    backupItemInstraction: "",
  });
  const [isShowItemCode, setisShowItemCode] = React.useState(
    JSON.parse(localStorage.getItem("Store_address"))?.is_show_item_code
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.closeItemdetailMobile();
  };

  const onEditModalOpen = (value) => {
    let backupItemInstraction = detailData.backupItemInstraction;
    let productNoteItemNote = detailData.productNoteItemNote;
    let doNotSubstitute = detailData.doNotSubstitute;
    let substituteWithSimilar = detailData.substituteWithSimilar;
    let callMe = detailData.callMe;

    productNoteItemNote = props.ProductDetails.note[0].split("~")[0];
    let selectBacknote = props.ProductDetails.note[0].split("~")[1];
    if (selectBacknote === "Do not substitute") {
      doNotSubstitute = true;
    } else {
      doNotSubstitute = false;
    }
    if (selectBacknote === "Substitute with similar") {
      substituteWithSimilar = true;
    } else {
      substituteWithSimilar = false;
    }

    if (selectBacknote === "Call me") {
      callMe = true;
    } else {
      callMe = false;
    }
    setDetaildata({
      ...detailData,
      setOpenItemNote: true,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
      productNoteItemNote: productNoteItemNote,
      backupItemInstraction,
    });
  };
  const CloseDialog = (value_icon) => {
    let productNote = detailData.productNoteItemNote;
    let mainNote = props.ProductDetails.note[0];
    if (value_icon === "Cancel") {
      setDetaildata({ ...detailData, setOpenItemNote: false });
    } else {
      if (
        productNote === "" &&
        detailData.doNotSubstitute === false &&
        detailData.substituteWithSimilar === false &&
        detailData.callMe === false
      ) {
        props.createSuccessMsg("error", "Please enter product note");
      } else {
        mainNote =
          mainNote === "" || mainNote === null ? "" : mainNote.split("~");

        if (mainNote.length > 1) {
          if (productNote !== "") {
            mainNote[0] = productNote;
          }

          if (detailData.doNotSubstitute) {
            mainNote[1] = "Do not substitute";
          } else if (detailData.substituteWithSimilar)
            mainNote[1] = "Substitute with similar";
          else if (detailData.callMe) mainNote[1] = "Call me";
          else mainNote.pop();
          mainNote = mainNote.join("~");
        } else {
          if (productNote !== "") {
            mainNote = productNote;
          }
          if (detailData.doNotSubstitute) {
            mainNote = mainNote + "~" + "Do not substitute";
          } else if (detailData.substituteWithSimilar)
            mainNote = mainNote + "~" + "Substitute with similar";
          else if (detailData.callMe) mainNote = mainNote + "~" + "Call me";
        }

        props.ProductDetails.note[props.pro_item_details_ind] = mainNote;
        setDetaildata({
          ...detailData,
          setOpenItemNote: false,
          doNotSubstitute: false,
          substituteWithSimilar: false,
          callMe: false,
        });
        // console.log(detailData);
        UpdateCartItemFunctionlity(
          props.ProductDetails.id,
          props.ProductDetails.name,
          props.ProductDetails.added_quantity[props.pro_item_details_ind],
          props.ProductDetails.added_quantity_is_case[
            props.pro_item_details_ind
          ],
          "icon",
          props.ProductDetails.slug,
          props.ProductDetails.note[props.pro_item_details_ind],
          ""
        );
      }
    }
  };
  const FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: product_id,
    };
    favoriteLevelListFunctionlity(formValues, "").then((res) => {
      let favoriteLevelDataForItem = [];
      if (res.data && res.data.result) {
        favoriteLevelDataForItem = res.data.result;
      }
      setDetaildata({
        ...detailData,
        favoriteLevelDataForItem: favoriteLevelDataForItem,
      });
    });
  };
  const renderoptionTemplate = () => {
    let optionTemplate = [];
    if (container && container.length > 0) {
      optionTemplate = container.map((v) => (
        <option value={v.value}>{v.label}</option>
      ));
    }
    return optionTemplate;
  };
  const openAddQuantityCount = (
    values,
    quantity,
    added_qty_is_case,
    is_parent
  ) => {
    if (quantity > 0) {
      if (values.original_price === 0 && is_parent) {
        props.createSuccessMsg("error", "Please select the item");
      } else {
        if (
          localStorage.getItem("frontend_animation") &&
          localStorage.getItem("frontend_animation") ===
            "must_login_add_to_cart" &&
          localStorage.getItem("isLogin") === "false"
        ) {
          props.OpenLoginPoup();
        } else if (
          localStorage.getItem("slot") === undefined ||
          (localStorage.getItem("slot") === null &&
            localStorage.getItem("frontend_animation") &&
            localStorage.getItem("frontend_animation") !== "open" &&
            localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
        ) {
          props.openisslotDeliverypopupState_compwill();
        } else {
          let product_id = values.id;
          AddToCart(product_id, quantity, added_qty_is_case);
        }
      }
    }
  };
  const openAddCount = (
    product_id,
    added_quantity,
    is_case,
    categoryindex,
    addTocartQty = 1,
    is_parent
  ) => {
    added_quantity = parseInt(added_quantity);
    addTocartQty = parseInt(addTocartQty);
    if (added_quantity === 1) {
      AddToCartFunc(product_id, added_quantity, is_case, "Frequently");
      // }
    } else if (added_quantity > 1) {
      props.UpdateCartItemPopularproduct(
        product_id,
        added_quantity,
        is_case,
        "",
        ""
      );
    } else {
      deleteCartItem(
        product_id,
        "",
        added_quantity,
        is_case,
        "",
        "",
        "Frequently"
      );
    }
  };
  const AddToCartFunc = (
    product_id,
    added_quantity,
    added_qty_is_case,
    selector
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: added_qty_is_case,
      note: "",
      container_type: "",
    };

    let PopularProduct = PopularProductList;
    AddToCartFunctionlity(formValue, "").then((res) => {
      if (res && res.status === 200) {
        props.commonFunctionLity(product_id, added_quantity, added_qty_is_case);

        props.FetchCartCount();
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.AddToCartFunc(
              product_id,
              added_quantity,
              added_qty_is_case,
              selector
            );
          });
        }
      } else {
        if (res.errors && res.errors.status === 400 && res.errors.data) {
          props.createSuccessMsg("error", res.errors.data.errors);
        }
      }
    });
  };

  const AddToCart = (product_id, quantity, added_qty_is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: added_qty_is_case,
      note: "",
      container_type: container_type,
    };
    AddToCartFunctionlity(formValue, "").then((res) => {
      if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.AddToCart(product_id, quantity, added_qty_is_case);
          });
        }
      } else if (res.errors && res.errors.data) {
        props.createSuccessMsg("error", res.errors.data.errors);
      } else if (res && res.status === 200) {
        var added_qtyInd =
          ProductDetails["added_quantity_is_case"].indexOf(added_qty_is_case);
        props.FetchCartCount();
        props.commonFunctionForContainer(
          added_qtyInd,
          quantity,
          added_qty_is_case
        );
      }
    });
  };
  const renderPopularItem = () => {
    return PopularProductList && PopularProductList.length > 0
      ? PopularProductList.map((row, index) => {
          var c_i_case = props.chack_is_casefrequently[row.id]
            ? props.chack_is_casefrequently[row.id]
            : false;
          var check_added_quantity_is_case =
            row.added_quantity_is_case.indexOf(c_i_case);
          var check_is_case_flag = false;
          if (check_added_quantity_is_case > -1) {
            check_is_case_flag = true;
          } else {
            check_is_case_flag = true;
            row.added_quantity_is_case.push(c_i_case);
            row.added_quantity.push(0);
            check_added_quantity_is_case =
              row.added_quantity_is_case.indexOf(c_i_case);
          }
          return (
            <ItemCardMob
              extraCharges={extraCharges}
              check_is_case_flag={check_is_case_flag}
              check_added_quantity_is_case={check_added_quantity_is_case}
              itemData={row}
              favoriteLevelListData={detailData.favoriteLevelDataForItem}
              index={index}
              removeFromfavouriteProduct={(favoriot_id, product_id) =>
                this.removeFromfavouriteProduct(favoriot_id, product_id)
              }
              addFavoiteLevelFunctionlity={(value, product_id) =>
                this.addFavoiteLevelFunctionlity(value, product_id)
              }
              history={history}
              activePageName={pathname}
              addFavoiteProductFunctionlity={(
                favoritelabel_id,
                id,
                added_quantity,
                added_quantity_is_case
              ) =>
                this.addFavoiteProductFunctionlity(
                  favoritelabel_id,
                  id,
                  added_quantity,
                  added_quantity_is_case
                )
              }
              FetchFavoriteLevelList={(product_id) =>
                this.FetchFavoriteLevelList(product_id)
              }
              HandelChangeTrueWishList={() => this.HandelChangeTrueWishList()}
              handlePopOpen={(id, slug, next, prev, index) =>
                props.handlepopularOpen(id, slug, next, prev, index)
              }
              openAddCount={(
                id,
                added_quantity,
                added_quantity_is_case,
                categoryindex,
                containertype
              ) =>
                openAddCount(
                  id,
                  added_quantity,
                  added_quantity_is_case,
                  categoryindex,
                  containertype
                )
              }
              inputField={false}
              changeInputBoxValue={() => this.changeInputBoxValue()}
              ProductCaseFunctionlity={(id, event) =>
                props.ProductCaseFunctionlity(id, event)
              }
              restrictByOnhand={props.restrictByOnhand}
            />
          );
        })
      : "";
  };

  const increaseItemCount = (
    added_quantity,
    added_qty_is_case,
    container_type
  ) => {
    added_quantity = parseInt(added_quantity);
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      props.OpenLoginPoup();
      // this.props.history.history.push({ pathname: "/login" });
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      props.openisslotDeliverypopupState_compwill();
    } else {
      UpdateCartQty(
        ProductDetails,
        added_quantity,
        added_qty_is_case,
        container_type
      );
    }
  };
  const openAddToCart = (added_quantity, added_qty_is_case) => {
    added_quantity = parseInt(added_quantity);
    var added_qtyInd =
      ProductDetails["added_quantity_is_case"].indexOf(added_qty_is_case);
    if (added_quantity >= 0) {
      props.commonFunctionForContainer(
        added_qtyInd,
        added_quantity,
        added_qty_is_case
      );
    } else {
      AddtoCartBtn = true;
    }
    UpdateCartQty(ProductDetails, added_quantity, added_qty_is_case);
  };
  const UpdateCartQty = (
    values,
    added_quantity,
    added_quantity_is_case,
    container_type,
    btn_icons
  ) => {
    let product_id = values.id;
    let slug = values.slug;
    let quantity = added_quantity;
    let product_name_lowercase = values.name.toLowerCase();
    let product_name = product_name_lowercase.replace(/\s/g, "-");
    let is_case = added_quantity_is_case;
    let note = values.note[0];
    if (added_quantity >= 1) {
      UpdateCartItemFunctionlity(
        product_id,
        product_name,
        quantity,
        is_case,
        btn_icons,
        slug,
        note
      );
    } else {
      deleteCartItem(
        product_id,
        product_name,
        added_quantity,
        added_quantity_is_case,
        btn_icons,
        slug
      );
    }
  };
  const deleteCartItem = (
    product_id,
    product_name,
    added_quantity,
    added_quantity_is_case,
    btn_icons,
    slug,
    type = ""
  ) => {
    let formValue = {
      product_id: product_id,
      is_case: added_quantity_is_case,
      container_type: container_type,
    };
    DeleteCrtIemFunctionlity(formValue, "").then((res) => {
      if (res.data) {
        if (type === "") props.hendleAddtoCartBtn();
        else
          props.commonFunctionLity(
            product_id,
            added_quantity,
            added_quantity_is_case
          );
        props.FetchCartCount();
      }
    });
  };
  const backupInstractTion = (value) => {
    let doNotSubstitute = detailData.doNotSubstitute;
    let substituteWithSimilar = detailData.substituteWithSimilar;
    let callMe = detailData.callMe;
    let backupItemInstraction = detailData.backupItemInstraction;
    if (value === "Do not substitute") {
      doNotSubstitute = !doNotSubstitute;
      substituteWithSimilar = false;
      callMe = false;
    } else if (value === "Substitute with similar") {
      substituteWithSimilar = !substituteWithSimilar;
      doNotSubstitute = false;
      callMe = false;
    } else {
      callMe = !callMe;
      doNotSubstitute = false;
      substituteWithSimilar = false;
    }
    if (doNotSubstitute === true) {
      backupItemInstraction = "Do not substitute";
    } else if (substituteWithSimilar === true) {
      backupItemInstraction = "Substitute with similar";
    } else if (callMe === true) {
      backupItemInstraction = "Call me";
    } else {
      backupItemInstraction = "";
    }

    setDetaildata({
      ...detailData,
      backupItemInstraction,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
    });
  };
  const UpdateCartItemFunctionlity = (
    product_id,
    product_name,
    quantity,
    is_case,
    btn_icons,
    slug,
    note,
    container_type,
    type = ""
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: is_case,
      slug: slug,
      note: note,
      container_type: container_type,
    };

    UpdateCartFunctionlity(formValue, "").then((res) => {
      if (res.errors && res.errors.data) {
        props.createSuccessMsg("error", res.errors.data.errors);
      } else {
        if (quantity >= 1) {
          console.log(type);
          if (type === "") {
            var added_qtyInd =
              ProductDetails["added_quantity_is_case"].indexOf(is_case);

            props.commonFunctionForContainer(added_qtyInd, quantity, is_case);
            setDetaildata({
              ...detailData,
              itemCount: detailData.itemCount + 1,
              setOpenItemNote: false,
            });
          } else props.commonFunctionLity(product_id, quantity, is_case);
          props.FetchCartCount();
        }
      }
    });
  };
  const addFavoiteProductFunctionlity = (favoritelabel_id, product_id) => {
    const ProductDetails = props.ProductDetails;
    const pro_item_details_ind = props.pro_item_details_ind;
    if (favoritelabel_id !== "") {
      let formValues = {
        product_id: ProductDetails.id,
        favoritelabel_id: favoritelabel_id ? favoritelabel_id.toString() : "",
        quantity: ProductDetails.added_quantity[pro_item_details_ind]
          ? ProductDetails.added_quantity[pro_item_details_ind]
          : 0,
        is_case: ProductDetails.added_quantity_is_case[pro_item_details_ind]
          ? ProductDetails.added_quantity_is_case[pro_item_details_ind]
          : false,
        note: ProductDetails.note[pro_item_details_ind]
          ? ProductDetails.note[pro_item_details_ind]
          : "",
        container_type: props.container_type ? props.container_type : "",
      };
      AddFavoriteProductFunctionlity(formValues, "").then((res) => {
        if (res.errors && res.errors.status === 400) {
          props.createSuccessMsg("error", res.errors.data.errors);
          ProductDetails.wishlist = false;
        } else {
          if (ProductDetails.id === product_id && favoritelabel_id != "") {
            ProductDetails.wishlist = true;
            setDetaildata({ ...detailData, wishlist: true });
          } else {
            ProductDetails.wishlist = false;
            setDetaildata({ ...detailData, wishlist: false });
          }

          ProductDetails.favoriot_id = res.favorite_ids;
          props.createSuccessMsg("success", res.message);
        }
      });
    }
  };
  const removeFromfavouriteProduct = (favoriteproduct_id, product_id) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.join(","),
    };

    RemoveFavouriteProduct(formValues, "").then((res) => {
      let productData = props.ProductDetails;

      if (productData.id === product_id) {
        productData.wishlist = true;
      } else {
        productData.wishlist = false;
      }
      setDetaildata({ ...detailData, wishlist: false });
      productData.favoriot_id = [];
    });
  };
  const OpenDialog = () => {
    if (
      props.ProductDetails.added_quantity[props.pro_item_details_ind] ===
      undefined
    )
      props.createSuccessMsg("error", "Atleast 1 Quantity is required");
    else {
      setDetaildata({
        ...detailData,
        setOpenItemNote: true,
        productNoteItemNote: "",
      });
    }
  };
  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer("bottom", true)}>{"bottom"}</Button>
        {itemDetailsmobModal && (
          <Drawer
            anchor={"bottom"}
            open={itemDetailsmobModal}
            onClose={toggleDrawer("bottom", false)}
            style={{ overflow: "hidden" }}
          >
            <ScrollColorTypography
              component="div"
              className="details_main_desc"
              style={{ height: "100vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-end py-1">
                <AiOutlineClose
                  onClick={() => props.closeItemdetailMobile()}
                  style={{ fontSize: "3rem" }}
                  className="p-1"
                />
              </div>
              {productDetailsLoader ? (
                <CustomPreloader />
              ) : (
                <>
                  <div className="details_main details_main_nav">
                    <div className="navBttns">
                      <div
                        className="leftNav"
                        onClick={() => {
                          if (prev_rec === true)
                            props.preProductItem(true, false);
                        }}
                        style={{
                          fontSize: "3rem",
                          opacity: prev_rec === true ? "" : "0.3",
                        }}
                      >
                        <span
                          class="icon-left-arrow"
                          style={{ background: "white" }}
                        ></span>
                      </div>

                      <div
                        className="rightNav"
                        onClick={() => {
                          if (next_rec === true)
                            props.nextProductItem(false, true);
                        }}
                        style={{
                          fontSize: "3rem",
                          opacity: next_rec === true ? "" : "0.3",
                        }}
                      >
                        <span
                          class="icon-left-arrow"
                          style={{ background: "white" }}
                        ></span>
                      </div>
                    </div>
                    <div className="details_img_caro">
                      <div className="likecart">
                        <CreateFavorite
                          addFavoiteLevelFunctionlity={(value, product_id) =>
                            this.addFavoiteLevelFunctionlity(value, product_id)
                          }
                          favoriteLevelData={
                            detailData.favoriteLevelDataForItem
                          }
                          activePageName={pathname}
                          history={history}
                          addFavoiteProductFunctionlity={(favoritelabel_id) =>
                            addFavoiteProductFunctionlity(
                              favoritelabel_id,
                              ProductDetails.id
                            )
                          }
                          wishlist={
                            ProductDetails.wishlist && detailData.wishlist
                              ? true
                              : false
                          }
                          product_id={ProductDetails.id}
                          FetchFavoriteLevelList={(product_id) =>
                            this.FetchFavoriteLevelList(product_id)
                          }
                          removeFromfavouriteProduct={(
                            favoriot_id,
                            product_id
                          ) =>
                            removeFromfavouriteProduct(favoriot_id, product_id)
                          }
                          favoriot_id={ProductDetails.favoriot_id}
                        />
                      </div>
                      <ReactSlick
                        ProductImages={
                          ProductDetails.image
                            ?.split(",")
                            ?.filter((x) => x !== "")?.length > 0
                            ? ProductDetails.image
                                ?.split(",")
                                ?.filter((x) => x !== "")
                            : [NoImage]
                        }
                        ProductDetails={ProductDetails}
                        extraCharges={extraCharges}
                      />
                      {extraCharges.is_show_out_of_stock === true &&
                      ProductDetails.out_of_stock === true ? (
                        <div
                          className="out-of-stock"
                          style={{ left: 0, top: "50%" }}
                        >
                          <LabelPrimaryColor
                            className="text"
                            component={"span"}
                            border={2}
                            style={{ fontSize: "20px" }}
                          >
                            Out Of Stock
                          </LabelPrimaryColor>
                        </div>
                      ) : extraCharges.is_show_out_of_stock === true &&
                        ProductDetails.out_of_stock_case === true &&
                        chack_is_case === true ? (
                        <div
                          className="out-of-stock"
                          style={{ left: 0, top: "50%" }}
                        >
                          <LabelPrimaryColor
                            className="text"
                            component={"span"}
                            border={2}
                            style={{ fontSize: "20px" }}
                          >
                            Out Of Stock
                          </LabelPrimaryColor>
                        </div>
                      ) : (
                        ""
                      )}
                      {ProductDetails.is_special === true ? (
                        <div className="newsalee">
                          <Typography component={SaleLabelySecondaryColor}>
                            SALE{" "}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="details_desc_wrapper"
                      style={{ position: "relative" }}
                    >
                      <div
                        id="portal"
                        className="portal"
                        style={{ position: "absolute", zIndex: 999 }}
                      />
                      <div className="details_head_wrapper">
                        <div
                        // className={
                        //  ( ProductDetails.is_ebt === false|| ProductDetails?.brand_name === null) &&
                        //   (ProductDetails?.package === null ||
                        //     ProductDetails?.package?.trim() === "" ||
                        //     ProductDetails?.package === "0.0 0" ||
                        //     ProductDetails?.package === "0.0 ")
                        //     ? "details_head"
                        //     : ""
                        // }
                        >
                          {ProductDetails?.sub_description !== "" &&
                          ProductDetails?.sub_description !== null &&
                          ProductDetails?.sub_description !== undefined ? (
                            <div className="item-subdes-container">
                              <TypographyPrimaryBgColorDimmed
                                border={0}
                                component="span"
                                style={{
                                  position: "initial",
                                  padding: "5px 10px",
                                }}
                              >
                                {ProductDetails?.sub_description}
                              </TypographyPrimaryBgColorDimmed>
                            </div>
                          ) : (
                            <></>
                          )}
                          <TextFormating
                            component={"h3"}
                            Color="product"
                            className={
                              ProductDetails &&
                              ProductDetails.brand_name === null
                                ? "product_empty_brand"
                                : ""
                            }
                          >
                            {ProductDetails?.name !== undefined
                              ? detailData.web_config?.TextTransform?.main ===
                                "none"
                                ? ProductDetails.name
                                : ProductDetails.name.toLowerCase()
                              : ""}
                          </TextFormating>
                          <div className="details_type">
                            <TypographyTextColor
                              component="p"
                              Color="other"
                              className="mb-1 brand-name"
                              style={{ fontSize: "12px" }}
                            >
                              {ProductDetails && ProductDetails.brand_name
                                ? ProductDetails.brand_name +
                                  `${
                                    ProductDetails?.package?.trim() !== "" &&
                                    ProductDetails?.package !== "0.0 0" &&
                                    ProductDetails?.package !== "0.0 "
                                      ? " | "
                                      : ""
                                  }`
                                : ""}
                              {ProductDetails &&
                              ProductDetails?.package !== "0.0 0" &&
                              ProductDetails?.package !== "0.0 " &&
                              ProductDetails?.package?.trim() !== ""
                                ? ProductDetails?.package?.replace(
                                    /\.0(?!\d)/g,
                                    ""
                                  )
                                : ""}
                              {(ProductDetails.package?.trim() !== "" ||
                                ProductDetails.brand_name !== null) &&
                              isShowItemCode === true
                                ? " | "
                                : ""}
                              {isShowItemCode ? (
                                ProductDetails.item_code
                              ) : (
                                <></>
                              )}
                              {/* {(ProductDetails.package?.trim() !== "" ||
                        ProductDetails.brand_name !== null) &&
                      ProductDetails.is_ebt === true
                        ? " | "
                        : ""}
                      {ProductDetails.is_ebt === true ? " EBT" : ""} */}
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                      {child_items &&
                      child_items.length > 0 &&
                      ProductDetails.is_parent &&
                      selectedChilditem === "" ? (
                        <></>
                      ) : (
                        <div className="details_pricerow d-flex justify-content-between align-items-center">
                          <div>
                            {ProductDetails &&
                            ProductDetails.availableondays ? (
                              <h7 style={{ color: "#f57f18" }}>
                                {this.state.msgstr}
                              </h7>
                            ) : (
                              ""
                            )}
                            {ProductDetails.original_price !== 0 ? (
                              <TypographyTextColor
                                className={
                                  ProductDetails.is_by_weight === true &&
                                  ProductDetails.estimate_weight_per_piece > 0
                                    ? "details_pricerow_height"
                                    : ""
                                }
                                Color="other"
                              >
                                {chack_is_case === true
                                  ? "$" +
                                    Number(ProductDetails.case_price).toFixed(2)
                                  : ProductDetails.is_special === true
                                  ? ProductDetails.special_quantity > 0
                                    ? ProductDetails.special_quantity +
                                      "/$" +
                                      Number(
                                        ProductDetails.special_price
                                      ).toFixed(2)
                                    : "$" +
                                      Number(
                                        ProductDetails.special_price
                                      ).toFixed(2)
                                  : "$" +
                                    Number(ProductDetails.today_price).toFixed(
                                      2
                                    )}{" "}
                                {ProductDetails &&
                                ProductDetails.is_by_weight === true &&
                                chack_is_case === false
                                  ? "/ LB"
                                  : ""}
                                {ProductDetails.is_special === true ? (
                                  <TypographyTextColor
                                    Color="other"
                                    component="span"
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {"$" +
                                      Number(
                                        ProductDetails.today_price
                                      ).toFixed(2)}
                                  </TypographyTextColor>
                                ) : (
                                  ""
                                )}
                                <h6>
                                  <h6>
                                    {chack_is_case
                                      ? "($" +
                                        (
                                          ProductDetails.case_price /
                                          ProductDetails.case_quantity
                                        ).toFixed(2)
                                      : ""}
                                    {ProductDetails &&
                                    ProductDetails.is_by_weight === true &&
                                    chack_is_case
                                      ? "/ LB each)"
                                      : ProductDetails &&
                                        ProductDetails.is_by_weight === false &&
                                        chack_is_case
                                      ? " each)"
                                      : ""}{" "}
                                  </h6>

                                  {ProductDetails.is_by_weight === true &&
                                  ProductDetails.estimate_weight_per_piece > 0
                                    ? "est. " +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.estimate_weight_per_piece *
                                              ProductDetails.added_quantity[
                                                pro_item_details_ind
                                              ]
                                          : ProductDetails.estimate_weight_per_piece *
                                              1
                                      ).toFixed(2) +
                                      " LB / $" +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.estimate_weight_per_piece *
                                              ProductDetails.added_quantity[
                                                pro_item_details_ind
                                              ] *
                                              ProductDetails.today_price
                                          : ProductDetails.estimate_weight_per_piece *
                                              1 *
                                              ProductDetails.today_price
                                      ).toFixed(2)
                                    : ""}

                                  {ProductDetails.is_by_weight === true &&
                                  containerValue !== ""
                                    ? "est. $" +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.today_price *
                                              containerValue *
                                              ProductDetails.added_quantity[
                                                pro_item_details_ind
                                              ]
                                          : ProductDetails.today_price *
                                              containerValue *
                                              1
                                      ).toFixed(2)
                                    : ""}
                                  {container_type !== "" &&
                                  containerValue !== "" &&
                                  ProductDetails.is_by_weight === true
                                    ? "  ( $" +
                                      Number(
                                        ProductDetails.today_price *
                                          containerValue *
                                          1
                                      ).toFixed(2) +
                                      "/" +
                                      containerValue +
                                      " LB ) "
                                    : ""}
                                </h6>
                                {ProductDetails.is_offer ? (
                                  <>
                                    <span>
                                      {ProductDetails.is_offer
                                        ? ProductDetails.unit
                                        : "$"}
                                      {ProductDetails.is_offer
                                        ? ProductDetails.added_quantity_is_case[
                                            pro_item_details_ind
                                          ] === true
                                          ? "$" +
                                            Number(
                                              ProductDetails.case_quantity *
                                                ProductDetails.today_price
                                            ).toFixed(2)
                                          : "$" +
                                            Number(
                                              ProductDetails.original_price
                                            ).toFixed(2)
                                        : ""}
                                    </span>
                                    <span className="price_off">
                                      {ProductDetails.is_offer
                                        ? ProductDetails.offer_tag + "%"
                                        : ""}
                                      {ProductDetails &&
                                      ProductDetails.special_quantity > 0 &&
                                      ProductDetails.special_price > 0 &&
                                      ProductDetails.offer_msg === "B"
                                        ? " (Price $ " +
                                          (
                                            ProductDetails.special_price /
                                            ProductDetails.special_quantity
                                          ).toFixed(2) +
                                          " applied at Minimum 1 quantity"
                                        : ProductDetails &&
                                          ProductDetails.special_quantity > 0 &&
                                          ProductDetails.special_price > 0 &&
                                          ProductDetails.offer_msg === "M"
                                        ? "(Price $ " +
                                          ProductDetails.special_price.toFixed(
                                            2
                                          ) +
                                          " applied at Minimum " +
                                          ProductDetails.special_quantity +
                                          " quantity)"
                                        : ProductDetails &&
                                          ProductDetails.special_quantity > 0 &&
                                          ProductDetails.special_price > 0 &&
                                          ProductDetails.offer_msg === "P"
                                        ? "(Price $" +
                                          (
                                            ProductDetails.special_price /
                                            ProductDetails.special_quantity
                                          ).toFixed(2) +
                                          " applied at the Pack of " +
                                          ProductDetails.special_quantity +
                                          " quantity and Maximum " +
                                          ProductDetails.special_max +
                                          " pack)"
                                        : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </TypographyTextColor>
                            ) : (
                              <p className="details_pricerow_height"></p>
                            )}
                          </div>
                          {extraCharges.is_show_out_of_stock === true &&
                          ProductDetails.out_of_stock === true &&
                          props.restrictByOnhand === true ? (
                            ""
                          ) : child_items &&
                            child_items.length > 0 &&
                            ProductDetails.is_parent &&
                            selectedChilditem === "" ? (
                            <></>
                          ) : ProductDetails.instoreonly === false &&
                            (ProductDetails.is_parent ||
                              ProductDetails.is_case === false) &&
                            (ProductDetails.added_quantity.length === 0 ||
                              ProductDetails.added_quantity_is_case.length ===
                                0) ? (
                            <div className="add_cart">
                              <SecondaryButton
                                Fonttype="other"
                                className="mt-0"
                                style={{ color: "#fff" }}
                                onClick={() => {
                                  openAddQuantityCount(
                                    ProductDetails,
                                    ProductDetails.added_quantity[
                                      pro_item_details_ind
                                    ] > 0
                                      ? ProductDetails.added_quantity[
                                          pro_item_details_ind
                                        ]
                                      : 1,
                                    ProductDetails.added_quantity_is_case[
                                      pro_item_details_ind
                                    ] === true
                                      ? true
                                      : false,
                                    ProductDetails.added_quantity[
                                      pro_item_details_ind
                                    ]
                                  );
                                }}
                              >
                                Add To Cart
                              </SecondaryButton>
                            </div>
                          ) : ProductDetails.instoreonly === false ? (
                            <div className="p-1">
                              <div className="mb-2">
                                <Typography
                                  component={TypographySecondaryBgColor}
                                  className="count_box"
                                >
                                  <button
                                    className="count_btn"
                                    onClick={() =>
                                      openAddToCart(
                                        ProductDetails.added_quantity &&
                                          ProductDetails.added_quantity[
                                            pro_item_details_ind
                                          ] > 0
                                          ? ProductDetails.added_quantity[
                                              pro_item_details_ind
                                            ] - 1
                                          : 1,
                                        // ProductDetails.added_quantity_is_case && ProductDetails.added_quantity_is_case[pro_item_details_ind] === true? true: false,
                                        chack_is_case === true ? true : false,
                                        ProductDetails.container_type &&
                                          ProductDetails.container_type[
                                            pro_item_details_ind
                                          ]
                                          ? ProductDetails.container_type[
                                              pro_item_details_ind
                                            ]
                                          : "",
                                        "icon-minus"
                                      )
                                    }
                                  >
                                    <span className="icon-minus"></span>
                                  </button>
                                  <div className="count_no">
                                    {inputField === false ? (
                                      <input
                                        type="text"
                                        id={ProductDetails.id}
                                        className="count form-control"
                                        name="quentity"
                                        value={
                                          pro_item_details_ind > -1 &&
                                          ProductDetails.added_quantity.length >
                                            0
                                            ? ProductDetails.added_quantity[
                                                pro_item_details_ind
                                              ]
                                            : 0
                                        }
                                        // value={ProductDetails.added_quantity && ProductDetails.added_quantity[pro_item_details_ind] > 0? ProductDetails.added_quantity[pro_item_details_ind]: 0}
                                        // onBlur={()=>this.changeInputBoxValue()}
                                        onChange={(e) =>
                                          this.addQuentityForaddTocart(
                                            e.target.value,
                                            ProductDetails.added_quantity_is_case &&
                                              ProductDetails
                                                .added_quantity_is_case[
                                                pro_item_details_ind
                                              ] === true
                                              ? true
                                              : false,
                                            ProductDetails.container_type &&
                                              ProductDetails.container_type[
                                                pro_item_details_ind
                                              ]
                                              ? ProductDetails.container_type[
                                                  pro_item_details_ind
                                                ]
                                              : "",
                                            "icon-input"
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={ProductDetails.id}
                                        className="count form-control"
                                        name="quentity"
                                        defaultValue={
                                          pro_item_details_ind > -1
                                            ? ProductDetails.added_quantity[
                                                pro_item_details_ind
                                              ]
                                            : 0
                                        }
                                        onBlur={() =>
                                          this.changeInputBoxValue()
                                        }
                                        onChange={(e) =>
                                          this.addQuentityForaddTocart(
                                            e.target.value,
                                            ProductDetails.added_quantity_is_case &&
                                              ProductDetails
                                                .added_quantity_is_case[
                                                pro_item_details_ind
                                              ] === true
                                              ? true
                                              : false,
                                            ProductDetails.container_type &&
                                              ProductDetails.container_type[
                                                pro_item_details_ind
                                              ]
                                              ? ProductDetails.container_type[
                                                  pro_item_details_ind
                                                ]
                                              : "",
                                            "icon-input"
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                  <button
                                    className="count_btn"
                                    onClick={() =>
                                      increaseItemCount(
                                        ProductDetails.added_quantity &&
                                          ProductDetails.added_quantity[
                                            pro_item_details_ind
                                          ] > 0
                                          ? ProductDetails.added_quantity[
                                              pro_item_details_ind
                                            ] + 1
                                          : 1,
                                        chack_is_case === true ? true : false,
                                        ProductDetails.container_type &&
                                          ProductDetails.container_type[
                                            pro_item_details_ind
                                          ]
                                          ? ProductDetails.container_type[
                                              pro_item_details_ind
                                            ]
                                          : "",
                                        "icon-plus"
                                      )
                                    }
                                  >
                                    <span className="icon-plus"></span>
                                  </button>
                                </Typography>
                              </div>
                              {ProductDetails.is_case === true &&
                              ProductDetails.is_parent === false ? (
                                <TypographyPrimaryBgColorDimmed
                                  component={"div"}
                                  border={1}
                                  color="primary"
                                  className="case-cnt"
                                  onClick={() =>
                                    props.ProductCaseFunctionlityForItem(
                                      !chack_is_case
                                    )
                                  }
                                >
                                  <TypographyFontColor
                                    component={"span"}
                                    border={0}
                                    className="case-box"
                                  >
                                    <div className="case-line">
                                      <input
                                        type="checkbox"
                                        className="case-checkbox"
                                        checked={
                                          chack_is_case === true ? true : false
                                        }
                                      />
                                      Case of {ProductDetails.case_quantity}
                                    </div>
                                  </TypographyFontColor>
                                </TypographyPrimaryBgColorDimmed>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div className="add_cart">
                              <SecondaryButton
                                Fonttype="other"
                                disabled="true"
                                style={{ opacity: "0.7" }}
                              >
                                IN STORE ONLY
                              </SecondaryButton>
                            </div>
                          )}
                        </div>
                      )}
      {ProductDetails.is_special === true &&ProductDetails.special_max>0 ? (
                                  <TypographyTextColor
                                    component="h2"
                                    Color="heading"
                                  >
                                  You can add {ProductDetails.special_max} quantity on special price in your cart.
                                  </TypographyTextColor>
                                ) : (
                                  ""
                                )}
                      <div className="container-section">
                        {container && containerLength > 3 ? (
                          <select
                            value={containerValue}
                            class="form-select"
                            id="exampleFormControlSelect1"
                            name="exampleFormControlSelect1"
                            aria-label="Default select example"
                            onChange={(event) =>
                              this.changeConatinerType(event, "select")
                            }
                          >
                            {renderoptionTemplate()}
                          </select>
                        ) : (
                          <RadioGroup
                            value={containerValue}
                            id="exampleRadio"
                            name="exampleRadio"
                            aria-label="gender"
                          >
                            {container && container.length > 0
                              ? container.map((row, index) => {
                                  return (
                                    <FormControlLabel
                                      value={row.value}
                                      id={row.label}
                                      onChange={(e) =>
                                        this.changeConatinerType(
                                          e,
                                          "radio",
                                          row.label
                                        )
                                      }
                                      control={<Radio color="primary" />}
                                      label={row.label}
                                    />
                                  );
                                })
                              : ""}
                          </RadioGroup>
                        )}
                      </div>

                      <div className="details_head_wrapper">
                        {child_items &&
                        child_items.length > 0 &&
                        ProductDetails.is_parent ? (
                          <select
                            value={selectedChilditem}
                            class="form-select"
                            id="exampleFormControlSelect2"
                            aria-label="Default select example"
                            onChange={(e) =>
                              props.changeChilditemdata(e, "select")
                            }
                            style={{
                              padding: "10px",
                              marginTop: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            <option selected="true" value="" disabled={true}>
                              Select Item
                            </option>
                            {child_items.map((val) => (
                              <option value={val.id}>{val.name}</option>
                            ))}
                          </select>
                        ) : (
                          <></>
                        )}

                        {ProductDetails.note &&
                        ProductDetails.note[pro_item_details_ind] &&
                        ProductDetails.added_quantity[pro_item_details_ind] >
                          0 ? (
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: "15px 0" }}
                            className="d-flex justify-content-end"
                          >
                            <Grid item display={"flex"} alignItems={"center"}>
                              <ProductNoteChipsview
                                size="medium"
                                ProductDetailsnotes={
                                  ProductDetails.note[pro_item_details_ind]
                                }
                              />
                            </Grid>
                            <Grid item style={{ alignSelf: "center" }}>
                              <TypographySecondaryBgColorDimmed
                                border={1}
                                color="secondary"
                                className="notebtn-action"
                              >
                                <EditIcon
                                  onClick={(value) =>
                                    onEditModalOpen(
                                      ProductDetails.note[pro_item_details_ind]
                                    )
                                  }
                                />
                              </TypographySecondaryBgColorDimmed>
                            </Grid>
                            <Grid item style={{ alignSelf: "center" }}>
                              <TypographySecondaryBgColorDimmed
                                border={1}
                                color="secondary"
                                className="notebtn-action"
                              >
                                {" "}
                                <DeleteIcon
                                  onClick={() => {
                                    ProductDetails.note[pro_item_details_ind] =
                                      "";

                                    setDetaildata({
                                      ...detailData,
                                      setOpenItemNote: false,
                                      doNotSubstitute: false,
                                      substituteWithSimilar: false,
                                      callMe: false,
                                    });
                                  }}
                                />
                              </TypographySecondaryBgColorDimmed>
                            </Grid>
                          </Grid>
                        ) : (
                          <div className="note_box">
                            <Typography
                              component={TypographySecondaryColor}
                              className="addNotes"
                              Fonttype="other"
                              onClick={() => OpenDialog()}
                            >
                              {" "}
                              <NoteAddIcon /> Add note
                            </Typography>
                          </div>
                        )}
                      </div>
                      {ProductDetails &&
                      (ProductDetails.bpa_free === true ||
                        ProductDetails.dairy_free === true ||
                        ProductDetails.gluten_free === true ||
                        ProductDetails.soy_free === true ||
                        ProductDetails.carrageenan_free === true ||
                        ProductDetails.non_gmo === true ||
                        ProductDetails.vegan === true ||
                        ProductDetails.kitnityot === true ||
                        ProductDetails.kosherforpassover === true ||
                        ProductDetails.natural === true ||
                        ProductDetails.kosher1 !== "" ||
                        ProductDetails.kosher2 !== "" ||
                        ProductDetails.naturalprocessing === true ||
                        ProductDetails.noartificialing === true ||
                        ProductDetails.noeggs === true ||
                        ProductDetails.nosugaradded === true ||
                        ProductDetails.paleofriendly ||
                        ProductDetails.pareve === true ||
                        ProductDetails.pasyisroel === true ||
                        ProductDetails.sulfitefree === true ||
                        ProductDetails.yoshon === true ||
                        ProductDetails.cholovyisroel === true ||
                        ProductDetails.cholovyisroel === true ||
                        ProductDetails.organic === true ||
                        ProductDetails.kosher3 !== "" ||
                        ProductDetails.kosher4 !== "") ? (
                        <div className="details_desc">
                          <ItemDetailDescription
                            ProductDetails={ProductDetails}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {ProductDetails?.ingredient === "" &&
                  ProductDetails.about_product === "" ? (
                    <></>
                  ) : (
                    <div className="details_main_wrapper">
                      {ProductDetails && ProductDetails.about_product ? (
                        <div className="details_main_details">
                          <TypographyTextColor component="h5" Color="heading">
                            About the Product
                          </TypographyTextColor>
                          <TypographyTextColor Color="other">
                            {ProductDetails && ProductDetails.about_product
                              ? fromBinary(ProductDetails.about_product)
                              : ""}
                          </TypographyTextColor>
                        </div>
                      ) : (
                        ""
                      )}
                      {ProductDetails && ProductDetails.ingredient ? (
                        <div className="details_main_details">
                          <TypographyTextColor component="h5" Color="heading">
                            Other Product Info
                          </TypographyTextColor>
                          <p>
                            <TypographyTextColor component="span" Color="other">
                              {ProductDetails ? ProductDetails.ingredient : ""}{" "}
                            </TypographyTextColor>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {productDetailsLoader ? (
                    <></>
                  ) : (
                    <>
                      {PopularProductList?.length > 0 ? (
                        <section className="items-content list_main_content">
                          <div className="header-cnt">
                            <div className="header-details">
                              <TypographyTextColor
                                Color="heading"
                                component="h4"
                              >
                                Frequently Bought Together
                              </TypographyTextColor>
                            </div>
                            <div
                              className="show_all"
                              style={{ marginRight: "15px" }}
                              onClick={() =>
                                this.handleChangeRouteForDeal(
                                  "popular_products"
                                )
                              }
                            >
                              <Link to>See All</Link>
                            </div>
                          </div>
                          <div>
                            <div className="items-details item_listing_details">
                              {renderPopularItem()}
                            </div>
                          </div>
                        </section>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
            </ScrollColorTypography>
            {detailData.setOpenItemNote && (
              <Dialog
                open={detailData.setOpenItemNote}
                onClose={() => CloseDialog("Cancel")}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                className="popUp"
              >
                <DialogContent className="p-0">
                  <div className="popup">
                    <div
                      className="header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TypographyTextColor Color="heading" component="h4">
                        {" "}
                        Add note or backup
                      </TypographyTextColor>
                      <span className="float-right">
                        <IconButton
                          style={{ color: "#389978", fontSize: "18px" }}
                          onClick={() => CloseDialog("Cancel")}
                        >
                          <span class="icon-close"></span>
                        </IconButton>
                      </span>
                    </div>
                    <div className="boxshadow rounded-0 py-3 px-3">
                      <div className="form-group mb-4">
                        <TypographyTextColor
                          Color="heading"
                          className="font-weight-bold"
                        >
                          Product Note
                        </TypographyTextColor>
                        <TextareaAutosize
                          style={{ marginLeft: "1px" }}
                          name="product_note"
                          onChange={(e) =>
                            setDetaildata({
                              ...detailData,
                              productNoteItemNote: e.target.value,
                            })
                          }
                          minRows={3}
                          fullWidth
                          variant="outlined"
                          className="w-100"
                          aria-label="maximum height"
                          placeholder="Add Notes"
                          value={detailData.productNoteItemNote}
                          maxLength={280}
                        />
                      </div>
                      <TypographyTextColor
                        Color="heading"
                        component="h6"
                        className="font-weight-bold"
                      >
                        Backup Instruction
                      </TypographyTextColor>
                      <TypographyTextColor Color="other">
                        Your choice in case the item is out of stock
                      </TypographyTextColor>

                      <FormGroup>
                        <FormControlLabel
                          value="Do not substitute"
                          control={
                            <Radio checked={detailData.doNotSubstitute} />
                          }
                          label={
                            <TypographyTextColor Color="other" component="span">
                              Do not substitute
                            </TypographyTextColor>
                          }
                          onChange={(e) => backupInstractTion(e.target.value)}
                        />
                        <FormControlLabel
                          value="Substitute with similar"
                          control={
                            <Radio checked={detailData.substituteWithSimilar} />
                          }
                          label={
                            <TypographyTextColor Color="other" component="span">
                              Substitute with similar
                            </TypographyTextColor>
                          }
                          onChange={(e) => backupInstractTion(e.target.value)}
                        />
                        <FormControlLabel
                          value="Call me"
                          control={<Radio checked={detailData.callMe} />}
                          label={
                            <TypographyTextColor Color="other" component="span">
                              Call me
                            </TypographyTextColor>
                          }
                          onChange={(e) => backupInstractTion(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <SecondaryCancelButton
                    className="popbtncancel"
                    onClick={() => CloseDialog("Cancel")}
                  >
                    Cancel
                  </SecondaryCancelButton>
                  <SecondaryButton
                    className="popbtn"
                    onClick={() => CloseDialog("Add_note")}
                  >
                    Add Note
                  </SecondaryButton>
                </DialogActions>
              </Dialog>
            )}
          </Drawer>
        )}
      </React.Fragment>
    </div>
  );
}
