import React from "react";
import CategoryCardLoader from "../common/Loader/CategoryCardLoader";
import CategoryCard from "./CategoryCard";
const CategoryHome = (props) => {
  const { CategoryDetails, categoryLoader } = props;
  return (
    <section className="home-product-category">
      {categoryLoader  ? (
      <div className="d-flex overflow-hidden">{  [...Array(window.screen.availWidth <= 900 ? 4 : 16)].map((x, i) => (
          <div className="mr-2">
            <CategoryCardLoader />
          </div>
        ))}</div>
      ) : (
        <ul>
          {CategoryDetails && CategoryDetails.length > 0
            ? CategoryDetails.map((row, index) => {
                return (
                  <li
                    onClick={() =>
                      props.handleInnerOpenDialogHome(
                        row.id,
                        row.name,
                        row.has_child,
                        row.level,
                        row.parent_id,
                        row
                      )
                    }
                    key={index}
                  >
                    <CategoryCard category={row} />
                  </li>
                );
              })
            : ""}
        </ul>
      )}
    </section>
  );
};

export default CategoryHome;
