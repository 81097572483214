import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { capitalize } from "../../../../utils/renderUtils/renderUtil";
import {
  RefineButton,
  TypographyTextColor,
  TypographyFontColor,
  TypographyBgColor,
} from "../../UiDesign";
export default function SwipeableEdgeDrawer(props) {
  // const [state, setState] = React.useState(false);
  const { BrandProduct, flavor, kosher, selectedSortedValue,expanded,setExpanded, sortingData,open,handleSwipeable } =
    props;
  const [totalFilter, settotalFilter] = React.useState(0);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleSwipeable(open);
  };
  const handleChange = (panel) => {
    setExpanded(expanded===panel?"": panel );
  };
  React.useEffect(()=>{
    let count=0
console.log(BrandProduct,flavor,kosher);
BrandProduct.map(ele=>{if(ele?.checkboxChecked)count++})
flavor.map(ele=>{if(ele?.checkboxChecked)count++})
kosher.map(ele=>{if(ele?.checkboxChecked)count++})
settotalFilter(count)
  },[open])
  const list = () => (
    <Box
      style={{ width: "auto", minHeight: "35vh", maxHeight: "80vh" }}
      role="presentation"

      // onClick={toggleDrawer( false)}
      // onKeyDown={toggleDrawer( false)}
    >
      <Box>
        <Accordion
          style={{ margin: "1rem" }}
          expanded={expanded === "panel1"}
          onChange={()=>handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ minHeight: "3rem", maxHeight: 0 }}
          >
            <TypographyTextColor
              Color="heading"
              style={{ fontWeight: 600 }}
              component="h6"
            >
              Sort By
            </TypographyTextColor>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ width: "100%" }}>
              <ul className="filter-panel-swipable">
                {sortingData.map((ele, i) => {
                  return (
                    <li key={i}>
                      
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={ele.value}
                                name="radio-buttons"
                                inputProps={{ "aria-label": ele.value }}
                                checked={selectedSortedValue === ele.value}
                                onChange={() =>
                                  props.handleChangeSorting(ele.value)
                                }
                              />
                            }
                            label={
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                {ele.name}&nbsp;
                              </TypographyTextColor>
                            }
                          />
                        </FormControl>
                     
                    </li>
                  );
                })}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {BrandProduct.length > 0 ? (
          <Accordion
            style={{ margin: "1rem" }}
            expanded={expanded === "panel2"}
            onChange={()=>handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ minHeight: "3rem", maxHeight: 0 }}
            >
              <TypographyTextColor
                Color="heading"
                style={{ fontWeight: 600 }}
                component="h6"
              >
                Brand
              </TypographyTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <ul className="filter-panel-swipable">
                  {BrandProduct.map((ele, i) => {
                    return (
                      <li key={i}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  "checkboxChecked" in ele
                                    ? ele.checkboxChecked
                                    : false
                                }
                                onChange={() =>
                                  props.handleChangeCheckbox(
                                    ele.brand_id,
                                    ele.brand__name
                                  )
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <TypographyTextColor
                                Color="other"
                                className="d-flex"
                                component="span"
                              >
                                {capitalize(ele.brand__name)}&nbsp;
                                <TypographyFontColor border={0}>
                                  {" "}
                                  {ele.item_count}
                                </TypographyFontColor>
                              </TypographyTextColor>
                            }
                          />

                          {/* <div className="count">
                                       {row.item_count}
                                     </div> */}
                        </FormControl>
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
        {flavor.length > 0 ? (
          <Accordion
            style={{ margin: "1rem" }}
            expanded={expanded === "panel3"}
            onChange={()=>handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ minHeight: "3rem", maxHeight: 0 }}
            >
              <TypographyTextColor
                Color="heading"
                style={{ fontWeight: 600 }}
                component="h6"
              >
                Flavor
              </TypographyTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <ul className="filter-panel-swipable">
                  {flavor.map((ele, i) => {
                    return (
                      <li key={i}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  "checkboxChecked" in ele
                                    ? ele.checkboxChecked
                                    : false
                                }
                                onChange={() =>
                                  props.handleChangeCheckboxForFlover(
                                    ele.flavor
                                  )
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                               <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.flavor)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                            }
                          />

                          {/* <div className="count">
                                       {row.item_count}
                                     </div> */}
                        </FormControl>
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
        {kosher.length > 0 ? (
          <Accordion
            style={{ margin: "1rem" }}
            expanded={expanded === "panel4"}
            onChange={()=>handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ minHeight: "3rem", maxHeight: 0 }}
            >
              <TypographyTextColor
                Color="heading"
                style={{ fontWeight: 600 }}
                component="h6"
              >
                Kosher
              </TypographyTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <ul className="filter-panel-swipable">
                  {kosher.map((ele, i) => {
                    return (
                      <li key={i}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  "checkboxChecked" in ele
                                    ? ele.checkboxChecked
                                    : false
                                }
                                onChange={() =>
                                  props.handleChangeCheckboxForKosher(
                                    ele.kosher.trim()
                                  )
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <TypographyTextColor
                                  Color="other"
                                  className="d-flex"
                                  component="span"
                                >
                                  {capitalize(ele.kosher)}&nbsp;
                                  <TypographyFontColor border={0}>
                                    {" "}
                                    {ele.item_count}
                                  </TypographyFontColor>
                                </TypographyTextColor>
                            }
                          />

                          {/* <div className="count">
                                       {row.item_count}
                                     </div> */}
                        </FormControl>
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
      </Box>
      <Box
        style={{
          justifyContent: "end",
          padding: "1rem",
          display: "flex",
          gap: "10px",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={toggleDrawer(false)}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDrawer(false)}
        >
          View
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <RefineButton onClick={toggleDrawer(true)} className="refine-btn">
          <TuneIcon />
         {totalFilter>0&& <TypographyBgColor component={"div"} className="count_rount" style={{right:'0'}}>
         {totalFilter}
                </TypographyBgColor>}
        </RefineButton>
        <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
