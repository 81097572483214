import React, { Component, createElement } from "react";
import { Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  commonPageTitel,
  getSchedule_zip,
} from "../../../utils/renderUtils/renderUtil";
import { GuestLoginAthentication } from "../../../actions/home/homeAction";
import ApiUrls from "../../../utils/apiUtils/apiUrl";
import * as Icons from "react-icons/fa";
import {
  ScrollColorTypography,
  TypographyHoverSecondaryBgColor,
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographyTextColor,
} from "../UiDesign";
import DefaultImg from "../../../assets/images/grocery-store.png";
import SideCateogorySkeleton from "../Skeleton/SideCateogorySkeleton";

class CategoryGrower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
      subMenuOpen: false,
      SubCategoryDetails: [],
      CategoryList: "",
      expanded: [],
      treeIcon: <ChevronRightIcon />,
      selected: [],
      loading: false,
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      category_id: "",
      sub_category_id: "",
      node_id: "",
      parent_id: "",
      nodeLoading: false,
      cat_ind_list: [],
      CategoryDetails: [],
      Props_value: this.props.Props_value,
      treeViewdata: this.props.TreeViewIteams,
      parent_onlyid:
        this.props.Props_value &&
        this.props.Props_value.location &&
        this.props.Props_value.location.state &&
        this.props.Props_value.location.state.parent_onlyid
          ? this.props.Props_value.location.state.parent_onlyid
          : [],
      setchilditem: false,
      currentCateogory: [],
      isSelected: false,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      store_Data: JSON.parse(localStorage.getItem("Store_address")),
    };
  }
  // componentWillReceiveProps(nextProps) {
  //   if (this.state.web_customize === null) {
  //     this.setState({
  //       web_customize: JSON.parse(localStorage.getItem("web_customize")),
  //       store_Data: JSON.parse(localStorage.getItem("Store_address")),
  //     });
  //   }
  // }
  bindCategorygroupdata = (data) => {
    let category_id =
      this.props.history?.location?.state?.category_id === undefined
        ? this.props.history?.location?.pathname?.split("/")[2]
        : this.props.history?.location?.state?.category_id;

    let parentdata;
    let newdata = [];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === category_id) {
        parentdata = data[i];
        newdata = [data[i]];
      }
      if (data[i].has_child === true && data[i]?.childItem?.length > 0) {
        for (let k = 0; k < data[i].childItem.length; k++) {
          if (data[i].childItem[k].id === +category_id) {
            parentdata = structuredClone(data[i]);

            newdata = []; // this.bindnewgorupeddata(parentdata.childItem, k);

            newdata.push(data[i]);
          }
        }
      }
    }

    this.setState({
      viewGroupcategory: newdata,
    });
  };
  FetchCategoryTreeViewForHome = () => {
    let formValues = getSchedule_zip();
    // CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
    let urlcategoryid = "";

    let TreeViewIteamsHome = [];
    //   if (res.data && res.data.result) {
    TreeViewIteamsHome = this.props.TreeViewIteams;
    if (this?.props?.history?.location?.pathname) {
      urlcategoryid = this.props.history.location.pathname.split("/")[2];
    }
    if (TreeViewIteamsHome?.length > 0) {
      var tree_data = this.onbuildForTreeData(TreeViewIteamsHome, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });
      this.bindCategorygroupdata(tree_data);
      let groupdata;
      for (let i = 0; i < tree_data.length; i++) {
        tree_data[i].isSelectedcat = false;
        if (tree_data[i].id === +this.state.parent_onlyid[0]) {
          if (tree_data[i].id === urlcategoryid) {
            groupdata = tree_data[i];
          }
        }
      }
      this.setState({
        TreeViewIteamsHome: TreeViewIteamsHome,
        CategoryListLoader: false,
        CategoryDetails: tree_data,
        currentCateogory: groupdata,
      });
    }
    // } else {
    //   const refreshToken = JSON.parse(localStorage.getItem("access_token"));
    //   console.log(refreshToken, "hitt");
    //   let myThis = this;
    //   if (refreshToken) {
    //     RefreshApiToken(
    //       refreshToken.refresh_token,
    //       myThis,
    //       function (myThis) {
    //         myThis.FetchCategoryTreeViewForHome();
    //       }
    //     );
    //   }
    // }
    // });
  };
  HitGuestApiToken = () => {
    let formValues = {};
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      
      if (res.data) {
        localStorage.setItem("access_token", JSON.stringify(res.data));
        logo = res.data && res.data.logo ? res.data.logo : "";

        this.setState({ guest_login: res.data ? true : "", logo: logo });

        this.FetchCategoryTreeViewForHome();
      }
    });
  };
  componentDidMount() {
    let expanded = [];
    let selected = [];
    let category_id = "";
    const accessToken = localStorage.getItem("access_token");
    if (accessToken === "undefined" || accessToken === null) {
      this.HitGuestApiToken();
    } else this.FetchCategoryTreeViewForHome();
    if (
      this.state.Props_value &&
      this.state.Props_value.location &&
      this.state.Props_value.location.state &&
      this.state.Props_value.location.state.parent_onlyid
    ) {
      selected = this.state.Props_value.location.state.parent_onlyid;
      expanded = this.state.Props_value.location.state.parent_onlyid;
      category_id = this.state.Props_value.location.state.category_id;
    } else if (this.state.parent_onlyid) {
      selected = this.state.parent_onlyid;
      expanded = this.state.parent_onlyid;
    }
  }

  componentWillReceiveProps(nextProps) {
    let expanded = [];
    let selected = [];
    let category_id = "";
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
        store_Data: JSON.parse(localStorage.getItem("Store_address")),
      });
    }
    if (this.props.Props_value !== nextProps.Props_value) {
      if (
        nextProps.Props_value &&
        nextProps.Props_value.location &&
        nextProps.Props_value.location.state &&
        nextProps.Props_value.location.state.parent_onlyid
      ) {
        selected = nextProps.Props_value.location.state.parent_onlyid;
        expanded = nextProps.Props_value.location.state.parent_onlyid;
        category_id = nextProps.Props_value.location.state.category_id;
      }

      var tree_data = this.onbuildForTreeData(this.state.treeViewdata, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });

      this.setState({
        CategoryDetails: tree_data,
        expanded: expanded,
        selected: selected,
        category_id: category_id,
      });
      this.FetchCategoryTreeViewForHome();
    }

    if (this.props.TreeViewIteams !== nextProps.TreeViewIteams) {
      var tree_data = this.onbuildForTreeData(nextProps.TreeViewIteams, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });

      this.setState({
        CategoryDetails: tree_data,
        expanded: expanded,
        selected: selected,
        category_id: category_id,
      });
      this.FetchCategoryTreeViewForHome();
    }
  }

  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }

  handleactiveitem = (data, type, has_child) => {
    let CategoryDetails = this.state.CategoryDetails;
    type = type === undefined ? false : type;
    if (has_child === true)
      CategoryDetails.map((val) => {
        if (val.id === data.id) val.isSelectedcat = !type;
        else val.isSelectedcat = false;
      });

    data.has_child === true
      ? this.props.openViewfullcategory(true, data)
      : this.props.openViewfullcategory(false, data);
    this.setState({ isSelected: !type, CategoryDetails });
  };
  handleClearActiveclass = () => {
    let CategoryDetails = this.state.CategoryDetails;

    CategoryDetails.map((val) => {
      val.isSelectedcat = false;
    });
    this.setState({ isSelected: false, CategoryDetails });
  };
  render() {
    const activeitem = {
      color: "white",
      fontWeight: 800,
    };
    const { CategoryDetails } = this.props;
    const { web_customize, store_Data } = this.state;
    return (
      <>
        <div
          className={
            window.screen.availWidth <= 900
              ? ""
              : this.props.pageName === "home_page"
              ? "home product_details"
              : "product_details"
          }
        >
          {/* <h3>Product Category</h3> */}
        </div>
        <div className="list_cat_wrapper">
        
          <ScrollColorTypography
            component={"div"}
            className="left-menu-inner"
            style={{
              padding: 0,
              height:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== ""
                  ? window.screen.availWidth < 1600
                    ? "80vh"
                    : "83vh"
                  : "",
            }}
          >
            {CategoryDetails.map((ele, i) => {
              return (
                <Typography
                  component={
                    ele?.isSelectedcat
                      ? TypographySecondaryBgColorDimmed
                      : TypographyHoverSecondaryBgColor
                  }
                  dimmedRatio={50}
                  border={0}
                  key={i}
                  id={ele.id}
                  className="newCategory-item"
                  style={ele?.isSelectedcat ? activeitem : {}}
                  onMouseEnter={() => {
                   
                    if (this.state.isSelected === false) {
                      ele.has_child === true
                        ? this.props.openViewfullcategory(true, ele)
                        : this.props.openViewfullcategory(false, ele);
                    }
                  }}
                  onClick={() => {
                    this.handleactiveitem(
                      ele,
                      ele.isSelectedcat,
                      ele.has_child
                    );
                    if (!ele.has_child)
                      this.props.FetchProduct(
                        ele.id,
                        ele,
                        ele.category_message
                      );
                  }}
                >
                  <TypographyTextColor
                    Color="category"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {ele.icon_image === null || ele.icon_image === "" ? (
                      store_Data?.is_show_category_icon === true ? (
                        <img
                          src={DefaultImg}
                          alt="default"
                          style={{
                            width: "30px",
                            height: "30px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div style={{ width: "30px", height: "30px" }}></div>
                      )
                    ) : Icons[ele.icon_image] === undefined ? (
                      <img
                        src={ele.icon_image[0]==="/"||ele.icon_image?.startsWith("upload")?ApiUrls.BASH_URL + "/media/" + ele.icon_image:ele.icon_image}
                        alt="image1"
                        style={{
                          width: "30px",
                          height: "30px",
                          mixBlendMode: "multiply",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      createElement(Icons[ele.icon_image], {
                        style: { fontSize: "1.8rem" },
                      })
                    )}

                   <span > {ele.name}</span>
                  </TypographyTextColor>
                  {ele.has_child === true ? (
                    <TypographyTextColor component={"span"} Color="category">
                      <ChevronRightIcon />
                    </TypographyTextColor>
                  ) : (
                    <></>
                  )}
                </Typography>
              );
            })}
          </ScrollColorTypography>
        </div>
      </>
    );
  }
}

export default CategoryGrower;
