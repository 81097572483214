import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
class Commondialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseOpnDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.statusDialogmsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleCloseOpnDialog} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => this.props.agreeFunctionlity("agree")}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.props.openDeleteDialog}
          onClose={this.props.handleCloseOpnDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.deleteDialogmsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleCloseOpnDeleteDialog}
              color="primary"
            >
              Disagree
            </Button>
            <Button
              onClick={() => this.props.agreeDeleteFunctionlity("agree")}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

      </>
    );
  }
}

export default Commondialog;
