import { SubmissionError } from "redux-form";
import {
  LoginApplication,
  RefreshLogin,
  AutoLoginGuest,
  GenerateOTPSalesrep,
  SalesRepLoginApplication,
} from "../../actions/login/loginAction";
import {
  CreateNewAccount,
  GetSettingsData,
} from "../../actions/profile/profileAction";
import { SubscribeaddApi } from "../../actions/home/homeAction";
const FetchSettingData = () => {
  let formValues = {};
  GetSettingsData(formValues, "").then((res) => {
    let settingData = [];
    if (res?.errors?.status === 401) {
      const refreshToken = JSON.parse(localStorage.getItem("access_token"));
      let myThis = this;
      if (refreshToken) {
        RefreshLogin(refreshToken.refresh_token, myThis, function (myThis) {
          FetchSettingData();
        });
      }
    } else if (res.data && res.data.result) {
      settingData = res.data.result;
    }
    let data = JSON.parse(localStorage.getItem("email"));
    data.emailnotification = settingData.email_notification;
    localStorage.setItem("email", JSON.stringify(data));
  });
};

const createaccountSubmit = (e, values) => {};
const loginPageSubmit = (e, values, dispatch, props) => {
  console.log(e, values);
  if (e.props?.location?.pathname === "/loginsalesrep") {
    if (e.state.isotpSection && (e.state.generateOtp.join("")!="")) {
      values["otp"] = e.state.generateOtp.join("");
      return SalesRepLoginApplication(values, dispatch).then((response) => {
        console.log(response);
        if (response.status === 200) {
          e.createSuccessMsg("success", response.data.message);
          localStorage.setItem('listOpen',false)
          localStorage.removeItem("SalesRepUserList")
          e.redirecttoUrl("/");
        } else if (response.status === 401 && "code" in response) {
          const refreshtoken = response.data.refresh_token;
          if (response.code === "token_not_valid") {
            return RefreshLogin(refreshtoken).then((res) => {});
          }
        } 
        else {          e.createSuccessMsg("error","OTP is invalid");
          throw new SubmissionError(response.errors);
        }
      });
    } else {
      return GenerateOTPSalesrep(values, dispatch).then((response) => {
        console.log(response);
        if (response.status === 200) {
          e.createSuccessMsg("success", response.data.message);
          e.openOTPSection();
        } else if (response.status === 401 && "code" in response) {
          const refreshtoken = response.data.refresh_token;
          if (response.code === "token_not_valid") {
            return RefreshLogin(refreshtoken).then((res) => {});
          }
        } else {
          e.createSuccessMsg("error", "Login failed, Invalid user");
          throw new SubmissionError(response.errors);
        }
      })
    }
  } else {
    if (e.state?.isSignup) {
      let mobile_number;
      let alternate_ph;
      console.log(e, values);
      if (typeof values.username === "number") {
        mobile_number = values.username;
      } else {
        mobile_number = values.username.replace(/-/g, "");
      }

      if (typeof values.alternate_phone === "number") {
        alternate_ph = values.alternate_phone;
      } else {
        alternate_ph = values.alternate_phone
          ? values.alternate_phone === ""
            ? 0
            : values.alternate_phone.replace(/-/g, "")
          : 0;
      }

      var formValues = {
        first_name: values.first_name ? values.first_name : "",
        last_name: values.last_name ? values.last_name : "",
        email: values.email_address,
        callingcode: 1,
        phone: mobile_number,
        password: "123456",
        password_confirm: "123456",
        address: values.address ? values.address : "",
        city_name: values.city_name ? values.city_name : "",
        state_name: values.state_name ? values.state_name.value : "",
        zipcode: values.zipcode ? values.zipcode : "",
        images: "",
        terms_condition_privacy: true,
        street_name: values.street_name ? values.street_name : "",
        alternate_phone: alternate_ph,
        is_sales_rep: true,
      };

      if (values.zipcode.replace(/_/g, "").length === 5) {
        return CreateNewAccount(formValues, dispatch)
          .then((res) => {
            console.log(res);
            if (res.errors) {
              if (res.errors.data) {
                e.createSuccessMsg("error", res.errors.data.errors);
              } else {
                throw new SubmissionError(res.errors.data.errors);
              }
            } else {
              values.username = values.username?.replace(/-/g, "");
              LoginApplication(values, dispatch).then((response) => {
                if (response.status === 200) {
                  FetchSettingData();
                  // SubscribeaddApi({ store_id: 0, email: values.username });
                  e.redirectToHomepage();
                  e.createSuccessMsg("success", "Login Success");
                  localStorage.removeItem("Guest_userid");
                  if (values.remember_me) {
                    localStorage.setItem("RememberMe", values.remember_me);
                  } else {
                    localStorage.removeItem("RememberMe");
                    localStorage.removeItem("DeviceId");
                  }
                  if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
                   
                    let userList = localStorage.getItem("SalesRepUserList");
                    let userData={
                      name:response.data.name,
                      email:response.data.email,
                      access_token:response.data
                    }
                    if (userList === null) {
                      userList = [];
                      userList.push({
                        email: userData?.access_token?.email,
                        data: userData
                      });
                    } else {
                      userList = JSON.parse(userList);
                      userList.push({
                        email: userData?.access_token?.email,
                        data: userData
                      });
                    }
                    localStorage.setItem("SalesRepUserList", JSON.stringify(userList));
                    // e.handleDialogueBox();
                  }
                } else if (response.status === 401 && "code" in response) {
                  const refreshtoken = response.data.refresh_token;
                  if (response.code === "token_not_valid") {
                    return RefreshLogin(refreshtoken).then((res) => {});
                  }
                } else {
                  
                    e.createSuccessMsg(
                      "error",
                      "Login failed, Invalid username or password"
                    );
                  throw new SubmissionError(response.errors);
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        e.createSuccessMsg("error", "Please enter valid zipcode");
      }
    } else if (e.state.guestLogin) {
      let mobile_number;
      if (typeof values.phone === "number") {
        mobile_number = values.phone;
      } else {
        mobile_number = values.phone.replace(/-/g, "");
      }
      var formValues = {
        email: values.email,
        phone: mobile_number,
        last_name: values.last_name,
        first_name: values.first_name,
        callingcode: 1,
        store_id: 0,
      };
      return AutoLoginGuest(formValues, dispatch).then((response) => {
        if (response.status === 200) {
          localStorage.setItem("Guest_userid", response.data.guest_user_id);
          localStorage.setItem(
            "email",
            JSON.stringify({ email: values.email })
          );
          localStorage.setItem(
            "name",
            JSON.stringify({ name: values.first_name + " " + values.last_name })
          );
          // localStorage.setItem("isLogin", true);
          // localStorage.removeItem("SelectedAddress");
          e.props.CloseLoginDialog("close", true);
          e.createSuccessMsg("success", "Login Success");
        } else if (response.status === 401 && "code" in response) {
          const refreshtoken = response.data.refresh_token;
          if (response.code === "token_not_valid") {
            return RefreshLogin(refreshtoken).then((res) => {});
          }
        } else {
          e.createSuccessMsg(
            "error",
            "Login failed, Invalid username or password"
          );
          throw new SubmissionError(response.errors);
        }
      });
    } else {
      if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
        values.username = values.username?.replace(/-/g, "");
      }
      return LoginApplication(values, dispatch).then((response) => {
        if (response.status === 200) {
          FetchSettingData();
          // SubscribeaddApi({ store_id: 0, email: values.username });
          e.redirectToHomepage();
          e.createSuccessMsg("success", "Login Success");
          localStorage.removeItem("Guest_userid");
          if (values.remember_me) {
            localStorage.setItem("RememberMe", values.remember_me);
          } else {
            localStorage.removeItem("RememberMe");
            localStorage.removeItem("DeviceId");
          }
          if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
                   
            let userList = localStorage.getItem("SalesRepUserList");
            let userData={
              name:response.data.name,
              email:response.data.email,
              access_token:response.data
            }
            if (userList === null) {
              userList = [];
              userList.push({
                email: userData?.access_token?.email,
                data: userData
              });
            } else {
              userList = JSON.parse(userList);
              userList.push({
                email: userData?.access_token?.email,
                data: userData
              });
            }
            localStorage.setItem("SalesRepUserList", JSON.stringify(userList));
            // e.handleDialogueBox();
          }
        } else if (response.status === 401 && "code" in response) {
          const refreshtoken = response.data.refresh_token;
          if (response.code === "token_not_valid") {
            return RefreshLogin(refreshtoken).then((res) => {});
          }
        } else {
          if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
            e.handleDialogueBox();
          } else
            e.createSuccessMsg(
              "error",
              "Login failed, Invalid username or password"
            );
          throw new SubmissionError(response.errors);
        }
      });
    }
  }
};

export default loginPageSubmit;
