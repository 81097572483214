import React from "react";
import {
  SearchButton,
  TypographyBorder,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../../UiDesign";
import ToggleLoginMenu from "../../ToggleLoginMenu";
import { ClickAwayListener, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ApiUrls from "../../../../utils/apiUtils/apiUrl";
import LOGO_IMAGE from "../../../../assets/images/logo.png";
import {
  addDefaultImageSrc,
  commonPageTitel,
  logout,
} from "../../../../utils/renderUtils/renderUtil";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchInputBox from "../SearchInputBox";
import { AiOutlineFieldTime } from "react-icons/ai";
import SearchPreviewMob from "./SearchPreviewMob";
import { SpeedDial } from "@mui/lab";
import { FaPlus } from "react-icons/fa";
import SearchableSelect from "../SearchableSelect";
const MobileHeader = (props) => {
  const {
    SearchByCategory,
    productDetails,
    cartLoader,
    CartCount,
    toggleMenu,
    history,
    logo,
    pageName,
    searchClose,
    searchTextValue,
    openSearch,
    top_menu,
    activeClass,
    weekendactiveclass,
    covid19activeClass,
    newItemactiveclass,
    slotName,
    redirectToProfile,
    redirectToLogin,
    rediectToSamePageForspacial,
    OpenDialogPdfPopup,
    toggleMenufunc,
    openDeliveryPickup,
    productSearchLoaer,
    chack_header_is_case,
    accesstoken,
    handleBlurSearchbox,
    handleFocusSearchbox,
    enterPressed,
    searchText,
    openAddCount,
    handleCloseMobileSearchPanel,
    extraCharges,
  } = props;
  return (
    <>
      {" "}
      {pageName !== "category_page" ? (
        <TypographyPrimaryBgColorDimmed
          component="div"
          dimmedRatio={100}
          className="header-menu"
          // onMouseEnter={() => this.closeViewfullcategory()}
        >
          <div className="toggleMenu" id="no-print">
            {toggleMenu && (
              <div className="leftmenu-main hide">
                <div className={`leftmenu ${toggleMenu ? "openmenu" : ""} `}>
                  <ToggleLoginMenu
                    redirectToProfile={() => redirectToProfile()}
                    redirectToLogin={() => redirectToLogin()}
                    top_menu={top_menu}
                    pageName={pageName}
                    activeClass={activeClass}
                    weekendactiveclass={weekendactiveclass}
                    covid19activeClass={covid19activeClass}
                    newItemactiveclass={newItemactiveclass}
                    ActiveCls={(row) => activeClass(row)}
                    rediectToSamePageForspacial={(row, pageName) =>
                      rediectToSamePageForspacial(row, pageName)
                    }
                    slotName={slotName}
                    OpenDialogPdfPopup={() => OpenDialogPdfPopup()}
                    history={history}
                  />
                </div>
                <div
                  className="leftmenu_bgblack"
                  onClick={() => toggleMenufunc()}
                ></div>
              </div>
            )}
            <div
              className="line"
              onClick={() => toggleMenufunc("loginMenu")}
            ></div>
          </div>
          <div className="main-head">
            <Link
              to="/"
              onClick={() => {
                history.push({ pathname: "/" });
                window.location.reload();
              }}
              className="logo"
            >
              <img
                src={
                  logo
                    ? logo[0] === "/"
                      ? ApiUrls.BASH_URL + "/media/" + logo
                      : logo
                    : LOGO_IMAGE
                }
                onError={(e) => addDefaultImageSrc(e)}
                alt="logo"
                style={{ mixBlendMode: "multiply" }}
              />
            </Link>
          </div>
          {localStorage.getItem("frontend_animation") &&
          localStorage.getItem("frontend_animation") !== "no_delivery_pickup" &&
          pageName !== "category_page" &&
          (localStorage.getItem("isLogin") === "true" ||
            localStorage.getItem("Guest_userid") !== null) ? (
            <div className="rp-slot">
              <div className="popup_delivery_btn">
                <button onClick={() => openDeliveryPickup()} className="btn">
                  {JSON.parse(localStorage.getItem("slot"))?.schedule === 2 ? (
                    <>
                      <span style={{ color: "rgb(61, 155, 123)" }}>
                        {localStorage.getItem("SelectedAddress") !== "undefined"
                          ? JSON.parse(localStorage.getItem("SelectedAddress"))
                              ?.name
                          : ""}
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <span>
                    <AiOutlineFieldTime
                      style={{ color: "red", fontSize: "1.5rem" }}
                    />
                  </span>{" "}
                  <TypographyTextColor component={"span"} Color="other">
                    {slotName}
                  </TypographyTextColor>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </TypographyPrimaryBgColorDimmed>
      ) : (
        <></>
      )}
      <div className="filter" id="no-print">
        <ClickAwayListener onClickAway={searchClose}>
          <div
            className="category search-panel bg-white"
            id="search_section"
            style={{ position: "sticky" }}
          >
            {pageName === "category_page" ? (
              <ChevronRightIcon
                onClick={() => {
                  history.go(-1);
                }}
                style={{
                  transform: " rotate(180deg)",
                  fontSize: "2rem",
                }}
              />
            ) : (
              <></>
            )}
            <div className="searchform">
              <Typography
                component={TypographyBorder}
                className="form-group inputBox d-flex"
              >
                <SearchInputBox
                  searchTextValue={searchTextValue}
                  SearchByCategory={SearchByCategory}
                  commonPageTitel={(accesstoken) =>
                    commonPageTitel(accesstoken)
                  }
                  handleBlurSearchbox={(e) => handleBlurSearchbox(e)}
                  handleFocusSearchbox={(e) => handleFocusSearchbox(e)}
                  enterPressed={(e) => enterPressed(e)}
                  searchText={(value) => searchText(value)}
                  accesstoken={accesstoken}
                />

                {searchTextValue !== "" ? (
                  <span
                    onClick={(e) => {
                      handleCloseMobileSearchPanel();
                    }}
                    className="icon-close mr-1"
                  ></span>
                ) : (
                  <></>
                )}

                {openSearch === true && window.screen.availWidth <= 900 ? (
                  <SearchPreviewMob
                    searchResultMsg={props.searchResultMsg}
                    categoryIds={props.categoryIds}
                    searchTextValue={searchTextValue}
                    productSearchLoaer={productSearchLoaer}
                    ProductCaseFunctionlity={(product_id, is_case, data) =>
                      props.ProductCaseFunctionlity(product_id, is_case, data)
                    }
                    productDetails={productDetails}
                    chack_header_is_case={chack_header_is_case}
                    reDirectToDetailsPage={(
                      id,
                      name,
                      slug,
                      brand_id,
                      category_id,
                      type
                    ) =>
                      props.reDirectToDetailsPage(
                        id,
                        name,
                        slug,
                        brand_id,
                        category_id,
                        type
                      )
                    }
                    openAddCount={(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      type
                    ) =>
                      openAddCount(
                        id,
                        added_quantity,
                        added_quantity_is_case,
                        type
                      )
                    }
                    extraCharges={extraCharges}
                    searchProductByName={(val) =>
                      props.searchProductByName(val)
                    }
                  />
                ) : (
                  ""
                )}
              </Typography>

              <SearchButton
                onClick={() => props.searchProductByName()}
                className="search-submit"
                variant="contained"
              >
                <span
                  class="icon-search label-icon"
                  style={{ fontSize: "20px" }}
                ></span>
              </SearchButton>
            </div>
          </div>
        </ClickAwayListener>

        {localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup" ? (
          <div className="popup_delivery_btn desk">
            <button
              onClick={() => this.openDeliveryPickup()}
              className="btn d-flex align-items-end"
            >
              <span>
                <AiOutlineFieldTime
                  className="mr-1"
                  style={{ color: "red", fontSize: "1.5rem" }}
                />
              </span>
              <div>
                {" "}
                {JSON.parse(localStorage.getItem("slot"))?.schedule === 2 ? (
                  <>
                    <TypographySecondaryColor component={"span"}>
                      {localStorage.getItem("SelectedAddress") !== "undefined"
                        ? JSON.parse(localStorage.getItem("SelectedAddress"))
                            ?.name
                        : ""}
                    </TypographySecondaryColor>
                    <br />
                  </>
                ) : (
                  ""
                )}{" "}
                <TypographyTextColor
                  component={"span"}
                  Color="other"
                  style={{ display: "block", width: "max-content" }}
                >
                  {slotName}
                </TypographyTextColor>
              </div>
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {localStorage.getItem("isLogin") !== "true" &&
      localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) !== null ? (
        <div className="m-2">
          <SearchableSelect
            openSearchCustomer={props.openSearchCustomer}
            onhandleSearchContainer={props.onhandleSearchContainer}
            handleLineupCustomer={props.handleLineupCustomer}
            onhandleChangeCustomer={props.onhandleChangeCustomer}
            customerSearchTxt={props.customerSearchTxt}
            customerSearchList={props.customerSearchList}
            redirectToLogin={props.redirectToLogin}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileHeader;
