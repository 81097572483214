import React from "react";
import { Carousel } from "react-responsive-carousel";
import ApiUrls from "../../utils/apiUtils/apiUrl";
const BannerHome = (props) => {
  return (
    <section className="main-banner">
      <div className="banner-car">
        <div className="banner-image">
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            {props.bannerImage1.length > 0
              ? props.bannerImage1.map((row, index) => {
                  return (
                    <div
                      onClick={() =>
                        props.bannerImageLink1.length - 1 >= index &&
                        props.bannerImageLink1[index] !== ""
                          ? window.open(props.bannerImageLink1[index])
                          : ""
                      }
                      className="bannerImgContainer"
                    >
                      <img alt="bannerimage"
                        onError={(e) => props.addDefaultBannerSrc(e)}
                        src={row ?row[0]==="/"? ApiUrls.BASH_URL + "/media" + row:row : ""}
                      />
                    </div>
                  );
                })
              : ""}
          </Carousel>
        </div>
      </div>
    </section>
  );
};
export default BannerHome;
