import React, { Component } from "react";
import {
  Fab,
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  sufixIconInputButton,
  sufixIconInput,
  renderNewCheckbox,
  renderHiddenField,
  renderNumberInputForZipCode,
  renderSelect3Input,
  sufixIconInputForProfile,
  sufixIconInputForaddressMobile,
  sweetAlertBox,
} from "../../utils/renderUtils/renderUtil";
import { required } from "redux-form-validators";
import LOGO_IMAGE from "../../assets/images/logo.png";
import createaccountSubmit from "../createaccount/createaccountSubmit";
import { Redirect } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import { State_Territory } from "../../utils/renderUtils/dbData";
import { AutoLoginApplication } from "../../actions/login/loginAction";
import { SecondaryButton, SecondaryCancelButton, TypographySecondaryColor, TypographyTextColor } from "../common/UiDesign";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showForgetPassword: true,
      feildTypePassword: true,
      feildTypeConfirmPassword: true,
      success: false,
      error: false,
      password: "",
      confirmPassword: "",
      msgPassworderror: false,
      msgPasswordsuccess: false,
      checkboxConformation: false,
      logo: "",
      city: [],
      state: {},
      allstate: [],
      countryList: [],
      TCContent: JSON.parse(localStorage.getItem("Store_address"))
        ?.signup_t_and_c,
      showPopup: false,
      TCopen: false,
    };
  }

  changeFeildTypePassword = () => {
    this.setState({
      feildTypePassword: !this.state.feildTypePassword,
    });
  };

  changeFeildTypeConfirmPassword = () => {
    this.setState({
      feildTypeConfirmPassword: !this.state.feildTypeConfirmPassword,
    });
  };
  handleTCClose = () =>{ this.setState({ TCopen: false });}
  componentDidMount() {
    document.title = "Create account";
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.setState({ logo: logo });
    this.setState({ allstate: State_Territory });
  }

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      // this.errorClick();
      if (msg.email && msg.email[0]) {
        sweetAlertBox('Attention!!',msg.email[0],"info")
      
        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      } else if (msg.password && msg.password[0]) {
        sweetAlertBox('Attention!!',msg.password[0],"info")
      
        // this.setState({
        //   error: msg.password[0],
        //   success: false,
        // });
      } else if (msg.password_confirm && msg.password_confirm[0]) {
        sweetAlertBox('Attention!!',"Confirm Password has at least 6 characters.","info")
      
        // this.setState({
        //   error: "Confirm Password has at least 6 characters.",
        //   success: false,
        // });
      } else if (msg.zipcode && msg.zipcode[0]) {
        sweetAlertBox('Attention!!',msg.zipcode[0],"info")
      
        // this.setState({
        //   error: msg.zipcode[0],
        //   success: false,
        // });
      } else if (msg.state && msg.state[0]) {
        sweetAlertBox('Attention!!',msg.state[0],"info")
      
        // this.setState({
        //   error: msg.state[0],
        //   success: false,
        // });
      } else if (msg.city && msg.city[0]) {
        sweetAlertBox('Attention!!',msg.city[0],"info")
      
        // this.setState({
        //   error: msg.city[0],
        //   success: false,
        // });
      } else if (msg.phone && msg.phone[0]) {
        sweetAlertBox('Attention!!',msg.phone[0].includes("already")
        ? msg.phone[0]
        : "Please enter 10 digit valid Phone no","info")
        // this.setState({
        //   error: msg.phone[0].includes("already")
        //     ? msg.phone[0]
        //     : "Please enter 10 digit valid Phone no",
        //   success: false,
        // });
      } else if (msg.alternate_phone && msg.alternate_phone[0]) {
        sweetAlertBox('Attention!!',"Please enter 10 digit valid Alternate no","info")
      
        // this.setState({
        //   error: "Please enter 10 digit valid Alternate no",
        //   success: false,
        // });
      } else if (msg.first_name && msg.first_name[0]) {
        sweetAlertBox('Attention!!',"Ensure First name should have atleast 2 letters","info")
      
        // this.setState({
        //   error: "Ensure First name should have atleast 2 letters",
        //   success: false,
        // });
      } else {
        sweetAlertBox('Error!!',msg,"error")
      
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  redirectToSuccessPage = (mobile_number, email_address, password) => {
    let storevalue = JSON.parse(localStorage.getItem("Store_address"));

    if (mobile_number !== "") {
      if (storevalue.is_sms_verification === true)
        this.props.history.push({
          pathname: "/verify-number",
          state: {
            mobile: mobile_number,
            email: email_address,
            password: password,
          },
        });
      else {
        if (storevalue.is_user_must_be_activate === false) {
          let formValues = {
            username: email_address,
            password: password,
          };
          AutoLoginApplication(formValues, "").then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                this.props.history.push({
                  pathname: "/",
                });
              }, 500);
            }
          });
        } else {
          this.setState({ showPopup: true });
     
        }
      }
    }
  };

  handlePassword = (event) => {
    if (event.target.value.length > 5 && event.target.value.length < 16) {
      // console.log(event.target.value);
      this.setState({
        msgPassworderror: false,
      });
    } else {
      this.setState({ msgPassworderror: "Password too short" });
    }
    this.setState({ password: event.target.value });
  };

  handleConfirmPassword = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({
        msgPassworderror: "Passwords do not match",
      });
      this.setState({ confirmPassword: event.target.value });
      this.setState({ msgPasswordsuccess: false });
    } else if (event.target.value.length < 6) {
      this.setState({ msgPassworderror: "Password too short" });
    } else {
      this.setState({ msgPassworderror: false });
      this.setState({ msgPasswordsuccess: "Password match" });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  cofirmTermsAndCondition = (event) => {
    console.log("event", event.target.checked);
    this.setState({ checkboxConformation: event.target.checked });
  };

  handleChnageState = (e) => {
    this.setState({
      state: { label: e.value, value: e.value },
    });
  };

  renderText=()=>{
    return(<TypographyTextColor
      component="span"
       style={{fontFamily:JSON.parse(localStorage.getItem('web_customize'))?.TextFontFamily?.other}}>
    
        I agreed to the
        
        <a
          className="d-inline"
          onClick={(e) => {
            console.log('hitt');
            e.stopPropagation();
            e.preventDefault();
            this.setState({
              checkboxConformation: false,
              TCopen: true,
            });
            this.props.change(
              "terms_condition_privacy",
              false
            );
           
          }}
        >
          Terms & Conditions
        </a>{" "}
        and Privacy Policy
      </TypographyTextColor>
    )
  }
  render() {
    const { handleSubmit } = this.props;
    let selector = this;
    const { msgPassworderror, msgPasswordsuccess, success, error } = this.state;
    return (
      <div>
        {localStorage.getItem("isLogin") === "true" ? (
          <Redirect to="/" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div to="/" className="header-pop">
                  <Link to="/">
                    <img
                      src={
                        this.state.logo
                          ? this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                          : LOGO_IMAGE
                      }
                      // src={LOGO_IMAGE}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="login-page">
                  <div className="login-form">
                    {!this.state.showPopup ? (
                      <form
                        onSubmit={handleSubmit(
                          createaccountSubmit.bind(null, this)
                        )}
                        autoComplete="off"
                      >
                        <div className="login-head">
                        <TypographyTextColor component={"h4"} Color="heading">Create Account</TypographyTextColor>
                        <TypographyTextColor
                              component="span"
                              Color="other" className="sub-head user_desc">
                            Tell us a little about yourself to get started
                          </TypographyTextColor>
                        </div>
                        <div className="container-box">
                          <div>
                            <div className="user-input">
                              <Field
                                name="first_name"
                                label="First Name"
                                id="outlined-basic"
                                component={sufixIconInput}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({ message: "First is required." }),
                                ]}
                              />
                            </div>
                          </div>
                          <div >
                            <div className="user-input">
                              <Field
                                name="last_name"
                                label="Last Name"
                                id="outlined-basic"
                                component={sufixIconInput}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({ message: "Last is required." }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="user-input">
                          <Field
                            name="email_address"
                            label="Email Address"
                            id="outlined-basic"
                            component={sufixIconInput}
                            type="text"
                            size="small"
                            rootClass=""
                            required={true}
                            validate={[
                              required({
                                message: "Email Address is required.",
                              }),
                            ]}
                          />
                        </div>
                        <div
                          className="user-input"
                          style={{ marginBottom: "10px" }}
                        >
                          <Field
                            component={sufixIconInputForaddressMobile}
                            name="mobile_no"
                            variant="outlined"
                            label="Mobile Numbers"
                            id="outlined-basic"
                            color="primary"
                            position="start"
                            placeholder="Mobile No."
                            className="fileds"
                            size="small"
                            required={true}
                            validate={[
                              required({
                                message: "Mobile No. is required.",
                              }),
                            ]}
                          />
                        </div>

                        <div
                          className="user-input"
                          style={{ marginBottom: "10px" }}
                        >
                          <Field
                            component={sufixIconInputForaddressMobile}
                            name="alternate_phone"
                            variant="outlined"
                            id="outlined-basic"
                            label="Alternate Mobile No."
                            color="primary"
                            position="start"
                            placeholder="Alternate Mobile No."
                            className="fileds"
                            size="small"
                          />
                        </div>

                        <div className="user-input">
                          <Field
                            name="address"
                            label="Address"
                            id="outlined-basic"
                            component={sufixIconInput}
                            type="text"
                            size="small"
                            rootClass=""
                            required={true}
                            validate={[
                              required({ message: "Address is required." }),
                            ]}
                          />
                        </div>
                        <div
                          className="user-input"
                          style={{ marginBottom: "10px" }}
                        >
                          <Field
                            name="street_name"
                            label="APT/FLOOR"
                            id="outlined-basic"
                            component={sufixIconInputForProfile}
                            type="text"
                            size="small"
                            rootClass=""
                          />
                        </div>
                        <div className="container-box">
                          <div>
                            <div className="user_input">
                              <Field
                                name="city_name"
                                size="small"
                                label="City"
                                placeholder="City"
                                component={sufixIconInput}
                                // initialOptions={this.state.city}
                                // fetchOptions={this.fetchCity}
                                // onChange={this.selectCityCountry.bind(
                                //   this
                                // )}
                                required={true}
                                isClearable={true}
                                validate={[
                                  required({
                                    message: "City is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div style={{minWidth:'30%'}}>
                            <div className="user_input">
                              <Field
                                name="hidden_city_id"
                                component={renderHiddenField}
                                type="hidden"
                              />
                              <Field
                                autoFocus={true}
                                name="state_name"
                                size="small"
                                label="State"
                                component={renderSelect3Input}
                                options={this.state.allstate}
                                onChange={this.handleChnageState}
                                Value={this.state.state}
                                className="outLine"
                                type="text"
                                required={true}
                                validate={[
                                  required({
                                    message: "State is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="user_input">
                              <Field
                                name="zipcode"
                                label="Zipcode"
                                id="outlined-basic"
                                component={renderNumberInputForZipCode}
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Zipcode is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="container-box">
                          <div >
                            <div className="user-input">
                              <Field
                                name="password"
                                label="Password"
                                component={sufixIconInputButton}
                                type={
                                  this.state.feildTypePassword
                                    ? "password"
                                    : "text"
                                }
                                icon={
                                  this.state.feildTypePassword
                                    ? "icon-visibility icn-usr"
                                    : "icon-invisible icn-usr"
                                }
                                changeFeildTypeFunction={
                                  this.changeFeildTypePassword
                                }
                                rootClass=""
                                size="small"
                                onChange={(e) => this.handlePassword(e)}
                                required={true}
                                validate={[
                                  required({
                                    message: "Password is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div >
                            <div className="user-input">
                              <Field
                                name="confirm_password"
                                label="Confirm Password"
                                component={sufixIconInputButton}
                                type={
                                  this.state.feildTypeConfirmPassword
                                    ? "password"
                                    : "text"
                                }
                                icon={
                                  this.state.feildTypeConfirmPassword
                                    ? "icon-visibility icn-usr"
                                    : "icon-invisible icn-usr"
                                }
                                changeFeildTypeFunction={
                                  this.changeFeildTypeConfirmPassword
                                }
                                rootClass=""
                                size="small"
                                required={true}
                                onChange={(e) => this.handleConfirmPassword(e)}
                                validate={[
                                  required({
                                    message: "Confirm Password is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        {msgPassworderror ? (
                          <div className="alert alert-danger mt-3" role="alert">
                            {msgPassworderror}
                          </div>
                        ) : (
                          ""
                        )}

                        {msgPasswordsuccess ? (
                          <div
                            className="alert alert-success mt-3"
                            role="alert"
                          >
                            {msgPasswordsuccess}
                          </div>
                        ) : (
                          ""
                        )}
                        <TypographyTextColor
      component="span"
      Color="other"  className="user-input">
                          <Field
                            name="terms_condition_privacy"
                            id="outlined-basic"
                            component={renderNewCheckbox}
                            type="text"
                            size="small"
                            rootClass=""
                            label={
                            this.renderText()}
                            onChange={(e) => this.cofirmTermsAndCondition(e)}
                          />
                        </TypographyTextColor>
                        <div
                          className="bttn-fill"
                          style={{ textAlign: "-webkit-center" }}
                        >
                          <SecondaryButton
                            className="bttn_grn"
                            variant="extended"
                            type="submit"
                            Fonttype="other"
                            color="primary"
                            disabled={!this.state.checkboxConformation}
                          >
                            Create Account
                          </SecondaryButton>
                          <TypographyTextColor
                              component="p"
                              Color="other" className=" user_desc">
                                Already have a Account? Sign In&nbsp;
                            <TypographySecondaryColor
                              Fonttype="other"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: "/login",
                                });
  
                              }}
                              component={"a"}
                              role="button"
                             style={{textDecoration:"underline"}}
                            >
                              here
                            </TypographySecondaryColor>
                                </TypographyTextColor>
                          
                          
                        </div>
                      </form>
                    ) : (
                      <div className="login-head">
                       <TypographyTextColor component={"h4"} Color="heading">
                          Your account has been under process. We will get back
                          to you soon.
                        </TypographyTextColor>
                        <div className="cart_checkout">
                          <div className="bttn-fill">
                            <Link to="/" className="bttn_grn">
                              Go to Home
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.TCContent !== "" && this.state.TCContent !== null && (
              <Dialog
                open={this.state.TCopen}
                onClose={this.handleTCClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Terms & Conditions
                </DialogTitle>
                <DialogContent dividers={true}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.TCContent,
                      }}
                    ></p>
                  </DialogContentText>
                  <div className="d-flex justify-content-end">
                  <SecondaryButton
                  variant="contained"
                  
                  style={{color:'#fff'}}
                    onClick={() => {
                      this.setState({ checkboxConformation: false });
                      this.props.change("terms_condition_privacy", false);
                      this.handleTCClose();
                    }}
                  >
                    Ok
                  </SecondaryButton>
                  </div>
                </DialogContent>
                {/* <DialogActions> */}
                 
                  {/* <Button onClick={(e) => {this.handleTCClose()
                   this.setState({ checkboxConformation: true });
                   this.props.change("terms_condition_privacy", true);}}>Agree</Button> */}
                {/* </DialogActions> */}
              </Dialog>
            )}
            {success ? (
              <Snackbar
                open={this.state.success}
                onClose={this.handleClose}
                autoHideDuration={6000}
                className="success"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        <h6>Successful Create Account</h6>
                        <p>{success}</p>
                        {/* <p>Login failed. Invalid username or password</p> */}
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
            {error ? (
              <Snackbar
                open={this.state.error}
                onClose={this.handleClose}
                autoHideDuration={6000}
                className="error"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        <p>{error}</p>
                        {/* <p>Login failed. Invalid username or password</p> */}
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "CreateAccount" })(CreateAccount)
);
