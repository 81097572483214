import { Skeleton } from "@mui/material";
import React from "react";

const CommonSkeleton = (props) => {
  const { width, height, className } = props;
  return (
    <Skeleton
      animation="wave"
      width={width}
      height={height}
      className={className}
    />
  );
};

export default CommonSkeleton;
