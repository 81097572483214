import React, { Component } from "react";
import { Fab, Snackbar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  sufixIconInputButton,
  sufixIconInput,
  renderMCheckbox,
  sweetAlertBox,
} from "../../utils/renderUtils/renderUtil";
import { required } from "redux-form-validators";
import LOGO_IMAGE from "../../assets/images/logo.png";
import loginPageSubmit from "../login/loginSubmit";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import {
  SecondaryButton,
  SecondaryCancelButton,
  TypographySecondaryColor,
  TypographyTextColor,
} from "../common/UiDesign";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showForgetPassword: true,
      feildType: true,
      rememberMeValue: false,
      success: false,
      error: false,
      logo: "",
      generateOtp: ["", "", "", "", "", ""],
      isotpSection: false,
      isResendOTP: false,
    };
  }

  errorClick = () => {
    this.setState({
      error: true,
    });
  };

  successClick = () => {
    this.setState({
      success: true,
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      sweetAlertBox('Error!!',msg,"error")
      // this.setState({
      //   error: msg,
      //   success: false,
      // });
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
    this.setState({ isResendOTP: false });
  };

  changeFeildType = () => {
    this.setState({
      feildType: !this.state.feildType,
    });
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  selectRememberCheckbox = (e) => {
    this.setState({ rememberMeValue: e.target.checked });
  };

  componentDidMount() {
    document.title = "Login page";
    let logo = this.state.logo;
    const accessToken = localStorage.getItem("access_token");
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    let backUrlhName = this.props.location.pathname.split("/login/?next=");
    if (this.props?.location?.pathname === "/loginsalesrep") {
      localStorage.removeItem("access_token");
      localStorage.setItem("listOpen", false);
      localStorage.removeItem("email");
      localStorage.setItem("isLogin", false);
      localStorage.removeItem("name");
      localStorage.removeItem("isWebsiteVisited");
      localStorage.removeItem("SalesRepUserList");
    }
    window.addEventListener("popstate", (event) => {
      if (backUrlhName) {
        this.redirecttoUrl(backUrlhName[1]);
      }
    });
    this.setState({ logo: logo });
  }

  redirecttoUrl = (url) => {
    this.props.history.push({
      pathname: url,
    });
  };

  redirectToHomepage = () => {
    let redirect_url;

    const getPathname = this.props.location.pathname.split("/login/?next=");
    const getOrderPathname =
      this.props.location.pathname.split("/login/?order=");
    if (getPathname && getPathname[1]) {
      redirect_url = getPathname[1];
    } else if (getOrderPathname && getOrderPathname[1]) {
      redirect_url = "/delivery-address";
    } else {
      redirect_url = "/";
    }
    setTimeout(() => {
      this.redirecttoUrl(redirect_url);
    }, 500);
  };

  rediretToSingup = () => {
    this.props.history.push({
      pathname: "/create-account",
    });
  };

  openOTPSection = () => this.setState({ isotpSection: true });
  handleChange = (e, index, field) => {
    e.preventDefault();
    console.log(e, index);
    const { maxLength, value, name } = e.target;
    let generateOtp = this.state.generateOtp;
    generateOtp[index] = e.target.value;

    const [fieldName, fieldIndex] = name.split("_");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    // var Inputresult = generateOtp.join("");
    // if (Inputresult.length === 6) {
    //   this.props.handleSubmit(Inputresult);
    // }

    this.setState({ generateOtp });
  };

  handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    if (pasteData.match(/^[0-9]{6}$/)) {
      const newOtp = pasteData.split('');
      this.setState({ generateOtp: newOtp }, () => {
        // Focus on the last input field after updating the state
        this[`inputRef_${5}`].focus();
      });
    }
    e.preventDefault();
  };
  render() {
    const { handleSubmit } = this.props;
    const { success, error } = this.state;
    return (
      <div>
        {this.props?.user === "true" &&
        this.props?.location?.pathname !== "/loginsalesrep" ? (
          <Redirect to="/" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div className="header-pop">
                  <Link to="/">
                    <img
                      src={
                        this.state.logo
                          ? this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                          : LOGO_IMAGE
                      }
                      // src={LOGO_IMAGE}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="login-page" style={{minWidth:'450px'}}>
                  <div className="login-form">
                    <form
                      onSubmit={handleSubmit(loginPageSubmit.bind(null, this))}
                    >
                      <div className="login-head">
                        <TypographyTextColor component={"h4"} Color="heading">
                          Sign In
                        </TypographyTextColor>
                        <TypographyTextColor
                          component="span"
                          Color="other"
                          className="sub-head user_desc"
                        >
                          Welcome! Please login to your account
                        </TypographyTextColor>
                      </div>
                      <div className="user-input">
                        <Field
                          name="username"
                          label={`Email Address${
                            this.props.history.location.pathname === "/login"
                              ? "/Mobile No."
                              : ""
                          }`}
                          id="outlined-basic"
                          component={sufixIconInput}
                          type="text"
                          size="small"
                          rootClass=""
                          autoComplete={false}
                          required={true}
                          validate={[
                            required({
                              message: "Email/Username is required.",
                            }),
                          ]}
                        />
                      </div>
                      {this.props.history.location.pathname === "/login" ? (
                        <>
                          <div className="user-input">
                            <Field
                              name="password"
                              label="Password"
                              component={sufixIconInputButton}
                              type={this.state.feildType ? "password" : "text"}
                              icon={
                                this.state.feildType
                                  ? "icon-visibility icn-usr"
                                  : "icon-invisible icn-usr"
                              }
                              changeFeildTypeFunction={this.changeFeildType}
                              rootClass=""
                              size="small"
                              required={true}
                              autoComplete={false}
                              validate={[
                                required({ message: "Password is required." }),
                              ]}
                            />
                          </div>
                          <TypographyTextColor
                            component="span"
                            Color="other"
                            className="user-input d-flex justify-content-between"
                          >
                            <Link to="/forgot-password">Forgot Password?</Link>{" "}
                          </TypographyTextColor>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.props.history.location.pathname === "/login" ||
                      this.state.isotpSection === false ? (
                        <></>
                      ) : (
                        <form autoComplete="off">
                          <div className="user-input verify-mobile mt-4">
                            {[...Array(6)].map((x, i) => (
                              <input
                                type="text"
                                value={this.state.generateOtp[i]}
                                name={`otp_${i + 1}`}
                                className="verify-otp"
                                maxLength={1}
                                ref={(input) => (this[`inputRef_${i}`] = input)} // Assign ref directly
                                onChange={(e) => {
                                  this.handleChange(e, i, `otp_${i + 1}`);
                                }}
                                autoFocus={i === 0 ? true : false}
                                onKeyPress={(event) => {
                                  if (
                                    !/[0-9]/.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  } else if (i < 5 && /[0-9]/.test(event.key)) {
                                    // Move focus to the next input if a number key is pressed (excluding the last input)
                                    this[`inputRef_${i + 1}`].focus();
                                  }
                                }}
                                onKeyUp={(event) => {
                                  if (
                                    !/[0-9]/.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  } else if (
                                    event.key === "Backspace" &&
                                    i > 0
                                  ) {
                                    // Allow backspace only if it's not the first input
                                    this[`inputRef_${i - 1}`].focus();
                                  }
                                }}
                                onPaste={this.handlePaste}
                              />
                            ))}
                          </div>
                          <div className="bttn-fill">
                            {this.state.countdown > 0 ? (
                              <p className="login-txt">
                                Resend OTP in &nbsp;{" "}
                                <span>{` 00 : ${Number(
                                  this.state.countdown
                                )}`}</span>
                              </p>
                            ) : (
                              <TypographyTextColor
                                component="p"
                                Color="other"
                                className="text-right"
                              >
                                <span
                                  onClick={() => {
                                    this.setState({ isResendOTP: true });
                                    document
                                      .getElementById("submitBtn")
                                      .click();
                                  }}
                                  role={"button"}
                                >
                                  Resend OTP
                                </span>
                              </TypographyTextColor>
                            )}
                          </div>
                        </form>
                      )}
                      <div
                        className="bttn-fill"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <SecondaryButton
                          className="bttn_grn"
                          variant="extended"
                          type="submit"
                          color="primary"
                          Fonttype="other"
                          id="submitBtn"
                        >
                          {this.props.history.location.pathname === "/loginsalesrep" &&
                          this.state.isotpSection === false
                            ? "Send OTP"
                            : " Sign In"}
                        </SecondaryButton>
                        {this.props.history.location.pathname === "/login" ? (
                          <>
                           <TypographyTextColor
                            className="user_desc"
                            Color="other"
                            Fonttype="other"
                          >
                            New Customer? Register&nbsp;
                            <TypographySecondaryColor
                              Fonttype="other"
                              onClick={() => this.rediretToSingup()}
                              component={"a"}
                              role="button"
                             style={{textDecoration:"underline"}}
                            >
                              here
                            </TypographySecondaryColor>
                          </TypographyTextColor>
                         
                           
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {success ? (
              <Snackbar
                open={this.state.success}
                onClose={this.handleClose}
                autoHideDuration={6000}
                className="success"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        {/* <h6>Successful Login</h6> */}
                        <p>{success}</p>
                        {/* <p>Login failed. Invalid username or password</p> */}
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
            {error ? (
              <Snackbar
                open={this.state.error}
                onClose={this.handleClose}
                autoHideDuration={6000}
                className="error"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <i className="icon-close"></i>
                    </IconButton>
                  </React.Fragment>
                }
                message={
                  <React.Fragment>
                    <div className="row gutter-8">
                      <div className="col-auto">
                        <i className="icon-closer"></i>
                      </div>
                      <div className="col">
                        <p>{error}</p>
                        {/* <p>Login failed. Invalid username or password</p> */}
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(reduxForm({ form: "Login" })(Login));
