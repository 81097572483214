import React from "react";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DeviceUUID } from "device-uuid";
import {
  IconButton,
  FormControl,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Tooltip,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import Select2 from "react-select";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncCreatable from "react-select/async-creatable";
import Async from "react-select/async";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import CKEditor from "react-ckeditor-component";
import { RefreshLogin } from "../../actions/login/loginAction";
import NO_IMAGE from "../../assets/images/image-coming-soon.jpg";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import VISA from "../../assets/images/visa.png";
import MASTERCARD from "../../assets/images/mastercard.png";
import AMEX from "../../assets/images/amex.png";
import DISCOVER from "../../assets/images/discover.png";
import swal from "sweetalert";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#009688",
      main: "#F57F18",
      dark: "#00897b",
      constantText: "#F57F18",
    },
    secondary: {
      light: "#EB4F8E",
      main: "#E72472",
      dark: "#A1194F",
      contrastText: "#fff",
    },
    error: {
      light: "#FF7663",
      main: "#FF543D",
      dark: "#B23A2A",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: "400",
    fontDisplay: "block",
  },
  tooltip: {
    backgroundColor: "#333",
    color: "#fff",
    fontSize: 11,
  },
});

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} className="OutlinedSelect" />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

export const generateDeviceId = () => {
  var du = new DeviceUUID().parse();
  var dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.pixelDepth,
    du.colorDepth,
    du.resolution,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isChromeOS,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isAndroid,
    du.isSamsung,
    du.isSmartTV,
    du.isRaspberry,
    du.isBlackberry,
    du.pixelDepth,
    du.isTouchScreen,
    du.isOpera,
    du.isIE,
    du.isEdge,
    du.isIECompatibilityMode,
    du.isSafari,
    du.isFirefox,
    du.isWebkit,
    du.isChrome,
    du.isKonqueror,
    du.isOmniWeb,
    du.isSeaMonkey,
    du.isFlock,
    du.isAmaya,
    du.isPhantomJS,
    du.isEpiphany,
    du.source,
  ];
  var uuid = du.hashMD5(dua.join(":"));
  return uuid;
};

const Option = (props) => {
  return (
    <MenuItem
      className="select2-option"
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const components = {
  Option,
  Control,
};
function NumberFieldComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
    />
  );
}
NumberFieldComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function PhoneFieldComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}
PhoneFieldComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function noOptionsMessage() {
  return null;
}
class NumberContainer extends React.Component {
  render() {
    return <div onKeyUp={this.props.inputChange}>{this.props.children}</div>;
  }
}
export const renderNumberInput = ({
  input,
  label,
  meta: { touched, error },
  type,
  required,
  unit,
  minValue,
  maxValue,
  ispositive,
  nodecimalScale,
  inputKeypress,
  onClick,
  size,
  autofocus,
  placeholder,
  colors,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user_main_input">
      <NumberContainer inputChange={inputKeypress}>
        <TextField
          fullWidth
          error={!!(touched && error)}
          label={required ? label + " *" : label}
          placeholder={placeholder ? placeholder : ""}
          type={type}
          variant="outlined"
          size={size}
          color={colors}
          onClick={onClick}
          helperText={touched && error}
          disabled={disabled ? disabled : false}
          {...input}
          value={input.value}
          autoFocus={autofocus}
          InputProps={{
            inputComponent: NumberFieldComponent,
            inputProps: {
              minlength: 5,
              maxLength: 5,
              ispositive: ispositive ? true : false,
              // decimalScale: nodecimalScale ? 0 : 2,
            },
            endAdornment: unit ? (
              <InputAdornment position="end">{unit}</InputAdornment>
            ) : (
              ""
            ),
          }}
        />
      </NumberContainer>
    </div>
  </ThemeProvider>
);
export const sufixIconInput = ({
  input,
  label,
  meta: { touched, error },
  size,
  colors,
  type,
  required,
  icon,
  autoComplete = true,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user-main">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        variant="outlined"
        helperText={touched && error}
        autoComplete={autoComplete}
        {...input}
        value={input.value}
        InputProps={{
          autoComplete: "none",
          endAdornment: <i className={icon}></i>,
        }}
      />
    </div>
  </ThemeProvider>
);

export const sufixIconInputFroMobileNumber = ({
  input,
  label,
  meta: { touched, error },
  size,
  colors,
  type,
  required,
  icon,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user-main">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
        InputProps={{
          autoComplete: "none",
          inputComponent: PhoneFieldComponent,
          endAdornment: <i className={icon}></i>,
        }}
      />
    </div>
  </ThemeProvider>
);

export const sufixIconInputForaddressMobile = ({
  input,
  label,
  meta: { touched, error },
  size,
  colors,
  type,
  required,
  icon,
  placeholder,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user_main_input">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
        disabled={disabled}
        InputProps={{
          autoComplete: "none",
          inputComponent: PhoneFieldComponent,
          endAdornment: <i className={icon}></i>,
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
        }}
      />
    </div>
  </ThemeProvider>
);

export const sufixIconInputButton = ({
  input,
  label,
  meta: { touched, error },
  type,
  required,
  icon,
  changeFeildTypeFunction,
  size,
  colors,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user-main">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
        InputProps={{
          endAdornment: (
            <i className={icon} onClick={changeFeildTypeFunction}></i>
            // <IconButton size={"small"} >

            // </IconButton>
          ),
        }}
      />
    </div>
  </ThemeProvider>
);

export const renderNewCheckbox = ({ input, label }) => (
  <ThemeProvider theme={theme}>
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          color="primary"
          value={input.value}
          checked={input.value}
          onChange={(value) => input.onChange(value)}
        />
      }
    />
  </ThemeProvider>
);

export const renderTextInput = ({
  input,
  label,
  meta: { touched, error },
  type,
  readonly,
  onClick,
  required,
  placeholder,
  onKeyDown,
  autofocus,
  size,
  colors,
  endAdornment,
  disabled,
  variant,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        placeholder={placeholder ? placeholder : ""}
        type={type}
        size={size}
        color={colors}
        variant={variant}
        helperText={touched && error}
        fullWidth
        {...input}
        value={input.value}
        onClick={onClick}
        disabled={disabled ? disabled : false}
        onKeyDown={onKeyDown}
        autoFocus={autofocus}
        InputProps={{
          readOnly: readonly ? true : false,
          endAdornment: endAdornment,
        }}
      />
    </div>
  </ThemeProvider>
);

export const renderSimpleTextInput = ({
  input,
  meta: { touched, error },
  type,
  readonly,
  onClick,
  required,
  placeholder,
  onKeyDown,
  autofocus,
  size,
  colors,
  endAdornment,
  startAdornment,
  disabled,
  variant,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        error={!!(touched && error)}
        placeholder={placeholder ? placeholder : ""}
        type={type}
        size={size}
        color={colors}
        variant={variant}
        helperText={touched && error}
        fullWidth
        {...input}
        value={input.value}
        onClick={onClick}
        disabled={disabled ? disabled : false}
        onKeyDown={onKeyDown}
        autoFocus={autofocus}
        InputProps={{
          readOnly: readonly ? true : false,
          startAdornment: startAdornment,
        }}
      />
    </div>
  </ThemeProvider>
);

export const prefixTextInput = ({
  className,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  readonly,
  required,
  placeholder,
  position,
  prefix,
}) => (
  <div className={className}>
    <TextField
      error={!!(touched && error)}
      label={required ? label + " *" : label}
      placeholder={placeholder ? placeholder : ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      fullWidth
      {...input}
      disabled={disabled ? (disabled === "true" ? true : false) : false}
      value={input.value}
      InputProps={{
        startAdornment: (
          <InputAdornment position={position}>{prefix}</InputAdornment>
        ),
        readOnly: readonly ? true : false,
      }}
    />
  </div>
);

export const renderPhoneValidatorInputRegister = ({
  input,
  label,
  meta: { touched, error },
  color,
  variant,
  position,
  type,
  required,
  erroricon,
  successicon,
  showicon,
  isverified,
  validateFunction,
  size,
  prefix,
  placeholder,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user-main">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        placeholder={placeholder ? placeholder : ""}
        variant={variant}
        color={color}
        helperText={touched && error}
        {...input}
        value={input.value}
        disabled={disabled ? disabled : false}
        InputProps={{
          inputComponent: PhoneFieldComponent,
          startAdornment: (
            <InputAdornment position={position}>{prefix}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {showicon && !isverified ? (
                <Tooltip
                  title="Click to verify"
                  placement="bottom-end"
                  enterDelay={300}
                >
                  <IconButton onClick={validateFunction}>
                    {showicon ? <i className={erroricon}></i> : ""}
                  </IconButton>
                </Tooltip>
              ) : showicon && isverified ? (
                <Tooltip
                  title="Verified"
                  placement="bottom-end"
                  enterDelay={300}
                >
                  <IconButton>
                    {showicon ? <i className={successicon}></i> : ""}
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  </ThemeProvider>
);

export const renderSelect2Input = ({
  input,
  label,
  meta: { touched, error },
  options,
  placeholder,
  required,
  isMulti,
  isClearable,
  defaultValue,
  size,
  colors,
  clsnm,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={
        touched && error
          ? clsnm
            ? "form-group  invalid " + clsnm
            : "form-group  invalid"
          : clsnm
          ? "form-group " + clsnm
          : "form-group "
      }
    >
      <Select2
        options={options}
        components={components}
        error={!!(touched && error)}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        onChange={(value) => input.onChange(value)}
        // onBlur={() => input.onBlur(input.value)}
        // onBlur={(event) => event.preventDefault()}
        blurInputOnSelect={false}
        onFocus={() => input.onFocus()}
        isMulti={isMulti}
        color={colors}
        isDisabled={disabled ? disabled : false}
        size={size}
        placeholder={placeholder}
        selectedValue={defaultValue}
        helperText={touched && error}
        value={input.value}
        fullWidth
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderSelect3Input = ({
  input,
  label,
  meta: { touched, error },
  options,
  placeholder,
  required,
  isMulti,
  isClearable,
  defaultValue,
  size,
  colors,
  clsnm,
  disabled,
  Value,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={
        touched && error
          ? clsnm
            ? "form-group  invalid " + clsnm
            : "form-group  invalid"
          : clsnm
          ? "form-group " + clsnm
          : "form-group "
      }
    >
      <Select2
        options={options}
        components={components}
        error={!!(touched && error)}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        // onBlur={(event) => event.preventDefault()}
        blurInputOnSelect={false}
        onFocus={() => input.onFocus()}
        isMulti={isMulti}
        color={colors}
        isDisabled={disabled ? disabled : false}
        size={size}
        placeholder={placeholder}
        selectedValue={defaultValue}
        helperText={touched && error}
        value={Value}
        fullWidth
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      ></Select2>
      <div className="text-danger">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderSelect2InputForSlot = ({
  input,
  label,
  meta: { touched, error },
  options,
  placeholder,
  required,
  isMulti,
  isClearable,
  defaultValue,
  size,
  colors,
  clsnm,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={
        touched && error
          ? clsnm
            ? "form-group  invalid " + clsnm
            : "form-group  invalid"
          : clsnm
          ? "form-group " + clsnm
          : "form-group "
      }
    >
      <Select2
        options={options}
        components={components}
        error={!!(touched && error)}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        onFocus={() => input.onFocus()}
        isMulti={isMulti}
        color={colors}
        size={size}
        placeholder={placeholder}
        selectedValue={defaultValue}
        helperText={touched && error}
        value={input.value}
        fullWidth
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-danger">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderSelect2InputReqired = ({
  input,
  label,
  meta: { touched, error },
  options,
  placeholder,
  required,
  isMulti,
  isClearable,
  defaultValue,
  size,
  colors,
  clsnm,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={
        touched && error
          ? clsnm
            ? "form-group  invalid " + clsnm
            : "form-group  invalid"
          : clsnm
          ? "form-group " + clsnm
          : "form-group "
      }
    >
      <Select2
        options={options}
        components={components}
        error={!!(touched && error)}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        onFocus={() => input.onFocus()}
        isMulti={isMulti}
        color={colors}
        isDisabled={disabled ? disabled : false}
        size={size}
        placeholder={placeholder}
        selectedValue={defaultValue}
        helperText={touched && error}
        value={input.value}
        fullWidth
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderSelectInput = ({
  input,
  label,
  meta: { touched, error },
  type,
  children,
  required,
  colors,
  size,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        fullWidth
        select
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        color={colors}
        size={size}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
      >
        {children}
      </TextField>
    </div>
  </ThemeProvider>
);

export const renderAsyncSelect2Input = ({
  input,
  label,
  meta: { touched, error },
  placeholder,
  required,
  isMulti,
  defaultValue,
  fetchOptions,
  isClearable,
  initialOptions,
  menuIsOpen,
  isDisabled,
  isOptionDisabled,
  size,
  colors,
  err_msg,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={touched && error ? "form-group invalid " : "form-group"}
    >
      <Async
        error={!!(touched && error)}
        helperText={touched && error}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        components={components}
        loadOptions={fetchOptions}
        defaultOptions={initialOptions ? initialOptions : []}
        placeholder={placeholder}
        color={colors}
        size={size}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        onFocus={() => input.onFocus()}
        selectedValue={defaultValue}
        isDisabled={isDisabled ? isDisabled : false}
        // isOptionDisabled={isOptionDisabled ? isOptionDisabled : null}
        value={input.value}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        menuIsOpen={menuIsOpen}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-danger">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderAsyncCreatableSelect2Input = ({
  input,
  label,
  meta: { touched, error },
  placeholder,
  required,
  isMulti,
  defaultValue,
  fetchOptions,
  initialOptions,
  isClearable,
  createOption,
  menuIsOpen,
  isDisabled,
  size,
  colors,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={touched && error ? "form-group invalid" : "form-group"}
    >
      <AsyncCreatable
        error={!!(touched && error)}
        helperText={touched && error}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        components={components}
        loadOptions={fetchOptions}
        color={colors}
        size={size}
        defaultOptions={initialOptions ? initialOptions : []}
        formatCreateLabel={createOption}
        placeholder={placeholder}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        selectedValue={defaultValue}
        value={input.value}
        isMulti={isMulti}
        isDisabled={isDisabled ? isDisabled : false}
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        menuIsOpen={menuIsOpen}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);

export const renderCreatableSelect2Input = ({
  input,
  label,
  meta: { touched, error },
  placeholder,
  required,
  options,
  isMulti,
  isClearable,
  defaultValue,
  size,
  colors,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup
      className={touched && error ? "form-group invalid" : "form-group"}
    >
      <CreatableSelect
        error={!!(touched && error)}
        helperText={touched && error}
        {...input}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        components={components}
        options={options}
        color={colors}
        size={size}
        placeholder={placeholder}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        selectedValue={defaultValue}
        value={input.value}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable ? isClearable : false}
        textFieldProps={{
          label: label,
          required: required,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </FormGroup>
  </ThemeProvider>
);
export const renderTextAreaInput = ({
  input,
  label,
  meta: { touched, error },
  variant,
  required,
  rows,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        variant={variant}
        multiline
        rows={rows ? rows : 4}
        margin="none"
        helperText={touched && error}
        fullWidth
        {...input}
        value={input.value}
      />
    </div>
  </ThemeProvider>
);

export const renderTextAreaInputForDoctorNotes = ({
  input,
  label,
  meta: { touched, error },
  variant,
  required,
  rows,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        variant={variant}
        multiline
        rows={rows ? rows : 4}
        margin="none"
        className="doctor_notes"
        helperText={touched && error}
        fullWidth
        {...input}
        value={input.value}
      />
    </div>
  </ThemeProvider>
);
export const renderPhoneValidatorInput = ({
  input,
  label,
  meta: { touched, error },
  variant,
  type,
  required,
  erroricon,
  successicon,
  showicon,
  isverified,
  validateFunction,
  size,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        variant={variant}
        helperText={touched && error}
        {...input}
        value={input.value}
        InputProps={{
          inputComponent: PhoneFieldComponent,
          endAdornment: (
            <InputAdornment position="end">
              {showicon && !isverified ? (
                <Tooltip
                  title="Click to verify"
                  placement="bottom-end"
                  enterDelay={300}
                >
                  <IconButton onClick={validateFunction}>
                    {showicon ? <i className={erroricon}></i> : ""}
                  </IconButton>
                </Tooltip>
              ) : showicon && isverified ? (
                <Tooltip
                  title="Verified"
                  placement="bottom-end"
                  enterDelay={300}
                >
                  <IconButton>
                    {showicon ? <i className={successicon}></i> : ""}
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  </ThemeProvider>
);
export const renderHiddenField = ({
  input,
  type,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <input
    className={
      (touched && error) || (touched && warning)
        ? "form-control invalid"
        : "form-control"
    }
    {...input}
    placeholder={placeholder}
    type={type}
    value={input.value}
    autoComplete="off"
  />
);
export const renderMCheckbox = ({
  input,
  // label,
  // type,
  // meta: { touched, error, warning },
  checkText,
  defaultValue,
  checked,
}) => (
  <FormControlLabel
    control={
      <Checkbox
        {...input}
        color="primary"
        checked={checked}
        value={defaultValue}
      />
    }
    label={checkText}
  />
);
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  // var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  var match1 = cleaned.slice(0, 1);
  var match2 = cleaned.slice(1, 2);
  var match3 = cleaned.slice(2, 3);
  var match4 = cleaned.slice(3, 4);
  var match5 = cleaned.slice(4, 5);
  var match6 = cleaned.slice(5, 6);
  var match7 = cleaned.slice(6, 7);
  var match8 = cleaned.slice(7, 8);
  var match9 = cleaned.slice(8, 9);
  var match10 = cleaned.slice(9, 10);

  return (
    "+1 (" +
    match1 +
    match2 +
    match3 +
    ") " +
    match4 +
    match5 +
    match6 +
    "-" +
    match7 +
    match8 +
    match9 +
    match10
  );
};

export const renderSwitchValue = ({
  input,
  // label,
  type,
  meta: { error, warning },
  checkText,
  // checked,
  textPosition,
}) => (
  <span className={error || warning ? "invalid" : ""}>
    <label
      className={
        textPosition && textPosition === "left" ? "switch" : "switch left-side"
      }
    >
      {textPosition && textPosition === "left" ? (
        <span className="slide-text">{checkText}</span>
      ) : (
        ""
      )}
      <input
        type={type}
        {...input}
        checked={input.value}
        className={error || warning ? "form-control invalid" : "form-control"}
      />
      <span className="slide"></span>
      {!textPosition ? <span className="slide-text">{checkText}</span> : ""}
    </label>
    {(error && <div className="invalid-feedback">{error}</div>) ||
      (warning && <div className="invalid-feedback">{warning}</div>)}
  </span>
);

export const renderSwitch = ({
  input,
  // label,
  type,
  meta: { error, warning },
  checkText,
  checked,
  textPosition,
}) => (
  <span className={error || warning ? "invalid" : ""}>
    <label
      className={
        textPosition && textPosition === "left" ? "switch" : "switch left-side"
      }
    >
      {textPosition && textPosition === "left" ? (
        <span className="slide-text">{checkText}</span>
      ) : (
        ""
      )}
      <input
        type={type}
        {...input}
        checked={checked}
        className={error || warning ? "form-control invalid" : "form-control"}
      />
      <span className="slide"></span>
      {!textPosition ? <span className="slide-text">{checkText}</span> : ""}
    </label>
    {(error && <div className="invalid-feedback">{error}</div>) ||
      (warning && <div className="invalid-feedback">{warning}</div>)}
  </span>
);

export const dateTimeFormat = (date) => {
  var d = new Date(date);
  var monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  var day = d.getDate();
  var year = d.getFullYear();
  var monthName = monthNames[d.getMonth()];
  var hours = d.getHours();
  var minutes = d.getMinutes();
  // var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;
  var strTime = hours + ":" + minutes + " " + ampm;
  return monthName + " " + day + ", " + year + "  " + strTime;
};
export const timeFormat = (date) => {
  var d = new Date(date);
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const renderRadioGroup = ({
  input,
  label,
  meta: { touched, error },
  options,
  required,
}) => (
  <ThemeProvider theme={theme}>
    <FormGroup row className="form-group">
      <FormControl
        required={required}
        error={!!(touched && error)}
        component="fieldset"
      >
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          style={{ display: "initial", width: "auto" }}
          {...input}
          value={input.value}
          onChange={(event, value) => input.onChange(value)}
        >
          {options.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio />}
              label={item.label ? item.label : item.value}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>

        {error ? <FormHelperText>{touched && error}</FormHelperText> : ""}
      </FormControl>
    </FormGroup>
  </ThemeProvider>
);
export const sendDateFormat = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  // var hours = d.getHours();
  // var minutes = d.getMinutes();
  // var seconds = d.getSeconds();
  // var strTime = hours + ":" + minutes + ":" + seconds;
  return [month, day, year].join("/");
};
export const postfullTimeFormat = (date) => {
  var d = new Date(date);

  var hours = d.getHours;
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var localTime = d.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  // console.log(d,localTime);
  // hours = hours < 10 ? "0" + hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;
  // var ampm = hours >= 12 ? "PM" : "AM";
  // hours = hours % 12;
  // hours = hours ? hours : 12;
  // var strTime = hours + ":" + minutes + " " + ampm;
  return localTime;
};
export const scheduleTimeFormat = (date, timeType) => {
  var d = new Date(date);
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  hours = hours < 10 ? "0" + hours : hours; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = "00";
  if (
    timeType === "endtime" &&
    hours === "00" &&
    minutes === "00" &&
    seconds === "00"
  ) {
    hours = "23";
    minutes = "59";
    seconds = "59";
  }
  var strTime = hours + ":" + minutes + ":" + seconds;
  return strTime;
};
export const dateFormat = (date) => {
  var d = new Date(date);
  //var now = new Date();
  var monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  //var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  var day = d.getDate();
  var year = d.getFullYear();
  // var dayName =
  //   d.getDate() === now.getDate()
  //     ? "Today"
  //     : d.getDate() === now.getDate() + 1
  //     ? "Tomorrow"
  //     : days[d.getDay()];
  var monthName = monthNames[d.getMonth()];
  return monthName + " " + day + ", " + year;
};
export function Ucfirst(str) {
  //console.log(str)
  str = str.split(" ");
  for (var i = 0, x = str.length; i < x; i++) {
    if (str[i][0]) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    } else {
      str[i] = str[i][0] ? str[i][0] : "" + str[i].substr(1);
    }
  }
  return str.join(" ");
}
export const onlytimeFormat = (value) => {
  var d = value.split(":");
  var hours = d[0];
  var minutes = d[1];
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  //minutes = minutes < 10 ? minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const appointmentDateFormat = (date) => {
  var d = new Date(date);
  //lookup arrays for days of week and months for use in output.
  var daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var daysOfMonth = [
    "NaN",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
  ];
  //Returns a date string for display
  var returnDate =
    daysOfWeek[d.getDay()] +
    ", " +
    monthsOfYear[d.getMonth()] +
    " " +
    daysOfMonth[d.getDate()] +
    ", " +
    d.getFullYear();
  return returnDate;
};
export const sendDayFormat = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};
export const dayCFormat = (date) => {
  var d = new Date(date);
  // var now = new Date();
  var monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  var day = d.getDate();
  var year = d.getFullYear();
  var dayName = days[d.getDay()];
  var monthName = monthNames[d.getMonth()];
  return dayName + ", " + monthName + " " + day + ", " + year;
};
export const daySelect = (date) => {
  var d = new Date(date);
  var days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  var dayName = days[d.getDay()];
  return dayName;
};
export const appointmentStatusColor = (status) => {
  switch (status) {
    case 1:
      return "pending";
    case 2:
      return "queue";
    case 3:
      return "checked-in";
    case 4:
      return "checked-out";
    case 5:
      return "completed";
    case 6:
      return "cancelled";
    default:
      return null;
  }
};
export const timeByHour = (number) => {
  switch (number) {
    case 0:
      return "12:00 AM";
    case 1:
      return "1:00 AM";
    case 2:
      return "2:00 AM";
    case 3:
      return "3:00 AM";
    case 4:
      return "4:00 AM";
    case 5:
      return "5:00 AM";
    case 6:
      return "6:00 AM";
    case 7:
      return "7:00 AM";
    case 8:
      return "8:00 AM";
    case 9:
      return "9:00 AM";
    case 10:
      return "10:00 AM";
    case 11:
      return "11:00 AM";
    case 12:
      return "12:00 PM";
    case 13:
      return "1:00 PM";
    case 14:
      return "2:00 PM";
    case 15:
      return "3:00 PM";
    case 16:
      return "4:00 PM";
    case 17:
      return "5:00 PM";
    case 18:
      return "6:00 PM";
    case 19:
      return "7:00 PM";
    case 20:
      return "8:00 PM";
    case 21:
      return "9:00 PM";
    case 22:
      return "10:00 PM";
    case 23:
      return "11:00 PM";
    default:
      return null;
  }
};
export const slotTimeFormat = (time) => {
  var timearr = time.split(":");
  var hours =
    parseInt(timearr[0]) > 12
      ? parseInt(timearr[0]) - 12
      : parseInt(timearr[0]);
  var am_pm = parseInt(timearr[0]) >= 12 ? "PM" : "AM";
  hours = hours < 10 ? "0" + hours : hours;
  var minutes =
    parseInt(timearr[1]) < 10
      ? "0" + parseInt(timearr[1])
      : parseInt(timearr[1]);
  time = hours + ":" + minutes + " " + am_pm;
  return time;
};

export const renderCustomEditor = ({
  input,
  // meta: {},
  id,
  // label,
  // required,
}) => {
  return (
    <React.Fragment>
      <CKEditor
        placeholder="Enter the Content"
        content={input.value}
        events={{
          change: (event) => input.onChange(event.editor.getData()),
          blur: (event) => input.onChange(event.editor.getData()),
          afterPaste: (event) => input.onChange(event.editor.getData()),
        }}
        scriptUrl="//cdn.gaic.com/cdn/ui-bootstrap/0.58.0/js/lib/ckeditor/ckeditor.js"
        config={{
          toolbarCanCollapse: true,
          toolbarStartupExpanded: true,
          height: "100%",
          width: "100%",
          uiColor: "#ffffff",
          removePlugins: "elementspath, resize",
          fontSize: {
            options: ["tiny", "default", "big"],
          },
          toolbar: [
            {
              name: "basicstyles",
              items: ["Bold", "Italic", "Underline", "Strike"],
            },
            {
              name: "alignment",
              items: [
                "JustifyLeft",
                "JustifyCenter",
                "JustifyRight",
                "JustifyBlock",
              ],
            },
            { name: "colors", items: ["TextColor", "BGColor"] },
            { name: "hyperlink", items: ["Link", "Unlink"] },
            {
              name: "bullet",
              items: [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "RemoveFormat",
              ],
            },
            {
              name: "paragraph",
              items: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
            },
            { name: "links", items: ["links"] },

            // { name: 'insert', items: [ 'Image','Table', 'HorizontalRule', 'Smiley', 'SpecialChar'] }
          ],
          onLoad: function () {
            this.getInputElement().$.classList.add("form-group");
          },
          id: id,
          allowedContent: true,
        }}
      />
      {/* {touched && ((error && <div className="invalid-feedback">{error}</div>) || (warning && <div className="invalid-feedback">{warning}</div>))} */}
    </React.Fragment>
  );
};

export const sufixIconInputForProfile = ({
  input,
  label,
  meta: { touched, error },
  size,
  colors,
  type,
  required,
  autoFocus,
  icon,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user_main_input">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        autoFocus={autoFocus}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
        disabled={disabled ? disabled : false}
        InputProps={{
          endAdornment: <i className={icon}></i>,
        }}
      />
    </div>
  </ThemeProvider>
);

export const sufixIconInputButtonTwo = ({
  input,
  label,
  meta: { touched, error },
  type,
  required,
  icon,
  changeFeildTypeFunction,
  size,
  colors,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user_main_input">
      <TextField
        fullWidth
        error={!!(touched && error)}
        label={required ? label + " *" : label}
        type={type}
        size={size}
        color={colors}
        variant="outlined"
        helperText={touched && error}
        {...input}
        value={input.value}
        InputProps={{
          endAdornment: (
            <IconButton size={"small"} onClick={changeFeildTypeFunction}>
              <i className={icon}></i>
            </IconButton>
          ),
        }}
      />
    </div>
  </ThemeProvider>
);

export const radioButton = ({ input, value, required, ...rest }) => (
  <FormControl component="fieldset">
    <RadioGroup row aria-label="position" {...input} {...rest}>
      <FormControlLabel
        value="Home"
        control={<Radio color="primary" required={required} />}
        label="Home"
        name="for_home"
      />
      <FormControlLabel
        value="Work"
        control={<Radio color="primary" />}
        label="Work"
        name="for_work"
      />
      <FormControlLabel
        value="Other"
        control={<Radio color="primary" />}
        label="Others"
        name="for_other"
      />
      <FormControlLabel
        value="Summer Home"
        control={<Radio color="primary" />}
        label="Summer Home"
        name="for_summerhome"
      />
    </RadioGroup>
  </FormControl>
);

export const CommonLogoutFunctionlity = () => {
  localStorage.removeItem("access_token");
  localStorage.setItem("listOpen", false);
  localStorage.removeItem("email");
  localStorage.setItem("isLogin", false);
  localStorage.removeItem("name");
};

export const CommonAddQtyFunction = function (
  ProductDetails,
  targetValue,
  added_qty_is_case
) {
  if (targetValue >= 0) {
    var added_qtyInd =
      ProductDetails["added_quantity_is_case"].indexOf(added_qty_is_case);
    console.log("added_qtyInd", added_qtyInd);
    if (added_qtyInd > -1) {
      ProductDetails["added_quantity"][added_qtyInd] = targetValue;
    } else {
      ProductDetails["added_quantity"].push(targetValue);
      ProductDetails["added_quantity_is_case"].push(added_qty_is_case);
    }
  }
  return ProductDetails;
};

export const CommonSetParentOnlyIdLevel = function (
  selectIndexforParentId,
  parentOnlyId,
  category_id
) {
  let newParentonlyIDSelect = [];
  if (selectIndexforParentId === -1) {
    newParentonlyIDSelect = newParentonlyIDSelect.concat(
      parentOnlyId,
      category_id
    );
  } else if (selectIndexforParentId === 0) {
    newParentonlyIDSelect = newParentonlyIDSelect.concat(parentOnlyId.slice(1));
  } else if (selectIndexforParentId === parentOnlyId.length - 1) {
    newParentonlyIDSelect = newParentonlyIDSelect.concat(
      parentOnlyId.slice(0, -1)
    );
  } else if (selectIndexforParentId > 0) {
    newParentonlyIDSelect = newParentonlyIDSelect.concat(
      parentOnlyId.slice(0, selectIndexforParentId)
    );
  }
  return newParentonlyIDSelect;
};

export const CommonSetParentLevelSet = function (
  selectParentLevelIndexParentLevel,
  parent_level,
  level
) {
  let newParentLevelSelect = [];
  if (selectParentLevelIndexParentLevel === -1) {
    newParentLevelSelect = newParentLevelSelect.concat(parent_level, level);
  } else if (selectParentLevelIndexParentLevel === 0) {
    newParentLevelSelect = newParentLevelSelect.concat(parent_level.slice(1));
  } else if (selectParentLevelIndexParentLevel === parent_level.length - 1) {
    newParentLevelSelect = newParentLevelSelect.concat(
      parent_level.slice(0, -1)
    );
  } else if (selectParentLevelIndexParentLevel > 0) {
    newParentLevelSelect = newParentLevelSelect.concat(
      parent_level.slice(0, selectParentLevelIndexParentLevel)
    );
  }
  return newParentLevelSelect;
};

export const CommonSetParentIds = function (
  selectedIndexForparentIds,
  parentIds,
  category_id,
  level
) {
  let newParentIDSelect = [];
  if (selectedIndexForparentIds === -1) {
    newParentIDSelect = newParentIDSelect.concat(
      parentIds,
      category_id + "" + level
    );
  } else if (selectedIndexForparentIds === 0) {
    newParentIDSelect = newParentIDSelect.concat(parentIds.slice(1));
  } else if (selectedIndexForparentIds === parentIds.length - 1) {
    newParentIDSelect = newParentIDSelect.concat(parentIds.slice(0, -1));
  } else if (selectedIndexForparentIds > 0) {
    newParentIDSelect = newParentIDSelect.concat(
      parentIds.slice(0, selectedIndexForparentIds)
    );
  }
  return newParentIDSelect;
};

export const commonSelectedId = (newSelected, selectedIndex, selected, id) => {
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex));
  }
  return newSelected;
};

export const commonPageTitel = (StoreName) => {
  let pageTitel = StoreName && StoreName.sname ? StoreName.sname : "Home page";
  return pageTitel;
};

export const forInconitoTab = () => {
  let show_message;
  var fs = window.RequestFileSystem || window.webkitRequestFileSystem;
  if (!fs) {
    show_message = "check failed?";
    return;
  }
  fs(
    window.TEMPORARY,
    100,
    function (fs) {
      console.log("not seem");
      show_message = "it does not seem like you are in incognito mode";
    },
    function (err) {
      console.log("seems");
      show_message = "it seems like you are in incognito mode";
    }
  );

  return show_message;
};

export const SelectSingle = (field) => {
  const { input, options } = field;

  return (
    <Select
      {...input}
      onBlur={() => {
        input.onBlur();
      }}
      onChange={(option) => {
        input.onChange(option.value);
      }}
      onFocus={() => {
        input.onFocus();
      }}
      options={options}
      value={options.find((option) => option.value === input.value)}
    />
  );
};

export const tConvert = (time) => {
  let timeString = time;
  let H = +timeString.substr(0, 2);
  let h = H % 12 || 12;
  let ampm = H < 12 ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
};

export const timeConvert = (time) => {
  let timeString = new Date(time);
  let H = timeString.getHours();
  let h = (H % 12).toString().length > 1 ? H : "0" + (H % 12);
  let M =
    timeString.getMinutes().toString().length > 1
      ? timeString.getMinutes()
      : "0" + timeString.getMinutes();
  let ampm = H < 12 ? " AM" : " PM";
  timeString = h + ":" + M + ampm;
  return timeString;
};

export const fromBinary = (encoded) => {
  return window.atob(encoded);
};
export const convertTodate = (value) => {
  if (value) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const realvalue = value.split("-");

    return `${monthNames[parseInt(realvalue.at(1)) - 1]} ${realvalue.at(
      -1
    )} ${realvalue.at(0)}`;
  }
};
export const renderNumberInputForZipCode = ({
  input,
  label,
  meta: { touched, error },
  type,
  required,
  unit,
  minValue,
  maxValue,
  ispositive,
  nodecimalScale,
  inputKeypress,
  onClick,
  size,
  autofocus,
  placeholder,
  colors,
}) => (
  <ThemeProvider theme={theme}>
    <div className="form-group user_main_input">
      <NumberContainer inputChange={inputKeypress}>
        <TextField
          fullWidth
          error={!!(touched && error)}
          label={required ? label + " *" : label}
          placeholder={placeholder ? placeholder : ""}
          type={type}
          variant="outlined"
          size={size}
          color={colors}
          onClick={onClick}
          helperText={touched && error}
          {...input}
          value={input.value}
          autoFocus={autofocus}
          onChange={(value) => input.onChange(value)}
          InputProps={{
            inputComponent: NumberFieldComponent,
            // inputProps: {
            //   minLength: 0,
            //   maxLength: 5,
            //   ispositive: ispositive ? true : false,

            //   // decimalScale: nodecimalScale ? 0 : 2,
            // },
            // endAdornment: unit ? (
            //   <InputAdornment position="end">{unit}</InputAdornment>
            // ) : (
            //   ""
            // ),
          }}
        />
      </NumberContainer>
    </div>
  </ThemeProvider>
);

export const tokenFunctionlity = () => {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  return configToken;
};

export const formatPhoneNumberCommon = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

export const setupBeforeUnloadListenerClearCache = () => {
  window.onbeforeunload = function () {
    if (localStorage.getItem("isLogin") === "false")
      localStorage.removeItem("slot");
  };
};

export const RefreshApiToken = (refresh_token, myThis, callback) => {
  let formValues = {
    refresh: refresh_token,
  };
  RefreshLogin(formValues).then((res) => {
    if (res.data) {
      let access_token = JSON.parse(localStorage.getItem("access_token"));
      access_token.access_token = res.data.access_token;
      localStorage.setItem("access_token", JSON.stringify(access_token));
      callback(myThis);
    } else {
      logout();
    }
  });
};

export const logout = () => {
  localStorage.removeItem("pdf_data_view");
  localStorage.removeItem("SelectedAddress");
  localStorage.removeItem("access_token");
  localStorage.removeItem("slot");
  localStorage.setItem("listOpen", false);
  localStorage.removeItem("email");
  localStorage.setItem("isLogin", false);
  localStorage.removeItem("name");
  if (
    localStorage.getItem(
      "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
    ) === null
  )
    localStorage.removeItem("isWebsiteVisited");
  window.location.href = "/";
};

export const getSchedule_zip = () => {
  let frontend_animation = localStorage.getItem("frontend_animation");
  let slot = localStorage.getItem("slot")
    ? JSON.parse(localStorage.getItem("slot"))
    : "";
  let selectedAddress =
    localStorage.getItem("SelectedAddress") !== undefined &&
    localStorage.getItem("SelectedAddress") !== "undefined"
      ? JSON.parse(localStorage.getItem("SelectedAddress"))
      : "";
  let formValues = {
    scheduleType:
      frontend_animation !== "open" &&
      frontend_animation !== "no_delivery_pickup" &&
      slot.schedule !== undefined
        ? slot.schedule
        : "",
    zipCode:
      frontend_animation !== "open" &&
      frontend_animation !== "no_delivery_pickup" &&
      slot.zipCode !== undefined
        ? slot.zipCode
        : "",
    pickup_address_id:
      slot.schedule === 2
        ? selectedAddress?.id === undefined
          ? 0
          : selectedAddress?.id
        : 0,
  };
  return formValues;
};
export const getSchedule_zip_Cart = () => {
  let frontend_animation = localStorage.getItem("frontend_animation");
  let slot = localStorage.getItem("slot")
    ? JSON.parse(localStorage.getItem("slot"))
    : "";
  let selectedAddress =
    localStorage.getItem("SelectedAddress") !== undefined &&
    localStorage.getItem("SelectedAddress") !== "undefined"
      ? JSON.parse(localStorage.getItem("SelectedAddress"))
      : "";
  let formValues = {
    scheduleType:
      frontend_animation !== "no_delivery_pickup" && slot.schedule !== undefined
        ? slot.schedule
        : "",
    zipCode:
      frontend_animation !== "no_delivery_pickup" && slot.zipCode !== undefined
        ? slot.zipCode
        : "",
    pickup_address_id:
      slot.schedule === 2
        ? selectedAddress?.id === undefined
          ? 0
          : selectedAddress?.id
        : 0,
  };
  return formValues;
};

export const addDefaultImageSrc = (ev) => {
  let imgurl =
    localStorage.getItem("product_no_image")[0] === "/"
      ? ApiUrls.BASH_URL + "/media" + localStorage.getItem("product_no_image")
      : localStorage.getItem("product_no_image");
  if (
    localStorage.getItem("product_no_image") === "" ||
    localStorage.getItem("product_no_image") === null
  )
    ev.target.src = NO_IMAGE;
  else {
    const img = new Image();
    img.src = imgurl;
    ev.target.src = imgurl;
    img.onerror = function () {
      ev.target.src = NO_IMAGE;
    };
  }
};

export const capitalize = (string) => {
  const words = string.split(/\s+/); // Split the input string by whitespace
  const result = [];

  for (const word of words) {
    const firstLetter = word[0];
    const restOfWord = word.slice(1);

    // Check if the word starts with an apostrophe and has more characters
    if (firstLetter === "'" && restOfWord.length > 0) {
      result.push(word); // Keep the word unchanged
    } else {
      result.push(firstLetter.toUpperCase() + restOfWord.toLowerCase());
    }
  }

  return result.join(" ");
};

export function CardFieldComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}

export function EBTCardFieldComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}

export const getCreditCardType = (cardNumber) => {
  // Visa
  var visaPattern = /^4/;
  if (cardNumber.match(visaPattern)) {
    return VISA;
  }
  // Mastercard
  var mastercardPattern = /^5[1-5]/;
  if (cardNumber.match(mastercardPattern)) {
    return MASTERCARD;
  }
  // American Express
  var amexPattern = /^3[47]/;
  if (cardNumber.match(amexPattern)) {
    return AMEX;
  }
  // Discover
  var discoverPattern = /^6(?:011|5[0-9]{2})/;
  if (cardNumber.match(discoverPattern)) {
    return DISCOVER;
  }

  // JCB
  var jcbPattern = /^(?:2131|1800|35\d{3})\d{11}$/;
  if (cardNumber.match(jcbPattern)) {
    return "jcb";
  }
  // Unknown card type
  return "";
};

export const sweetAlertBox = (
  title,
  msg,
  Icontype,
  timer = null,
  isbtnVisible = true
) => {
  let web_config = JSON.parse(localStorage.getItem("web_customize"));
  const customStyles = document.createElement("style");
  customStyles.innerHTML = `
    .swal-button.custom-swal-button {
      background-color: ${web_config?.primary?.main || "#000"} !important;
      color: white !important;
    }
  `;
  document.head.appendChild(customStyles);
  swal({
    icon: Icontype,
    timer: timer,
    buttons: {
      confirm: {
        text: "OK",
        visible: isbtnVisible,
        className: "custom-swal-button",
      },
    },
    content: {
      element: "div",
      attributes: {
        innerHTML: `<div>
          <h2 style="font-weight:bold;color: ${web_config?.TextColor?.heading}">${title}</h2>
          <div style="color: ${web_config?.TextColor?.other}">${msg}</div>
          </div>`,
      },
    },
  });
};

export const checkImageUrls = (image1, image2, image3) => {
  return new Promise((resolve) => {
    const img1 = new Image();
    img1.src = image1;

    img1.onload = () =>
      resolve({
        valid: true,
        url: image1, //.slice(image1.indexOf("/local") + 1, image1.length),
      });
    img1.onerror = () => {
      const img2 = new Image();
      img2.src = image2;

      img2.onload = () =>
        resolve({
          valid: true,
          url: image2, //.slice(image2.indexOf("/global") + 1, image2.length),
        });
      img2.onerror = () => {
        const img3 = new Image();
        img3.src = image3;

        img3.onload = () => resolve({ valid: false, url: image3 });
        img3.onerror = () => resolve({ valid: false, url: image3 });
      };
    };
  });
};
export const SinglecheckImageUrls = (image1, image2, image3) => {
  return new Promise((resolve) => {
    const img1 = new Image();
    img1.src = image1;

    img1.onload = () => resolve(true);
    img1.onerror = () => {
      resolve(false);
    };
  });
};

export function slugifyStr(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}


export const refineImgurl = (url, transformation) => {
  if (url?.includes("https://res.cloudinary.com/") === true) {
    const transformations = transformation;
    const urlParts = url.split("/");
    // Find the position to insert the transformations
    // The transformations should be inserted after 'upload'
    const uploadIndex = urlParts.indexOf("upload") + 1;
    // Create the transformation string
    const transformationString = Object.keys(transformations)
      .map((key) => `${key}_${transformations[key]}`)
      .join(",");
    // Insert the transformation string into the URL parts
    urlParts.splice(uploadIndex, 0, transformationString,"q_auto","f_auto");
    // Join the URL parts back into a string
    return urlParts.join("/");
  } else {
    return url ? url : NO_IMAGE;
  }
};
