import React, { Component } from "react";
// import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from "react-slick";
import "../../../assets/css/react-slick.css";
import ONIONS_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import ImageMagnifier from "./ImageMagnifier";
export default class ReactSlickExample extends Component {
  addDefaultSubCategorySrc(ev) {
    ev.target.src = ONIONS_IMAGE;
  }
  render() {
    const { rimProps, rsProps } = this.props;
    const { ProductImages,ProductDetails } = this.props;
    return (
      <ReactSlick
        {...{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }}
        {...rsProps}
      >
        {ProductImages?.length>0?
          ProductImages.map((src, index) => (
            <div
              key={index}
              className={`fluid ${
                this.props.extraCharges?.is_show_out_of_stock === true &&
                this.props.ProductDetails?.out_of_stock === true
                  ? "filter-blurimg"
                  : ""
              }`}
            >
              <ImageMagnifier src={src} ProductDetails={ProductDetails}/>
            </div>
          )):""}
      </ReactSlick>
    );
  }
}
