import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loginpopup from "../../login/Loginpopup";
import {
  AddFavoriteLevelFunctionlity,
  favoriteLevelListFunctionlity,
  RemoveFavouriteProduct,
} from "../../../actions/favorite/favoriteAction";
import Commondialog from "../Commondialog";
import { RefreshApiToken } from "../../../utils/renderUtils/renderUtil";
import CustomPreloader from "../Loader/CustomPreloader";
import SaveIcon from "@mui/icons-material/Save";
import {
  PrimaryBorderButton,
  PrimaryButton,
  SecondaryButton,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColorDimmed,
  TypographyTextColor,
} from "../UiDesign";
import { AiFillHeart } from "react-icons/ai";
class CreateFavorite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteOpen: false,
      favoriteLevel: "",
      filterByFavorite: [],
      warningAlert: false,
      addButton: "",
      favaritelevel_id: "",
      favoriteLevelData: [],
      f_list: false,
      setOpenLoginPopup: false,
      isLoginCLostBtnShow: true,
      MainLoader: false,
      openDialog: false,
      statusDialogmsg: "Do you want to remove this item from favorite list ?",
    };
  }

  addFavoiteLevelFunctionlity = (favoriteLevel, product_id) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res && res.message) {
          this.FetchFavoriteLevelList(product_id, res.id);
        }
      }
    );
  };
  favariteOnclick = () => {
    let login_status = localStorage.getItem("isLogin");
    let activePage = this.props.activePageName ? this.props.activePageName : "";
    var favoriteLevel = this.state.favoriteLevel;
    if (login_status === "true") {
      this.FetchFavoriteLevelList(this.props.product_id);
      this.setState({ favoriteOpen: true, addButton: "", MainLoader: true });
      if (favoriteLevel !== "") {
        this.addFavoiteLevelFunctionlity(favoriteLevel, this.props.product_id);
      }
    } else {
      this.setState({ setOpenLoginPopup: true });
    }
  };

  hidefavoriteOpen = () => {
    this.setState({ favoriteOpen: !this.state.favoriteOpen });
  };

  changeLevelValue = (e) => {
    this.setState({ favoriteLevel: e.target.value, addButton: e.target.value });
  };

  FetchFavoriteLevelList = (product_id, favaritelevel_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 100,
      product_id: product_id ? product_id : "",
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelData = [];
        if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
              myThis.FetchFavoriteLevelList(product_id, favaritelevel_id);
            });
          }
        } else if (res.data && res.data.result) {
          favoriteLevelData = res.data.result;
        }

        this.setState({
          favoriteLevelData: favoriteLevelData,
          MainLoader: false,
        });
        if (favaritelevel_id > 0) {
          this.funtiolityOfProduct(favaritelevel_id);
        }
      }
    );
  };

  funtiolityOfProduct = (favoritelabel_id, e) => {
    let favoriteLevelData = this.state.favoriteLevelData;
    let checkfavoriteLevelID = favoriteLevelData.findIndex(
      (p) => p.id === favoritelabel_id
    );
    if (checkfavoriteLevelID > -1) {
      favoriteLevelData[checkfavoriteLevelID]["f_list"] =
        !favoriteLevelData[checkfavoriteLevelID]["f_list"];
    }
    this.setState({ favoriteLevelData: favoriteLevelData });
    const selectedIndex = this.state.filterByFavorite.indexOf(favoritelabel_id);
    let newFavoriteSelected = [];
    if (selectedIndex === -1) {
      newFavoriteSelected = newFavoriteSelected.concat(
        this.state.filterByFavorite,
        favoritelabel_id
      );
    } else if (selectedIndex === 0) {
      newFavoriteSelected = newFavoriteSelected.concat(
        this.state.filterByFavorite.slice(1)
      );
    } else if (selectedIndex === this.state.filterByFavorite.length - 1) {
      newFavoriteSelected = newFavoriteSelected.concat(
        this.state.filterByFavorite.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      this.state.filterByFavorite.splice(selectedIndex, 1);
      newFavoriteSelected = this.state.filterByFavorite;
    }
    this.setState({
      filterByFavorite: newFavoriteSelected,
      warningAlert: false,
    });
  };
  handleClosedialog = () => this.setState({ openDialog: false });
  productAddIntoFavoriteLevel = () => {
    let favoriteLevelData = this.state.favoriteLevelData;
    let favoriteOpen = true;
    let finalFabIds = "";
    let warningAlert = false;
    for (const filterobj of favoriteLevelData) {
      if (filterobj["f_list"]) {
        if (finalFabIds.length > 0) {
          finalFabIds += "," + filterobj.id;
        } else {
          finalFabIds = filterobj.id.toString();
        }
      }
    }
    if (finalFabIds && finalFabIds.length > 0) {
      this.props.addFavoiteProductFunctionlity(finalFabIds);
      warningAlert = false;
      favoriteOpen = false;
    } else {
      this.props.addFavoiteProductFunctionlity(finalFabIds);
      warningAlert = false;
      favoriteOpen = false;
    }
    this.setState({ favoriteOpen: favoriteOpen, warningAlert: warningAlert });
  };
  CloseLoginDialog = (type) => {
    if (type === "close") this.setState({ setOpenLoginPopup: false });
    else {
      this.setState({ setOpenLoginPopup: false });
    }
  };

  render() {
    const { favoriteLevelData } = this.state;
    return (
      <div className="addNoteSed">
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={(type) => this.CloseLoginDialog(type)}
          isShowclose={this.state.isLoginCLostBtnShow}
          // history={this.props.history}
        />
        {this.props.pageName === "cart_page" ? (
          this.props.cartCategoryListlength > 0 ? (
            <Tooltip title="Save As Shopping List" role="button">
              <TypographySecondaryBgColorDimmed
                border={1}
                color="secondary"
                variant="contained"
                className="p-1"
                style={{ borderRadius: "1rem",display:'inline-block' }}
                onClick={() => this.favariteOnclick()}
              >
                <SaveIcon /> Shopping List
              </TypographySecondaryBgColorDimmed>
            </Tooltip>
          ) : (
            <></>
          )
        ) : (
          <Tooltip
            title={this.props.wishlist === false ? "Add Favorite" : "Added"}
            arrow
          >
            {this.props.wishlist === false ? (
              <Link
                className="no_selected"
                to
                onClick={() => this.favariteOnclick()}
                style={{marginTop:'6px'}}
              >
                <span>
                  {" "}
                  <img src="/lists.svg"></img>
                </span>
              </Link>
            ) : (
              <Link
                onClick={() => this.setState({ openDialog: true })}
                className="wish_selected"
              >
                <TypographyFontColor border={0} className="heart-icon">
                  <AiFillHeart />
                  {/* <img src="/checked_active.svg"></img> */}
                </TypographyFontColor>
              </Link>
            )}
          </Tooltip>
        )}
        <Commondialog
          open={this.state.openDialog}
          handleCloseOpnDialog={() => this.handleClosedialog()}
          agreeFunctionlity={(value) => {
            this.handleClosedialog();
            this.props.removeFromfavouriteProduct(
              this.props.favoriot_id,
              this.props.product_id
            );
          }}
          statusDialogmsg={this.state.statusDialogmsg}
        />
        <Dialog
          open={this.state.favoriteOpen}
          onClose={() => this.hidefavoriteOpen()}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp"
        >
          <DialogContent className="p-0">
            <div className="popup">
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <TypographyTextColor component="h4" Color="heading">
                  {" "}
                  Favorite List
                </TypographyTextColor>

                <IconButton onClick={() => this.hidefavoriteOpen()}>
                  <span class="icon-close" style={{ fontSize: "18px" }}></span>
                </IconButton>
              </div>
              <div className="boxshadow rounded-0 py-3 px-3">
                {this.state.MainLoader ? (
                  <div className=""> Loading...</div>
                ) : (
                  <div className="favlist">
                    {favoriteLevelData && favoriteLevelData.length > 0 ? (
                      favoriteLevelData.map((row, index) => {
                        return (
                          <div className="form-group">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  name="checkedC"
                                  defaultChecked={
                                    "f_list" in row ? row.f_list : false
                                  }
                                  checked={"f_list" in row ? row.f_list : false}
                                />
                              }
                              label={
                                <TypographyTextColor
                                  component="span"
                                  Color="other"
                                >
                                  {row.name}
                                </TypographyTextColor>
                              }
                              onChange={(e) =>
                                this.funtiolityOfProduct(row.id, e)
                              }
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="sideemtpyCartMsg">
                        There is no favorite list
                      </div>
                    )}
                  </div>
                )}
                <div className="favoriteSave">
                  <SecondaryButton
                    variant="contained"
                    onClick={() => this.productAddIntoFavoriteLevel()}
                    className="fav-list-btn"
                    Fonttype="other"
                  >
                    {" "}
                    Save{" "}
                  </SecondaryButton>
                </div>
                <hr></hr>
                <div className="form-group mb-4 d-flex py-1 favoriteSave">
                  <TextField
                    placeholder="New List"
                    type="text"
                    variant="outlined"
                    className="fav-list-text"
                    value={this.state.addButton}
                    onChange={(event) => this.changeLevelValue(event)}
                  />
                  <SecondaryButton
                    disabled={!this.state.addButton ? "true" : ""}
                    variant="contained"
                    onClick={() => this.favariteOnclick()}
                    className={
                      this.state.addButton ? "fav-list-btn" : "fav-list-dis"
                    }
                    startIcon={<AddIcon />}
                    Fonttype="other"
                  >
                    {" "}
                    Add{" "}
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default CreateFavorite;
