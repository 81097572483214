import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const CommonAlertBox = (props) => {
  return (
    <Dialog
      open={props.error}
      onClose={(e) => props.handleClose(e)}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      className="popUp"
     
    >
      <DialogContent className="p-0">
        <h4 style={{ color: "red",textAlign:'center',fontSize:'1.0rem',marginBottom:'10px' }}>
          <ErrorOutlineIcon style={{fontSize:'1.0rem'}}/> {props.error}
        </h4>
      </DialogContent>
      <DialogActions style={{justifyContent:'center'}}>
        <Button onClick={(e) => props.handleClose(e)} variant="outlined">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonAlertBox;
