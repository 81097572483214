import { constant } from "../store/Constant";
const initialState = {
  TopMenu: [],
};

const headerreducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_TOPMENU_DATA:
      return { ...state, TopMenu: [] };
    default:
      return state;
  }
};

export default headerreducer;
