import React from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import CategoryListing from "./common/Category/categoryListing";
import CategoriesModal from "./common/Category/categoriesModal";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import LoderImage from "../assets/images/more.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AddFavoriteLevelFunctionlity,
  favoriteLevelListFunctionlity,
  AddFavoriteProductFunctionlity,
  RemoveFavouriteProduct,
} from "../actions/favorite/favoriteAction";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateFavorite from "./common/ProductCard/createFavorite";
import ItemDetailsModal from "./common/ProductDetail/item-details-modal";
import {
  ProductDetailsApi,
  AddToCartFunctionlity,
  CategeriesListView,
  UpdateCartFunctionlity,
  PopularProductsList,
  WishListFunctionlity,
  FilterListApiByBrandFlavorKosher,
  CategoryTreeViewApi,
  GuestLoginAthentication,
  SubCategeriesListView,
  ProductsListListingPage,
} from "../actions/home/homeAction";
import { RefreshLogin } from "../actions/login/loginAction";
import ReactSlick from "./common/ProductDetail/ReactSlick";
import { Snackbar } from "@mui/material";
import {
  CommonAddQtyFunction,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  fromBinary,
  RefreshApiToken,
  getSchedule_zip,
  sweetAlertBox,
  SinglecheckImageUrls,
  slugifyStr,
  checkImageUrls,
} from "../utils/renderUtils/renderUtil";
import Loginpopup from "./login/Loginpopup";
import CustomPreloader from "./common/Loader/CustomPreloader";
import CommonAlertBox from "./common/CommonAlertBox";
import { GetSettingsData } from "../actions/profile/profileAction";
import ItemDetailDescription from "./common/ProductDetail/ItemDetailDescription";
import ItemCard from "./common/ProductCard/ItemCard";
import ProductNote from "./common/ProductCard/ProductNote";
import ProductNoteChipsview from "./common/Header/ProductNoteChipsview";
import {
  LabelPrimaryColor,
  SaleLabelySecondaryColor,
  SecondaryButton,
  TextFormating,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryBgColorDimmed,
  TypographySecondaryColor,
  TypographyTextColor,
} from "./common/UiDesign";
import ItemCardMob from "./common/ProductCard/Mobile/ItemCardMob";
import ApiUrls from "../utils/apiUtils/apiUrl";
var check_added_quantity_is_case = -1;
class DetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      open_inner: false,
      ProductDetails: [],
      extra_info: true,
      OpenQtyCount: false,
      itemCount: 1,
      pageName: "details_page",
      PopularProductList: [],
      addTocart: true,
      addCount: false,
      CartCount: [],
      cartItemList: [],
      SubTotal: 0,
      categoryID: [],
      cartListOpen: true,
      next_info: false,
      pre_info: false,
      productDetailsLoader: false,
      cartListLoader: false,
      cartListIndex: 0,
      cartLoader: true,
      BrandProduct: [],
      flavor: [],
      kosher: [],
      fruitType: [],
      page: 1,
      category_id: "",
      subcategory_id: "",
      subsubcategory_id: "",
      categoryData: [],
      note: "",
      disabled: false,
      TreeViewIteams: [],
      filterByType: [],
      filterByFlavor: [],
      filterByKosher: [],
      next_rec: false,
      prev_rec: false,
      error: false,
      success: false,
      is_case_check: false,
      setOpen: false,
      productNoteItemNote: "",
      backupInstraction: "",
      backupInstractioArr: [
        "Do not substitute",
        "Substitute with similar",
        "Call me",
      ],
      CategoryOption: [],
      favoriteOpen: false,
      favoriteLevel: "",
      favoriteLevelData: [],
      special_filter: "all",
      special_filter_value: "",
      SubcategoryName: "",
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      msgstr: "",
      ProductName: "",
      container: [],
      containerLength: 0,
      targetvalue: "",
      propularProductLoader: true,
      chack_is_case: [],
      containerValue: "",
      container_type: "",
      is_container_check: [],
      quantity: 0,
      pro_details_ind: -1,
      AddtoCartBtn: true,
      inputField: false,
      headerinputField: false,
      logo: "",
      SubSubCategoryName: "",
      doNotSubstitute: false,
      substituteWithSimilar: false,
      callMe: false,
      categoryNames: "",
      brandName: [],
      brandIds: [],
      extraCharges: [],
      setOpenLoginPopup: false,
      isslotDeliverypopupState_compwill: false,
      child_items: [],
      selectedChilditem: "",
      Parent_item: [],
      is_next_rec: false,
      is_prev_rec: false,
      emailSubscribe: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      tree_data: [],
      SideCategoryListLoader: false,
      restrictByOnhand: JSON.parse(localStorage.getItem("Store_address"))
        ?.restrict_by_onhand,
      isShowItemCode: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_show_item_code,
      ProductImages: [],
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.isLogin = this.loginOptions.bind(this);
    this.searchOpen = this.searchOpen.bind(this);
    this.categoryOpen = this.categoryOpen.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  toggleMenu() {
    this.setState({ toggleMenu: !this.state.toggleMenu });
  }
  loginOptions() {
    this.setState({ isLogin: !this.state.isLogin });
  }
  searchOpen() {
    this.setState({ openSearch: !this.state.openSearch });
  }
  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }

  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }
  CloseLoginDialog = (type) => {
    if (type === "close") {
      localStorage.removeItem("tempProductData");

      this.setState({ setOpenLoginPopup: false });
    } else {
      if (
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") === "must_login_add_to_cart"
      ) {
        let tempData = JSON.parse(localStorage.getItem("tempProductData"));

        if (tempData !== null) {
          this.AddToCartFunctionlity(
            tempData.product_id,
            tempData.added_quantity,
            tempData.is_case,
            tempData.categoryindex
          );
        }
        localStorage.removeItem("tempProductData");
      }
      this.setState({ setOpenLoginPopup: false });
      this.FetchCategoryList();
      this.FetchPopularProductList();
      this.FetchCartItemList();

      this.ExtraChargesFunctiolity();
    }
  };
  handleSlotName = (data) => {
    this.setState({ slotName: data });
  };
  OpenLoginPoup = () => {
    this.setState({ setOpenLoginPopup: true });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  filterCategoryBreadcumbs = (data) => {
    this.setState({ categoryNames: data[0] });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const accessToken = localStorage.getItem("access_token");
    let StoreName = JSON.parse(localStorage.getItem("access_token"));
    let pageTitel = StoreName ? StoreName.sname : "";
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    const isLogin = localStorage.getItem("isLogin");
    let product_id = 0;
    let AddtoCartBtn;
    let ProductName;
    let special_filter = this.state.special_filter;
    let special_filter_value = this.state.special_filter_value;
    let selectchild = "";
    let pageNumber = 0;
    let selectedProductindex = 0;
    let ProductList = [];
    let total = 0;
    let categoryname = "";
    if (this.props.location && this.props.location.search) {
      const value = this.props.location.search;
      const getPathname = this.props.location?.pathname.split("/product/");
      const replaceProductName = getPathname[1].replaceAll("-", " ");
      ProductName = this.titleCase(replaceProductName);
      product_id = value.product_id;
    } else {
      const getItemName = this.props.location?.pathname.split("/product/");
      ProductName = getItemName[1];
    }
    pageNumber = this?.props?.location?.state?.listingFormValues?.page;
    selectedProductindex = this?.props?.location?.state?.selectedProductindex;
    ProductList = this?.props?.location?.state?.ProductListData;
    total = this?.props?.location?.state?.total;
    categoryname = this?.props?.location?.state?.categoryNames;

    let pre_info = false;
    let next_info = false;
    if (selectedProductindex === 0) {
      pre_info = false;
    } else pre_info = true;

    if (selectedProductindex === total - 1) {
      next_info = false;
    } else {
      next_info = true;
    }
    if (ProductList === undefined) {
      next_info = false;
      pre_info = false;
    }
    this.setState({
      ProductName: ProductName,
      page: pageNumber,
      selectedProductindex: selectedProductindex,
      ProductList: ProductList,
      total: total,
      is_prev_rec: pre_info,
      is_next_rec: next_info,
      categoryNames: categoryname,
    });
    document.title = ProductName;

    if (this.props.location && this.props.location.state) {
      AddtoCartBtn = this.props.location.state.addTocart_btn;
      special_filter = this.props.location.state.special_filter;
      special_filter_value = this.props.location.state.special_filter_value;
      selectchild = this.props.location.state.selectedChilditem;
    } else {
      AddtoCartBtn = true;
    }
    this.setState({
      special_filter: special_filter,
      special_filter_value: special_filter_value,
      logo: logo,
      selectedChilditem: selectchild,
    });

    if (AddtoCartBtn === false) {
      this.setState({ AddtoCartBtn: false, OpenQtyCount: true });
    } else {
      this.setState({ AddtoCartBtn: true, OpenQtyCount: false });
    }
    if (accessToken === "undefined" || accessToken === null) {
      this.HitGuestApiToken();
    } else if (accessToken && isLogin === "false") {
      this.FetchCategoryList();
      this.FetchPopularProductList();
      this.FetchCartItemList();
      this.ExtraChargesFunctiolity();
      if (special_filter === "campaign") {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          special_filter,
          special_filter_value
        );
      } else {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          this.state.special_filter,
          this.state.special_filter_value
        );
      }
      this.FetchCategoryTreeView();
    } else if (accessToken && isLogin === "true") {
      this.FetchSettingData();
      this.FetchCategoryList();
      this.FetchPopularProductList();
      this.FetchCartItemList();
      this.ExtraChargesFunctiolity();
      if (special_filter === "campaign") {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          special_filter,
          special_filter_value
        );
      } else {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          this.state.special_filter,
          this.state.special_filter_value
        );
      }
      this.FetchCategoryTreeView();
    }
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.ExtraChargesFunctiolity();
          });
        }
      } else if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  handleInnerOpenDialog(
    e,
    parent_id,
    subcategory_name,
    has_child,
    level,
    cslug,
    all_data
  ) {
    let SubcategoryName = this.state.SubcategoryName;
    let SubSubCategoryName = this.state.SubSubCategoryName;
    if (all_data && all_data.length > 0) {
      if (all_data.length === 2) {
        for (let i = 0; i < all_data.length; i++) {
          if (all_data[i].category_name === subcategory_name) {
            SubcategoryName = all_data[i].category_name;
          } else {
            SubSubCategoryName =
              parent_id != null ? all_data[i].category_name : "";
          }
        }
      } else {
        SubcategoryName = all_data[0].category_name;
        SubSubCategoryName = parent_id != null ? all_data[1].category_name : "";
      }
    } else {
      SubcategoryName = subcategory_name;
    }

    if (has_child === true) {
      this.setState({
        open_inner: true,
        SubcategoryName: SubcategoryName,
        SubSubCategoryName: SubSubCategoryName,
      });
      this.FetchSubCategoryList(e, 0);
    } else {
      this.handleInnerCloseDialog();
    }
  }

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      }

      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parentIds,
      category_id,
      level
    );
    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchCategoryTreeView = (product_id, next_info, pre_info) => {
    let formValues = getSchedule_zip();
    this.setState({ SideCategoryListLoader: true });
    CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
      let TreeViewIteams = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchCategoryTreeView(product_id, next_info, pre_info);
          });
        }
      } else if (res.data && res.data.result) {
        var tree_data = this.onbuildForTreeData(res.data.result, null);
        tree_data.sort(function (a, b) {
          return a.cat_order - b.cat_order;
        });
        TreeViewIteams = res.data.result;
        this.setState({
          tree_data: tree_data,
          TreeViewIteams: TreeViewIteams,
          SideCategoryListLoader: false,
        });
      }
    });
  };
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  FetchProdutDetails = (
    product_id,
    ProductName,
    next_info,
    pre_info,
    special_filter,
    special_filter_value
  ) => {
    if (window.screen.availWidth <= 900) {
      let header = document.getElementById("search_section");
      console.log("header", header);
      header.style.position = "relative";
    }
    let StoreName = JSON.parse(localStorage.getItem("access_token"));
    let pageTitel = StoreName ? StoreName.sname : "";
    let formValues = {
      product_id: product_id,
      slug: ProductName,
      extra_info: this.state.extra_info,
      next_info: false,
      pre_info: false,
      special_filter: special_filter === undefined ? "" : special_filter,
      special_filter_value:
        special_filter_value === undefined ? "" : special_filter_value,
    };
    document.title = ProductName;
    this.setState({ productDetailsLoader: true });
    console.log(formValues);
    ProductDetailsApi(formValues, this.props.dispatch).then((res) => {
      console.log(res);
      let productDetails = [];
      let categoryData = [];
      let parent_onlyid = [];
      let next_rec = false;
      let prev_rec = false;
      let container = [];
      let containerVal = "";
      let parseContainerVal = "";
      var is_checked = false;
      let AddtoCartBtn = this.state.AddtoCartBtn;
      let pro_details_ind = this.state.pro_details_ind;
      let msgstr = "Available on ";
      let child_item = [];
      let container_type = this.state.container_type;
      let category_id = this.state.category_id;
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchProdutDetails(
              product_id,
              ProductName,
              next_info,
              pre_info,
              special_filter,
              special_filter_value
            );
          });
        }
      } else if (res.data && res.data.result && res.data.category.length > 0) {
        productDetails = res.data.result;
        // this.checkingImageUrl(productDetails, productDetails.image);
        window.scrollTo(0, 0);
        next_rec = res.data.next_rec;
        prev_rec = res.data.prev_rec;
        categoryData = res.data.category_data;
        child_item = res.data.chield_items;
        for (let i = 0; i < categoryData.length; i++) {
          parent_onlyid.push(categoryData[i]?.category_id);
          // category_id = categoryData[i].category_id;
        }
        console.log(categoryData, categoryData.at(-1)?.at(-1));
        category_id = categoryData?.at(-1)?.at(-1)?.category_id;
        if (this.state.categoryNames === undefined)
          this.filterCategoryBreadcumbs(categoryData);
        let dow = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        let dowarr = productDetails.availableondays
          ? productDetails.availableondays.split(",")
          : "";
        dowarr = dowarr === "" ? "" : Array.from(new Set(dowarr));
        let finaldowarr = "";
        let dowarrlength = dowarr.length;
        for (let j = 0; j < dowarrlength; j++) {
          finaldowarr = dowarr[j] - 1;
          if (finaldowarr >= 0 && finaldowarr <= 6) {
            if (j === 0) {
              msgstr += dow[finaldowarr];
            } else if (j < dowarrlength - 1) {
              msgstr += ", " + dow[finaldowarr];
            } else {
              msgstr += " and " + dow[finaldowarr];
            }
          }
        }

        containerVal = productDetails.container
          ? productDetails.container.split(",")
          : "";
        if (containerVal) {
          parseContainerVal = JSON.parse(containerVal);
          if (containerVal.length > 3) {
            container.push({ label: "Select", value: "" });
          }
          // var mySelect = document.getElementById('exampleFormControlSelect1');
          for (const property in parseContainerVal) {
            if (containerVal.length > 3) {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
              // if(property === productDetails.container_type[0]) {
              //   this.setState({containerValue: parseContainerVal[property], container_type: property})
              // }
            } else {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
            }
            if (property === productDetails.container_type[0]) {
              this.setState({
                containerValue: parseContainerVal[property],
                container_type: property,
              });
            }
          }
        }

        if (productDetails.added_quantity.length > 0) {
          if (productDetails.added_quantity[0] !== 0) {
            AddtoCartBtn = false;
            pro_details_ind = 0;
            is_checked = productDetails.added_quantity_is_case[0];
          } else {
            productDetails.added_quantity = [];
            productDetails.added_quantity_is_case = [];
            productDetails.container_type = [];
            pro_details_ind = -1;
          }
        }
        console.log(category_id);
        this.setState({
          AddtoCartBtn,
          is_case_check: is_checked,
          pro_details_ind: pro_details_ind,
          ProductDetails: productDetails,
          Parent_item: Object.assign({}, productDetails),
          categoryData: categoryData,
          parent_onlyid: parent_onlyid,
          // ProductImages: ProductImages,
          next_rec,
          prev_rec,
          msgstr: msgstr,
          container: container,
          containerLength: containerVal.length,
          category_id: category_id,
          child_items: child_item,
        });
        if (this.state.selectedChilditem !== "")
          this.changeChilditemdata(
            { target: { value: this.state.selectedChilditem } },
            "select"
          );
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProdutDetails(
                formValues.product_id,
                formValues.slug,
                formValues.extra_info,
                formValues.next_info,
                formValues.pre_info,
                formValues.special_filter,
                formValues.special_filter_value
              );
            }
          );
        }
      } else {
        console.log("errors", res.errors);
        this.props.history.push({
          pathname: "/not-found",
        });
      }
      this.setState({ productDetailsLoader: false });
    });
  };

  FetchPopularProductList = () => {
    let formValues = getSchedule_zip();
    formValues.limit = 8;
    formValues.page = 1;
    this.setState({ propularProductLoader: true });
    PopularProductsList(formValues, this.props.dispatch).then((res) => {
      let popularProducts = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchPopularProductList();
            }
          );
        }
      } else if (res.data && res.data.result) {
        popularProducts = res.data.result;
      }
      this.setState({
        PopularProductList: popularProducts,
        propularProductLoader: false,
      });
    });
  };

  HitGuestApiToken = () => {
    let formValues = {};
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
      logo = res.data && res.data.logo ? res.data.logo : "";
      this.FetchPopularProductList();
      this.FetchCartItemList();
      this.FetchCategoryList();
      this.ExtraChargesFunctiolity();
      this.setState({ logo: logo });
      this.FetchCategoryTreeView();
      let product_id = 0;
      let AddtoCartBtn;
      let ProductName;
      let special_filter = this.state.special_filter;
      let special_filter_value = this.state.special_filter_value;
      if (this.props.location && this.props.location.search) {
        const value = this.props.location.search;
        const getPathname = this.props.location.pathname.split("/product/");
        const replaceProductName = getPathname[1].replaceAll("-", " ");
        ProductName = this.titleCase(replaceProductName);
        product_id = value.product_id;
      } else {
        const getItemName = this.props.location.pathname.split("/product/");
        ProductName = getItemName[1];
      }
      let StoreName = JSON.parse(localStorage.getItem("access_token"));
      let pageTitel = StoreName ? StoreName.sname : "";
      document.title = ProductName;
      if (this.props.location && this.props.location.state) {
        AddtoCartBtn = this.props.location.state.addTocart_btn;
        special_filter = this.props.location.state.special_filter;
        special_filter_value = this.props.location.state.special_filter_value;
      } else {
        AddtoCartBtn = true;
      }
      if (special_filter === "campaign") {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          special_filter,
          special_filter_value
        );
      } else {
        this.FetchProdutDetails(
          product_id,
          ProductName,
          this.state.next_info,
          this.state.pre_info,
          "all",
          ""
        );
      }
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        // this.FetchCategoryList();
        // this.FetchCartItemList();
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  FetchFilterApiByBrandFlavorKosher = (
    filter_name,
    page,
    category_id,
    subcategory_id,
    subsubcategory_id
  ) => {
    let formValues = {
      filter_name: filter_name,
      limit: 12,
      page: page,
      category_id: category_id,
      subcategory_id: subcategory_id,
      subsubcategory_id: subsubcategory_id,
    };
    let zonedata = getSchedule_zip();
    formValues = { ...formValues, ...zonedata };
    let brand_id;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.brand_id
    ) {
      brand_id = this.props.location.state.brand_id;
    } else {
      brand_id = "";
    }
    FilterListApiByBrandFlavorKosher(formValues, this.props.dispatch).then(
      (res) => {
        let BrandProduct = [];
        let flavor = [];
        let kosher = [];
        let fruitType = [];
        if (res.data && res.data.result) {
          BrandProduct = res.data.result.brand;
          flavor = res.data.result.flavor;
          kosher = res.data.result.kosher;
          fruitType = res.data.result.type;
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchFilterApiByBrandFlavorKosher(
                  formValues.filter_name,
                  formValues.page,
                  formValues.category_id,
                  formValues.subcategory_id,
                  formValues.subsubcategory_id
                );
              }
            );
          }
        }

        if (brand_id > 0 && BrandProduct && BrandProduct.length > 0) {
          let checkBrandID = BrandProduct.findIndex(
            (p) => p.brand_id === brand_id
          );
          BrandProduct[checkBrandID]["checkboxChecked"] = true;
        }
        this.setState({
          BrandProduct: BrandProduct,
          flavor: flavor,
          kosher: kosher,
          fruitType: fruitType,
        });
      }
    );
  };

  openAddQuantityCount = (e, added_quantity, added_qty_is_case, is_parent) => {
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.OpenLoginPoup();
      localStorage.setItem(
        "tempProductData",
        JSON.stringify({
          product_id: e.id,
          added_quantity,
          is_case: added_qty_is_case,
          categoryindex: "Details",
          addTocartQty: "",
          container_type: "",
        })
      );
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({ isslotDeliverypopupState_compwill: true });
    } else {
      if (added_quantity > 0) {
        let product_id = e.id;
        if (e.original_price === 0 && is_parent) {
          this.createSuccessMsg("error", "Please select the item");
        } else {
          this.AddToCartFunctionlity(
            product_id,
            added_quantity,
            added_qty_is_case,
            "Details"
          );
        }
      }
    }
  };

  addDefaultSubCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  openAddToCart = (
    added_quantity,
    added_quantity_is_case,
    container_type,
    icon_type
  ) => {
    added_quantity = parseInt(added_quantity);
    let ProductDetails = this.state.ProductDetails;
    let containerType = container_type;
    if (added_quantity >= 0) {
      var added_qtyInd = ProductDetails["added_quantity_is_case"].indexOf(
        added_quantity_is_case
      );
      this.commonFunctionForContainer(
        added_qtyInd,
        added_quantity,
        added_quantity_is_case
      );

      this.setState({
        itemCount: this.state.itemCount - 1,

        // AddtoCartBtn: true,
      });
      this.UpdateCartQty(
        ProductDetails,
        added_quantity,
        added_quantity_is_case,
        containerType,
        icon_type
      );
    }
  };

  increaseItemCount = (
    added_quantity,
    added_quantity_is_case,
    container_type,
    icon_type
  ) => {
    added_quantity = parseInt(added_quantity);
    let ProductDetails = this.state.ProductDetails;
    let containerType = container_type;
    let pro_details_ind = this.state.pro_details_ind;

    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.OpenLoginPoup();
      localStorage.setItem(
        "tempProductData",
        JSON.stringify({
          product_id: ProductDetails.id,
          added_quantity,
          is_case: added_quantity_is_case,
          categoryindex: "Details",
          addTocartQty: "",
          container_type: "",
        })
      );
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({ isslotDeliverypopupState_compwill: true });
    } else {
      this.UpdateCartQty(
        ProductDetails,
        added_quantity,
        added_quantity_is_case,
        this.state.container_type,
        icon_type
      );
    }
  };

  AddToCartFunctionlity = (
    product_id,
    added_quantity,
    added_qty_is_case,
    selector
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: added_qty_is_case,
      note: this.state.note,
      container_type: this.state.container_type,
    };
    let container_type = this.state.container_type;
    let ProductDetails = this.state.ProductDetails;
    let PopularProductList = this.state.PopularProductList;
    let pro_details_ind = this.state.pro_details_ind;
    this.setState({ cartLoader: true });
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res && res.status === 200) {
        if (selector === "Details") {
          var added_qtyInd =
            ProductDetails["added_quantity_is_case"].indexOf(added_qty_is_case);

          this.commonFunctionForContainer(
            added_qtyInd,
            added_quantity,
            added_qty_is_case
          );
        } else {
          this.commonFunctionLity(
            product_id,
            added_quantity,
            added_qty_is_case
          );
        }

        this.FetchCartItemList();
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.AddToCartFunctionlity(
              product_id,
              added_quantity,
              added_qty_is_case,
              selector
            );
          });
        }
      } else {
        if (res.errors && res.errors.status === 400 && res.errors.data) {
          this.createSuccessMsg("error", res.errors.data.errors);
        }
      }
      this.setState({
        cartLoader: false,
        PopularProductList,
        // Parent_item:
        //   this.state.Parent_item.id === ProductDetails.id
        //     ? Object.assign({}, ProductDetails)
        //     : this.state.Parent_item,
        ProductDetails,
      });
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      } else if (res.data && res.data.result && res.data.result.length > 0) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    quantity,
    is_case,
    note,
    container_type,
    icons = "",
    selector = "Details"
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: is_case,
      note: note,
      container_type:
        container_type &&
        container_type.length !== 0 &&
        container_type[0] !== ""
          ? container_type
          : "",
    };
    let cartItemList = this.state.cartItemList;
    let ProductDetails = this.state.ProductDetails;
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res && res.message) {
        if (icons !== "") {
          for (let i = 0; i < cartItemList.length; i++) {
            if (cartItemList[i].product_id === product_id) {
              cartItemList[i].total_price = res.subtotal;
              cartItemList[i].qty = quantity;
              cartItemList[i].note = note;
            }
            SubTotal += Number(cartItemList[i].total_price);
          }
          if (selector === "Details") {
            if (quantity >= 1) {
              var added_qtyInd =
                ProductDetails["added_quantity_is_case"].indexOf(is_case);

              this.commonFunctionForContainer(added_qtyInd, quantity, is_case);
            }
          } else {
            this.commonFunctionLity(product_id, quantity, is_case);
          }
          this.FetchCartItemList();
        }
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.UpdateCartItemFunctionlity(
                formValue.product_id,
                formValue.quantity,
                formValue.is_case,
                formValue.note
              );
            }
          );
        }
      } else {
        if (res.errors && res.errors.status === 400 && res.errors.data) {
          this.createSuccessMsg("error", res.errors.data.errors);
        }
      }

      this.setState({
        cartLoader: false,
        SubTotal: SubTotal.toFixed(2),
        Parent_item:
          this.state.Parent_item.id === ProductDetails.id
            ? Object.assign({}, ProductDetails)
            : this.state.Parent_item,
        ProductDetails,
        cartItemList,
        disabled: false,
        inputField: false,
      });
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case, containerType) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: this.state.container_type,
    };
    let ProductDetails = this.state.ProductDetails;
    this.setState({ cartLoader: true });
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        // this.FetchCartCount();
        this.FetchCartItemList();
        this.commonFunctionLity(product_id, added_quantity, is_case);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.deleteCartItem(
                formValue.product_id,
                formValue.is_case,
                formValue.containerType
              );
            }
          );
        }
      }
      this.setState({ cartLoader: false, ProductDetails, AddtoCartBtn: true });
    });
  };

  commonFunctionLity = (product_id, added_quantity, is_case) => {
    let PopularProductList = this.state.PopularProductList;
    var Proind = PopularProductList.findIndex((p) => p.id === product_id);

    if (Proind > -1 && added_quantity > -1) {
      var added_qtyInd =
        PopularProductList[Proind]["added_quantity_is_case"].indexOf(is_case);
      if (added_qtyInd > -1) {
        PopularProductList[Proind]["added_quantity"][added_qtyInd] =
          added_quantity;
      } else if (added_quantity > 0) {
        PopularProductList[Proind]["added_quantity"].push(added_quantity);
        PopularProductList[Proind]["added_quantity_is_case"].push(is_case);
      } else {
        PopularProductList[Proind]["added_quantity"].push(0);
        PopularProductList[Proind]["added_quantity_is_case"].push(is_case);
      }
      this.setState({ PopularProductList: PopularProductList });
    }
  };

  commonFunctionForContainer = (
    added_qtyInd,
    added_quantity,
    added_quantity_is_case
  ) => {
    let ProductDetails = this.state.ProductDetails;
    let container_type = this.state.container_type;
    let pro_details_ind = this.state.pro_details_ind;
    if (added_qtyInd > -1) {
      let added_ind = added_qtyInd;
      if (container_type) {
        var container_typeInd =
          ProductDetails["container_type"].indexOf(container_type);
        if (container_typeInd > -1) {
          ProductDetails["added_quantity"][container_typeInd] = added_quantity;
          pro_details_ind = container_typeInd;
        } else {
          ProductDetails["container_type"].push(container_type);
          ProductDetails["added_quantity"].push(added_quantity);
          ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
          pro_details_ind = ProductDetails["container_type"].length - 1;
        }
      } else {
        if (added_quantity === 0) ProductDetails["added_quantity"] = [];
        else ProductDetails["added_quantity"][added_ind] = added_quantity;
        pro_details_ind = added_qtyInd;
      }
    } else {
      ProductDetails["added_quantity"].push(added_quantity);
      ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
      if (container_type !== "") {
        ProductDetails["container_type"].push(container_type);
      }

      pro_details_ind = ProductDetails["added_quantity"].length - 1;
    }

    this.setState({
      OpenQtyCount: true,
      AddtoCartBtn: false,

      pro_details_ind,
      ProductDetails,
    });
  };

  UpdateCartQty = (
    values,
    added_quantity,
    added_quantity_is_case,
    container_type,
    icon_type
  ) => {
    if (added_quantity >= 1) {
      let product_id = values.id;
      let quantity = added_quantity;
      let is_case = added_quantity_is_case;
      let note = values.note[0];
      this.UpdateCartItemFunctionlity(
        product_id,
        quantity,
        is_case,
        note,
        container_type,
        icon_type
      );
    } else {
      this.deleteCartItem(
        values.id,
        added_quantity,
        added_quantity_is_case,
        container_type
      );
    }
  };

  openAddCount = (
    product_id,
    added_quantity,
    is_case,
    categoryindex,
    addTocartQty = 1,
    is_parent
  ) => {
    added_quantity = parseInt(added_quantity);
    addTocartQty = parseInt(addTocartQty);
    if (added_quantity === 1) {
      this.AddToCartFunctionlity(
        product_id,
        added_quantity,
        is_case,
        "Frequently"
      );
      // }
    } else if (added_quantity > 1) {
      this.UpdateCartItemFunctionlity(
        product_id,
        added_quantity,
        is_case,
        "",
        "",
        "plus",
        "Frequently"
      );
    } else {
      this.deleteCartItem(
        product_id,
        added_quantity,
        is_case,
        this.state.container_type
      );
    }
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let cartItemList = [];
      let CartCount = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchCartItemList();
          });
        }
      } else if (res.data && res.data.result) {
        cartItemList = res.data.result;

        // const promises = cartItemList.map((ele) => {
        //   let localUrl = ele?.cloudinary_images_local;
        //   let globalUrl = ele?.cloudinary_images_global;
        //   let currentUrl =
        //     ele.image !== null
        //       ? ele.image[0] === "/"
        //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
        //         : ele.image
        //       : NO_IMAGE;

        //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     ele.image = url.url;
        //     ele.valid = url.valid;
        //     return ele;
        //   });
        // });
        // Promise.all(promises).then((updatedCartItems) => {
        if (cartItemList && cartItemList.length > 0) {
          for (let i = 0; i < cartItemList.length; i++) {
            SubTotal += Number(cartItemList[i].total_price);
          }
        }

        if (res.data.total > 0) {
          CartCount.item = res.data.total;
          CartCount.price = Number(res.data.total_subtotal).toFixed(2);
        }

        this.setState({
          cartItemList: cartItemList,
          SubTotal: SubTotal.toFixed(2),
          cartListOpen: false,
          cartLoader: false,
          CartCount: CartCount,
        });
        // });
      }
      this.setState({
        cartListOpen: false,
        cartLoader: false,
      });
    });
  };

  bttnToggle = () => {
    this.setState({ cartListOpen: !this.state.cartListOpen });
  };

  preProductItem = (pre_info, next_info) => {
    let index = this.state.selectedProductindex;
    let Pro_id = this.state.ProductList[index - 1];
    let next_slug = this.state.ProductList[index - 1]?.slug;
    if (index === -1) {
      pre_info = false;
    } else pre_info = true;
    if (index === this.state.total) next_info = false;
    else next_info = true;
    if (index === 1) {
      this.setState({
        AddtoCartBtn: true,
        selectedProductindex: index - 1,
        is_prev_rec: false,
        is_next_rec: next_info,
      });
    } else
      this.setState({
        AddtoCartBtn: true,
        selectedProductindex: index - 1,
        is_prev_rec: pre_info,
        is_next_rec: next_info,
      });
    this.FetchProdutDetails(
      Pro_id,
      next_slug,
      next_info,
      pre_info,
      this.state.special_filter,
      this.state.special_filter_value
    );
  };

  nextProductItem = (pre_info, next_info) => {
    let index = this.state.selectedProductindex;
    if (index === this.state.total - 1) {
      this.setState({ is_next_rec: false });
    } else {
      if (
        20 * this.state.page < this.state.total &&
        index === 20 * this.state.page - 1
      ) {
        let nextpage = this.state.page + 1;
        this.setState({ page: nextpage });
        this.FetchProductList();
      } else {
        let Pro_id = this.state.ProductList[index + 1];
        let next_slug = this.state.ProductList[index + 1]?.slug;
        if (index === this.state.total - 2) next_info = false;
        if (index === -1) {
          pre_info = false;
        } else pre_info = true;
        this.setState({
          AddtoCartBtn: true,
          selectedProductindex: index + 1,
          is_next_rec: next_info,
          is_prev_rec: pre_info,
        });
        this.FetchProdutDetails(
          Pro_id,
          next_slug,
          next_info,
          pre_info,
          this.state.special_filter,
          this.state.special_filter_value
        );
      }
    }
  };

  HandelChangeFalseWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    this.setState({ isLogin: login_status });
    let ProductDetails = this.state.ProductDetails;
    if (ProductDetails.id === product_id) {
      ProductDetails.wishlist = value;
    }
    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      // this.props.history.push({
      //   pathname: "/login",
      //   state: "details_page",
      // });
      this.OpenLoginPoup();
    }
  };

  HandelChangeTrueWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    let ProductDetails = this.state.ProductDetails;
    if (ProductDetails.id === product_id) {
      ProductDetails.wishlist = value;
    }

    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      // this.props.history.push({
      //   pathname: "/login",
      //   state: "details_page",
      // });
      this.OpenLoginPoup();
    }
  };

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {});
  };

  AddCountForcartList = (
    product_id,
    added_quantity,
    is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let cartItemList = this.state.cartItemList;
    let ProductDetails = this.state.ProductDetails;

    if (icons === "icon-plus") {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = cartItemList[i].qty + 1;
          if (ProductDetails.id === product_id) {
            var added_qtyInd =
              ProductDetails["added_quantity_is_case"].indexOf(is_case);

            ProductDetails.added_quantity[added_qtyInd] =
              ProductDetails.added_quantity[added_qtyInd] + 1;
          }

          this.UpdateCartItemFunctionlity(
            product_id,
            cartItemList[i].qty,
            cartItemList[i].is_case,
            cartItemList[i].note,
            cartItemList[i].container_type,
            icons
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = cartItemList[i].qty - 1;
          if (ProductDetails.id === product_id) {
            var added_qtyInd =
              ProductDetails["added_quantity_is_case"].indexOf(is_case);
            ProductDetails.added_quantity[added_qtyInd] =
              ProductDetails.added_quantity[added_qtyInd] - 1;
          }
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              cartItemList[i].note,
              cartItemList[i].container_type,
              icons
            );
          } else {
            this.deleteCartItem(
              product_id,
              added_quantity,
              is_case,
              this.state.container_type
            );
            SubTotal -= Number(cartItemList[i].total_price);
            cartItemList.splice(i, 1);
            this.setState({ SubTotal: SubTotal });
          }
        }
      }
    } else {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = added_quantity
            ? added_quantity
            : cartItemList[i].qty;
          if (ProductDetails.id === product_id) {
            var added_qtyInd =
              ProductDetails["added_quantity_is_case"].indexOf(is_case);
            ProductDetails.added_quantity[added_qtyInd] = added_quantity;
          }
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              cartItemList[i].note,
              cartItemList[i].container_type,
              icons
            );
          } else {
            this.deleteCartItem(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              cartItemList[i].container_type
            );
          }
        }
      }
    }
    this.setState({ cartItemList: cartItemList, headerinputField: false });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    this.HitGuestApiToken();

    this.props.history.push({
      pathname: "/",
    });
  };

  redirectToCartPage = () => {
    this.props.history.push({
      pathname: "/cart",
    });
  };

  backToParentProduct = (category_id) => {
    let categoryData = this.state.categoryData;
    let categoryIds = [];

    for (let i = 0; i < categoryData.length; i++) {
      categoryIds.push(categoryData[i].category_id);
    }
    let checkCategoryID = categoryIds.findIndex((p) => p === category_id);

    if (checkCategoryID >= 0) {
      categoryIds.splice(checkCategoryID + 1, 1);
    }
    this.props.history.push({
      pathname: "/listing",
      state: {
        pageName: "details_page",
        category_id: category_id,
        CategoryDetails: this.state.CategoryDetails,
        parent_onlyid: categoryIds,
      },
    });
  };

  handleChangeCheckbox = (brand_ids, brand_name) => {
    let BrandProduct = this.state.BrandProduct;
    let brandName = this.state.brandName;
    let checkBrandID = BrandProduct.findIndex((p) => p.brand_id === brand_ids);
    if (checkBrandID > -1) {
      BrandProduct[checkBrandID]["checkboxChecked"] =
        !BrandProduct[checkBrandID]["checkboxChecked"];
      // BrandProduct[checkBrandID]["checkboxChecked"] = true
    }
    this.setState({ BrandProduct: BrandProduct });
    const selectedIndex = this.state.brandIds.indexOf(brand_ids);
    let newSelected = this.state.brandIds;
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(brand_ids);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    const selectedIndexbrandName = brandName.findIndex(
      (p) => p.brand_id === brand_ids
    );
    if (selectedIndexbrandName === -1) {
      let obj = {
        brand_id: brand_ids,
        brand_name: brand_name,
      };
      brandName.push(obj);
    } else {
      brandName.splice(selectedIndexbrandName, 1);
    }
    this.props.history.push({
      pathname: "/listing",
      state: {
        brand_id: brand_ids,
        brand_name: brand_name,
      },
    });
    this.setState({
      brandIds: newSelected,
      brandName: brandName,
      ProductList: [],
      page: 1,
      product_deal: "",
      special_filter: "brand",
      special_filter_value: brand_ids,
    });
  };

  handleChangeCheckboxForProductType = (productType) => {
    let fruitType = this.state.fruitType;
    let checkBrandID = fruitType.findIndex(
      (p) => p.product_type === productType
    );
    fruitType[checkBrandID]["checkboxChecked"] =
      !fruitType[checkBrandID]["checkboxChecked"];
    this.setState({ fruitType: fruitType });
    const selectedIndex = this.state.filterByType.indexOf(productType);
    let newProducTypeSelected = [];
    if (selectedIndex === -1) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType,
        productType
      );
    } else if (selectedIndex === 0) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(1)
      );
    } else if (selectedIndex === this.state.filterByType.length - 1) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(0, selectedIndex)
      );
    }
    this.setState({
      filterByType: newProducTypeSelected,
      ProductList: [],
      page: 1,
    });
  };

  handleChangeCheckboxForFlover = (flavorType) => {
    let flavor = this.state.flavor;
    let checkBrandID = flavor.findIndex((p) => p.flavor === flavorType);
    flavor[checkBrandID]["checkboxChecked"] =
      !flavor[checkBrandID]["checkboxChecked"];
    this.setState({ flavor: flavor });
    const selectedIndex = this.state.filterByFlavor.indexOf(flavorType);
    let newFlavorSelected = [];
    if (selectedIndex === -1) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor,
        flavorType
      );
    } else if (selectedIndex === 0) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor.slice(1)
      );
    } else if (selectedIndex === this.state.filterByFlavor.length - 1) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      this.state.filterByFlavor.splice(selectedIndex, 1);
      newFlavorSelected = this.state.filterByFlavor;
    }
    this.setState({
      filterByFlavor: newFlavorSelected,
      ProductList: [],
      page: 1,
    });
  };

  handleChangeCheckboxForKosher = (kosherType) => {
    let kosher = this.state.kosher;
    let checkBrandID = kosher.findIndex((p) => p.kosher === kosherType);
    kosher[checkBrandID]["checkboxChecked"] =
      !kosher[checkBrandID]["checkboxChecked"];
    this.setState({ kosher: kosher });
    const selectedIndex = this.state.filterByKosher.indexOf(kosherType);
    let newKosherSelected = [];
    if (selectedIndex === -1) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher,
        kosherType
      );
    } else if (selectedIndex === 0) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(1)
      );
    } else if (selectedIndex === this.state.filterByKosher.length - 1) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(0, selectedIndex)
      );
    }
    this.setState({
      filterByKosher: newKosherSelected,
      ProductList: [],
      page: 1,
    });
  };

  FetchDataForPriceRange = (price_min, price_max, category_id, has_child) => {
    let brandIds = [];
    let filterByKosher = [];
    let filterByFlavor = [];
    if (price_max > 0) {
      brandIds = this.state.brandIds;
      filterByKosher = this.state.filterByKosher;
      filterByFlavor = this.state.filterByFlavor;
    }
    this.setState({
      min_price: price_min,
      max_price: price_max,
      category_id: category_id,
      ProductList: [],
      brandIds: brandIds,
      brandName: [],
      filterByFlavor: filterByFlavor,
      flavor: [],
      kosher: [],
      filterByKosher: filterByFlavor,
      page: 1,
    });
  };

  createSuccessMsg = (type, msg) => {
    console.log(type, msg);

    if (type === "error") {
      if (msg.quantity && msg.quantity) {
        let ProductDetails = this.state.ProductDetails;
        let pro_details_ind = this.state.pro_details_ind;
        ProductDetails.note[pro_details_ind] = "";
        sweetAlertBox(
          "Attention!!",
          msg.quantity[0] === "This field is required."
            ? "Atleast 1 Quantity is required"
            : msg.quantity[0],
          "info"
        );
        this.setState({
          // error:
          //   msg.quantity[0] === "This field is required."
          //     ? "Atleast 1 Quantity is required"
          //     : msg.quantity[0],
          // success: false,
          ProductDetails,
          productNoteItemNote: "",
          backupInstraction: "",
        });
      } else if (msg.container_type && msg.container_type) {
        this.setState({
          error: msg.container_type[0],
          success: false,
        });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  ProductCaseFunctionlity = (added_qty_is_case, note) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_details_ind = this.state.pro_details_ind;

    var check_ind =
      ProductDetails.added_quantity_is_case.indexOf(added_qty_is_case);
    if (check_ind > -1) {
      pro_details_ind = check_ind;
    } else {
      pro_details_ind = -1;
    }
    this.setState({ is_case_check: added_qty_is_case, pro_details_ind });
  };

  ProductCaseFunctionlityForFrequentBrought = (product_id, is_case) => {
    let chack_is_case = this.state.chack_is_case;
    chack_is_case[product_id] = is_case;
    this.setState({ chack_is_case: chack_is_case });
  };

  handleKeyDown = (event, value, note_value) => {
    if (event.key === "Enter") {
      let ProductDetails = this.state.ProductDetails;
      let cartItemList = this.state.cartItemList;
      let checkProductID = cartItemList.findIndex(
        (p) => p.product_id === ProductDetails.id
      );
      if (ProductDetails.note != note_value) {
        ProductDetails.note = note_value;
        let product_id;
        let quentiry;
        let is_case;
        let index = "";
        if (value.length > 0) {
          product_id = cartItemList[checkProductID]["product_id"];
          quentiry = cartItemList[checkProductID]["qty"];
          is_case = cartItemList[checkProductID]["is_case"];
        } else {
          product_id = value.id;
          quentiry = value.added_quantity;
          is_case = value.added_quantity_is_case;
        }
        this.UpdateCartItemFunctionlity(
          product_id,
          quentiry,
          is_case,
          note_value,
          index
        );
        this.setState({ cartItemList, ProductDetails, note: note_value });
      }
    }
  };

  addQuentityForaddTocart = (
    targetValue,
    added_quantity_is_case,
    container_type,
    icon_type = ""
  ) => {
    targetValue = parseInt(targetValue);
    let ProductDetails = this.state.ProductDetails;
    let pro_details_ind = this.state.pro_details_ind;
    if (!targetValue) {
      this.setState({ inputField: true });
      var el = document.getElementById(`${ProductDetails.id}`);
      el.value = "";
      var event = new Event("input", { target: el, bubbles: true });
      return el ? el.dispatchEvent(event) : false;
    }

    let result;
    if (targetValue >= 0) {
      result = CommonAddQtyFunction(
        ProductDetails,
        targetValue,
        added_quantity_is_case
      );
      if (result) {
        ProductDetails = result;
        pro_details_ind = 0;
        this.UpdateCartQty(
          result,
          targetValue,
          added_quantity_is_case,
          container_type,
          icon_type
        );
      }
    }
    this.setState({ ProductDetails: ProductDetails, pro_details_ind });
  };

  changeInputBoxValue = () => {
    this.setState({ inputField: false });
  };

  OpenDialog = () => {
    if (
      this.state.ProductDetails.added_quantity[this.state.pro_details_ind] ===
      undefined
    )
      sweetAlertBox("Attention!!", "Atleast 1 Quantity is required", "info");
    else this.setState({ setOpen: true, productNoteItemNote: "" });
  };
  CloseDialog = (value_icon) => {
    let productNote = this.state.productNoteItemNote;
    let mainNote = this.state.ProductDetails.note[0];

    if (value_icon === "Cancel") {
      this.setState({ setOpen: false });
    } else {
      if (
        productNote === "" &&
        this.state.doNotSubstitute === false &&
        this.state.substituteWithSimilar === false &&
        this.state.callMe === false
      ) {
        this.createSuccessMsg("error", "Please enter product note");
      } else {
        mainNote =
          mainNote === "" || mainNote === null ? "" : mainNote.split("~");
        if (mainNote.length > 1) {
          if (productNote !== "") {
            mainNote[0] = productNote;
          }

          if (this.state.doNotSubstitute) {
            mainNote[1] = "Do not substitute";
          } else if (this.state.substituteWithSimilar)
            mainNote[1] = "Substitute with similar";
          else if (this.state.callMe) mainNote[1] = "Call me";
          else mainNote.pop();
          mainNote = mainNote.join("~");
        } else {
          if (productNote !== "") {
            mainNote = productNote;
          }
          if (this.state.doNotSubstitute)
            mainNote = mainNote + "~" + "Do not substitute";
          else if (this.state.substituteWithSimilar)
            mainNote = mainNote + "~" + "Substitute with similar";
          else if (this.state.callMe) mainNote = mainNote + "~" + "Call me";
        }

        this.state.ProductDetails.note[this.state.pro_details_ind] = mainNote;
        this.UpdateCartItemFunctionlity(
          this.state.ProductDetails.id,
          this.state.ProductDetails.added_quantity[this.state.pro_details_ind],
          this.state.ProductDetails.added_quantity_is_case[
            this.state.pro_details_ind
          ],
          this.state.ProductDetails.note[this.state.pro_details_ind],
          this.state.ProductDetails.container_type,
          "icon"
        );
        this.setState({
          setOpen: false,
          doNotSubstitute: false,
          substituteWithSimilar: false,
          callMe: false,
        });
      }
    }
  };

  prodcutNote = (event) => {
    this.setState({ productNoteItemNote: event.target.value });
  };
  backupInstractTion = (value) => {
    let doNotSubstitute = this.state.doNotSubstitute;
    let substituteWithSimilar = this.state.substituteWithSimilar;
    let callMe = this.state.callMe;
    let backupInstraction = this.state.backupInstraction;
    if (value === "Do not substitute") {
      doNotSubstitute = !doNotSubstitute;
      substituteWithSimilar = false;
      callMe = false;
    } else if (value === "Substitute with similar") {
      substituteWithSimilar = !substituteWithSimilar;
      doNotSubstitute = false;
      callMe = false;
    } else {
      callMe = !callMe;
      substituteWithSimilar = false;
      doNotSubstitute = false;
    }
    if (doNotSubstitute === true) {
      backupInstraction = "Do not substitute";
    } else if (substituteWithSimilar === true) {
      backupInstraction = "Substitute with similar";
    } else if (callMe === true) {
      backupInstraction = "Call me";
    } else {
      backupInstraction = "";
    }
    this.setState({
      backupInstraction,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
    });
  };

  onEditModalOpen = (value) => {
    let backupItemInstraction = this.state.backupItemInstraction;
    let productNoteItemNote = this.state.productNoteItemNote;
    let doNotSubstitute = this.state.doNotSubstitute;
    let substituteWithSimilar = this.state.substituteWithSimilar;
    let callMe = this.state.callMe;

    productNoteItemNote = this.state.ProductDetails.note[0].split("~")[0];
    let selectBacknote = this.state.ProductDetails.note[0].split("~")[1];
    if (selectBacknote === "Do not substitute") {
      doNotSubstitute = true;
    } else {
      doNotSubstitute = false;
    }
    if (selectBacknote === "Substitute with similar") {
      substituteWithSimilar = true;
    } else {
      substituteWithSimilar = false;
    }

    if (selectBacknote === "Call me") {
      callMe = true;
    } else {
      callMe = false;
    }
    this.setState({
      setOpen: true,
      doNotSubstitute,
      substituteWithSimilar,
      callMe,
      productNoteItemNote: productNoteItemNote,
      backupItemInstraction,
    });
  };

  addFavoiteLevelFunctionlity = (favoriteLevel, product_id) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          this.FetchFavoriteLevelList(product_id);
        }
      }
    );
  };
  removeFromfavouriteProduct = (favoriteproduct_id, product_id) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.join(","),
    };
    let ProductDetails = this.state.ProductDetails;
    RemoveFavouriteProduct(formValues, this.props.dispatch).then((res) => {
      ProductDetails.wishlist = false;
      ProductDetails.favoriot_id = [];
      this.createSuccessMsg("success", res.message);
      this.setState({ ProductDetails: ProductDetails });
    });
  };

  addFavoiteProductFunctionlity = (favoritelabel_id, product_id) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_details_ind = this.state.pro_details_ind;

    if (favoritelabel_id !== "") {
      let formValues = {
        product_id: ProductDetails.id,
        favoritelabel_id: favoritelabel_id ? favoritelabel_id.toString() : "",
        quantity: ProductDetails.added_quantity[pro_details_ind]
          ? ProductDetails.added_quantity[pro_details_ind]
          : 0,
        is_case: ProductDetails.added_quantity_is_case[pro_details_ind]
          ? ProductDetails.added_quantity_is_case[pro_details_ind]
          : false,
        note: ProductDetails.note[pro_details_ind]
          ? ProductDetails.note[pro_details_ind]
          : "",
        container_type: this.state.container_type
          ? this.state.container_type
          : "",
      };
      AddFavoriteProductFunctionlity(formValues, this.props.dispatch).then(
        (res) => {
          if (res.errors && res.errors.status === 400) {
            this.createSuccessMsg("error", res.errors.data.errors);
            ProductDetails.wishlist = false;
          } else {
            if (ProductDetails.id === product_id && favoritelabel_id != "") {
              ProductDetails.wishlist = true;
            } else {
              ProductDetails.wishlist = false;
            }
            ProductDetails.favoriot_id = res.favorite_ids;
            this.createSuccessMsg("success", res.message);
          }
          this.setState({ ProductDetails: ProductDetails });
        }
      );
    }
  };

  FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: product_id,
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelData = [];
        if (res.data && res.data.result) {
          favoriteLevelData = res.data.result;
        }
        this.setState({ favoriteLevelData: favoriteLevelData });
      }
    );
  };

  handleInnerCloseDialog() {
    this.setState({ open_inner: false, open_subDetails: false });
  }

  changeConatinerType = (event, type, myContainer = null) => {
    let text = "";
    var el = document.getElementById("exampleFormControlSelect1");
    if (type === "select") {
      text = el.options[el.selectedIndex].innerHTML;
    } else {
      text = myContainer;
    }
    let ProductDetails = this.state.ProductDetails;
    let pro_details_ind = this.state.pro_details_ind;
    let is_case_check = this.state.is_case_check;
    let AddtoCartBtn = this.state.AddtoCartBtn;
    var container_ind = ProductDetails.container_type.indexOf(text);
    if (container_ind > -1) {
      if (
        ProductDetails.added_quantity_is_case[container_ind] === is_case_check
      ) {
        pro_details_ind = container_ind;
      } else {
      }
      AddtoCartBtn = false;
    } else {
      pro_details_ind = -1;

      AddtoCartBtn = true;
    }
    this.setState({
      containerValue: event.target.value,
      ProductDetails,
      container_type: text,
      pro_details_ind: pro_details_ind,
      AddtoCartBtn: AddtoCartBtn,
    });
  };

  handlePopOpen = (e, value, slug, next_info, pre_info) => {
    this.setState({ itemDetails: true });

    this.FetchProdutDetails(
      e,
      value,
      next_info,
      pre_info,
      this.state.special_filter,
      this.state.special_filter_value
    );
  };

  handlePopClose(value) {
    this.setState({
      itemDetails: false,
      PopularProductList: [],
      chack_is_case: [],
    });
    let product_id = 0;
    let ProductName;
    if (this.props.location && this.props.location.search) {
      const value = this.props.location.search;
      const getPathname = this.props.location.pathname.split("/product/");
      const replaceProductName = getPathname[1].replaceAll("-", " ");
      ProductName = this.titleCase(replaceProductName);
      product_id = value.product_id;
    } else {
      const getItemName = this.props.location.pathname.split("/product/");
      ProductName = getItemName[1];
    }
    this.FetchPopularProductList();
    this.FetchProdutDetails(
      product_id,
      ProductName,
      this.state.next_info,
      this.state.pre_info,
      "",
      ""
    );
  }

  ProductCaseFunctionlityForItem = (added_qty_is_case, note) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_details_ind = this.state.pro_details_ind;
    var check_ind =
      ProductDetails.added_quantity_is_case.indexOf(added_qty_is_case);
    if (check_ind > -1) {
      pro_details_ind = check_ind;
    } else {
      pro_details_ind = -1;
    }
    this.setState({ chack_is_case: added_qty_is_case, pro_details_ind });
  };

  redirectToDetailsPage = (
    product_id,
    slag,
    special_filter,
    special_filter_value
  ) => {
    this.setState({ itemDetails: false, chack_is_case: [] });
    this.FetchProdutDetails(
      product_id,
      slag,
      this.state.next_info,
      this.state.pre_info,
      special_filter,
      special_filter_value
    );
    this.FetchPopularProductList();
    this.FetchCartItemList();
  };

  handleChangeRouteForDeal = (deal_name) => {
    this.props.history.push({
      pathname: "/listing/productdeal=" + deal_name,
      state: {
        deal_name: deal_name,
      },
    });
  };

  hendleAddtoCartBtnForListing = () => {
    this.setState({ AddtoCartBtn: true });
  };
  redirectToback = () => {
    this.props.history.push({
      // pathname: `/listing/${this.props.history.location.state.category_id}`,
      pathname: `/listing/${this.state.category_id}`,
    });
  };
  closeisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: false });
  };
  openisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: true });
  };
  changeChilditemdata = (event, type, myContainer = null) => {
    let text = "";
    var el = document.getElementById("exampleFormControlSelect2");
    let pro_details_ind = this.state.pro_details_ind;
    if (type === "select") {
      text = event.target.value;
    } else {
      text = myContainer;
    }
    let status = false;

    if (text !== "") {
      this.state.child_items.forEach((element) => {
        if (element.id.toString() === text) {
          status = true;
          let data = this.state.ProductDetails;
          for (var key of Object.keys(this.state.ProductDetails)) {
            if (key !== "name" && key !== "is_parent") {
              data[key] = element[key];
            }
          }
          var is_checked = false;

          if (data.added_quantity.length > 0) {
            if (data.added_quantity[0] !== 0) {
              pro_details_ind = 0;
              is_checked = data.added_quantity_is_case[0];
            } else {
              data.added_quantity = [0];
              data.added_quantity_is_case = [0];
              data.container_type = [];
              pro_details_ind = 0;
            }
          }
          this.setState({
            ProductDetails: Object.assign({}, data),
            selectedChilditem: event.target.value,
            pro_details_ind: pro_details_ind,
          });
        }
      });

      if (status === false) {
        this.setState({
          ProductDetails: Object.assign({}, this.state.Parent_item),
          selectedChilditem: "",
          pro_details_ind:
            this.state.Parent_item.added_quantity.length > 0
              ? 0
              : pro_details_ind,
        });
      }
    } else {
      this.setState({
        ProductDetails: Object.assign({}, this.state.Parent_item),
        selectedChilditem: "",
        pro_details_ind:
          this.state.Parent_item.added_quantity.length > 0
            ? 0
            : pro_details_ind,
      });
    }
  };
  FetchProductList = () => {
    let formValues = this?.props?.location?.state?.listingFormValues;
    formValues.page = this.state.page;
    ProductsListListingPage(formValues, this.props.dispatch).then((res) => {
      let productList = [];
      let categoryData = [];
      let productlistLength = 0;
      let parent_onlyid = [];
      let total = undefined;
      if (res.data && res.data.result && res.data.result.length > 0) {
        productList = res.data.result;
        productlistLength = res.data.length;
        total = res.data.total;
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.setState({ productList: "", productlistLength: 0, total: 0 });
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchProductList();
          });
        }
      }
      if (res.data && res.data.cat_data) {
        categoryData = res.data.cat_data;
      }
      for (let i = 0; i < categoryData.length; i++) {
        parent_onlyid.push(categoryData[i].category_id);
      }
      this.setState({
        ProductList: [...this.state.ProductList, ...productList],
        total: total,
        listingFormValues: formValues,
      });
      // if(this.state.is_next_rec)
      // this.nextProductItem(false,true)
    });
  };
  toggleMenuCategory() {
    this.setState({ toggleMenuCategory: !this.state.toggleMenuCategory });
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  checkingImageUrl = async (data, currentImage) => {
    let localUrl = data?.cloudinary_images_local;

    let globalUrl = data?.cloudinary_images_global;

    let globalPromises = [];
    let localPromises = [];

    for (let i = 0; i < localUrl?.length; i++) {
      let finalLocalUrl = localUrl[i];
      localPromises.push(
        SinglecheckImageUrls(finalLocalUrl).then((isValid) => {
          if (isValid === false && i === 0) return 1;
          if (isValid) {
            return {
              url: finalLocalUrl,
              name: i === 0 ? data.item_code : `${data.item_code}_${i}`,
            };
          } else {
            return null;
          }
        })
      );
    }
    for (let i = 0; i < globalUrl.length; i++) {
      let finalGlobalUrl = globalUrl[i];
      globalPromises.push(
        SinglecheckImageUrls(finalGlobalUrl).then((isValid) => {
          console.log(finalGlobalUrl, isValid);
          if (isValid) {
            return {
              url: finalGlobalUrl,
              name: i === 0 ? data.item_code : `${data.item_code}_${i}`,
            };
          } else {
            return null;
          }
        })
      );
    }

    const globalResults = await Promise.all(globalPromises);
    const localResults = await Promise.all(localPromises);

    let localData = [];
    const globalData = globalResults.filter((result) => result !== null);
    if (localResults.some((x) => x === 1)) localData = [];
    else localData = localResults.filter((result) => result !== null);
    console.log("Global Data:", globalData);
    console.log("Local Data:", localData);
    let iscloudinary = false;
    let allUrl = [];
    if (localData.length > 0) {
      allUrl = localData.map((x) => x.url);
      iscloudinary = true;
    } else if (globalData.length > 0) {
      allUrl = globalData.map((x) => x.url);
      iscloudinary = true;
    } else {
      allUrl = currentImage?.split(",");
      iscloudinary = false;
    }
    console.log(allUrl);
    this.setState({ ProductImages: allUrl, iscloudinary });
  };
  render() {
    const {
      ProductDetails,
      CategoryDetails,
      PopularProductList,
      SubTotal,
      categoryID,
      CartCount,
      productDetailsLoader,
      fruitType,
      flavor,
      kosher,
      BrandProduct,
      categoryData,
      TreeViewIteams,
      error,
      setOpen,
      CategoryOption,
      favoriteLevelData,
      containerValue,
      categoryNames,
      isShowItemCode,
      web_customize,
    } = this.state;
    let ProductImages;

    // if (this.state.ProductDetails?.cloudinary_images?.length > 0) {
    //   ProductImages = this.state.ProductDetails.cloudinary_images;
    // } else
    if (this.state.ProductDetails && this.state.ProductDetails.image) {
      ProductImages = this.state.ProductDetails.image
        .split(",")
        .filter((x) => x !== "");
      if (ProductImages.length === 0) ProductImages = [NO_IMAGE];
    } else {
      ProductImages = [NO_IMAGE];
    }
    let optionTemplate = [];

    if (this.state.container && this.state.container.length > 0) {
      optionTemplate = this.state.container.map((v) => (
        <option value={v.value} data-tag={v.label}>
          {v.label}
        </option>
      ));
    }

    return (
      <div
        className={
          this.state.cartListOpen === true
            ? "main-content  close_sidenav"
            : "main-content"
        }
      >
        <Header
          CategoryDetails={
            CategoryDetails && CategoryDetails.length > 0 ? CategoryDetails : ""
          }
          CategoryOption={CategoryOption}
          cartItemList={this.state.cartItemList}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          ProductName={this.state.ProductName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchProdutDetails={(product_id, slug) =>
            this.FetchProdutDetails(product_id, slug, false, false, "", "")
          }
          FetchCartItemList={() => this.FetchCartItemList()}
          FetchCartCount={() => this.FetchCartItemList()}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index,
            container_type
          ) =>
            this.AddCountForcartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            )
          }
          SideCategoryListLoader={this.state.SideCategoryListLoader}
          SubTotal={SubTotal}
          changeheaderinputFieldValue={() => this.changeheaderinputFieldValue()}
          headerinputField={this.state.headerinputField}
          logo={this.state.logo}
          extraCharges={this.state.extraCharges}
          FetchDataForPriceRange={(
            price_min,
            price_max,
            category_id,
            subcategory_id,
            has_child
          ) =>
            this.FetchDataForPriceRange(
              price_min,
              price_max,
              category_id,
              subcategory_id,
              has_child
            )
          }
          OpenLoginPoup={() => this.OpenLoginPoup()}
          handleSlotNameData={(data) => this.handleSlotName(data)}
          isslotDeliverypopupState_compwill={
            this.state.isslotDeliverypopupState_compwill
          }
          closeisslotDeliverypopupState_compwill={() =>
            this.closeisslotDeliverypopupState_compwill()
          }
          tree_data={this.state.tree_data}
        />
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={(type) => this.CloseLoginDialog(type)}
          isShowclose={true}
          history={this.props.history}
        />
        {CategoryDetails && CategoryDetails.length > 0 ? (
          <CategoriesModal
            open={this.state.open}
            handleOpenDialog={this.handleOpenDialog}
            handleCloseDialog={this.handleCloseDialog}
            CategoryDetails={CategoryDetails}
            history={this.props}
            pageName={this.state.pageName}
            categoryLoader={this.state.categoryLoader}
            tree_data={this.state.tree_data}
          />
        ) : (
          ""
        )}

        <SubCategoryModal
          open_inner={this.state.open_inner}
          SubcategoryName={this.state.SubcategoryName}
          SubSubCategoryName={this.state.SubSubCategoryName}
          handleInnerOpenDialog={this.handleInnerOpenDialog}
          handleInnerCloseDialog={this.handleInnerCloseDialog}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          SubCategoryDetails={this.state.SubCategoryDetails}
          history={this.props.history}
          pageName={this.state.pageName}
          SubSubCategoryDetails={this.state.SubSubCategoryDetails}
          subCategoryLoader={this.state.subCategoryLoader}
          FetchSubCategoryList={(id, level) =>
            this.FetchSubCategoryList(id, level)
          }
          parentIds={this.state.parentIds}
          parent_onlyid={this.state.parent_onlyid}
          parent_level={this.state.parent_level}
          tree_data={this.state.tree_data}
        />

        {SubTotal > 0 ? (
          <div class="mob-det-check d-block d-lg-none">
            <div class="row">
              <div
                class="col-12 cat text-uppercase"
                onClick={() => this.redirectToCartPage()}
              >
                Checkout
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className="main-data"
          style={{
            paddingTop:
              web_customize !== null &&
              web_customize?.top_marquee !== null &&
              web_customize?.top_marquee !== "" &&
              window.screen.availWidth > 900
                ? "11rem"
                : "",
          }}
        >
          <section className="listing_inner_data">
            <div className="listing_main">
              <div className="listing_category">
                {" "}
                {CategoryDetails &&
                  CategoryDetails.length > 0 &&
                  TreeViewIteams.length > 0 &&
                  this.state.parent_onlyid.length > 0 && (
                    <CategoryListing
                      CategoryDetails={CategoryDetails}
                      categoryID={categoryID}
                      fruitType={fruitType}
                      flavor={flavor}
                      kosher={kosher}
                      BrandProduct={BrandProduct}
                      pageName={this.state.pageName}
                      history={this.props.history}
                      Props_value={this.props}
                      TreeViewIteams={this.state.TreeViewIteams}
                      parent_onlyid={this.state.parent_onlyid}
                      handleChangeCheckboxForProductType={(product_type) =>
                        this.handleChangeCheckboxForProductType(product_type)
                      }
                      handleChangeCheckboxForFlover={(flover) =>
                        this.handleChangeCheckboxForFlover(flover)
                      }
                      handleChangeCheckboxForKosher={(kosher) =>
                        this.handleChangeCheckboxForKosher(kosher.trim())
                      }
                      handleChangeCheckbox={(brand_id, brand_name) =>
                        this.handleChangeCheckbox(brand_id, brand_name)
                      }
                      FetchDataForPriceRange={(
                        price_min,
                        price_max,
                        category_id,
                        subcategory_id,
                        has_child
                      ) =>
                        this.FetchDataForPriceRange(
                          price_min,
                          price_max,
                          category_id,
                          subcategory_id,
                          has_child
                        )
                      }
                      toggleMenuCat={() => this.toggleMenuCategory()}
                    />
                  )}
              </div>
            </div>
            {productDetailsLoader ? (
              <div className="main_containerLoader">
                {" "}
                <CustomPreloader />
              </div>
            ) : (
              <div
                className="list_main_content show_sidecart"
                style={{
                  background: "white",
                }}
              >
                <div className="listing_details">
                  <div className="specified_details">
                    <ul>
                      <TypographyTextColor
                        Color="other"
                        component="li"
                        onClick={() => this.handleOpenDialog()}
                      >
                        All
                      </TypographyTextColor>
                      {categoryNames && categoryNames.length > 0
                        ? categoryNames.map((row, index) => {
                            return index === categoryNames.length - 1 ? (
                              <TypographyTextColor
                                Color="heading"
                                component="li"
                                onClick={
                                  index === 2 && categoryNames.length === 3
                                    ? () => this.redirectToback()
                                    : index === 1 && categoryNames.length === 2
                                    ? () => this.redirectToback()
                                    : index === 0 && categoryNames.length === 1
                                    ? () => this.redirectToback()
                                    : () =>
                                        this.handleInnerOpenDialog(
                                          row.category_id,
                                          row.parent_id,
                                          row.category_name,
                                          true,
                                          row.level,
                                          row.cslug,
                                          categoryNames
                                        )
                                }
                              >
                                {row ? row.category_name : ""}
                              </TypographyTextColor>
                            ) : (
                              <TypographyTextColor
                                Color="other"
                                component="li"
                                onClick={
                                  index === 2 && categoryNames.length === 3
                                    ? () => this.redirectToback()
                                    : index === 1 && categoryNames.length === 2
                                    ? () => this.redirectToback()
                                    : index === 0 && categoryNames.length === 1
                                    ? () => this.redirectToback()
                                    : () =>
                                        this.handleInnerOpenDialog(
                                          row.category_id,
                                          row.parent_id,
                                          row.category_name,
                                          true,
                                          row.level,
                                          row.cslug,
                                          categoryNames
                                        )
                                }
                              >
                                {row ? row.category_name : ""}
                              </TypographyTextColor>
                            );
                          })
                        : ""}
                    </ul>
                  </div>
                </div>
                <div className="details_main_desc">
                  <div className="details_main details_main_nav">
                    <div className="navBttns">
                      {this.state.is_prev_rec === true ? (
                        <div
                          className="leftNav"
                          onClick={() => this.preProductItem(true, false)}
                        >
                          <span class="icon-left-arrow"></span>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.is_next_rec === true ? (
                        <div
                          className="rightNav"
                          onClick={() => this.nextProductItem(false, true)}
                        >
                          <span class="icon-left-arrow"></span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="details_img_caro">
                      <div className="likecart">
                        <CreateFavorite
                          addFavoiteLevelFunctionlity={(value, product_id) =>
                            this.addFavoiteLevelFunctionlity(value, product_id)
                          }
                          favoriteLevelData={favoriteLevelData}
                          activePageName={this.props.location.pathname}
                          history={this.props.history}
                          addFavoiteProductFunctionlity={(favoritelabel_id) =>
                            this.addFavoiteProductFunctionlity(
                              favoritelabel_id,
                              ProductDetails.id
                            )
                          }
                          wishlist={ProductDetails.wishlist}
                          product_id={ProductDetails.id}
                          FetchFavoriteLevelList={(product_id) =>
                            this.FetchFavoriteLevelList(product_id)
                          }
                          removeFromfavouriteProduct={(
                            favoriot_id,
                            product_id
                          ) =>
                            this.removeFromfavouriteProduct(
                              favoriot_id,
                              product_id
                            )
                          }
                          favoriot_id={ProductDetails.favoriot_id}
                        />
                      </div>
                      {/* {ProductImages?.length > 0 ? ( */}
                      <ReactSlick
                        ProductImages={ProductImages}
                        ProductDetails={ProductDetails}
                        extraCharges={this.state.extraCharges}
                      />
                      {/* ) : (
                        <></>
                      )} */}
                      {this.state.extraCharges.is_show_out_of_stock === true &&
                      ProductDetails.out_of_stock === true ? (
                        <div
                          className="out-of-stock"
                          style={{ left: 0, top: "50%" }}
                        >
                          <LabelPrimaryColor
                            className="text"
                            component={"span"}
                            border={2}
                            style={{ fontSize: "20px" }}
                          >
                            Out Of Stock
                          </LabelPrimaryColor>
                        </div>
                      ) : this.state.extraCharges.is_show_out_of_stock ===
                          true &&
                        ProductDetails.out_of_stock_case === true &&
                        this.state.is_case_check === true ? (
                        <div
                          className="out-of-stock"
                          style={{ left: 0, top: "50%" }}
                        >
                          <LabelPrimaryColor
                            className="text"
                            component={"span"}
                            border={2}
                            style={{ fontSize: "20px" }}
                          >
                            Out Of Stock
                          </LabelPrimaryColor>
                        </div>
                      ) : (
                        ""
                      )}
                      {ProductDetails.is_special === true ? (
                        <div className="newsalee">
                          <Typography component={SaleLabelySecondaryColor}>
                            SALE{" "}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="details_desc_wrapper"
                      style={{ position: "relative" }}
                    >
                      <div
                        id="portal"
                        className="portal"
                        style={{ position: "absolute", zIndex: 999 }}
                      />
                      <div className="details_head_wrapper">
                        <div
                        // className={
                        //   ProductDetails?.is_ebt===false&&ProductDetails?.brand_name === null &&
                        //   (ProductDetails?.package === null ||
                        //     ProductDetails?.package?.trim() === "" ||
                        //     ProductDetails?.package === "0.0 0" ||
                        //     ProductDetails?.package === "0.0 ")
                        //     ? "details_head"
                        //     : ""
                        // }
                        >
                          {ProductDetails?.sub_description !== "" &&
                          ProductDetails?.sub_description !== null &&
                          ProductDetails?.sub_description !== undefined ? (
                            <div className="item-subdes-container">
                              <TypographyPrimaryBgColorDimmed
                                border={0}
                                component="span"
                                style={{
                                  position: "initial",
                                  padding: "5px 10px",
                                }}
                              >
                                {ProductDetails?.sub_description}
                              </TypographyPrimaryBgColorDimmed>
                            </div>
                          ) : (
                            <></>
                          )}
                          <TextFormating
                            component={"h3"}
                            Color="product"
                            className={
                              ProductDetails &&
                              ProductDetails.brand_name === null
                                ? "product_empty_brand"
                                : "product_empty_brand"
                            }
                          >
                            {ProductDetails?.name !== undefined
                              ? web_customize?.TextTransform?.main === "none"
                                ? ProductDetails.name
                                : ProductDetails.name.toLowerCase()
                              : ""}
                          </TextFormating>
                          <div className="details_type">
                            <TypographyTextColor
                              component="p"
                              Color="other"
                              className="mb-1 brand-name"
                            >
                              {ProductDetails && ProductDetails.brand_name
                                ? ProductDetails.brand_name +
                                  `${
                                    ProductDetails?.package?.trim() !== "" &&
                                    ProductDetails?.package !== "0.0 0" &&
                                    ProductDetails?.package !== "0.0 "
                                      ? " | "
                                      : ""
                                  }`
                                : ""}
                              {ProductDetails &&
                              ProductDetails?.package !== "0.0 0" &&
                              ProductDetails?.package !== "0.0 " &&
                              this.props.itemData?.package?.trim() !== ""
                                ? ProductDetails?.package?.replace(
                                    /\.0(?!\d)/g,
                                    ""
                                  )
                                : ""}
                              {(ProductDetails.package?.trim() !== "" ||
                                ProductDetails.brand_name !== null) &&
                              isShowItemCode
                                ? " | "
                                : ""}
                              {isShowItemCode ? (
                                ProductDetails.item_code
                              ) : (
                                <></>
                              )}
                              {/* {(ProductDetails.package?.trim() !== "" ||
                        ProductDetails.brand_name !== null) &&
                      ProductDetails.is_ebt === true 
                        ? " | "
                        : ""}
                      {ProductDetails.is_ebt === true ? " EBT" : ""} */}
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                      {this.state.child_items &&
                      this.state.child_items.length > 0 &&
                      ProductDetails.is_parent &&
                      this.state.selectedChilditem === "" ? (
                        <></>
                      ) : (
                        <div
                          className={`${
                            window.screen.availWidth < 900
                              ? "d-flex justify-content-between align-items-center"
                              : ""
                          } details_pricerow`}
                        >
                          <div>
                            {ProductDetails &&
                            ProductDetails.availableondays ? (
                              <h7 style={{ color: "#f57f18" }}>
                                {this.state.msgstr}
                              </h7>
                            ) : (
                              ""
                            )}
                            {ProductDetails.original_price !== 0 ? (
                              <Typography
                                component={
                                  ProductDetails.is_special === true
                                    ? TypographySecondaryColor
                                    : TypographyTextColor
                                }
                                Fonttype="other"
                                className={
                                  ProductDetails.is_by_weight === true &&
                                  ProductDetails.estimate_weight_per_piece > 0
                                    ? "details_pricerow_height"
                                    : ""
                                }
                                Color="other"
                              >
                                {this.state.is_case_check === true
                                  ? "$" +
                                    Number(ProductDetails.case_price).toFixed(2)
                                  : ProductDetails.is_special === true
                                  ? ProductDetails.special_quantity > 0
                                    ? ProductDetails.special_quantity +
                                      "/$" +
                                      Number(
                                        ProductDetails.special_price
                                      ).toFixed(2)
                                    : "$" +
                                      Number(
                                        ProductDetails.special_price
                                      ).toFixed(2)
                                  : "$" +
                                    Number(ProductDetails.today_price).toFixed(
                                      2
                                    )}{" "}
                                {ProductDetails.is_special === true ? (
                                  <TypographyTextColor
                                    component="span"
                                    Color="other"
                                  >
                                    {"$" +
                                      Number(
                                        ProductDetails.today_price
                                      ).toFixed(2)}
                                  </TypographyTextColor>
                                ) : (
                                  ""
                                )}
                                {ProductDetails &&
                                ProductDetails.is_by_weight === true &&
                                this.state.is_case_check === false
                                  ? "/ LB"
                                  : ""}
                                {ProductDetails.is_special === true &&
                                ProductDetails.special_max > 0 ? (
                                  <TypographyTextColor
                                    component="h2"
                                    Color="heading"
                                  >
                                    You can add {ProductDetails.special_max}{" "}
                                    quantity on special price in your cart.
                                  </TypographyTextColor>
                                ) : (
                                  ""
                                )}
                                <h6>
                                  <h6>
                                    {this.state.is_case_check
                                      ? "($" +
                                        (
                                          ProductDetails.case_price /
                                          ProductDetails.case_quantity
                                        ).toFixed(2)
                                      : ""}
                                    {ProductDetails &&
                                    ProductDetails.is_by_weight === true &&
                                    this.state.is_case_check
                                      ? "/ LB each)"
                                      : ProductDetails &&
                                        ProductDetails.is_by_weight === false &&
                                        this.state.is_case_check
                                      ? " each)"
                                      : ""}{" "}
                                  </h6>

                                  {ProductDetails.is_by_weight === true &&
                                  ProductDetails.estimate_weight_per_piece > 0
                                    ? "est. " +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.estimate_weight_per_piece *
                                              ProductDetails.added_quantity[
                                                this.state.pro_details_ind
                                              ]
                                          : ProductDetails.estimate_weight_per_piece *
                                              1
                                      ).toFixed(2) +
                                      " LB / $" +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.estimate_weight_per_piece *
                                              ProductDetails.added_quantity[
                                                this.state.pro_details_ind
                                              ] *
                                              ProductDetails.today_price
                                          : ProductDetails.estimate_weight_per_piece *
                                              1 *
                                              ProductDetails.today_price
                                      ).toFixed(2)
                                    : ""}

                                  {ProductDetails.is_by_weight === true &&
                                  containerValue !== ""
                                    ? "est. $" +
                                      Number(
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.today_price *
                                              containerValue *
                                              ProductDetails.added_quantity[
                                                this.state.pro_details_ind
                                              ]
                                          : ProductDetails.today_price *
                                              containerValue *
                                              1
                                      ).toFixed(2)
                                    : ""}
                                  {this.state.container_type !== "" &&
                                  containerValue !== "" &&
                                  ProductDetails.is_by_weight === true
                                    ? "  ( $" +
                                      Number(
                                        ProductDetails.today_price *
                                          containerValue *
                                          1
                                      ).toFixed(2) +
                                      "/" +
                                      this.state.containerValue +
                                      " LB ) "
                                    : ""}
                                </h6>
                                {ProductDetails.is_offer ? (
                                  <>
                                    <span>
                                      {ProductDetails.is_offer
                                        ? ProductDetails.unit
                                        : "$"}
                                      {ProductDetails.is_offer
                                        ? ProductDetails.added_quantity_is_case[
                                            this.state.pro_details_ind
                                          ] === true
                                          ? "$" +
                                            Number(
                                              ProductDetails.case_quantity *
                                                ProductDetails.today_price
                                            ).toFixed(2)
                                          : "$" +
                                            Number(
                                              ProductDetails.original_price
                                            ).toFixed(2)
                                        : ""}
                                    </span>
                                    <span className="price_off">
                                      {ProductDetails.is_offer
                                        ? ProductDetails.offer_tag + "%"
                                        : ""}
                                      {ProductDetails &&
                                      ProductDetails.special_quantity > 0 &&
                                      ProductDetails.special_price > 0 &&
                                      ProductDetails.offer_msg === "B"
                                        ? " (Price $ " +
                                          (
                                            ProductDetails.special_price /
                                            ProductDetails.special_quantity
                                          ).toFixed(2) +
                                          " applied at Minimum 1 quantity"
                                        : ProductDetails &&
                                          ProductDetails.special_quantity > 0 &&
                                          ProductDetails.special_price > 0 &&
                                          ProductDetails.offer_msg === "M"
                                        ? "(Price $ " +
                                          ProductDetails.special_price.toFixed(
                                            2
                                          ) +
                                          " applied at Minimum " +
                                          ProductDetails.special_quantity +
                                          " quantity)"
                                        : ProductDetails &&
                                          ProductDetails.special_quantity > 0 &&
                                          ProductDetails.special_price > 0 &&
                                          ProductDetails.offer_msg === "P"
                                        ? "(Price $" +
                                          (
                                            ProductDetails.special_price /
                                            ProductDetails.special_quantity
                                          ).toFixed(2) +
                                          " applied at the Pack of " +
                                          ProductDetails.special_quantity +
                                          " quantity and Maximum " +
                                          ProductDetails.special_max +
                                          " pack)"
                                        : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            ) : (
                              <p className="details_pricerow_height"></p>
                            )}
                          </div>

                          {window.screen.availWidth < 900 ? (
                            this.state.extraCharges.is_show_out_of_stock ===
                              true &&
                            ProductDetails.out_of_stock === true &&
                            this.state.restrictByOnhand === true ? (
                              ""
                            ) : this.state.child_items &&
                              this.state.child_items.length > 0 &&
                              ProductDetails.is_parent &&
                              this.state.selectedChilditem === "" ? (
                              <></>
                            ) : this.state.AddtoCartBtn === true &&
                              ProductDetails.instoreonly === false &&
                              (ProductDetails.is_parent ||
                                ProductDetails.is_case === false) &&
                              (ProductDetails.added_quantity.length === 0 ||
                                ProductDetails.added_quantity_is_case.length ===
                                  0) ? (
                              <div className="add_cart">
                                <SecondaryButton
                                  Fonttype="other"
                                  className="mt-0 mr-3"
                                  onClick={this.openAddQuantityCount.bind(
                                    this,
                                    ProductDetails,
                                    ProductDetails.added_quantity &&
                                      ProductDetails.added_quantity[
                                        this.state.pro_details_ind
                                      ] > 0
                                      ? ProductDetails.added_quantity[
                                          this.state.pro_details_ind
                                        ]
                                      : 1,
                                    this.state.is_case_check === true
                                      ? true
                                      : false,
                                    ProductDetails.is_parent
                                  )}
                                >
                                  Add To Cart
                                </SecondaryButton>
                              </div>
                            ) : ProductDetails.instoreonly === false ? (
                              <div className="p-1">
                                <Typography
                                  component={TypographySecondaryBgColor}
                                  className="count_box"
                                >
                                  <button
                                    className="count_btn"
                                    onClick={this.openAddToCart.bind(
                                      this,
                                      ProductDetails.added_quantity &&
                                        ProductDetails.added_quantity[
                                          this.state.pro_details_ind
                                        ] > 0
                                        ? ProductDetails.added_quantity[
                                            this.state.pro_details_ind
                                          ] - 1
                                        : 1,
                                      // ProductDetails.added_quantity_is_case && ProductDetails.added_quantity_is_case[this.state.pro_details_ind] === true? true: false,
                                      this.state.is_case_check === true
                                        ? true
                                        : false,
                                      ProductDetails.container_type &&
                                        ProductDetails.container_type[
                                          this.state.pro_details_ind
                                        ]
                                        ? ProductDetails.container_type[
                                            this.state.pro_details_ind
                                          ]
                                        : "",
                                      "icon-minus"
                                    )}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.disabled ? (
                                      <img src={LoderImage} alt="" />
                                    ) : (
                                      <span className="icon-minus"></span>
                                    )}
                                  </button>
                                  <div className="count_no">
                                    {this.state.inputField === false ? (
                                      <input
                                        type="text"
                                        id={ProductDetails.id}
                                        className="count form-control"
                                        name="quentity"
                                        value={
                                          this.state.pro_details_ind > -1 &&
                                          ProductDetails.added_quantity.length >
                                            0
                                            ? ProductDetails.added_quantity[
                                                this.state.pro_details_ind
                                              ]
                                            : 0
                                        }
                                        // value={ProductDetails.added_quantity && ProductDetails.added_quantity[this.state.pro_details_ind] > 0? ProductDetails.added_quantity[this.state.pro_details_ind]: 0}
                                        // onBlur={()=>this.changeInputBoxValue()}
                                        onChange={(e) =>
                                          this.addQuentityForaddTocart(
                                            e.target.value,
                                            ProductDetails.added_quantity_is_case &&
                                              ProductDetails
                                                .added_quantity_is_case[
                                                this.state.pro_details_ind
                                              ] === true
                                              ? true
                                              : false,
                                            ProductDetails.container_type &&
                                              ProductDetails.container_type[
                                                this.state.pro_details_ind
                                              ]
                                              ? ProductDetails.container_type[
                                                  this.state.pro_details_ind
                                                ]
                                              : "",
                                            "icon-input"
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={ProductDetails.id}
                                        className="count form-control"
                                        name="quentity"
                                        defaultValue={
                                          this.state.pro_details_ind > -1
                                            ? ProductDetails.added_quantity[
                                                this.state.pro_details_ind
                                              ]
                                            : 0
                                        }
                                        onBlur={() =>
                                          this.changeInputBoxValue()
                                        }
                                        onChange={(e) =>
                                          this.addQuentityForaddTocart(
                                            e.target.value,
                                            ProductDetails.added_quantity_is_case &&
                                              ProductDetails
                                                .added_quantity_is_case[
                                                this.state.pro_details_ind
                                              ] === true
                                              ? true
                                              : false,
                                            ProductDetails.container_type &&
                                              ProductDetails.container_type[
                                                this.state.pro_details_ind
                                              ]
                                              ? ProductDetails.container_type[
                                                  this.state.pro_details_ind
                                                ]
                                              : "",
                                            "icon-input"
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                  <button
                                    className="count_btn"
                                    onClick={this.increaseItemCount.bind(
                                      this,
                                      ProductDetails.added_quantity &&
                                        ProductDetails.added_quantity[
                                          this.state.pro_details_ind
                                        ] > 0
                                        ? ProductDetails.added_quantity[
                                            this.state.pro_details_ind
                                          ] + 1
                                        : 1,
                                      this.state.is_case_check === true
                                        ? true
                                        : false,
                                      ProductDetails.container_type &&
                                        ProductDetails.container_type[
                                          this.state.pro_details_ind
                                        ]
                                        ? ProductDetails.container_type[
                                            this.state.pro_details_ind
                                          ]
                                        : "",
                                      "icon-plus"
                                    )}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.disabled ? (
                                      <img src={LoderImage} alt="" />
                                    ) : (
                                      <span className="icon-plus"></span>
                                    )}
                                  </button>
                                </Typography>

                                {ProductDetails.is_case === true &&
                                ProductDetails.is_parent === false ? (
                                  <TypographyPrimaryBgColorDimmed
                                    component={"div"}
                                    border={1}
                                    color="primary"
                                    className="case-cnt"
                                    onClick={() =>
                                      this.ProductCaseFunctionlity(
                                        !this.state.is_case_check,
                                        ProductDetails.note[0]
                                      )
                                    }
                                  >
                                    <TypographyFontColor
                                      component={"span"}
                                      border={0}
                                      className="case-box"
                                    >
                                      <div className="case-line">
                                        <input
                                          type="checkbox"
                                          className="case-checkbox"
                                          checked={
                                            this.state.is_case_check === true
                                              ? true
                                              : false
                                          }
                                        />
                                        Case of {ProductDetails.case_quantity}
                                      </div>
                                    </TypographyFontColor>
                                  </TypographyPrimaryBgColorDimmed>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <div className="add_cart">
                                <SecondaryButton
                                  Fonttype="other"
                                  disabled="true"
                                  style={{ opacity: "0.7" }}
                                >
                                  IN STORE ONLY
                                </SecondaryButton>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <div className="container-section">
                        {this.state.container &&
                        this.state.containerLength > 3 ? (
                          <select
                            value={this.state.containerValue}
                            class="form-select"
                            id="exampleFormControlSelect1"
                            name="exampleFormControlSelect1"
                            aria-label="Default select example"
                            onChange={(event) =>
                              this.changeConatinerType(event, "select")
                            }
                          >
                            {optionTemplate}
                          </select>
                        ) : (
                          <RadioGroup
                            value={this.state.containerValue}
                            id="exampleRadio"
                            data_label={this.state.container_type}
                            name="exampleRadio"
                            aria-label="gender"
                          >
                            {this.state.container &&
                            this.state.container.length > 0
                              ? this.state.container.map((row, index) => {
                                  return (
                                    <FormControlLabel
                                      value={row.value}
                                      id={row.label}
                                      onChange={(e) =>
                                        this.changeConatinerType(
                                          e,
                                          "radio",
                                          row.label
                                        )
                                      }
                                      control={<Radio color="primary" />}
                                      label={row.label}
                                    />
                                  );
                                })
                              : ""}
                          </RadioGroup>
                        )}
                      </div>

                      <div className="details_head_wrapper">
                        {this.state.child_items &&
                        this.state.child_items.length > 0 &&
                        ProductDetails.is_parent ? (
                          <select
                            value={this.state.selectedChilditem}
                            class="form-select"
                            id="exampleFormControlSelect2"
                            aria-label="Default select example"
                            onChange={(e) =>
                              this.changeChilditemdata(e, "select")
                            }
                            style={{
                              padding: "10px",
                              marginTop: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            <option selected="true" value="" disabled={true}>
                              {/* {this.state.Parent_item.original_price === 0 &&
                              ProductDetails.is_parent
                                ? "Select"
                                : ProductDetails.name} */}
                              Select Item
                            </option>
                            {this.state.child_items.map((val) => (
                              <option value={val.id}>{val.name}</option>
                            ))}
                          </select>
                        ) : (
                          <></>
                        )}

                        {window.screen.availWidth >= 900 ? (
                          this.state.extraCharges.is_show_out_of_stock ===
                            true &&
                          ProductDetails.out_of_stock === true &&
                          this.state.restrictByOnhand === true ? (
                            ""
                          ) : this.state.child_items &&
                            this.state.child_items.length > 0 &&
                            ProductDetails.is_parent &&
                            this.state.selectedChilditem === "" ? (
                            <></>
                          ) : this.state.AddtoCartBtn === true &&
                            ProductDetails.instoreonly === false &&
                            (ProductDetails.is_parent ||
                              ProductDetails.is_case === false) &&
                            (ProductDetails.added_quantity.length === 0 ||
                              ProductDetails.added_quantity_is_case.length ===
                                0) ? (
                            <div className="add_cart">
                              <SecondaryButton
                                Fonttype="other"
                                className="mt-0 mr-3"
                                onClick={this.openAddQuantityCount.bind(
                                  this,
                                  ProductDetails,
                                  ProductDetails.added_quantity &&
                                    ProductDetails.added_quantity[
                                      this.state.pro_details_ind
                                    ] > 0
                                    ? ProductDetails.added_quantity[
                                        this.state.pro_details_ind
                                      ]
                                    : 1,
                                  this.state.is_case_check === true
                                    ? true
                                    : false,
                                  ProductDetails.is_parent
                                )}
                              >
                                Add To Cart
                              </SecondaryButton>
                            </div>
                          ) : ProductDetails.instoreonly === false ? (
                            <div className="textrow align-items-center">
                              <Typography
                                component={TypographySecondaryBgColor}
                                className="count_box"
                              >
                                <button
                                  className="count_btn"
                                  onClick={this.openAddToCart.bind(
                                    this,
                                    ProductDetails.added_quantity &&
                                      ProductDetails.added_quantity[
                                        this.state.pro_details_ind
                                      ] > 0
                                      ? ProductDetails.added_quantity[
                                          this.state.pro_details_ind
                                        ] - 1
                                      : 1,
                                    // ProductDetails.added_quantity_is_case && ProductDetails.added_quantity_is_case[this.state.pro_details_ind] === true? true: false,
                                    this.state.is_case_check === true
                                      ? true
                                      : false,
                                    ProductDetails.container_type &&
                                      ProductDetails.container_type[
                                        this.state.pro_details_ind
                                      ]
                                      ? ProductDetails.container_type[
                                          this.state.pro_details_ind
                                        ]
                                      : "",
                                    "icon-minus"
                                  )}
                                  disabled={this.state.disabled}
                                >
                                  {this.state.disabled ? (
                                    <img src={LoderImage} alt="" />
                                  ) : (
                                    <span className="icon-minus"></span>
                                  )}
                                </button>
                                <div className="count_no">
                                  {this.state.inputField === false ? (
                                    <input
                                      type="text"
                                      id={ProductDetails.id}
                                      className="count form-control"
                                      name="quentity"
                                      value={
                                        this.state.pro_details_ind > -1 &&
                                        ProductDetails.added_quantity.length > 0
                                          ? ProductDetails.added_quantity[
                                              this.state.pro_details_ind
                                            ]
                                          : 0
                                      }
                                      // value={ProductDetails.added_quantity && ProductDetails.added_quantity[this.state.pro_details_ind] > 0? ProductDetails.added_quantity[this.state.pro_details_ind]: 0}
                                      // onBlur={()=>this.changeInputBoxValue()}
                                      onChange={(e) =>
                                        this.addQuentityForaddTocart(
                                          e.target.value,
                                          ProductDetails.added_quantity_is_case &&
                                            ProductDetails
                                              .added_quantity_is_case[
                                              this.state.pro_details_ind
                                            ] === true
                                            ? true
                                            : false,
                                          ProductDetails.container_type &&
                                            ProductDetails.container_type[
                                              this.state.pro_details_ind
                                            ]
                                            ? ProductDetails.container_type[
                                                this.state.pro_details_ind
                                              ]
                                            : "",
                                          "icon-input"
                                        )
                                      }
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      id={ProductDetails.id}
                                      className="count form-control"
                                      name="quentity"
                                      defaultValue={
                                        this.state.pro_details_ind > -1
                                          ? ProductDetails.added_quantity[
                                              this.state.pro_details_ind
                                            ]
                                          : 0
                                      }
                                      onBlur={() => this.changeInputBoxValue()}
                                      onChange={(e) =>
                                        this.addQuentityForaddTocart(
                                          e.target.value,
                                          ProductDetails.added_quantity_is_case &&
                                            ProductDetails
                                              .added_quantity_is_case[
                                              this.state.pro_details_ind
                                            ] === true
                                            ? true
                                            : false,
                                          ProductDetails.container_type &&
                                            ProductDetails.container_type[
                                              this.state.pro_details_ind
                                            ]
                                            ? ProductDetails.container_type[
                                                this.state.pro_details_ind
                                              ]
                                            : "",
                                          "icon-input"
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                <button
                                  className="count_btn"
                                  onClick={this.increaseItemCount.bind(
                                    this,
                                    ProductDetails.added_quantity &&
                                      ProductDetails.added_quantity[
                                        this.state.pro_details_ind
                                      ] > 0
                                      ? ProductDetails.added_quantity[
                                          this.state.pro_details_ind
                                        ] + 1
                                      : 1,
                                    this.state.is_case_check === true
                                      ? true
                                      : false,
                                    ProductDetails.container_type &&
                                      ProductDetails.container_type[
                                        this.state.pro_details_ind
                                      ]
                                      ? ProductDetails.container_type[
                                          this.state.pro_details_ind
                                        ]
                                      : "",
                                    "icon-plus"
                                  )}
                                  disabled={this.state.disabled}
                                >
                                  {this.state.disabled ? (
                                    <img src={LoderImage} alt="" />
                                  ) : (
                                    <span className="icon-plus"></span>
                                  )}
                                </button>
                              </Typography>

                              {ProductDetails.is_case === true &&
                              ProductDetails.is_parent === false ? (
                                <TypographyPrimaryBgColorDimmed
                                  component={"div"}
                                  border={1}
                                  color="primary"
                                  className="case-cnt"
                                  onClick={() =>
                                    this.ProductCaseFunctionlity(
                                      !this.state.is_case_check,
                                      ProductDetails.note[0]
                                    )
                                  }
                                >
                                  <TypographyFontColor
                                    component={"span"}
                                    border={0}
                                    className="case-box"
                                  >
                                    <div className="case-line">
                                      <input
                                        type="checkbox"
                                        className="case-checkbox"
                                        checked={
                                          this.state.is_case_check === true
                                            ? true
                                            : false
                                        }
                                      />
                                      Case of {ProductDetails.case_quantity}
                                    </div>
                                  </TypographyFontColor>
                                </TypographyPrimaryBgColorDimmed>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div className="add_cart">
                              <SecondaryButton
                                Fonttype="other"
                                disabled="true"
                                style={{ opacity: "0.7" }}
                              >
                                IN STORE ONLY
                              </SecondaryButton>
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        {ProductDetails.note &&
                        ProductDetails.note[this.state.pro_details_ind] &&
                        ProductDetails.added_quantity[
                          this.state.pro_details_ind
                        ] > 0 ? (
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: "15px 0" }}
                            className="align-items-center"
                          >
                            <Grid item display={"flex"} alignItems={"center"}>
                              <ProductNoteChipsview
                                size="medium"
                                ProductDetailsnotes={
                                  ProductDetails.note[
                                    this.state.pro_details_ind
                                  ]
                                }
                              />
                            </Grid>
                            <Grid item style={{ alignSelf: "center" }}>
                              <TypographySecondaryBgColorDimmed
                                border={1}
                                color="secondary"
                                className="notebtn-action"
                              >
                                <EditIcon
                                  onClick={(value) =>
                                    this.onEditModalOpen(
                                      ProductDetails.note[
                                        this.state.pro_details_ind
                                      ]
                                    )
                                  }
                                />
                              </TypographySecondaryBgColorDimmed>
                            </Grid>
                            <Grid item style={{ alignSelf: "center" }}>
                              <TypographySecondaryBgColorDimmed
                                border={1}
                                color="secondary"
                                className="notebtn-action"
                              >
                                {" "}
                                <DeleteIcon
                                  onClick={() => {
                                    ProductDetails.note[
                                      this.state.pro_details_ind
                                    ] = "";
                                    this.UpdateCartItemFunctionlity(
                                      ProductDetails.id,
                                      ProductDetails.added_quantity[
                                        this.state.pro_details_ind
                                      ],
                                      ProductDetails.added_quantity_is_case[
                                        this.state.pro_details_ind
                                      ],

                                      ProductDetails.note[
                                        this.state.pro_details_ind
                                      ],
                                      ProductDetails.container_type
                                    );
                                    this.setState({
                                      setOpenItemNote: false,
                                      doNotSubstitute: false,
                                      substituteWithSimilar: false,
                                      callMe: false,
                                    });
                                  }}
                                />
                              </TypographySecondaryBgColorDimmed>
                            </Grid>
                          </Grid>
                        ) : (
                          <div className="note_box">
                            <Typography
                              component={TypographySecondaryColor}
                              className="addNotes"
                              onClick={() => this.OpenDialog()}
                              Fonttype="other"
                            >
                              {" "}
                              <NoteAddIcon /> Add note
                            </Typography>
                          </div>
                        )}
                      </div>
                      {ProductDetails &&
                      (ProductDetails.bpa_free === true ||
                        ProductDetails.dairy_free === true ||
                        ProductDetails.gluten_free === true ||
                        ProductDetails.soy_free === true ||
                        ProductDetails.carrageenan_free === true ||
                        ProductDetails.non_gmo === true ||
                        ProductDetails.vegan === true ||
                        ProductDetails.kitnityot === true ||
                        ProductDetails.kosherforpassover === true ||
                        ProductDetails.natural === true ||
                        ProductDetails.kosher1 !== "" ||
                        ProductDetails.kosher2 !== "" ||
                        ProductDetails.naturalprocessing === true ||
                        ProductDetails.noartificialing === true ||
                        ProductDetails.noeggs === true ||
                        ProductDetails.nosugaradded === true ||
                        ProductDetails.paleofriendly ||
                        ProductDetails.pareve === true ||
                        ProductDetails.pasyisroel === true ||
                        ProductDetails.sulfitefree === true ||
                        ProductDetails.yoshon === true ||
                        ProductDetails.cholovyisroel === true ||
                        ProductDetails.cholovyisroel === true ||
                        ProductDetails.organic === true ||
                        ProductDetails.kosher3 !== "" ||
                        ProductDetails.kosher4 !== "") ? (
                        <div className="details_desc">
                          <ItemDetailDescription
                            ProductDetails={ProductDetails}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {ProductDetails?.ingredient === "" &&
                  ProductDetails.about_product === "" ? (
                    <></>
                  ) : (
                    <div className="details_main_wrapper">
                      {ProductDetails && ProductDetails.about_product ? (
                        <div className="details_main_details">
                          <TypographyTextColor component="h5" Color="heading">
                            About the Product
                          </TypographyTextColor>
                          <TypographyTextColor Color="other">
                            {ProductDetails && ProductDetails.about_product
                              ? fromBinary(ProductDetails.about_product)
                              : ""}
                          </TypographyTextColor>
                        </div>
                      ) : (
                        ""
                      )}
                      {ProductDetails && ProductDetails.ingredient ? (
                        <div className="details_main_details">
                          <TypographyTextColor component="h5" Color="heading">
                            Other Product Info
                          </TypographyTextColor>
                          <p>
                            <TypographyTextColor component="span" Color="other">
                              {ProductDetails ? ProductDetails.ingredient : ""}{" "}
                            </TypographyTextColor>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>

          {productDetailsLoader ? (
            <></>
          ) : (
            <>
              {PopularProductList?.length > 0 ? (
                <section className="items-content list_main_content">
                  <div className="header-cnt">
                    <div className="header-details">
                      <TypographyTextColor Color="heading" component="h4">
                        Frequently Bought Together
                      </TypographyTextColor>
                    </div>
                    <div
                      className="show_all"
                      style={{ marginRight: "15px" }}
                      onClick={() =>
                        this.handleChangeRouteForDeal("popular_products")
                      }
                    >
                      <TypographyTextColor component={Link} Color="other">
                        See All
                      </TypographyTextColor>
                    </div>
                  </div>
                  <div>
                    <div className="items-details item_listing_details">
                      {this.state.propularProductLoader ? (
                        <CircularProgress
                          style={{
                            display: "flex",
                            color: "#3d9b7b",
                            position: "relative",
                            right: "-35%",
                            "& > * + *": {
                              marginLeft: 2,
                            },
                          }}
                        />
                      ) : PopularProductList &&
                        PopularProductList.length > 0 ? (
                        PopularProductList.map((row, index) => {
                          var c_i_case = this.state.chack_is_case[row.id]
                            ? this.state.chack_is_case[row.id]
                            : false;
                          var check_added_quantity_is_case =
                            row.added_quantity_is_case.indexOf(c_i_case);
                          var check_is_case_flag = false;
                          if (check_added_quantity_is_case > -1) {
                            check_is_case_flag = true;
                          } else {
                            check_is_case_flag = true;
                            row.added_quantity_is_case.push(c_i_case);
                            row.added_quantity.push(0);
                            check_added_quantity_is_case =
                              row.added_quantity_is_case.indexOf(c_i_case);
                          }
                          return window.screen.availWidth >= 700 ? (
                            <ItemCard
                              extraCharges={this.state.extraCharges}
                              check_is_case_flag={check_is_case_flag}
                              check_added_quantity_is_case={
                                check_added_quantity_is_case
                              }
                              itemData={row}
                              favoriteLevelListData={favoriteLevelData}
                              index={index}
                              removeFromfavouriteProduct={(
                                favoriot_id,
                                product_id
                              ) =>
                                this.removeFromfavouriteProduct(
                                  favoriot_id,
                                  product_id
                                )
                              }
                              addFavoiteLevelFunctionlity={(
                                value,
                                product_id
                              ) =>
                                this.addFavoiteLevelFunctionlity(
                                  value,
                                  product_id
                                )
                              }
                              history={this.props.history}
                              activePageName={this.props.location.pathname}
                              addFavoiteProductFunctionlity={(
                                favoritelabel_id,
                                id,
                                added_quantity,
                                added_quantity_is_case
                              ) =>
                                this.addFavoiteProductFunctionlity(
                                  favoritelabel_id,
                                  id,
                                  added_quantity,
                                  added_quantity_is_case
                                )
                              }
                              FetchFavoriteLevelList={(product_id) =>
                                this.FetchFavoriteLevelList(product_id)
                              }
                              HandelChangeTrueWishList={() =>
                                this.HandelChangeTrueWishList()
                              }
                              handlePopOpen={(id, slug, next, prev, index) =>
                                this.handlePopOpen(id, slug, next, prev, index)
                              }
                              openAddCount={(
                                id,
                                added_quantity,
                                added_quantity_is_case,
                                categoryindex,
                                containertype
                              ) =>
                                this.openAddCount(
                                  id,
                                  added_quantity,
                                  added_quantity_is_case,
                                  categoryindex,
                                  containertype
                                )
                              }
                              openAddCounttext={(
                                id,
                                added_quantity,
                                added_quantity_is_case,
                                categoryindex,
                                containertype
                              ) =>
                                this.openAddCount(
                                  id,
                                  added_quantity,
                                  added_quantity_is_case,
                                  categoryindex,
                                  containertype
                                )
                              }
                              disabled={this.state.disabled}
                              inputField={this.state.inputField}
                              changeInputBoxValue={() =>
                                this.changeInputBoxValue()
                              }
                              ProductCaseFunctionlity={(id, event) =>
                                this.ProductCaseFunctionlityForFrequentBrought(
                                  id,
                                  event
                                )
                              }
                              cardWidth={
                                window.screen.availWidth <= 1200
                                  ? "calc(95%/4)"
                                  : "calc(95%/8)"
                              }
                              restrictByOnhand={this.state.restrictByOnhand}
                            />
                          ) : (
                            <ItemCardMob
                              extraCharges={this.state.extraCharges}
                              check_is_case_flag={check_is_case_flag}
                              check_added_quantity_is_case={
                                check_added_quantity_is_case
                              }
                              itemData={row}
                              favoriteLevelListData={
                                this.state.favoriteLevelListData
                              }
                              index={index}
                              removeFromfavouriteProduct={(
                                favoriot_id,
                                product_id,
                                categoryIndx
                              ) =>
                                this.removeFromfavouriteProduct(
                                  favoriot_id,
                                  product_id,
                                  categoryIndx
                                )
                              }
                              addFavoiteLevelFunctionlity={(
                                value,
                                product_id
                              ) =>
                                this.addFavoiteLevelFunctionlity(
                                  value,
                                  product_id
                                )
                              }
                              history={this.props.history}
                              activePageName={this.props.location.pathname}
                              addFavoiteProductFunctionlity={(
                                favoritelabel_id,
                                id,
                                added_quantity,
                                categoryIndx,
                                added_quantity_is_case
                              ) =>
                                this.addFavoiteProductFunctionlity(
                                  favoritelabel_id,
                                  id,
                                  added_quantity,
                                  categoryIndx,
                                  added_quantity_is_case
                                )
                              }
                              FetchFavoriteLevelList={(product_id) =>
                                this.FetchFavoriteLevelList(product_id)
                              }
                              HandelChangeTrueWishList={() =>
                                this.HandelChangeTrueWishList()
                              }
                              handlePopOpen={(
                                id,
                                slug,
                                next,
                                prev,
                                index,
                                categoryindx
                              ) =>
                                this.handlePopOpen(
                                  id,
                                  slug,
                                  next,
                                  prev,
                                  index,
                                  categoryindx
                                )
                              }
                              openAddCount={(
                                id,
                                added_quantity,
                                added_quantity_is_case,
                                categoryindex,
                                containertype
                              ) =>
                                this.openAddCount(
                                  id,
                                  added_quantity,
                                  added_quantity_is_case,
                                  categoryindex,
                                  containertype
                                )
                              }
                              openAddCounttext={(
                                id,
                                added_quantity,
                                added_quantity_is_case,
                                categoryindex,
                                containertype
                              ) =>
                                this.openAddCount(
                                  id,
                                  added_quantity,
                                  added_quantity_is_case,
                                  categoryindex,
                                  containertype
                                )
                              }
                              disabled={this.state.disabled}
                              inputField={this.state.inputField}
                              changeInputBoxValue={() =>
                                this.changeInputBoxValue()
                              }
                              ProductCaseFunctionlity={(id, event) =>
                                this.ProductCaseFunctionlity(id, event)
                              }
                              restrictByOnhand={this.state.restrictByOnhand}
                            />
                          );
                        })
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {Object.keys(this.state.ProductDetails).length === 1 ? (
                    <ItemDetailsModal
                      open_inner={this.state.open_inner}
                      open={this.state.itemDetails}
                      handleOpenDialog={this.handleOpenDialog}
                      handlePopClose={(
                        product_id,
                        added_quantity,
                        added_quantity_is_case,
                        is_case
                      ) =>
                        this.handlePopClose(
                          product_id,
                          added_quantity,
                          added_quantity_is_case,
                          is_case
                        )
                      }
                      ProductDetails={
                        this.state.ProductDetails
                          ? this.state.ProductDetails
                          : []
                      }
                      categoryNames={this.state.categoryData}
                      prev_rec={this.state.prev_rec}
                      next_rec={this.state.next_rec}
                      preProductItem={(product_id, slug, pre_info, next_info) =>
                        this.preProductItem(
                          product_id,
                          slug,
                          pre_info,
                          next_info
                        )
                      }
                      nextProductItem={(
                        product_id,
                        slug,
                        pre_info,
                        next_info
                      ) =>
                        this.nextProductItem(
                          product_id,
                          slug,
                          pre_info,
                          next_info
                        )
                      }
                      productDetailsLoader={this.state.productDetailsLoader}
                      history={this.props}
                      addQuentityForaddTocart={(
                        targetValue,
                        added_qty_is_case,
                        container_type
                      ) =>
                        this.addQuentityForaddTocart(
                          targetValue,
                          added_qty_is_case,
                          container_type
                        )
                      }
                      FetchProdutDetails={(
                        product_id,
                        slug,
                        next_info,
                        pre_info
                      ) =>
                        this.FetchProdutDetails(
                          product_id,
                          slug,
                          next_info,
                          pre_info
                        )
                      }
                      parentIds={this.state.parentIds}
                      parent_onlyid={this.state.parent_onlyid}
                      parent_level={this.state.parent_level}
                      RefreshApiToken={(refreshToken, myThis, callback) =>
                        this.RefreshApiToken(refreshToken, myThis, callback)
                      }
                      special_filter={this.state.special_filter}
                      special_filter_value={this.state.special_filter_value}
                      ProductCaseFunctionlityForItem={(added_qty_is_case) =>
                        this.ProductCaseFunctionlityForItem(added_qty_is_case)
                      }
                      chack_is_case={this.state.chack_is_case}
                      container={this.state.container}
                      containerLength={this.state.containerLength}
                      containerValue={this.state.containerValue}
                      pro_item_details_ind={this.state.pro_item_details_ind}
                      container_type={this.state.container_type}
                      changeConatinerType={(event, type, myContainer) =>
                        this.changeConatinerType(event, type, myContainer)
                      }
                      AddtoCartBtn={this.state.AddtoCartBtn}
                      changeInputBoxValue={() => this.changeInputBoxValue()}
                      inputField={this.state.inputField}
                      commonFunctionForContainer={(
                        added_qtyInd,
                        added_quantity,
                        added_quantity_is_case
                      ) =>
                        this.commonFunctionForContainer(
                          added_qtyInd,
                          added_quantity,
                          added_quantity_is_case
                        )
                      }
                      activePageName={this.props.location.pathname}
                      hendleAddtoCartBtn={() =>
                        this.hendleAddtoCartBtnForListing()
                      }
                      CategoryDetails={CategoryDetails}
                      FetchCartCount={() => this.FetchCartItemList()}
                      openisslotDeliverypopupState_compwill={() =>
                        this.openisslotDeliverypopupState_compwill()
                      }
                    />
                  ) : (
                    ""
                  )}
                </section>
              ) : (
                ""
              )}
              <div className="list_main_content">
                <Footer emailSubscribe={this.state.emailSubscribe} />
              </div>
            </>
          )}
        </div>
        <div className="toggleMenu" id="no-print">
          {this.state.toggleMenuCategory && (
            <div className="leftmenu-main hide">
              <div
                className={`leftmenu ${
                  this.state.toggleMenuCategory ? "openmenu overflow-auto" : ""
                } `}
              >
                {CategoryDetails &&
                CategoryDetails.length > 0 &&
                TreeViewIteams.length > 0 ? (
                  <CategoryListing
                    pageName={this.state.pageName}
                    CategoryDetails={CategoryDetails}
                    BrandProduct={BrandProduct}
                    fruitType={fruitType}
                    flavor={flavor}
                    kosher={kosher}
                    handleChangeCheckboxForProductType={(product_type) =>
                      this.handleChangeCheckboxForProductType(product_type)
                    }
                    handleChangeCheckboxForFlover={(flover) =>
                      this.handleChangeCheckboxForFlover(flover)
                    }
                    handleChangeCheckboxForKosher={(kosher) =>
                      this.handleChangeCheckboxForKosher(kosher.trim())
                    }
                    handleChangeCheckbox={(brand_id, brand_name) =>
                      this.handleChangeCheckbox(brand_id, brand_name)
                    }
                    FetchDataForPriceRange={(
                      price_min,
                      price_max,
                      category_id,
                      subcategory_id,
                      has_child
                    ) =>
                      this.FetchDataForPriceRange(
                        price_min,
                        price_max,
                        category_id,
                        subcategory_id,
                        has_child
                      )
                    }
                    Props_value={this.props ? this.props : ""}
                    List_PropsValue={this.state.Props_value}
                    {...this.state.parent_onlyid}
                    parent_onlyid={
                      this.props &&
                      this.props.Props_value &&
                      this.props.Props_value.location.state
                        ? this.props.Props_value.location.state.parent_onlyid
                        : []
                    }
                    TreeViewIteams={this.state.TreeViewIteams}
                    max_price={this.state.max_price}
                    history={this.props.history}
                    toggleMenuCat={() => this.toggleMenuCategory()}
                  />
                ) : (
                  <div
                    className="main_containerLoader"
                    style={{
                      display: this.state.CategoryListLoader ? "flex" : "none",
                    }}
                  >
                    <CustomPreloader />
                  </div>
                )}
              </div>
              <div
                className="leftmenu_bgblack"
                onClick={() => this.toggleMenuCategory()}
              ></div>
            </div>
          )}
        </div>
        <ProductNote
          setOpen={setOpen}
          CloseDialog={(type) => this.CloseDialog(type)}
          prodcutNote={(e) => this.prodcutNote(e)}
          productNoteItemNote={this.state.productNoteItemNote}
          callMe={this.state.callMe}
          substituteWithSimilar={this.state.substituteWithSimilar}
          doNotSubstitute={this.state.doNotSubstitute}
          backupInstractTion={(e) => this.backupInstractTion(e)}
        />

        <CommonAlertBox
          error={this.state.error}
          handleClose={this.handleClose}
        />
        <Snackbar
          open={this.state.success}
          onClose={this.handleClose}
          autoHideDuration={2000}
          className={"success"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <i className="icon-close"></i>
              </IconButton>
            </React.Fragment>
          }
          message={
            <React.Fragment>
              <div className="row gutter-8">
                <div className="col-auto">
                  <i className="icon-closer"></i>
                </div>
                <div className="col">
                  <p>{this.state.success}</p>
                </div>
              </div>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "DetailsPage" })(DetailsPage)
);
