import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import { generateDeviceId } from "../../utils/renderUtils/renderUtil";
import {
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_ERROR,
  LOGIN_ADMIN_REQUEST,
} from "../ActionType";

export function userAuthValivate() {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const Url = ApiUrls.USERAUTHCHECK;

  return axios
    .get(Url, configToken)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export function LoginApplication(formValues, dispatch) {
  const loginUrl = ApiUrls.LOGIN_URL;
  let formData = new FormData();
  let Device_id = generateDeviceId();
  formData.append("username", formValues.username);
  formData.append("password", formValues.password);
  const user = {
    username: formData.get("username"),
    password: formData.get("password"),
    device_id: Device_id,
    is_sales_rap:localStorage.getItem("UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id)===null?false:true
  };
  return axios
    .post(loginUrl, user)
    .then(function (res) {
      dispatch({
        type: LOGIN_ADMIN_REQUEST,
        payload: {
          loading: true,
        },
      });
      if (res.data && res.status === 200) {
        localStorage.setItem("DeviceId", JSON.stringify(Device_id));
        localStorage.setItem("access_token", JSON.stringify(res.data));
        localStorage.setItem(
          "email",
          JSON.stringify({ email: res.data.email })
        );
        localStorage.setItem("name", JSON.stringify({ name: res.data.name }));

        localStorage.setItem("isLogin", true);
        if( localStorage.getItem(
          "UserDetail" +
            JSON.parse(localStorage.getItem("Store_address"))?.id
        ) === null)
        localStorage.setItem("listOpen", true);
      }
      dispatch({
        type: LOGIN_ADMIN_SUCCESS,
        payload: {
          adminuser: res.data,
        },
      });
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        dispatch({
          type: LOGIN_ADMIN_ERROR,
          payload: {
            errors: "Entered Username / Email or Password is not valid.",
          },
        });
        return {
          errors: {
            _error: "Entered Username / Email or Password is not valid.",
          },
        };
      } else {
        dispatch({
          type: LOGIN_ADMIN_ERROR,
          payload: {
            errors: "",
          },
        });
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function SalesRepLoginApplication(formValues, dispatch) {
  const loginUrl = ApiUrls.LOGIN_SALES_URL;

  const user = {
    username: formValues.username,
    otp: formValues.otp,
    store_id: "",
    
  };
  return axios
    .post(loginUrl, user)
    .then(function (res) {
      if (res.data && res.status === 200) {
        localStorage.setItem(
         "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id,
          JSON.stringify({ user_id: res.data.user_id, name: res.data.name,isUserSalesrep: true })
        );
      }

      return res;
    })
    .catch(function (error) {
      return error;
    });
}
export function GenerateOTPSalesrep(formValues, dispatch) {
  const loginUrl = ApiUrls.OTP_SALES_URL;
  let formData = new FormData();
  formData.append("username", formValues.username);
  const user = {
    username: formData.get("username"),
    store_id: "",
  };
  return axios
    .post(loginUrl, user)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      return error;
    });
}

export function AutoLoginApplication(formValues, dispatch) {
  const loginUrl = ApiUrls.LOGIN_URL;
  let formData = new FormData();
  let Device_id = generateDeviceId();
  formData.append("username", formValues.username);
  formData.append("password", formValues.password);

  const user = {
    username: formData.get("username"),
    password: formData.get("password"),
    device_id: Device_id,
  };
  return axios
    .post(loginUrl, user)
    .then(function (res) {
      if (res.data && res.status === 200) {
        localStorage.setItem("DeviceId", JSON.stringify(Device_id));
        localStorage.setItem("access_token", JSON.stringify(res.data));
        localStorage.setItem(
          "email",
          JSON.stringify({ email: res.data.email })
        );
        localStorage.setItem("name", JSON.stringify({ name: res.data.name }));

        localStorage.setItem("isLogin", true);
        localStorage.setItem("listOpen", true);
      }
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error;
      }
    });
}
export function AutoLoginGuest(formValues, dispatch) {
  const loginUrl = ApiUrls.Guest_USER_SINGUP;
  // let formData = new FormData();
  // formData.append("email", formValues.email);
  // formData.append("phone", formValues.phone);

  // const user = {
  //   email: formData.get("email"),
  //   phone: formData.get("phone"),
  //   callingcode: 1,
  //   store_id:0
  // };
  return axios
    .post(loginUrl, formValues)
    .then(function (res) {
     
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error;
      }
    });
}

export function RefreshLogin(formValues) {
  const refreshUrl = ApiUrls.REFRESh_URL;

  const formDate = {
    refresh: formValues.refresh,
  };

  return axios
    .post(refreshUrl, formDate)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error) {
        return error;
      }
    });
}

export function ForgetPassword(formValues) {
  const refreshUrl = ApiUrls.FORGET_PASSWORD;

  const formDate = {
    username: formValues.email_address,
  };

  return axios
    .post(refreshUrl, formDate)
    .then(function (res) {
      if (res.data && res.status === 200) {
        localStorage.setItem("isLogin", false);
        localStorage.setItem("listOpen", false);
      }
      return res;
    })
    .catch(function (error) {
      if (error) {
        return error;
      }
    });
}

export function ResetPassword(formValues, dispatch) {
  let token = formValues.token;
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    };
  }

  const url = ApiUrls.RESETPASSWORD_URL;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CheckurlResetPassword(formValues, dispatch) {
  let token = formValues.token;
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    };
  }

  const url = ApiUrls.CHECK_RESETPASSWORD_URL;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
