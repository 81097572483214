import React, { Component } from "react";
import { SubscribeaddApi } from "../../../actions/home/homeAction";
import Commonsnackbar from "../commonsnackbar";
import DoneIcon from "@mui/icons-material/Done";
import CommonAlertBox from "../CommonAlertBox";
import {
  GetSettingsData,
  SettingsSetValue,
} from "../../../actions/profile/profileAction";
import { RefreshApiToken, sweetAlertBox } from "../../../utils/renderUtils/renderUtil";
import SendIcon from "@mui/icons-material/Send";
import { PrimaryButton, SubscribePrimaryButton, TypographyFontColor } from "../UiDesign";
class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpenLoginPopup: false,
      email: "",
      success: false,
      error: false,
      subscirbe: false,
      email_notification: "",
      bgColor: "#f57f18",
    };
  }
  componentDidMount() {}
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.emailSubscribe?.email_notification) {
      this.setState({
        email_notification: nextProps.emailSubscribe?.email_notification,
      });
    }
  }
  SettingsActiveInactive = () => {
    let formValues = { field_name: "email_notification", field_value: true };
    SettingsSetValue(formValues, this.props.dispatch).then((res) => {
      
      if (res) {
        // data.email_notification = true;
        if (this?.props?.history?.location?.pathname === "/settings") {
          window.location.reload();
        } else this.setState({ email_notification: true });
      } else if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      } else {
        this.createSuccessMsg("error", res.message);
      }
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.email) {
        sweetAlertBox('Attention!!',msg.email[0],"info")
        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      this.setState({
        // error: false,
        // success: msg,
        email: "",
      });
    }
  };
  handlesubscribe = () => {
    // if (localStorage.getItem("isLogin") === "true") {
    if (this.state.email !== "") {
      let formValue = { store_id: 0, email: this.state.email };
      SubscribeaddApi(formValue).then((res) => {
        if (res.message) {
          this.createSuccessMsg("success", "Subscribe has been successfully");
        } else {
          this.createSuccessMsg("error", res.errors.data.errors);
        }
      });
    }
    // } else {
    //   this.setState({ setOpenLoginPopup: true });
    // }
  };
  // CloseLoginDialog = (type) => {
  //   this.setState({ setOpenLoginPopup: false });
  //   this.fetchSubscibtiondetail();
  // };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      success: false,
      error: false,
    });
  };
  render() {
    return (
      localStorage.getItem("isLogin") === "true" ? (
        this.state.email_notification ? (
          <SubscribePrimaryButton className="subscribe-btn">
            Subscribed <DoneIcon />
          </SubscribePrimaryButton>
        ) : (
          <SubscribePrimaryButton
            className="subscribe-btn"
            onClick={() => {
              this.SettingsActiveInactive();
            }}
          >
            subscribe
          </SubscribePrimaryButton>
        )
      ) : (
      <form className="newsletter">
        <input type="text" placeholder="Email Address"  value={this.state.email}   onChange={(e) => this.setState({ email: e.target.value })} />
        <button className="newsletter_submit_btn" type="button"   onClick={() => {
                    this.handlesubscribe();
                  }}>
     <TypographyFontColor border={0}>  <SendIcon></SendIcon></TypographyFontColor>
        </button>
      </form>)
    );
  }
}

export default Subscribe;
