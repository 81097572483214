import React, { Component } from "react";
import { IconButton, Snackbar } from "@mui/material";
class Commonsnackbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_value: "",
    };
  }
  componentDidMount() {
    console.log(this.props);
    this.setState({ class_value: this.props.success ? "success" : "error" });
  }
  render() {
    return (
      <>
        <Snackbar
          open={this.props.success ? this.props.success : this.props.error}
          onClose={this.props.handleClose}
          autoHideDuration={3000}
          className={this.state.class_value}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.props.handleClose()}
              >
                <i className="icon-close"></i>
              </IconButton>
            </React.Fragment>
          }
          message={
            <React.Fragment>
              <div className="row gutter-8">
                <div className="col-auto">
                  <i className="icon-closer"></i>
                </div>
                <div className="col">
                  <p>
                    {this.props.success ? this.props.success : this.props.error}
                  </p>
                </div>
              </div>
            </React.Fragment>
          }
        />
      </>
    );
  }
}

export default Commonsnackbar;
