import React, { Component } from "react";
import { Fab, Snackbar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  sufixIconInputButton,
  renderHiddenField,
} from "../../utils/renderUtils/renderUtil";
import { required } from "redux-form-validators";
import LOGO_IMAGE from "../../assets/images/logo.png";
import submitForgetPassword from "./submitForgetPassword";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import { SecondaryButton, TypographyTextColor } from "../common/UiDesign";
import {
  AutoLoginApplication,
  CheckurlResetPassword,
} from "../../actions/login/loginAction";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showForgetPassword: true,
      feildType: true,
      feildTypePassword: true,
      feildTypeConfirmPassword: true,
      success: false,
      error: false,
      token: "",
      key: "",
      password: "",
      confirmPassword: "",
      msgPassworderror: false,
      msgPasswordsuccess: false,
      logo: "",
      isUrlValid: true,
      userEmail: "",
    };
  }

  changeFeildTypePassword = () => {
    this.setState({
      feildTypePassword: !this.state.feildTypePassword,
    });
  };

  changeFeildTypeConfirmPassword = () => {
    this.setState({
      feildTypeConfirmPassword: !this.state.feildTypeConfirmPassword,
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      this.setState({
        error: msg.token[0],
        success: false,
      });
    } else {
      // this.successClick();
      this.setState({
        error: false,
        success: msg,
      });
      console.log(this.state.isUrlValid);
      if(this.state.isUrlValid)
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  handleCloseForPasswordReset = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  componentDidMount() {
    // console.log(this.props.location);
    document.title = "Reset Password";
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    let value = this.props.location.search;
    value = value?.split("&");

    const token = value[1]?.split("=")[1];
    const key = value[0]?.split("=")[1];
    this.checkUrlEmail(key);
    this.setState({ logo: logo, token, key });
    this.props.change("key", key);
    this.props.change("token", token);
  }
  redirectToSuccessPage = (data) => {
    // let formValues = {
    //   username: data.email,
    //   password: this.state.password,
    // };
    this.props.history.push({
      pathname: "/login",
    });
    //  AutoLoginApplication(formValues, this.props.dispatch).then((res) => {
    //     if (res.status === 200) {
    //  this.props.history.push({
    //     pathname: "/",
    //   });
    //     }
    //   });
  };

  handlePasswordForPasswordReset = (event) => {
    if (event.target.value.length > 5 && event.target.value.length < 16) {
      this.setState({
        msgPassworderror: false,
      });
    } else {
      this.setState({ msgPassworderror: "Password too short" });
    }
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordForResetPass = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({
        msgPassworderror: "Passwords do not match",
      });
      this.setState({ confirmPassword: event.target.value });
      this.setState({ msgPasswordsuccess: false });
    } else if (event.target.value.length < 6) {
      this.setState({ msgPassworderror: "Password too short" });
    } else {
      this.setState({ msgPassworderror: false });
      this.setState({ msgPasswordsuccess: "Password match" });
    }
  };

  checkUrlEmail = (key) => {
    let formvalue = {
      key: key,
      token: "string",
    };
    CheckurlResetPassword(formvalue, this.props.dispatch).then((res) => {
      console.log(res);
      if (res.message !== "This is valid link") {
        this.setState({
          isUrlValid: false,
        });
      }
      this.setState({
        userEmail: res?.email ? res?.email : res?.errors?.data?.email,
      });
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { success, error, msgPassworderror, msgPasswordsuccess, isUrlValid } =
      this.state;
    return (
      <div className="main-content">
        <div className="container-fluid p-0">
          <div className="login-main">
            <div className="header-pop">
              <Link to="/">
                <img
                  src={
                    this.state.logo
                      ?this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                      : LOGO_IMAGE
                  }
                  // src={LOGO_IMAGE}
                  alt=""
                />
              </Link>
            </div>
            <div className="login-page">
              <div className="login-form">
                <form
                  onSubmit={handleSubmit(submitForgetPassword.bind(null, this))}
                >
                  <div className="login-head">
                    <TypographyTextColor component={"h4"} Color="heading">
                      {isUrlValid ? "Change Password" : "This url is invalid"}
                    </TypographyTextColor>
                  </div>
                  {isUrlValid ? (
                    <>
                      <div className="user-input">
                        <Field
                          name="key"
                          component={renderHiddenField}
                          type="hidden"
                        />
                        <Field
                          name="token"
                          component={renderHiddenField}
                          type="hidden"
                        />
                        <Field
                          name="password"
                          label="Password"
                          component={sufixIconInputButton}
                          type={
                            this.state.feildTypePassword ? "password" : "text"
                          }
                          icon={
                            this.state.feildTypePassword
                              ? "icon-visibility icn-usr"
                              : "icon-invisible icn-usr"
                          }
                          changeFeildTypeFunction={this.changeFeildTypePassword}
                          onChange={(e) =>
                            this.handlePasswordForPasswordReset(e)
                          }
                          rootClass=""
                          size="small"
                          required={true}
                          validate={[
                            required({ message: "Password is required." }),
                          ]}
                        />
                      </div>
                      <div className="user-input">
                        <Field
                          name="cmf_password"
                          label="Confirm Password"
                          component={sufixIconInputButton}
                          type={
                            this.state.feildTypeConfirmPassword
                              ? "password"
                              : "text"
                          }
                          icon={
                            this.state.feildTypeConfirmPassword
                              ? "icon-visibility icn-usr"
                              : "icon-invisible icn-usr"
                          }
                          changeFeildTypeFunction={
                            this.changeFeildTypeConfirmPassword
                          }
                          onChange={(e) =>
                            this.handleConfirmPasswordForResetPass(e)
                          }
                          rootClass=""
                          size="small"
                          required={true}
                          validate={[
                            required({
                              message: "Confirm Password is required.",
                            }),
                          ]}
                        />
                      </div>
                      {msgPassworderror ? (
                        <div className="alert alert-danger mt-3" role="alert">
                          {msgPassworderror}
                        </div>
                      ) : (
                        ""
                      )}

                      {msgPasswordsuccess ? (
                        <div className="alert alert-success mt-3" role="alert">
                          {msgPasswordsuccess}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className="bttn-fill"
                    style={{ textAlign: " -webkit-center" }}
                  >
                    <SecondaryButton
                      className="bttn_grn"
                      variant="extended"
                      type="submit"
                      Fonttype="other"
                      color="primary"
                    >
                      {isUrlValid ? "Change" : "Resend link"}
                    </SecondaryButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {success ? (
          <Snackbar
            open={this.state.success}
            onClose={this.handleCloseForPasswordReset}
            autoHideDuration={6000}
            className="success"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseForPasswordReset}
                >
                  <i className="icon-close"></i>
                </IconButton>
              </React.Fragment>
            }
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    {/* <h6>Successful Login</h6> */}
                    <p>{success}</p>
                    {/* <p>Login failed. Invalid username or password</p> */}
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
        {error ? (
          <Snackbar
            open={this.state.error}
            onClose={this.handleCloseForPasswordReset}
            autoHideDuration={6000}
            className="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseForPasswordReset}
                >
                  <i className="icon-close"></i>
                </IconButton>
              </React.Fragment>
            }
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{error}</p>
                    {/* <p>Login failed. Invalid username or password</p> */}
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "ResetPassword" })(ResetPassword)
);
