import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  CardOrderSummaryFunctionlity,
  CardSaveapi,
  CardknoxPostAuthapi,
  CartOrderFunctionlity,
} from "../../../actions/cart/cartAction";
import { GetExtraChargesData } from "../../../actions/cart/cartAction";
import CircularProgress from "@mui/material/CircularProgress";
import {
  RefreshApiToken,
  getSchedule_zip_Cart,
  sweetAlertBox,
} from "../../../utils/renderUtils/renderUtil";
import {
  SecondaryButton,
  TypographyFontColor,
  TypographyTextColor,
} from "../UiDesign";
import Commonsnackbar from "../commonsnackbar";
import CardsavePopup from "../CardsavePopup";
import { Divider } from "@mui/material";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CardOrderSummary: [],
      tipForPacker:
        localStorage.getItem("tipAmount") !== null &&
        JSON.parse(localStorage.getItem("tipAmount"))?.tipForPacker !== ""
          ? JSON.parse(localStorage.getItem("tipAmount")).tipForPacker
          : this.props.history &&
            this.props.history.location &&
            this.props.history.location.state
          ? this.props.history.location.state.tipForPacker
          : this.props.tipForPacker
          ? this.props.tipForPacker
          : "0.00",
      tipForDeliveryboy:
        localStorage.getItem("tipAmount") !== null &&
        JSON.parse(localStorage.getItem("tipAmount"))?.tipForDeliveryboy !== ""
          ? JSON.parse(localStorage.getItem("tipAmount")).tipForDeliveryboy
          : this.props.history &&
            this.props.history.location &&
            this.props.history.location.state
          ? this.props.history.location.state.tipForDeliveryboy
          : this.props.tipForDeliveryboy
          ? this.props.tipForDeliveryboy
          : "0.00",
      donation:
        localStorage.getItem("tipAmount") !== null &&
        JSON.parse(localStorage.getItem("tipAmount"))?.donation !== ""
          ? JSON.parse(localStorage.getItem("tipAmount")).donation
          : this.props.history &&
            this.props.history.location &&
            this.props.history.location.state
          ? this.props.history.location.state.donation
          : this.props.donation
          ? this.props.donation
          : "0.00",
      TotalPayment:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.state
          ? this.props.history.location.state.TotalPayment
          : this.props.TotalPayment
          ? this.props.TotalPayment
          : 0,
      OriginalPayment: 0,
      subtotal_price: 0,
      client: "",
      paymentCard: "",
      success: false,
      error: false,
      paymentLoader: false,
      order_id: 0,
      extraCharges: [],
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      is_show_customer_credit: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_show_customer_credit,
    };
  }

  componentDidMount() {
    //document.title = "Payment Page";
    console.log(this.props.customercreditData);
    this.FetchCartOrderSummaryList();
    this.ExtraChargesFunctiolity();
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  FetchCartOrderSummaryList = () => {
    let formdata = getSchedule_zip_Cart();
    let formValues = {
      limit: 7,
    };
    let TotalPayment;
    let OriginalPayment;
    formValues = { ...formValues, ...formdata };
    CardOrderSummaryFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let cardOrderSummar = [];
        if (res.data && res.data.result) {
          cardOrderSummar = res.data.result;
          
          let extracharges =
            cardOrderSummar.extra_charges_name !== "" &&
            cardOrderSummar.extra_charges_name !== null &&
            cardOrderSummar.extra_charges_price !== 0 &&
            cardOrderSummar.extra_charges_price !== null
              ? cardOrderSummar.extra_charges_price
              : 0;

          TotalPayment =
            cardOrderSummar.subtotal +
            cardOrderSummar.shipping_price +
            cardOrderSummar.Estimated_tax +
            cardOrderSummar.delivery_charge +
            parseInt(this.state.tipForPacker) +
            parseInt(this.state.tipForDeliveryboy) +
            parseFloat(this.state.donation) +
            extracharges;
          OriginalPayment =
            cardOrderSummar.subtotal +
            cardOrderSummar.shipping_price +
            cardOrderSummar.Estimated_tax +
            cardOrderSummar.delivery_charge +
            extracharges;
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          if (refreshToken) {
            this.props.RefreshApiToken(refreshToken.refresh_token);
          }
        }

        this.setState({
          CardOrderSummary: cardOrderSummar,
          TotalPayment: TotalPayment,
          OriginalPayment: OriginalPayment,
          subtotal_price: cardOrderSummar.subtotal,
          delivery_charge: cardOrderSummar.delivery_charge,
        });
      }
    );
  };

  onTipForPackerChange = (e, value) => {
    const re = /^[+]?\d*(?:[.]\d*)?$/;
    let tipForPacker = this.state.tipForPacker;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    let donation = this.state.donation;

    if (re.test(value)) {
      tipForPacker = value;
      this.setState({ tipForPacker: tipForPacker });
    } else if (value == "") {
      this.setState({ tipForPacker: 0 });
    }
    if (this.props.pageName === "delivery-address") {
      this.props.changetipforPackerChange(tipForPacker, TotalPayment);
    }
    this.props.handleTipAmount(
      Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation)
    );
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );
    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);

    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
    });
  };

  onTipForDeliveryBoy = (e, value) => {
    const re = /^[+]?\d*(?:[.]\d*)?$/;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForPacker = this.state.tipForPacker;
    let donation = this.state.donation;
    console.log(value);
    if (re.test(value)) {
      tipForDeliveryboy = value;
      this.setState({ tipForDeliveryboy: tipForDeliveryboy });
    } else if (value == "") {
      this.setState({ tipForDeliveryboy: 0 });
    }

    if (this.props.pageName === "delivery-address") {
      this.props.changetipForDeliveryboyChange(tipForDeliveryboy, TotalPayment);
    }
    this.props.handleTipAmount(
      Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation)
    );
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );
    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
    });
  };

  onTipForDonation = (e, value) => {
    const re = /^[+]?\d*(?:[.]\d*)?$/;
    let donation = this.state.donation;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForPacker = this.state.tipForPacker;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    if (re.test(value)) {
      donation = value;
      this.setState({ donation: donation });
    } else if (value == "") {
      this.setState({ donation: 0 });
    }

    if (this.props.pageName === "delivery-address") {
      this.props.changedonationChange(donation, TotalPayment);
    }
    this.props.handleTipAmount(
      Number(tipForPacker) + Number(tipForDeliveryboy) + Number(donation)
    );
    localStorage.setItem(
      "tipAmount",
      JSON.stringify({
        tipForPacker,
        tipForDeliveryboy,
        donation,
      })
    );
    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
    });
  };

  onTipForPackerBlur = () => {
    let tipForPacker = this.state.tipForPacker;
    let TotalPayment = this.state.TotalPayment;
    let OriginalPayment = this.state.OriginalPayment;
    let tipForDeliveryboy = this.state.tipForDeliveryboy;
    let donation = this.state.donation;

    TotalPayment =
      Number(tipForPacker) +
      Number(OriginalPayment) +
      Number(tipForDeliveryboy) +
      Number(donation);
    this.setState({
      TotalPayment: TotalPayment.toFixed(2),
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
      addressTab: false,
    });
  };

  ConfiramCartOrderFunctionlity = (formValues) => {
    CartOrderFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.ConfiramCartOrderFunctionlity(formValues);
            }
          );
        }
      } else if (res.data && res.status === 200) {
        this.setState({ paymentLoader: false, order_id: res.data.order_id });
        localStorage.removeItem("selectedPaymentoption");
        
        this.props.history.history.push({
          pathname: "/order-success",
          state: {
            order_id: res.data.order_id,
            delivery_option: this.props.deliveryOption,
            guest_user_id: localStorage.getItem("Guest_userid"),
          },
        });
      } else {
        this.createSuccessMsg("error", res.errors.data.errors);
      }
    });
  };

  FinalOrderSaveFunction = (xToken = "") => {
    let paymentoption = this.props.paymentOption;
    paymentoption =
      paymentoption +
      (this.props?.customercreditData?.isPaidByCredit ? ",C_Credit" : "");

    if (this.props.isebtpayment) {
      if (paymentoption.split(",").includes("ebt") === false)
        paymentoption = paymentoption + ",ebt";
    }
    const carddata = this.props.selectedCardData;
    const cardD = this.props.cardDetailData;
    let formValues = {
      subtotal_price: this.state.subtotal_price,
      total_price: Number(this.state.TotalPayment).toFixed(2),
      discount: this.state.CardOrderSummary.discount,
      shipping_charges: this.state.CardOrderSummary.shipping_price,
      tax: this.state.CardOrderSummary.Estimated_tax,
      tip_for_packer: this.state.tipForPacker ? this.state.tipForPacker : 0,
      tip_for_delivery_boy: this.state.tipForDeliveryboy
        ? this.state.tipForDeliveryboy
        : 0,
      donation: this.state.donation ? this.state.donation : 0,
      payment_method: (this.props.cartListIte.every(ele=>ele.product_id===null)&&
      this.state.TotalPayment<=0
      )?"cash_payment":paymentoption,
      transaction_id:
        this.props.paymentOption === "cash_payment"
          ? null
          : xToken !== ""
          ? xToken
          : null,
      transaction_type: "credit",

      delivery_charges: this.state.delivery_charge,
      no_pic_del:
        localStorage.getItem("frontend_animation") === "no_delivery_pickup"
          ? true
          : false,
      is_guest: localStorage.getItem("Guest_userid") !== null,
      guest_user_id: localStorage.getItem("Guest_userid"),
      ebt_total_price:
        localStorage.getItem("selectedPaymentoption") !== null
          ? this.props.ebtorderAmount
          : 0,
      other_total_price:
        (this.props.ebtorderAmount !== 0 &&
          this.state.isShowEBT &&
          this.props.isebtpayment) ||
        this.props?.customercreditData?.isPaidByCredit
          ? (
              parseFloat(this.state.TotalPayment) -
              parseFloat(this.props.ebtorderAmount) -
              (this.props?.customercreditData?.isPaidByCredit
                ? parseFloat(this.props?.customercreditData?.paymentAmount)
                : 0)
            )?.toFixed(2)
          : localStorage.getItem("selectedPaymentoption") === null ||
            this.props.paymentOption !== "ebt"
          ? parseFloat(this.state.TotalPayment).toFixed(2)
          : 0,
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      extra_charges_price: this.state.CardOrderSummary?.extra_charges_price,
      invoice: Date.parse(new Date()),
      customer_credit_price: this.props?.customercreditData?.isPaidByCredit
        ? this.props?.customercreditData?.paymentAmount
        : 0,
    };
    if (this.props.paymentOption === "card_payment") {
      let cardinfo = {};
      if (cardD.cardNumber !== undefined && cardD.cardNumber !== "") {
        cardinfo = {
          cvv: cardD.cardCVV,
          card_holder_name: cardD?.cardHolderName ? cardD?.cardHolderName : "",
          expire_date: cardD.cardMonth + cardD.cardYear,
          transaction_id: cardD.cardNumber.split(" ").join(""),
          exp: cardD.cardMonth + cardD.cardYear,
        };
      } else if (carddata.token !== undefined && carddata.token !== "") {
        cardinfo = {
          cvv: carddata.cvv,
          card_holder_name: carddata?.cardHolderName
            ? carddata?.cardHolderName
            : "",
          expire_date: carddata.card_exp_date,
          transaction_id: carddata.token,
          expire_date: carddata.card_exp_date,
        };
      }
      formValues = { ...formValues, ...cardinfo };
    }
    let extrachar = {};
    if (
      this.state.CardOrderSummary.extra_charges_price !== null &&
      this.state.CardOrderSummary.extra_charges_price !== 0 &&
      this.state.CardOrderSummary.extra_charges_name !== null &&
      this.state.CardOrderSummary.extra_charges_name !== ""
    ) {
      extrachar = {
        extra_charges_price:
          this.state.CardOrderSummary.extra_charges_price !== null
            ? this.state.CardOrderSummary.extra_charges_price
            : 0,
        extra_charges_name: this.state.CardOrderSummary.extra_charges_name,
      };
    }
    formValues = { ...formValues, ...extrachar };
    if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
      formValues = {
        ...formValues,
        ...{
          sales_rap_id: parseInt(JSON.parse(localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id))?.user_id),
        },
      };
    }
    console.log(formValues);

    this.ConfiramCartOrderFunctionlity(formValues);
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.user_id) {
        sweetAlertBox('Attention!!',msg.user_id[0],"info")
      
        this.setState({
          // error: msg.user_id[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.cart) {
        sweetAlertBox('Attention!!',msg.cart[0],"info")
      
        this.setState({
          // error: msg.cart[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.subtotal) {
        sweetAlertBox('Attention!!',msg.subtotal[0],"info")
      
        this.setState({
          // error: msg.subtotal[0],
          // success: false,
          paymentLoader: false,
        });
      } else if (msg && msg.error && msg.error.length > 0) {
        sweetAlertBox('Attention!!',msg.error[0],"info")
      
        this.setState({
          // error: msg.error[0],
          // success: false,
          paymentLoader: false,
        });
      } else {
        sweetAlertBox('Error!!',msg,"error")
      
        this.setState({
          // error: msg,
          // success: false,
          paymentLoader: false,
        });
      }
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
      // window.location.reload(false);
    }
  };

  isNumberKey = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  };

  FinalOrderCardknoxVerify = () => {
    this.setState({
      paymentLoader: true,
    });

    let CardOrderSummary = this.state.CardOrderSummary;

    if ((this.props.cartListIte.every(ele=>ele.product_id===null)&&
    this.state.TotalPayment<=0
    )||
      this.props.paymentOption === "cash_payment" ||
      (CardOrderSummary.ebt_subtotal > 0 &&
        this.state.TotalPayment === CardOrderSummary.ebt_subtotal &&
        this.state.isShowEBT &&
        this.props.paymentOption === "ebt") ||
      (this.state.is_show_customer_credit &&
        this.props.customercreditData.isPaidByCredit &&
        parseFloat(this.props.customercreditData.paymentAmount) ===
          parseFloat(this.state.TotalPayment)) ||
      (this.props.isebtpayment &&
        this.props.customercreditData.isPaidByCredit &&
        parseFloat(
          parseFloat(this.props.ebtorderAmount) +
            parseFloat(this.props.customercreditData.paymentAmount)
        ).toFixed(2) == parseFloat(this.state.TotalPayment))
    ) {
      this.FinalOrderSaveFunction("");
    } else if (this.props.paymentOption !== "") {
      let formValues = {};
      const carddata = this.props.selectedCardData;
      const cardD = this.props.cardDetailData;
      console.log(cardD.cardNumber, carddata.token);
      if (
        this.props.selectedCardData === "" &&
        cardD.cardNumber !== undefined &&
        cardD.cardNumber !== ""
      ) {
        formValues = {
          card_number: cardD.cardNumber.split(" ").join(""),
          expire_date: cardD.cardMonth + cardD.cardYear,
          cvv: cardD.cardCVV,
          card_holder_name: cardD?.cardHolderName ? cardD?.cardHolderName : "",
          amount: this.state.TotalPayment,
          invoice: Date.parse(new Date()),
        };
      } else if (carddata.token !== undefined && carddata.token !== "") {
        formValues = {
          card_number: carddata.token,
          expire_date: carddata.card_exp_date,
          cvv: carddata.cvv,
          card_holder_name: carddata?.cardHolderName
            ? carddata?.cardHolderName
            : "",
          amount: this.state.TotalPayment,
          invoice: Date.parse(new Date()),
          credit_card_id: carddata.id,
        };
        if (carddata.isauthorize === false)
          formValues = { ...formValues, isauthorize: true };
      } else {
        this.createSuccessMsg("error", "Please select payment method");
        return;
      }

      if (
        this.props.selectedCardData === "" &&
        this.props.cardDetailData.isChecked
      ) {
        this.handleSavingCarddetails(formValues);
      } else {
        this.FinalOrderSaveFunction("");
      }
    } else {
      this.createSuccessMsg("error", "Please select payment method");
    }
  };
  handleSavingCarddetails = (data) => {
    let formValues = {
      card_number: this.props.cardDetailData.cardNumber,
      expire_date: data.expire_date,
      card_holder_name: this.props.cardDetailData.cardHolderName,
    };
    CardSaveapi(formValues, this.props.dispatch).then((res) => {
      console.log(res);
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.handleSavingCarddetails(data);
            }
          );
        }
      } else if (res?.errors?._error?.status === 400) {
        this.createSuccessMsg("error", res?.errors?._error?.data?.errors);
      } else {
        this.FinalOrderSaveFunction(data.xToken);
      }
    });
  };

  render() {
    const { pageName, OrderAddressAndDeliveryPost } = this.props;
    const { CardOrderSummary, TotalPayment } = this.state;

    return (
      <div className="cart_main_order">
        <div className={"cart_order_summary"} style={{ bottom: "60px" }}>
          {this.state.paymentLoader ? (
            <>
              <CircularProgress
                style={{
                  display: "flex",
                  color: "#3d9b7b",
                  position: "relative",
                  right: "-35%",
                  "& > * + *": {
                    marginLeft: 2,
                  },
                }}
              />
              <div
                className="main_containerLoader"
                style={{ position: "absolute", top: 0, left: 0 }}
              ></div>
            </>
          ) : pageName === "payment" && window.screen.availWidth > 900 ? (
            <div
              className="bttn-fill main_cart_bttn"
              onClick={() => {
                this.FinalOrderCardknoxVerify();
              }}
            >
              <SecondaryButton Fonttype="other" className="bttn_grn">
                Place Order
              </SecondaryButton>
              <Divider className="mb-3" />
            </div>
          ) : (
            <div
              className="bttn-fill main_cart_bttn"
              onClick={OrderAddressAndDeliveryPost}
            >
              <Link className="bttn_grn">Payment</Link>
            </div>
          )}
          <div className="order_summ">
            <TypographyTextColor Color="heading" component="h5">
              Order Summary
            </TypographyTextColor>
          </div>
          <div className="summary_details">
            <ul>
              <li>
                <div className="summ_det_desc">
                  <TypographyTextColor Color="heading" component="span">
                    Subtotal
                  </TypographyTextColor>
                </div>
                <div className="summ_rate">
                  <TypographyTextColor Color="other" component="span">
                    {CardOrderSummary ? CardOrderSummary.unit : ""}
                    {CardOrderSummary && CardOrderSummary.subtotal > 0
                      ? // isShowEBT
                        //   ? Number(
                        //       CardOrderSummary.subtotal -
                        //         CardOrderSummary.ebt_subtotal
                        //     ).toFixed(2)
                        //   :
                        Number(CardOrderSummary.subtotal).toFixed(2)
                      : "0.00"}
                  </TypographyTextColor>
                </div>
              </li>
              {CardOrderSummary.ebt_subtotal > 0 && this.state.isShowEBT ? (
                <li style={{ fontSize: "12px" }}>
                  <div className="summ_det_desc">
                    <TypographyTextColor Color="heading" component="span">
                      SNAP Eligible
                    </TypographyTextColor>
                  </div>
                  <div className="summ_rate">
                    <TypographyTextColor Color="other" component="span">
                      {CardOrderSummary ? CardOrderSummary.unit : ""}
                      {CardOrderSummary
                        ? Number(CardOrderSummary.ebt_subtotal).toFixed(2)
                        : ""}{" "}
                    </TypographyTextColor>
                  </div>
                </li>
              ) : (
                <></>
              )}
              {CardOrderSummary.discount === 0 ? (
                <></>
              ) : (
                <li>
                  <div className="summ_det_desc">
                    <TypographyTextColor Color="heading" component="span">
                      Discount
                    </TypographyTextColor>
                  </div>
                  <div className="summ_rate">
                    <TypographyTextColor
                      Color="other"
                      component="span"
                      className="discount_rate"
                    >
                      {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                      {CardOrderSummary
                        ? Number(CardOrderSummary.discount).toFixed(2)
                        : ""}
                    </TypographyTextColor>
                  </div>
                </li>
              )}
              {JSON.parse(localStorage.getItem("slot"))?.schedule === 3 ? (
                <li>
                  <div className="summ_det_desc">
                    <TypographyTextColor Color="heading" component="span">
                      Shipping Charge
                    </TypographyTextColor>
                  </div>
                  <div className="summ_rate">
                    <TypographyTextColor Color="other" component="span">
                      {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                      {CardOrderSummary
                        ? Number(CardOrderSummary.shipping_price).toFixed(2)
                        : ""}
                      {}
                    </TypographyTextColor>
                  </div>
                </li>
              ) : (
                <></>
              )}
              <li>
                <div className="summ_det_desc">
                  <TypographyTextColor Color="heading" component="span">
                    Estimated Tax
                  </TypographyTextColor>
                </div>
                <div className="summ_rate">
                  <TypographyTextColor Color="other" component="span">
                    {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                    {CardOrderSummary
                      ? Number(CardOrderSummary.Estimated_tax).toFixed(2)
                      : ""}
                  </TypographyTextColor>
                </div>
              </li>
              {CardOrderSummary.extra_charges_name !== "" &&
              CardOrderSummary.extra_charges_name !== null &&
              CardOrderSummary.extra_charges_price !== 0 &&
              CardOrderSummary.extra_charges_price !== null ? (
                <li>
                  <div className="summ_det_desc">
                    <TypographyTextColor
                      Color="heading"
                      component="span"
                      style={{ textTransform: "capitalize" }}
                    >
                      {CardOrderSummary.extra_charges_name}
                    </TypographyTextColor>
                  </div>
                  <div className="summ_rate">
                    <TypographyTextColor Color="other" component="span">
                      {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                      {CardOrderSummary &&
                      CardOrderSummary.extra_charges_price > 0 &&
                      CardOrderSummary.extra_charges_price !== null
                        ? Number(CardOrderSummary.extra_charges_price).toFixed(
                            2
                          )
                        : "0.00"}
                    </TypographyTextColor>
                  </div>
                </li>
              ) : (
                <></>
              )}
              {CardOrderSummary.delivery_charge === 0 ? (
                <></>
              ) : (
                <li>
                  <div className="summ_det_desc">
                    <TypographyTextColor Color="heading" component="span">
                      Delivery Charges
                    </TypographyTextColor>
                  </div>
                  <div className="summ_rate">
                    <TypographyTextColor Color="other" component="span">
                      {CardOrderSummary ? CardOrderSummary.unit : ""}
                      {CardOrderSummary
                        ? Number(CardOrderSummary.delivery_charge).toFixed(2)
                        : ""}{" "}
                    </TypographyTextColor>
                  </div>
                </li>
              )}
            </ul>
            {this.state.extraCharges.tip_for_packer === false &&
            this.state.extraCharges.tip_for_delivery_boy === false &&
            this.state.extraCharges.donation === false ? (
              <></>
            ) : (
              <ul className="tip_don">
                {this.state.extraCharges.tip_for_packer === true ? (
                  <li>
                    <div className="summ_det_desc">
                      <TypographyTextColor Color="heading" component="span">
                        Tip for Packer
                      </TypographyTextColor>
                    </div>
                    <div className="summ_rate">
                      <TypographyTextColor
                        Color="other"
                        component="span"
                        className="dollar"
                      >
                        ${" "}
                      </TypographyTextColor>
                      <input
                        type="text"
                        value={this.state.tipForPacker}
                        onChange={(e) =>
                          this.onTipForPackerChange(e, e.target.value)
                        }
                        // onkeypress={(event) => this.isNumberKey(event)}
                      />
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {this.state.extraCharges.tip_for_delivery_boy === true ? (
                  <li>
                    <div className="summ_det_desc">
                      <TypographyTextColor Color="heading" component="span">
                        Delivery Tip
                      </TypographyTextColor>
                    </div>
                    <div className="summ_rate">
                      <TypographyTextColor
                        Color="other"
                        component="span"
                        className="dollar"
                      >
                        ${" "}
                      </TypographyTextColor>
                      <input
                        type="text"
                        value={this.state.tipForDeliveryboy}
                        // onBlur={() => this.onTipForPackerBlur()}
                        onChange={(e) =>
                          this.onTipForDeliveryBoy(e, e.target.value)
                        }
                        className="auto-fill"
                      />
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {this.state.extraCharges.donation === true ? (
                  <li>
                    <div className="summ_det_desc">
                      <TypographyTextColor
                        Color="heading"
                        component="span"
                        className="d-flex align-items-center"
                      >
                        Donation(
                        <a
                          href={this.state.extraCharges.donation_url}
                          target="_blank"
                        >
                          <TypographyFontColor
                            border={0}
                            className="change-add"
                          >
                            {" "}
                            {this.state.extraCharges.donation_name}
                          </TypographyFontColor>
                        </a>
                        )
                      </TypographyTextColor>
                    </div>
                    <div className="summ_rate">
                      <TypographyTextColor
                        Color="other"
                        component="span"
                        className="dollar"
                      >
                        ${" "}
                      </TypographyTextColor>
                      <input
                        type="text"
                        value={this.state.donation}
                        // onBlur={() => this.onTipForPackerBlur()}
                        onChange={(e) =>
                          this.onTipForDonation(e, e.target.value)
                        }
                      />
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            )}
          </div>
          <div className="summ_total">
            <div className="cart_total">
              <TypographyTextColor Color="heading" component="span">
                Total
              </TypographyTextColor>
            </div>
            <div className="total_price">
              <TypographyTextColor Color="heading" component="span">
                ${TotalPayment ? Number(TotalPayment).toFixed(2) : 0}
              </TypographyTextColor>
            </div>
          </div>
        </div>

        <div
          className={
            window.screen.availWidth <= 900
              ? "cart_order_bttn position-absolute w-100"
              : "cart_order_bttn"
          }
          style={{ bottom: 0 }}
        >
          <div className="mobile_cart_order">
            {this.props.addressShowHide === false ? (
              this.state.paymentLoader && window.screen.availWidth <= 900 ? (
                <>
                  <CircularProgress
                    style={{
                      display: "flex",
                      color: "#3d9b7b",
                      position: "relative",
                      right: "-35%",
                      "& > * + *": {
                        marginLeft: 2,
                      },
                    }}
                  />
                  <div
                    className="main_containerLoader"
                    style={{ position: "absolute", top: 0, left: 0 }}
                  ></div>
                </>
              ) : (
                <>
                  <div className="order_rate">
                    <p>${TotalPayment ? Number(TotalPayment).toFixed(2) : 0}</p>
                    <span>DETAILS</span>
                  </div>
                  {pageName === "payment" ? (
                    <div
                      className="bttn-fill"
                      onClick={() => this.FinalOrderCardknoxVerify()}
                    >
                      <SecondaryButton Fonttype="other" className="bttn_grn">
                        Continue
                      </SecondaryButton>
                    </div>
                  ) : (
                    <div
                      className="bttn-fill"
                      onClick={OrderAddressAndDeliveryPost}
                    >
                      <SecondaryButton
                        component={Link}
                        Fonttype="other"
                        className="bttn_grn"
                      >
                        Continue
                      </SecondaryButton>
                    </div>
                  )}
                </>
              )
            ) : (
              ""
            )}
          </div>
        </div>

        <CardsavePopup
          createSuccessMsg={(type, msg) => this.createSuccessMsg(type, msg)}
          FinalOrderCardknoxVerify={() => this.FinalOrderCardknoxVerify()}
          cardType={this.props.cardType}
          onCardHandleChange={(e) => this.props.onCardHandleChange(e)}
          cardDetailData={this.props.cardDetailData}
          isopen={this.props.isNewcard}
          handleClose={() => this.props.handleCloseCardPopup()}
          paymentLoader={this.state.paymentLoader}
          type="paymentpage"
          paymentOption={this.props.paymentOption}
          ebtcardNumber={this.props.ebtcardNumber}
          handleEBTcardno={(e) => this.props.handleEBTcardno(e)}
          isebtCardSave={this.props.isebtCardSave}
          handleaddebtCard={(cardnumber, iscardsave) =>
            this.props.handleaddebtCard(cardnumber, iscardsave)
          }
        />

        {this.state.success === false && this.state.error === false ? (
          ""
        ) : (
          <Commonsnackbar
            success={this.state.success}
            handleClose={this.handleClose}
            error={this.state.error}
          />
        )}
      </div>
    );
  }
}

export default OrderSummary;
