import React from "react";
import { Link } from "react-router-dom";
import { Snackbar, IconButton, Zoom, CircularProgress } from "@mui/material";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import ItemDetailsModal from "./common/ProductDetail/item-details-modal";
import CategoryListing from "./common/Category/categoryListing";
import CategoriesModal from "./common/Category/categoriesModal";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import {
  CategeriesListView,
  ProductsListListingPage,
  ProductDetailsApi,
  WishListFunctionlity,
  AddToCartFunctionlity,
  UpdateCartFunctionlity,
  GuestLoginAthentication,
  FilterListApiByBrandFlavorKosher,
  CategoryTreeViewApi,
  SubCategeriesListView,
  PopularProductsList,
  MostOrderedProductList,
} from "../actions/home/homeAction";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  CommonAddQtyFunction,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  commonPageTitel,
  RefreshApiToken,
  getSchedule_zip,
  sweetAlertBox,
  SinglecheckImageUrls,
} from "../utils/renderUtils/renderUtil";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  AddFavoriteLevelFunctionlity,
  favoriteLevelListFunctionlity,
  AddFavoriteProductFunctionlity,
  RemoveFavouriteProduct,
} from "../actions/favorite/favoriteAction";
import ItemLoaderCard from "./common/Loader/ItemLoaderCard";
import Loginpopup from "./login/Loginpopup";
import CustomChips from "./CustomChips";
import CustomPreloader from "./common/Loader/CustomPreloader";
import { GetSettingsData } from "../actions/profile/profileAction";
import ItemCard from "./common/ProductCard/ItemCard";
import SwipeableEdgeDrawer from "./common/Header/Mobile/SwipeableEdgeDrawer";
import {
  ScrollColorTypography,
  TypographyFontColor,
  TypographyTextColor,
  lightenColor,
} from "./common/UiDesign";
import ItemCardMob from "./common/ProductCard/Mobile/ItemCardMob";
import DetailpageMob from "./common/ProductDetail/Mobile/DetailpageMob";
import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { BiGridAlt, BiGridSmall } from "react-icons/bi";
import { GoAlert } from "react-icons/go";
import SideCateogorySkeleton from "./common/Skeleton/SideCateogorySkeleton";
import ItemCardCatering from "./common/ProductCard/ItemCardCatering";
import _ from "lodash";
import ApiUrls from "../utils/apiUtils/apiUrl";
const UpdateAPIDebounced = AwesomeDebouncePromise(UpdateCartFunctionlity, 50);

class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      itemDetails: false,
      openSearch: false,
      openCategory: false,
      open: false,
      pageName: "listing_page",
      CategoryDetails: [],
      ProductList: [],
      addTocart: true,
      addCount: false,
      itemCount: 1,
      ProductDetails: [],
      extra_info: true,
      loading: false,
      page: 0,
      prevY: 0,
      max_price: 0,
      min_price: 0,
      category_id: "",
      subcategory_id: "",
      subsubcategory_id: "",
      checkboxChecked: false,
      ProductCartArray: [],
      categoryName: "",
      subcategoryName: "",
      cartItemList: [],
      SubTotal: 0,
      CartCount: [],
      categoryIds: [],
      cartListOpen: false,
      next_rec: false,
      prev_rec: false,
      is_next_rec: false,
      is_prev_rec: false,
      brand_page: 1,
      cartLoader: true,
      brandProductLoader: false,
      cartListLoader: false,
      cartListIndex: 0,
      shownextArrow: true,
      showprvArrow: true,
      BrandProduct: [],
      brandIds: [],
      brandName: [],
      flavor: [],
      filterByFlavor: [],
      kosher: [],
      filterByKosher: [],
      fruitType: [],
      filterByType: [],
      filterName: "All",
      categoryNames: [],
      categoryData: [],
      FilterForMobileView: false,
      FruitTypeForMobleView: [],
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      resultBrandData: [],
      disabled: false,
      TreeViewIteams: [],
      TreeViewIteamsSpecial: [],
      listOpen: false,
      uncheckPrice: true,
      productlistLength: 0,
      total: undefined,
      pageLimit: 24,
      error: false,
      CategoryListCall: true,
      Props_value: this.props.history ? this.props.history : "",
      chack_is_case: [],
      check_isCase: false,
      SubCategoryList: [],
      SubSubCategoryList: [],
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      subCategoryLoader: false,
      open_inner: false,
      bodyInnerClass: false,
      specials: "",
      CategoryOption: [],
      favoriteLevelListData: [],
      special_filter: "all",
      special_filter_value: "",
      brand_total: "",
      flavor_total: "",
      kosher_total: "",
      type_total: "",
      container: [],
      containerLength: 0,
      containerValue: "",
      container_type: "",
      pro_item_details_ind: -1,
      AddtoCartBtn: true,
      inputField: false,
      headerinputField: false,
      product_deal: "",
      cat_id: "",
      SubSubCategoryName: "",
      top_menu_value: "",
      extraCharges: [],
      footerDetails: [],
      slotName: "",
      setOpenLoginPopup: false,
      isLoginCLostBtnShow: true,
      isslotDeliverypopupState_compwill: false,
      order: "name",
      scrollLoading: false,
      CategoryListLoader: true,
      FilterListLoader: true,
      child_items: [],
      selectedChilditem: "",
      Parent_item: [],
      mobileView: false,
      searchResultMsg: "",
      toggleMenuCategory: false,
      emailSubscribe: "",
      ProductListData: [],
      viewGroupcategory: [],
      parentdata: [],
      tabingkey: "brand",
      tree_data: [],
      selectedCategory: "",
      CurrentHightlightedcategory: "",
      customChipsData: "",
      categoryBreadcrumbs: "",
      SideCategoryListLoader: false,
      sortingData: [
        { name: "Alphabetical A-Z", value: "name" },
        { name: "Alphabetical Z-A", value: "z-aName" },
        { name: "Price: Low to High", value: "price_low_to_high" },
        { name: "Price: High to Low", value: "price_high_to_low" },
      ],
      isScrollObserver: false,
      humbergerBtn: false,
      itemDetailsmobModal: false,
      cardWidth: "",
      cardSize: "small",
      currentScreenSize: "",
      PopularProductList: [],
      restrict_by_onhand: JSON.parse(localStorage.getItem("Store_address"))
        ?.restrict_by_onhand,
      CustomCategoryMessage: this.props.history.location.state?.categoryviewdata
        ?.category_message
        ? this.props.history.location.state?.categoryviewdata?.category_message
        : this.props.history.location.state?.customCategoryMsg
        ? this.props.history.location.state?.customCategoryMsg
        : "",
      isSwipeableOpen: false,
      expandedSwipeable: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      Store_Data: JSON.parse(localStorage.getItem("Store_address")),
      SideCategoryListLoader: false,
      currentVisibleCategoryIds: "",
      viewPointCategoryIds:''
    };
    this.handlePopOpen = this.handlePopOpen.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.openFilterMobileView = this.openFilterMobileView.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    this.handleScrollFitlertype = this.handleScrollFitlertype.bind(this);
    this.debouncedHandleScrollFilter = _.debounce(
      this.handleScrollFitlertype,
      1000
    );

    this.myRef = React.createRef();
    this.child = React.createRef();
    this.loadingRef = React.createRef(null);
    this.debouncedScrollHandler = this.debounce(
      this.handleLocalStorageChange,
      100
    );
    // this.headerchild=React.createRef()
  }
  resize = () => {
    this.setState({ mobileView: window.innerWidth <= 900 });
  };
  handleOpenDialog() {
    this.setState({ open: true, open_inner: false, parent_onlyid: [] });
  }
  handlepopularOpen = (value, slug, next, prev, index) => {
    this.FetchProdutDetails(value, slug, next, prev, "", "");
    // let PopularProduct = this.state.PopularProductList;
    // var Proind = PopularProduct.findIndex((p) => p.id === id);
    // console.log(PopularProduct,Proind);
    // this.setState({ProductDetails:PopularProduct[Proind]})
  };
  handlePopOpen(value, slug, next_info, pre_info, index, categoryindx) {
    if (index === 0) {
      pre_info = false;
    } else pre_info = true;
    if (index === this.state.ProductList[categoryindx].productList.length - 1) {
      next_info = false;
    } else {
      next_info = true;
    }

    if (this.state.mobileView) {
      this.setState({
        itemDetailsmobModal: true,
        selectedProductindex: index,
        selectedCategoryindex: categoryindx,
        AddtoCartBtn: this.state.AddtoCartBtn,
        is_prev_rec: pre_info,
        is_next_rec: next_info,
        ProductListData: this.state.ProductList[categoryindx]?.productList,
        selectedChilditem: "",
      });
    } else {
      this.setState({
        itemDetails: true,
        selectedProductindex: index,
        selectedCategoryindex: categoryindx,
        AddtoCartBtn: this.state.AddtoCartBtn,
        is_prev_rec: pre_info,
        is_next_rec: next_info,
        ProductListData: this.state.ProductList[categoryindx]?.productList,
      });
    }
    this.FetchProdutDetails(
      value,
      slug,
      next_info,
      pre_info,
      this.state.special_filter,
      this.state.special_filter_value
    );
  }
  toggleMenuCategory() {
    this.setState({ toggleMenuCategory: !this.state.toggleMenuCategory });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  CloseLoginDialog = (type) => {
    if (type === "close") {
      this.setState({ setOpenLoginPopup: false });
      localStorage.removeItem("tempProductData");
    } else {
      if (
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") === "must_login_add_to_cart"
      ) {
        let tempData = JSON.parse(localStorage.getItem("tempProductData"));

        if (tempData !== null) {
          this.AddToCartFunctionlity(
            tempData.product_id,
            tempData.added_quantity,
            tempData.is_case,
            tempData.categoryindex
          );
        }
        localStorage.removeItem("tempProductData");
        if (localStorage.getItem("slot"))
          this.setState({
            setOpenLoginPopup: false,
          });
        else
          this.setState({
            setOpenLoginPopup: false,
            isslotDeliverypopupState_compwill: true,
          });
      } else this.setState({ setOpenLoginPopup: false });
      if (localStorage.getItem("isLogin") === "true") {
        this.FetchCartItemList();
        this.FetchCategoryList();

        this.ExtraChargesFunctiolity();
      }
    }
  };
  OpenLoginPoup = () => {
    
    this.setState({ setOpenLoginPopup: true });
  };
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };

  handleChangeBreadcrumbsonScroll = () => {
    // this.setState({ isScrollObserver: true });

    let categoryD = this.state.viewGroupcategory;
    if (this.state.isScrollObserver) {
      let data = document.getElementsByClassName("activeItem");

      let breadcumbs = this.state.categoryData;
      if (breadcumbs?.length <= 1) {
        // window.removeEventListener(
        //   "scroll",
        //   this.handleChangeBreadcrumbsonScroll,
        //   false
        // );
        return;
      }
      let selectedD = categoryD.filter((x) => x.id === +data[0]?.id);

      if (selectedD.length > 0) {
        let catIndx = breadcumbs
          .map((e) => e.category_id)
          .indexOf(selectedD[0].parent_id);

        if (catIndx === 0 && breadcumbs.length === 3) {
          breadcumbs.splice(1, 1);
        } else if (catIndx === -1) {
          let data =
            this.props.history.location.state?.selectedgroupcat?.category;

          let i = 0;

          for (; i < data?.length; i++) {
            if (data[i].id === selectedD[0].parent_id) break;
          }

          if (breadcumbs.length === 2)
            breadcumbs.splice(1, 0, {
              category_id: data[i].id,
              category_name: data[i].name,
              cslug: data[i].slug,
              parent_id: data[i].parent_id,
            });
          else if (data?.length > 0) {
            breadcumbs[1] = {
              category_id: data[i]?.id,
              category_name: data[i].name,
              cslug: data[i].slug,
              parent_id: data[i].parent_id,
            };
          }
        }

        breadcumbs.pop();
        breadcumbs.push({
          category_id: selectedD[0].id,
          category_name: selectedD[0].name,
          cslug: selectedD[0].slug,
          parent_id: selectedD[0].parent_id,
        });
      }
    }
  };

  handleLocalStorageChange = () => {
    let data = document.getElementsByClassName("activeItem");

    let categoryitem = document.getElementsByClassName("test-active");
    let urlcategoryid = this.props.history.location.pathname.split("/")[2];
    this.handleChangeBreadcrumbsonScroll();
    if (
      (isNaN(+urlcategoryid) === false ||
        this.props.history.location.pathname
          .split("/")[2]
          .includes("SPECIALS")) &&
      this.state.viewGroupcategory.length > 1
    ) {
      if (
        this.state.CurrentHightlightedcategory !== data[0]?.id &&
        data.length > 0
      ) {
        this.setState({
          CurrentHightlightedcategory: data[0].id,
          // categoryData: breadcumbs,
        });

        this.setState({
          flavor: [],
          kosher: [],
          fruitType: [],
        });
        console.log("calling filter");
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   +data[0].id,
        //   this.state.cat_id,
        //   this.state.brandIds,
        //   [],
        //   this.state.specials,
        //   this.state.product_deal,
        //   this.state.top_menu_value
        // );
        if (window.screen.availWidth >= 900) {
          this.handleChangeBreadcrumbsonScroll();
        }
      }
    }
  };

  debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  handleResize = (cardWidthData = "") => {
    let size = document.getElementById("allproduct").offsetWidth;
    let cardSize = cardWidthData === "" ? this.state.cardSize : cardWidthData;
    let cardWidth = this.state.cardWidth;
    let noOfCards = 0;
    if (this.state.currentScreenSize !== size) {
      if (size <= 900) {
        noOfCards = cardSize === "small" ? 4 : 4;
      } else if (size > 900 && size <= 1200) {
        noOfCards = cardSize === "small" ? 6 : 4;
      } else if (size > 1200 && size < 1800) {
        noOfCards = cardSize === "small" ? 8 : 6;
      } else {
        noOfCards = cardSize === "small" ? 9 : 8;
      }
      cardWidth = `calc(100%/${noOfCards})`;
      if (document.getElementById("allproduct")?.offsetWidth <= 2500)
        this.setState({
          cardWidth,
          currentScreenSize: document.getElementById("allproduct")?.offsetWidth,
        });
      else
        this.setState({
          cardWidth: "185px",
          currentScreenSize: document.getElementById("allproduct")?.offsetWidth,
        });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debouncedHandleScrollFilter);

    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.debouncedScrollHandler);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }

    // if (
    //   this.props.history.location.state?.customCategoryMsg !==
    //     this.state.CustomCategoryMessage
    // )
    this.setState({
      CustomCategoryMessage: this.props.history.location.state?.categoryviewdata
        ?.category_message
        ? this.props.history.location.state?.categoryviewdata?.category_message
        : this.props.history.location.state?.customCategoryMsg
        ? this.props.history.location.state?.customCategoryMsg
        : "",
    });
  }
  handleScrollFitlertype() {
    let viewCategoryIds = [];
    let headerHeight =
      document.querySelector("header")?.getBoundingClientRect()?.height + 300;
    document
      .querySelectorAll("[data-segment-category-id]")
      .forEach(function (element) {
        let rect = element.getBoundingClientRect();
        let segmentValue = element.getAttribute("data-segment-category-id");
        let adjustedTop = rect.top - 200;

        let isvisible =
          adjustedTop <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.bottom >= 0 &&
          rect.left <=
            (window.innerWidth || document.documentElement.clientWidth) &&
          rect.right >= 0;
        if (isvisible) {
          viewCategoryIds.push(segmentValue);
        }
      });
    viewCategoryIds = viewCategoryIds.join(",");
    if (
      viewCategoryIds !== "" &&
      this.state.brandIds.length === 0 &&
      viewCategoryIds !== this.state.currentVisibleCategoryIds
    ) {
      this.setState({ currentVisibleCategoryIds: viewCategoryIds });
      this.FetchFilterApiByBrandFlavorKosher(
        "All",
        1,
        viewCategoryIds,
        this.state.cat_id,
        this.state.brandIds,
        this.state.brandName,
        this.state.specials,
        this.state.product_deal,
        this.state.top_menu_value
      );
    }
  }
  componentDidMount() {
    try {
      
      this.setState({
        pageLimit:
          document.getElementById("allproduct")?.offsetWidth < 2000 ? 24 : 45,
      });
      if (
        this.state.Store_Data?.default_listing_card_size !== null ||
        this.state.Store_Data?.default_listing_card_size !== ""
      ) {
        this.handleResize(
          this.state.Store_Data?.default_listing_card_size === "Small"
            ? "small"
            : "big"
        );
        this.setState({
          cardSize:
            this.state.Store_Data?.default_listing_card_size === "Small"
              ? "small"
              : "big",
        });
      }
      this.resize();
      // this.handleResize();
      document.body.style.overflow = "hidden";
      // document.getElementById("main-listing").scrollTop=0
      if (window.screen.availWidth >= 900) {
        window.addEventListener("scroll", this.debouncedScrollHandler);
        // if(this.props.match.params.id.includes("q=")===false)
        window.addEventListener("scroll", this.debouncedHandleScrollFilter);
        window.addEventListener("resize", this.handleResize);
      } else {
        this.setState({ isScrollObserver: true });
        this.FetchPopularProductList();
      }

      const accessToken = localStorage.getItem("access_token");
      let logo = this.state.logo;
      const accessTokenForLogo = JSON.parse(
        localStorage.getItem("access_token")
      );
      logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
      // document.title = commonPageTitel(
      //   JSON.parse(localStorage.getItem("access_token"))
      // );
      let categoryId = this.state.category_id;
      let brandIds = this.state.brandIds;
      let categoryIds = this.state.categoryIds;
      let parentIds = this.state.parentIds;
      let specials = this.state.specials;
      let special_filter = this.state.special_filter;
      let special_filter_value = this.state.special_filter_value;
      let top_menu_value = this.state.top_menu_value;
      let product_deal = "";
      let pro_deal = this.props.match.params.id.split("productdeal=");
      let pathName = this.props.match.params.id.split("brand=");
      let pathNameCampaign = this.props.match.params.id.split("campaign=");
      let brandName = this.props.history.location?.state?.brand_name;
      if (
        (pro_deal[1] !== "" && pro_deal !== undefined) ||
        brandName !== undefined
      ) {
        this.setState({
          customChipsData:
            brandName !== undefined
              ? brandName
              : pro_deal[1]?.replaceAll("_", " "),
        });
      }
      if (
        this.props.match.params &&
        this.props.match.params.id &&
        pathName.length === 1 &&
        pathNameCampaign.length === 1 &&
        pro_deal.length === 1 &&
        accessToken
      ) {
        this.setState({ ProductList: [] });
        categoryId = this.props.match.params.id;
        if (
          isNaN(categoryId) === true &&
          this.props.match.params.id.includes("q=")
        ) {
          specials = categoryId;
          categoryId =
            this.props.location.state && this.props.location.state.category_id
              ? this.props.location.state.category_id
              : "";
          categoryIds = [];

          special_filter =
            this.props.location.state && this.props.location.state.campaign
              ? this.props.location.state.campaign
              : "";
          special_filter_value =
            this.props.location.state &&
            this.props.location.state.special_filter_value
              ? this.props.location.state.special_filter_value
              : this.props.match && this.props.match.params
              ? this.props.match.params.id.replace("q=", "")
              : "";

          this.callSpacialFunction(
            specials,
            categoryId,
            "",
            "",
            this.state.page
          );
          this.FetchCategoryTreeView();
        } else {
          this.setState({ product_deal: "", brandIds: [], brandName: [] });

          this.FetchDataForPriceRange(
            "",
            "",
            categoryId,
            false,
            "initial",
            false
          );
        }
      } else if (pro_deal.length === 2) {
        console.log("componentdid else if pro_deal.length === 2");
        this.FetchProductList(
          1,
          this.state.filterByType,
          this.state.filterByKosher,
          this.state.filterByFlavor,
          this.state.brandIds,
          this.state.min_price,
          this.state.max_price,
          this.state.category_id,
          this.state.specials,
          pro_deal[1],
          this.state.top_menu_value,
          this.state.order
        );
      } else if (
        this.props.location &&
        this.props.location.query &&
        this.props.location.query.category_id
      ) {
        categoryId = this.props.location.query.category_id;
      } else if (
        pathNameCampaign &&
        pathNameCampaign.length > 1 &&
        accessToken
      ) {
        if (
          pathNameCampaign.includes("POPULAR PRODUCT") ||
          pathNameCampaign.includes("RECENTLY ORDERED")
        )
          this.fetchMostOrder(pathNameCampaign[1]);
        else {
          categoryId = pathNameCampaign[1];
          if (isNaN(categoryId) === true) {
            top_menu_value = categoryId;
            categoryId =
              this.props.location.state && this.props.location.state.category_id
                ? this.props.location.state.category_id
                : "";
            categoryIds = [];

            special_filter =
              this.props.location.state && this.props.location.state.campaign
                ? this.props.location.state.campaign
                : "";
            special_filter_value =
              this.props.location.state &&
              this.props.location.state.special_filter_value
                ? this.props.location.state.special_filter_value
                : this.props.match && this.props.match.params
                ? this.props.match.params.id
                : "";

            this.callSpacialFunction(
              "",
              "",
              pathNameCampaign[1],
              "",
              this.state.page
            );
          }
        }
      } else if (pathName && pathName.length > 1 && accessToken) {
        brandIds.push(parseInt(pathName[1]));
        console.log("componentdid else if pathName.length > 1 ");
        this.FetchProductList(
          1,
          this.state.filterByType,
          this.state.filterByKosher,
          this.state.filterByFlavor,
          pathName[1],
          this.state.min_price,
          this.state.max_price,
          this.state.category_id,
          this.state.specials,
          this.state.product_deal,
          this.state.top_menu_value,
          this.state.order
        );
        this.FetchFilterApiByBrandFlavorKosher(
          "All",
          1,
          this.state.category_id,
          this.state.cat_id,
          this.state.brandIds,
          [],
          this.state.specials,
          this.state.product_deal,
          this.state.top_menu_value
        );
      } else {
        specials = this.props.location.state
          ? this.props.location.state.special_filter_value
          : "";
        special_filter = "all";
        special_filter_value =
          this.props.location.state &&
          this.props.location.state.special_filter_value
            ? this.props.location.state.special_filter_value
            : "";
        categoryId = "";
        categoryIds = [];
        product_deal =
          this.props.location.state && this.props.location.state.deal_name
            ? this.props.location.state.deal_name
            : "";
      }

      this.setState({
        category_id: categoryId,
        brandIds: brandIds,
        categoryIds: categoryIds,
        parentIds: parentIds,
        Props_value: this.props.history,
        specials: specials,
        special_filter: special_filter,
        special_filter_value: special_filter_value,
        product_deal: product_deal,
        logo: logo,
        top_menu_value: top_menu_value,
      });
      if (accessToken && localStorage.getItem("isLogin") === "false") {
        let options = {
          root: null,
          rootMargin: window.innerHeight > 1000 ? "150px" : "150px",
          threshold: 1,
        };
        this.observer = new IntersectionObserver(
          this.handleObserver.bind(this),
          options
        );
        this.observer.observe(this.loadingRef);

        this.FetchCartItemList();
        this.FetchCategoryList();
        if (
          this.props.history.location.pathname
            .split("/")[2]
            .includes("SPECIALS")
        )
          this.FetchCategoryTreeView(true);
        this.FetchCategoryTreeView(false);
        this.ExtraChargesFunctiolity();
      } else if (accessToken === "undefined" || accessToken === null) {
        this.HitGuestApiToken();
      } else if (accessToken && localStorage.getItem("isLogin") === "true") {
        let options = {
          root: null,
          rootMargin: window.innerHeight > 1000 ? "150px" : "150px",
          threshold: 1,
        };
        this.observer = new IntersectionObserver(
          this.handleObserver.bind(this),
          options
        );
        this.FetchSettingData();
        this.observer.observe(this.loadingRef);
        this.FetchCartItemList();
        this.FetchCategoryList();
        if (
          this.props.history.location.pathname
            .split("/")[2]
            .includes("SPECIALS")
        )
          this.FetchCategoryTreeView(true);
        this.FetchCategoryTreeView(false);
        this.ExtraChargesFunctiolity();
      }
    } catch (err) {
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    }
  }
  fetchMostOrder = (menu) => {
    let formValues = {
      recently_ordered: menu === "RECENTLY ORDERED" ? true : false,
    };

    MostOrderedProductList(formValues, this.props.dispatch).then((res) => {
      console.log(res);
      let productList = [];

      let categoryData = this.state.categoryData;
      let productlistLength = 0;
      let parent_onlyid = [];
      let total = undefined;
      let msgsearch = "";
      let categorydata = this.state.viewGroupcategory;
      if (res.data && res.data.result) {
        productList = res.data.result;
        productlistLength = res.data.length;

        total = res.data.total;
        if (productlistLength === 0) {
          msgsearch = (
            <>
              <div> We're sorry. We were not able to find a match.</div>
              <div className="pt-5">Try Another Search ?</div>
            </>
          );
        } else {
          msgsearch = "";
        }
        if (this.state.ProductList.length === 0) {
          if (res.data && res.data.cat_data) {
            categoryData = res.data.cat_data;
          }
          for (let i = 0; i < categoryData.length; i++) {
            parent_onlyid.push(categoryData[i].category_id);
          }
        }
        let groupedproductlist = [...this.state.ProductList];
        let parentdata;
        parentdata = {
          name: "Search result (" + menu + ")",
          cat_count: res.data.total,
        };
        if (groupedproductlist.length === 0) {
          groupedproductlist.push({
            parentName: parentdata,
            productList,
          });
        } else {
          groupedproductlist[0].parentName = parentdata;
          groupedproductlist[0].productList = [
            ...groupedproductlist[0].productList,
            ...productList,
          ];
        }
        this.setState({
          ProductList: groupedproductlist,
          categoryData: categoryData,
          productlistLength: productlistLength,
          loading: false,
          parent_onlyid: parent_onlyid,
          total: total,
          scrollLoading: true,
          CategoryListLoader: false,
          // top_menu_value: top_menu,
          listingFormValues: formValues,
          searchResultMsg: msgsearch,
          categoryBreadcrumbs: res.data.cat_data,
        });
        document.body.style.overflow = "auto";
      } else if (res.errors && res.errors._error.status === 401) {
        
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          // this.setState({ productList: "", productlistLength: 0, total: 0 });
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.fetchMostOrder();
          });
        }
      }
    });
  };
  ExtraChargesFunctiolity = () => {
    
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();

    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image =ele?.cloudinary_cat_images_global
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  FetchCategoryTreeView = (is_special = false) => {
    let formValues = getSchedule_zip();
    formValues.is_special = is_special;
    this.setState({ SideCategoryListLoader: true });
    CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
      let TreeViewIteams = [];
      if (res.data && res.data.result) {
        TreeViewIteams = res.data.result;
        var tree_data = this.onbuildForTreeData(TreeViewIteams, null);
        tree_data.sort(function (a, b) {
          return a.cat_order - b.cat_order;
        });
        let urlcategoryid = this.props.history.location.pathname.split("/")[2];

        if (isNaN(+urlcategoryid) === false) {
          this.bindCategorygroupdata(tree_data);
        }
        if (is_special) {
          this.bindCategorygroupdata(tree_data);
          this.setState({
            TreeViewIteamsSpecial: TreeViewIteams,
          });
        }
        this.setState({
          tree_data: tree_data,
          TreeViewIteams: TreeViewIteams,
          SideCategoryListLoader: false,
        });

        window.scrollTo(0, 0);
        if (window.screen.availWidth <= 900) {
          if (localStorage.getItem("toggleMenuCategoryMob") === "true") {
            localStorage.setItem("toggleMenuCategoryMob", "false");
            this.toggleMenuCategory();
          }
        }
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryTreeView();
            }
          );
        }
      }
    });
  };

  handleAdjustHeight = (stateData) => {
    let height = document
      .getElementById("filterData")
      .getBoundingClientRect().height;

    if (window.screen.availWidth > 900) {
      if (height > 100)
        document.getElementById("allproduct").style.paddingTop = height + "px";
      else if (
        stateData.BrandProduct.length === 0 &&
        stateData.flavor.length === 0 &&
        stateData.kosher.length === 0
      )
        document.getElementById("allproduct").style.paddingTop = "4rem";
      else
        document.getElementById("allproduct").style.paddingTop =
          "calc(8rem + 50px);";
    }
  };

  handleChangeCheckbox = (brand_ids, brand_name) => {
    window.scrollTo(0, 0);
    // this.child?.current?.handleClearselectionitem();
    let BrandProduct = this.state.BrandProduct;
    let brandName = this.state.brandName;
    console.log(brand_name, brandName);
    let checkBrandID = BrandProduct.findIndex((p) => p.brand_id === brand_ids);

    if (checkBrandID > -1) {
      BrandProduct[checkBrandID]["checkboxChecked"] =
        !BrandProduct[checkBrandID]["checkboxChecked"];
      // BrandProduct[checkBrandID]["checkboxChecked"] = true
    }
    this.setState({ BrandProduct: BrandProduct });
    const selectedIndex = this.state.brandIds.indexOf(brand_ids);
    let newSelected = this.state.brandIds;
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(brand_ids);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    const selectedIndexbrandName = brandName.findIndex(
      (p) => p.brand_id === brand_ids
    );
    let special_filter;
    if (selectedIndexbrandName === -1) {
      let obj = {
        brand_id: brand_ids,
        brand_name: brand_name,
      };
      brandName.push(obj);
    } else if (selectedIndexbrandName === 1) {
      brandName.splice(selectedIndexbrandName, 1);
    } else {
      brandName.splice(selectedIndexbrandName, 1);
    }
    if (newSelected.length > 0) {
      special_filter = "brand";
    } else {
      special_filter = "all";
    }

    this.setState({
      brandIds: newSelected,
      brandName: brandName,
      ProductList: [],
      page: 1,
      product_deal: "",
      special_filter: special_filter,
      special_filter_value: newSelected,
      CategoryListLoader: true,
    });
    let cateogires = this.state.viewGroupcategory;

    let urlcategoryid = this.props.history.location.pathname.split("/")[2];
    let currenthighCat = this.state.CurrentHightlightedcategory;
    let statusselection = this.props.history.location.state?.selectedCategory;
    let categoryid;
    console.log(isNaN(urlcategoryid) === false);
    
    if (statusselection === true) {
      categoryid = urlcategoryid;
    } else {
      if (newSelected.length > 0) categoryid = currenthighCat;
      else if (isNaN(urlcategoryid) === false) {
        categoryid = this.state.viewGroupcategory[0].id;
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   +categoryid,
        //   this.state.cat_id,
        //   [],
        //   [],
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      } else {
        categoryid = "";
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   categoryid,
        //   this.state.cat_id,
        //   newSelected,
        //   brand_name,
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      }
    }
    console.log(
      categoryid,
      BrandProduct?.some((x) => x.checkboxChecked === true)
        ? this.state.categoryData?.at(-1)?.parent_id
        : categoryid
    );

    console.log(
      BrandProduct?.some((x) => x.checkboxChecked === true)
        ? this.state.categoryData?.at(-1)?.parent_id
        : categoryid === ""
        ? cateogires[0]?.id
        : categoryid,
      cateogires,
      categoryid
    );
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      newSelected,
      this.state.min_price,
      this.state.max_price,
      BrandProduct?.some((x) => x.checkboxChecked === true)
        ? this.state.viewPointCategoryIds
        : categoryid === ""
        ? cateogires[0]?.id
        : categoryid,
      this.state.specials,
      this.state.product_deal,
      this.state.top_menu_value,
      this.state.order,
      newSelected.length === 0 &&
        cateogires[0]?.cat_count < 20 &&
        statusselection === false &&
        this.state.viewGroupcategory.length > 1
        ? true
        : false
    );
  };

  handlePopClose = (
    product_id,
    added_quantity,
    added_quantity_is_case,
    is_case
  ) => {
    // this.setState({ProductList: [], chack_is_case : [], page: 1})

    // this.FetchProductList(
    //   1,
    //   this.state.filterByType,
    //   this.state.filterByKosher,
    //   this.state.filterByFlavor,
    //   this.state.brandIds,
    //   this.state.min_price,
    //   this.state.max_price,
    //   this.state.category_id,
    //   this.state.specials,
    //   ''
    // );

    this.setState({ AddtoCartBtn: true, selectedChilditem: "" });

    let ProductList = this.state.ProductList;
    if (added_quantity > 0) {
      for (let i = 0; i < ProductList.length; i++) {
        if (ProductList[i].id === product_id) {
          ProductList[i].added_quantity[this.state.pro_item_details_ind] =
            added_quantity;
          ProductList[i].added_quantity_is_case[
            this.state.pro_item_details_ind
          ] = added_quantity_is_case;
        }
      }
    } else {
      for (let i = 0; i < ProductList.length; i++) {
        if (ProductList[i].id === product_id) {
          ProductList[i].added_quantity[this.state.pro_item_details_ind] = 0;
          ProductList[i].is_case = is_case;
        }
      }
    }

    this.FetchCartItemList();

    this.setState({
      itemDetails: false,
      ProductList: ProductList,
      chack_is_case: [],
      containerValue: "",
    });
  };

  handleChangeCheckboxForFlover = (flavorType) => {
    window.scrollTo(0, 0);
    // this.child?.current?.handleClearselectionitem();
    let flavor = this.state.flavor;
    let checkBrandID = flavor.findIndex((p) => p.flavor === flavorType);
    flavor[checkBrandID]["checkboxChecked"] =
      !flavor[checkBrandID]["checkboxChecked"];
    this.setState({ flavor: flavor });
    const selectedIndex = this.state.filterByFlavor.indexOf(flavorType);
    let newFlavorSelected = [];
    if (selectedIndex === -1) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor,
        flavorType
      );
    } else if (selectedIndex === 0) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor.slice(1)
      );
    } else if (selectedIndex === this.state.filterByFlavor.length - 1) {
      newFlavorSelected = newFlavorSelected.concat(
        this.state.filterByFlavor.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      this.state.filterByFlavor.splice(selectedIndex, 1);
      newFlavorSelected = this.state.filterByFlavor;
    }

    let special_filter;
    if (newFlavorSelected.length > 0) {
      special_filter = "flavor";
    } else {
      special_filter = "all";
    }
    this.setState({
      filterByFlavor: newFlavorSelected,
      page: 1,
      special_filter: special_filter,
      special_filter_value: newFlavorSelected,
      CategoryListLoader: true,
      ProductList: [],
    });
    let cateogires = this.state.viewGroupcategory;
    let urlcategoryid = this.props.history.location.pathname.split("/")[2];
    let currenthighCat = this.state.CurrentHightlightedcategory;
    let statusselection = this.props.history.location.state?.selectedCategory;
    let categoryid;
    if (statusselection === true) {
      categoryid = urlcategoryid;
    } else {
      if (newFlavorSelected.length > 0) categoryid = currenthighCat;
      else {
        if (
          this.state.viewGroupcategory === "" ||
          this.state.viewGroupcategory.length === 0
        ) {
          categoryid = isNaN(urlcategoryid) === false ? urlcategoryid : "";
        } else if (isNaN(urlcategoryid) === false) {
          categoryid = this.state.viewGroupcategory[0].id;
          // this.FetchFilterApiByBrandFlavorKosher(
          //   "All",
          //   1,
          //   +categoryid,
          //   this.state.cat_id,
          //   [],
          //   [],
          //   this.state.specials,
          //   "",
          //   this.state.top_menu_value
          // );
        } else {
          categoryid = "";
          // this.FetchFilterApiByBrandFlavorKosher(
          //   "All",
          //   1,
          //   categoryid,
          //   this.state.cat_id,
          //   [],
          //   [],
          //   this.state.specials,
          //   "",
          //   this.state.top_menu_value
          // );
        }
      }
    }

    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      newFlavorSelected,
      this.state.brandIds,
      this.state.min_price,
      this.state.max_price,
      flavor?.some((x) => x.checkboxChecked === true)
        ? this.state.viewPointCategoryIds
        : categoryid === ""
        ? cateogires[0]?.id
        : categoryid,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeCheckboxForKosher = (kosherType) => {
    window.scrollTo(0, 0);
    this.child?.current?.handleClearselectionitem();

    let kosher = this.state.kosher;
    let checkBrandID = kosher.findIndex((p) => p.kosher === kosherType);
    kosher[checkBrandID]["checkboxChecked"] =
      !kosher[checkBrandID]["checkboxChecked"];
    this.setState({ kosher: kosher });
    const selectedIndex = this.state.filterByKosher.indexOf(kosherType);
    let newKosherSelected = [];
    if (selectedIndex === -1) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher,
        kosherType
      );
    } else if (selectedIndex === 0) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(1)
      );
    } else if (selectedIndex === this.state.filterByKosher.length - 1) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newKosherSelected = newKosherSelected.concat(
        this.state.filterByKosher.slice(0, selectedIndex)
      );
    }

    let special_filter;
    if (newKosherSelected.length > 0) {
      special_filter = "kosher";
    } else {
      special_filter = "all";
    }

    this.setState({
      filterByKosher: newKosherSelected,
      special_filter: special_filter,
      special_filter_value: kosherType,
      page: 1,
      CategoryListLoader: true,
      ProductList: [],
    });
    let cateogires = this.state.viewGroupcategory;
    let urlcategoryid = this.props.history.location.pathname.split("/")[2];
    let currenthighCat = this.state.CurrentHightlightedcategory;
    let statusselection = this.props.history.location.state?.selectedCategory;
    let categoryid;
    if (statusselection === true) {
      categoryid = urlcategoryid;
    } else {
      if (newKosherSelected.length > 0) categoryid = currenthighCat;
      else if (isNaN(urlcategoryid) === false) {
        categoryid = this.state.viewGroupcategory[0].id;
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   +categoryid,
        //   this.state.cat_id,
        //   [],
        //   [],
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      } else {
        categoryid = "";
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   categoryid,
        //   this.state.cat_id,
        //   [],
        //   [],
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      }
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      newKosherSelected,
      this.state.filterByFlavor,
      this.state.brandIds,
      this.state.min_price,
      this.state.max_price,
      kosher?.some((x) => x.checkboxChecked === true)
        ? this.state.viewPointCategoryIds
        : categoryid === ""
        ? cateogires[0]?.id
        : categoryid,
      "",
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeCheckboxForProductType = (productType) => {
    window.scrollTo(0, 0);
    this.child?.current?.handleClearselectionitem();
    let fruitType = this.state.fruitType;
    let checkBrandID = fruitType.findIndex(
      (p) => p.product_type === productType
    );
    fruitType[checkBrandID]["checkboxChecked"] =
      !fruitType[checkBrandID]["checkboxChecked"];
    this.setState({ fruitType: fruitType });
    const selectedIndex = this.state.filterByType.indexOf(productType);
    let newProducTypeSelected = [];
    if (selectedIndex === -1) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType,
        productType
      );
    } else if (selectedIndex === 0) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(1)
      );
    } else if (selectedIndex === this.state.filterByType.length - 1) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newProducTypeSelected = newProducTypeSelected.concat(
        this.state.filterByType.slice(0, selectedIndex)
      );
    }
    this.setState({
      filterByType: newProducTypeSelected,
      page: 1,
      ProductList: [],
      CategoryListLoader: true,
    });
    let cateogires = this.state.viewGroupcategory;
    let urlcategoryid = this.props.history.location.pathname.split("/")[2];
    let currenthighCat = this.state.CurrentHightlightedcategory;
    let statusselection = this.props.history.location.state?.selectedCategory;
    let categoryid;
    if (statusselection === true) {
      categoryid = urlcategoryid;
    } else {
      if (newProducTypeSelected.length > 0) categoryid = currenthighCat;
      else if (isNaN(urlcategoryid) === false) {
        console.log("else if", this.state.brandName);
        categoryid = this.state.viewGroupcategory[0].id;
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   +categoryid,
        //   this.state.cat_id,
        //   [],
        //   this.state.brandName,
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      } else {
        console.log("else");
        categoryid = "";
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   categoryid,
        //   this.state.cat_id,
        //   [],
        //   [],
        //   this.state.specials,
        //   "",
        //   this.state.top_menu_value
        // );
      }
    }

    this.FetchProductList(
      1,
      newProducTypeSelected,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      this.state.min_price,
      this.state.max_price,
      fruitType?.some((x) => x.checkboxChecked === true)
        ? this.state.viewPointCategoryIds
        : categoryid === ""
        ? cateogires[0]?.id
        : categoryid,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  toFindDuplicates = (arry) => {
    const uniqueElements = new Set(arry);
    const filteredElements = arry.filter((item) => {
      if (uniqueElements.has(item)) {
        uniqueElements.delete(item);
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  };

  FetchFilterApiByBrandFlavorKosher = (
    filter_name,
    page,
    category_id,
    cat_id,
    brand_ids,
    brand__Name,
    specials,
    product_deal,
    top_menu
  ) => {
    let categoryid = this.props.history.location.pathname.split("/")[2];
    let categoryData = this.state.viewGroupcategory;
    let catId = categoryData.filter(
      (x) => x?.id === this.state.CurrentHightlightedcategory
    );
    let formValues = {
      filter_name: filter_name,
      limit: 1000,
      page: page,
      category_id: category_id,
      search: specials.replace("q=", ""),
      product_deal: product_deal,
      top_menu: top_menu,
    };
    let zonedata = getSchedule_zip();
    formValues = { ...formValues, ...zonedata };
    let brand_id;
    let brand_name;
    let brandName = brand__Name;
    let brandIds = brand_ids;
    let newSelected = brand_ids;
    if (category_id?.toString() === cat_id?.toString()) {
      const duplicateElements = this.toFindDuplicates(this.state.brandName);

      brandName = duplicateElements;
    }
    //  else {
    //   brandName = [];
    // }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.brand_id &&
      brandIds.length === 1
    ) {
      brand_id = this.props.location.state.brand_id;
      brand_name = this.props.location.state.brand_name;

      let obj = {
        brand_id: brand_id,
        brand_name: brand_name,
      };
      brandName.push(obj);
    } else if (brandIds.length > 1) {
      let allBrandName = structuredClone(this.state.brandName);
      for (let i = 0; i < allBrandName.length; i++) {
        let obj = {
          brand_id: allBrandName[i].brand_id,
          brand_name: allBrandName[i].brand_name,
        };
        brandName.push(obj);
      }
    } else {
      brand_id = "";
      brand_name = "";
    }
    brandName = this.toFindDuplicates(this.state.brandName);
    console.log("=====",category_id);
    this.setState({ FilterListLoader: true });
    FilterListApiByBrandFlavorKosher(formValues, this.props.dispatch).then(
      (res) => {
        let resultBrandData = [];
        let BrandProduct = this.state.BrandProduct;
        let flavor = this.state.flavor;
        let kosher = this.state.kosher;
        let fruitType = this.state.fruitType;
        let brand_total = this.state.brand_total;
        let flavor_total = this.state.flavor_total;
        let kosher_total = this.state.kosher_total;
        let type_total = this.state.type_total;
        if (res.data && res.data.result) {
          resultBrandData = resultBrandData.push(res.data.result);
          BrandProduct = res.data.result.brand;
          if (res.data.result.brand_total > 0) {
            brand_total = res.data.result.brand_total;
          } else {
            brand_total = this.state.brand_total;
          }
          if (flavor.length !== res.data.result.flavor.length)
            flavor = res.data.result.flavor;
          if (res.data.result.flavor_total > 0) {
            flavor_total = res.data.result.flavor_total;
          } else {
            flavor_total = this.state.flavor_total;
          }
          if (kosher.length !== res.data.result.kosher.length)
            kosher = res.data.result.kosher;
          if (res.data.result.kosher_total > 0) {
            kosher_total = res.data.result.kosher_total;
          } else {
            kosher_total = this.state.kosher_total;
          }
          if (fruitType.length !== res.data.result.type.length)
            fruitType = res.data.result.type;

          if (res.data.result.type_total > 0) {
            type_total = res.data.result.type_total;
          } else {
            type_total = this.state.type_total;
          }
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            // this.setState({ productList: "", productlistLength: 0, total: 0 });
            RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
              myThis.FetchFilterApiByBrandFlavorKosher(
                filter_name,
                page,
                category_id,
                cat_id,
                brand_ids,
                brand__Name,
                specials,
                product_deal,
                top_menu
              );
            });
          }
        }

        var OrderByBrandVartical = [];
        var cols = 5;
        var rows = Math.ceil(BrandProduct.length / cols);
        var col, row;
        if (rows > 1) {
          for (row = 0; row < rows; ++row) {
            for (col = 0; col < cols; ++col) {
              OrderByBrandVartical.push(BrandProduct[row + col * rows] || "");
            }
          }
        }

        var filteredBrand = OrderByBrandVartical.filter(function (el) {
          return el != "";
        });

        if (brand_id > 0 && BrandProduct && BrandProduct.length > 0) {
          let checkBrandID = BrandProduct.findIndex(
            (p) => p.brand_id === brand_id
          );

          if (checkBrandID > -1) {
            BrandProduct[checkBrandID]["checkboxChecked"] = true;
          }
        } else {
          if (BrandProduct && BrandProduct.length > 0) {
            for (let i = 0; i < this.state.brandIds.length; i++) {
              let checkBrandID = BrandProduct.findIndex(
                (p) => p.brand_id === this.state.brandIds[i]
              );
              if (checkBrandID > -1) {
                BrandProduct[checkBrandID]["checkboxChecked"] =
                  !BrandProduct[checkBrandID]["checkboxChecked"];
              }
            }
          }
        }
        let stateData = {};

        if (filteredBrand && filteredBrand.length > 0) {
          stateData["BrandProduct"] = filteredBrand;
        } else {
          stateData["BrandProduct"] = BrandProduct;
        }

        if (flavor && flavor.length > 0) {
          stateData["flavor"] = flavor;
        } else {
          stateData["flavor"] = this.state.flavor;
        }
        if (kosher && kosher.length > 0) {
          stateData["kosher"] = kosher;
        } else {
          stateData["kosher"] = this.state.kosher;
        }
        if (fruitType && fruitType.length > 0) {
          stateData["fruitType"] = fruitType;
        } else {
          stateData["fruitType"] = this.state.fruitType;
        }
        if (stateData.BrandProduct && stateData.BrandProduct.length < 2) {
          resultBrandData = [];
        }

        stateData.BrandProduct.sort(function (a, b) {
          if (a.brand__name < b.brand__name) {
            return -1;
          }
          if (a.brand__name > b.brand__name) {
            return 1;
          }
          return 0;
        });
        stateData.kosher.sort(function (a, b) {
          if (a.kosher < b.kosher) {
            return -1;
          }
          if (a.kosher > b.kosher) {
            return 1;
          }
          return 0;
        });
        stateData.flavor.sort(function (a, b) {
          if (a.flavor < b.flavor) {
            return -1;
          }
          if (a.flavor > b.flavor) {
            return 1;
          }
          return 0;
        });
        this.setState({
          resultBrandData: resultBrandData,
          BrandProduct: stateData.BrandProduct,
          flavor: flavor,
          kosher: stateData.kosher,
          fruitType: stateData.fruitType,
          brand_total: brand_total,
          flavor_total: flavor_total,
          kosher_total: kosher_total,
          type_total: type_total,
          brandName: brandName,
          brandIds: newSelected,
          FilterListLoader: false,
          viewPointCategoryIds:category_id
        });
      }
    );
  };
  bindnewgorupeddata = (category, index) => {
    let mycategory = [...category];
    let tempdata = structuredClone(mycategory[index]);
    mycategory.splice(index, 1);
    mycategory.splice(0, 0, tempdata);
    
    return mycategory;
  };
  bindCategorygroupdata = (tree_data = []) => {
    let tempdata = [];
    if (
      this.props.history.location.pathname
        .split("/")[2]
        .includes("SPECIALS") === false
    ) {
      if (
        this.props.history.location.state?.selectedgroupcat?.category !==
        undefined
      ) {
        let selectedData;
        if (
          Array.isArray(
            this.props.history.location.state?.selectedgroupcat?.category
          )
        )
          selectedData = [
            ...this.props.history.location.state?.selectedgroupcat?.category,
          ];
        else {
          selectedData = [
            this.props.history.location.state?.selectedgroupcat?.category,
          ];
        }
        tempdata = [];
        for (let i = 0; i < selectedData.length; i++) {
          if (!selectedData[i]?.has_child) {
            tempdata.push(selectedData[i]);
          } else {
            for (let j = 0; j < selectedData[i].childItem.length; j++) {
              tempdata.push(selectedData[i].childItem[j]);
            }
          }
        }

        if (tempdata.length > 0 && tempdata[0]?.meta_title !== "") {
          document.title = tempdata[0].meta_title;
        } else {
          document.title = commonPageTitel(
            JSON.parse(localStorage.getItem("access_token"))
          );
        }
        this.setState({
          viewGroupcategory: tempdata,
          CurrentHightlightedcategory: tempdata[0]?.id,
        });
      } else if (tree_data.length !== 0) {
        let category_id =
          this.props.history.location.state?.category_id === undefined
            ? this.props.history.location.pathname.split("/")[2]
            : this.props.history.location.state?.category_id;
        let data = tree_data;
        let parentdata;
        let newdata = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].level === 0) {
            if (data[i].id === +category_id) {
              parentdata = data[i];
              newdata =
                data[i].has_child === true ? data[i].childItem : [data[i]];
              break;
            }
          } else if (data[i].id === +category_id) {
            parentdata = data[i];
            newdata = [data[i]];
            break;
          }
          if (data[i].has_child === true && data[i]?.childItem?.length > 0) {
            for (let k = 0; k < data[i].childItem.length; k++) {
              if (data[i].childItem[k].id === +category_id) {
                parentdata = structuredClone(data[i]);
                newdata = this.bindnewgorupeddata(parentdata.childItem, k);

                break;
              }

              if (
                data[i].childItem[k].has_child === true &&
                data[i].childItem[k].childItem.length > 0
              ) {
                for (
                  let j = 0;
                  j < data[i].childItem[k].childItem.length;
                  j++
                ) {
                  if (data[i].childItem[k].childItem[j].id === +category_id) {
                    parentdata = structuredClone(data[i].childItem[k]);
                    newdata = this.bindnewgorupeddata(parentdata.childItem, j);

                    break;
                  }
                }
              }
            }
          }
        }
        for (let i = 0; i < newdata?.length; i++) {
          if (!newdata[i]?.has_child) {
            tempdata.push(newdata[i]);
          } else {
            for (let j = 0; j < newdata[i]?.childItem?.length; j++) {
              tempdata.push(newdata[i]?.childItem[j]);
            }
          }
        }

        if (
          this.props.history.location?.state === undefined &&
          this.state.ProductList.length === 0 &&
          isNaN(category_id) === false
        ) {
          console.log("fetchdatafor function without last arg");
          this.FetchDataForPriceRange(
            "",
            "",
            category_id,
            false,
            "initial",
            false
          );
        }
        if (tempdata.length > 0 && tempdata[0]?.meta_title !== "") {
          document.title = tempdata[0].meta_title;
        } else {
          document.title = commonPageTitel(
            JSON.parse(localStorage.getItem("access_token"))
          );
        }
        this.setState({
          viewGroupcategory: tempdata.length > 0 ? tempdata : tree_data,
          parentdata: parentdata,
          CurrentHightlightedcategory:
            tempdata.length > 0 ? tempdata[0].id : tree_data[0].id,
          SideCategoryListLoader: false,
        });
      }

      this.setState({ fruitType: [] });
      let urlcategoryid = this.props.history.location.pathname.split("/")[2];

      this.FetchProductList(
        1,
        [],
        [],
        [],
        [],
        "",
        "",
        tempdata.length === 0 ? urlcategoryid : tempdata[0].id,
        "",
        "",
        "",
        this.state.order,
        tempdata[0]?.cat_count < 20 && tempdata?.length > 1 ? true : false
      );

      // this.FetchFilterApiByBrandFlavorKosher(
      //   "All",
      //   1,
      //   tempdata.length === 0 ? urlcategoryid : tempdata[0].id,
      //   this.state.cat_id,
      //   [],
      //   [],
      //   "",
      //   "",
      //   ""
      // );
    } else {
      let tempdata = [];
      console.log("bindnew hitt");
      for (let i = 0; i < tree_data.length; i++) {
        if (tree_data[i]?.level === 0) {
          let singleObj = tree_data[i];
          singleObj.has_child = false;
          delete singleObj?.childItem;

          tempdata.push(singleObj);
        }
        // if (!tree_data[i].has_child) {
        //   tempdata.push(tree_data[i]);
        // } else {
        //   for (let j = 0; j < tree_data[i]?.childItem?.length; j++) {
        //     console.log(tree_data[i].childItem[j]);
        //     if (tree_data[i].childItem[j].has_child === true) {
        //       let subsubchidlItem = tree_data[i].childItem[j]?.childItem;
        //       console.log(subsubchidlItem);
        //       for (let k = 0; k < subsubchidlItem?.length; k++) {
        //         tempdata.push(subsubchidlItem[k]);
        //       }
        //     } else tempdata.push(tree_data[i].childItem[j]);
        //   }
        // }
      }

      let parentdata = {
        id: 0,
        name: "SPECIALS",
        level: 0,
        parent_id: null,
      };
      if (tempdata.length > 0 && tempdata[0]?.meta_title !== "") {
        document.title = tempdata[0].meta_title;
      } else {
        document.title = commonPageTitel(
          JSON.parse(localStorage.getItem("access_token"))
        );
      }
      this.setState({
        viewGroupcategory: tempdata,
        parentdata: parentdata,
        CurrentHightlightedcategory: tempdata[0]?.id,
        SideCategoryListLoader: false,
      });
      this.FetchProductList(
        1,
        [],
        [],
        [],
        [],
        "",
        "",
        tempdata[0]?.id === undefined ? "" : tempdata[0]?.id,
        "",
        "",
        "SPECIALS",
        this.state.order,
        tempdata[0]?.cat_count < 20 && tempdata?.length > 1 ? true : false
      );
    }
  };
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  FetchProductList = (
    page,
    product_type,
    kosher,
    flavor,
    brandIds,
    min_price,
    max_price,
    category_id,
    specials,
    product_deal,
    top_menu = "",
    order,
    callAgain = false
  ) => {
    this.setState({ isScrollObserver: false, loading: true });
    let formValues = {
      limit: this.state.pageLimit,
      page: page,
      product_type: product_type,
      kosher: kosher,
      flavor: flavor,
      brand_id: brandIds,
      price_min: min_price,
      price_max: max_price,
      category_id:
        category_id !== undefined && category_id !== null ? category_id : "",
      search: specials.replace("q=", ""),
      product_deal: product_deal,
      top_menu: top_menu,
      order: order,
    };

    let scheduledata = getSchedule_zip();
    formValues = { ...formValues, ...scheduledata };

    ProductsListListingPage(formValues, this.props.dispatch).then((res) => {
      let productList = [];
      let categoryData = this.state.categoryData;
      let productlistLength = 0;
      let parent_onlyid = [];
      let total = undefined;
      let msgsearch = "";
      let categorydata = this.state.viewGroupcategory;

      if (res.data && res.data.result) {
        productList = res.data.result;
        productlistLength = res.data.length;
        total = res.data.total;

        if (productlistLength === 0) {
          msgsearch = (
            <>
              <div>We're sorry. We were not able to find a match.</div>
              <div className="pt-5">Try Another Search?</div>
            </>
          );
        } else {
          msgsearch = "";
        }

        if (this.state.ProductList.length === 0) {
          if (res.data && res.data.cat_data) {
            categoryData = res.data.cat_data;
          }
          for (let i = 0; i < categoryData.length; i++) {
            parent_onlyid.push(categoryData[i].category_id);
          }
        }

        let groupedproductlist = [...this.state.ProductList];
        // const imagePromises = productList.map((ele) => {
        //   // let localUrl =
        //   //   CLOUD_URL +
        //   //   "local/" +
        //   //   JSON.parse(localStorage.getItem("Store_address"))?.id +
        //   //   "/product/" +
        //   //   slugifyStr(ele.item_code);
        //   let localUrl = ele?.cloudinary_images_local[0];
        //   // let globalUrl =
        //   //   CLOUD_URL + "global/product/" + slugifyStr(ele.item_code);
        //   let globalUrl = ele?.cloudinary_images_global[0];
        //   let currentUrl =
        //     ele.main_image !== null
        //       ? ele.main_image[0] === "/"
        //         ? ApiUrls.BASH_URL + "/media" + ele.main_image
        //         : ele.main_image
        //       : NO_IMAGE;

        //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     ele.main_image = url.url;
        //     ele.valid = url.valid;
        //     return ele; // return the updated product
        //   });
        // });

        // Promise.all(imagePromises).then((updatedProductList) => {
        //   if (updatedProductList.length !== 0) {
        //     if (
        //       (top_menu === "" || top_menu === "SPECIALS") &&
        //       specials === "" &&
        //       this.props.match.params.id.includes("productdeal=") === false &&
        //       (brandIds === "" || brandIds.length === 0) &&
        //       this.state.filterByType.length === 0 &&
        //       this.state.filterByFlavor.length === 0 &&
        //       this.state.filterByKosher.length === 0
        //     ) {
        //       let categorydata = this.state.viewGroupcategory;
        //       if (categorydata !== undefined) {
        //         for (let i = 0; i < categorydata.length; i++) {
        //           if (categorydata[i].id === +category_id) {
        //             if (
        //               groupedproductlist.length === 0 ||
        //               groupedproductlist[groupedproductlist.length - 1]
        //                 ?.productList?.length === this.state.total
        //             )
        //               groupedproductlist.push({
        //                 parentName: categorydata[i],
        //                 productList: updatedProductList,
        //               });
        //             else if (
        //               this.props.history.location.state?.selectedCategory
        //             ) {
        //               groupedproductlist[0].parentName = categorydata[i];
        //               groupedproductlist[0].productList = [
        //                 ...groupedproductlist[0].productList,
        //                 ...updatedProductList,
        //               ];
        //             } else {
        //               groupedproductlist[
        //                 groupedproductlist.length - 1
        //               ].parentName = categorydata[i];
        //               groupedproductlist[
        //                 groupedproductlist.length - 1
        //               ].productList = [
        //                 ...groupedproductlist[groupedproductlist.length - 1]
        //                   .productList,
        //                 ...updatedProductList,
        //               ];
        //             }
        //             break;
        //           }
        //         }
        //       }
        //     } else {
        //       let parentdata;
        //       if (
        //         top_menu === "" &&
        //         specials === "" &&
        //         (brandIds === "" || brandIds.length === 0) &&
        //         this.state.filterByType.length === 0 &&
        //         this.state.filterByFlavor.length === 0 &&
        //         this.state.filterByKosher.length === 0 &&
        //         this.props.match.params.id.includes("productdeal=") === false
        //       ) {
        //         let categorydata = this.state.viewGroupcategory;
        //         let currentindx = categorydata
        //           .map(function (e) {
        //             return e?.id;
        //           })
        //           .indexOf(+this.state.category_id);
        //         parentdata = {
        //           name: categorydata[currentindx].name,
        //           cat_count: res.data.total,
        //         };
        //       } else if (this.props.match.params.id.includes("productdeal=")) {
        //         parentdata = {
        //           name: this.props.match.params.id
        //             .split("productdeal=")[1]
        //             ?.replaceAll("_", " "),
        //           cat_count: res.data.total,
        //         };
        //       } else if (
        //         (brandIds !== "" && brandIds.length !== 0) ||
        //         this.state.filterByType.length !== 0 ||
        //         this.state.filterByFlavor.length !== 0 ||
        //         this.state.filterByKosher.length !== 0
        //       ) {
        //         parentdata = {
        //           name: res.data.cat_data?.at(-1)?.category_name,
        //           cat_count: res.data.result.length,
        //           id: res.data.cat_data?.at(-1)?.category_id,
        //         };
        //       } else {
        //         parentdata = {
        //           name: this.props.match.params.id.includes("q=")
        //             ? "Search result (" + specials + ")"
        //             : top_menu === ""
        //             ? specials
        //             : top_menu,
        //           cat_count: res.data.total,
        //         };
        //       }

        //       if (groupedproductlist.length === 0) {
        //         groupedproductlist.push({
        //           parentName: parentdata,
        //           productList: updatedProductList,
        //         });
        //       } else {
        //         groupedproductlist[0].parentName = parentdata;
        //         groupedproductlist[0].productList = [
        //           ...groupedproductlist[0].productList,
        //           ...updatedProductList,
        //         ];
        //       }
        //     }
        //   }

        //   if (this.state.scrollLoading || this.state.CategoryListLoader)
        //     this.setState({ ProductList: [] });
        //   if (brandIds === "") {
        //     this.setState({
        //       special_filter: "all",
        //       special_filter_value: "",
        //       brandName: [],
        //     });
        //   }

        //   this.setState({
        //     ProductList: groupedproductlist,
        //     categoryData: categoryData,
        //     productlistLength: productlistLength,
        //     loading: false,
        //     parent_onlyid: parent_onlyid,
        //     total: total,
        //     scrollLoading: true,
        //     CategoryListLoader: callAgain ? true : false,
        //     top_menu_value: top_menu,
        //     listingFormValues: formValues,
        //     searchResultMsg: msgsearch,
        //     category_id: category_id,
        //     page: page,
        //     brandIds: brandIds,
        //     categoryBreadcrumbs: res.data.cat_data,
        //     isScrollObserver: true,
        //   });
        //   document.body.style.overflow = "auto";
        //   if (
        //     this.props.match.params.id.includes("q=") === false &&
        //     (this.props.match.params.id.includes("campaign=") === false ||
        //       this.props.match.params.id?.includes("SPECIALS")) &&
        //     this.props.match.params.id.includes("brand=") === false
        //   ) {
        //     this.scrollcategorywise(
        //       groupedproductlist,
        //       this.props.history.location.state?.selectedCategory
        //     );

        //     if (
        //       this.state.categoryData?.length > 1 &&
        //       window.screen.availWidth >= 900
        //     )
        //       this.handleChangeBreadcrumbsonScroll();
        //   }
        //   if (callAgain && categorydata.length > 1) {
        //     this.FetchProductList(
        //       page,
        //       product_type,
        //       kosher,
        //       flavor,
        //       brandIds,
        //       min_price,
        //       max_price,
        //       categorydata[1].id,
        //       specials,
        //       product_deal,
        //       top_menu,
        //       order,
        //       false
        //     );
        //   } else this.handleScrollFitlertype();

        //   this.handleLocalStorageChange();
        //   if (this.state.is_next_rec) this.nextProductItem(false, true);
        // });

        if (productList.length !== 0) {
          if (
            (top_menu === "" || top_menu === "SPECIALS") &&
            specials === "" &&
            this.props.match.params.id.includes("productdeal=") === false &&
            (brandIds === "" || brandIds.length === 0) &&
            this.state.filterByType.length === 0 &&
            this.state.filterByFlavor.length === 0 &&
            this.state.filterByKosher.length === 0
          ) {
            console.log("special");
            let categorydata = this.state.viewGroupcategory;
            if (categorydata !== undefined) {
              for (let i = 0; i < categorydata.length; i++) {
                if (categorydata[i].id === +category_id) {
                  if (
                    groupedproductlist.length === 0 ||
                    groupedproductlist[groupedproductlist.length - 1]
                      ?.productList?.length === this.state.total
                  )
                    groupedproductlist.push({
                      parentName: categorydata[i],
                      productList,
                    });
                  else if (
                    this.props.history.location.state?.selectedCategory
                  ) {
                    groupedproductlist[0].parentName = categorydata[i];
                    groupedproductlist[0].productList = [
                      ...groupedproductlist[0].productList,
                      ...productList,
                    ];
                  } else {
                    groupedproductlist[
                      groupedproductlist.length - 1
                    ].parentName = categorydata[i];
                    groupedproductlist[
                      groupedproductlist.length - 1
                    ].productList = [
                      ...groupedproductlist[groupedproductlist.length - 1]
                        .productList,
                      ...productList,
                    ];
                  }
                  break;
                }
              }
            }
          } else {
            console.log("2");
            let parentdata;
            if (
              top_menu === "" &&
              specials === "" &&
              (brandIds === "" || brandIds.length === 0) &&
              this.state.filterByType.length === 0 &&
              this.state.filterByFlavor.length === 0 &&
              this.state.filterByKosher.length === 0 &&
              this.props.match.params.id.includes("productdeal=") === false
            ) {
              console.log("p-if");
              let categorydata = this.state.viewGroupcategory;
              let currentindx = categorydata
                .map(function (e) {
                  return e?.id;
                })
                .indexOf(+this.state.category_id);
              parentdata = {
                name: categorydata[currentindx].name,
                cat_count: res.data.total,
              };
            } else if (this.props.match.params.id.includes("productdeal=")) {
              console.log("p-else if1");
              parentdata = {
                name: this.props.match.params.id
                  .split("productdeal=")[1]
                  ?.replaceAll("_", " "),
                cat_count: res.data.total,
              };
            } else if (
              (brandIds !== "" && brandIds.length !== 0) ||
              this.state.filterByType.length !== 0 ||
              this.state.filterByFlavor.length !== 0 ||
              this.state.filterByKosher.length !== 0
            ) {
              parentdata = {
                name: res.data.cat_data?.at(-1)?.category_name,
                cat_count: res.data.result.length,
                id: res.data.cat_data?.at(-1)?.category_id,
              };
              // console.log("p-else if 2",res);
            } else {
              parentdata = {
                name: this.props.match.params.id.includes("q=")
                  ? "Search result (" + specials + ")"
                  : top_menu === ""
                  ? specials
                  : top_menu,
                cat_count: res.data.total,
              };
              console.log("search hitt");
            }
            
            if (groupedproductlist.length === 0) {
              groupedproductlist.push({
                parentName: parentdata,
                productList,
              });
            } else {
              groupedproductlist[0].parentName = parentdata;
              groupedproductlist[0].productList = [
                ...groupedproductlist[0].productList,
                ...productList,
              ];
            }
          }
        }

        if (this.state.scrollLoading || this.state.CategoryListLoader)
          this.setState({ ProductList: [] });
        if (brandIds === "") {
          this.setState({
            special_filter: "all",
            special_filter_value: "",
            brandName: [],
          });
        }

        this.setState({
          ProductList: groupedproductlist,
          categoryData: categoryData,
          productlistLength: productlistLength,
          loading: false,
          parent_onlyid: parent_onlyid,
          total: total,
          scrollLoading: true,
          CategoryListLoader: callAgain ? true : false,
          top_menu_value: top_menu,
          listingFormValues: formValues,
          searchResultMsg: msgsearch,
          category_id: category_id,
          page: page,
          brandIds: brandIds,
          categoryBreadcrumbs: res.data.cat_data,
          isScrollObserver: true,
        });
        document.body.style.overflow = "auto";
        // window.scrollBy(0, -150);
        if (
          this.props.match.params.id.includes("q=") === false &&
          (this.props.match.params.id.includes("campaign=") === false ||
            this.props.match.params.id?.includes("SPECIALS")) &&
          this.props.match.params.id.includes("brand=") === false
        ) {
          this.scrollcategorywise(
            groupedproductlist,
            this.props.history.location.state?.selectedCategory
          );

          if (
            this.state.categoryData?.length > 1 &&
            window.screen.availWidth >= 900
          )
            this.handleChangeBreadcrumbsonScroll();
        }
        if (callAgain && categorydata.length > 1) {
          this.FetchProductList(
            page,
            product_type,
            kosher,
            flavor,
            brandIds,
            min_price,
            max_price,
            categorydata[1].id,
            specials,
            product_deal,
            top_menu,
            order,
            false
          );
        } else this.handleScrollFitlertype();
        // else {
        //   this.FetchFilterApiByBrandFlavorKosher(
        //     "All",
        //     1,
        //     this.props.history.location.pathname?.includes("campaign=")
        //       ? ""
        //       : this.props.history.location.state?.selectedgroupcat?.parent
        //       ? this.props.history.location.state.selectedgroupcat.parent.id
        //       : res.data.cat_data?.length > 0
        //       ? res.data.cat_data[0].category_id
        //       : "",
        //     this.state.cat_id,
        //     brandIds,
        //     this.state.brandName,
        //     specials,
        //     product_deal,
        //     top_menu
        //   );
        // }

        this.handleLocalStorageChange();
        // this.FetchFilterApiByBrandFlavorKosher(
        //   "All",
        //   1,
        //   this.state.category_id,
        //   this.state.cat_id,
        //   this.state.brandIds,
        //   [],
        //   this.state.specials,
        //   this.state.product_deal,
        //   this.state.top_menu_value
        // );

        if (this.state.is_next_rec) this.nextProductItem(false, true);
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchProductList(
              page,
              product_type,
              kosher,
              flavor,
              brandIds,
              min_price,
              max_price,
              category_id,
              specials,
              product_deal,
              top_menu,
              order
            );
          });
        }
      } else if (
        res.errors &&
        res.errors._error.status === 400 &&
        res.errors?._error?.data?.errors?.category_id
      ) {
        this.closecustomChips();
      }
    });
  };

  handleObserver(entities, observer) {
    if (this.state.isScrollObserver) {
      const y = entities[0].intersectionRatio;
      let category = this.state.viewGroupcategory;
      let urlcategoryid = this.state.category_id;
      let currentindx = 0;

      if (
        (this.state.special_filter !== "" ||
          this.props.history.location.pathname
            .split("/")[2]
            .includes("SPECIALS")) &&
        category?.length > 0
      )
        currentindx = category
          .map(function (e) {
            return e?.id;
          })
          .indexOf(+urlcategoryid);
      if (y > 0) {
        let nextPage;
        if (this.state.scrollLoading) {
          nextPage = this.state.page + 1;

          this.setState({ page: nextPage, scrollLoading: false });
        }

        if (
          this.state.total &&
          nextPage * this.state.pageLimit <
            this.state.total + this.state.pageLimit &&
          nextPage > 1 &&
          (this.props.history.location.state?.selectedCategory === true ||
            this.state.special_filter !== "")
        ) {
          console.log("if");

          this.setState({ loading: true });
          this.FetchProductList(
            this.state.page,
            this.state.filterByType,
            this.state.filterByKosher,
            this.state.filterByFlavor,
            this.state.brandIds,
            this.state.min_price,
            this.state.max_price,
            this.state.category_id,
            this.state.specials,
            this.state.product_deal,
            this.state.top_menu_value,
            this.state.order
          );
        } else if (
          this.state.total &&
          nextPage * this.state.pageLimit <
            this.state.total + this.state.pageLimit &&
          nextPage > 1 &&
          this.props.match.params.id.includes("q=")
        ) {
          console.log("2");
          this.setState({ loading: true });
          this.FetchProductList(
            this.state.page,
            this.state.filterByType,
            this.state.filterByKosher,
            this.state.filterByFlavor,
            this.state.brandIds,
            this.state.min_price,
            this.state.max_price,
            this.state.category_id,
            this.props.match.params.id.replace("q=", ""),
            this.state.product_deal,
            this.state.top_menu_value,
            this.state.order
          );
        } else if (
          category?.length > 1 &&
          this.state.ProductList.length !== category?.length &&
          this.props.history.location.state?.selectedCategory !== true &&
          this.props.match.params.id.includes("q=") === false &&
          (this.props.match.params.id.includes("campaign=") === false ||
            this.props.match.params.id?.includes("SPECIALS")) &&
          (this.state.special_filter === "all" ||
            this.props.match.params.id?.includes("SPECIALS"))
        ) {
          console.log("hitt special");
          this.setState({ loading: true });
          if (
            this.state.ProductList.at(-1).productList.length >= this.state.total
          ) {
            console.log("observer total=productlist lenght");
            this.FetchProductList(
              1,
              this.state.filterByType,
              this.state.filterByKosher,
              this.state.filterByFlavor,
              this.state.brandIds,
              this.state.min_price,
              this.state.max_price,
              category[currentindx + 1].id,
              this.state.specials,
              this.state.product_deal,
              this.state.top_menu_value,
              this.state.order
            );
          } else {
            this.FetchProductList(
              this.state.page,
              this.state.filterByType,
              this.state.filterByKosher,
              this.state.filterByFlavor,
              this.state.brandIds,
              this.state.min_price,
              this.state.max_price,
              category[currentindx].id,
              this.state.specials,
              this.state.product_deal,
              this.state.top_menu_value,
              this.state.order
            );
          }
        }
        // else if (this.state.total === undefined) {
        //   console.log("initial");
        //   this.setState({ loading: true });
        //   this.FetchProductList(
        //     this.state.page,
        //     this.state.filterByType,
        //     this.state.filterByKosher,
        //     this.state.filterByFlavor,
        //     this.state.brandIds,
        //     this.state.min_price,
        //     this.state.max_price,
        //     this.state.category_id,
        //     this.state.specials,
        //     this.state.product_deal,
        //     this.state.top_menu_value,
        //     this.state.order
        //   );
        // }
      }
      this.setState({ prevY: y });
    }
  }

  FetchProdutDetails = (
    product_id,
    slug,
    next_info,
    pre_info,
    special_filter,
    special_filter_value
  ) => {
    let formValues = {
      product_id: product_id,
      slug: slug,
      extra_info: this.state.extra_info,
      next_info: false,
      pre_info: false,
      special_filter: special_filter,
      special_filter_value: special_filter_value,
    };
    this.setState({ productDetailsLoader: true });
    ProductDetailsApi(formValues, this.props.dispatch).then((res) => {
      let productData = [];
      let categoryNames = [];
      let next_rec = false;
      let prev_rec = false;
      let container = [];
      let containerVal = "";
      var is_checked = false;
      let child_item = [];
      let AddtoCartBtn = this.state.AddtoCartBtn;
      let parseContainerVal = "";
      let pro_item_details_ind = this.state.pro_item_details_ind;
      if (res.data && res.data.result) {
        productData = res.data.result;
        categoryNames = res.data.category_data;
        next_rec = next_info;
        prev_rec = pre_info;
        child_item = res.data.chield_items;
        containerVal = productData.container
          ? productData.container.split(",")
          : "";

        if (containerVal) {
          parseContainerVal = JSON.parse(containerVal);
          if (containerVal.length > 3) {
            container.push({ label: "Select", value: "" });
          }
          for (const property in parseContainerVal) {
            if (containerVal.length > 3) {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
            } else {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
            }
            if (property === productData.container_type[0]) {
              this.setState({
                containerValue: parseContainerVal[property],
                container_type: property,
              });
            }
          }
        }

        if (
          productData &&
          productData.added_quantity &&
          productData.added_quantity.length > 0
        ) {
          if (productData.added_quantity[0] !== 0) {
            AddtoCartBtn = false;
            pro_item_details_ind = 0;
            is_checked = productData.added_quantity_is_case[0];
          } else {
            productData.added_quantity = [];
            productData.added_quantity_is_case = [];
            productData.container_type = [];
            pro_item_details_ind = -1;
          }
        }

        this.setState({
          AddtoCartBtn: AddtoCartBtn,
          ProductDetails: productData,
          Parent_item: JSON.parse(JSON.stringify(productData)),
          categoryNames: categoryNames,
          pro_item_details_ind: pro_item_details_ind,
          check_isCase: is_checked,
          prev_rec,
          next_rec,
          child_items: child_item,
          container: container,
          containerLength: containerVal.length,
        });
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProdutDetails(
                product_id,
                slug,
                next_info,
                pre_info,
                special_filter,
                special_filter_value
              );
            }
          );
        }
      }
      this.setState({ productDetailsLoader: false, open_inner: false });
    });
  };

  UpdateCartItemPopularproduct = (
    product_id,
    quantity,
    is_case,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: is_case,
      note: typeof note === "string" ? note : "",
      container_type: typeof container_type === "string" ? container_type : "",
    };
    let productlist = this.state.PopularProductList;
    var Proind = productlist.findIndex((p) => p.id === product_id);
    productlist[Proind]["disabled"] = true;
    this.setState({ PopularProductList: productlist, inputField: false });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      productlist[Proind]["disabled"] = false;
      this.setState({ PopularProductList: productlist, inputField: true });
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.UpdateCartItemFunctionlityForCase(
                formValue.product_id,
                formValue.quantity,
                formValue.is_case,
                formValue.note,
                formValue.container_type
              );
            }
          );
        }
      } else {
        this.commonFunctionLity(product_id, quantity, is_case);
        this.FetchCartItemList();
      }
    });
  };

  openAddToCart = () => {
    if (this.state.itemCount > 1) {
      this.setState({ itemCount: this.state.itemCount - 1 });
    } else {
      this.setState({ addTocart: true, addCount: false });
    }
  };

  increaseItemCount = () => {
    this.setState({ itemCount: this.state.itemCount + 1 });
  };

  FetchDataForPriceRange = (
    price_min,
    price_max,
    category_id,
    has_child,
    stage = "",
    selectedcatStatus = true
  ) => {
    let brandIds = [];
    let filterByKosher = [];
    let filterByFlavor = [];
    let brandName = [];
    let productList = [];
    let categoryId = category_id;
    window.scrollTo(0, 0);
    if (price_max > 0) {
      brandIds = this.state.brandIds;
      filterByKosher = this.state.filterByKosher;
      filterByFlavor = this.state.filterByFlavor;
    }
    this.child.current?.bindCategorygroupdata(
      this.state.tree_data,
      selectedcatStatus
    );

    // if (this.props.history.location.state?.selectedCategory !== undefined) {
    this.setState({ ProductList: [] });
    // }

    this.setState({
      // ProductList: productList,
      product_deal: "",
      min_price: price_min,
      max_price: price_max,
      cat_id: category_id,
      category_id: category_id,
      fruitType: [],
      brandName: [],
      BrandProduct: [],
      filterByFlavor: filterByFlavor,
      flavor: [],
      kosher: [],
      filterByType: [],
      filterByKosher: filterByFlavor,
      customChipsData: "",
      page: 1,
      specials: "",
      special_filter: "all",
      special_filter_value: "",
      top_menu_value: this.props.history.location.pathname
        .split("/")[2]
        .includes("SPECIALS")
        ? "SPECIALS"
        : "",
      CategoryListLoader: true,
      humbergerBtn: false,
    });

    if (category_id === this.state.cat_id) {
      brandIds = this.state.brandIds;
      brandName = [];
    } else {
      brandIds = [];
      brandName = [];
    }
    brandIds = selectedcatStatus ? [] : brandIds;
    this.setState({
      brandIds: brandIds,
      brandName: brandName,
    });
    // document.getElementById("main-listing").scrollTop=0
    // this.bindCategorygroupdata()
    if (isNaN(category_id) || selectedcatStatus) {
      let cateogires = this.state.viewGroupcategory;
      let statusselection = this.props.history.location.state?.selectedCategory;
      this.FetchProductList(
        1,
        this.state.filterByType,
        filterByKosher,
        filterByFlavor,
        brandIds,
        price_min,
        price_max,
        category_id,
        "",
        "",
        this.props.history.location.pathname.split("/")[2].includes("SPECIALS")
          ? "SPECIALS"
          : "",
        this.state.order,
        cateogires[0]?.cat_count < 20 &&
          statusselection === false &&
          this.state.viewGroupcategory.length > 1
          ? true
          : false
      );

      this.FetchFilterApiByBrandFlavorKosher(
        "All",
        1,
        category_id,
        this.state.cat_id,
        brandIds,
        brandName,
        "",
        "",
        this.props.history.location.pathname.split("/")[2].includes("SPECIALS")
          ? "SPECIALS"
          : ""
      );
    }
  };

  callSpacialFunction = (
    value,
    categoryIds,
    top_menu,
    filter_data,
    pageName
  ) => {
    let pro_deal = this.props.match.params.id.split("productdeal=");
    let brandName = this.props.history.location?.state?.brand_name;
    let ChipsData = "";
    if (
      (pro_deal[1] !== "" && pro_deal !== undefined) ||
      brandName !== undefined
    ) {
      ChipsData =
        brandName !== undefined ? brandName : pro_deal[1]?.replaceAll("_", " ");
    }
    this.setState({
      specials: value.replace("q=", ""),
      page: 1,
      category_id: categoryIds,
      resultBrandData: [],
      brandIds: [],
      brandName: [],
      BrandProduct: [],
      flavor: [],
      kosher: [],
      special_filter: filter_data,
      special_filter_value: top_menu,
      ProductList: [],
      customChipsData: ChipsData,
      fruitType: [],
    });
    let header = document.getElementById("search_section");
    header.style.position = "relative";
    if (top_menu === "") {
      this.setState({ special_filter_value: value });
    }

    if (top_menu === "POPULAR PRODUCT" || top_menu === "RECENTLY ORDERED")
      this.fetchMostOrder(top_menu);
    else if (
      this.props.history.location.pathname
        .split("/")[2]
        ?.includes("SPECIALS") === false
    ) {
      this.setState({ CategoryListLoader: true });

      this.FetchProductList(
        1,
        this.state.filterByType,
        this.state.filterByKosher,
        this.state.filterByFlavor,
        [],
        this.state.min_price,
        this.state.max_price,
        categoryIds,
        value.replace("q=", ""),
        filter_data,
        top_menu,
        this.state.order
      );
      // this.FetchFilterApiByBrandFlavorKosher(
      //   "All",
      //   1,
      //   categoryIds,
      //   this.state.cat_id,
      //   [],
      //   [],
      //   value.replace("q=", ""),
      //   filter_data,
      //   top_menu
      // );
    }
  };

  callProduct_EmptyCart_Function = (
    value,
    categoryIds,
    top_menu,
    filter_data,
    pageName
  ) => {
    this.setState({
      specials: value.replace("q=", ""),
      page: 1,
      category_id: categoryIds,
      resultBrandData: [],
      // special_filter: filter_data,
      special_filter_value: top_menu,
      ProductList: [],
    });
    if (top_menu === "") {
      this.setState({ special_filter_value: value });
    }
    this.setState({ CategoryListLoader: true });

    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      this.state.min_price,
      this.state.max_price,
      categoryIds,
      value.replace("q=", ""),
      filter_data,
      top_menu,
      this.state.order
    );
    // this.FetchFilterApiByBrandFlavorKosher(
    //   "All",
    //   1,
    //   categoryIds,
    //   this.state.cat_id,
    //   this.state.brandIds,
    //   this.state.brandName,
    //   value.replace("q=", ""),
    //   filter_data,
    //   top_menu
    // );
  };

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {});
  };

  openAddCount = (
    product_id,
    added_quantity,
    is_case,
    categoryindex,
    addTocartQty = 1,
    container_type
  ) => {
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.setState({ setOpenLoginPopup: true, isLoginCLostBtnShow: false });
      localStorage.setItem(
        "tempProductData",
        JSON.stringify({
          product_id,
          added_quantity,
          is_case,
          categoryindex,
          addTocartQty,
          container_type,
        })
      );
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({ isslotDeliverypopupState_compwill: true });
    } else {
      if (added_quantity === "") {
        this.setState({ inputField: true });
        var el = document.getElementById(`${product_id}`);
        el.value = "";
        var event = new Event("input", { target: el, bubbles: true });
        return el ? el.dispatchEvent(event) : false;
      }
      added_quantity = parseInt(added_quantity);
      addTocartQty = parseInt(addTocartQty);
      if (added_quantity > 0 && addTocartQty === 0) {
        this.AddToCartFunctionlity(
          product_id,
          added_quantity,
          is_case,
          categoryindex
        );
      } else if (added_quantity > 0 && addTocartQty >= 1) {
        this.UpdateCartItemFunctionlity(
          product_id,
          added_quantity,
          is_case,
          categoryindex,
          container_type
        );
      } else {
        this.deleteCartItem(
          product_id,
          added_quantity,
          is_case,
          categoryindex,
          container_type
        );
      }
      this.setState({ inputField: false });
    }
  };

  changeInputBoxValue = () => {
    this.setState({ inputField: false });
  };

  AddToCartFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    categoryindex
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: 1,
      is_case: is_case,
    };
    this.setState({ cartListLoader: true });
    let ProductList = this.state.ProductList;
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddToCartFunctionlity(
                product_id,
                1,
                is_case,
                categoryindex
              );
            }
          );
        }
      } else if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        this.FetchCartItemList();
        this.UpdateProductListData(
          ProductList,
          product_id,
          categoryindex,
          added_quantity,
          is_case
        );
      }
    });
  };
  UpdateProductListData = (
    ProductList,
    product_id,
    categoryindex,
    added_quantity,
    is_case
  ) => {
    var Proind = ProductList[categoryindex].productList.findIndex(
      (p) => p.id === product_id
    );
    if (Proind >= 0 && added_quantity >= 0) {
      var added_qtyInd =
        ProductList[categoryindex].productList[Proind][
          "added_quantity_is_case"
        ].indexOf(is_case);
      if (added_qtyInd > -1) {
        ProductList[categoryindex].productList[Proind]["added_quantity"][
          added_qtyInd
        ] = added_quantity;
      } else {
        ProductList[categoryindex].productList[Proind]["added_quantity"].push(
          added_quantity
        );
        ProductList[categoryindex].productList[Proind][
          "added_quantity_is_case"
        ].push(is_case);
      }
    }
    this.setState({
      cartListLoader: false,
      ProductList,
    });
  };
  UpdateCartItemFunctionlity = async (
    product_id,
    added_quantity,
    is_case,
    categoryindex,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: typeof note === "string" ? note : "",
      container_type: typeof container_type === "string" ? container_type : "",
    };
    let cartItemList = this.state.cartItemList;
    let ProductList = this.state.ProductList;
    let SubTotal = 0;
    var Proind = ProductList[categoryindex].productList.findIndex(
      (p) => p.id === product_id
    );
    ProductList[categoryindex].productList[Proind]["disabled"] = true;
    this.setState({ ProductList });
    this.setState({ cartListLoader: true });
    await UpdateAPIDebounced(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.UpdateCartItemFunctionlity(
                product_id,
                added_quantity,
                is_case,
                categoryindex,
                note,
                container_type
              );
            }
          );
        }
      } else if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < cartItemList.length; i++) {
          if (cartItemList[i].product_id === product_id) {
            cartItemList[i].total_price = res.subtotal;
            cartItemList[i].qty = res.quantity;
          }
          SubTotal += Number(cartItemList[i].total_price);
        }
        if (Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd =
            ProductList[categoryindex].productList[Proind][
              "added_quantity_is_case"
            ].indexOf(is_case);
          if (added_qtyInd > -1) {
            ProductList[categoryindex].productList[Proind]["added_quantity"][
              added_qtyInd
            ] = added_quantity;
          } else {
            ProductList[categoryindex].productList[Proind][
              "added_quantity"
            ].push(added_quantity);
            ProductList[categoryindex].productList[Proind][
              "added_quantity_is_case"
            ].push(is_case);
          }
        }
      }
      ProductList[categoryindex].productList[Proind]["disabled"] = false;
      this.FetchCartItemList();
      this.setState({ cartItemList, ProductList });
      this.setState({ cartListLoader: false, SubTotal: SubTotal.toFixed(2) });
      this.setState({ disabled: false });
    });
  };

  deleteCartItem = (
    product_id,
    added_quantity,
    is_case,
    categoryindex,
    index,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type ? container_type : "",
    };
    let cartItemList = this.state.cartItemList;
    let ProductList = this.state.ProductList;
    let SubTotal = 0;
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.deleteCartItem(
                product_id,
                added_quantity,
                is_case,
                index,
                container_type,
                categoryindex
              );
            }
          );
        }
      } else if (res.data) {
        for (let i = 0; i < cartItemList.length; i++) {
          if (cartItemList[i].id === index) {
            cartItemList[i].total_price = res.subtotal;
            cartItemList[i].qty = res.quantity;
            cartItemList.splice(i, 1);
          }
        }
        var Proind = ProductList[categoryindex].productList.findIndex(
          (p) => p.id === product_id
        );
        if (isNaN(added_quantity)) {
          added_quantity = 0;
        }
        if (Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd =
            ProductList[categoryindex].productList[Proind][
              "added_quantity_is_case"
            ].indexOf(is_case);
          if (added_qtyInd > -1) {
            ProductList[categoryindex].productList[Proind]["added_quantity"][
              added_qtyInd
            ] = added_quantity;
          } else {
            ProductList[categoryindex].productList[Proind][
              "added_quantity"
            ].push(0);
            ProductList[categoryindex].productList[Proind][
              "added_quantity_is_case"
            ].push(is_case);
          }
        }
        this.FetchCartItemList();
        this.setState({
          cartItemList: cartItemList,
          ProductList,
        });
      }
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });

    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let cartItemList = [];
      let CartCount = {};

      if (res.data && res.data.result) {
        cartItemList = res.data.result;
        // const promises = response.map((ele) => {
        //   let localUrl = ele?.cloudinary_images_local;
        //   let globalUrl = ele?.cloudinary_images_global;
        //   let currentUrl =
        //     ele.image !== null
        //       ? ele.image[0] === "/"
        //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
        //         : ele.image
        //       : NO_IMAGE;

        //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     ele.image = url.url;
        //     ele.valid = url.valid;
        //     return ele;
        //   });
        // });

        // Promise.all(promises).then((updatedCartItems) => {
          if (cartItemList && cartItemList.length > 0) {
            for (let i = 0; i < cartItemList.length; i++) {
              SubTotal += Number(cartItemList[i].total_price);
            }
          }

          if (res.data.total > 0) {
            CartCount.item = res.data.total;
            CartCount.price = Number(res.data.total_subtotal).toFixed(2);
          }

          this.setState({
            cartItemList: cartItemList,
            SubTotal: SubTotal.toFixed(2),
            cartListOpen: true,
            CartCount: CartCount,
            cartLoader: false,
          });
        // });
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, this, (myThis) => {
            myThis.FetchCartItemList();
          });
        }
      }
      // else {
      //   this.HitGuestApiToken();
      // }
    });
  };

  changeConatinerType = (event, type, myContainer = null) => {
    let text = "";
    var el = document.getElementById("exampleFormControlSelect1");
    if (type === "select") {
      text = el.options[el.selectedIndex].innerHTML;
    } else {
      text = myContainer;
    }
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    let chack_is_case = this.state.chack_is_case;
    let AddtoCartBtn = this.state.AddtoCartBtn;
    var container_ind = ProductDetails.container_type.indexOf(text);
    if (container_ind > -1) {
      if (
        ProductDetails.added_quantity_is_case[container_ind] === chack_is_case
      ) {
        pro_item_details_ind = container_ind;
      } else {
      }
      AddtoCartBtn = false;
    } else {
      pro_item_details_ind = -1;
      AddtoCartBtn = true;
    }

    this.setState({
      containerValue: event.target.value,
      ProductDetails,
      container_type: text,
      pro_item_details_ind: pro_item_details_ind,
      AddtoCartBtn: AddtoCartBtn,
    });
  };
  changeChilditemdata = (event, type, myContainer = null) => {
    let text = "";
    var el = document.getElementById("exampleFormControlSelect2");
    let pro_details_ind = this.state.pro_item_details_ind;
    if (type === "select") {
      text = event.target.value;
    } else {
      text = myContainer;
    }
    let status = false;
    if (text !== "") {
      let data = this.state.ProductDetails;

      this.state.child_items.forEach((element) => {
        if (element.id.toString() === text) {
          status = true;
          for (var key of Object.keys(data)) {
            if (
              key !== "name" &&
              key !== "is_parent" &&
              key !== "slug" &&
              key !== "about_product" &&
              key !== "ingredient"
            ) {
              data[key] = element[key];
            }
          }
          var is_checked = false;

          if (data.added_quantity.length > 0) {
            if (data.added_quantity[0] !== 0) {
              pro_details_ind = 0;
              is_checked = data.added_quantity_is_case[0];
            } else {
              data.added_quantity = [0];
              data.added_quantity_is_case = [0];
              data.container_type = [];
              pro_details_ind = 0;
            }
          }
          this.setState({
            ProductDetails: Object.assign({}, data),
            selectedChilditem: event.target.value,
            pro_item_details_ind: pro_details_ind,
          });
        }
      });
      if (status === false) {
        this.setState({
          ProductDetails: Object.assign({}, this.state.Parent_item),
          selectedChilditem: "",
          pro_item_details_ind:
            this.state.Parent_item.added_quantity.length > 0
              ? 0
              : pro_details_ind,
        });
      }
    } else {
      this.setState({
        ProductDetails: JSON.parse(JSON.stringify(this.state.Parent_item)),
        selectedChilditem: "",
        pro_item_details_ind:
          this.state.Parent_item.added_quantity.length > 0
            ? 0
            : pro_details_ind,
      });
    }
  };
  commonFunctionLity = (product_id, added_quantity, is_case) => {
    let PopularProduct = this.state.PopularProductList;
    var Proind = PopularProduct.findIndex((p) => p.id === product_id);
    if (Proind > -1 && added_quantity > -1) {
      var added_qtyInd =
        PopularProduct[Proind]["added_quantity_is_case"].indexOf(is_case);
      if (added_qtyInd > -1) {
        PopularProduct[Proind]["added_quantity"][added_qtyInd] = added_quantity;
      } else if (added_quantity > 0) {
        PopularProduct[Proind]["added_quantity"].push(added_quantity);
        PopularProduct[Proind]["added_quantity_is_case"].push(is_case);
      } else {
        PopularProduct[Proind]["added_quantity"].push(0);
        PopularProduct[Proind]["added_quantity_is_case"].push(is_case);
      }
      this.setState({ PopularProductList: PopularProduct });
    }
  };
  FetchPopularProductList = () => {
    let formValues = getSchedule_zip();
    formValues.limit = 5;
    formValues.page = 1;
    PopularProductsList(formValues, "").then((res) => {
      let popularProducts = [];
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchPopularProductList();
            }
          );
        }
      } else if (res.data && res.data.result) {
        popularProducts = res.data.result;
      }
      this.setState({ PopularProductList: popularProducts });
    });
  };

  commonFunctionForContainer = (
    added_qtyInd,
    added_quantity,
    added_quantity_is_case
  ) => {
    let ProductDetails = this.state.ProductDetails;
    let container_type = this.state.container_type;
    let ProductList = this.state.ProductList;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    if (added_qtyInd > -1) {
      let added_ind = added_qtyInd;
      if (container_type) {
        var container_typeInd =
          ProductDetails["container_type"].indexOf(container_type);
        if (container_typeInd > -1) {
          ProductDetails["added_quantity"][container_typeInd] = added_quantity;
          pro_item_details_ind = container_typeInd;
        } else {
          ProductDetails["container_type"].push(container_type);
          ProductDetails["added_quantity"].push(added_quantity);
          ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
          pro_item_details_ind = ProductDetails["container_type"].length - 1;
        }
      } else {
        if (added_quantity === 0) ProductDetails["added_quantity"] = [];
        else ProductDetails["added_quantity"][added_ind] = added_quantity;
        pro_item_details_ind = added_qtyInd;
      }
    } else {
      ProductDetails["added_quantity"].push(added_quantity);
      ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
      if (container_type !== "") {
        ProductDetails["container_type"].push(container_type);
      }
      pro_item_details_ind = ProductDetails["added_quantity"].length - 1;
    }
    if (this.state.selectedChilditem === "") {
      this.setState({
        Parent_item: JSON.parse(JSON.stringify(ProductDetails)),
      });
    }
    this.UpdateProductListData(
      ProductList,
      ProductDetails.id,
      this.state.selectedCategoryindex,
      added_quantity,
      added_quantity_is_case
    );

    this.setState({
      pro_item_details_ind: pro_item_details_ind,
      AddtoCartBtn: false,
      ProductDetails,
      Parent_item:
        this.state.Parent_item.id === ProductDetails.id
          ? Object.assign({}, ProductDetails)
          : this.state.Parent_item,
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
        this.setState({ open: false, open_inner: false, itemDetails: false });
      }
    });
  };

  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    this.HitGuestApiToken();
    this.props.history.push({
      pathname: "/",
    });
  };

  HitGuestApiToken = () => {
    let formValues = {};
    let specials = this.state.specials;
    let top_menu_value = this.state.top_menu_value;
    let catId =
      this.props.location.state && this.props.location.state.category_id
        ? this.props.location.state.category_id
        : "";
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
      logo = res.data && res.data.logo ? res.data.logo : "";
      // document.title = commonPageTitel(
      //   JSON.parse(localStorage.getItem("access_token"))
      // );
      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchCategoryTreeView();
      this.ExtraChargesFunctiolity();
      this.setState({ logo: logo });
      if (this.state.specials !== undefined) {
        this.callSpacialFunction(
          this.state.specials,
          this.state.category_id,
          this.state.top_menu_value,
          this.state.pageName
        );
      } else {
        this.FetchProductList(
          1,
          this.state.filterByType,
          this.state.filterByKosher,
          this.state.filterByFlavor,
          this.state.brandIds,
          "",
          "",
          catId,
          specials,
          "",
          top_menu_value,
          this.state.order
        );
      }
    });
  };

  bttnToggle = () => {
    this.setState({ cartListOpen: !this.state.cartListOpen, categoryIds: [] });
  };

  preProductItem = (pre_info, next_info) => {
    let index = this.state.selectedProductindex;
    let catindex = this.state.selectedCategoryindex;
    let Pro_id = this.state.ProductList[catindex].productList[index - 1];
    let next_slug =
      this.state.ProductList[catindex].productList[index - 1]?.slug;
    if (index === -1) {
      pre_info = false;
    } else pre_info = true;
    if (index === this.state.ProductList[catindex].productList.length)
      next_info = false;
    else next_info = true;
    if (index === 1) {
      this.setState({
        AddtoCartBtn: true,
        selectedProductindex: index - 1,
        selectedCategoryindex: catindex,
        is_prev_rec: false,
        is_next_rec: next_info,
      });
    } else
      this.setState({
        AddtoCartBtn: true,
        selectedProductindex: index - 1,
        selectedCategoryindex: catindex,
        is_prev_rec: pre_info,
        is_next_rec: next_info,
      });
    this.FetchProdutDetails(
      Pro_id,
      next_slug,
      next_info,
      pre_info,
      this.state.special_filter,
      this.state.special_filter_value
    );
  };

  nextProductItem = (pre_info, next_info) => {
    let index = this.state.selectedProductindex;
    let catindex = this.state.selectedCategoryindex;
    if (index === this.state.ProductList[catindex]?.productList?.length - 1) {
      this.setState({ is_next_rec: false });
    } else {
      if (
        this.state.pageLimit * this.state.page < this.state.total &&
        index === this.state.pageLimit * this.state.page - 1
      ) {
        let nextpage = this.state.page + 1;
        this.setState({ page: nextpage });
        this.FetchProductList(
          nextpage,
          this.state.filterByType,
          this.state.filterByKosher,
          this.state.filterByFlavor,
          this.state.brandIds,
          this.state.min_price,
          this.state.max_price,
          this.state.category_id,
          this.state.specials,
          this.state.product_deal,
          this.state.top_menu_value,
          this.state.order
        );
      } else {
        let Pro_id = this.state.ProductList[catindex]?.productList[index + 1];
        let next_slug =
          this.state.ProductList[catindex].productList[index + 1]?.slug;
        if (index === this.state.ProductList[catindex].productList.length - 2)
          next_info = false;
        if (index === -1) {
          pre_info = false;
        } else pre_info = true;
        this.setState({
          AddtoCartBtn: true,
          selectedProductindex: index + 1,
          selectedCategoryindex: catindex,
          is_next_rec: next_info,
          is_prev_rec: pre_info,
        });
        this.FetchProdutDetails(
          Pro_id,
          next_slug,
          next_info,
          pre_info,
          this.state.special_filter,
          this.state.special_filter_value
        );
      }
    }
  };

  redirectingListingPage = (category_id) => {
    window.scrollTo(0, 0);
    let categoryIds = this.state.categoryIds;
    this.child.current?.bindCategorygroupdata(this.state.tree_data, false);
    // this.headerchild.current?.closeViewfullcategory()
    let Props_value = this.state.Props_value;
    categoryIds.push(category_id);
    if (this.props) {
      Props_value = this.props.history;
    }

    this.setState({
      page: 1,
      category_id: category_id,
      categoryIds: categoryIds,
      Props_value: Props_value,
      itemDetails: false,
      open_inner: false,
      open: false,
      specials: "",
      product_deal: "",
      ProductList: [],
      filterByType: [],
      customChipsData: "",
      // brandIds: [],
      // brandName: [],
      // BrandProduct: [],
      flavor: [],
      kosher: [],
      // special_filter: "all",
      // special_filter_value: "",
    });
    this.bindCategorygroupdata();

    window.history.replaceState(
      null,
      "New Page Title",
      `/listing/${category_id}`
    );
  };

  AddCountForcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let cartItemList = this.state.cartItemList;
    let productlist = this.state.ProductList;
    let categoryindx = 0;
    for (let i = 0; i < productlist.length; i++) {
      let data = productlist[i].productList;
      for (let j = 0; j < data.length; j++) {
        if (data[j].id === product_id) categoryindx = i;
      }
    }
    if (icons === "icon-plus") {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = cartItemList[i].qty + 1;

          this.UpdateCartItemFunctionlity(
            product_id,
            cartItemList[i].qty,
            cartItemList[i].is_case,
            categoryindx,
            cartItemList[i].note,
            cartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = cartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              categoryindx,
              cartItemList[i].note,
              cartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              cartItemList[i].qty,
              added_qty_is_case,
              categoryindx,
              index,
              cartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < cartItemList.length; i++) {
        if (cartItemList[i].id === index) {
          cartItemList[i].qty = added_quantity
            ? added_quantity
            : cartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              categoryindx,
              cartItemList[i].note,
              cartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              cartItemList[i].qty,
              cartItemList[i].is_case,
              categoryindx,
              index,
              cartItemList[i].container_type
            );
          }
        }
      }
      // this.setState({headerinputField: false});
    }
    this.setState({ cartItemList: cartItemList, headerinputField: false });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  nextBrandList = (filter_name) => {
    let brand_page = this.state.brand_page;
    let brand_total = this.state.brand_total;
    if (brand_page * 15 < brand_total) {
      this.FetchFilterApiByBrandFlavorKosher(
        filter_name,
        brand_page + 1,
        this.state.category_id,
        this.state.cat_id,
        this.state.brandIds,
        this.state.brandName,
        this.state.specials,
        this.state.product_deal,
        this.state.top_menu_value
      );
      brand_page = this.state.brand_page + 1;
    } else {
      brand_page = this.state.brand_page - 1;
    }
    this.setState({ brand_page: brand_page, categoryIds: [] });
  };

  prevBrandList = (filter_name) => {
    let brand_page = this.state.brand_page;
    if (brand_page > 0) {
      this.FetchFilterApiByBrandFlavorKosher(
        filter_name,
        brand_page - 1,
        this.state.category_id,
        this.state.cat_id,
        this.state.brandIds,
        this.state.brandName,
        this.state.specials,
        this.state.product_deal,
        this.state.top_menu_value
      );
      brand_page = this.state.brand_page - 1;
    } else {
      brand_page = this.state.brand_page + 1;
    }
    this.setState({ brand_page: brand_page, categoryIds: [] });
  };

  handleChangeLessthanTwenty = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: !this.state.checkedLessThanTwenty,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      min_price: price_min,
      max_price: price_max,
    });
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeTwetyToFifty = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: !this.state.checkedTwentyOneToFifty,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      min_price: price_min,
      max_price: price_max,
    });
    if (this.state.checkedTwentyOneToFifty === true) {
      price_min = 0;
      price_max = 0;
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeFiftyToHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: !this.state.checkedFiftyToHundread,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      min_price: price_min,
      max_price: price_max,
    });
    if (this.state.checkedFiftyToHundread === true) {
      price_min = 0;
      price_max = 0;
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeHundreadToTwoHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: !this.state.checkedHundreadToTwohundread,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      min_price: price_min,
      max_price: price_max,
    });
    if (this.state.checkedHundreadToTwohundread === true) {
      price_min = 0;
      price_max = 0;
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeTwoHundreadToFiveHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread:
        !this.state.checkedTwohundreadToFivehundread,
      checkedGraterThanFifty: false,
      min_price: price_min,
      max_price: price_max,
    });
    if (this.state.checkedTwohundreadToFivehundread === true) {
      price_min = 0;
      price_max = 0;
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  handleChangeGreaterThanFiveHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: !this.state.checkedGraterThanFifty,
      min_price: price_min,
      max_price: price_max,
    });
    if (this.state.checkedGraterThanFifty === true) {
      price_min = 0;
      price_max = 0;
    }
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      price_min,
      price_max,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  ApplyFilterForMobileView = () => {
    this.setState({ FilterForMobileView: false });
  };

  openFilterMobileView = () => {
    if (this.state.BrandProduct && this.state.BrandProduct.length === 0) {
      this.FetchFilterApiByBrandFlavorKosher(
        "All",
        this.state.page,
        this.state.category_id,
        this.state.brandIds,
        this.state.brandName,
        this.state.specials,
        this.state.product_deal,
        this.state.top_menu_value
      );
    }
    this.setState({ FilterForMobileView: !this.state.FilterForMobileView });
  };

  closeFilterMobileView = () => {
    this.setState({
      FilterForMobileView: false,
      BrandProduct: [],
      flavor: [],
      kosher: [],
      fruitType: [],
    });
  };
  closePriceRange = () => {
    this.setState({
      max_price: 0,
    });
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      0,
      0,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  ProductCaseFunctionlity = (product_id, is_case) => {
    let chack_is_case = this.state.chack_is_case;
    chack_is_case[product_id] = is_case;
    this.setState({ chack_is_case: chack_is_case });
  };
  ProductCaseFunctionlityFrequtenltyItem = (added_qty_is_case, note) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;

    var check_ind =
      ProductDetails.added_quantity_is_case.indexOf(added_qty_is_case);
    if (check_ind > -1) {
      pro_item_details_ind = check_ind;
    } else {
      pro_item_details_ind = -1;
    }
    this.setState({
      check_added_quantity_is_case: added_qty_is_case,
      pro_item_details_ind,
    });
  };
  ProductCaseFunctionlityForItem = (added_qty_is_case, note) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    var check_ind =
      ProductDetails.added_quantity_is_case.indexOf(added_qty_is_case);
    if (check_ind > -1) {
      pro_item_details_ind = check_ind;
    } else {
      pro_item_details_ind = -1;
    }
    this.setState({ check_isCase: added_qty_is_case, pro_item_details_ind });
    //
  };

  backToParentProduct = (category_id) => {
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      "",
      "",
      category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  addnote = (value, note_value, cartItemVal) => {
    let ProductDetails = this.state.ProductDetails;
    let cartItemList = this.state.cartItemList;
    let product_id;
    let quentiry;
    let is_case;
    let index = "";
    if (cartItemVal) {
      product_id = cartItemVal["product_id"];
      quentiry = cartItemVal["qty"];
      is_case = cartItemVal["is_case"];
    }
    this.UpdateCartItemFunctionlity(
      product_id,
      quentiry,
      is_case,
      index,
      note_value
    );
    this.setState({ cartItemList, ProductDetails });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
    });
  };

  addQuentityForaddTocart = (
    targetValue,
    added_qty_is_case,
    container_type
  ) => {
    targetValue = parseInt(targetValue);
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    if (!targetValue) {
      this.setState({ inputField: true });
      var el = document.getElementById(`${ProductDetails.id}`);
      el.value = "";
      var event = new Event("input", { target: el, bubbles: true });
      return el ? el.dispatchEvent(event) : false;
    }
    let result;
    if (targetValue > 0) {
      result = CommonAddQtyFunction(
        ProductDetails,
        targetValue,
        added_qty_is_case
      );
      if (result) {
        ProductDetails = result;
        pro_item_details_ind = 0;
        this.UpdateCartItemFunctionlity(
          ProductDetails.id,
          targetValue,
          added_qty_is_case,
          ProductDetails.note.toString(),
          container_type
        );
      }
    } else {
      this.deleteCartItem(
        ProductDetails.id,
        targetValue,
        added_qty_is_case,
        container_type
      );
    }
    this.setState({ ProductDetails: ProductDetails, pro_item_details_ind });
  };

  HandelChangeTrueWishList = (product_id, value) => {
    // this.props.history.push({
    //   pathname: "/login",
    //   appState: {
    //     pagename: this.props.location.pathname,
    //   },
    // });
    this.OpenLoginPoup();
  };

  FetchSubCategoryList = (category_id, level, parent_id) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    if (category_id === this.state.parent_onlyid[0]) {
      this.setState({
        parentIds: [this.state.parentIds[0]],
        parent_onlyid: [this.state.parent_onlyid[0]],
        parent_level: [this.state.parent_level[0]],
        SubSubCategoryName: undefined,
      });
    }

    this.commonSetParentIdsOnlyIdLevel(category_id, level, parent_id);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(formValues.ids);
            }
          );
        }
      }
      this.setState({
        SubCategoryList: subCategoryList,
        SubSubCategoryList: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level, parent_id = "") => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);

    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = [];
    var resultParentLevel = [];
    var resultParentIds = [];
    if (parent_id !== null && parent_id !== "") {
      resultParentIds.splice(0, 0, parent_id + "" + (level + 1));
      reultParentOnlyId.splice(0, 0, parent_id);
    } else {
      resultParentIds.push(resultParentIds[0]);
      reultParentOnlyId.push(reultParentOnlyId[0]);

      reultParentOnlyId = CommonSetParentOnlyIdLevel(
        selectOnlyIndexForparentOnlyId,
        this.state.parent_onlyid,
        category_id
      );
      resultParentLevel = CommonSetParentLevelSet(
        selectParentLevelIndexParentLevel,
        this.state.parent_level,
        level
      );
      resultParentIds = CommonSetParentIds(
        selectedIndexForparentIds,
        this.state.parentIds,
        category_id,
        level
      );
    }

    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  handleInnerOpenDialog(
    e,
    parent_id,
    subcategory_name,
    has_child,
    level,
    cslug,
    all_data
  ) {
    let SubcategoryName = this.state.SubcategoryName;
    let SubSubCategoryName = this.state.SubSubCategoryName;
    if (all_data && all_data.length > 0) {
      if (all_data.length === 2) {
        for (let i = 0; i < all_data.length; i++) {
          if (all_data[i].category_name === subcategory_name) {
            SubcategoryName = all_data[i].category_name;
          } else {
            SubSubCategoryName =
              parent_id != null ? all_data[i].category_name : "";
          }
        }
      } else {
        SubcategoryName = all_data[0].category_name;
        SubSubCategoryName = parent_id != null ? all_data[1].category_name : "";
      }
    } else {
      SubcategoryName = subcategory_name;
    }

    if (has_child === true) {
      this.setState({
        open_inner: true,
        SubcategoryName: SubcategoryName,
        SubSubCategoryName: SubSubCategoryName,
      });
      this.FetchSubCategoryList(e, 0, parent_id);
    } else {
      this.handleInnerCloseDialog();
    }
  }
  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  SetBodyClass = (value) => {
    this.setState({ bodyInnerClass: value });
  };

  addFavoiteLevelFunctionlity = (favoriteLevel, product_id) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res && res.message) {
          this.FetchFavoriteLevelList(product_id);
        }
      }
    );
  };

  FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: product_id,
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelListData = [];
        if (res.data && res.data.result) {
          favoriteLevelListData = res.data.result;
        }
        this.setState({ favoriteLevelListData: favoriteLevelListData });
      }
    );
  };

  addFavoiteProductFunctionlity = (
    favoritelabel_id,
    product_id,
    quantity,
    categoryIndx,
    added_quantity_is_case
  ) => {
    let formValues = {
      product_id: product_id,
      favoritelabel_id: favoritelabel_id ? favoritelabel_id.toString() : "",
      quantity: quantity,
      is_case: added_quantity_is_case,
      note: "",
    };
    let ProductList = this.state.ProductList;
    AddFavoriteProductFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          for (
            let i = 0;
            i < ProductList[categoryIndx].productList.length;
            i++
          ) {
            if (
              ProductList[categoryIndx].productList[i].id === product_id &&
              favoritelabel_id !== ""
            ) {
              ProductList[categoryIndx].productList[i].wishlist = true;
              ProductList[categoryIndx].productList[i].favoriot_id =
                res.favorite_ids;
            }
          }
        }

        this.setState({ ProductList: ProductList });
      }
    );
  };
  removeFromfavouriteProduct = (
    favoriteproduct_id,
    product_id,
    categoryIndx
  ) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.join(","),
    };
    let ProductList = this.state.ProductList;
    RemoveFavouriteProduct(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.message) {
        // this.createSuccessMsg("success", res.data.message);
        for (let i = 0; i < ProductList[categoryIndx].productList.length; i++) {
          if (ProductList[categoryIndx].productList[i].id === product_id) {
            ProductList[categoryIndx].productList[i].wishlist = false;
            ProductList[categoryIndx].productList[i].favoriot_id = [];
          }
        }
      }
      this.setState({ ProductList: ProductList });
    });
  };
  AfterRemoveCart = () => {
    this.FetchProductList(
      1,
      this.state.filterByType,
      this.state.filterByKosher,
      this.state.filterByFlavor,
      this.state.brandIds,
      this.state.min_price,
      this.state.max_price,
      this.state.category_id,
      this.state.specials,
      "",
      this.state.top_menu_value,
      this.state.order
    );
  };

  addDefaultSubCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  hendleAddtoCartBtnForListing = () => {
    this.setState({ AddtoCartBtn: true });
  };
  handleSlotName = (data) => {
    this.setState({ slotName: data });
  };
  closeisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: false });
  };
  openisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: true });
  };
  handleChangeSorting = (value) => {
    if (value !== this.state.order) {
      this.setState({
        order: value,
        CategoryListLoader: true,
        ProductList: [],
      });
      let productlist = this.state.ProductList;
      let urlcategoryid = this.props.history.location.pathname.split("/")[2];
      // if (isNaN(this.props.history.location.pathname.split("/")[2]) === false) {
      for (let i = 0; i < productlist.length; i++) {
        if (value === "name" || value === "z-aName") {
          if (value === "name")
            productlist[i]?.productList.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          else
            productlist[i]?.productList
              .sort((a, b) => a.name.localeCompare(b.name))
              .reverse();

          this.setState({
            ProductList: productlist,
            CategoryListLoader: false,
          });
        } else {
          if (value === "price_low_to_high")
            productlist[i]?.productList.sort(
              (a, b) => a.original_price - b.original_price
            );
          else
            productlist[i]?.productList
              .sort((a, b) => a.original_price - b.original_price)
              .reverse();
          this.setState({
            ProductList: productlist,
            CategoryListLoader: false,
          });
        }
      }
    }
  };
  renderItemcard = (row, index, categoryIndx, categoryData) => {
    var c_i_case = this.state.chack_is_case[row.id]
      ? this.state.chack_is_case[row.id]
      : false;
    var check_added_quantity_is_case =
      row.added_quantity_is_case.indexOf(c_i_case);
    var check_is_case_flag = false;
    if (check_added_quantity_is_case > -1) {
      check_is_case_flag = true;
    } else {
      check_is_case_flag = true;
      row.added_quantity_is_case.push(c_i_case);
      row.added_quantity.push(0);
      check_added_quantity_is_case =
        row.added_quantity_is_case.indexOf(c_i_case);
    }

    return window.screen.availWidth >= 700 ? (
      row?.iscatering?.some((x) => x === true) ? (
        <ItemCardCatering
          iscatering={true}
          categoryData={categoryData}
          extraCharges={this.state.extraCharges}
          check_is_case_flag={check_is_case_flag}
          check_added_quantity_is_case={check_added_quantity_is_case}
          itemData={row}
          favoriteLevelListData={this.state.favoriteLevelListData}
          index={index}
          categoryIndx={categoryIndx}
          removeFromfavouriteProduct={(favoriot_id, product_id, categoryIndx) =>
            this.removeFromfavouriteProduct(
              favoriot_id,
              product_id,
              categoryIndx
            )
          }
          addFavoiteLevelFunctionlity={(value, product_id) =>
            this.addFavoiteLevelFunctionlity(value, product_id)
          }
          history={this.props.history}
          activePageName={this.props.location.pathname}
          addFavoiteProductFunctionlity={(
            favoritelabel_id,
            id,
            added_quantity,
            categoryIndx,
            added_quantity_is_case
          ) =>
            this.addFavoiteProductFunctionlity(
              favoritelabel_id,
              id,
              added_quantity,
              categoryIndx,
              added_quantity_is_case
            )
          }
          FetchFavoriteLevelList={(product_id) =>
            this.FetchFavoriteLevelList(product_id)
          }
          HandelChangeTrueWishList={() => this.HandelChangeTrueWishList()}
          handlePopOpen={(id, slug, next, prev, index, categoryindx) =>
            this.handlePopOpen(id, slug, next, prev, index, categoryindx)
          }
          openAddCount={(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          ) =>
            this.openAddCount(
              id,
              added_quantity,
              added_quantity_is_case,
              categoryindex,
              containertype
            )
          }
          openAddCounttext={(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          ) =>
            this.openAddCount(
              id,
              added_quantity,
              added_quantity_is_case,
              categoryindex,
              containertype
            )
          }
          disabled={this.state.disabled}
          inputField={this.state.inputField}
          changeInputBoxValue={() => this.changeInputBoxValue()}
          ProductCaseFunctionlity={(id, event) =>
            this.ProductCaseFunctionlity(id, event)
          }
          cardWidth={"33%"}
          cardSize={this.state.cardSize}
          restrictByOnhand={this.state.restrict_by_onhand}
        />
      ) : (
        <ItemCard
          categoryData={categoryData}
          extraCharges={this.state.extraCharges}
          check_is_case_flag={check_is_case_flag}
          check_added_quantity_is_case={check_added_quantity_is_case}
          itemData={row}
          favoriteLevelListData={this.state.favoriteLevelListData}
          index={index}
          categoryIndx={categoryIndx}
          removeFromfavouriteProduct={(favoriot_id, product_id, categoryIndx) =>
            this.removeFromfavouriteProduct(
              favoriot_id,
              product_id,
              categoryIndx
            )
          }
          addFavoiteLevelFunctionlity={(value, product_id) =>
            this.addFavoiteLevelFunctionlity(value, product_id)
          }
          history={this.props.history}
          activePageName={this.props.location.pathname}
          addFavoiteProductFunctionlity={(
            favoritelabel_id,
            id,
            added_quantity,
            categoryIndx,
            added_quantity_is_case
          ) =>
            this.addFavoiteProductFunctionlity(
              favoritelabel_id,
              id,
              added_quantity,
              categoryIndx,
              added_quantity_is_case
            )
          }
          FetchFavoriteLevelList={(product_id) =>
            this.FetchFavoriteLevelList(product_id)
          }
          HandelChangeTrueWishList={() => this.HandelChangeTrueWishList()}
          handlePopOpen={(id, slug, next, prev, index, categoryindx) =>
            this.handlePopOpen(id, slug, next, prev, index, categoryindx)
          }
          openAddCount={(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          ) =>
            this.openAddCount(
              id,
              added_quantity,
              added_quantity_is_case,
              categoryindex,
              containertype
            )
          }
          openAddCounttext={(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          ) =>
            this.openAddCount(
              id,
              added_quantity,
              added_quantity_is_case,
              categoryindex,
              containertype
            )
          }
          disabled={this.state.disabled}
          inputField={this.state.inputField}
          changeInputBoxValue={() => this.changeInputBoxValue()}
          ProductCaseFunctionlity={(id, event) =>
            this.ProductCaseFunctionlity(id, event)
          }
          cardWidth={this.state.cardWidth}
          cardSize={this.state.cardSize}
          restrictByOnhand={this.state.restrict_by_onhand}
        />
      )
    ) : (
      <ItemCardMob
        extraCharges={this.state.extraCharges}
        check_is_case_flag={check_is_case_flag}
        check_added_quantity_is_case={check_added_quantity_is_case}
        itemData={row}
        favoriteLevelListData={this.state.favoriteLevelListData}
        index={index}
        categoryIndx={categoryIndx}
        removeFromfavouriteProduct={(favoriot_id, product_id, categoryIndx) =>
          this.removeFromfavouriteProduct(favoriot_id, product_id, categoryIndx)
        }
        addFavoiteLevelFunctionlity={(value, product_id) =>
          this.addFavoiteLevelFunctionlity(value, product_id)
        }
        history={this.props.history}
        activePageName={this.props.location.pathname}
        addFavoiteProductFunctionlity={(
          favoritelabel_id,
          id,
          added_quantity,
          categoryIndx,
          added_quantity_is_case
        ) =>
          this.addFavoiteProductFunctionlity(
            favoritelabel_id,
            id,
            added_quantity,
            categoryIndx,
            added_quantity_is_case
          )
        }
        FetchFavoriteLevelList={(product_id) =>
          this.FetchFavoriteLevelList(product_id)
        }
        HandelChangeTrueWishList={() => this.HandelChangeTrueWishList()}
        handlePopOpen={(id, slug, next, prev, index, categoryindx) =>
          this.handlePopOpen(id, slug, next, prev, index, categoryindx)
        }
        openAddCount={(
          id,
          added_quantity,
          added_quantity_is_case,
          categoryindex,
          containertype
        ) =>
          this.openAddCount(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          )
        }
        openAddCounttext={(
          id,
          added_quantity,
          added_quantity_is_case,
          categoryindex,
          containertype
        ) =>
          this.openAddCount(
            id,
            added_quantity,
            added_quantity_is_case,
            categoryindex,
            containertype
          )
        }
        disabled={this.state.disabled}
        inputField={this.state.inputField}
        changeInputBoxValue={() => this.changeInputBoxValue()}
        ProductCaseFunctionlity={(id, event) =>
          this.ProductCaseFunctionlity(id, event)
        }
        restrictByOnhand={this.state.restrict_by_onhand}
      />
    );
  };
  openAllcategory = () => {
    this.handleopenAllcategory();
  };
  handleopenAllcategory = (handleopenAllcategoryRef) => {
    this.handleopencategoryClick = handleopenAllcategoryRef;
  };

  scrollcategorywise = (ProductList, selectedCategory) => {
    // Debounce function to limit scroll event handling frequency
    function debounce(func, delay) {
      let timer;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(func, delay);
      };
    }

    function test() {
      const headerHeight = document
        .getElementsByTagName("header")[0]
        ?.getBoundingClientRect()?.height;
      const categories = document.querySelectorAll("[categoty]");
      const categoryParent = document.querySelectorAll("[categoryParent]");
      let web_configure = JSON.parse(localStorage.getItem("web_customize"));
      let ActiveStyle = `color: ${web_configure?.primary?.main};`;
      let defaultStyle = `border: none;`;
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].getBoundingClientRect().y - headerHeight > 0) {
          document
            .querySelectorAll("[data-target-segment]")
            .forEach(function (element) {
              if (element.classList.contains("parent-item") === false) {
                element.style.cssText = defaultStyle;
                const spanElements = element.querySelectorAll("span");
                const svgElements = element.querySelectorAll("svg");

                svgElements.forEach((ele) => {
                  ele.style.color = "#adadad";
                });
                spanElements.forEach(function (span) {
                  span.style.fontWeight = "normal";
                });
              }
            });

          document
            .querySelectorAll(
              '[data-target-segment="' +
                ProductList[categories[i].getAttribute("categoty")]?.parentName
                  ?.id +
                '"]'
            )
            .forEach(function (element) {
              element.style.cssText = ActiveStyle;
              const spanElements = element.querySelectorAll("span");
              const svgElements = element.querySelectorAll("svg");
              spanElements.forEach(function (span) {
                span.style.fontWeight = "bold";
              });
              svgElements.forEach((ele) => {
                ele.style.color = web_configure?.primary?.main;
              });
            });
          document
            .querySelectorAll("[data-target-segment]")
            .forEach(function (element) {
              element.classList.remove("activeItem");
            });
          document
            .querySelectorAll(
              '[data-target-segment="' +
                ProductList[categories[i].getAttribute("categoty")]?.parentName
                  ?.id +
                '"]'
            )
            .forEach(function (element) {
              element.classList.add("activeItem");
            });

          localStorage.setItem("currentCategory", ProductList[categories[i]]);
          break;
        }
      }
    }

    setTimeout(() => {
      test();
    }, 0);

    window.addEventListener("scroll", test);
  };
  closecustomChips = () => {
    this.props.history.push({ pathname: "/" });
    window.location.reload();
  };
  handleHumburgerbtn = (isActive) => {
    let data = this.props.history.location.state;
    if (data === undefined) data = { openallCategory: true };
    else data["openallCategory"] = true;
    this.props.history.push({
      state: data,
    });
    this.setState({ humbergerBtn: isActive });
  };
  closeItemdetailMobile = () => {
    this.setState({ itemDetailsmobModal: false });
  };
  handleAlignment = (event, newAlignment) => {
    let size = this.state.cardWidth;
    if (document.getElementById("allproduct").offsetWidth <= 2500) {
      if (size !== "") {
        let count = size[size.length - 2];
        if (newAlignment === "small") count = +count + 2;
        else if (newAlignment === "big") count = +count - 2;
        size = `calc(100%/${count})`;
      }
      if (newAlignment !== null)
        this.setState({
          cardSize: newAlignment,
          cardWidth: size,
        });
    }
  };
  handleSwipeable = (value) => this.setState({ isSwipeableOpen: value });
  handlesetExpanded = (value) => this.setState({ expandedSwipeable: value });
  render() {
    const {
      CategoryDetails,
      BrandProduct,
      ProductList,
      ProductDetails,
      category_id,
      SubTotal,
      categoryIds,
      parentIds,
      CartCount,
      flavor,
      kosher,
      categoryNames,
      fruitType,
      categoryData,
      filterByType,
      brandName,
      TreeViewIteams,
      cartItemList,
      error,
      CategoryOption,
      web_customize,
    } = this.state;

    const loadingTextCSS = {
      display: this.state.loading ? "flex" : "none",
      flexWrap: "wrap",
      width: "100%",
      marginTop: "10px",
    };
    return (
      <>
        <div
          className="main_containerLoader"
          style={{ display: this.state.CategoryListLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>
        <div
          className={this.state.bodyInnerClass === true ? "add_list_class" : ""}
          ref={this.myRef}
        >
          <Header
            CategoryDetails={CategoryDetails}
            CategoryOption={CategoryOption}
            cartItemList={cartItemList}
            handleOpenDialog={this.handleOpenDialog}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            cartLoader={this.state.cartLoader}
            FetchCartItemList={() => this.FetchCartItemList()}
            FetchCartCount={() => this.FetchCartItemList()}
            AddCountForcartList={(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            ) =>
              this.AddCountForcartList(
                product_id,
                added_quantity,
                is_case,
                icons,
                index,
                container_type
              )
            }
            SubTotal={SubTotal}
            SetBodyClass={(value) => this.SetBodyClass(value)}
            callSpacialFunction={(
              value,
              categoryIds,
              top_menu,
              filter_data,
              pageName
            ) =>
              this.callSpacialFunction(
                value,
                categoryIds,
                top_menu,
                filter_data,
                pageName
              )
            }
            callProduct_EmptyCart_Function={(
              value,
              categoryIds,
              top_menu,
              filter_data,
              pageName
            ) =>
              this.callProduct_EmptyCart_Function(
                value,
                categoryIds,
                top_menu,
                filter_data,
                pageName
              )
            }
            changeheaderinputFieldValue={() =>
              this.changeheaderinputFieldValue()
            }
            headerinputField={this.state.headerinputField}
            logo={this.state.logo}
            categoryData={categoryData}
            FetchDataForPriceRange={(
              price_min,
              price_max,
              category_id,
              subcategory_id,
              has_child,
              selectedCategory
            ) =>
              this.FetchDataForPriceRange(
                price_min,
                price_max,
                category_id,
                subcategory_id,
                has_child,
                selectedCategory
              )
            }
            extraCharges={this.state.extraCharges}
            category_id={this.state.category_id}
            handleSlotNameData={(data) => this.handleSlotName(data)}
            OpenLoginPoup={() => this.OpenLoginPoup()}
            isslotDeliverypopupState_compwill={
              this.state.isslotDeliverypopupState_compwill
            }
            closeisslotDeliverypopupState_compwill={() =>
              this.closeisslotDeliverypopupState_compwill()
            }
            bindCategorygroupdata={(tree_data = []) =>
              this.bindCategorygroupdata(tree_data)
            }
            BrandProduct={BrandProduct}
            flavor={flavor}
            kosher={kosher}
            fruitType={fruitType}
            handleChangeCheckbox={(brand_id, brand__name) =>
              this.handleChangeCheckbox(brand_id, brand__name)
            }
            handleChangeCheckboxForFlover={(flavor) =>
              this.handleChangeCheckboxForFlover(flavor)
            }
            handleChangeCheckboxForKosher={(kosher) =>
              this.handleChangeCheckboxForKosher(kosher)
            }
            sortingData={this.state.sortingData}
            selectedSortedValue={this.state.order}
            handleChangeSorting={(value) => this.handleChangeSorting(value)}
            handleChangeCheckboxForProductType={(product_type) =>
              this.handleChangeCheckboxForProductType(product_type)
            }
            humbergerBtn={this.state.humbergerBtn}
            handleHumburgerbtn={(state) => this.handleHumburgerbtn(state)}
            tree_data={this.state.tree_data}
            TreeViewIteamsHome={this.state.TreeViewIteams}
            SideCategoryListLoader={this.state.SideCategoryListLoader}
          />

          <Loginpopup
            setOpenLoginPopup={this.state.setOpenLoginPopup}
            CloseLoginDialog={(type) => this.CloseLoginDialog(type)}
            isShowclose={true}
            history={this.props.history}
          />
          {CategoryDetails.length > 0 ? (
            <CategoriesModal
              open={this.state.open}
              handleOpenDialog={this.handleOpenDialog}
              handleCloseDialog={this.handleCloseDialog}
              CategoryDetails={CategoryDetails}
              SubCategoryDetails={this.state.SubCategoryList}
              history={this.props}
              pageName={this.state.pageName}
              redirectingListingPage={(category_id) =>
                this.redirectingListingPage(category_id)
              }
              AllCategoryDetails={this.state.tree_data}
              tree_data={this.state.tree_data}
            />
          ) : (
            ""
          )}
          <SubCategoryModal
            open_inner={this.state.open_inner}
            SubcategoryName={this.state.SubcategoryName}
            SubSubCategoryName={this.state.SubSubCategoryName}
            handleInnerOpenDialog={this.handleInnerOpenDialog}
            handleInnerCloseDialog={this.handleInnerCloseDialog}
            handleCloseDialog={this.handleCloseDialog}
            handleOpenDialog={this.handleOpenDialog}
            SubCategoryDetails={this.state.SubCategoryList}
            history={this.props}
            pageName={this.state.pageName}
            SubSubCategoryDetails={this.state.SubSubCategoryList}
            subCategoryLoader={this.state.subCategoryLoader}
            FetchSubCategoryList={(id, level, parent_id) =>
              this.FetchSubCategoryList(id, level, parent_id)
            }
            parentIds={this.state.parentIds}
            parent_onlyid={this.state.parent_onlyid}
            parent_level={this.state.parent_level}
            redirectingListingPage={(category_id) =>
              this.redirectingListingPage(category_id)
            }
            CategoryDetails={this.state.tree_data}
            categoryData={categoryData}
            tree_data={this.state.tree_data}
          />
          {/* <div class="mob-cat-filter d-block d-lg-none">
            <ControlledOpenSpeedDial
              toggleMenuCategory={() => this.toggleMenuCategory()}
            />
          </div> */}

          {/* Start For code mobile view*/}
          {this.state.FilterForMobileView ? (
            <div class="mob-filter d-lg-none">
              <div className="menu-head-mobile">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <ArrowBackIosIcon
                      onClick={() => this.closeFilterMobileView()}
                    />
                  </li>
                  <li className="list-inline-item font-weight-bold text-uppercase">
                    Filters
                  </li>
                </ul>
              </div>

              <div class="mob-filter-footer">
                <div class="row">
                  <div
                    class="col-6 canc"
                    onClick={() => this.closeFilterMobileView()}
                  >
                    Cancel
                  </div>
                  <div
                    class="col-6 apply"
                    onClick={() => this.ApplyFilterForMobileView()}
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ScrollColorTypography
              component="div"
              className="main-data show"
              id="main-listing"
              style={{
                paddingTop:
                  web_customize !== null &&
                  web_customize?.top_marquee !== null &&
                  web_customize?.top_marquee !== "" &&
                  window.screen.availWidth > 900
                    ? "11rem"
                    : "",
              }}
            >
              <section className="listing_inner_data">
                {isNaN(this.props.history.location.pathname.split("/")[2]) ===
                  false ||
                this.props.history.location.pathname
                  .split("/")[2]
                  .includes("SPECIALS") ? (
                  <div className="listing_main">
                    <ScrollColorTypography
                      component={"div"}
                      className="listing_category"
                      style={{
                        paddingTop:
                          web_customize !== null &&
                          web_customize?.top_marquee !== null &&
                          web_customize?.top_marquee !== "" &&
                          window.screen.availWidth > 900
                            ? "5rem"
                            : "",
                      }}
                    >
                      {
                        //           this.state.SideCategoryListLoader ? (
                        //  <div> {[...Array(20)].map((x, i) =>

                        //   <SideCateogorySkeleton />
                        //   )}</div>
                        // ) :

                        CategoryDetails &&
                          CategoryDetails.length > 0 &&
                          TreeViewIteams.length > 0 && (
                            <CategoryListing
                              SideCategoryListLoader={
                                this.state.SideCategoryListLoader
                              }
                              pageName={this.state.pageName}
                              CategoryDetails={CategoryDetails}
                              BrandProduct={BrandProduct}
                              fruitType={fruitType}
                              flavor={flavor}
                              kosher={kosher}
                              handleChangeCheckboxForProductType={(
                                product_type
                              ) =>
                                this.handleChangeCheckboxForProductType(
                                  product_type
                                )
                              }
                              handleChangeCheckboxForFlover={(flover) =>
                                this.handleChangeCheckboxForFlover(flover)
                              }
                              handleChangeCheckboxForKosher={(kosher) =>
                                this.handleChangeCheckboxForKosher(
                                  kosher.trim()
                                )
                              }
                              handleChangeCheckbox={(brand_id, brand_name) =>
                                this.handleChangeCheckbox(brand_id, brand_name)
                              }
                              FetchDataForPriceRange={(
                                price_min,
                                price_max,
                                category_id,
                                subcategory_id,
                                has_child,
                                selectedCategory
                              ) =>
                                this.FetchDataForPriceRange(
                                  price_min,
                                  price_max,
                                  category_id,
                                  subcategory_id,
                                  has_child,
                                  selectedCategory
                                )
                              }
                              categoryID={categoryIds}
                              parentID={parentIds}
                              Props_value={this.props ? this.props : ""}
                              List_PropsValue={this.state.Props_value}
                              {...this.state.parent_onlyid}
                              parent_onlyid={
                                this.props &&
                                this.props.Props_value &&
                                this.props.Props_value.location.state
                                  ? this.props.Props_value.location.state
                                      .parent_onlyid
                                  : []
                              }
                              TreeViewIteams={
                                this.props.history.location.pathname
                                  .split("/")[2]
                                  .includes("SPECIALS")
                                  ? this.state.TreeViewIteamsSpecial
                                  : this.state.TreeViewIteams
                              }
                              max_price={this.state.max_price}
                              history={this.props.history}
                              toggleMenuCat={() => this.toggleMenuCategory()}
                              openAllcategory={() =>
                                this.content.openViewfullcategory()
                              }
                              viewGroupcategory={this.state.viewGroupcategory}
                              parentdata={this.state.parentdata}
                              ref={this.child}
                              bindCategorygroupdata={(treeData = []) =>
                                this.bindCategorygroupdata(treeData)
                              }
                              redirectingListingPage={(category_id) =>
                                this.redirectingListingPage(category_id)
                              }
                            />
                          )
                      }
                    </ScrollColorTypography>
                  </div>
                ) : (
                  <></>
                )}
                <ScrollColorTypography
                  component={"div"}
                  className={
                    this.state.bodyInnerClass === true
                      ? "list_main_content show_sidecart"
                      : "list_main_content"
                  }
                  // style={{maxHeight:'90vh',overflow:'auto'}}
                >
                  {this.state.toggleMenuCategory && (
                    <div className="toggleMenu" id="no-print">
                      <div className="leftmenu-main hide">
                        <div
                          className={`leftmenu ${
                            this.state.toggleMenuCategory
                              ? "openmenu overflow-auto"
                              : ""
                          } `}
                        >
                          {CategoryDetails &&
                          CategoryDetails.length > 0 &&
                          TreeViewIteams.length > 0 ? (
                            <CategoryListing
                              pageName={this.state.pageName}
                              CategoryDetails={this.state.TreeViewIteams}
                              BrandProduct={BrandProduct}
                              fruitType={fruitType}
                              flavor={flavor}
                              kosher={kosher}
                              handleChangeCheckboxForProductType={(
                                product_type
                              ) =>
                                this.handleChangeCheckboxForProductType(
                                  product_type
                                )
                              }
                              handleChangeCheckboxForFlover={(flover) =>
                                this.handleChangeCheckboxForFlover(flover)
                              }
                              handleChangeCheckboxForKosher={(kosher) =>
                                this.handleChangeCheckboxForKosher(
                                  kosher.trim()
                                )
                              }
                              handleChangeCheckbox={(brand_id, brand_name) =>
                                this.handleChangeCheckbox(brand_id, brand_name)
                              }
                              FetchDataForPriceRange={(
                                price_min,
                                price_max,
                                category_id,
                                subcategory_id,
                                has_child,
                                selectedCategory
                              ) =>
                                this.FetchDataForPriceRange(
                                  price_min,
                                  price_max,
                                  category_id,
                                  subcategory_id,
                                  has_child,
                                  selectedCategory
                                )
                              }
                              categoryID={categoryIds}
                              parentID={parentIds}
                              Props_value={this.props ? this.props : ""}
                              List_PropsValue={this.state.Props_value}
                              {...this.state.parent_onlyid}
                              parent_onlyid={
                                this.props &&
                                this.props.Props_value &&
                                this.props.Props_value.location.state
                                  ? this.props.Props_value.location.state
                                      .parent_onlyid
                                  : []
                              }
                              TreeViewIteams={
                                this.props.history.location.pathname
                                  .split("/")[2]
                                  .includes("SPECIALS")
                                  ? this.state.TreeViewIteamsSpecial
                                  : this.state.TreeViewIteams
                              }
                              max_price={this.state.max_price}
                              history={this.props.history}
                              toggleMenuCat={() => this.toggleMenuCategory()}
                              bindCategorygroupdata={(tree_data = []) =>
                                this.bindCategorygroupdata(tree_data)
                              }
                              redirectingListingPage={(category_id) =>
                                this.redirectingListingPage(category_id)
                              }
                            />
                          ) : (
                            <div
                              className="main_containerLoader"
                              style={{
                                display: this.state.CategoryListLoader
                                  ? "flex"
                                  : "none",
                              }}
                            >
                              <CustomPreloader />
                            </div>
                          )}
                        </div>
                        <div
                          className="leftmenu_bgblack"
                          onClick={() => this.toggleMenuCategory()}
                        ></div>
                      </div>
                    </div>
                  )}

                  {categoryData.length === 0 && window.innerWidth <= 900 ? (
                    <></>
                  ) : (
                    <div
                      id="filterData"
                      className="filter-container"
                      style={{
                        top:
                          web_customize !== null &&
                          web_customize?.top_marquee !== null &&
                          web_customize?.top_marquee !== "" &&
                          window.screen.availWidth > 900
                            ? "10.6rem"
                            : window.screen.availWidth > 900
                            ? "7.8rem"
                            : "",
                      }}
                    >
                      <div className="listing_details">
                        {this.props.history.location.pathname
                          .split("/")[2]
                          .includes("SPECIALS") === false &&
                        categoryData.length > 0 ? (
                          <div className="specified_details">
                            {category_id > 0 ? (
                              <ul>
                                <TypographyTextColor
                                  component={"li"}
                                  Color="other"
                                  onClick={() => this.handleOpenDialog()}
                                >
                                  All
                                </TypographyTextColor>
                                {categoryData && categoryData.length > 0
                                  ? categoryData.map((row, index) => {
                                      return index ===
                                        categoryData.length - 1 ? (
                                        <TypographyTextColor
                                          component={"li"}
                                          Color="heading"
                                        >
                                          {row ? row.category_name : ""}
                                        </TypographyTextColor>
                                      ) : (
                                        <TypographyTextColor
                                          component={"li"}
                                          Color="other"
                                          onClick={
                                            row.cslug
                                              ? () =>
                                                  this.handleInnerOpenDialog(
                                                    row.category_id,
                                                    row.parent_id,
                                                    row.category_name,
                                                    true,
                                                    row.level,
                                                    row.cslug,
                                                    categoryData
                                                  )
                                              : ""
                                          }
                                        >
                                          {row ? row.category_name : ""}
                                        </TypographyTextColor>
                                      );
                                    })
                                  : ""}
                              </ul>
                            ) : (
                              ""
                            )}
                            <span className="filter-count"></span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="filter_listing">
                          <CustomChips
                            filterByType={filterByType}
                            handleChangeCheckboxForProductType={(row) =>
                              this.handleChangeCheckboxForProductType(row)
                            }
                            max_price={this.state.max_price}
                            min_price={this.state.min_price}
                            closePriceRange={this.closePriceRange}
                            brandName={brandName}
                            handleChangeCheckbox={(brand_id, brand_name) =>
                              this.handleChangeCheckbox(brand_id, brand_name)
                            }
                            filterByFlavor={this.state.filterByFlavor}
                            handleChangeCheckboxForFlover={(row) =>
                              this.handleChangeCheckboxForFlover(row)
                            }
                            filterByKosher={this.state.filterByKosher}
                            handleChangeCheckboxForKosher={(row) =>
                              this.handleChangeCheckboxForKosher(row)
                            }
                            customChipsData={this.state.customChipsData}
                            closecustomChips={() => this.closecustomChips()}
                          />
                        </div>
                        {document.getElementById("allproduct")?.offsetWidth <=
                          2500 &&
                        document.getElementById("allproduct")?.offsetWidth >=
                          900 ? (
                          <div
                            style={{ marginLeft: "auto", marginRight: "10px" }}
                          >
                            <ToggleButtonGroup
                              value={this.state.cardSize}
                              exclusive
                              onChange={this.handleAlignment}
                              aria-label="text alignment"
                              size="small"
                            >
                              <ToggleButton
                                value="small"
                                className="p-0"
                                style={{ fontSize: "2rem" }}
                                aria-label="left aligned"
                              >
                                <BiGridSmall />
                              </ToggleButton>
                              <ToggleButton
                                value="big"
                                className="p-0"
                                style={{ fontSize: "2rem" }}
                                aria-label="left aligned"
                              >
                                <BiGridAlt />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mob_tablising my-md-2">
                    {this.state.itemDetailsmobModal && (
                      <DetailpageMob
                        ProductDetails={this.state.ProductDetails}
                        prev_rec={this.state.is_prev_rec}
                        next_rec={this.state.is_next_rec}
                        container={this.state.container}
                        extraCharges={this.state.extraCharges}
                        itemDetailsmobModal={this.state.itemDetailsmobModal}
                        closeItemdetailMobile={() =>
                          this.closeItemdetailMobile()
                        }
                        pathname={this.props.location.pathname}
                        history={this.props.history}
                        child_items={this.state.child_items}
                        selectedChilditem={this.state.selectedChilditem}
                        chack_is_case={this.state.check_isCase}
                        chack_is_casefrequently={this.state.chack_is_case}
                        pro_item_details_ind={this.state.pro_item_details_ind}
                        containerLength={this.state.containerLength}
                        container_type={this.state.container_type}
                        AddtoCartBtn={this.state.AddtoCartBtn}
                        inputField={this.state.inputField}
                        productDetailsLoader={this.state.productDetailsLoader}
                        preProductItem={(pre_info, next_info) =>
                          this.preProductItem(pre_info, next_info)
                        }
                        nextProductItem={(pre_info, next_info) =>
                          this.nextProductItem(pre_info, next_info)
                        }
                        containerValue={this.state.containerValue}
                        createSuccessMsg={(type, msg) =>
                          this.createSuccessMsg(type, msg)
                        }
                        OpenLoginPoup={() => this.OpenLoginPoup()}
                        openisslotDeliverypopupState_compwill={() =>
                          this.props.openisslotDeliverypopupState_compwill()
                        }
                        FetchCartCount={() => this.FetchCartItemList()}
                        commonFunctionForContainer={(
                          added_qtyInd,
                          added_quantity,
                          added_quantity_is_case
                        ) =>
                          this.commonFunctionForContainer(
                            added_qtyInd,
                            added_quantity,
                            added_quantity_is_case
                          )
                        }
                        openAddCount={(
                          id,
                          added_quantity,
                          added_quantity_is_case,
                          categoryindex,
                          containertype
                        ) =>
                          this.openAddCount(
                            id,
                            added_quantity,
                            added_quantity_is_case,
                            categoryindex,
                            containertype
                          )
                        }
                        categoryIndx={this.state.selectedCategoryindex}
                        hendleAddtoCartBtn={() =>
                          this.hendleAddtoCartBtnForListing()
                        }
                        ProductCaseFunctionlityForItem={(added_qty_is_case) =>
                          this.ProductCaseFunctionlityForItem(added_qty_is_case)
                        }
                        PopularProductList={this.state.PopularProductList}
                        commonFunctionLity={(
                          product_id,
                          added_quantity,
                          is_case
                        ) =>
                          this.commonFunctionLity(
                            product_id,
                            added_quantity,
                            is_case
                          )
                        }
                        UpdateCartItemPopularproduct={(
                          product_id,
                          quantity,
                          is_case,
                          note,
                          container_type
                        ) =>
                          this.UpdateCartItemPopularproduct(
                            product_id,
                            quantity,
                            is_case,
                            note,
                            container_type
                          )
                        }
                        handlepopularOpen={(id, slug, next, prev, index) =>
                          this.handlepopularOpen(id, slug, next, prev, index)
                        }
                        restrictByOnhand={this.state.restrict_by_onhand}
                        changeChilditemdata={(event, type, myContainer) =>
                          this.changeChilditemdata(event, type, myContainer)
                        }
                        ProductCaseFunctionlity={(id, event) =>
                          this.ProductCaseFunctionlity(id, event)
                        }
                        ProductCaseFunctionlityFrequtenltyItem={(id, event) =>
                          this.ProductCaseFunctionlityFrequtenltyItem(id, event)
                        }
                      />
                    )}
                  </div>
                  <div class="listing-wrapper pb-3" id="allproduct">
                    {this.props.history.location.state?.categoryviewdata
                      ?.category_banner ? (
                      <div
                        style={{
                          backgroundImage: this.props.history.location.state
                            ?.categoryviewdata?.category_banner
                            ? 'url("' +
                              (this.props.history.location.state
                                ?.categoryviewdata?.category_banner===null||this.props.history.location.state
                                ?.categoryviewdata?.category_banner===""?
                                this.props.history.location.state
                                ?.categoryviewdata?.cloudinary_cat_banner_images_global:
                                this.props.history.location.state
                                ?.categoryviewdata?.category_banner[0] === "/"
                                ? ApiUrls.BASH_URL +
                                  "/media" +
                                  this.props.history.location.state
                                    ?.categoryviewdata?.category_banner
                                : this.props.history.location.state
                                    ?.categoryviewdata?.category_banner) +
                              '")'
                            : "",
                        }}
                        className="category-banner"
                      ></div>
                    ) : (
                      <></>
                    )}
                    {ProductList && ProductList.length > 0 ? (
                      ProductList.map((x, i) => {
                        return (
                          <div data-segment-category-id={x.parentName.id}>
                            {x.parentName.category_message !== undefined &&
                            x.parentName?.category_message !== "" &&
                            x.parentName?.category_message !== null ? (
                              <div
                                // severity="error"
                                className="mx-3 mt-3 d-flex align-items-center p-2"
                                style={{
                                  color:
                                    x.parentName?.category_message_color ===
                                      "" ||
                                    x.parentName?.category_message_color ===
                                      null
                                      ? "#f44336"
                                      : x.parentName?.category_message_color,
                                  background:
                                    x.parentName?.category_message_color ===
                                      "" ||
                                    x.parentName?.category_message_color ===
                                      null
                                      ? lightenColor("#f44336", 170)
                                      : lightenColor(
                                          x.parentName?.category_message_color,
                                          170
                                        ),
                                }}
                              >
                                <GoAlert className="mr-2 mb-1" />{" "}
                                {x.parentName?.category_message}
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className="listing-group-categoryname-section justify-content-between "
                              data-segment={x.parentName.id}
                            >
                              {" "}
                              {this.props.match.params.id.includes("brand=") ===
                              false ? (
                                <span className="d-flex align-items-center">
                                  <TypographyTextColor
                                    component={"span"}
                                    Color="category"
                                    className="text-size-30"
                                  >
                                    {x.parentName.name?.replace("q=", "")}
                                  </TypographyTextColor>
                                  <TypographyFontColor
                                    border={0}
                                    Fonttype="other"
                                    className="item-count"
                                  >
                                    {x.parentName.cat_count} Product
                                    {x.parentName.cat_count > 1 ? "s" : ""}
                                  </TypographyFontColor>
                                </span>
                              ) : (
                                <></>
                              )}
                              {window.innerWidth <= 900 && i === 0 ? (
                                <SwipeableEdgeDrawer
                                  BrandProduct={BrandProduct}
                                  flavor={flavor}
                                  kosher={kosher}
                                  handleChangeCheckbox={(
                                    brand_id,
                                    brand__name
                                  ) =>
                                    this.handleChangeCheckbox(
                                      brand_id,
                                      brand__name
                                    )
                                  }
                                  handleChangeCheckboxForFlover={(flavor) =>
                                    this.handleChangeCheckboxForFlover(flavor)
                                  }
                                  handleChangeCheckboxForKosher={(kosher) =>
                                    this.handleChangeCheckboxForKosher(kosher)
                                  }
                                  sortingData={this.state.sortingData}
                                  selectedSortedValue={this.state.order}
                                  handleChangeSorting={(value) =>
                                    this.handleChangeSorting(value)
                                  }
                                  open={this.state.isSwipeableOpen}
                                  handleSwipeable={(value) =>
                                    this.handleSwipeable(value)
                                  }
                                  expanded={this.state.expandedSwipeable}
                                  setExpanded={(value) =>
                                    this.handlesetExpanded(value)
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="items-details item_listing_details">
                              {x.productList.map((row, index) => {
                                return (
                                  <>
                                    {this.renderItemcard(
                                      row,
                                      index,
                                      i,
                                      x.parentName
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <TypographyFontColor
                        border={0}
                        className="search-empty-data"
                      >
                        {this.state.searchResultMsg}
                      </TypographyFontColor>
                    )}
                    <div className="items-details item_listing_details">
                      <div style={loadingTextCSS}>
                        {window.screen.availWidth <= 900 ? (
                          <div className="d-flex justify-content-center w-100">
                            <CircularProgress />
                          </div>
                        ) : (
                          [...Array(window.innerWidth <= 760 ? 2 : 16)].map(
                            (x, i) => {
                              return (
                                <Zoom
                                  className="items-container"
                                  style={{
                                    transitionDelay: `${100 * (i + 1)}ms`,
                                    flexBasis:
                                      this.state.cardWidth !== ""
                                        ? this.state.cardWidth
                                        : "",
                                    maxWidth:
                                      this.state.cardWidth !== ""
                                        ? this.state.cardWidth
                                        : "",
                                  }}
                                  in={true}
                                >
                                  <div className="items">
                                    <ItemLoaderCard />
                                  </div>
                                </Zoom>
                              );
                            }
                          )
                        )}
                        {/* <div class="Spinner-loader"></div> */}
                      </div>
                    </div>
                    {ProductDetails && (
                      <ItemDetailsModal
                        open_inner={this.state.open_inner}
                        open={this.state.itemDetails}
                        handlePopClose={(
                          product_id,
                          added_quantity,
                          added_quantity_is_case,
                          is_case
                        ) =>
                          this.handlePopClose(
                            product_id,
                            added_quantity,
                            added_quantity_is_case,
                            is_case
                          )
                        }
                        handleOpenDialog={this.handleOpenDialog}
                        ProductDetails={ProductDetails}
                        categoryNames={categoryNames}
                        pageName={this.state.pageName}
                        prev_rec={this.state.is_prev_rec}
                        next_rec={this.state.is_next_rec}
                        preProductItem={(pre_info, next_info) =>
                          this.preProductItem(pre_info, next_info)
                        }
                        nextProductItem={(pre_info, next_info) =>
                          this.nextProductItem(pre_info, next_info)
                        }
                        productDetailsLoader={this.state.productDetailsLoader}
                        history={this.props}
                        addQuentityForaddTocart={(
                          targetValue,
                          added_qty_is_case,
                          container_type
                        ) =>
                          this.addQuentityForaddTocart(
                            targetValue,
                            added_qty_is_case,
                            container_type
                          )
                        }
                        special_filter={this.state.special_filter}
                        special_filter_value={this.state.special_filter_value}
                        parentIds={this.state.parentIds}
                        parent_onlyid={this.state.parent_onlyid}
                        parent_level={this.state.parent_level}
                        totalProduct={this.state.total}
                        redirectingListingPage={(category_id) =>
                          this.redirectingListingPage(category_id)
                        }
                        handleCloseDialog={this.handleCloseDialog}
                        ProductCaseFunctionlityForItem={(added_qty_is_case) =>
                          this.ProductCaseFunctionlityForItem(added_qty_is_case)
                        }
                        chack_is_case={this.state.check_isCase}
                        container={this.state.container}
                        containerLength={this.state.containerLength}
                        containerValue={this.state.containerValue}
                        pro_item_details_ind={this.state.pro_item_details_ind}
                        container_type={this.state.container_type}
                        changeConatinerType={(event, type, myContainer) =>
                          this.changeConatinerType(event, type, myContainer)
                        }
                        AddtoCartBtn={this.state.AddtoCartBtn}
                        changeInputBoxValue={() => this.changeInputBoxValue()}
                        inputField={this.state.inputField}
                        commonFunctionForContainer={(
                          added_qtyInd,
                          added_quantity,
                          added_quantity_is_case
                        ) =>
                          this.commonFunctionForContainer(
                            added_qtyInd,
                            added_quantity,
                            added_quantity_is_case
                          )
                        }
                        RefreshApiToken={(refreshToken, myThis, callback) =>
                          this.RefreshApiToken(refreshToken, myThis, callback)
                        }
                        activePageName={this.props.location.pathname}
                        hendleAddtoCartBtn={() =>
                          this.hendleAddtoCartBtnForListing()
                        }
                        CategoryDetails={CategoryDetails}
                        FetchCartCount={() => this.FetchCartItemList()}
                        category_id={this.state.category_id}
                        extraCharges={this.state.extraCharges}
                        openisslotDeliverypopupState_compwill={() =>
                          this.openisslotDeliverypopupState_compwill()
                        }
                        child_items={this.state.child_items}
                        changeChilditemdata={(event, type, myContainer) =>
                          this.changeChilditemdata(event, type, myContainer)
                        }
                        selectedChilditem={this.state.selectedChilditem}
                        Parent_item={this.state.Parent_item}
                        listingFormValues={this.state.listingFormValues}
                        ProductListData={this.state.ProductListData}
                        selectedProductindex={this.state.selectedProductindex}
                        tree_data={this.state.tree_data}
                      />
                    )}
                    {/* </Grid> */}
                  </div>

                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={{
                      display: "block",
                      minHeight: "50px",
                    }}
                    className="mt-2"
                  >
                    {ProductList.length ===
                      this.state.viewGroupcategory.length &&
                    isNaN(
                      this.props.history.location.pathname.split("/")[2]
                    ) === false &&
                    ProductList.at(-1)?.productList?.length ===
                      this.state.total ? (
                      <Footer emailSubscribe={this.state.emailSubscribe} />
                    ) : (
                      <></>
                    )}
                  </div>
                </ScrollColorTypography>
              </section>
            </ScrollColorTypography>
          )}

          {error ? (
            <Snackbar
              open={this.state.error}
              onClose={this.handleClose}
              autoHideDuration={1000}
              className="error"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <i className="icon-close"></i>
                  </IconButton>
                </React.Fragment>
              }
              message={
                <React.Fragment>
                  <div className="row gutter-8">
                    <div className="col-auto">
                      <i className="icon-closer"></i>
                    </div>
                    <div className="col">
                      <p>{error}</p>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default Listing;
