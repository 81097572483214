import React,{useEffect} from "react";
import ApiUrls from "../../../utils/apiUtils/apiUrl";
import axios from "axios";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
  } from "@mui/material";
const PdfViewHeader = (props) => {

  return (<Dialog
    open={props.setOpenPdfpoup}
    // onClose={() => props.handleClickawayDeliveryPopup()}
    aria-labelledby="form-dialog-title"
    maxWidth="md"
    fullWidth={true}
    className="popUp"
  ><DialogTitle id="scroll-dialog-title">
  <div style={{ display: "flex" }}>
    <div className="delivery-box-title">
    {JSON.parse(localStorage.getItem("pdf_data_view"))?.name}
    </div>
      <div className="header px-2 py-2">
        <span className="float-right">
          <a
            href="javascript:void(0);"
            onClick={() => props.CloseDialogPdfPopup()}
          >
            <span class="icon-close"></span>
          </a>
        </span>
      </div>
      </div>
      </DialogTitle>
    <DialogContent>
    <div>
      {" "}
        <object
        width="100%"
        height="500"
        data={
          ApiUrls.BASH_URL + "/media" + JSON.parse(localStorage.getItem("pdf_data_view"))?.link
        }
        type="application/pdf"
      >
        {" "}
      </object>
    </div>
    </DialogContent>
    </Dialog>
  );
};

export default PdfViewHeader;
