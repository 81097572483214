import React, { useEffect, useState } from "react";
import ApiUrls from "../../utils/apiUtils/apiUrl";

const CommonCategoryBgImg = ({categoryviewdata,imageError,setImageError}) => {
 console.log(categoryviewdata,imageError);
  if (imageError) {
    return null; // If there's an error, return null to remove the image element
  }
  return (
    <img
      className="menubg"
      src={
        categoryviewdata?.background_image === "" ||
        categoryviewdata?.background_image === null
          ? categoryviewdata?.cloudinary_catbackground_images_global
          : categoryviewdata?.background_image[0] === "/"
          ? ApiUrls.BASH_URL + "/media" + categoryviewdata?.background_image
          : categoryviewdata?.background_image
      }
      onError={() => setImageError(true)}
      alt="bg"
    />
  );
};

export default CommonCategoryBgImg;
