import { SubmissionError } from "redux-form";
import { UpdateProfileDetails } from "../actions/profile/profileAction";

const profileSubmit = (e, values, dispatch, props) => {
  const formData = new FormData();
  let mobile_number = 0;
  let alternate_phone = 0;
  let hidden_mobile_number = 0;
  if (typeof values.mobile_number === "number") {
    mobile_number = values.mobile_number;
  } else {
    mobile_number = values.mobile_number.replace(/-/g, "");
    // mobile_number = mobile_number.replace(/[\])}[{(]/g, "");
    // mobile_number = mobile_number.slice(mobile_number.indexOf(" "))
    // mobile_number = mobile_number.replace(/ +/g, "");
  }

  if (typeof values.hidden_mobile_number === "number") {
    hidden_mobile_number = values.hidden_mobile_number;
  } else {
    hidden_mobile_number = values.hidden_mobile_number.replace(/-/g, "");
    // hidden_mobile_number = hidden_mobile_number.replace(/[\])}[{(]/g, "");
    // hidden_mobile_number = hidden_mobile_number.slice(hidden_mobile_number.indexOf(" "))
    // hidden_mobile_number = hidden_mobile_number.replace(/ +/g, "");
  }

  if (
    values.profile_alternate_mobile &&
    typeof values.profile_alternate_mobile === "number"
  ) {
    alternate_phone = values.profile_alternate_mobile;
  } else {
    if (values.profile_alternate_mobile) {
      alternate_phone = values.profile_alternate_mobile.replace(/-/g, "");
      // alternate_phone = alternate_phone.replace(/[\])}[{(]/g, "");
      // alternate_phone = alternate_phone.slice(alternate_phone.indexOf(" "))
      // alternate_phone = alternate_phone.replace(/ +/g, "");
    } else {
      alternate_phone = 0;
    }
  }

  formData.append(
    "first_name",
    values.profile_first_name ? values.profile_first_name : ""
  );
  formData.append(
    "last_name",
    values.profile_last_name ? values.profile_last_name : ""
  );
  formData.append("email", values.email_address ? values.email_address : "");
  formData.append("address", values.address ? values.address : "");
  formData.append(
    "street_name",
    values.profile_street_name ? values.profile_street_name : ""
  );
  formData.append(
    "city_name",
    values.profile_city_name ? values.profile_city_name : ""
  );
  formData.append(
    "state_name",
    values.profile_state_name ? values.profile_state_name.value : ""
  );
  // formData.append("city", values.hidden_city_id ? values.hidden_city_id: '');
  // formData.append("state", values.profile_state_id ? values.profile_state_id.value: '');
  formData.append(
    "zipcode",
    values.profile_zipcode ? values.profile_zipcode : ""
  );
  formData.append("phone", mobile_number);
  formData.append("callingcode", 1);
  formData.append("alternate_phone", alternate_phone);
  formData.append(
    "delivery_remark",
    values.profile_delivery_remark ? values.profile_delivery_remark : ""
  );
  formData.append("current_password", values.password ? values.password : "");
  formData.append(
    "new_password",
    values.new_password ? values.new_password : ""
  );
  formData.append(
    "new_password_confirm",
    values.confirm_new_password ? values.confirm_new_password : ""
  );
  console.log(hidden_mobile_number);
  console.log(mobile_number);
  if (values.profile_zipcode.replace(/_/g, "").length === 5) {
    if (values.confirm_new_password !== "" && values.new_password !== "") {
      if (
        values.confirm_new_password !== undefined &&
        values.new_password !== undefined &&
        (values.password === "" || values.password === undefined)
      ) {
        e.createSuccessMsg("error", "Please enter the password");
      } else if (values.confirm_new_password !== values.new_password) {
        e.createSuccessMsg(
          "error",
          "Pasword and confirm password should be same"
        );
      } else {
        if (parseInt(hidden_mobile_number) === parseInt(mobile_number)) {
          return UpdateProfileDetails(formData, dispatch)
            .then((res) => {
              if (res.errors && res.errors._error.status === 400) {
                if (res.errors._error.data) {
                  e.createSuccessMsg("error", res.errors._error.data.errors);
                } else {
                  throw new SubmissionError(res.errors._error.data.errors);
                }
              } else if (res.errors && res.errors._error.status === 401) {
                window.location.reload();
              } else {
                localStorage.setItem(
                  "email",
                  JSON.stringify({ email: values.email_address })
                );
                e.createSuccessMsg("success", res.data.message);
                // localStorage.setItem('userdata', JSON.stringify(sendValues.username));
                // e.redirectToSuccessPage(values.mobile_no);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          e.handleOpenOtpDialog(mobile_number);
        }
      }
    }
  } else {
    e.createSuccessMsg("error", "Please enter valid zipcode");
  }
};

export default profileSubmit;
