import React, { Component } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoblieViewCategoryListing from "../MoblieViewCategoryListing";
import {
  TypographyBgColor,
  TypographyFontColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../UiDesign";
import FilterLIstSideBar from "./FilterLIstSideBar";

import { GoDotFill } from "react-icons/go";
import SideCateogorySkeleton from "../Skeleton/SideCateogorySkeleton";

const closeStyle = {
  fontSize: "11px",
  fontWeight: 700,
  padding: "3px 6px",
  marginLeft: "5px",
  boxShadow: "0px 0px 7px 2px rgba(0,0,0,0.1)",
  color: "#fff",
  height: "25px",
};
class CategoryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
      subMenuOpen: false,
      SubCategoryDetails: [],
      CategoryList: "",
      expanded: [],
      treeIcon: <ChevronRightIcon />,
      selected: [],
      loading: false,
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
      category_id: "",
      sub_category_id: "",
      node_id: "",
      parent_id: "",
      nodeLoading: false,
      cat_ind_list: [],
      CategoryDetails: [],
      Props_value: this.props.Props_value,
      treeViewdata: this.props.TreeViewIteams,
      parent_onlyid:
        this.props.Props_value &&
        this.props.Props_value.location &&
        this.props.Props_value.location.state &&
        this.props.Props_value.location.state.parent_onlyid
          ? this.props.Props_value.location.state.parent_onlyid
          : [],
      setchilditem: false,
      currentCateogory: [],
      viewGroupcategory: "",
      selectedCategory: "",
    };
  }

  componentDidMount() {
    let expanded = [];
    let selected = [];
    let category_id = "";
    let urlcategoryid = "";

    if (
      this.state.Props_value &&
      this.state.Props_value.location &&
      this.state.Props_value.location.state &&
      this.state.Props_value.location.state.parent_onlyid
    ) {
      selected = this.state.Props_value.location.state.parent_onlyid;
      expanded = this.state.Props_value.location.state.parent_onlyid;
      category_id = this.state.Props_value.location.state.category_id;
    } else if (this.state.parent_onlyid) {
      selected = this.state.parent_onlyid;
      expanded = this.state.parent_onlyid;
    }

    if (this.props.history.location.state?.selectedgroupcat !== undefined) {
      let selectedD = this.props.history.location.state?.selectedgroupcat;
      this.setState({
        viewGroupcategory: {
          category: selectedD.category,
          parent:
            selectedD.parentdata === undefined
              ? selectedD.parent
              : selectedD.parentdata,
        },
      });
    } else if (this.props?.viewGroupcategory?.length > 0) {
      this.setState({
        viewGroupcategory: {
          category: this.props.viewGroupcategory,
          parent: this.props.parentdata,
        },
      });
    } else {
      var tree_data = this.onbuildForTreeData(this.state.treeViewdata, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });
      this.bindCategorygroupdata(tree_data);
    }
    if (window.screen.availWidth < 900) {
      var tree_data = this.onbuildForTreeData(this.state.treeViewdata, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });
      this.bindCategorygroupdata(tree_data);
    }
    this.setState({
      CategoryDetails: tree_data,
      expanded: expanded,
      selected: selected,
      category_id: category_id,
    });
  }

  componentWillReceiveProps(nextProps) {
    let expanded = [];
    let selected = [];
    let category_id = "";

    if (this.props.Props_value !== nextProps.Props_value) {
      if (
        nextProps.Props_value &&
        nextProps.Props_value.location &&
        nextProps.Props_value.location.state &&
        nextProps.Props_value.location.state.parent_onlyid
      ) {
        selected = nextProps.Props_value.location.state.parent_onlyid;
        expanded = nextProps.Props_value.location.state.parent_onlyid;
        category_id = nextProps.Props_value.location.state.category_id;
      }

      var tree_data = this.onbuildForTreeData(this.state.treeViewdata, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });
      this.setState({
        // CategoryDetails: tree_data,
        expanded: expanded,
        selected: selected,
        // category_id: category_id,
      });
    }

    if (this.props.TreeViewIteams !== nextProps.TreeViewIteams) {
      var tree_data = this.onbuildForTreeData(nextProps.TreeViewIteams, null);
      tree_data.sort(function (a, b) {
        return a.cat_order - b.cat_order;
      });
      // this.bindCategorygroupdata();
      this.setState({
        // CategoryDetails: tree_data,
        expanded: expanded,
        selected: selected,
        // category_id: category_id,
      });
    }
  }

  fetchsubcat = async (parentOnlyId, parentIds, parentLevel) => {
    for (let i = 0; i < parentOnlyId.length; i++) {
      await this.FetchSubCategoryList(
        parentOnlyId[i],
        parentIds[i],
        true,
        1,
        parentLevel[i]
      );
    }
  };

  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }

  FetchProduct = (category_id, has_child, data) => {
    let selectedgroupcat = [];
    let categorydata = structuredClone(data);
    if (categorydata !== undefined) {
      if (categorydata.childItem === undefined) {
        selectedgroupcat = { category: [categorydata], parent: categorydata };
      } else if (categorydata.id === +category_id) {
        selectedgroupcat = { category: [categorydata], parent: categorydata };
      } else {
        let newdata = categorydata.childItem;
        let parentdata;
        parentdata = categorydata;
        // newdata.push(parentdata);
        let urlcategoryid = category_id;
        for (let i = 0; i < newdata.length; i++) {
          if (newdata[i].id === +urlcategoryid) {
            let data = newdata[i];
            newdata.splice(i, 1);
            newdata.splice(0, 0, data);
            break;
          }
        }
        selectedgroupcat = { category: newdata, parent: parentdata };
      }
    }
    this.props.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: "",
        parentIds: "",
        parent_level: "",
        categoryviewdata: data,
        selectedgroupcat: selectedgroupcat,
      },
    });
    if (
      this.props.pageName === "details_page" ||
      this.props.pageName === "home_page"
    ) {
    } else {
      this.props.FetchDataForPriceRange("", "", category_id, "", false, false);
      this.props.bindCategorygroupdata();
    }
    this.props.toggleMenuCat();
  };

  handleClearselectionitem = () => {
    let data = this.state.viewGroupcategory;
    if (data !== "" && data?.category.length > 0) {
      data.category.map((ele) => {
        ele.iscloseshow = false;
        if (ele.has_child === true)
          ele.childItem.map((val) => {
            val.iscloseshow = false;
          });
      });
      this.setState({ viewGroupcategory: data });
    }
  };
  redirecttoCategory = (category_id, indx, type, catData = "") => {
    let selectedcat = true;
    let data = this.state.viewGroupcategory;
    if (type === "select") {
      document
        .querySelectorAll("[data-target-segment]")
        .forEach(function (element) {
          element.classList.remove("activeItem");
        });
      data.category.map((ele) => {
        if (ele.id === category_id) ele.iscloseshow = true;
        else ele.iscloseshow = false;
        if (ele.has_child === true) {
          ele.childItem.map((val) => {
            if (val.id === category_id) val.iscloseshow = true;
            else val.iscloseshow = false;
          });
        }
      });
      selectedcat = true;
    } else {
      if (data?.category[0]?.has_child === true)
        category_id = data?.category[0]?.childItem[0].id;
      else category_id = data?.category[0]?.id;
      selectedcat = false;
      this.handleClearselectionitem();
    }

    this.setState({ selectedCategory: selectedcat, viewGroupcategory: data });
    if (
      this.props.history.location.pathname.split("/")[2].includes("SPECIALS")
    ) {
      this.props.history.push({
        state: {
          category_id: category_id,
          parent_id: "",
          parentIds: "",
          parent_level: "",
          CategoryDetails: this.props.CategoryDetails,
          slotName: this.props.slotName,
          selectedCategory: selectedcat,
        },
      });
    } else
      this.props.history.push({
        pathname: "/listing/" + category_id,
        state: {
          category_id: category_id,
          parent_id: "",
          parentIds: "",
          parent_level: "",
          CategoryDetails: this.props.CategoryDetails,
          slotName: this.props.slotName,
          selectedCategory: selectedcat,
          customCategoryMsg: catData?.category_message,
        },
      });
    this.props.FetchDataForPriceRange("", "", category_id, "", false);
  };
  handleToggle = (event, nodeIds) => {
    let expanded = this.state.expanded;
    let FilterNodeIds = nodeIds.filter((val) => !expanded.includes(val));
    this.setState({
      expanded: FilterNodeIds,
      selected: FilterNodeIds,
      parent_onlyid: FilterNodeIds,
    });
  };

  handleSelect = (event, nodeIds) => {
    let selected = [];
    if (
      this.props.pageName === "details_page" ||
      this.props.pageName === "home_page"
    ) {
      
      if (
        this.state.Props_value &&
        this.state.Props_value.location &&
        this.state.Props_value.location.state &&
        this.state.Props_value.location.state.parent_onlyid
      ) {
        selected = this.state.Props_value.location.state.parent_onlyid;
      }
    } else selected = this.state.selected;
    this.setState({
      selected: nodeIds,
      expanded: [],
    });
  };

  handleChangeLessthanTwenty = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: true,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
    });

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };

  handleChangeTwetyToFifty = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: true,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
    });
    if (this.state.checkedTwentyOneToFifty === true) {
      price_min = 0;
      price_max = 0;
    }

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };

  handleChangeFiftyToHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: true,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
    });

    if (this.state.checkedFiftyToHundread === true) {
      price_min = 0;
      price_max = 0;
    }

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };

  handleChangeHundreadToTwoHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: true,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: false,
    });

    if (this.state.checkedHundreadToTwohundread === true) {
      price_min = 0;
      price_max = 0;
    }

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };

  handleChangeTwoHundreadToFiveHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: true,
      checkedGraterThanFifty: false,
    });

    if (this.state.checkedTwohundreadToFivehundread === true) {
      price_min = 0;
      price_max = 0;
    }

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };

  handleChangeGreaterThanFiveHundread = (price_min, price_max) => {
    this.setState({
      checkedLessThanTwenty: false,
      checkedTwentyOneToFifty: false,
      checkedFiftyToHundread: false,
      checkedHundreadToTwohundread: false,
      checkedTwohundreadToFivehundread: false,
      checkedGraterThanFifty: true,
    });

    if (this.state.checkedGraterThanFifty === true) {
      price_min = 0;
      price_max = 0;
    }

    this.props.FetchDataForPriceRange(
      price_min,
      price_max,
      this.state.category_id,
      false
    );
  };
  bindnewgorupeddata = (category, index) => {
    let mycategory = [...category];
    let tempdata = structuredClone(mycategory[index]);
    mycategory.splice(index, 1);
    mycategory.splice(0, 0, tempdata);
    return mycategory;
  };
  bindCategorygroupdata = (data, selectedcatStatus = "") => {
    if (selectedcatStatus === false) {
      this.setState({ selectedCategory: false });
    }
    if (selectedcatStatus === false) {
      let category_id =
        this.props.history.location.state?.category_id === undefined
          ? this.props.history.location.pathname.split("/")[2]
          : this.props.history.location.state?.category_id;
      let parentdata;
      let newdata = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].level === 0) {
          if (data[i].id === +category_id) {
            parentdata = data[i];
            newdata =
              data[i].has_child === true ? data[i].childItem : [data[i]];
            break;
          }
        } else if (data[i].id === +category_id) {
          parentdata = data[i];
          newdata = [data[i]];
          break;
        }
        if (data[i].has_child === true && data[i]?.childItem?.length > 0) {
          for (let k = 0; k < data[i].childItem.length; k++) {
            if (data[i].childItem[k].id === +category_id) {
              parentdata = structuredClone(data[i]);
              
              newdata = this.bindnewgorupeddata(parentdata.childItem, k);
              
              // newdata.push(data[i]);
              break;
            }

            if (
              data[i].childItem[k].has_child === true &&
              data[i].childItem[k]?.childItem?.length > 0
            ) {
              for (let j = 0; j < data[i].childItem[k].childItem.length; j++) {
                if (data[i].childItem[k].childItem[j].id === +category_id) {
                  parentdata = structuredClone(data[i].childItem[k]);
                  newdata = this.bindnewgorupeddata(parentdata.childItem, j);
                  
                  // newdata.push(parentdata);
                  break;
                }
              }
            }
          }
        }
      }
      this.setState({
        viewGroupcategory: { category: newdata, parent: parentdata },
      });
      // this.props.history.push({
      //   ...this.props.history.location.state,
      //   state: { categoryviewdata: newdata },
      // });
    }
  };
  handleBackToCategory = (parent) => {
    let data = [];
    data = this.onbuildForTreeData(this.state.treeViewdata, null);
    data.sort(function (a, b) {
      return a.cat_order - b.cat_order;
    });
    
    this.bindcategorytreeviewData(data, parent);
  };
  bindcategorytreeviewData = (data, parent) => {
    let parentdata;
    let newdata = [];
    for (let i = 0; i < data.length; i++) {
      if (parent.parent_id === null) {
        if (parent.level === 0) {
          let data = this.props.history.location.state;
          if (data === undefined) data = { openallCategory: true };
          else data["openallCategory"] = true;
          this.props.history.push({
            state: data,
          });
        }
        if (data[i].id === +parent.id) {
          parentdata = data[i];
          newdata = data[i].has_child === true ? data[i].childItem : [data[i]];
          break;
        }
      }
      if (data[i].has_child === true) {
        for (let k = 0; k < data[i].childItem.length; k++) {
          if (data[i].childItem[k].id === +parent.id) {
            parentdata = structuredClone(data[i]);
            
            newdata = parentdata.childItem; //this.bindnewgorupeddata(parentdata.childItem, k);
            
            // newdata.push(data[i]);

            break;
          }

          if (data[i].childItem[k].has_child === true) {
            for (let j = 0; j < data[i].childItem[k].childItem.length; j++) {
              if (data[i].childItem[k].childItem[j].id === +parent.id) {
                parentdata = structuredClone(data[i].childItem[k]);
                newdata = parentdata.childItem; //this.bindnewgorupeddata(parentdata.childItem, j);
                
                // newdata.push(parentdata);
                break;
              }
            }
          }
        }
      }
    }
    if (parent.level !== 0) {
      this.props.history.push({
        pathname: "/listing/" + newdata[0].id,
        state: {
          category_id: newdata[0].id,
          parent_id: "",
          parentIds: "",
          parent_level: "",
          categoryviewdata: data,
          selectedgroupcat: { category: newdata, parent: parentdata },
          openallCategory: false,
        },
      });
      this.props.bindCategorygroupdata();
      this.props.FetchDataForPriceRange(
        "",
        "",
        newdata[0].id,
        "",
        false,
        false
      );
      this.setState({
        viewGroupcategory: { category: newdata, parent: parentdata },
      });
    }
  };
  redirecttoSubclass = (category_id, data, parentdata) => {
    this.props.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: "",
        parentIds: "",
        parent_level: "",
        categoryviewdata: data,
        selectedgroupcat: { category: data, parent: parentdata },
        customCategoryMsg: data?.category_message,
      },
    });
    if (
      this.props.pageName === "details_page" ||
      this.props.pageName === "home_page"
    ) {
    } else {
      this.props.FetchDataForPriceRange("", "", category_id, "", false, false);
      this.props.bindCategorygroupdata();
    }
  };
  preventDefaultScroll = (event) => {
    event.preventDefault();
  };
  render() {
    const iconClass = this.state.open ? "icon-plus" : "icon-minus";
    const { fruitType, max_price, flavor, kosher, BrandProduct } = this.props;
    const { CategoryDetails, viewGroupcategory, web_customize } = this.state;
    const parentStyle = {
      background: "#fff",
      border: "2px solid #e4e4e485",
      fontWeight: 900,
      boxShadow: "darkgray -1px 1px 5px",
    };
    return (
      <>
        <div className="list_cat_wrapper" id="categoires-listing">
          {window.screen.availWidth < 900 ? (
            <div className="list_category" style={{ padding: 0 }}>
              {this.props.pageName === "home_page" ? (
                ""
              ) : (
                <div className="list_head"></div>
              )}

              <MoblieViewCategoryListing
                CategoryDetails={CategoryDetails}
                FetchProduct={(category_id, has_child, data) =>
                  this.FetchProduct(category_id, has_child, data)
                }
              />
            </div>
          ) : (
            <>
              {viewGroupcategory !== "" ? (
                <div className={`category-group-setion`}>
                  {viewGroupcategory?.parent?.parent_id !== null ? (
                    <TypographyTextColor
                      component={"div"}
                      Color="category"
                      className="category-parent-name mb-2"
                      onClick={() => {
                        this.handleBackToCategory(
                          structuredClone(
                            this.state.treeViewdata.filter(
                              (x) =>
                                x.id === viewGroupcategory?.parent?.parent_id
                            )[0]
                          )
                        );
                      }}
                      role="button"
                    >
                      <h5 className="category-back-icon">
                        <ChevronRightIcon />
                      </h5>
                      <h5>
                        {
                          this.state.treeViewdata.filter(
                            (x) => x.id === viewGroupcategory?.parent?.parent_id
                          )[0]?.name
                        }
                      </h5>
                    </TypographyTextColor>
                  ) : (
                    <></>
                  )}
                  <TypographyTextColor
                    component={"div"}
                    Color="category"
                    className="category-parent-name"
                    onClick={() => {
                      this.handleBackToCategory(
                        structuredClone(viewGroupcategory?.parent)
                      );
                    }}
                    role="button"
                  >
                    <h5 className="category-back-icon">
                      <ChevronRightIcon />
                    </h5>
                    <h5>{viewGroupcategory?.parent?.name}</h5>
                  </TypographyTextColor>
                  <List>
                    {viewGroupcategory?.category?.length > 0 ? (
                      viewGroupcategory.category.map((ele, i) => {
                        return (
                          <>
                            {" "}
                            <ListItem
                              id={ele.id}
                              style={ele.has_child ? parentStyle : {}}
                              data-target-segment={ele?.id}
                              className={ele.has_child ? "parent-item" : ""}
                              // component={(e)=>ListItem}
                              onClick={() => {
                                if (viewGroupcategory.category.length > 1) {
                                  if (
                                    !ele?.iscloseshow &&
                                    ele.has_child === false
                                  )
                                    this.redirecttoCategory(
                                      ele?.id,
                                      i,
                                      "select",
                                      ele
                                    );
                                  else {
                                    this.redirecttoSubclass(
                                      ele.childItem[0].id,
                                      ele.childItem,
                                      ele
                                    );
                                  }
                                }
                              }}
                            >
                              <ListItemText
                                className="m-0"
                                primary={
                                  <TypographyTextFontFamily
                                    component="span"
                                    type="category"
                                  >
                                    {" "}
                                    <GoDotFill
                                      className="mr-1"
                                      style={{
                                        fontSize: "13px",
                                        color: "#adadad",
                                      }}
                                    />
                                    {ele?.name}
                                  </TypographyTextFontFamily>
                                }
                              />
                              {ele.has_child === false ? (
                                <TypographyFontColor
                                  border={0}
                                  className="d-flex"
                                  Fonttype="other"
                                >
                                  <TypographyTextFontFamily
                                    component="span"
                                    type="category"
                                    style={{
                                      padding: "4px",
                                    }}
                                  >
                                    {ele?.cat_count}
                                  </TypographyTextFontFamily>
                                  {ele?.iscloseshow ? (
                                    <TypographyBgColor
                                      className="icon-close align-self-center rounded-pill"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.redirecttoCategory(
                                          ele?.id,
                                          i,
                                          "remove"
                                        );
                                      }}
                                      style={closeStyle}
                                    ></TypographyBgColor>
                                  ) : (
                                    <></>
                                  )}
                                </TypographyFontColor>
                              ) : (
                                <></>
                              )}
                            </ListItem>
                            {ele.has_child ? (
                              <ul>
                                {ele.childItem.map((val, indx) => {
                                  return (
                                    <li
                                      id={val.id}
                                      data-target-segment={val?.id}
                                      className={`test-active ${
                                        val?.iscloseshow ? " activeItem" : ""
                                      } ${val.has_child ? "parent-item" : ""}`}
                                      onClick={() => {
                                        if (!val?.iscloseshow)
                                          this.redirecttoCategory(
                                            val?.id,
                                            indx,
                                            "select",
                                            val
                                          );
                                      }}
                                    >
                                      <TypographyTextFontFamily
                                        component="div"
                                        type="category"
                                        style={{ width: "80%" }}
                                      >
                                        <GoDotFill
                                          className="mr-1"
                                          style={{
                                            fontSize: "13px",
                                            color: "#adadad",
                                          }}
                                        />{" "}
                                        {val?.name}
                                      </TypographyTextFontFamily>
                                      <TypographyFontColor
                                        border={0}
                                        className="d-flex "
                                      >
                                        <TypographyTextFontFamily
                                          component="span"
                                          type="category"
                                          style={{
                                            padding: "4px",
                                          }}
                                        >
                                          {val?.cat_count}
                                        </TypographyTextFontFamily>
                                        {val?.iscloseshow ? (
                                          <TypographyBgColor
                                            className="icon-close align-self-center rounded-pill"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.redirecttoCategory(
                                                val?.id,
                                                indx,
                                                "remove"
                                              );
                                            }}
                                            style={closeStyle}
                                          ></TypographyBgColor>
                                        ) : (
                                          <></>
                                        )}
                                      </TypographyFontColor>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </List>
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {fruitType?.length > 0 ||
          BrandProduct?.length > 0 ||
          flavor?.length > 0 ||
          kosher?.length > 0 ? (
            <div className="list_category list-category-filter">
              <div className="list_head">
                <TypographyTextColor component="h4" Color="heading">
                  Filters Options
                </TypographyTextColor>
              </div>
            </div>
          ) : (
            ""
          )}

          {fruitType && fruitType.length > 0 ? (
            <FilterLIstSideBar
              Label="Type"
              FilterTypeData={fruitType}
              handleChangeCheckbox={(product_type) =>
                this.props.handleChangeCheckboxForProductType(product_type)
              }
              type="product_type"
            />
          ) : (
            ""
          )}

          {BrandProduct && BrandProduct.length > 0 ? (
            <FilterLIstSideBar
              Label="Brand"
              FilterTypeData={BrandProduct}
              handleChangeCheckbox={(brand_id, brand__name) =>
                this.props.handleChangeCheckbox(brand_id, brand__name)
              }
              type="brand__name"
            />
          ) : (
            ""
          )}

          {flavor && flavor.length > 0 ? (
            <FilterLIstSideBar
              Label="Flavor"
              FilterTypeData={flavor}
              handleChangeCheckbox={(flavor) =>
                this.props.handleChangeCheckboxForFlover(flavor)
              }
              type="flavor"
            />
          ) : (
            ""
          )}

          {kosher && kosher.length > 0 ? (
            <FilterLIstSideBar
              Label="Kosher"
              FilterTypeData={kosher}
              handleChangeCheckbox={(kosher) =>
                this.props.handleChangeCheckboxForKosher(kosher)
              }
              type="kosher"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default CategoryListing;
