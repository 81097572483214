import React from "react";
import Header from "./common/Header/header";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import Footer from "./common/Footer/footer";
import {
  SettingsSetValue,
  GetProfileDetails,
} from "../actions/profile/profileAction";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
} from "../actions/home/homeAction";
import {
  CardSaveapi,
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetCardSavedapi,
  GetExtraChargesData,
  deleteCardapi,
} from "../actions/cart/cartAction";
import {
  RefreshApiToken,
  getSchedule_zip,
  getCreditCardType,
  sweetAlertBox,
  SinglecheckImageUrls,
} from "../utils/renderUtils/renderUtil";
import Commonsnackbar from "./common/commonsnackbar";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import ApiUrls from "../utils/apiUtils/apiUrl";
import CustomPreloader from "./common/Loader/CustomPreloader";
import { SecondaryButton, TypographyTextColor } from "./common/UiDesign";
import { IconButton } from "@mui/material";
import { RefreshLogin } from "../actions/login/loginAction";
import VISA from "../assets/images/visa.png";
import MASTERCARD from "../assets/images/mastercard.png";
import AMEX from "../assets/images/amex.png";
import DISCOVER from "../assets/images/discover.png";
import CardsavePopup from "./common/CardsavePopup";
import { MdDelete } from "react-icons/md";
import Commondialog from "./common/Commondialog";
import { MdVerifiedUser } from "react-icons/md";
import {
  DeleteEBTCard,
  InitiateEBT,
  fetchEBTCard,
} from "../actions/order/orderAction";
class PaymentMethodlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      success: false,
      error: false,
      settingData: [],
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      pageName: "paymentmethodlistpage",
      mobileViewHide: true,
      CategoryOption: [],
      settingCartItemList: [],
      SubTotal: 0,
      headerinputField: false,
      logo: "",
      profileDetails: [],
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      footerDetails: [],
      mainLoader: true,
      isNewcard: false,
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
      cardType: "",
      openDialog: false,
      statusDialogmsg: "Do you really want to delete?",
      selectedCard: "",
      paymentOption: "",
      EBTcarddata: [],
      cardTypeD: "",
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))
        ?.allow_ebt_on_profile,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleCloseCardPopup = () => {
    this.setState({
      isNewcard: false,
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
    });
  };

  onCardHandleChange = (e) => {
    let { name, value } = e.target;
    let cardType = this.state.cardType;
    if (name === "cardNumber") {
      cardType = getCreditCardType(value);
    } else if (name === "cardMonth" || name === "cardYear") {
      value =
        value.length !== 3
          ? value.replace(/\D/, "")
          : value.slice(0, 2).padStart(2, "0");
    } else if (name === "cardCVV") {
      value =
        value.length !== 5
          ? value.replace(/\D/, "")
          : value.slice(0, 4).padStart(2, "0");
    } else if (name === "isChecked") {
      value = e.target.checked;
    }

    this.setState({
      cardDetailData: { ...this.state.cardDetailData, [name]: value },
      cardType,
    });
  };
  handleSavingCarddetails = (data) => {
    let formValues = {
      card_number: this.props.cardDetailData.cardNumber,
      expire_date: data.xExp,
      card_holder_name: this.props.cardDetailData.cardHolderName,
    };
    CardSaveapi(formValues, this.props.dispatch).then((res) => {
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.handleSavingCarddetails(data);
            }
          );
        }
      } else if (res?.errors?._error?.status === 400) {
        this.createSuccessMsg("error", res?.errors?._error?.data?.errors);
      } else {
        this.createSuccessMsg("success", "Card saved successfully");
      }
    });
  };

  handleaddebtCard = (cardNumber, iscardsave) => {
    let formvalue = {
      cardnum: cardNumber?.replace(/\s/g, ""),
      is_card_save: iscardsave,
    };
    this.setState({ mainLoader: true });

    InitiateEBT(formvalue, this.props.dispatch).then((res) => {
      if (res.status === 200) {
        if (res.data.result?.xStatus === "Approved") {
          let xAccuID = res.data.result?.xAccuID;
          let xPinPadURL =
            ApiUrls.BASH_URL +
            "/customer/account/redirect-ebt-profile/" +
            JSON.parse(localStorage.getItem("Store_address"))?.id; //window.location.href//res.data.result?.xPinPadURL;
          this.setState({ xAccuID, xPinPadURL });

          localStorage.setItem("refnum", res.data.result?.xRefNum);
          document.getElementById("ebtsubmitbtn").click();
        } else this.createSuccessMsg("error", res.data.message);
      } else if (res?.errors?._error?.status === 400) {
        this.setState({ mainLoader: false });
        this.createSuccessMsg("error", "Please enter correct EBT card no.");
      }
    });
  };

  handleAddcard = () => {
    const carddata = this.state.cardDetailData;
    let formValues = {
      card_number: carddata.cardNumber,
      expire_date: carddata.cardMonth + carddata.cardYear,
      card_holder_name: carddata.cardHolderName,
      isauthorize: false,
    };
    CardSaveapi(formValues, this.props.dispatch).then((res) => {
      console.log(res);
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.handleAddcard();
            }
          );
        }
      } else if (res?.errors?._error?.status === 400) {
        this.createSuccessMsg("error", res?.errors?._error?.data?.errors);
      } else {
        this.createSuccessMsg("success", "Card saved successfully");
        this.gelSavedCardapi();
      }
    });
  };
  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  gelSavedCardapi = () => {
    GetCardSavedapi().then((res) => {
      let cardData = [];
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.gelSavedCardapi();
            }
          );
        }
      } else if (res.data && res.data.status) {
        cardData = res.data.status;
        this.setState({ cardData, mainLoader: false });
      }
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    let LogoNav = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    LogoNav = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.gelSavedCardapi();
    this.FetchCategoryList();
    this.FetchCartItemList();
    this.FetchProfileDetails();
    this.ExtraChargesFunctiolity();
    this.setState({ logo: LogoNav });
    if (JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt)
      this.fetchEBTCardData();
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      let settingCartItemList = [];
      if (res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }

      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            settingCartItemList.push(respone[i]);
            SubTotal += Number(respone[i].total_price);
          }
        }
      }
      this.setState({
        SubTotal: SubTotal.toFixed(2),
        settingCartItemList: settingCartItemList,
        CartCount: CartCount,
        cartLoader: false,
      });
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };
  fetchEBTCardData = () => {
    fetchEBTCard().then((res) => {
      console.log(res);
      if (res.status === 200) {
        this.setState({ EBTcarddata: res.data?.status });
      } else if (res.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.fetchEBTCardData();
            }
          );
        }
      }
    });
  };
  SettingsActiveInactive = (field_name, field_value) => {
    let settingData = this.state.settingData;
    let formValues = { field_name: field_name, field_value: field_value };
    SettingsSetValue(formValues, this.props.dispatch).then((res) => {
      
      if (res) {
        if (field_name === "email_notification") {
          settingData.email_notification = field_value;
        } else if (field_name === "sms_notification") {
          settingData.sms_notification = field_value;
        } else if (field_name === "promotion_and_newslatter") {
          settingData.promotion_and_newslatter = field_value;
        }
        this.createSuccessMsg("success", res.message);
        window.location.reload();
      } else if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      } else {
        this.createSuccessMsg("error", res.message);
      }
    });
  };

  createSuccessMsg = (type, msg) => {
    console.log(type, msg);
    if (type === "error") {
      if (msg.email && msg.email[0]) {
        sweetAlertBox("Attention!!", msg.email[0], "info");
        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      } else if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else if (msg && msg.phone && msg.phone[0]) {
        sweetAlertBox("Attention!!", msg.phone[0], "info");
        // this.setState({
        //   error: msg.phone[0],
        //   success: false,
        // });
      } else if (msg && msg.error && msg.error[0]) {
        sweetAlertBox("Attention!!", msg.error[0], "info");
        // this.setState({
        //   error: msg.error[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      this.setState({
        // error: false,
        // success: msg,
        isNewcard: false,
      });
    }
  };

  handleClose = (event, reason) => {
    console.log("hitt");
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };
  handleCloseDialogBox() {
    this.setState({ openDialog: false, selectedCard: "" });
  }
  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let settingCartItemList = this.state.settingCartItemList;
    if (icons === "icon-plus") {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          settingCartItemList[i].qty = settingCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            settingCartItemList[i].qty,
            settingCartItemList[i].is_case,
            settingCartItemList[i].note,
            settingCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          settingCartItemList[i].qty = settingCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].note,
              settingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              settingCartItemList[i].qty,
              added_qty_is_case,
              settingCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          // settingCartItemList[i].qty = added_quantity;
          settingCartItemList[i].qty = added_quantity
            ? added_quantity
            : settingCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].note,
              settingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].container_type
            );
          }
        }
      }
      // this.setState({headerinputField: false});
    }
    this.setState({
      settingCartItemList: settingCartItemList,
      headerinputField: false,
    });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: note ? note : "",
      container_type: container_type ? container_type : "",
    };
    let settingCartItemList = this.state.settingCartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < settingCartItemList.length; i++) {
          if (settingCartItemList[i].product_id === product_id) {
            settingCartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(settingCartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({
        disabled: false,
        settingCartItemList,
        SubTotal: SubTotal,
      });
    });
  };
  deleteCartItem = (product_id, added_quantity, is_case, container_type) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };
  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    this.props.history.push({
      pathname: "/listing/" + e,
      state: {
        category_id: e,
        parent_id: parent_id,
        // parentIds: this.state.parentIds,
        // parent_onlyid: this.state.parent_onlyid,
        // parent_level: this.state.parent_level,
      },
    });
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  handleDeleteCard = () => {
    if (this.state.cardTypeD === "credit") {
      deleteCardapi(this.state.selectedCard, this.props.dispatch).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            this.setState({ openDialog: false, cardTypeD: "" });
            this.gelSavedCardapi();
            this.createSuccessMsg("success", "Card deleted successfully");
          }
        }
      );
    } else {
      DeleteEBTCard(this.state.selectedCard, this.props.dispatch).then(
        (res) => {
          if (res.status === 200) {
            this.setState({ openDialog: false, cardTypeD: "" });
            this.fetchEBTCardData();
            this.createSuccessMsg("success", "Card deleted successfully");
          }
        }
      );
    }
  };
  // handleaddebtCard = (cardNumber, iscardsave) => {
  //   console.log(cardNumber);
  //   let formvalue = {
  //     cardnum: cardNumber?.replace(/\s/g, ""),
  //     is_card_save: iscardsave,
  //   };
  //   this.setState({ mainLoader: true });

  //   InitiateEBT(formvalue, this.props.dispatch).then((res) => {
  //     if (res.status === 200) {
  //       if (res.data.result?.xSt atus === "Approved") {
  //         let xAccuID = res.data.result?.xAccuID;
  //         let xPinPadURL =
  //           ApiUrls.BASH_URL +
  //           "/customer/account/redirect/" +
  //           JSON.parse(localStorage.getItem("Store_address"))?.id; //window.location.href//res.data.result?.xPinPadURL;
  //         this.setState({ xAccuID, xPinPadURL });
  //         localStorage.setItem("refnum", res.data.result?.xRefNum);
  //         document.getElementById("ebtsubmitbtn").click();
  //       } else this.createSuccessMsg("error", res.data.message);
  //     } else if (res?.errors?._error?.status === 400) {
  //       this.setState({ mainLoader: false });
  //       this.createSuccessMsg("error", "Please enter correct EBT card no.");
  //     }
  //   });
  // };
  handleEBTcardno = (e) => {
    let { name, value } = e.target;
    if (name === "isChecked")
      this.setState({ isebtCardSave: e.target.checked });
    else {
      let values =
        e.target.value.toString().length !== 20
          ? e.target.value.toString().replace(/\D/, "")
          : e.target.value.toString().slice(0, 4).padStart(2, "0");
      this.setState({ ebtcardNumber: values });
    }
  };
  render() {
    const {
      CategoryDetails,
      CartCount,
      CategoryOption,
      settingCartItemList,
      web_customize,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          cartItemList={settingCartItemList}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartCount={() => this.FetchCartItemList()}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index,
            container_type
          ) =>
            this.AddCountForShoppingcartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            )
          }
          changeheaderinputFieldValue={() => this.changeheaderinputFieldValue()}
          headerinputField={this.state.headerinputField}
          logo={this.state.logo}
          extraCharges={this.state.extraCharges}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="user_details">
              <div className="user_main_wrapper">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  profileDetails={this.state.profileDetails}
                  openDetailsMenu={() => this.openDetailsMenu()}
                />
                <div className="user_details_desc user_detail_new order_main_wrapper">
                  <div className="row">
                    <div
                      className={`col-md-12 ${
                        window.screen.availWidth >= 900 ? "" : "pr-0"
                      }`}
                    >
                      <div className="cust_head_main cust_hide">
                        <TypographyTextColor Color="heading" component="h4">
                          Payment Methods
                        </TypographyTextColor>
                        <div
                          className={`${
                            window.screen.availWidth >= 900 ? "" : "d-flex"
                          }`}
                        >
                          <SecondaryButton
                            // style={{ color: "#fff" }}
                            onClick={() => {
                              this.setState({
                                isNewcard: true,
                                paymentOption: "card",
                              });
                            }}
                            Fonttype="other"
                          >
                            Add Credit Card
                          </SecondaryButton>
                          {this.state.isShowEBT ? (
                            <SecondaryButton
                              Fonttype="other"
                              // style={{ color: "#fff" }}
                              className="ml-2"
                              onClick={() => {
                                this.setState({
                                  isNewcard: true,
                                  paymentOption: "ebt",
                                });
                              }}
                            >
                              Add EBT
                            </SecondaryButton>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="switch_wrapper">
                        {this.state.cardData.map((val, i) => {
                          return (
                            <div className="settings_content" key={i}>
                              <div className="d-flex align-items-center">
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  {val.card_number}
                                </TypographyTextColor>
                                <div>
                                  <img
                                    src={
                                      val.card_type === "Visa"
                                        ? VISA
                                        : val.card_type === "MasterCard"
                                        ? MASTERCARD
                                        : val.card_type === "Amex"
                                        ? AMEX
                                        : DISCOVER
                                    }
                                    class="card-img ml-2"
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                  {val.isauthorize && (
                                    <MdVerifiedUser
                                      style={{
                                        color: "#008000",
                                        fontSize: "20px",
                                      }}
                                    />
                                  )}{" "}
                                </div>
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                  className="ml-5"
                                >
                                  {" "}
                                  {val?.card_holder_name === null
                                    ? "Name"
                                    : val.card_holder_name}
                                </TypographyTextColor>
                              </div>
                              <IconButton
                                className="ml-auto"
                                onClick={() => {
                                  this.setState({
                                    selectedCard: val.id,
                                    openDialog: true,
                                    cardTypeD: "credit",
                                  });
                                }}
                              >
                                <MdDelete />
                              </IconButton>
                            </div>
                          );
                        })}

                        {this.state.EBTcarddata.map((val, i) => {
                          return (
                            <div className="settings_content" key={i}>
                              <div className="d-flex align-items-center">
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  {val.card_number}
                                </TypographyTextColor>
                                <div>
                                  <TypographyTextColor
                                    Color="heading"
                                    component="span"
                                    className="ml-2 card-label"
                                  >
                                    SNAP (EBT)
                                  </TypographyTextColor>
                                </div>
                              </div>
                              <IconButton
                                className="ml-auto"
                                onClick={() => {
                                  this.setState({
                                    selectedCard: val.id,
                                    openDialog: true,
                                    cardTypeD: "ebt",
                                  });
                                }}
                              >
                                <MdDelete />
                              </IconButton>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer history={this.props.history} />
            {/* <Subscribe  history={this.props.history}/>
          <Footer/> */}
          </div>
        )}
        <Commondialog
          open={this.state.openDialog}
          handleCloseOpnDialog={() => this.handleCloseDialogBox()}
          agreeFunctionlity={(value) => {
            this.handleDeleteCard();
          }}
          statusDialogmsg={this.state.statusDialogmsg}
        />
        {this.state.success === false && this.state.error === false ? (
          ""
        ) : (
          <Commonsnackbar
            success={this.state.success}
            handleClose={this.handleClose}
            error={this.state.error}
          />
        )}
        {this.state.isNewcard && (
          <CardsavePopup
            createSuccessMsg={(type, msg) => this.createSuccessMsg(type, msg)}
            FinalOrderCardknoxVerify={() => this.handleAddcard()}
            cardType={this.state.cardType}
            onCardHandleChange={(e) => this.onCardHandleChange(e)}
            cardDetailData={this.state.cardDetailData}
            isopen={this.state.isNewcard}
            handleClose={() => this.handleCloseCardPopup()}
            paymentLoader={this.state.paymentLoader}
            ebtcardNumber={this.state.ebtcardNumber}
            type="Accountpage"
            paymentOption={this.state.paymentOption}
            handleEBTcardno={(e) => this.handleEBTcardno(e)}
            handleaddebtCard={(cardnumber, iscardsave) =>
              this.handleaddebtCard(cardnumber, true)
            }
          />
        )}
        <form
          action="https://test.paysecure.acculynk.net/PINpad"
          method="post"
          style={{ display: "none", opacity: 0 }}
        >
          <label>AccuId:</label>
          <input
            type="text"
            id="AccuId"
            name="AccuId"
            value={this.state.xAccuID}
          />
          <label>AccuReturnURL:</label>
          <input
            type="text"
            id="AccuReturnURL"
            name="AccuReturnURL"
            value={this.state.xPinPadURL}
          />
          <label>AccuLanguage:</label>
          <input
            type="text"
            id="AccuLanguage"
            name="AccuLanguage"
            value="en-US"
          />
          <input type="submit" value="Submit" id="ebtsubmitbtn" />
        </form>
      </div>
    );
  }
}
export default PaymentMethodlist;
