import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CreditCard from "@mui/icons-material/CreditCard";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SecondaryButton, TypographyTextColor } from "./UiDesign";
import {
  CardFieldComponent,
  EBTCardFieldComponent,
} from "../../utils/renderUtils/renderUtil";

const CardsavePopup = (props) => {
  const {
    isopen,
    handleClose,
    cardType,
    isebtCardSave,
    cardDetailData,
    onCardHandleChange,
    paymentOption,
    ebtcardNumber,
    handleEBTcardno,
  } = props;
  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);
  const handleSaveCard = () => {
    if (
      cardDetailData.cardNumber !== "" &&
      cardDetailData.cardHolderName !== "" &&
      cardDetailData.cardMonth !== "" &&
      cardDetailData.cardYear !== "" &&
      (cardDetailData.cardCVV !== "" || props.type === "Accountpage")
    ) {
      if (props.type === "paymentpage" || props.type === "Accountpage") {
        props.FinalOrderCardknoxVerify();
      } else {
      }
    } else {
      props.createSuccessMsg("error", "Please enter all mandatory fields");
    }
  };
  return (
    <Dialog open={isopen}>
      <DialogTitle>
        <div className={`d-flex justify-content-between ${window.screen.availWidth >= 900?'':'p-2'}`}>
          <TypographyTextColor component={"h4"} Color="heading">
            Card Details
          </TypographyTextColor>

          <IconButton size="small" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div id="form-container">
          {paymentOption === "ebt" ? (
            <>
              <Typography component="div" id="card-front">
                <TypographyTextColor
                  component="label"
                  Color="other"
                  for="card-number"
                  className="credit-card-label"
                >
                  EBT Card Number <span style={{ color: "red" }}>*</span>
                </TypographyTextColor>
                <Typography
                  component={TextField}
                  fullWidth
                  type="text"
                  id="card-number"
                  name="cardNumber"
                  onChange={(e) => handleEBTcardno(e)}
                  className="credit-card-input"
                  placeholder="e.g. 1234 5678 9101 1112"
                  value={ebtcardNumber}
                  InputProps={{
                    inputComponent: EBTCardFieldComponent,
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <span>
                          <CreditCard fontSize="large" />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                    (e.target.placeholder = "e.g. 1234 5678 9101 1112")
                  }
                />
              </Typography>
              {props.type === "paymentpage" &&
              localStorage.getItem("Guest_userid") === null ? (
                <div className="mt-3 d-flex align-items-start">
                  <input
                    type={"checkbox"}
                    style={{ transform: "scale(1.2)", marginTop: "4px" }}
                    name="isChecked"
                    value={isebtCardSave}
                    onChange={(e) => handleEBTcardno(e)}
                  />{" "}
                  <TypographyTextColor
                    component="label"
                    Color="other"
                    for="card-exp"
                    className=" ml-2"
                    style={{ fontSize: "12px" }}
                  >
                    Save this card on file
                  </TypographyTextColor>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Typography component="div" id="card-front">
              <TypographyTextColor
                component="label"
                Color="other"
                for="card-number"
                className="credit-card-label"
              >
                Card Number <span style={{ color: "red" }}>*</span>
              </TypographyTextColor>

              <Typography
                component={TextField}
                fullWidth
                type="text"
                id="card-number"
                name="cardNumber"
                onChange={(e) => onCardHandleChange(e)}
                className="credit-card-input"
                placeholder="e.g. 1234 5678 9101 1112"
                value={cardDetailData.cardNumber}
                InputProps={{
                  inputComponent: CardFieldComponent,
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <span>
                        {cardType !== "" ? (
                          <img
                            src={cardType}
                            alt="MASTERCARD"
                            style={{ width: "40px" }}
                          />
                        ) : (
                          <CreditCard fontSize="large" />
                        )}
                      </span>
                    </InputAdornment>
                  ),
                }}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) =>
                  (e.target.placeholder = "e.g. 1234 5678 9101 1112")
                }
              />

              <div id="cardholder-container" className="mt-3">
                <TypographyTextColor
                  component="label"
                  Color="other"
                  for="card-holder"
                  className="credit-card-label"
                >
                  Card Holder <span style={{ color: "red" }}>*</span>
                </TypographyTextColor>

                <Typography
                  component="input"
                  type="text"
                  id="card-holder"
                  name="cardHolderName"
                  value={cardDetailData.cardHolderName}
                  onChange={(e) => onCardHandleChange(e)}
                  className="credit-card-input"
                  placeholder="e.g. John Doe"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "e.g. John Doe")}
                />
              </div>

              <div id="exp-container" className="mt-3">
                <TypographyTextColor
                  component="label"
                  Color="other"
                  for="card-exp"
                  className="credit-card-label"
                >
                  Expiration <span style={{ color: "red" }}>*</span>
                </TypographyTextColor>

                <Typography
                  component="input"
                  id="card-month"
                  type="text"
                  name="cardMonth"
                  value={cardDetailData.cardMonth}
                  onChange={(e) => {
                    onCardHandleChange(e);
                    if (e.target.value.length === 2)
                      yearInputRef.current.focus();
                  }}
                  className="credit-card-input"
                  placeholder="MM"
                  length="2"
                  pattern="[0-9]*"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "MM")}
                  autoComplete={false}
                />
                <Typography
                  component="input"
                  id="card-year"
                  type="text"
                  name="cardYear"
                  value={cardDetailData.cardYear}
                  onChange={(e) => {
                    onCardHandleChange(e);
                    if (
                      e.target.value.length === 2 &&
                      props.type === "paymentpage"
                    )
                      cvvInputRef.current.focus();
                  }}
                  className="credit-card-input"
                  placeholder="YY"
                  length="2"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "YY")}
                  autoComplete={false}
                  ref={yearInputRef}
                />
              </div>
              {props.type === "paymentpage" ? (
                <>
                  <div>
                    <TypographyTextColor
                      component="label"
                      Color="other"
                      for="card-cvc"
                      className="credit-card-label"
                    >
                      CVC/CVV <span style={{ color: "red" }}>*</span>
                    </TypographyTextColor>

                    <Typography
                      component="input"
                      id="card-cvc"
                      placeholder="XXXX"
                      name="cardCVV"
                      value={cardDetailData.cardCVV}
                      onChange={(e) => onCardHandleChange(e)}
                      className="credit-card-input"
                      type="text"
                      min-length="3"
                      max-length="4"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "XXXX")}
                      autoComplete={false}
                      ref={cvvInputRef}
                    />
                  </div>
                  {localStorage.getItem("Guest_userid") === null ? (
                    <div className="mt-2 d-flex align-items-start">
                      <input
                        type={"checkbox"}
                        style={{ transform: "scale(1.2)", marginTop: "4px" }}
                        name="isChecked"
                        value={cardDetailData.isChecked}
                        onChange={(e) => onCardHandleChange(e)}
                      />{" "}
                      <TypographyTextColor
                        component="label"
                        Color="other"
                        for="card-exp"
                        className=" ml-2"
                        style={{ fontSize: "12px" }}
                      >
                        Save this card on file
                      </TypographyTextColor>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          Fonttype="other"
          disabled={props.paymentLoader}
          className="text-white"
          onClick={() => {
            paymentOption === "ebt"
              ? props.handleaddebtCard(ebtcardNumber, isebtCardSave)
              : handleSaveCard();
          }}
        >
          {props.paymentLoader
            ? "Processing..."
            : props.type === "paymentpage"
            ? "Continue"
            : "Save"}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CardsavePopup;
