import React from "react";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import OrderSummary from "./common/Order/orderSummary";
import CategoriesModal from "./common/Category/categoriesModal";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  CartListFunctionlity,
  CardPaymentData,
  GetExtraChargesData,
  GetCardSavedapi,
  CardOrderSummaryFunctionlity,
} from "../actions/cart/cartAction";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  CommonLogoutFunctionlity,
  commonPageTitel,
  getSchedule_zip,
  RefreshApiToken,
  getSchedule_zip_Cart,
  getCreditCardType,
  sweetAlertBox,
} from "../utils/renderUtils/renderUtil";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import {
  CategeriesListView,
  SubCategeriesListView,
} from "../actions/home/homeAction";
import {
  GetProfileDetails,
  GetSettingsData,
} from "../actions/profile/profileAction";
import CustomPreloader from "./common/Loader/CustomPreloader";
import VISA from "../assets/images/visa.png";
import MASTERCARD from "../assets/images/mastercard.png";
import AMEX from "../assets/images/amex.png";
import DISCOVER from "../assets/images/discover.png";
import { Typography, Divider } from "@mui/material";
import {
  ScrollColorTypography,
  TypographyPrimaryBgColorDimmed,
  TypographyTextColor,
} from "./common/UiDesign";
import { BsCashStack } from "react-icons/bs";
import { MdVerifiedUser } from "react-icons/md";
import {
  GetCustomerCreditsApi,
  InitiateEBT,
  fetchEBTCard,
  fetchEBTbalance,
} from "../actions/order/orderAction";
import ApiUrls from "../utils/apiUtils/apiUrl";
import Commonsnackbar from "./common/commonsnackbar";
import EBTLOGO from "../assets/images/logo-snap.png";
class PaymentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      pageName: "payment",
      CategoryDetails: [],
      CartCount: [],
      paymentOption:
        localStorage.getItem("PaymentOptionVisibility") === "no_option"
          ? "cash_payment"
          : localStorage.getItem("selectedPaymentoption") !== null
          ? "ebt"
          : "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCVV: "",
      deliveryOption: this.props?.history?.location?.state?.delivery_type,
      chooseCardPayment: true,
      chooseCashPayment: false,
      CategoryOption: [],
      paycartItemList: [],
      cartLoader: false,
      addressShowHide: false,
      logo: "",
      account: {
        xKey: "",
        xSoftwareName: "",
        xSoftwareVersion: "",
      },

      issuer: "unknown",
      xToken: "",
      xTokenType: "",
      CVVToken: "",
      paymentLoader: false,
      cardDetailsDesiable: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      mainLoader: true,
      emailSubscribe: "",
      creditcardNumber: "",
      creditcardCVV: "",
      creditCardType: "",
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
      cardType: "",
      cardData: [],
      selectedCardData: "",
      isNewcard: false,
      ebtAmount: 0,
      ebtorderAmount: 0,
      originalebtorderAmount: 0,
      ebtcardNumber: "",
      isebtpayment: false,
      total_price: 0,
      EBTcarddata: [],
      chooseEBTPayment: false,
      isebtCardSave: false,
      tips_amount: 0,
      ebtisSelected:
        localStorage.getItem("selectedPaymentoption") !== null
          ? parseInt(localStorage.getItem("selectedPaymentoption"))
          : "",
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      is_show_customer_credit: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_show_customer_credit,
      creditData: { isPaidByCredit: false, paymentAmount: 0 },
      cartListIte: [],
      web_customize: JSON.parse(localStorage.getItem("web_customize")),

      // creditData: {
      //   isPaidByCredit: false,
      //   paymentAmount: 0,
      //   customerCreditStatus: { availableCredit: 100, hasCredit: true },
      // },
    };
    this.cvvRef = React.createRef();
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }

  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  gelSavedCardapi = () => {
    this.setState({ mainLoader: true });
    GetCardSavedapi().then((res) => {
      this.setState({ mainLoader: false });
      let cardData = [];
      if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.gelSavedCardapi();
            }
          );
        }
      } else if (res.data && res.data.status) {
        cardData = res.data.status;
        cardData.map((ele) => {
          ele.isSelected = false;
          ele.cvv = "";
        });
        this.setState({ cardData });
      }
    });
  };
  fetchebtdata = () => {
    if (localStorage.getItem("refnum") !== null) {
      let formvalues = {
        refnum: localStorage.getItem("refnum"),
      };
      this.setState({ mainLoader: true });
      fetchEBTbalance(formvalues, this.props.dispatch).then((res) => {
        if (res.status === 200) {
          let ebtorderamt = this.state.ebtorderAmount;
          let ebtBalance = res.data.result?.xRemainingBalanceEBTFS;
          let credits = this.state.creditData;
          if (ebtorderamt <= ebtBalance)
            this.setState({ ebtAmount: ebtBalance });
          else {
            this.setState({
              ebtAmount: ebtBalance,
              ebtorderAmount: ebtBalance,
              originalebtorderAmount: ebtBalance,
            });
          }
          let TotalPayment = this.state.total_price;
          let finalPrice = (
            parseFloat(TotalPayment) - parseFloat(this.state.ebtorderAmount)
          ).toFixed(2);
          if (
            parseFloat(credits?.customerCreditStatus?.availableCredit) >=
            finalPrice
          )
            credits.paymentAmount = finalPrice;
          else
            credits.paymentAmount =
              credits.customerCreditStatus.availableCredit;
          this.setState({
            paymentOption: "ebt",
            isebtpayment: true,
            creditData: credits,
          });
        } else {
          this.setState({ ebtAmount: 0, paymentOption: "", ebtisSelected: "" });
          localStorage.removeItem("selectedPaymentoption");
        }
      });
      this.setState({ mainLoader: false });
    }
  };
  fetchEBTCardData = () => {
    this.setState({ mainLoader: true });
    fetchEBTCard().then((res) => {
      console.log(res);
      this.setState({ mainLoader: false });
      if (res.status === 200) {
        this.setState({ EBTcarddata: res.data?.status });
      } else if (res.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.fetchEBTCardData();
            }
          );
        }
      }
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    const accessToken = localStorage.getItem("access_token");
    document.title = commonPageTitel(
      JSON.parse(localStorage.getItem("access_token"))
    );
    const isLogin = localStorage.getItem("isLogin");
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    if (
      (accessToken && isLogin === "true") ||
      localStorage.getItem("Guest_userid") !== null
    ) {
      this.FetchCategoryList();
      this.FetchPaymentCartItemList();
      this.FetchCardPaymentData();
      this.FetchSettingData();
      this.gelSavedCardapi();
      this.FetchCartOrderSummaryList();
      this.fetchEBTCardData();
      if (this.state.is_show_customer_credit) this.FetchProfileDetails();
    }
    this.setState({ logo: logo });

    if (
      localStorage.getItem("PaymentOptionVisibility") &&
      localStorage.getItem("PaymentOptionVisibility") === "no_option"
    ) {
      this.setState({
        chooseCashPayment: true,
        chooseCardPayment: false,
        cardDetailsDesiable: true,
      });
    } else
      this.setState({
        chooseCashPayment: false,
        chooseCardPayment: true,
        cardDetailsDesiable: false,
        // paymentOption: "card_payment",
      });
  }
  FetchCartOrderSummaryList = () => {
    let formdata = getSchedule_zip_Cart();
    let formValues = {
      limit: 7,
    };
    let TotalPayment;
    let OriginalPayment;
    formValues = { ...formValues, ...formdata };
    CardOrderSummaryFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let cardOrderSummar = [];
        if (res.data && res.data.result) {
          cardOrderSummar = res.data.result;
          

          let extracharges =
            cardOrderSummar.extra_charges_name !== "" &&
            cardOrderSummar.extra_charges_name !== null &&
            cardOrderSummar.extra_charges_price !== 0 &&
            cardOrderSummar.extra_charges_price !== null
              ? cardOrderSummar.extra_charges_price
              : 0;

          TotalPayment =
            cardOrderSummar.subtotal +
            cardOrderSummar.shipping_price +
            cardOrderSummar.Estimated_tax +
            cardOrderSummar.delivery_charge +
            extracharges;
          // OriginalPayment =
          //   cardOrderSummar.subtotal +
          //   cardOrderSummar.shipping_price +
          //   cardOrderSummar.Estimated_tax +
          //   cardOrderSummar.delivery_charge +
          //   extracharges;
          let ebttotal = cardOrderSummar.ebt_subtotal;
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          if (refreshToken) {
            this.props.RefreshApiToken(refreshToken.refresh_token);
          }
        }
        let credits = this.state.creditData;
        // if (
        //   parseFloat(credits?.customerCreditStatus?.availableCredit) <
        //   parseFloat(TotalPayment).toFixed(2)
        // )
        //   credits.paymentAmount = parseFloat(
        //     credits.customerCreditStatus?.availableCredit
        //   ).toFixed(2);
        // else credits.paymentAmount = parseFloat(TotalPayment).toFixed(2);
        this.setState({
          // CardOrderSummary: cardOrderSummar,
          // TotalPayment: TotalPayment,
          // OriginalPayment: OriginalPayment,
          total_price: TotalPayment,
          // creditData: credits,
          // isebtpayment: cardOrderSummar.ebt_subtotal > 0,
          // delivery_charge: cardOrderSummar.delivery_charge,
          ebtorderAmount: cardOrderSummar.ebt_subtotal,
          originalebtorderAmount: cardOrderSummar.ebt_subtotal,
        });
        this.fetchebtdata();
      }
    );
  };
  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  RefreshApiToken = (refresh_token) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        document.title = commonPageTitel(
          JSON.parse(localStorage.getItem("access_token"))
        );
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        this.FetchCategoryList();
        this.FetchPaymentCartItemList();
        this.FetchCardPaymentData();
      } else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    CommonLogoutFunctionlity();
    this.props.history.push({
      pathname: "/",
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  FetchSubCategoryList = (category_id) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      if (res.data && res.data.result) {
        subCategoryList = res.data.result;
      }
      this.setState({ SubCategoryDetails: subCategoryList });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }

  chosePaymentOption = (value, isNewcard) => {
    let chooseCardPayment;
    let chooseCashPayment;
    let chooseEBTPayment = false;
    let data = this.state.cardData;
    let cardDetailsDesiable = this.state.cardDetailsDesiable;
    let credits = this.state.creditData;
    if (
      parseFloat(this.state.ebtorderAmount) ===
        parseFloat(this.state.total_price) &&
      this.state.ebtisSelected !== ""
    ) {
      return;
    } else if (
      credits.isPaidByCredit &&
      parseFloat(credits.paymentAmount) === parseFloat(this.state.total_price)
    ) {
      return;
    }
    console.log(credits);
    if (value === "ebt") {
      chooseCashPayment = false;
      chooseCardPayment = false;
      cardDetailsDesiable = false;
      chooseEBTPayment = true;
    } else if (value === "cash_payment" || value === "pickup_pay") {
      chooseCashPayment = true;
      chooseCardPayment = false;
      cardDetailsDesiable = true;
    } else {
      chooseCashPayment = false;
      chooseCardPayment = true;
      cardDetailsDesiable = false;
      this.setState({ selectedCardData: "" });
    }

    if (isNewcard || value === "cash_payment") {
      data.map((val) => {
        val.isSelected = false;
      });
    }
    this.setState({
      paymentOption: value,
      chooseCashPayment,
      chooseCardPayment,
      cardDetailsDesiable,
      isNewcard: isNewcard,
      cardData: data,
      chooseEBTPayment,
    });
  };

  FetchPaymentCartItemList = () => {
    let formValues = getSchedule_zip_Cart();
    let SubTotal = 0;
    let cartListIte = [];
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res.data.total === 0) {
        this.props.history.push({
          pathname: "/",
        });
      } else {
        let paycartItemList = [];
        let CartCount = [];
        if (res.data && res.data.result) {
          const respone = res.data.result;
          if (respone.length > 0) {
            for (let i = 0; i < respone.length; i++) {
              paycartItemList.push(respone[i]);
              SubTotal += Number(respone[i].total_price);
            }
          }
          cartListIte = res.data.result;
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchPaymentCartItemList();
              }
            );
          }
        }
        if (res.data.total > 0) {
          CartCount.item = res.data.total;
          CartCount.price = Number(res.data.total_subtotal).toFixed(2);
        }
        this.setState({
          paycartItemList: paycartItemList,
          SubTotal: SubTotal.toFixed(2),
          cartListOpen: true,
          CartCount: CartCount,
          cartLoader: false,
          mainLoader: false,
          cartListIte,
        });
      }
    });
  };

  FetchCardPaymentData = () => {
    let formValues = {};
    this.setState({ paymentLoader: true });
    CardPaymentData(formValues, this.props.dispatch).then((res) => {
      let xKey = "";
      let xSoftwareName = "";
      let xSoftwareVersion = "";
      if (res.data) {
        xKey = res.data.ifield_data;
        xSoftwareName = res.data.software_name;
        xSoftwareVersion = res.data.software_version;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCardPaymentData();
            }
          );
        }
      }
      this.setState({
        account: {
          xKey: xKey,
          xSoftwareName: xSoftwareName,
          xSoftwareVersion: xSoftwareVersion,
        },
        paymentLoader: false,
      });
    });
  };

  handleSlotName = (data) => {};

  allowOnlyNumericsOrDigits(e) {
    if (this.state.originalebtorderAmount >= e.target.value)
      this.setState({ ebtorderAmount: e.target.value });
    if (parseFloat(e.target.value) === parseFloat(this.state.total_price)) {
      let data = this.state.cardData;
      data.map((val) => {
        val.isSelected = false;
      });
      this.setState({
        paymentOption: "ebt",
        chooseCashPayment: false,
        chooseCardPayment: false,
        cardDetailsDesiable: false,
        cardData: data,
      });
    }
  }

  handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }
  allowOnlyNumericsOrDigitscredits(e) {
    let data = this.state.creditData;
    console.log(
      parseFloat(this.state.total_price).toFixed(2) >=
        parseFloat(e.target.value) &&
        parseFloat(data?.customerCreditStatus?.availableCredit) <
          parseFloat(e.target.value),
      parseFloat(this.state.total_price).toFixed(2) >=
        parseFloat(e.target.value),
      parseFloat(data?.customerCreditStatus?.availableCredit) <
        parseFloat(e.target.value),
      parseFloat(data?.customerCreditStatus?.availableCredit),
      parseFloat(e.target.value)
    );
    data.paymentAmount =
      e.target.value === ""
        ? ""
        : parseFloat(this.state.total_price).toFixed(2) >=
            parseFloat(e.target.value) &&
          parseFloat(data?.customerCreditStatus?.availableCredit) >=
            parseFloat(e.target.value)
        ? this.handleDecimalsOnValue(e.target.value)
        : data.paymentAmount;
    this.setState({ creditData: data });

    // Check if the entered payment amount equals the total price
    if (e.target.value.toString() === this.state.total_price.toString()) {
      // Update cardData in the component's state to unselect all cards
      let data = this.state.cardData;
      data.map((val) => {
        val.isSelected = false;
      });

      // Update the component's state for payment options and card details
      this.setState({
        paymentOption: "ebt",
        chooseCashPayment: false,
        chooseCardPayment: false,
        cardDetailsDesiable: false,
        cardData: data,
      });
      // }
    }
  }

  onCardHandleChange = (e) => {
    let { name, value } = e.target;
    let cardType = this.state.cardType;
    if (name === "cardNumber") {
      cardType = getCreditCardType(value);
    } else if (name === "cardMonth" || name === "cardYear") {
      value =
        value.length !== 3
          ? value.replace(/\D/, "")
          : value.slice(0, 2).padStart(2, "0");
    } else if (name === "cardCVV") {
      value =
        value.length !== 5
          ? value.replace(/\D/, "")
          : value.slice(0, 4).padStart(2, "0");
    } else if (name === "isChecked") {
      value = e.target.checked;
    }

    this.setState({
      cardDetailData: { ...this.state.cardDetailData, [name]: value },
      cardType,
    });
  };
  handleSelectCard = (id, key, value = "") => {
    let data = this.state.cardData;
    let selectedData = "";
    let cardDetails = true;
    if (
      parseFloat(this.state.ebtorderAmount) ===
        parseFloat(this.state.total_price) &&
      this.state.ebtisSelected !== ""
    ) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        // if (data[i]["isSelected"] === id && key === "isSelected") {
        //   data[i][key] = "";
        // } else
        data[i][key] =
          key === "isSelected"
            ? id
            : value.length !== 5
            ? value.replace(/\D/, "")
            : value.slice(0, 4).padStart(2, "0");
        selectedData = data[i];
        cardDetails = data[i].isSelected;
      } else data[i][key] = "";
    }
    this.setState({
      cardData: data,
      selectedCardData: selectedData,
      cardDetailsDesiable: cardDetails,
      chooseCashPayment: false,
      chooseCardPayment: false,
      paymentOption: "card_payment",
    });
  };

  handleCloseCardPopup = () => {
    this.setState({
      paymentOption: "",
      isNewcard: false,
      cardDetailData: {
        cardHolderName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCVV: "",
        isChecked: false,
      },
    });
  };
  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.message) {
        sweetAlertBox("Attention!!", msg.message, "info");
        this.setState({
          // error: msg.message,
          // success: false,
          paymentLoader: false,
        });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        this.setState({
          // error: msg,
          // success: false,
          paymentLoader: false,
        });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
      // window.location.reload(false);
    }
  };
  handleaddebtCard = (cardNumber, iscardsave, isSetref = true) => {
    let formvalue = {
      cardnum: cardNumber?.replace(/\s/g, ""),
      is_card_save: iscardsave,
    };
    this.setState({ mainLoader: true });

    InitiateEBT(formvalue, this.props.dispatch).then((res) => {
      if (res.status === 200) {
        if (res.data.result?.xStatus === "Approved") {
          let xAccuID = res.data.result?.xAccuID;
          let xPinPadURL =
            ApiUrls.BASH_URL +
            "/customer/account/redirect/" +
            JSON.parse(localStorage.getItem("Store_address"))?.id+'/true'; //window.location.href//res.data.result?.xPinPadURL;
          this.setState({ xAccuID, xPinPadURL });
          localStorage.setItem("refnum", res.data.result?.xRefNum);
          if (isSetref)
            localStorage.setItem(
              "selectedPaymentoption",
              res.data.result?.xRefNum
            );
          document.getElementById("ebtsubmitbtn").click();
        } else this.createSuccessMsg("error", res.data.message);
      } else if (res?.errors?._error?.status === 400) {
        this.setState({ mainLoader: false });
        this.createSuccessMsg("error", "Please enter correct EBT card no.");
      }
    });
  };
  handleEBTcardno = (e) => {
    let { name, value } = e.target;
    if (name === "isChecked")
      this.setState({ isebtCardSave: e.target.checked });
    else {
      let values =
        e.target.value.toString().length !== 20
          ? e.target.value.toString().replace(/\D/, "")
          : e.target.value.toString().slice(0, 4).padStart(2, "0");
      this.setState({ ebtcardNumber: values });
    }
  };
  handleTipAmount = (amount) => {
    let credits = this.state.creditData;
    credits.paymentAmount = (this.state.total_price + amount).toFixed(2);
    this.setState({ tips_amount: amount, creditData: credits });
  };
  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    this.setState({ mainLoader: true });
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      this.setState({ mainLoader: false });
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
        this.fetchCustomerCredits(profileDetails.phone);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  fetchCustomerCredits = (phone) => {
    let formValues = { mobile_number: phone.toString() };
    this.setState({ mainLoader: true });
    GetCustomerCreditsApi(formValues).then((res) => {
      this.setState({ mainLoader: false });
      if (res.status === 200) {
        let Response = res.data.result.data;
        let credit = this.state.creditData;
        let TotalPayment = this.state.total_price;
        console.log(
          credit,
          parseFloat(credit?.customerCreditStatus?.availableCredit) <
            parseFloat(TotalPayment).toFixed(2),
          parseFloat(credit?.customerCreditStatus?.availableCredit),
          parseFloat(TotalPayment).toFixed(2)
        );
        if (
          parseFloat(Response?.customerCreditStatus?.availableCredit) <
          parseFloat(TotalPayment).toFixed(2)
        )
          credit.paymentAmount = parseFloat(
            Response.customerCreditStatus?.availableCredit
          ).toFixed(2);
        else credit.paymentAmount = parseFloat(TotalPayment).toFixed(2);
        credit = { ...credit, ...Response };
        this.setState({ creditData: credit });
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  FetchCallAllAPi = () => {
    this.FetchCategoryList();
    this.FetchPaymentCartItemList();
    this.FetchCardPaymentData();
    this.FetchSettingData();
    this.gelSavedCardapi();
    this.FetchCartOrderSummaryList();
    this.fetchEBTCardData();
  };
  render() {
    const {
      CategoryDetails,
      SubCategoryDetails,
      CartCount,
      CategoryOption,
      web_customize,
    } = this.state;

    return (
      <ScrollColorTypography component="div" className="main-content">
        <Header
          FetchCallAllAPi={() => this.FetchCallAllAPi()}
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          FetchCartCount={() => this.FetchPaymentCartItemList()}
          logo={this.state.logo}
          extraCharges={this.state.extraCharges}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
          handleSlotNameData={(data) => this.handleSlotName(data)}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          SubCategoryDetails={SubCategoryDetails}
          history={this.props}
          pageName={this.state.pageName}
        />

        <SubCategoryModal
          open_inner={this.state.open_inner}
          SubcategoryName={this.state.SubcategoryName}
          // handleInnerOpenDialog={this.handleInnerOpenDialog}
          handleInnerCloseDialog={this.handleInnerCloseDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleOpenDialog={this.handleOpenDialog}
          SubCategoryDetails={this.state.SubCategoryDetails}
          history={this.props.history}
          pageName={this.state.pageName}
          SubSubCategoryDetails={this.state.SubSubCategoryDetails}
          subCategoryLoader={this.state.subCategoryLoader}
          FetchSubCategoryList={(id, level) =>
            this.FetchSubCategoryList(id, level)
          }
          parentIds={this.state.parentIds}
          parent_onlyid={this.state.parent_onlyid}
          parent_level={this.state.parent_level}
        />
        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            {" "}
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="cart_main">
              <div className="cart_wrapper">
                <div className="delivery_wrapper d-flex justify-content-center">
                  <div className="credit-card-container">
                    <div>
                      <TypographyTextColor
                        Color="heading"
                        className="mb-4"
                        component="h4"
                      >
                        PAYMENT METHOD
                      </TypographyTextColor>
                      {this.state.ebtorderAmount !== 0 &&
                      this.state.isShowEBT ? (
                        <>
                          <Typography
                            component={
                              this.state.paymentOption === "ebt" &&
                              this.state.chooseEBTPayment
                                ? TypographyPrimaryBgColorDimmed
                                : "div"
                            }
                            border={0}
                            className={`card-items`}
                          >
                            <div
                              className="d-flex justify-content-between"
                              onClick={(e) => {
                                this.chosePaymentOption("ebt", true);
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  style={{ transform: "scale(1.5)" }}
                                  checked={
                                    this.state.paymentOption === "ebt" &&
                                    this.state.chooseEBTPayment
                                  }
                                  name="card payment"
                                />
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                  className="ml-2 card-label"
                                >
                                  SNAP (EBT)
                                </TypographyTextColor>
                                <div className="card-items">
                                  <img
                                    src={EBTLOGO}
                                    alt="MASTERCARD"
                                    // className="mr-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.state.EBTcarddata?.length > 0 &&
                      this.state.ebtorderAmount !== 0 &&
                      this.state.isShowEBT
                        ? this.state.EBTcarddata.map((val, i) => {
                            return (
                              <>
                                <Typography
                                  key={i}
                                  component={
                                    this.state.ebtisSelected === val.id
                                      ? TypographyPrimaryBgColorDimmed
                                      : "div"
                                  }
                                  border={0}
                                  className={`card-items ml-5`}
                                >
                                  <div
                                    className="d-flex justify-content-between flex-wrap"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "selectedPaymentoption",
                                        val.id
                                      );
                                      this.handleaddebtCard(
                                        val.token,
                                        false,
                                        false
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        width:
                                          window.screen.availWidth <= 700
                                            ? "100%"
                                            : "",
                                      }}
                                      // className="p-1"
                                    >
                                      <input
                                        type="radio"
                                        style={{ transform: "scale(1.5)" }}
                                        checked={
                                          this.state.ebtisSelected === val.id
                                        }
                                        name="isSelected"
                                      />
                                      <span className="card-label">
                                        <TypographyTextColor
                                          Color="heading"
                                          component="strong"
                                          className="ml-2"
                                        >
                                          SNAP
                                        </TypographyTextColor>
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                          className="mx-3"
                                        >
                                          {val.card_number}
                                        </TypographyTextColor>
                                      </span>
                                    </div>
                                  </div>
                                </Typography>
                              </>
                            );
                          })
                        : ""}
                      {this.state.ebtorderAmount !== 0 ? (
                        <>
                          {this.state.ebtAmount !== 0 ? (
                            <div style={{ top: "10px", position: "relative" }}>
                              <TypographyTextColor
                                Color="other"
                                component="span"
                                className="ml-2 mt-2 card-label"
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  checked={this.state.isebtpayment}
                                  onClick={(e) =>
                                    this.setState({
                                      isebtpayment: !this.state.isebtpayment,
                                    })
                                  }
                                  style={{ transform: "scale(1.5)" }}
                                />
                                EBT balance is ${this.state.ebtAmount}, you can
                                pay{" "}
                                <input
                                  style={{ width: "100px" }}
                                  value={this.state.ebtorderAmount}
                                  // onFocus={(e) => {
                                  //   e.stopPropagation();
                                  //   this.handleSelectCard(
                                  //     val.id,
                                  //     "cvv",
                                  //     e.target.value
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    this.allowOnlyNumericsOrDigits(e);
                                  }}
                                  type="text"
                                  disabled={!this.state.isebtpayment}
                                />
                              </TypographyTextColor>
                              <div style={{ color: "red" }}>
                                * you can only pay $
                                {this.state.originalebtorderAmount}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.is_show_customer_credit &&
                      this.state.creditData?.customerCreditStatus?.hasCredit ? (
                        <div style={{ top: "10px", position: "relative" }}>
                          <Divider className="m-2" />
                          <TypographyTextColor
                            Color="other"
                            component="span"
                            className="ml-2 mt-2 card-label"
                          >
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={this.state.creditData.isPaidByCredit}
                              onClick={() => {
                                let data = this.state.creditData;
                                data.isPaidByCredit = !data.isPaidByCredit;
                                console.log(data);
                                if (data.isPaidByCredit)
                                  this.setState({ paymentOption: "C_Credit" });
                                this.setState({
                                  creditData: data,
                                });
                              }}
                              style={{ transform: "scale(1.5)" }}
                            />
                            Available Credit is $
                            {
                              this.state.creditData?.customerCreditStatus
                                ?.availableCredit
                            }
                            , you can pay{" "}
                            <input
                              style={{ width: "100px" }}
                              value={this.state.creditData.paymentAmount}
                              onChange={(e) => {
                                this.allowOnlyNumericsOrDigitscredits(e);
                              }}
                              type="text"
                              disabled={!this.state.creditData.isPaidByCredit}
                            />
                          </TypographyTextColor>
                        </div>
                      ) : (
                        <></>
                      )}
                      {localStorage.getItem("PaymentOptionVisibility") &&
                      (localStorage.getItem("PaymentOptionVisibility") ===
                        "only_card" ||
                        localStorage.getItem("PaymentOptionVisibility") ===
                          "cod_card") ? (
                        <>
                          <Divider className="my-2 mt-3" />
                          <Typography
                            component={
                              this.state.paymentOption === "card_payment" &&
                              this.state.chooseCardPayment
                                ? TypographyPrimaryBgColorDimmed
                                : "div"
                            }
                            border={0}
                            className={`card-items`}
                          >
                            <div
                              className="d-flex justify-content-between"
                              onClick={(e) => {
                                this.chosePaymentOption("card_payment", true);
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  style={{ transform: "scale(1.5)" }}
                                  checked={
                                    this.state.paymentOption ===
                                      "card_payment" &&
                                    this.state.chooseCardPayment
                                  }
                                  name="card payment"
                                />
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                  className="ml-2 card-label"
                                >
                                  credit or debit card
                                </TypographyTextColor>
                                <div className="card-items">
                                  <img src={VISA} alt="visa" className="mr-2" />
                                  <img
                                    src={MASTERCARD}
                                    alt="MASTERCARD"
                                    className="mr-2"
                                  />
                                  <img src={AMEX} alt="AMEX" className="mr-2" />
                                  <img src={DISCOVER} alt="DISCOVER" />
                                </div>
                              </div>

                              {this.state.chooseCardPayment ===
                                "card_payment" && (
                                <div className="px-5">Enter CVV :</div>
                              )}
                            </div>
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {localStorage.getItem("PaymentOptionVisibility") &&
                      (localStorage.getItem("PaymentOptionVisibility") ===
                        "only_card" ||
                        localStorage.getItem("PaymentOptionVisibility") ===
                          "cod_card") &&
                      this.state.cardData.length > 0 ? (
                        <div className="mb-3">
                          {this.state.cardData.map((val) => {
                            return (
                              <Typography
                                component={
                                  val.isSelected
                                    ? TypographyPrimaryBgColorDimmed
                                    : "div"
                                }
                                border={0}
                                className={`card-items ml-5`}
                              >
                                <div
                                  className="d-flex flex-wrap"
                                  onClick={(e) => {
                                    this.handleSelectCard(val.id, "isSelected");
                                  }}
                                >
                                  <div
                                    style={{
                                      width:
                                        window.screen.availWidth <= 700
                                          ? "100%"
                                          : "",
                                    }}
                                    className="p-1"
                                  >
                                    <input
                                      type="radio"
                                      style={{ transform: "scale(1.5)" }}
                                      checked={val.isSelected === val.id}
                                      name="isSelected"
                                    />
                                    <span className="card-label">
                                      <TypographyTextColor
                                        Color="heading"
                                        component="strong"
                                        className="ml-2"
                                      >
                                        {val.card_type}
                                      </TypographyTextColor>
                                      <TypographyTextColor
                                        Color="other"
                                        component="span"
                                        className="mx-3"
                                      >
                                        {val.card_number}
                                      </TypographyTextColor>
                                    </span>
                                    {/* <div> */}
                                    <img
                                      src={
                                        val.card_type === "Visa"
                                          ? VISA
                                          : val.card_type === "MasterCard"
                                          ? MASTERCARD
                                          : val.card_type === "Amex"
                                          ? AMEX
                                          : DISCOVER
                                      }
                                      class="card-img"
                                      alt=""
                                    />
                                    {val.isauthorize && (
                                      <MdVerifiedUser
                                        style={{
                                          color: "#008000",
                                          fontSize: "20px",
                                        }}
                                      />
                                    )}
                                    {/* </div> */}
                                    <TypographyTextColor
                                      Color="other"
                                      component="span"
                                      className="ml-1"
                                    >
                                      {(this.state.isebtpayment &&
                                        this.state.ebtAmount !== 0) ||
                                      (this.state.is_show_customer_credit &&
                                        this.state.creditData.isPaidByCredit)
                                        ? "($ " +
                                          Math.abs(
                                            this.state.total_price +
                                              this.state.tips_amount -
                                              ((this.state.isebtpayment
                                                ? parseFloat(
                                                    this.state.ebtorderAmount
                                                  )
                                                : 0) +
                                                (this.state.creditData
                                                  .isPaidByCredit &&
                                                this.state.creditData
                                                  ?.paymentAmount !== ""
                                                  ? parseFloat(
                                                      this.state.creditData
                                                        ?.paymentAmount
                                                    )
                                                  : 0))
                                          ).toFixed(2) +
                                          ")"
                                        : ""}
                                    </TypographyTextColor>
                                  </div>
                                  <TypographyTextColor
                                    Color="other"
                                    component="div"
                                    style={{
                                      alignSelf: "center",
                                      marginLeft:
                                        window.screen.availWidth <= 700
                                          ? "25px"
                                          : "",
                                    }}
                                  >
                                    {val?.card_holder_name === null
                                      ? "Name"
                                      : val.card_holder_name}
                                  </TypographyTextColor>
                                </div>
                                {val.isSelected && (
                                  <TypographyTextColor
                                    Color="other"
                                    component="div"
                                    className="px-5"
                                  >
                                    Enter CVV :{" "}
                                    <input
                                      name="cvv"
                                      style={{ width: "100px" }}
                                      value={val.cvv}
                                      onFocus={(e) => {
                                        e.stopPropagation();
                                        this.handleSelectCard(
                                          val.id,
                                          "cvv",
                                          e.target.value
                                        );
                                      }}
                                      onChange={(e) => {
                                        this.handleSelectCard(
                                          val.id,
                                          "cvv",
                                          e.target.value
                                        );
                                      }}
                                      type="text"
                                      min-length="3"
                                      max-length="4"
                                      autoComplete={false}
                                    />
                                  </TypographyTextColor>
                                )}
                              </Typography>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* <Divider className="mt-2" />
                       */}

                      {localStorage.getItem("PaymentOptionVisibility") &&
                      (localStorage.getItem("PaymentOptionVisibility") ===
                        "no_option" ||
                        localStorage.getItem("PaymentOptionVisibility") ===
                          "cod_card") ? (
                        <>
                          {" "}
                          <Divider className="my-2" />
                          <Typography
                            component={
                              (this.state.paymentOption === "cash_payment" ||
                                this.state.paymentOption === "pickup_pay") &&
                              this.state.chooseCashPayment
                                ? TypographyPrimaryBgColorDimmed
                                : "div"
                            }
                            border={0}
                            className={`card-items`}
                          >
                            <div
                              className="d-flex justify-content-between"
                              onClick={(e) => {
                                this.chosePaymentOption("cash_payment", false);
                              }}
                            >
                              <div
                                style={{
                                  width:
                                    window.screen.availWidth <= 700
                                      ? "100%"
                                      : "",
                                }}
                                className="p-1"
                              >
                                <input
                                  type="checkbox"
                                  style={{ transform: "scale(1.5)" }}
                                  checked={
                                    (this.state.paymentOption ===
                                      "cash_payment" ||
                                      this.state.paymentOption ===
                                        "pickup_pay") &&
                                    this.state.chooseCashPayment
                                  }
                                  name="cash payment"
                                />
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                  className="mx-2 card-label"
                                >
                                  Cash on delivery&nbsp;
                                  <BsCashStack
                                    style={{
                                      fontSize: "2.5rem",
                                      color: "green",
                                    }}
                                  />
                                  <span className="ml-1">
                                    {" "}
                                    {(this.state.isebtpayment &&
                                      this.state.ebtAmount !== 0) ||
                                    (this.state.is_show_customer_credit &&
                                      this.state.creditData.isPaidByCredit)
                                      ? "($ " +
                                        Math.abs(
                                          this.state.total_price +
                                            this.state.tips_amount -
                                            ((this.state.isebtpayment
                                              ? parseFloat(
                                                  this.state.ebtorderAmount
                                                )
                                              : 0) +
                                              (this.state.creditData
                                                .isPaidByCredit &&
                                              this.state.creditData
                                                ?.paymentAmount !== ""
                                                ? parseFloat(
                                                    this.state.creditData
                                                      ?.paymentAmount
                                                  )
                                                : 0))
                                        ).toFixed(2) +
                                        ")"
                                      : ""}
                                  </span>
                                </TypographyTextColor>
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <OrderSummary
                  pageName={this.state.pageName}
                  history={this.props}
                  deliveryOption={
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.delivery_option
                      ? this.props.history.location.state.delivery_option
                      : ""
                  }
                  deliveryType={
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.delivery_type
                      ? this.props.history.location.state.delivery_type
                      : ""
                  }
                  deliveryDatetime={
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.delivery_datetime
                      ? this.props.history.location.state.delivery_datetime
                      : ""
                  }
                  paymentOption={this.state.paymentOption}
                  RefreshApiToken={(refreshToken) =>
                    this.RefreshApiToken(refreshToken)
                  }
                  addressShowHide={this.state.addressShowHide}
                  xToken={this.state.xToken}
                  xTokenType={this.state.xTokenType}
                  cardCVV={this.state.cardCVV}
                  cardYear={this.state.cardYear}
                  cardMonth={this.state.cardMonth}
                  CVVToken={this.state.CVVToken}
                  xKey={this.state.account.xKey}
                  xSoftwareName={this.state.account.xSoftwareName}
                  xSoftwareVersion={this.state.account.xSoftwareVersion}
                  cardDetailData={this.state.cardDetailData}
                  selectedCardData={this.state.selectedCardData}
                  handleCloseCardPopup={() => this.handleCloseCardPopup()}
                  onCardHandleChange={(e) => this.onCardHandleChange(e)}
                  cardType={this.state.cardType}
                  isNewcard={this.state.isNewcard}
                  ebtorderAmount={
                    this.state.isebtpayment ? this.state.ebtorderAmount : 0
                  }
                  ebtcardNumber={this.state.ebtcardNumber}
                  handleEBTcardno={(e) => this.handleEBTcardno(e)}
                  isebtCardSave={this.state.isebtCardSave}
                  handleaddebtCard={(cardnumber, iscardsave) =>
                    this.handleaddebtCard(cardnumber, iscardsave)
                  }
                  handleTipAmount={(amount) => this.handleTipAmount(amount)}
                  isebtpayment={this.state.isebtpayment}
                  customercreditData={this.state.creditData}
                  cartListIte={this.state.cartListIte}
                />
              </div>
            </section>
            <Footer emailSubscribe={this.state.emailSubscribe} />
          </div>
        )}
        {this.state.success === false && this.state.error === false ? (
          ""
        ) : (
          <Commonsnackbar
            success={this.state.success}
            handleClose={this.handleClose}
            error={this.state.error}
          />
        )}
        <form
          action="https://test.paysecure.acculynk.net/PINpad"
          method="post"
          style={{ display: "none", opacity: 0 }}
        >
          <label>AccuId:</label>
          <input
            type="text"
            id="AccuId"
            name="AccuId"
            value={this.state.xAccuID}
          />
          <label>AccuReturnURL:</label>
          <input
            type="text"
            id="AccuReturnURL"
            name="AccuReturnURL"
            value={this.state.xPinPadURL}
          />
          <label>AccuLanguage:</label>
          <input
            type="text"
            id="AccuLanguage"
            name="AccuLanguage"
            value="en-US"
          />
          <input type="submit" value="Submit" id="ebtsubmitbtn" />
        </form>
      </ScrollColorTypography>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "PaymentPage" })(PaymentPage)
);
