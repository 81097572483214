import React from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import ItemDetailsModal from "./common/ProductDetail/item-details-modal";
import Subscribe from "./common/Footer/subscribe";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoderImage from "../assets/images/more.png";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  GetWishListFunctionlity,
  ProductDetailsApi,
  AddToCartFunctionlity,
  UpdateCartFunctionlity,
  WishListFunctionlity,
  CategeriesListView, 
  ViewCartCount
} from "../actions/home/homeAction";
import { DeleteCrtIemFunctionlity } from "../actions/cart/cartAction";
import { GetSettingsData } from "../actions/profile/profileAction";
import { RefreshApiToken, sweetAlertBox } from "../utils/renderUtils/renderUtil";
class Wishlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      itemDetails: false,
      getWishList: [],
      ProductCartArray: [],
      ProductDetails: [],
      next_info: false,
      pre_info: false,
      extra_info: false,
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      pageName: "wishlist_page",
      mobileViewHide: true,
      emailSubscribe:'',
      chack_is_case: [],
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handlePopOpen = this.handlePopOpen.bind(this);
    this.handlePopClose = this.handlePopClose.bind(this);
  }

  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  handlePopOpen(e, value, next_info, pre_info) {
    this.setState({ itemDetails: true });
    this.FetchProdutDetails(value, next_info, pre_info);
  }
  handlePopClose() {
    this.setState({ itemDetails: false });
  }
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
    
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
        
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
     this.setState({emailSubscribe:settingData})
    });
  };

  componentDidMount() {
    //document.title = "Wishlist Page";
    window.scrollTo(0, 0);
    this.FetchSettingData()
    this.FetchWishList();
    this.FetchCategoryList();
    this.FetchCartCount();
  }

  FetchWishList = () => {
    let formValues = {
      limit: 7,
    };
    GetWishListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let getWishList = [];
      if (res && res.result) {
        getWishList = res.result;
      }
      this.setState({ getWishList: getWishList });
    });
  };

  FetchCategoryList = () => {
    let formValues = {};
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
      }
      this.setState({ CategoryDetails: categoryList });
    });
  };

  FetchCartCount = () => {
    let formValues = {};
    this.setState({ cartLoader: true });
    ViewCartCount(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      if (res.data && res.data.result) {
        CartCount = res.data.result;
      }
      this.setState({ CartCount: CartCount });
      this.setState({ cartLoader: false });
    });
  };

  FetchProdutDetails = (product_id, next_info, pre_info) => {
    let formValues = {
      product_id: product_id,
      extra_info: this.state.extra_info,
      next_info: next_info,
      pre_info: pre_info,
    };
    ProductDetailsApi(formValues, this.props.dispatch).then((res) => {
      let productDetails = [];
      if (res.data && res.data.result) {
        productDetails = res.data.result;
        this.setState({ ProductDetails: productDetails });
      }
    });
  };

  openAddCount = (product_id, added_quantity, is_case, addTocartQty = 1) => {
    added_quantity = parseInt(added_quantity);
    addTocartQty = parseInt(addTocartQty);
    if(added_quantity > 0 && addTocartQty === 0) {
      this.AddToCartFunctionlity(product_id, added_quantity, is_case)
    } else if(added_quantity > 0 && addTocartQty >= 1) {
      this.UpdateCartItemFunctionlity(product_id, added_quantity, is_case)
    } else {
      this.deleteCartItem(product_id,added_quantity, is_case)
    }
  };
  

  AddToCartFunctionlity = (
    product_id,
    added_quantity,
    is_case
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: 1,
      is_case: is_case,
    };
    let getWishList = this.state.getWishList;
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if(res.errors && res.errors.data) {
        this.createSuccessMsg('error',res.errors.data.errors)
      } else {
        this.FetchCartCount();
        var Proind = getWishList.findIndex(
          (p) => p.id === product_id
        );
        if(Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd = getWishList[Proind]['added_quantity_is_case'].indexOf(is_case)
          if(added_qtyInd > -1){
            getWishList[Proind]['added_quantity'][added_qtyInd] = added_quantity
          } else {
            getWishList[Proind]['added_quantity'].push(added_quantity)
            getWishList[Proind]['added_quantity_is_case'].push(is_case)
          }
        } 
      }
      this.setState({getWishList})
    });
  };

  UpdateCartItemFunctionlity = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
    };
    let getWishList = this.state.getWishList;
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.data){
        this.createSuccessMsg('error',res.errors.data.errors)
      } else {
        var Proind = getWishList.findIndex(
          (p) => p.id === product_id
        );
        if(Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd = getWishList[Proind]['added_quantity_is_case'].indexOf(is_case)
          if(added_qtyInd > -1){
            getWishList[Proind]['added_quantity'][added_qtyInd] = added_quantity
          } else {
            getWishList[Proind]['added_quantity'].push(added_quantity)
            getWishList[Proind]['added_quantity_is_case'].push(is_case)
          }
        } 
        this.FetchCartCount();
      }
      this.setState({ getWishList});
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      is_case:is_case,
    };
    let getWishList = this.state.getWishList;
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        var Proind = getWishList.findIndex(
          (p) => p.id === product_id
        );
        if(isNaN(added_quantity)) {
          added_quantity = 0
        }
        if(Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd = getWishList[Proind]['added_quantity_is_case'].indexOf(is_case)
          if(added_qtyInd > -1){
            getWishList[Proind]['added_quantity'][added_qtyInd] = added_quantity
          } else {
            getWishList[Proind]['added_quantity'].push(0)
            getWishList[Proind]['added_quantity_is_case'].push(is_case)
          }
        } 
        this.FetchCartCount();
        this.setState({getWishList})
      }
    });
  };

  ProductCaseFunctionlity = (product_id, is_case) => {
    let chack_is_case = this.state.chack_is_case;
    chack_is_case[product_id] = is_case
    this.setState({chack_is_case : chack_is_case})
  };

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {
      
      if (res) {
        this.FetchWishList();
      }
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox('Attention!!',msg.quantity[0],"info")
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } 
    } 
  };

  render() {
    const {
      getWishList,
      ProductCartArray,
      ProductDetails,
      CategoryDetails,
      CartCount,
    } = this.state;
    return (
      <div className="main-content">
        <Header
        FetchTopMenuDeatilsHome={this.FetchTopMenuDeatils}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartCount = {()=>this.FetchCartCount()}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        <div className="main-data">
          <section className="user_details">
            <div className="user_main_wrapper">
              <div className="menuOuter">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  openDetailsMenu={() => this.openDetailsMenu()}
                />
              </div>
              <div className="user_details_desc order_main_wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cust_head_main cust_hide">
                      <h4>
                        <ArrowBackIosIcon
                          onClick={() => this.closeDetailsMenu()}
                          className="d-lg-none"
                        />{" "}
                        Wishlist
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="items-details wishlist-details">
                      {getWishList && getWishList.length > 0 ? (
                        getWishList.map((row, index) => {
                          var c_i_case = this.state.chack_is_case[row.id]? this.state.chack_is_case[row.id]: false;
                          var check_added_quantity_is_case = row.added_quantity_is_case.indexOf(c_i_case); 
                          var check_is_case_flag= false;
                          if(check_added_quantity_is_case > -1) {
                            check_is_case_flag = true;
                          } else {
                            check_is_case_flag = true;
                            row.added_quantity_is_case.push(c_i_case)
                            row.added_quantity.push(0)
                            check_added_quantity_is_case = row.added_quantity_is_case.indexOf(c_i_case)
                          }
                          return (
                            <div className="items">
                              {row.offer_tag !== 0 ? (
                                <div className="items-discount">
                                  {row.offer_tag > 0
                                    ? row.offer_tag + "% OFF"
                                    : 0 + "% OFF"}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="likecart">
                                <Link
                                  to
                                  onClick={() =>
                                    this.AddWishlistFunctionility(row.id, false)
                                  }
                                >
                                  <span className="icon-close"></span>
                                </Link>
                              </div>
                              <div className="imgbox">
                                <img src={NO_IMAGE} alt="" />
                              </div>
                              <div className="item_details">
                                <Link to>
                                  <div
                                    onClick={(e) =>
                                      this.handlePopOpen(
                                        this,
                                        row.id,
                                        false,
                                        false
                                      )
                                    }
                                  >
                                    <div className="textbox">
                                      <p>{row.name}</p>
                                    </div>
                                    <div className="items-type">
                                      <p>{row.package}</p>
                                    </div>
                                    <div className="price">
                                      {row.unit !== '' ? row.unit : '$'}
                                      {row.added_quantity_is_case[check_added_quantity_is_case] === true
                                        ? Number(row.case_price).toFixed(2)
                                        : Number(row.today_price).toFixed(2)}{" "}
                                      {row.is_offer === true ? (
                                        <span>
                                          {row.unit !== '' ? row.unit : '$'}
                                          {Number(row.original_price).toFixed(2)}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {check_is_case_flag && row.added_quantity[check_added_quantity_is_case] ? <div className="casetext"><p><span className="icon-supermarket cat_icn"></span> {row.added_quantity[check_added_quantity_is_case]}</p></div> : ''}
                                    </div>
                                  </div>
                                </Link>
                                <div>
                                  {row.is_case === false && row.added_quantity[check_added_quantity_is_case] === 0? (
                                    <div className="add_tocart">
                                      <button
                                        onClick={this.openAddCount.bind(
                                          this,
                                          row.id,
                                          row.added_quantity[check_added_quantity_is_case] + 1,
                                          row.added_quantity_is_case[check_added_quantity_is_case],
                                          row.added_quantity[check_added_quantity_is_case],
                                        )}
                                      >
                                        Add To Cart
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="add-count">
                                      <div className=" textrow">
                                        <div className="count_box">
                                          <button
                                            className="count_btn"
                                            onClick={this.openAddCount.bind(
                                              this,
                                              row.id,
                                              row.added_quantity[check_added_quantity_is_case] - 1,
                                              row.added_quantity_is_case[check_added_quantity_is_case],
                                              row.added_quantity[check_added_quantity_is_case]
                                            )}
                                            disabled={this.state.disabled}
                                          >
                                            {this.state.disabled ? (
                                              <img className="loaderImg" src={LoderImage} alt="" />
                                            ) : (
                                              <span className="icon-minus"></span>
                                            )}
                                          </button>
                                          <div className="count_no">
                                          <input type="text" 
                                            value={row.added_quantity[check_added_quantity_is_case] > 0? row.added_quantity[check_added_quantity_is_case]: 0}
                                            onChange={(e) => this.openAddCount(row.id, e.target.value, row.added_quantity_is_case[check_added_quantity_is_case])}
                                          />
                                          </div>
                                          <button
                                              className="count_btn"
                                              onClick={this.openAddCount.bind(
                                                this,
                                                row.id,
                                                row.added_quantity[check_added_quantity_is_case] + 1,
                                                row.added_quantity_is_case[check_added_quantity_is_case],
                                                row.added_quantity[check_added_quantity_is_case]
                                              )}
                                              disabled={this.state.disabled}
                                            >
                                              {this.state.disabled ? (
                                                <img className="loaderImg" src={LoderImage} alt="" />
                                              ) : (
                                                <span className="icon-plus"></span>
                                              )}
                                            </button>
                                        </div>
                                        {row.is_case === true ? (
                                          <div className="case-cnt">
                                            <span>
                                              <input
                                                type="checkbox"
                                                name="vehicle1"
                                                checked={
                                                    row.added_quantity_is_case[check_added_quantity_is_case] ? 
                                                    true
                                                    : false
                                                }
                                                onChange={(event) =>
                                                  this.ProductCaseFunctionlity(
                                                    row.id,
                                                    event.target.checked
                                                  )
                                                }
                                              />
                                              <label for="vehicle1">Case <span className="ml-1">{row.case_quantity}</span></label>
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="empty_wishlist">
                          <h5>
                            Your Wishlist Is Empty. Please Click On Heart Icon
                            To Add Product In Wishlist
                          </h5>
                          <div className="bttn-fill w-25 m-0 m-auto">
                            <Link
                              to="/listing"
                              className="bttn_grn wislist_continue_btn"
                            >
                              Continue Shopping
                            </Link>
                          </div>
                        </div>
                      )}
                      <ItemDetailsModal
                        open={this.state.itemDetails}
                        handlePopClose={this.handlePopClose}
                        ProductDetails={ProductDetails}
                        preProductItem={(product_id, pre_info, next_info) =>
                          this.preProductItem(product_id, pre_info, next_info)
                        }
                        nextProductItem={(product_id, pre_info, next_info) =>
                          this.nextProductItem(product_id, pre_info, next_info)
                        }
                        productDetailsLoader={this.state.productDetailsLoader}
                        history={this.props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer emailSubscribe={this.state.emailSubscribe}/>
        </div>
      </div>
    );
  }
}

export default Wishlist;
