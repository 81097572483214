import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FooterManagementApi,
  GuestLoginAthentication,
  FooterPageListApi,
} from "../../../actions/home/homeAction";
import {
  commonPageTitel,
  RefreshApiToken,
} from "../../../utils/renderUtils/renderUtil";
import FooterMobile from "./Mobile/FooterMobile";
import "../../../assets/css/footer.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Typography } from "@mui/material";
import {
  TypographyPrimaryBgColorDimmed,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../UiDesign";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      addclassName: false,
      isLogin: false,
      open: false,
      open_inner: false,
      open_subDetails: false,
      openSearch: false,
      openCategory: false,
      footerDetails: [],
      storeData: [],
      expanded: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
  }
  componentDidMount() {
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    if (accessToken !== "undefined") {
     
    }
    if (isLogin === "false") {
      // setupBeforeUnloadListenerClearCache();
    }

    this.FetchHomeFooterDetails();
  }

  FetchHomeFooterDetails = () => {
    
    let formValues = {};
    FooterManagementApi(formValues, this.props.dispatch).then((res) => {
      let final_Data = [];
      let store_Data = [];

      if (res.errors) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchHomeFooterDetails();
            }
          );
        } else {
          this.HitGuestApiToken();
        }
      } else if (res.data && res.data.result) {
        let respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            if (final_Data.length === 0) {
              let mydata = {
                title: respone[i]["title"],
                items: [respone[i]],
              };
              final_Data.push(mydata);
            } else {
              var ind = final_Data.findIndex(
                (p) => p.title === respone[i]["title"]
              );
              if (ind > -1) {
                final_Data[ind]["items"].push(respone[i]);
              } else {
                let mydata = {
                  title: respone[i]["title"],
                  items: [respone[i]],
                };
                final_Data.push(mydata);
              }
            }
          }
        }
      }
      if (res.data && res.data.sdata) {
        store_Data = res.data.sdata;
      }

      this.setState({
        footerDetails: final_Data,
        storeData: store_Data,
      });
    });
  };
  phoneFormat = (input) => {
    if (!input || isNaN(input))
      return `input must be a number was sent ${input}`;
    if (typeof input !== "string") input = input.toString();
    if (input.length === 10) {
      return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (input.length < 10) {
      return "was not supplied enough numbers please pass a 10 digit number";
    } else if (input.length > 10) {
      return "was supplied too many numbers please pass a 10 digit number";
    } else {
      return "something went wrong";
    }
  };

  HitGuestApiToken = () => {
    let formValues = {};
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
     
      this.FetchHomeFooterDetails();
    });
  };

  render() {
    const { footerDetails, expanded,web_customize } = this.state;
    return (
      <footer className={`${this.props?.className}`} style={{paddingBottom:web_customize !== null &&
        web_customize?.top_marquee !== null &&
        web_customize?.top_marquee !== "" &&window.screen.availWidth <= 900&&window.location.pathname===
        "/"?'6.5rem':'' }}>
        <Typography component={TypographyPrimaryBgColorDimmed}>
          <div className="footer-wrap">
            
            <div className="mobi-footer-drop">
              <FooterMobile
                footerDetails={footerDetails}
                storeData={this.state.storeData}
                phoneFormat={(val) => this.phoneFormat(val)}
              />
            </div>
            <div className="second_className">
              <div className="second_className_bdr">
                <div
                  className="row justify-content-center md-footer-size"
                  style={{ padding: "0 3rem" }}
                >
                  {footerDetails && footerDetails.length > 0
                    ? footerDetails.map(function (row, index) {
                        return (
                          <>
                            {row.title !== "social_media" ? (
                              <div className="col-md-2 col-sm-6">
                                <TypographyTextColor
                                  component="h3"
                                  Color="heading"
                                >
                                  {" "}
                                  {row.title === "about_company"
                                    ? "ABOUT COMPANY"
                                    : row.title === "news_media"
                                    ? "NEWS & MEDIA"
                                    : row.title === "our_policies"
                                    ? "OUR POLICIES"
                                    : row.title === "gift_cards"
                                    ? "GIFT CARDS"
                                    : ""}
                                </TypographyTextColor>
                                <ul className="footer-links">
                                  {row.items && row.items.length > 0
                                    ? row.items.map(function (item, key) {
                                        return item.page_type ===
                                          "external_page" ? (
                                          <li>
                                            <KeyboardArrowRightIcon
                                              style={{ color: "#878787" }}
                                            />{" "}
                                            
                                            <Link to={item.url}>
                                            <TypographyTextFontFamily type="other" component={"span"} >
                                            {item.name}
                                            </TypographyTextFontFamily>
                                            </Link>
                                          </li>
                                        ) : (
                                          <li>
                                            <KeyboardArrowRightIcon
                                              style={{ color: "#878787" }}
                                            />{" "}
                                            <Link to={`/page/${item.url}`}>
                                            <TypographyTextFontFamily type="other" component={"span"} >
                                            {item.name}
                                            </TypographyTextFontFamily>
                                            </Link>
                                          </li>
                                        );
                                      })
                                    : ""}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </div>
              
              </div>
            </div>

            <div className="row">
              <div className="container-fluid">
                <TypographyTextFontFamily type="other" className="copyright">
                  {" "}
                  © {new Date().getFullYear()} POS WITH LOGIC. All rights reserved.
                </TypographyTextFontFamily>
              </div>
            </div>
          </div>
        </Typography>
      </footer>
    );
  }
}

export default Footer;
