import React, { Component } from "react";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import CategoriesModal from "./common/Category/categoriesModal";

import { CartListFunctionlity } from "../actions/cart/cartAction";
import {
  commonPageTitel,
  RefreshApiToken,
  getSchedule_zip,
  slugifyStr,
  checkImageUrls,
  SinglecheckImageUrls,
} from "../utils/renderUtils/renderUtil";
import {
  FooterPageListApi,
  CategeriesListView,
  GuestLoginAthentication,
} from "../actions/home/homeAction";
import { RefreshLogin } from "../actions/login/loginAction";
import { GetSettingsData } from "../actions/profile/profileAction";
import { ScrollColorTypography } from "./common/UiDesign";
import ApiUrls from "../utils/apiUtils/apiUrl";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
class CommonManagementSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CartCount: [],
      PageContent: [],
      cmscartItemList: [],
      open: false,
      cartLoader: true,
      CategoryDetails: [],
      CategoryOption: [],
      logo: "",
      emailSubscribe: "",
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, "").then((res) => {
      
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    //;
    let logo = this.state.logo;
    const accessTokenForLogo =
      accessToken !== "undefined"
        ? JSON.parse(localStorage.getItem("access_token"))
        : "";

    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    let footer_pagename = "";
    if (this.props.match && this.props.match.params) {
      footer_pagename = this.props.match.params.id;
      if (accessToken && isLogin === "false") {
        this.HitGuestApiToken(footer_pagename);
      } else if (accessToken && isLogin === "true") {
        this.FetchFooterPageApiDetails(footer_pagename);
        this.FetchCategoryList();
        this.FetchCartItemListForHeader();
        this.FetchSettingData();
      } else {
        this.HitGuestApiToken(footer_pagename);
      }
    }

    this.setState({ logo: logo });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.FetchFooterPageApiDetails(this.props.match.params.id);
      window.scrollTo(0, 0);
    }
  }

  HitGuestApiToken = (footer_pagename) => {
    let formValues = {};
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));

      this.FetchFooterPageApiDetails(footer_pagename);
      this.FetchCategoryList();
      this.FetchCartItemListForHeader();
    });
  };
  FetchCartItemListForHeader = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let cmscartItemList = [];
      let CartCount = [];
      if (res.data && res.data.result) {
        const result = res.data.result;
        if (result.length > 0) {
          // const promises = result.map((ele) => {
          //   let localUrl = ele?.cloudinary_images_local;
          //   let globalUrl = ele?.cloudinary_images_global; //`${CLOUD_URL}global/product/${slugifyStr(
          //   let currentUrl =
          //     ele.image !== null
          //       ? ele.image[0] === "/"
          //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
          //         : ele.image
          //       : NO_IMAGE;

          //   return checkImageUrls(localUrl, globalUrl, currentUrl).then(
          //     (url) => {
          //       ele.image = url.url;
          //       ele.valid = url.valid;
          //       return ele;
          //     }
          //   );
          // });

          // Promise.all(promises).then((updatedCartItems) => {
            result.forEach((item) => {
              cmscartItemList.push(item);
              SubTotal += Number(item.total_price);
            });

            if (res.data.total > 0) {
              CartCount.item = res.data.total;
              CartCount.price = Number(res.data.total_subtotal).toFixed(2);
            }

            this.setState({
              cmscartItemList: cmscartItemList,
              SubTotal: SubTotal.toFixed(2),
              cartListOpen: true,
              CartCount: CartCount,
              cartLoader: false,
            });
          // });
          for (let i = 0; i < result.length; i++) {
            cmscartItemList.push(result[i]);
            SubTotal += Number(result[i].total);
          }
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchCartItemListForHeader();
              }
            );
          }
        }
      }
      this.setState({
        cartLoader: false,
      });
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image =ele?.cloudinary_cat_images_global
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  FetchFooterPageApiDetails = (slug) => {
    let formValues = {
      slug: slug,
    };
    FooterPageListApi(formValues, this.props.dispatch).then((res) => {
      let PageContent = [];
      if (res.data) {
        PageContent = res.data.result;
      }
      console.log("PageContent", PageContent);
      document.title =
        PageContent?.meta_title !== null && PageContent?.meta_title !== ""
          ? PageContent?.meta_title
          : commonPageTitel(JSON.parse(localStorage.getItem("access_token")));
      this.setState({
        PageContent: PageContent,
      });
    });
  };

  handleOpenDialog() {
    this.setState({ open: true });
  }

  handleCloseDialog() {
    this.setState({ open: false });
  }

  render() {
    const { CategoryDetails, CategoryOption, cmscartItemList, SubTotal } =
      this.state;
    return (
      <ScrollColorTypography component="div" className="main-content">
        <Header
          CategoryDetails={
            CategoryDetails && CategoryDetails.length > 0 ? CategoryDetails : ""
          }
          CategoryOption={CategoryOption}
          cartItemList={cmscartItemList}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={this.state.CartCount}
          FetchCartCount={() => this.FetchCartItemListForHeader()}
          cartLoader={this.state.cartLoader}
          headerinputField={this.state.headerinputField}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index,
            container_type
          ) =>
            this.AddCountForHomecartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            )
          }
          SubTotal={SubTotal}
          changeheaderinputFieldValue={() => this.changeheaderinputFieldValue()}
          logo={this.state.logo}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        <div class="main-data">
          <div className="cms-container">
            {" "}
            <div className="cms-header">{this.state.PageContent.name}</div>
            <div className="cms-body">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.PageContent.content,
                }}
              ></p>
            </div>
          </div>
        </div>
        <Footer emailSubscribe={this.state.emailSubscribe} />
      </ScrollColorTypography>
    );
  }
}

export default CommonManagementSystem;
