import React, { useEffect, useState } from "react";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { TypographyPrimaryBgColorDimmed } from "../UiDesign";
import { AiOutlineHistory, AiFillDelete, AiOutlineClose } from "react-icons/ai";
const RecentSearchTab = (props) => {
  const [open, setOpen] = React.useState(false);
  let storeName = JSON.parse(
    localStorage.getItem("access_token")
  )?.sname?.replace(/\s/g, "");
  const [recent, setRecent] = useState(
    localStorage.getItem(`recent${storeName}`)
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    if (recent !== undefined && recent !== null) fetchData();
  }, [recent === undefined && recent === null ? "" : recent?.length]);

  const fetchData = () => {
    let recentData = localStorage.getItem(`recent${storeName}`);
    console.log(recentData);
    if(recentData===null){
setRecent('')
setData([])
    }
    else{
    setRecent(recentData);
    setData(recentData.split("@%").splice(0,10));
    }
  };

  const handleClick = (name) => {
    props.searchProductByName(name);
  };
  const handleDelete = (name) => {
    let storeName = JSON.parse( 
      localStorage.getItem("access_token")
    )?.sname?.replace(/\s/g, "");
    let recentData = localStorage.getItem(`recent${storeName}`);
    let arryData = recentData.split("@%");
    let indx = -1;
    for (let i = 0; i < arryData.length; i++) {
      if (name.toLowerCase() === arryData[i].toLowerCase()) {
        indx = i;
        break;
      }
    }
    if (indx !== -1) {
      arryData.splice(indx, 1);
      let data = arryData.join("@%");
      if (data === "") localStorage.removeItem(`recent${storeName}`);
      else localStorage.setItem(`recent${storeName}`, data);
      fetchData();
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <div>
        {data.length > 0 ? (
          data.map((val, i) => (
            <div
              key={i}
              className="recent-item"
              onClick={() => handleClick(val)}
            >
              <span><AiOutlineHistory/><span className="ml-1">{val}</span></span>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(val);
                }}
                size="small"
              >
                <span className="icon-close" style={{fontSize:'15px'}}></span>
              </IconButton>
            </div>
          ))
        ) : (
          ""
        )}
      </div>
     
    </>
  );
};
// recent !== undefined && recent !== null ? (
//   <TypographyPrimaryBgColorDimmed
//     component="div"
//     border={0}
//     dimmedRatio={150}
//     className="recent-search-container"
//   >
//     <span className="label">Recent Search</span>
//     {data.map((val, i) => (
//       <Chip
//         label={val}
//         variant="outlined"
//         key={i}
//         style={{ background: "#fff", fontSize: "15px" }}
//         onClick={() => handleClick(val)}
//         onDelete={() => handleDelete(val)}
//       />
//     ))}
//   </TypographyPrimaryBgColorDimmed>
// ) : (
//   <></>
// );
export default RecentSearchTab;
