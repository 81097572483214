import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import ApiUrls from "../utils/apiUtils/apiUrl";
import { OrderSuccessDetailsApi } from "../actions/order/orderAction";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
} from "../actions/home/homeAction";
import {
  GetProfileDetails,
  GetSettingsData,
} from "../actions/profile/profileAction";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import {
  formatPhoneNumberCommon,
  postfullTimeFormat,
  sendDateFormat,
  getSchedule_zip,
  RefreshApiToken,
  addDefaultImageSrc,
  checkImageUrls,
  refineImgurl,
} from "../utils/renderUtils/renderUtil";
import {
  ScrollColorTypography,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "./common/UiDesign";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      orderDetails: [],
      deliveryAddress: [],
      CategoryDetails: [],
      order: [],
      pageName: "order_details",
      OrderStatus: [
        {
          name: "pending",
          order_status: false,
          status_date: "",
        },
        {
          name: "packed",
          order_status: false,
          status_date: "",
        },
        {
          name: "shipped",
          order_status: false,
          status_date: "",
        },
        {
          name: "delivered",
          order_status: false,
          status_date: "",
        },
      ],
      order_id: 0,
      CategoryOption: [],
      CartCount: [],
      orderDetailsItemList: [],
      logo: "",
      profileDetails: [],
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      emailSubscribe: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }

  FetchOrderDetails = (order_id) => {
    let guest_id = localStorage.getItem("Guest_userid");
    let formValues = {
      order_id: order_id,
      guest_id: guest_id === null ? 0 : guest_id,
      is_guest: guest_id !== null,
    };

    OrderSuccessDetailsApi(formValues, this.props.dispatch).then((res) => {
      let orderDetails = [];
      let OrderStatus = this.state.OrderStatus;
      let setOrderStatus = [];
      console.log(res);
      if (
        res.data &&
        res.data.result &&
        res.data.result.order_status.length > 0
      ) {
        orderDetails = res.data.result;
        setOrderStatus = orderDetails.order_status;
        // const promises = orderDetails?.other_details?.map((ele) => {
        //   let localUrl = ele?.cloudinary_images_local;
        //   let globalUrl = ele?.cloudinary_images_global;
        //   let currentUrl =
        //     ele.image !== null
        //       ? ele.image[0] === "/"
        //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
        //         : ele.image
        //       : NO_IMAGE;

        //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     ele.image = url.url;
        //     ele.valid = url.valid;
        //     return ele;
        //   });
        // });

        // Promise.all(promises).then((updatedCartItems) => {
          for (let i = 0; i < setOrderStatus.length; i++) {
            var FindIndex = OrderStatus.findIndex(
              (p) => p.name === setOrderStatus[i].name
            );
            if (FindIndex >= 0) {
              OrderStatus[FindIndex]["order_status"] =
                setOrderStatus[i].order_status;
              OrderStatus[FindIndex]["status_date"] =
                setOrderStatus[i].status_date;
            } else {
              OrderStatus.splice(i, 0, setOrderStatus[i]);
            }
          }
          this.setState({
            orderDetails: orderDetails.other_details,
            OrderStatus: OrderStatus,
            deliveryAddress: orderDetails.delivery_address,
            order: orderDetails.order,
          });
        // });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchOrderDetails(order_id);
            }
          );
        }
      }
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let CategoryDetails = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        CategoryDetails = res.data.result;
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < CategoryDetails.length; i++) {
        CategoryOption.push({
          label: CategoryDetails[i].name,
          value: CategoryDetails[i].id,
        });
      }
      this.setState({ CategoryDetails: CategoryDetails, CategoryOption });
    });
  };

  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  componentDidMount() {
    //;
    window.scrollTo(0, 0);
    let order_id = 0;
    let logo = this.state.logo;
    document.body.style.overflow = "hidden";
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    if (this.props && this.props.location && this.props.location.state) {
      order_id = this.props.location.state.order_id;
    }
    this.FetchSettingData();
    this.FetchOrderDetails(order_id);
    this.FetchCategoryList();
    this.FetchCartItemList();
    this.FetchProfileDetails();
    this.ExtraChargesFunctiolity();
    this.setState({ logo: logo });
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      let orderDetailsItemList = [];
      if (res.data && res.data.total > 0) {
        console.log(res);
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
        // res.data.result.map((item) => {
        //   let localUrl = item?.cloudinary_images_local
        //   let globalUrl =item?.cloudinary_images_global 
        //   let currentUrl =
        //     item.image !== null
        //       ? item.image[0] === "/"
        //         ? `${ApiUrls.BASH_URL}/media${item.image}`
        //         : item.image
        //       : NO_IMAGE;

        //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     item.image = url.url;
        //     item.valid = url.valid;
        //     return item;
        //   });
        // })
      }
      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            orderDetailsItemList.push(respone[i]);
            SubTotal += Number(respone[i].total_price);
          }
        }
      }
      this.setState({
        SubTotal: SubTotal.toFixed(2),
        orderDetailsItemList: orderDetailsItemList,
        cartListOpen: true,
        CartCount: CartCount,
        cartLoader: false,
      });
    });
  };

  redirectToDetailsPageAfterClick = (
    product_name,
    slug,
    product_id,
    addTocart_btn
  ) => {
    this.props.history.push({
      pathname: "/product/" + slug,
      state: {
        addTocart_btn: addTocart_btn,
      },
    });
  };

  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index
  ) => {
    let SubTotal = 0;
    let orderDetailsItemList = this.state.orderDetailsItemList;

    if (icons === "icon-plus") {
      for (let i = 0; i < orderDetailsItemList.length; i++) {
        if (orderDetailsItemList[i].id === index) {
          orderDetailsItemList[i].qty = orderDetailsItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            orderDetailsItemList[i].qty,
            orderDetailsItemList[i].is_case
          );
        }
      }
    } else {
      for (let i = 0; i < orderDetailsItemList.length; i++) {
        if (orderDetailsItemList[i].id === index) {
          orderDetailsItemList[i].qty = orderDetailsItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              orderDetailsItemList[i].qty,
              orderDetailsItemList[i].is_case
            );
          } else {
            this.deleteCartItem(
              product_id,
              orderDetailsItemList[i].qty,
              orderDetailsItemList[i].is_case
            );
          }
        }
      }
    }
    this.setState({ orderDetailsItemList: orderDetailsItemList });
  };

  UpdateCartItemFunctionlity = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
    };
    let orderDetailsItemList = this.state.orderDetailsItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < orderDetailsItemList.length; i++) {
          if (orderDetailsItemList[i].product_id === product_id) {
            orderDetailsItemList[i].total = res.subtotal;
          }
          SubTotal += Number(orderDetailsItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({
        disabled: false,
        orderDetailsItemList,
        SubTotal: SubTotal,
      });
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };
  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    this.props.history.push({
      pathname: "/listing/" + e,
      state: {
        category_id: e,
        parent_id: parent_id,
        // parentIds: this.state.parentIds,
        // parent_onlyid: this.state.parent_onlyid,
        // parent_level: this.state.parent_level,
      },
    });
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  render() {
    const {
      orderDetails,
      deliveryAddress,
      OrderStatus,
      order,
      CategoryDetails,
      CategoryOption,
      CartCount,
      orderDetailsItemList,
      web_customize,
    } = this.state;
    const hide1 = () => {
      document
        .getElementsByClassName("menuOuter")[0]
        .classList.toggle("showMenu");
    };
    return (
      <ScrollColorTypography component="div" className="main-content">
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          cartItemList={orderDetailsItemList}
          CartCount={CartCount}
          history={this.props.history}
          pageName={this.state.pageName}
          cartLoader={this.state.cartLoader}
          FetchCartCount={() => this.FetchCartItemList()}
          handleOpenDialog={this.handleOpenDialog}
          extraCharges={this.state.extraCharges}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index
          ) =>
            this.AddCountForShoppingcartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index
            )
          }
          logo={this.state.logo}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
          FetchCartCount={() => this.FetchCartItemList()}
        />

        <div
          className="main-data"
          style={{
            paddingTop:
              web_customize !== null &&
              web_customize?.top_marquee !== null &&
              web_customize?.top_marquee !== "" &&
              window.screen.availWidth > 900
                ? "11rem"
                : "",
          }}
        >
          <section className="user_details">
            <div className="user_main_wrapper">
              <div className="menuOuter">
                <UserDetailsMenu
                  history={this.props.history}
                  profileDetails={this.state.profileDetails}
                />
              </div>
              <div className="order-success">
                <div className="row">
                  <div className="col-md-12">
                    <div className="delivery_add">
                      <div className="cust_head_main">
                        <TypographyTextColor Color="heading" component="h4">
                          <Link to="/orders" className="back_order">
                            <span className="icon-left-arrow"></span>
                          </Link>
                          Order Details
                        </TypographyTextColor>
                      </div>
                      <div className="order-progress">
                        {OrderStatus && OrderStatus.length > 0
                          ? OrderStatus.map((row, index) => {
                              return (
                                <div className="order_main_pr">
                                  <div className="order_status">
                                    <TypographyTextFontFamily
                                      component="span"
                                      type="other"
                                      className="order_desc"
                                    >
                                      {row.name === "pending"
                                        ? "Ordered"
                                        : row.name?.charAt(0).toUpperCase() +
                                          row.name?.slice(1)}
                                      <span className="sub_order_desc">
                                        {row.status_date
                                          ? " (" +
                                            new Date(
                                              new Date(
                                                row.status_date
                                              ).toUTCString()
                                            ).getDate() +
                                            " " +
                                            new Date(
                                              row.status_date
                                            ).toLocaleString("en-us", {
                                              month: "short",
                                            }) +
                                            ") "
                                          : ""}
                                      </span>
                                    </TypographyTextFontFamily>
                                  </div>
                                  <div
                                    className={`progressBar_start ${
                                      row.order_status === true &&
                                      row.name !== "cancel"
                                        ? "progress_active"
                                        : row.order_status === true &&
                                          row.name === "cancel"
                                        ? "progress_inactive"
                                        : ""
                                    }`}
                                  ></div>
                                  {row.name === "delivered" ? (
                                    ""
                                  ) : (
                                    <div className="progressBar">
                                      <div
                                        className={`sub_progressBar ${
                                          row.order_status === true &&
                                          row.name !== "cancel"
                                            ? "active_sub_pb"
                                            : row.order_status === true &&
                                              row.name === "cancel"
                                            ? "inactive_sub_pb"
                                            : ""
                                        }`}
                                      ></div>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="delivery_add summ_add">
                      <TypographyTextColor
                        Color="heading"
                        component="div"
                        className="cust_head_main"
                      >
                        {deliveryAddress &&
                        deliveryAddress?.delivery_type === "pickup" ? (
                          <h4>Pickup Address</h4>
                        ) : deliveryAddress?.delivery_type === "delivery" ? (
                          <h4>Delivery Address</h4>
                        ) : deliveryAddress?.delivery_type === "shipping" ? (
                          <h4>Shipping Address</h4>
                        ) : (
                          "No delivery and pickup"
                        )}
                      </TypographyTextColor>
                      {deliveryAddress?.delivery_type ===
                      "No Delivery Pickup" ? (
                        "No delivery and pickup"
                      ) : (
                        <div className="summ_details">
                          <TypographyTextColor Color="other" component="h6">
                            {deliveryAddress
                              ? deliveryAddress?.first_name +
                                " " +
                                deliveryAddress?.last_name
                              : ""}
                          </TypographyTextColor>

                          <TypographyTextColor Color="other" component="span">
                            {deliveryAddress &&
                            deliveryAddress?.delivery_type === "pickup" ? (
                              <>
                                {deliveryAddress &&
                                deliveryAddress?.appartment_name
                                  ? deliveryAddress?.appartment_name
                                  : ""}
                                {deliveryAddress &&
                                deliveryAddress?.street_name ? (
                                  <>
                                    <br></br>
                                    {"APT/FLOOR - " +
                                      deliveryAddress?.street_name}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                {deliveryAddress && deliveryAddress?.street_name
                                  ? deliveryAddress?.street_name
                                  : ""}
                                {deliveryAddress &&
                                deliveryAddress?.appartment_name ? (
                                  <>
                                    <br></br>
                                    {"APT/FLOOR - " +
                                      deliveryAddress?.appartment_name}
                                  </>
                                ) : (
                                  ""
                                )}
                                <br></br>
                              </>
                            )}

                            {deliveryAddress && deliveryAddress?.city
                              ? deliveryAddress?.city + ", "
                              : ""}
                            {deliveryAddress && deliveryAddress?.state
                              ? deliveryAddress?.state + " "
                              : ""}
                            {deliveryAddress && deliveryAddress?.zipcode
                              ? deliveryAddress?.zipcode
                              : ""}
                            <br></br>
                          </TypographyTextColor>
                          <TypographyTextColor
                            Color="other"
                            component="span"
                            className="summ_mobile"
                          >
                            {deliveryAddress
                              ? formatPhoneNumberCommon(deliveryAddress?.phone)
                              : ""}
                            {deliveryAddress &&
                            deliveryAddress?.alternate_phone ? (
                              <>
                                {" / " +
                                  formatPhoneNumberCommon(
                                    deliveryAddress?.alternate_phone
                                  )}
                                <br></br>
                              </>
                            ) : (
                              ""
                            )}

                            {deliveryAddress && deliveryAddress?.delivery_remark
                              ? "Delivery Remark : " +
                                deliveryAddress?.delivery_remark
                              : ""}
                          </TypographyTextColor>
                          {deliveryAddress?.pickup_message === undefined ||
                          deliveryAddress?.pickup_message === "undefined" ||
                          deliveryAddress?.pickup_message === "" ||
                          deliveryAddress?.pickup_message === null ? (
                            ""
                          ) : (
                            <>
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                              >
                                {deliveryAddress?.pickup_message}
                              </TypographyTextColor>
                              <br />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="delivery_add summ_add">
                      <div className="cust_head_main">
                        <TypographyTextColor Color="heading" component="h4">
                          {" "}
                          Order ID & Date
                        </TypographyTextColor>
                      </div>
                      <div className="summ_details">
                        <TypographyTextColor Color="other" component="h6">
                          Order Id : {order ? "ORD" + order?.order_id : ""}
                        </TypographyTextColor>
                        <TypographyTextColor Color="other" component="span">
                          Order Date :{" "}
                          {order && order?.order_date
                            ? `${sendDateFormat(order?.order_date)}
                           ${" " + postfullTimeFormat(order?.order_date)}`
                            : ""}
                        </TypographyTextColor>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="delivery_add summ_add">
                      <div className="cust_head_main">
                        <TypographyTextColor Color="heading" component="h4">
                          Payable Amount , Method{" "}
                          {deliveryAddress?.delivery_type ===
                          "No Delivery Pickup"
                            ? ""
                            : ", Slot"}
                        </TypographyTextColor>
                      </div>
                      <div className="summ_details summ_add">
                        <TypographyTextColor Color="other" component="h6">
                          ${order ? order?.amount : ""}
                        </TypographyTextColor>
                        {order?.ebt_total_price !== 0 ||
                        order?.method?.split(",").includes("credit-card") ? (
                          <TypographyTextColor
                            className="card-msg"
                            Color="other"
                            component="span"
                          >
                            (Your card was temporarily authorized for $
                            {order && order?.amount
                              ? Number(order.amount).toFixed(2)
                              : "0.00"}
                            . You should see the hold removed, and a final
                            charge reflected on your statement within 3-7
                            business days (depending on your banks policies) of
                            order completion.)
                          </TypographyTextColor>
                        ) : (
                          <></>
                        )}
                        {order?.other_total_price !== 0 ? (
                          <>
                            {" "}
                            <TypographyTextColor Color="other" component="span">
                              {order &&
                              order?.method &&
                              order?.method?.split(",").includes("cash_payment")
                                ? "Cash"
                                : order?.method
                                    ?.split(",")
                                    .includes("pickup_pay")
                                ? "Pickup & Pay"
                                : order?.method
                                    ?.split(",")
                                    .includes("credit-card")
                                ? "Credit Card"
                                : ""}

                              {order?.method
                                ?.split(",")
                                .includes("card_payment") ||
                              order?.method
                                ?.split(",")
                                .includes("credit-card") ? (
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  {" "}
                                  : {order?.card_type} ({order?.card_number})
                                </TypographyTextColor>
                              ) : (
                                <></>
                              )}

                              {order?.ebt_total_price !== 0 ||
                              (order?.customer_credit_price !== 0 &&
                                this.state.is_show_customer_credit)
                                ? " $" + order?.other_total_price
                                : ""}
                            </TypographyTextColor>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                        {order?.customer_credit_price !== 0 ? (
                          <>
                            <TypographyTextColor Color="other" component="span">
                              {"Customer Credits"}: $
                              {parseFloat(order?.customer_credit_price).toFixed(
                                2
                              )}
                            </TypographyTextColor>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                        <TypographyTextColor Color="other" component="span">
                          {order?.ebt_total_price !== 0
                            ? "EBT : $" + order?.ebt_total_price
                            : ""}
                        </TypographyTextColor>
                        <br />
                        {deliveryAddress?.delivery_type ===
                          "No Delivery Pickup" ||
                        deliveryAddress?.delivery_type === "shipping" ||
                        deliveryAddress?.pickup_type === "no_date_time" ? (
                          ""
                        ) : (
                          <>
                            <TypographyTextColor Color="other" component="span">
                              {" "}
                              <span className="text-capitalize">
                                {deliveryAddress?.delivery_type
                                  ? deliveryAddress?.delivery_type
                                  : ""}{" "}
                                Date :{" "}
                              </span>
                              {deliveryAddress?.delivery_datetime
                                ? deliveryAddress?.delivery_datetime.split(
                                    "-"
                                  )[1] +
                                  "/" +
                                  deliveryAddress?.delivery_datetime.split(
                                    "-"
                                  )[2] +
                                  "/" +
                                  deliveryAddress?.delivery_datetime.split(
                                    "-"
                                  )[0]
                                : ""}
                            </TypographyTextColor>
                            <br />{" "}
                            <TypographyTextColor Color="other" component="span">
                              {" "}
                              <span className="text-capitalize">
                                {deliveryAddress?.delivery_type
                                  ? deliveryAddress?.delivery_type
                                  : ""}{" "}
                                Time :{" "}
                              </span>{" "}
                              {deliveryAddress?.delivery_option
                                ? deliveryAddress?.delivery_option
                                : ""}
                            </TypographyTextColor>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {order && order?.general_note ? (
                    <div className="col-md-4">
                      <div className="delivery_add summ_add">
                        <div className="cust_head_main">
                          <h4>General Notes</h4>
                        </div>
                        <div className="summ_details summ_add">
                          <h6>{order ? order?.general_note : ""}</h6>
                          <span>{order?.general_note}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="delivery_add cartdetails">
                      <div className="cust_head_main">
                        <TypographyTextColor Color="heading" component="h4">
                          Order Details
                        </TypographyTextColor>
                      </div>
                      <div className="cart_inner_head order_succ_detail">
                        <table>
                          <tbody>
                            <tr>
                              <td className="cart_item">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Item Description
                                </TypographyTextColor>
                              </td>
                              <td className="cart_quantity">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Quantity
                                </TypographyTextColor>
                              </td>
                              <td className="cart_unit">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Unit Price
                                </TypographyTextColor>
                              </td>
                              <td className="cart_unit">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Subtotal
                                </TypographyTextColor>
                              </td>
                            </tr>
                            {orderDetails && orderDetails.length > 0
                              ? orderDetails.map((row, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="cart_inner_details">
                                          <div className="cart_inner_item">
                                            <div className="smll_img">
                                              {/* <img
                                                src={
                                                  row.image
                                                    ? row.image
                                                    : BROC_IMAGE
                                                }
                                                alt=""
                                                onClick={()=>this.redirectToDetailsPageAfterClick(row.product_name,row.slug, row.product_id)}
                                              /> */}
                                              <img
                                                src={refineImgurl(row.image , {
                                               
                                                  w: 100,
                                                })}
                                                onError={(e) =>
                                                  addDefaultImageSrc(e)
                                                }
                                                alt=""
                                                onClick={() =>
                                                  this.redirectToDetailsPageAfterClick(
                                                    row.product_name,
                                                    row.slug,
                                                    row.product_id
                                                  )
                                                }
                                              />
                                            </div>
                                            <div
                                              className="cart_description"
                                              onClick={() =>
                                                this.redirectToDetailsPageAfterClick(
                                                  row.product_name,
                                                  row.slug,
                                                  row.product_id
                                                )
                                              }
                                            >
                                              <TypographyTextColor
                                                Color="product"
                                                component="span"
                                              >
                                                {row.name === null
                                                  ? row.unlist_product_name
                                                  : row.name}
                                              </TypographyTextColor>
                                              <p>
                                                {row.note ? (
                                                  <p>
                                                    {row.note.split("~")[0] ===
                                                    ""
                                                      ? row.note.split("~")[1]
                                                      : row.note
                                                          .split("~")
                                                          .join(", ")}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="case_details">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {row.quantity}{" "}
                                          {row.is_case === true ? "Case" : ""}
                                        </TypographyTextColor>
                                      </td>
                                      <td className="unit_price">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          $
                                          {row.unit_price === null
                                            ? 0
                                            : row.unit_price.toFixed(2)}
                                        </TypographyTextColor>
                                      </td>
                                      <td className="unit_price">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          $
                                          {row.total === null
                                            ? 0
                                            : row.total.toFixed(2)}
                                        </TypographyTextColor>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                        <div className="cart-listing-details">
                          {orderDetails && orderDetails.length > 0
                            ? orderDetails.map((row, index) => {
                                return (
                                  <div
                                    className="cart_sub_d etails"
                                    key={index}
                                  >
                                    <div className="cart_details">
                                      <TypographyTextColor
                                        component="span"
                                        Color="other"
                                        className="count_main"
                                      >
                                        {row.quantity}{" "}
                                        {row.is_case === true ? "Case" : ""}
                                      </TypographyTextColor>
                                      <div className="smll_img">
                                        <img
                                          src={row.image ? row.image : NO_IMAGE}
                                          alt=""
                                          onError={(e) => addDefaultImageSrc(e)}
                                        />
                                      </div>
                                      <div className="cart_desc">
                                        <div className="cart_desc_name">
                                          <TypographyTextColor
                                            component="p"
                                            Color="product"
                                          >
                                            {row.name}
                                          </TypographyTextColor>
                                          <p>
                                            {row.note ? (
                                              <p>
                                                {row.note.split("~")[0] === ""
                                                  ? row.note.split("~")[1]
                                                  : row.note
                                                      .split("~")
                                                      .join(", ")}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                        </div>
                                        <div className="cart_details_count">
                                          <TypographyTextColor
                                            component="span"
                                            Color="other"
                                            className="prod_pieces mr-2"
                                          >
                                            ${row.unit_price?.toFixed(2)}
                                          </TypographyTextColor>
                                          <TypographyTextColor
                                            component="span"
                                            Color="other"
                                            className="prod_cost"
                                          >
                                            ${row.total?.toFixed(2)}
                                          </TypographyTextColor>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                          <div className="cart_items_total">
                            <div className="sub_total">
                              <div className="sub_desc">Subtotal</div>
                              <div className="sub_total_count">$60.00</div>
                            </div>
                            <div className="cart_checkout">
                              <div className="bttn-fill">
                                <Link to className="bttn_grn">
                                  Checkout
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer emailSubscribe={this.state.emailSubscribe} />
        </div>
      </ScrollColorTypography>
    );
  }
}

export default OrderDetails;
