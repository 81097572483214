import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";

const getConfigure = () => {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }
  return configToken;
};

export function CartListFunctionlity(formValues, dispatch) {
  let configToken = getConfigure();
  const url =
    ApiUrls.CART_LIST +
    "?schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode+
    "&pickup_address_id=" +
    formValues.pickup_address_id;

  return axios
    .get(url, configToken)
    .then( (res)=> {
      return res;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function DeleteAvailableCartItemFunctionlity(formValues, dispatch) {
  let configToken = getConfigure();

  const url =
    ApiUrls.CART_LIST +
    "?cart_for_id=" +
    formValues.cart_for_id +
    "&cart_id=" +
    formValues.cart_id;

  return axios
    .delete(url, configToken)
    .then( (res)=> {
      return res;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function CheckOutFunctionlity(formValues, dispatch) {
  let configToken = getConfigure();

  const url = ApiUrls.CHECKOUT_URL;

  return axios
    .get(url, configToken)
    .then( (res)=> {
      return res;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CardPaymentData(formValues, dispatch) {
  let configToken = getConfigure();
  const url = ApiUrls.CARD_PAYMENT_DATA;
  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CardOrderSummaryFunctionlity(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.CARD_ORDER_SUMMARY+
  "?zipcode="+formValues.zipcode+"&schedule_type="+formValues.scheduleType+
  "&pickup_address_id=" +
  formValues.pickup_address_id;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function DeleteCrtIemFunctionlity(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  let url =''
  if(formValues.product_id!==undefined)
    url=ApiUrls.DELETE_CART_ITEM +
    "?product_id=" +
    formValues.product_id +
    "&is_case=" +
    formValues.is_case +
    "&container_type=" +
    formValues.container_type;
else
url=ApiUrls.DELETE_CART_ITEM +
"?is_case=" +
formValues.is_case +
"&container_type=" +
formValues.container_type+
"&cart_id=" +
formValues.cart_id+"&is_unlist_item=true";

  return axios
    .delete(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function RemoveCartFunctionlity(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.EMPTY_CART;

  return axios
    .delete(url, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CartOrderFunctionlity(formValues, dispatch) {
  let configToken = getConfigure();

  const url = ApiUrls.ORDER_URL;
  return axios
    .post(url, formValues, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CardknoxPostAuthapi(formValues, dispatch) {
  let configToken = getConfigure();


  const url = ApiUrls.ORDER_MANAGEMENT+'card-authorize';
  return axios
    .post(url, formValues, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function CardSaveapi(formValues, dispatch) {
  let configToken = getConfigure();


  const url = ApiUrls.ORDER_MANAGEMENT+'card-save';
  return axios
    .post(url, formValues, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function GetCardSavedapi(formValues, dispatch) {
  let configToken = getConfigure();


  const url = ApiUrls.ORDER_MANAGEMENT+'card-save?user_id=0&login_status=true';
  return axios
    .get(url, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function deleteCardapi(card_id , dispatch) {
  let configToken = getConfigure();


  const url = ApiUrls.ORDER_MANAGEMENT+'card-save?user_id=0&login_status=true&card_id='+card_id ;
  return axios
    .delete(url, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function CheckMinimumOrderAPi(formValues, dispatch) {
  let configToken = getConfigure();
  const url = ApiUrls.CHECK_MINIMUM_ORDER;
  return axios
    .get(url, configToken)
    .then( (res)=> {
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return error.response
         
        
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetExtraChargesData(formValues, dispatch) {
  let configToken = getConfigure();
  const url = ApiUrls.EXTRA_CHARGE;
  return axios
    .get(url, configToken)
    .then( (res)=> {
      return res;
    })
    .catch( (error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
