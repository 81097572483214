import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";

export function OrderSuccessDetailsApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }
  const url =
    ApiUrls.ORDER_DETAILS_URL +
      "/" +
      formValues.order_id +
      "?guest_user_id=" +
      formValues.guest_id +
      "&is_guest=" +
      formValues.is_guest;
  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function AllOrdersApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ORDER_URL +
    "?limit=" +
    formValues.limit +
    "&page=" +
    formValues.page +
    "&order=-id";

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CreatePaymentKeyFuntionality(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization:
          "Basic XzhISko4UVk4a0E1Vk1ycDlwTDU5ZUxIRHZJNWVsWDMwUTJZQlIxOHZlOnMyLy8=",
      },
    };
  }

  const url = "https://sandbox.usaepay.com/api/v2/pub/payment_keys";
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function ReorderCallApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.REORDER_URL +
    formValues.order_id +
    "?for_reorder=" +
    formValues.for_reorder;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function ReorderCartCallApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.FAVOURITE_URL + "reorder-addtocart";

  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetCustomerCreditsApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ORDER_MANAGEMENT + "customer-credit";
// const url="https://2764-67-244-100-49.ngrok-free.app/api/customer/"+formValues.mobile_number
  return axios  
    .post(url, formValues,configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function OrderCountApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ORDER_COUNT_URL;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetSlotForDeliveryAndPickup(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.TIMESLOT_URL;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetSlotVisiblity(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.TIMESLOT_VISILITY;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function RestrictDeliveryApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.RESTRICT_DELIVERY_URL +
    "?user_id=0&login_status=" +
    formValues.status;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function TimeSlotapi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.TIME_SLOT_URL;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}


export function InitiateEBT(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ORDER_MANAGEMENT +
    "ebt-initiate" 
    

  return axios
    .post(url, formValues,configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function DeleteEBTCard(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ORDER_MANAGEMENT +
    "ebt-initiate?user_id=0&login_status=true&card_id="+formValues 
    

  return axios
    .delete(url,configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function fetchEBTCard() {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ORDER_MANAGEMENT +
    "ebt-initiate?user_id=0&login_status=true" 
    

  return axios
    .get(url,configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}


export function fetchEBTbalance(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ORDER_MANAGEMENT +
    "ebt-balance" 
    

  return axios
    .post(url, formValues,configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}