import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";

export function CountryListView(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.CITY_LOOKUPS +
    "?search=" +
    formValues.search +
    "&page=" +
    formValues.page +
    "&limit=" +
    formValues.limit +
    "&state_id=" +
    formValues.state_id;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function StateListView(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.STATE_LOOKUPS +
    "?search=" +
    formValues.search +
    "&page=" +
    formValues.page +
    "&limit=" +
    formValues.limit;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CreateNewAccount(formValues, dispatch) {
  const url = ApiUrls.CREATE_ACCOUNT;
  return axios
    .post(url, formValues)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function OtpVerifing(formValues, dispatch) {
  const url = ApiUrls.OTP_VERIFY;
  return axios
    .post(url, formValues)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function OtpPhoneVerifing(formValues, dispatch) {
  const url = ApiUrls.OTP_VERIFY_PHONE;
  return axios
    .post(url, formValues)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function ResendOtpApi(formValues, dispatch) {
  const url = ApiUrls.RESEND_OTP;
  return axios
    .post(url, formValues)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function SendOtpApi(formValues, dispatch) {
  const url = ApiUrls.SEND_OTP;
  return axios
    .post(url, formValues)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CreateNewAddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ADD_CUSTOMER_ADDRESS;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function CreateNewGuestAddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }
  const url = ApiUrls.Guest_USER_ADDRESS;
  return axios
    .put(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function GetGuestAddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }
  const url = ApiUrls.Guest_USER_ADDRESS+"/"+formValues.id;
  return axios
    .get(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
export function Updateaddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ADD_CUSTOMER_ADDRESS;
  return axios
    .put(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetallAddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ADD_CUSTOMER_ADDRESS +
    "?user_id=0&limit=100&page=1&order=-isdefault&store_address=" +
    formValues.store_address;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetAddressDetails(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ADD_CUSTOMER_ADDRESS + "?user_id=0&limit=10&page=1&order=-id";

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function OrderAddressAndDelivery(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ORDER_ADDRSS_AND_ADD_DELIVERY;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetProfileDetails(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.GET_PROFILE;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function UpdateProfileDetails(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.GET_PROFILE;

  return axios
    .put(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetAddressDetailsByID(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ADD_CUSTOMER_ADDRESS + "/" + formValues.id;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function SettingsSetValue(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.SETTING_URL;
  return axios
    .post(url, formValues, configToken)
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function GetSettingsData(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.SETTING_URL;

  return axios
    .get(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function SetDefaultAddress(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url = ApiUrls.ADD_CUSTOMER_ADDRESS;

  return axios
    .patch(url, formValues, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}

export function RemoveAddressFunctionlity(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
    };
  }

  const url =
    ApiUrls.ADD_CUSTOMER_ADDRESS + "?address_id=" + formValues.address_id;

  return axios
    .delete(url, configToken)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: "Network error. Please try again.",
          },
        };
      }
    });
}
