import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import CategoriesModal from "./common/Category/categoriesModal";
import { OrderSuccessDetailsApi } from "../actions/order/orderAction";
import {
  CategeriesListView,
  ViewCartCount,
  SubCategeriesListView,
} from "../actions/home/homeAction";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  CartListFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import { GetSettingsData } from "../actions/profile/profileAction";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  commonPageTitel,
  sendDateFormat,
  postfullTimeFormat,
  formatPhoneNumberCommon,
  getSchedule_zip,
  RefreshApiToken,
  addDefaultImageSrc,
  logout,
  slugifyStr,
  checkImageUrls,
  refineImgurl,
} from "../utils/renderUtils/renderUtil";

import ApiUrls from "../utils/apiUtils/apiUrl";
import CustomPreloader from "./common/Loader/CustomPreloader";
import OrderPdf from "./common/Order/OrderPdf";
import Loginpopup from "./login/Loginpopup";
import {
  PrimaryButton,
  ScrollColorTypography,
  SecondaryButton,
  SecondaryCancelButton,
  TypographyFontColor,
  TypographySecondaryColor,
  TypographyTextColor,
} from "./common/UiDesign";
import EBTLOGO from "../assets/images/logo-snap.png";
class OrderSuccessful extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      orderDetails: [],
      deliveryAddress: [],
      order: [],
      orderStatus: [],
      order_id: 0,
      CardOrderSummary: [],
      TotalPayment: 0,
      email: "",
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      pageName: "order_success",
      orderSuccessItemList: [],
      footerDetails: [],
      storeData: [],
      logo: "",
      customer_details: [],
      extraCharges: [],
      mainLoader: true,
      emailSubscribe: "",
      setOpenLoginPopup: false,
      is_show_customer_credit: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_show_customer_credit,
      isShowEBT: JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.categoryOpen = this.categoryOpen.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  redirectToDetailsPage = (slug, addTocart_btn) => {
    if (
      localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) !== null
    ) {
      this.logoutCustomerBySr();
    }
    this.props.history.push({
      pathname: "/product/" + slug,
      state: {
        addTocart_btn: addTocart_btn,
      },
    });
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return null;
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res.data && res.data.result) {
        settingData = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    localStorage.removeItem("slot");
    localStorage.removeItem("Guest_userid");
    localStorage.removeItem("SelectedAddress");
    localStorage.removeItem("tipAmount");
    //;

    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    let order_id;
    let email;
    let guest_id = "";
    if (this.props && this.props.location && this.props.location.state) {
      order_id = this.props.location.state.order_id;
      guest_id = this.props.location.state.guest_user_id;
    }
    if (
      JSON.parse(localStorage.getItem("access_token")) &&
      JSON.parse(localStorage.getItem("isLogin")) === true
    ) {
      email = JSON.parse(localStorage.getItem("email")).email;
    }

    this.setState({
      order_id: order_id,
      email: email,
      logo: logo,
      guest_id: guest_id,
    });
    this.FetchSettingData();
    this.FetchOrderDetails(order_id, guest_id);
    this.FetchCategoryList();
    this.FetchOrderSuccessItemList();
    this.ExtraChargesFunctiolity();
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.ExtraChargesFunctiolity();
            }
          );
        }
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  FetchSubCategoryList = (category_id) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      if (res.data && res.data.result) {
        subCategoryList = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(category_id);
            }
          );
        }
      }
      this.setState({ SubCategoryDetails: subCategoryList });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      this.setState({ CategoryDetails: categoryList });
    });
  };

  FetchCartCount = () => {
    let formValues = {};
    this.setState({ cartLoader: true });
    ViewCartCount(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      if (res.data && res.data.result) {
        CartCount = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartCount();
            }
          );
        }
      }
      this.setState({ CartCount: CartCount, cartLoader: false });
    });
  };

  FetchOrderDetails = (order_id, guest_id) => {
    let formValues = {
      order_id: order_id,
      guest_id: guest_id === null ? 0 : guest_id,
      is_guest: guest_id !== null,
    };
    OrderSuccessDetailsApi(formValues, this.props.dispatch).then((res) => {
      let orderDetails = [];
      if (res.data && res.data.result) {
        orderDetails = res.data.result;
        
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchOrderDetails(order_id, guest_id);
            }
          );
        }
      }
      // const promises = orderDetails?.other_details?.map((ele) => {
      //   let localUrl = ele?.cloudinary_images_local
      //     let globalUrl =ele?.cloudinary_images_global 
      //   let currentUrl =
      //     ele.image !== null
      //       ? ele.image[0] === "/"
      //         ? `${ApiUrls.BASH_URL}/media${ele.image}`
      //         : ele.image
      //       : NO_IMAGE;

      //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
      //     ele.image = url.url;
      //     ele.valid = url.valid;
      //     return ele;
      //   });
      // });
      // Promise.all(promises).then((updatedCartItems) => {
        this.setState({
          customer_details: orderDetails ? orderDetails.customer : "",
          orderDetails: orderDetails ? orderDetails.other_details : "",
          deliveryAddress: orderDetails ? orderDetails.delivery_address : "",
          orderStatus: orderDetails ? orderDetails.order_status : "",
          order: orderDetails ? orderDetails.order : "",
          mainLoader: false,
        });
      // });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };
  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }
  handleOpenDialog() {
    this.setState({ openCategory: true });
  }
  handleCloseDialog() {
    this.setState({ openCategory: false });
  }

  FetchOrderSuccessItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let orderSuccessItemList = [];
      let CartCount = [];
      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          for (let i = 0; i < respone.length; i++) {
            orderSuccessItemList.push(respone[i]);
            SubTotal += Number(respone[i].total_price);
          }
        }
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchOrderSuccessItemList();
            }
          );
        }
      }
      if (res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      }
      this.setState({
        orderSuccessItemList: orderSuccessItemList,
        SubTotal: SubTotal.toFixed(2),
        cartListOpen: true,
        CartCount: CartCount,
        cartLoader: false,
      });
    });
  };

  _exportPdf = (fileName, order_id, logoImg) => {
    document.getElementById("capture").style.display = "block";
    html2canvas(
      document.querySelector("#capture"),
      {
        onclone: function (clonedDoc) {
          clonedDoc.getElementById("hidden_tag").style.display = "block";
          clonedDoc.getElementById("hidden_logo").style.display = "block";
          clonedDoc.getElementById("hidden_tax_invoice").style.display =
            "block";
          clonedDoc.getElementById("customer_details").style.display = "block";
          clonedDoc.getElementById("delivery_address").style.display = "block";
          clonedDoc.getElementById("method").style.display = "block";
          clonedDoc.getElementById("hidden_order_summery").style.display =
            "block";
        },
      },
      { logging: true, letterRendering: 1, useCORS: true, imageTimeout: 15000 }
    ).then(function (canvas) {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.addImage(
        logoImg[0] === "/" ? ApiUrls.BASH_URL + "/media/" + logoImg : logoImg,
        "JPEG",
        14,
        5,
        32,
        15
      );
      // doc.addImage(
      //   NO_IMAGE,
      //   "JPEG",
      //   14,
      //   5,
      //   32,
      //   40
      // );
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("order#" + order_id ? "ORD" + order_id : "" + ".pdf");
    });
    document.getElementById("capture").style.display = "none";
  };
  OpenLoginPoup = () => {
    this.setState({ setOpenLoginPopup: true });
  };
  _createPdf = () => {};
  logoutCustomerBySr = () => {
    let list = JSON.parse(localStorage.getItem("SalesRepUserList"));
    if (list?.length > 1) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (
          JSON.parse(localStorage.getItem("access_token")).email ===
          list[i].email
        ) {
          break;
        }
      }
      list.splice(i, 1);
      localStorage.setItem(
        "access_token",
        JSON.stringify(list[0].data.access_token)
      );
      localStorage.setItem("SalesRepUserList", JSON.stringify(list));
    } else {
      localStorage.removeItem("SalesRepUserList");
      logout();
    }
  };
  render() {
    const {
      orderDetails,
      deliveryAddress,
      orderStatus,
      email,
      order,
      CategoryDetails,
      CartCount,
      customer_details,
      web_customize,
    } = this.state;
    return (
      <ScrollColorTypography component="div" className="main-content">
        {CategoryDetails && CategoryDetails.length > 0 ? (
          <Header
            CategoryDetails={CategoryDetails}
            handleOpenDialog={this.handleOpenDialog}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            cartLoader={this.state.cartLoader}
            FetchCartCount={() => this.FetchOrderSuccessItemList()}
            logo={this.state.logo}
            extraCharges={this.state.extraCharges}
            handleInnerOpenDialog={(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            ) =>
              this.handleInnerOpenDialog(
                catId,
                subcategory_name,
                has_child,
                level,
                parent_id
              )
            }
            OpenLoginPoup={() => this.OpenLoginPoup()}
          />
        ) : (
          ""
        )}
        {/* <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={this.CloseLoginDialog}
          isShowclose={true}
        /> */}
        <CategoriesModal
          open={this.state.openCategory}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />

        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            {" "}
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <div
              id="capture"
              style={{ width: "790px", padding: "50px", display: "none" }}
            >
              <OrderPdf
                order={order}
                extraCharges={this.state.extraCharges}
                customer_details={customer_details}
                orderDetails={orderDetails}
                deliveryAddress={deliveryAddress}
                logo={this.state.logo}
                guest_id={this.state.guest_id}
                is_show_customer_credit={this.state.is_show_customer_credit}
              />
            </div>
            <section className="cart_main">
              <div className="cart_wrapper">
                <div className="order-success">
                  <div className="container-fluid">
                    <div
                      className="row"
                      id="no-print"
                      data-html2canvas-ignore="true"
                    >
                      <div className="col-md-12">
                        <div className="delivery_add">
                          <div className="order_placed">
                            <TypographyFontColor
                              border={0}
                              className="success_icon"
                            >
                              <span className="icon-tick"></span>
                            </TypographyFontColor>
                            <div className="order_success_details">
                              <TypographySecondaryColor size={25}>
                                Thank you! Your order has been placed.
                              </TypographySecondaryColor>
                              <TypographyTextColor
                                Color="other"
                                className="d-flex align-items-baseline"
                              >
                                An email confirmation has been sent to{" "}
                                <TypographyFontColor
                                  border={0}
                                  className="ml-1"
                                >
                                  {customer_details?.email}
                                </TypographyFontColor>
                              </TypographyTextColor>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div
                          className="main-head"
                          id="hidden_logo"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div className="col-6">
                        <div
                          id="hidden_tax_invoice"
                          style={{ display: "none" }}
                        >
                          <h3 className="text-right">
                            Order #{order ? "ORD" + order.order_id : ""}
                          </h3>
                          <br></br>
                          <h3 className="text-right">
                            Date:{" "}
                            {order ? sendDateFormat(order.order_date) : ""}{" "}
                            {order ? postfullTimeFormat(order.order_date) : ""}
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-12 text-center"
                        id="hidden_order_summery"
                        style={{ display: "none" }}
                      >
                        <h3 className="text-center">Order Summary</h3>
                      </div>
                    </div>

                    <div
                      className="row"
                      id="no-print"
                      data-html2canvas-ignore="true"
                    >
                      <div className="col-md-12">
                        <div className="delivery_add">
                          <div className="cust_head_main">
                            <TypographyTextColor Color="heading" component="h4">
                              Track Order
                            </TypographyTextColor>
                          </div>
                          <div className="order-progress">
                            <div className="order_main_pr">
                              <div className="order_status">
                                <span className="order_desc">
                                  {orderStatus &&
                                  orderStatus.length > 0 &&
                                  orderStatus[0].name === "pending"
                                    ? "Ordered"
                                    : ""}
                                  <span className="sub_order_desc">
                                    {orderStatus &&
                                    orderStatus.length > 0 &&
                                    orderStatus[0].status_date
                                      ? " (" +
                                        new Date(
                                          new Date(
                                            orderStatus[0].status_date
                                          ).toUTCString()
                                        ).getDate() +
                                        " " +
                                        new Date(
                                          orderStatus[0].status_date
                                        ).toLocaleString("en-us", {
                                          month: "short",
                                        }) +
                                        ") "
                                      : ""}
                                  </span>
                                </span>
                              </div>
                              <div
                                className={`progressBar_start ${
                                  orderStatus &&
                                  orderStatus.length > 0 &&
                                  orderStatus[0].order_status === true
                                    ? "progress_active"
                                    : ""
                                }`}
                              ></div>
                              <div className="progressBar">
                                <div className="sub_progressBar active_sub_pb"></div>
                              </div>
                            </div>
                            <div className="order_main_pr">
                              <div className="order_status order_active_s">
                                <span className="order_desc">
                                  Packed
                                  <span className="sub_order_desc"></span>
                                </span>
                              </div>
                              <div className="progressBar_start"></div>
                              <div className="progressBar">
                                <div className="sub_progressBar"></div>
                              </div>
                            </div>
                            <div className="order_main_pr">
                              <div className="order_status ">
                                <span className="order_desc">
                                  Shipped
                                  <span className="sub_order_desc"></span>
                                </span>
                              </div>
                              <div className="progressBar_start"></div>
                              <div className="progressBar">
                                <div className="sub_progressBar"></div>
                              </div>
                            </div>
                            <div className="order_main_pr">
                              <div className="order_status">
                                <span className="order_desc">
                                  Delivered
                                  <span className="sub_order_desc"></span>
                                </span>
                              </div>
                              <div className="progressBar_start"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* for pdf */}
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="delivery_add summ_add"
                          id="customer_details"
                          style={{ display: "none" }}
                        >
                          <div className="cust_head_main">
                            <h4>Customer Details</h4>
                          </div>
                          {customer_details ? (
                            <div className="summ_details">
                              <h6>
                                {customer_details
                                  ? customer_details.first_name +
                                    " " +
                                    customer_details.last_name
                                  : ""}
                              </h6>
                              {deliveryAddress.delivery_type === "pickup" &&
                              this.state.guest_id !== null ? (
                                <></>
                              ) : (
                                <>
                                  {" "}
                                  <span>
                                    {customer_details.street_name
                                      ? customer_details.street_name
                                      : ""}

                                    {customer_details.address ? (
                                      <>
                                        {" "}
                                        <br></br>
                                        {"APT/FLOOR -  " +
                                          customer_details.address}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <br></br>
                                    {customer_details.city +
                                      ", " +
                                      customer_details.state +
                                      " " +
                                      customer_details.zipcode}
                                  </span>
                                </>
                              )}
                              <br></br>
                              <span>
                                Email:{" "}
                                {customer_details && customer_details.email
                                  ? customer_details.email
                                  : ""}
                              </span>
                              <span className="summ_mobile">
                                Mobile :{" "}
                                {customer_details && customer_details.phone
                                  ? this.formatPhoneNumber(
                                      customer_details.phone
                                    )
                                  : ""}
                                {customer_details.alternate_phone
                                  ? " / " +
                                    this.formatPhoneNumber(
                                      customer_details.alternate_phone
                                    )
                                  : ""}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="delivery_add summ_add"
                          id="delivery_address"
                          style={{ display: "none" }}
                        >
                          <TypographyTextColor
                            Color="heading"
                            className="cust_head_main"
                            component="div"
                          >
                            {deliveryAddress &&
                            deliveryAddress?.delivery_type === "pickup" ? (
                              <h4>Pickup Address</h4>
                            ) : deliveryAddress?.delivery_type ===
                              "delivery" ? (
                              <h4>Delivery Address</h4>
                            ) : deliveryAddress?.delivery_type ===
                              "shipping" ? (
                              <h4>Shipping Address</h4>
                            ) : (
                              <h4>No Delivery/pickup</h4>
                            )}
                          </TypographyTextColor>

                          {deliveryAddress?.delivery_type ===
                          "No Delivery Pickup" ? (
                            "No delivery/pickup"
                          ) : (
                            <div className="summ_details">
                              <TypographyTextColor
                                Color="heading"
                                component="h6"
                              >
                                {deliveryAddress
                                  ? deliveryAddress.first_name +
                                    " " +
                                    deliveryAddress.last_name
                                  : ""}
                              </TypographyTextColor>

                              <span>
                                {deliveryAddress &&
                                deliveryAddress.delivery_type === "pickup" ? (
                                  <>
                                    {deliveryAddress &&
                                    deliveryAddress.appartment_name
                                      ? deliveryAddress.appartment_name
                                      : ""}
                                    {deliveryAddress &&
                                    deliveryAddress.street_name ? (
                                      <>
                                        <br></br>
                                        {"APT/FLOOR - " +
                                          deliveryAddress.street_name}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {deliveryAddress &&
                                    deliveryAddress.street_name
                                      ? deliveryAddress.street_name
                                      : ""}
                                    {deliveryAddress &&
                                    deliveryAddress.appartment_name ? (
                                      <>
                                        <br></br>
                                        {"APT/FLOOR - " +
                                          deliveryAddress.appartment_name}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <br></br>
                                  </>
                                )}

                                {deliveryAddress && deliveryAddress.city
                                  ? deliveryAddress.city + ", "
                                  : ""}
                                {deliveryAddress && deliveryAddress.state
                                  ? deliveryAddress.state + " "
                                  : ""}
                                {deliveryAddress && deliveryAddress.zipcode
                                  ? deliveryAddress.zipcode
                                  : ""}
                                <br></br>
                              </span>
                              <TypographyTextColor
                                Color="other"
                                className="summ_mobile"
                                component="span"
                              >
                                {deliveryAddress
                                  ? formatPhoneNumberCommon(
                                      deliveryAddress.phone
                                    )
                                  : ""}
                                {deliveryAddress &&
                                deliveryAddress.alternate_phone ? (
                                  <>
                                    {" / " +
                                      formatPhoneNumberCommon(
                                        deliveryAddress.alternate_phone
                                      )}
                                    <br></br>
                                  </>
                                ) : (
                                  ""
                                )}

                                {deliveryAddress &&
                                deliveryAddress.delivery_remark ? (
                                  <>
                                    <br></br>
                                    {"Delivery Remark : " +
                                      deliveryAddress.delivery_remark}{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </TypographyTextColor>

                              {deliveryAddress?.pickup_message === undefined ||
                              deliveryAddress?.pickup_message === "undefined" ||
                              deliveryAddress?.pickup_message === "" ||
                              deliveryAddress?.pickup_message === null ? (
                                ""
                              ) : (
                                <>
                                  <TypographyTextColor
                                    Color="other"
                                    component="span"
                                  >
                                    {deliveryAddress.pickup_message}
                                  </TypographyTextColor>
                                  <br />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="delivery_add summ_add"
                          id="method"
                          style={{ display: "none" }}
                        >
                          <div className="cust_head_main">
                            <TypographyTextColor Color="heading" component="h4">
                              Payable Amount , Method , Slot
                            </TypographyTextColor>
                          </div>
                          <div className="summ_details summ_add">
                            <TypographyTextColor Color="heading" component="h6">
                              $
                              {order && order?.amount
                                ? Number(order?.amount).toFixed(2)
                                : "0.00"}
                            </TypographyTextColor>

                            <span>
                              {order?.method === "cash_payment"
                                ? "Cash"
                                : order?.method === "pickup_pay"
                                ? "Pickup & Pay"
                                : "Credit Card"}
                            </span>
                            {order?.method === "card_payment" ||
                            order?.method === "credit-card" ? (
                              <span>
                                {" "}
                                : {order?.card_type} ({order?.card_number})
                              </span>
                            ) : (
                              <></>
                            )}
                            <br />
                            {deliveryAddress?.delivery_type ===
                              "No Delivery Pickup" ||
                            deliveryAddress?.delivery_type === "shipping" ? (
                              ""
                            ) : (
                              <>
                                <span>
                                  {" "}
                                  <span className="text-capitalize">
                                    {deliveryAddress?.delivery_type
                                      ? deliveryAddress?.delivery_type
                                      : ""}{" "}
                                    Date :{" "}
                                  </span>
                                  {deliveryAddress?.delivery_datetime
                                    ? deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[1] +
                                      "/" +
                                      deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[2] +
                                      "/" +
                                      deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[0]
                                    : ""}
                                </span>
                                <br />
                                <span>
                                  {" "}
                                  <span className="text-capitalize">
                                    {deliveryAddress?.delivery_type
                                      ? deliveryAddress?.delivery_type
                                      : ""}{" "}
                                    Time :{" "}
                                  </span>
                                  {deliveryAddress?.delivery_option
                                    ? deliveryAddress?.delivery_option ===
                                      "instant_delivery"
                                      ? "Instant Delivery"
                                      : deliveryAddress.delivery_option
                                    : ""}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* for pdf */}

                    <div className="row" data-html2canvas-ignore="true">
                      <div className="col-md-4">
                        <div className="delivery_add summ_add">
                          <TypographyTextColor
                            className="cust_head_main"
                            Color="heading"
                            component="div"
                          >
                            {deliveryAddress &&
                            deliveryAddress?.delivery_type === "pickup" ? (
                              <h4>Pickup Address</h4>
                            ) : deliveryAddress?.delivery_type ===
                              "delivery" ? (
                              <h4>Delivery Address</h4>
                            ) : deliveryAddress?.delivery_type ===
                              "shipping" ? (
                              <h4>Shipping Address</h4>
                            ) : (
                              <h4>No delivery/pickup</h4>
                            )}
                          </TypographyTextColor>
                          {deliveryAddress?.delivery_type ===
                          "No Delivery Pickup" ? (
                            "No delivery/pickup"
                          ) : (
                            <div className="summ_details">
                              <TypographyTextColor
                                Color="heading"
                                component="h6"
                              >
                                {deliveryAddress
                                  ? deliveryAddress?.first_name +
                                    " " +
                                    deliveryAddress?.last_name
                                  : ""}
                              </TypographyTextColor>

                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                {deliveryAddress &&
                                deliveryAddress?.delivery_type === "pickup" ? (
                                  <>
                                    {deliveryAddress &&
                                    deliveryAddress?.appartment_name
                                      ? deliveryAddress.appartment_name
                                      : ""}
                                    {deliveryAddress &&
                                    deliveryAddress?.street_name ? (
                                      <>
                                        <br></br>
                                        {"APT/FLOOR - " +
                                          deliveryAddress?.street_name}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {deliveryAddress &&
                                    deliveryAddress?.street_name
                                      ? deliveryAddress?.street_name
                                      : ""}
                                    {deliveryAddress &&
                                    deliveryAddress?.appartment_name ? (
                                      <>
                                        <br></br>
                                        {"APT/FLOOR - " +
                                          deliveryAddress?.appartment_name}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <br></br>
                                  </>
                                )}

                                {deliveryAddress && deliveryAddress.city
                                  ? deliveryAddress.city + ", "
                                  : ""}
                                {deliveryAddress && deliveryAddress.state
                                  ? deliveryAddress.state + " "
                                  : ""}
                                {deliveryAddress && deliveryAddress.zipcode
                                  ? deliveryAddress.zipcode
                                  : ""}
                                <br></br>
                              </TypographyTextColor>
                              <TypographyTextColor
                                Color="other"
                                className="summ_mobile"
                                component="span"
                              >
                                {deliveryAddress
                                  ? formatPhoneNumberCommon(
                                      deliveryAddress.phone
                                    )
                                  : ""}
                                {deliveryAddress &&
                                deliveryAddress.alternate_phone ? (
                                  <>
                                    {" / " +
                                      formatPhoneNumberCommon(
                                        deliveryAddress.alternate_phone
                                      )}
                                    <br></br>
                                  </>
                                ) : (
                                  ""
                                )}

                                {deliveryAddress &&
                                deliveryAddress.delivery_remark ? (
                                  <>
                                    <br></br>
                                    {"Delivery Remark : " +
                                      deliveryAddress.delivery_remark}{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </TypographyTextColor>

                              {deliveryAddress?.pickup_message === undefined ||
                              deliveryAddress?.pickup_message === "undefined" ||
                              deliveryAddress?.pickup_message === "" ||
                              deliveryAddress?.pickup_message === null ? (
                                ""
                              ) : (
                                <>
                                  <TypographyTextColor
                                    Color="other"
                                    component="span"
                                  >
                                    {deliveryAddress.pickup_message}
                                  </TypographyTextColor>
                                  <br />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="delivery_add summ_add">
                          <div className="cust_head_main">
                            <TypographyTextColor Color="heading" component="h4">
                              Order ID & Date{" "}
                            </TypographyTextColor>
                          </div>

                          <div className="summ_details">
                            <TypographyTextColor Color="heading" component="h6">
                              Order Id : {order ? "ORD" + order.order_id : ""}
                            </TypographyTextColor>
                            <TypographyTextColor Color="other" component="span">
                              Order Date :{" "}
                              {order && order.order_date
                                ? `${sendDateFormat(order.order_date)}

                           ${postfullTimeFormat(order.order_date)}`
                                : ""}
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="delivery_add summ_add">
                          <div className="cust_head_main">
                            <TypographyTextColor Color="heading" component="h4">
                              Payable Amount , Method{" "}
                              {deliveryAddress?.delivery_type ===
                              "No Delivery Pickup"
                                ? ""
                                : ", Slot"}
                            </TypographyTextColor>
                          </div>

                          <div className="summ_details summ_add">
                            <TypographyTextColor Color="heading" component="h6">
                              $
                              {order && order?.amount
                                ? Number(order.amount).toFixed(2)
                                : "0.00"}
                            </TypographyTextColor>
                            {order?.ebt_total_price !== 0 ||
                            order?.method
                              ?.split(",")
                              .includes("credit-card") ? (
                              <TypographyTextColor
                                className="card-msg"
                                Color="other"
                                component="span"
                              >
                                (Your card was temporarily authorized for $
                                {order && order?.amount
                                  ? Number(order.amount).toFixed(2)
                                  : "0.00"}
                                . You should see the hold removed, and a final
                                charge reflected on your statement within 3-7
                                business days (depending on your banks policies)
                                of order completion.)
                              </TypographyTextColor>
                            ) : (
                              <></>
                            )}
                            {order?.other_total_price !== 0 ? (
                              <>
                                {" "}
                                <TypographyTextColor
                                  Color="other"
                                  component="div"
                                >
                                  {order &&
                                  order?.method &&
                                  order?.method
                                    ?.split(",")
                                    .includes("cash_payment")
                                    ? "Cash"
                                    : order?.method
                                        ?.split(",")
                                        .includes("pickup_pay")
                                    ? "Pickup & Pay"
                                    : order?.method
                                        ?.split(",")
                                        .includes("credit-card")
                                    ? "Credit Card"
                                    : ""}

                                  {order?.method
                                    ?.split(",")
                                    .includes("card_payment") ||
                                  order?.method
                                    ?.split(",")
                                    .includes("credit-card") ? (
                                    <TypographyTextColor
                                      Color="other"
                                      component="span"
                                    >
                                      {" "}
                                      : {order?.card_type} ({order?.card_number}
                                      )
                                    </TypographyTextColor>
                                  ) : (
                                    <></>
                                  )}

                                  {order?.ebt_total_price !== 0 ||
                                  (order?.customer_credit_price !== 0 &&
                                    this.state.is_show_customer_credit)
                                    ? " $" + order?.other_total_price
                                    : ""}
                                </TypographyTextColor>
                              </>
                            ) : (
                              <></>
                            )}
                            {order?.customer_credit_price !== 0 &&
                            this.state.is_show_customer_credit ? (
                              <>
                                <TypographyTextColor
                                  Color="other"
                                  component="div"
                                >
                                  {"Customer Credits"}: $
                                  {parseFloat(
                                    order?.customer_credit_price
                                  ).toFixed(2)}
                                </TypographyTextColor>
                              </>
                            ) : (
                              <></>
                            )}
                            <TypographyTextColor Color="other" component="div">
                              {order?.ebt_total_price !== 0
                                ? "EBT : $" + order?.ebt_total_price
                                : ""}
                            </TypographyTextColor>

                            {deliveryAddress?.delivery_type ===
                              "No Delivery Pickup" ||
                            deliveryAddress?.delivery_type === "shipping" ||
                            deliveryAddress?.pickup_type === "no_date_time" ? (
                              ""
                            ) : (
                              <>
                                <TypographyTextColor
                                  Color="other"
                                  component="div"
                                >
                                  {" "}
                                  <span className="text-capitalize">
                                    {deliveryAddress?.delivery_type
                                      ? deliveryAddress?.delivery_type
                                      : ""}{" "}
                                    Date :{" "}
                                  </span>
                                  {deliveryAddress?.delivery_datetime
                                    ? deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[1] +
                                      "/" +
                                      deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[2] +
                                      "/" +
                                      deliveryAddress?.delivery_datetime?.split(
                                        "-"
                                      )[0]
                                    : ""}
                                </TypographyTextColor>

                                <TypographyTextColor
                                  Color="other"
                                  component="div"
                                >
                                  {" "}
                                  <span className="text-capitalize">
                                    {deliveryAddress?.delivery_type
                                      ? deliveryAddress.delivery_type
                                      : ""}{" "}
                                    Time :{" "}
                                  </span>{" "}
                                  {deliveryAddress?.delivery_option
                                    ? deliveryAddress?.delivery_option ===
                                      "instant_delivery"
                                      ? postfullTimeFormat(order.order_date)
                                      : deliveryAddress.delivery_option
                                    : ""}
                                </TypographyTextColor>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="delivery_add">
                          <div className="cust_head_main cust_no_mr">
                            <TypographyTextColor Color="heading" component="h4">
                              Order Details
                            </TypographyTextColor>
                          </div>
                          <div className="cart_inner_head order_succ_detail">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="cart_item">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Item Description
                                    </TypographyTextColor>
                                  </td>
                                  <td className="cart_quantity">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Quantity
                                    </TypographyTextColor>
                                  </td>
                                  <td className="cart_unit">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Unit Price
                                    </TypographyTextColor>
                                  </td>
                                  <td></td>
                                  <td className="cart_unit">
                                    <TypographyTextColor
                                      Color="heading"
                                      component="span"
                                    >
                                      Subtotal
                                    </TypographyTextColor>
                                  </td>
                                </tr>
                                {orderDetails && orderDetails.length > 0
                                  ? orderDetails.map((row, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="cart_inner_details">
                                              <div className="cart_inner_item">
                                                <div
                                                  className="smll_img"
                                                  onClick={() =>
                                                    this.redirectToDetailsPage(
                                                      row.slug,
                                                      true
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      row.image
                                                        ? row.image
                                                        : NO_IMAGE
                                                    }
                                                    alt=""
                                                    onError={(e) =>
                                                      addDefaultImageSrc(e)
                                                    }
                                                  />
                                                </div>
                                                <div className="cart_description">
                                                  <TypographyTextColor
                                                    Color="product"
                                                    component="span"
                                                    onClick={() =>
                                                      this.redirectToDetailsPage(
                                                        row.slug,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    {row.name === null
                                                      ? row.unlist_product_name
                                                      : row.name}
                                                    <h6>
                                                      {row.quantity > 0 &&
                                                      row.is_by_weight ===
                                                        true &&
                                                      row.estimate_weight_per_piece >
                                                        0
                                                        ? "est. " +
                                                          Number(
                                                            row.estimate_weight_per_piece *
                                                              row.quantity
                                                          ).toFixed(2) +
                                                          " LB / $" +
                                                          Number(
                                                            row.estimate_weight_per_piece *
                                                              row.quantity *
                                                              row.unit_price
                                                          ).toFixed(2)
                                                        : ""}
                                                      {row.container_type
                                                        ? row.container_type
                                                        : ""}
                                                    </h6>
                                                  </TypographyTextColor>
                                                  {row.note === "" ||
                                                  row.note === null ? (
                                                    <></>
                                                  ) : (
                                                    <div className="note_box">
                                                      {" "}
                                                      <p className="note">
                                                        {row.note.split(
                                                          "~"
                                                        )[0] === ""
                                                          ? row.note.split(
                                                              "~"
                                                            )[1]
                                                          : row.note
                                                              .split("~")
                                                              .join(", ")}
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="case_details">
                                            <TypographyTextColor
                                              Color="other"
                                              component="span"
                                            >
                                              {row.quantity}{" "}
                                              {row.is_case === true
                                                ? "Case"
                                                : ""}
                                            </TypographyTextColor>
                                          </td>
                                          <td className="unit_price">
                                            <TypographyTextColor
                                              Color="other"
                                              component="span"
                                            >
                                              ${row.unit_price.toFixed(2)}
                                              {row.is_by_weight === true
                                                ? " / LB"
                                                : ""}
                                            </TypographyTextColor>
                                          </td>
                                          <td>
                                            {" "}
                                            {this.state?.isShowEBT &&
                                            row.is_ebt ? (
                                              <img
                                                src={EBTLOGO}
                                                style={{
                                                  width: "45px",
                                                  padding: "5px",
                                                }}
                                                alt="ebtlogo"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="unit_price">
                                            <TypographyTextColor
                                              Color="other"
                                              component="span"
                                            >
                                              ${row.total.toFixed(2)}
                                            </TypographyTextColor>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>

                            <div className="cart-listing-details">
                              <div className="d-flex justify-content-between py-2">
                                <div>Quantity</div>
                                <div>Name</div>
                                {/* <div className="col-3 text-right">Price</div> */}
                                <div>Total</div>
                              </div>

                              {orderDetails && orderDetails.length > 0
                                ? orderDetails.map((row, index) => {
                                    return (
                                      <div className="cart_sub_details">
                                        <div className="cart_details">
                                          <div className="count_main">
                                            {row.quantity}{" "}
                                            {row.is_case === true ? "Case" : ""}
                                          </div>
                                          <div className="smll_img">
                                          
                                            <img
                                              className=""
                                              src={refineImgurl(row.image , {
                                             
                                                w: 100,
                                              })}
                                              // src={
                                              //   row.image ? row.image : NO_IMAGE
                                              // }
                                              alt=""
                                              onError={(e) =>
                                                addDefaultImageSrc(e)
                                              }
                                            />
                                          </div>
                                          <div className="cart_desc">
                                            <div className="cart_desc_name">
                                              <TypographyTextColor
                                                Color="product"
                                                component="span"
                                              >
                                                {row.name}
                                              </TypographyTextColor>
                                              <p>
                                                {row.note
                                                  ? row.note.substring(0, 100) +
                                                    `${
                                                      row.note.substring(0, 100)
                                                        .length > 100
                                                        ? "...."
                                                        : ""
                                                    }`
                                                  : ""}
                                              </p>
                                            </div>
                                            <div className="cart_details_count mx-2">
                                              <span className="prod_pieces mr-2">
                                                ${row.unit_price.toFixed(2)}
                                                {row.is_by_weight === true
                                                  ? " / LB"
                                                  : ""}
                                              </span>
                                              <span className="prod_cost">
                                                ${row.total.toFixed(2)}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}

                              <div className="cart_items_total">
                                <div className="sub_total">
                                  <div className="sub_desc">Subtotal</div>
                                  <div className="sub_total_count">$60.00</div>
                                </div>
                                <div className="cart_checkout">
                                  <div className="bttn-fill">
                                    <Link to className="bttn_grn">
                                      Checkout
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      id="hidden_tag"
                      style={{ display: "none" }}
                    >
                      <div className="col-md-6 ml-auto">
                        <div className="cart_main_order">
                          <div className="cart_order_summary_hidden">
                            <div className="order_summ">
                              <TypographyTextColor
                                Color="heading"
                                component="h5"
                              >
                                Order Summary
                              </TypographyTextColor>
                            </div>
                            <div className="summary_details">
                              <ul>
                                <li>
                                  <div className="summ_det_desc">
                                    <span>Subtotal</span>
                                  </div>
                                  <div className="summ_rate">
                                    <span>
                                      $
                                      {order
                                        ? Number(order?.subtotal_price).toFixed(
                                            2
                                          )
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                {order?.ebt_total_price !== 0 ? (
                                  <li style={{ fontSize: "12px" }}>
                                    <div className="summ_det_desc">
                                      <span> SNAP Eligible</span>
                                    </div>
                                    <div className="summ_rate">
                                      <span>
                                        $
                                        {order
                                          ? Number(
                                              order?.ebt_total_price
                                            ).toFixed(2)
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {order?.discount === 0 ? (
                                  <></>
                                ) : (
                                  <li>
                                    <div className="summ_det_desc">
                                      <span>Discount</span>
                                    </div>
                                    <div className="summ_rate">
                                      <span className="discount_rate">
                                        -$
                                        {order
                                          ? Number(order?.discount).toFixed(2)
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                )}
                                {deliveryAddress?.delivery_type ===
                                "shipping" ? (
                                  <li>
                                    <div className="summ_det_desc">
                                      <span>Shipping Charge</span>
                                    </div>
                                    <div className="summ_rate">
                                      <span>
                                        $
                                        {order
                                          ? Number(
                                              order?.shipping_charges
                                            ).toFixed(2)
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                ) : (
                                  <></>
                                )}
                                <li>
                                  <div className="summ_det_desc">
                                    <span>Estimated Tax</span>
                                  </div>
                                  <div className="summ_rate">
                                    <span>
                                      $
                                      {order
                                        ? Number(order?.tax).toFixed(2)
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                {order?.extra_charges_price !== 0 ? (
                                  <li>
                                    <div className="summ_det_desc">
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {order?.extra_charges_name}
                                      </span>
                                    </div>
                                    <div className="summ_rate">
                                      <span>
                                        {order ? order?.unit : ""}{" "}
                                        {order && order?.extra_charges_price > 0
                                          ? Number(
                                              order?.extra_charges_price
                                            ).toFixed(2)
                                          : "0.00"}
                                      </span>
                                    </div>
                                  </li>
                                ) : (
                                  <></>
                                )}
                                {order?.delivery_charges === 0 ? (
                                  <></>
                                ) : (
                                  <li>
                                    <div className="summ_det_desc">
                                      <span>Delivery Charges</span>
                                    </div>
                                    <div className="summ_rate">
                                      <span>
                                        $
                                        {order
                                          ? Number(
                                              order.delivery_charges
                                            ).toFixed(2)
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </ul>
                              {this.state.extraCharges.tip_for_packer ===
                                false &&
                              this.state.extraCharges.tip_for_delivery_boy ===
                                false &&
                              this.state.extraCharges.donation === false ? (
                                <></>
                              ) : (
                                <ul className="tip_don">
                                  {this.state.extraCharges.tip_for_packer ===
                                  true ? (
                                    <li>
                                      <div className="summ_det_desc">
                                        <span>Tip for Packer</span>
                                      </div>
                                      <div className="summ_rate">
                                        <span>
                                          $
                                          {order
                                            ? Number(
                                                order.tip_for_packer
                                              ).toFixed(2)
                                            : ""}
                                        </span>
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.extraCharges
                                    .tip_for_delivery_boy === true ? (
                                    <li>
                                      <div className="summ_det_desc">
                                        <span>Delivery Tip</span>
                                      </div>
                                      <div className="summ_rate">
                                        <span>
                                          $
                                          {order
                                            ? Number(
                                                order.tip_for_delivery_boy
                                              ).toFixed(2)
                                            : ""}
                                        </span>
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.extraCharges.donation === true ? (
                                    <li>
                                      <div className="summ_det_desc">
                                        <span>
                                          Donation(
                                          {
                                            this.state.extraCharges
                                              .donation_name
                                          }
                                          )
                                        </span>
                                      </div>
                                      <div className="summ_rate">
                                        <span>
                                          $
                                          {order && order.donation
                                            ? Number(order.donation).toFixed(2)
                                            : "0.00"}
                                        </span>
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              )}
                            </div>
                            <div className="summ_total">
                              <div className="cart_total">
                                <span>Total</span>
                              </div>
                              <div className="total_price">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  $
                                  {order ? Number(order.amount).toFixed(2) : ""}
                                </TypographyTextColor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart_main_order" data-html2canvas-ignore="true">
                  <div
                    className="cart_order_summary"
                    style={{ border: "1px solid #d6d6d6" }}
                  >
                    <div className="order_summ">
                      <TypographyTextColor Color="heading" component="h5">
                        {" "}
                        Order Summary
                      </TypographyTextColor>
                    </div>
                    <div className="summary_details">
                      <ul>
                        <li>
                          <div className="summ_det_desc">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Subtotal
                            </TypographyTextColor>
                          </div>
                          <div className="summ_rate">
                            <TypographyTextColor Color="other" component="span">
                              $
                              {order
                                ? Number(order.subtotal_price).toFixed(2)
                                : ""}
                            </TypographyTextColor>
                          </div>
                        </li>
                        {order?.ebt_total_price !== 0 ? (
                          <li style={{ fontSize: "12px" }}>
                            <div className="summ_det_desc">
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                              >
                                {" "}
                                SNAP Eligible
                              </TypographyTextColor>
                            </div>
                            <div className="summ_rate">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                $
                                {order
                                  ? Number(order?.ebt_total_price).toFixed(2)
                                  : ""}
                              </TypographyTextColor>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {order?.discount === 0 ? (
                          <></>
                        ) : (
                          <li>
                            <div className="summ_det_desc">
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                              >
                                Discount
                              </TypographyTextColor>
                            </div>
                            <div className="summ_rate">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                                className="discount_rate"
                              >
                                -$
                                {order ? Number(order.discount).toFixed(2) : ""}
                              </TypographyTextColor>
                            </div>
                          </li>
                        )}
                        {deliveryAddress?.delivery_type === "shipping" ? (
                          <li>
                            <div className="summ_det_desc">
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                              >
                                Shipping Charge
                              </TypographyTextColor>
                            </div>
                            <div className="summ_rate">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                $
                                {order
                                  ? Number(order.shipping_charges).toFixed(2)
                                  : ""}
                              </TypographyTextColor>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}
                        <li>
                          <div className="summ_det_desc">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Estimated Tax
                            </TypographyTextColor>
                          </div>
                          <div className="summ_rate">
                            <TypographyTextColor Color="other" component="span">
                              ${order ? Number(order.tax).toFixed(2) : ""}
                            </TypographyTextColor>
                          </div>
                        </li>
                        {order?.extra_charges_price !== 0 ? (
                          <li>
                            <div className="summ_det_desc">
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                                style={{ textTransform: "capitalize" }}
                              >
                                {order?.extra_charges_name}
                              </TypographyTextColor>
                            </div>
                            <div className="summ_rate">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                {order ? order?.unit : ""}{" "}
                                {order && order?.extra_charges_price > 0
                                  ? Number(order?.extra_charges_price).toFixed(
                                      2
                                    )
                                  : "0.00"}
                              </TypographyTextColor>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}
                        {order?.delivery_charges === 0 ? (
                          <></>
                        ) : (
                          <li>
                            <div className="summ_det_desc">
                              <TypographyTextColor
                                Color="heading"
                                component="span"
                              >
                                Delivery Charges
                              </TypographyTextColor>
                            </div>
                            <div className="summ_rate">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                $
                                {order
                                  ? Number(order.delivery_charges).toFixed(2)
                                  : ""}
                              </TypographyTextColor>
                            </div>
                          </li>
                        )}
                      </ul>
                      {this.state.extraCharges.tip_for_packer === false &&
                      this.state.extraCharges.tip_for_delivery_boy === false &&
                      this.state.extraCharges.donation === false ? (
                        <></>
                      ) : (
                        <ul className="tip_don">
                          {this.state.extraCharges.tip_for_packer === true ? (
                            <li>
                              <div className="summ_det_desc">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Tip for Packer
                                </TypographyTextColor>
                              </div>
                              <div className="summ_rate">
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  $
                                  {order
                                    ? Number(order.tip_for_packer).toFixed(2)
                                    : ""}
                                </TypographyTextColor>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.extraCharges.tip_for_delivery_boy ===
                          true ? (
                            <li>
                              <div className="summ_det_desc">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Delivery Tip
                                </TypographyTextColor>
                              </div>
                              <div className="summ_rate">
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  $
                                  {order
                                    ? Number(
                                        order.tip_for_delivery_boy
                                      ).toFixed(2)
                                    : ""}
                                </TypographyTextColor>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.extraCharges.donation === true ? (
                            <li>
                              <div className="summ_det_desc">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Donation(
                                  {this.state.extraCharges.donation_name})
                                </TypographyTextColor>
                              </div>
                              <div className="summ_rate">
                                <TypographyTextColor
                                  Color="other"
                                  component="span"
                                >
                                  $
                                  {order && order.donation
                                    ? Number(order.donation).toFixed(2)
                                    : "0.00"}
                                </TypographyTextColor>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="summ_total">
                      <div className="cart_total">
                        <TypographyTextColor Color="heading" component="span">
                          Total
                        </TypographyTextColor>
                      </div>
                      <div className="total_price">
                        <TypographyTextColor Color="heading" component="span">
                          ${order ? Number(order.amount).toFixed(2) : ""}
                        </TypographyTextColor>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bttn-fill bttn_mob_order"
                    id="no-print"
                    style={{ margin: "30px 0 0 20px" }}
                  >
                    <SecondaryButton
                      className="bttn_grn"
                      onClick={() => window.print()}
                      Fonttype="other"
                    >
                      <span className="icon-print"></span>Print Confirmation
                    </SecondaryButton>
                    <SecondaryCancelButton
                      className="bttn_grn bttn_trans"
                      onClick={() =>
                        this._exportPdf(
                          "download",
                          order.order_id,
                          this.state.logo
                        )
                      }
                      Fonttype="other"
                    >
                      <span className="icon-pdf"></span>Save PDF
                    </SecondaryCancelButton>
                    <PrimaryButton
                      onClick={() => {
                        localStorage.removeItem("slot");
                        if (
                          localStorage.getItem(
                            "UserDetail" +
                              JSON.parse(localStorage.getItem("Store_address"))
                                ?.id
                          ) !== null
                        ) {
                          this.logoutCustomerBySr();
                        }
                        // else{
                        //   localStorage.removeItem("access_token");
                        //   localStorage.setItem("listOpen", false);
                        //   localStorage.removeItem("email");
                        //   localStorage.setItem("isLogin", false);
                        //   localStorage.removeItem("name");
                        //   localStorage.removeItem("isWebsiteVisited");
                        // }
                        this.props.history.push({ pathname: "/" });
                        window.location.reload();
                      }}
                      className="bttn_grn bttn_shop"
                      Fonttype="other"
                    >
                      Continue Shopping
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </section>
            <div id="no-print">
              <Footer emailSubscribe={this.state.emailSubscribe} />
            </div>
          </div>
        )}
      </ScrollColorTypography>
    );
  }
}

export default OrderSuccessful;
