import React from "react";
import Header from "./common/Header/header";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import Footer from "./common/Footer/footer";
import {
  SettingsSetValue,
  GetSettingsData,
  GetProfileDetails,
} from "../actions/profile/profileAction";

import {
  CategeriesListView,
  UpdateCartFunctionlity,
} from "../actions/home/homeAction";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import { RefreshLogin } from "../actions/login/loginAction";
import {
  RefreshApiToken,
  SinglecheckImageUrls,
  checkImageUrls,
  getSchedule_zip,
  sweetAlertBox,
} from "../utils/renderUtils/renderUtil";
import Commonsnackbar from "./common/commonsnackbar";
import CustomPreloader from "./common/Loader/CustomPreloader";
import {
  ScrollColorTypography,
  TypographySecondaryBgColor,
  TypographyTextColor,
} from "./common/UiDesign";
import { Typography } from "@mui/material";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import ApiUrls from "../utils/apiUtils/apiUrl";
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      success: false,
      error: false,
      settingData: [],
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      pageName: "settingPage",
      mobileViewHide: true,
      CategoryOption: [],
      settingCartItemList: [],
      SubTotal: 0,
      headerinputField: false,
      logo: "",
      profileDetails: [],
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      footerDetails: [],
      mainLoader: true,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }
  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    //;
    document.body.style.overflow = "hidden";
    let LogoNav = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    LogoNav = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.FetchSettingData();
    this.FetchCategoryList();
    this.FetchCartItemList();
    this.FetchProfileDetails();
    this.ExtraChargesFunctiolity();
    this.setState({ logo: LogoNav });
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      let settingCartItemList = [];
      if (res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }

      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          const promises = respone.map((ele) => {
            let localUrl = ele?.cloudinary_images_local;
            let globalUrl = ele?.cloudinary_images_global; //`${CLOUD_URL}global/product/${slugifyStr(
            let currentUrl =
              ele.image !== null
                ? ele.image[0] === "/"
                  ? `${ApiUrls.BASH_URL}/media${ele.image}`
                  : ele.image
                : NO_IMAGE;

            return checkImageUrls(localUrl, globalUrl, currentUrl).then(
              (url) => {
                ele.image = url.url;
                ele.valid = url.valid;
                return ele;
              }
            );
          });
          Promise.all(promises).then((updatedCartItems) => {
            updatedCartItems.forEach((item) => {
              settingCartItemList.push(item);
              SubTotal += Number(item.total_price);
            });

            if (res.data.total > 0) {
              CartCount.item = res.data.total;
              CartCount.price = Number(res.data.total_subtotal).toFixed(2);
            }

            this.setState({
              SubTotal: SubTotal.toFixed(2),
              settingCartItemList: settingCartItemList,
              CartCount: CartCount,
              cartLoader: false,
            });
          });
        }
      }
      this.setState({
        cartLoader: false,
      });
    });
  };

  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  SettingsActiveInactive = (field_name, field_value) => {
    let settingData = this.state.settingData;
    let formValues = { field_name: field_name, field_value: field_value };
    if (field_name === "email_notification") {
      settingData.email_notification = field_value;
    } else if (field_name === "sms_notification") {
      settingData.sms_notification = field_value;
    } else if (field_name === "promotion_and_newslatter") {
      settingData.promotion_and_newslatter = field_value;
    }
    this.setState({ settingData });
    SettingsSetValue(formValues, this.props.dispatch).then((res) => {
      
      if (res) {
        this.createSuccessMsg("success", res.message);
        // window.location.reload();
        this.FetchSettingData();
      } else if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      } else {
        this.createSuccessMsg("error", res.message);
      }
    });
  };

  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ settingData: settingData, mainLoader: false });
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg.email && msg.email[0]) {
        sweetAlertBox("Attention!!", msg.email[0], "info");
        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      } else if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Attention!!", msg.phone[0], "info");
        // this.setState({
        //   error: msg.phone[0],
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    console.log("hitt");
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let settingCartItemList = this.state.settingCartItemList;
    if (icons === "icon-plus") {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          settingCartItemList[i].qty = settingCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            settingCartItemList[i].qty,
            settingCartItemList[i].is_case,
            settingCartItemList[i].note,
            settingCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          settingCartItemList[i].qty = settingCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].note,
              settingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              settingCartItemList[i].qty,
              added_qty_is_case,
              settingCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < settingCartItemList.length; i++) {
        if (settingCartItemList[i].id === index) {
          // settingCartItemList[i].qty = added_quantity;
          settingCartItemList[i].qty = added_quantity
            ? added_quantity
            : settingCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].note,
              settingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              settingCartItemList[i].qty,
              settingCartItemList[i].is_case,
              settingCartItemList[i].container_type
            );
          }
        }
      }
      // this.setState({headerinputField: false});
    }
    this.setState({
      settingCartItemList: settingCartItemList,
      headerinputField: false,
    });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: note ? note : "",
      container_type: container_type ? container_type : "",
    };
    let settingCartItemList = this.state.settingCartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < settingCartItemList.length; i++) {
          if (settingCartItemList[i].product_id === product_id) {
            settingCartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(settingCartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({
        disabled: false,
        settingCartItemList,
        SubTotal: SubTotal,
      });
    });
  };
  deleteCartItem = (product_id, added_quantity, is_case, container_type) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };
  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    this.props.history.push({
      pathname: "/listing/" + e,
      state: {
        category_id: e,
        parent_id: parent_id,
        // parentIds: this.state.parentIds,
        // parent_onlyid: this.state.parent_onlyid,
        // parent_level: this.state.parent_level,
      },
    });
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }

  render() {
    const {
      success,
      error,
      settingData,
      CategoryDetails,
      CartCount,
      CategoryOption,
      settingCartItemList,
      web_customize,
    } = this.state;
    return (
      <ScrollColorTypography component="div" className="main-content">
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          cartItemList={settingCartItemList}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
          FetchCartCount={() => this.FetchCartItemList()}
          AddCountForcartList={(
            product_id,
            added_quantity,
            is_case,
            icons,
            index,
            container_type
          ) =>
            this.AddCountForShoppingcartList(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            )
          }
          changeheaderinputFieldValue={() => this.changeheaderinputFieldValue()}
          headerinputField={this.state.headerinputField}
          logo={this.state.logo}
          extraCharges={this.state.extraCharges}
          handleInnerOpenDialog={(
            catId,
            subcategory_name,
            has_child,
            level,
            parent_id
          ) =>
            this.handleInnerOpenDialog(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            )
          }
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        {this.state.mainLoader ? (
          <div className="main_containerLoader">
            <CustomPreloader />
          </div>
        ) : (
          <div
            className="main-data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="user_details">
              <div className="user_main_wrapper">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  profileDetails={this.state.profileDetails}
                  openDetailsMenu={() => this.openDetailsMenu()}
                />
                <div className="user_details_desc user_detail_new order_main_wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="cust_head_main cust_hide">
                        <TypographyTextColor Color="heading" component="h4">
                          Settings
                        </TypographyTextColor>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="switch_wrapper">
                        <div className="settings_content">
                          <div className="setting_switch">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={settingData.email_notification}
                                onChange={(e) =>
                                  this.SettingsActiveInactive(
                                    "email_notification",
                                    e.target.checked
                                  )
                                }
                              />
                              <Typography
                                component={
                                  settingData.email_notification
                                    ? TypographySecondaryBgColor
                                    : ""
                                }
                                className="slider"
                              ></Typography>
                            </label>
                          </div>
                          <div className="setting_details">
                            <TypographyTextColor Color="other" component="span">
                              Email Notifications
                            </TypographyTextColor>
                          </div>
                        </div>
                        <div className="settings_content">
                          <div className="setting_switch">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={settingData.sms_notification}
                                onChange={(e) =>
                                  this.SettingsActiveInactive(
                                    "sms_notification",
                                    e.target.checked
                                  )
                                }
                              />
                              <Typography
                                component={
                                  settingData.sms_notification
                                    ? TypographySecondaryBgColor
                                    : ""
                                }
                                className="slider"
                              ></Typography>
                            </label>
                          </div>
                          <div className="setting_details">
                            <TypographyTextColor Color="other" component="span">
                              SMS Notifications
                            </TypographyTextColor>
                          </div>
                        </div>
                        <div className="settings_content">
                          <div className="setting_switch">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={settingData.promotion_and_newslatter}
                                onChange={(e) =>
                                  this.SettingsActiveInactive(
                                    "promotion_and_newslatter",
                                    e.target.checked
                                  )
                                }
                              />
                              <Typography
                                component={
                                  settingData.promotion_and_newslatter
                                    ? TypographySecondaryBgColor
                                    : ""
                                }
                                className="slider"
                              ></Typography>
                            </label>
                          </div>
                          <div className="setting_details">
                            <TypographyTextColor Color="other" component="span">
                              Promotions & Newsletters
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer history={this.props.history} />
            {/* <Subscribe  history={this.props.history}/>
          <Footer/> */}
          </div>
        )}
        {this.state.success === false && this.state.error === false ? (
          ""
        ) : (
          <Commonsnackbar
            success={this.state.success}
            handleClose={this.handleClose}
            error={this.state.error}
          />
        )}
      </ScrollColorTypography>
    );
  }
}
export default Settings;
