import React from "react";
import ERROR_IMG from "../../assets/images/oops-something-went-wrong-large.png";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "", errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ errorInfo });
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={ERROR_IMG} alt="OOPS" />
          <p
            style={{
              position: "absolute",
              bottom: 0,
              fontSize: "15px",
              width: " 50%",
              fontWeight: 900,
            }}
          >{`${this.state.errorMessage} url is (${window?.location?.href})`}</p>
          {/* <p>{this.state?.errorInfo?.componentStack}</p> */}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
