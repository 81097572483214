import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
  } from "@mui/material";
const CommonAdvertisementDialoag = (props) => {
  return (
    <>
    <Dialog
      open={props.open}
      onClose={props.handleCloseOpnDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      className="popUp"
    ><DialogTitle
    style={{
      display: "flex",
      justifyContent: "end",
    padding:"0px"
    }}
  >
    <>

        <IconButton
          onClick={()=>props.handleCloseOpnDialog()}
         
        >
          <span class="icon-close"  style={{ color: "#389978" ,fontSize:'18px'}}></span>
        </IconButton>
      
    </>
  </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.Content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>props.handleCloseOpnDialog()} color="primary">
          Ok
        </Button>
       
      </DialogActions>
    </Dialog>

  

  </>
  )
}

export default CommonAdvertisementDialoag