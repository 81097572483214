import { SubmissionError } from "redux-form";
import { ForgetPassword, LoginApplication, ResetPassword } from "../../actions/login/loginAction";

const submitForgetPassword = (e, values, dispatch, props) => {
  var formValues = {
    key: values.key,
    token: values.token,
    password: values.password,
    confirm_password: values.cmf_password,
  };
  console.log(e,values);
if(e.state.isUrlValid){
  return ResetPassword(formValues, dispatch)
    .then((res) => {
      if (res.errors) {
        if (res.errors.data) {
          e.createSuccessMsg("error", res.errors.data.errors);
        } else {
          throw new SubmissionError(res.errors.data.errors);
        }
      } else {
        
        let fomrData={
          username:e.state.userEmail,
          password:values.password
        }
        return LoginApplication(fomrData, dispatch).then((response) => {
          if (response.status === 200) {
            
            e.createSuccessMsg("success", "Password changed successfully");
            localStorage.removeItem("Guest_userid");
            if (values.remember_me) {
              localStorage.setItem("RememberMe", true);
            } else {
              localStorage.removeItem("RememberMe");
              localStorage.removeItem("DeviceId");
            }
            
          } else if (response.status === 401 && "code" in response) {
         
          } else {
            if (localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) !== null) {
              e.handleDialogueBox();
            } else
              e.createSuccessMsg(
                "error",
                "Login failed, Invalid username or password"
              );
            throw new SubmissionError(response.errors);
          }
        });
       
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  else{
    let data={
      email_address:e.state.userEmail
    }
    return ForgetPassword(data, dispatch).then((response) => {
      if (response.status === 200) {
        e.createSuccessMsg("success", "A reset link has been sent your mail id");
      } else {
        e.createSuccessMsg("error", " Please type the register mail id");
      }
    });
  }
};

export default submitForgetPassword;
