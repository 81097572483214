import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";

import { RefreshLogin } from "../actions/login/loginAction";
import {
  UpdateCartFunctionlity,
  SubCategeriesListView,
} from "../actions/home/homeAction";
import { OtpPhoneVerifing, SendOtpApi } from "../actions/profile/profileAction";
import {
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import profileSubmit from "./profileSubmit";
import {
  Snackbar,
  IconButton,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  sufixIconInputForProfile,
  renderSelect2Input,
  radioButton,
  renderHiddenField,
  CommonLogoutFunctionlity,
  sufixIconInputButtonTwo,
  sufixIconInputForaddressMobile,
  commonPageTitel,
  renderNumberInputForZipCode,
  renderSelect3Input,
  generateDeviceId,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  getSchedule_zip,
  RefreshApiToken,
  sweetAlertBox,
  SinglecheckImageUrls,
  checkImageUrls,
} from "../utils/renderUtils/renderUtil";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import {
  GetallAddress,
  GetAddressDetailsByID,
  SetDefaultAddress,
  RemoveAddressFunctionlity,
  GetProfileDetails,
  GetSettingsData,
} from "../actions/profile/profileAction";
import { CategeriesListView } from "../actions/home/homeAction";
import deliveryaddressSubmit from "./deliveryaddress/deliveryaddressSubmit";
import { CartListFunctionlity } from "../actions/cart/cartAction";
import { State_Territory } from "../utils/renderUtils/dbData";
import CustomPreloader from "./common/Loader/CustomPreloader";
import EditIcon from "@mui/icons-material/Edit";
import {
  ScrollColorTypography,
  SecondaryButton,
  SecondaryCancelButton,
  TypographyFontColor,
  TypographyTextColor,
} from "./common/UiDesign";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import ApiUrls from "../utils/apiUtils/apiUrl";
class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      isLogin: false,
      openSearch: false,
      openCategory: false,
      open: false,
      addressOpne: false,
      addressDetails: [],
      city: [],
      state: [],
      countryList: [],
      success: false,
      error: false,
      CategoryDetails: [],
      cartLoader: true,
      CartCount: [],
      moreOption: false,
      getAddresDetails: [],
      mobileViewHide: true,
      openDialog: false,
      address_id: "",
      address_type: true,
      selected: "Home",
      CategoryOption: [],
      MobileaddressOpen: false,
      profileDetails: [],
      msgPassworderror: false,
      msgPasswordsuccess: false,
      editProfileDetails: false,
      feildTypeNewPassword: true,
      feildTypePassword: true,
      feildTypeConfirmPassword: true,
      orderCartItemList: [],
      headerinputField: false,
      pageName: "myAddressPage",
      hiddenCityList: [],
      logo: "",
      top_menu: [],
      edit_address: false,
      openOtpDialog: false,
      generateOtp: ["", "", "", "", "", ""],
      mobileNumber: "",
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      seletectedstate: {},
      allstate: [],
      MainLoader: true,
      emailSubscribe: "",
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.isLogin = this.loginOptions.bind(this);
    this.searchOpen = this.searchOpen.bind(this);
    this.categoryOpen = this.categoryOpen.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    // this.timer = setInterval(() => this.tick(), props.timeout || 1000);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleSubmit(value, dispatch) {
    let Device_id = generateDeviceId();
    let formValues = {
      phone: this.state.mobileNumber,
      otp: value,
      device_id: Device_id,
    };

    return OtpPhoneVerifing(formValues, dispatch)
      .then((res) => {
        if (res.errors) {
          if (res.errors.data) {
            this.createSuccessMsg("error", res.errors.data);
            document.getElementById("otp_1").focus();

            this.setState({ generateOtp: ["", "", "", "", "", ""] });
          } else {
            throw new SubmissionError(res.errors);
          }
        } else {
          // this.createSuccessMsg("success", res.message);
          this.setState({
            openOtpDialog: false,
            generateOtp: ["", "", "", "", "", ""],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  SendOtp = (mobile_number) => {
    let Device_id = generateDeviceId();
    let formValues = {
      phone: mobile_number.replace(/ +/g, ""),
      device_id: Device_id,
    };
    return SendOtpApi(formValues)
      .then((res) => {
        if (res.errors) {
          if (res.errors.data) {
            this.createSuccessMsg("error", res.errors.data);
            // this.props.change("hidden_mobile_number", '');
          } else {
            throw new SubmissionError(res.errors);
          }
        } else {
          // this.createSuccessMsg("success", res.message);
          this.setState({ openOtpDialog: true, mobileNumber: mobile_number });
          document.getElementById("otp_1").focus();
          this.props.change("hidden_mobile_number", mobile_number);
          // this.setState({countdown : 45})
          // this.tick();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleMenu() {
    this.setState({ toggleMenu: !this.state.toggleMenu });
  }
  loginOptions() {
    this.setState({ isLogin: !this.state.isLogin });
  }
  searchOpen() {
    this.setState({ openSearch: !this.state.openSearch });
  }
  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }

  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }

  clearNewAddressdata = () => {
    this.props.change("address_id", "");
    this.props.change("first_name", "");
    this.props.change("last_name", "");
    this.props.change("apartment_name", "");
    this.props.change("street_name", "");
    this.props.change("zipcode", "");
    this.props.change("mobile_no", "");
    this.props.change("alternate_mobile", "");
    this.props.change("delivery_remark", "");
    this.props.change("hidden_city_id", "");
    this.props.change("state_id", "");
    this.props.change("city_id", "");
    this.props.change("address_type", "Home");
  };
  addNewaddressOpen = () => {
    this.setState({
      addressOpne: true,
      editProfileDetails: false,
      edit_address: false,
      seletectedstate: {},
    });
    this.clearNewAddressdata();
  };

  addNewaddressClose = () => {
    this.props.reset();
    // this.props.change("state_id", "");
    // this.props.change("city_id", "");
    // this.props.change("hidden_city_id", "");
    this.setState({ addressOpne: false, edit_address: false });
    window.scrollTo(0, 0);
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, this.props.dispatch).then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    //;
    document.body.style.overflow = "hidden";
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    if (accessToken && isLogin === "true") {
      this.FetchAddress();
      // this.getCountrylistDebounce();
      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchSettingData();
      this.FetchProfileDetails();
    }
    this.setState({ logo: logo, allstate: State_Territory });
  }

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    CommonLogoutFunctionlity();
    this.props.history.push({
      pathname: "/",
    });
  };

  handleChnageState = (e) => {
    this.setState({
      seletectedstate: { label: e.value, value: e.value },
    });
  };
  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let CartCount = [];
      let orderCartItemList = [];
      if (res.data && res.data.total > 0) {
        CartCount.item = res.data.total;
        CartCount.price = Number(res.data.total_subtotal).toFixed(2);
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }
      if (res.data && res.data.result) {
        const respone = res.data.result;
        if (respone.length > 0) {
          if (respone.length > 0) {
            respone.forEach((item) => {
              orderCartItemList.push(item);
              SubTotal += Number(item.total_price);
            });
          }
        }
        if (res.data.total > 0) {
          CartCount.item = res.data.total;
          CartCount.price = Number(res.data.total_subtotal).toFixed(2);
        }

        this.setState({
          SubTotal: SubTotal.toFixed(2),
          orderCartItemList,
          cartListOpen: true,
          CartCount: CartCount,
          cartLoader: false,
        });
        // });
      }

      this.setState({
        cartLoader: false,
      });
    });
  };

  FetchAddress = () => {
    let formValue = { store_address: "" };
    GetallAddress(formValue, this.props.dispatch).then((res) => {
      let addressDetails = [];
      if (res.data && res.data.result) {
        addressDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddress();
            }
          );
        }
      }
      this.setState({
        addressDetails: addressDetails,
        MainLoader: false,
      });
    });
  };

  FetchAddressDetails = (address_id) => {
    let formValue = {
      id: address_id,
    };
    GetAddressDetailsByID(formValue, this.props.dispatch).then((res) => {
      let getAddresDetails = [];
      if (res.data && res.data.result) {
        getAddresDetails = res.data.result;
      }
      this.setState({
        getAddresDetails: getAddresDetails,
      });
      this.props.change("address_id", address_id);
      this.props.change("first_name", getAddresDetails.first_name);
      this.props.change("last_name", getAddresDetails.last_name);

      this.props.change("apartment_name", getAddresDetails.appartment_name);
      this.props.change("street_name", getAddresDetails.street_name);
      this.props.change("city_id", getAddresDetails.city_name);
      this.props.change("state_id", {
        label: getAddresDetails.state_name,
        value: getAddresDetails.state_name,
      });
      this.props.change("zipcode", getAddresDetails.zipcode);
      this.props.change("mobile_no", getAddresDetails.phone);
      this.props.change("alternate_mobile", getAddresDetails.alternate_phone);
      this.props.change("delivery_remark", getAddresDetails.delivery_remark);
      this.props.change("address_type", getAddresDetails.address_type);
      this.setState({
        seletectedstate: {
          label: getAddresDetails.state_name,
          value: getAddresDetails.state_name,
        },
      });
    });
  };

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg.delivery_datetime && msg.delivery_datetime[0]) {
        sweetAlertBox("Attention!!", msg.delivery_datetime[0], "info");
        // this.setState({
        //   error: msg.delivery_datetime[0],
        //   success: false,
        // });
      } else if (msg.delivery_option && msg.delivery_option[0]) {
        sweetAlertBox("Attention!!", msg.delivery_option[0], "info");
        // this.setState({
        //   error: msg.delivery_option[0],
        //   success: false,
        // });
      } else if (msg.address_type && msg.address_type[0]) {
        sweetAlertBox(
          "Attention!!",
          "Address type is not a valid choice",
          "info"
        );
        // this.setState({
        //   error: "Address type is not a valid choice",
        //   success: false,
        // });
      } else if (msg.city && msg.city[0]) {
        sweetAlertBox("Attention!!", "City is required", "info");
        // this.setState({
        //   error: "City is required",
        //   success: false,
        // });
      } else if (msg.current_password && msg.current_password[0]) {
        sweetAlertBox("Attention!!", msg.current_password[0], "info");
        // this.setState({
        //   error: msg.current_password[0],
        //   success: false,
        // });
      } else if (msg.zipcode && msg.zipcode[0]) {
        sweetAlertBox("Attention!!", msg.zipcode[0], "info");
        // this.setState({
        //   error: msg.zipcode[0],
        //   success: false,
        // });
      } else if (msg.errors && msg.errors.id[0]) {
        sweetAlertBox("Attention!!", msg.errors.id[0], "info");
        // this.setState({
        //   error: msg.errors.id[0],
        //   success: false,
        // });
      } else if (msg.errors && msg.errors.phone[0]) {
        sweetAlertBox("Attention!!", msg.errors.phone[0], "info");
        // this.setState({
        //   error: msg.errors.phone[0],
        //   success: false,
        // });
      } else if (msg.id && msg.id[0]) {
        sweetAlertBox("Attention!!", msg.id[0], "info");
        // this.setState({
        //   error: msg.id[0],
        //   success: false,
        // });
      } else if (msg.phone && msg.phone[0]) {
        sweetAlertBox(
          "Attention!!",
          "Please enter 10 digit valid Phone no",
          "info"
        );
        // this.setState({
        //   error:  "Please enter 10 digit valid Phone no",
        //   success: false,
        // });
      } else if (msg.alternate_phone && msg.alternate_phone[0]) {
        sweetAlertBox(
          "Attention!!",
          "Please enter 10 digit valid Alternate no",
          "info"
        );
        // this.setState({
        //   error: "Please enter 10 digit valid Alternate no",
        //   success: false,
        // });
      } else if (msg.first_name && msg.first_name[0]) {
        sweetAlertBox(
          "Attention!!",
          "Ensure First name should have atleast 2 letters",
          "info"
        );
        // this.setState({
        //   error: "Ensure First name should have atleast 2 letters",
        //   success: false,
        // });
      } else if (msg.email && msg.email[0]) {
        sweetAlertBox("Attention!!", msg.email[0], "info");

        // this.setState({
        //   error: msg.email[0],
        //   success: false,
        // });
      } else if (msg.message) {
        sweetAlertBox("Attention!!", msg.message, "info");
        // this.setState({
        //   error: msg.message,
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      this.setState({
        // error: false,
        // success: msg,
        addressOpne: false,
        editProfileDetails: false,
      });
      this.FetchAddress();
      this.FetchProfileDetails();
      window.scrollTo(0, 0);
    }
  };

  OnClickMoreOption = (address_id) => {
    let addressDetails = this.state.addressDetails;
    for (let i = 0; i < addressDetails.length; i++) {
      if (addressDetails[i].id === address_id) {
        addressDetails[i].moreOption = !addressDetails[i].moreOption;
      } else {
        addressDetails[i].moreOption = false;
      }
      this.setState({ addressDetails });
    }
  };

  editAddress = (address_id) => {
    let addressDetails = this.state.addressDetails;
    let findAddressIndex = addressDetails.findIndex((p) => p.id === address_id);
    if (findAddressIndex > -1) {
      addressDetails[findAddressIndex].moreOption = false;
    }
    this.FetchAddressDetails(address_id);
    this.setState({
      addressOpne: true,
      addressDetails,
      edit_address: true,
      seletectedstate: {},
    });
  };

  deleteAddress = (address_id) => {
    let formValues = {
      address_id: address_id,
    };
    RemoveAddressFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res && res.data) {
        this.createSuccessMsg("successs", res.data.message);
      }
      this.setState({ openDialog: false, address_id: "" });
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  setDefaultAddress = (i, address_id) => {
    let formValues = {
      address_id: address_id,
    };
    let addressDetails = this.state.addressDetails;
    SetDefaultAddress(formValues, this.props.dispatch).then((res) => {
      if (res && res.data) {
        let findAddressIndex = addressDetails.findIndex(
          (p) => p.id === address_id
        );
        if (findAddressIndex > -1) {
          addressDetails[findAddressIndex].moreOption = false;
        }
        this.createSuccessMsg("success", res.data.message);
      }
      this.setState({
        checked: i,
        address_id: address_id,
        addressDetails,
      });
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
      // addressOpne: false,
    });
  };

  handleOpnDialog = (id) => {
    this.setState({ openDialog: true, address_id: id });
  };

  handleCloseOpnDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenOtpDialog = (mobile_number) => {
    if (mobile_number) {
      this.SendOtp(mobile_number);
    }
    // this.setState({ openOtpDialog: true, mobileNumber: mobile_number});
  };

  handleCloseOtpDialog = () => {
    this.setState({
      openOtpDialog: false,
      generateOtp: ["", "", "", "", "", ""],
    });
    this.FetchProfileDetails();
  };

  handleChange = () => {
    this.setState({ address_type: false });
  };

  handleOtpChange = (e, index, field) => {
    e.preventDefault();
    const { maxLength, value, name } = e.target;
    let generateOtp = this.state.generateOtp;
    generateOtp[index] = e.target.value;

    const [fieldName, fieldIndex] = name.split("_");

    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    var Inputresult = generateOtp.join("");
    if (Inputresult.length === 6) {
      this.handleSubmit(Inputresult);
    }

    this.setState({ generateOtp });
  };

  handleKeyUpChange = (e, index, field) => {
    // console.log(e.keyCode);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    if (e.keyCode === 8) {
      const previousSibling = document.querySelector(
        `input[name=otp_${parseInt(fieldIndex, 10) - 1}]`
      );

      if (previousSibling !== null) {
        previousSibling.focus();
      }
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return null;
  };

  addMobileaddressClose = () => {
    this.setState({ MobileaddressOpen: false });
  };
  MobileAddressOpen = () => {
    this.setState({ MobileaddressOpen: true, editProfileDetails: false });
    window.scrollTo(0, 0);
    this.clearNewAddressdata();
  };

  handleSetPassword = (event) => {
    if (event.target.value.length > 5 && event.target.value.length < 16) {
      this.setState({
        msgPasswordsuccess:
          "Password minimum 6 character and maximum 16 character",
        password: event.target.value,
      });
    } else {
      this.setState({ msgPasswordsuccess: false });
    }
  };

  handleSetConfirmPassword = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({
        msgPassworderror: "Passwords do not match",
        confirmPassword: event.target.value,
        msgPasswordsuccess: false,
      });
    } else {
      this.setState({
        msgPassworderror: false,
        msgPasswordsuccess: "Password match",
      });
    }
  };

  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;

        this.props.change("profile_first_name", profileDetails.firstName);
        this.props.change(
          "profile_last_name",
          profileDetails.lastName || profileDetails.lastName !== "undefined"
            ? profileDetails.lastName
            : ""
        );
        this.props.change(
          "profile_street_name",
          profileDetails.street_name ||
            profileDetails.street_name !== "undefined"
            ? profileDetails.street_name
            : ""
        );
        this.props.change(
          "address",
          profileDetails.address || profileDetails.address !== "undefined"
            ? profileDetails.address
            : ""
        );
        this.props.change(
          "profile_city_name",
          profileDetails.city_name || profileDetails.city_name !== "undefined"
            ? profileDetails.city_name
            : ""
        );
        this.props.change(
          "profile_state_name",
          profileDetails.state_name || profileDetails.state_name !== "undefined"
            ? {
                label: profileDetails.state_name,
                value: profileDetails.state_name,
              }
            : ""
        );

        this.props.change("profile_zipcode", profileDetails.zipcode);
        this.props.change("email_address", profileDetails.email);
        this.props.change("mobile_number", profileDetails.phone);

        this.props.change("hidden_mobile_number", profileDetails.phone);

        this.props.change(
          "profile_alternate_mobile",
          profileDetails.alternate_phone ||
            profileDetails.alternate_phone !== "undefined" ||
            profileDetails.alternate_phone !== null
            ? profileDetails.alternate_phone.toString()
            : ""
        );

        this.props.change(
          "profile_delivery_remark",
          profileDetails.delivery_remark ||
            profileDetails.delivery_remark !== "undefined"
            ? profileDetails.delivery_remark
            : ""
        );
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }

      this.setState({
        profileDetails: profileDetails,
        seletectedstate: {
          label: profileDetails.state_name,
          value: profileDetails.state_name,
        },
      });
    });
  };

  changeProfileEdit = () => {
    // this.FetchProfileDetails();
    this.setState({
      editProfileDetails: true,
      addressOpne: false,
      getAddresDetails: [],
    });
  };

  changeFeildTypeNewPassword = () => {
    this.setState({
      feildTypeNewPassword: !this.state.feildTypeNewPassword,
    });
  };

  changeFeildTypePassword = () => {
    this.setState({
      feildTypePassword: !this.state.feildTypePassword,
    });
  };

  changeFeildTypeConfirmPassword = () => {
    this.setState({
      feildTypeConfirmPassword: !this.state.feildTypeConfirmPassword,
    });
  };

  redirectToPage = () => {
    window.scrollTo(0, 0);
    this.setState({ editProfileDetails: false });
  };

  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let orderCartItemList = this.state.orderCartItemList;

    if (icons === "icon-plus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          orderCartItemList[i].qty = orderCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            orderCartItemList[i].qty,
            orderCartItemList[i].is_case,
            orderCartItemList[i].note,
            orderCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          orderCartItemList[i].qty = orderCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].note,
              orderCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < orderCartItemList.length; i++) {
        if (orderCartItemList[i].id === index) {
          // orderCartItemList[i].qty = added_quantity;
          orderCartItemList[i].qty = added_quantity
            ? added_quantity
            : orderCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].note,
              orderCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              orderCartItemList[i].qty,
              orderCartItemList[i].is_case,
              orderCartItemList[i].container_type
            );
          }
        }
      }
      // this.setState({headerinputField: false});
    }
    this.setState({
      orderCartItemList: orderCartItemList,
      headerinputField: false,
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: note ? note : "",
      container_type: container_type ? container_type : "",
    };
    let orderCartItemList = this.state.orderCartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < orderCartItemList.length; i++) {
          if (orderCartItemList[i].product_id === product_id) {
            orderCartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(orderCartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({ disabled: false, orderCartItemList, SubTotal: SubTotal });
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case, container_type) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parentIds,
      category_id,
      level
    );
    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(formValues.ids, level);
            }
          );
        }
      }
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  handleSlotName = (data) => {};
  render() {
    const {
      addressDetails,
      success,
      error,
      CategoryDetails,
      CartCount,
      CategoryOption,
      profileDetails,
      msgPassworderror,
      msgPasswordsuccess,
      editProfileDetails,
      orderCartItemList,
      web_customize,
    } = this.state;
    const { handleSubmit, invalid } = this.props;
    const { selector } = this;
    const { selected } = this.state;
    const show = () => {
      document.getElementsByClassName("show")[0].classList.toggle("d-none");
    };

    return (
      <>
        <div
          className="main_containerLoader"
          style={{ display: this.state.MainLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>{" "}
        <ScrollColorTypography component="div" className="main-content">
          <Header
            CategoryDetails={CategoryDetails}
            CategoryOption={CategoryOption}
            cartItemList={orderCartItemList}
            handleOpenDialog={this.handleOpenDialog}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            cartLoader={this.state.cartLoader}
            FetchCartCount={() => this.FetchCartItemList()}
            AddCountForcartList={(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            ) =>
              this.AddCountForShoppingcartList(
                product_id,
                added_quantity,
                is_case,
                icons,
                index,
                container_type
              )
            }
            changeheaderinputFieldValue={() =>
              this.changeheaderinputFieldValue()
            }
            headerinputField={this.state.headerinputField}
            logo={this.state.logo}
            top_menu={this.state.top_menu}
            extraCharges={this.state.extraCharges}
            handleInnerOpenDialog={(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            ) =>
              this.handleInnerOpenDialog(
                catId,
                subcategory_name,
                has_child,
                level,
                parent_id
              )
            }
            handleSlotNameData={(data) => this.handleSlotName(data)}
          />
          <CategoriesModal
            open={this.state.open}
            handleOpenDialog={this.handleOpenDialog}
            handleCloseDialog={this.handleCloseDialog}
            CategoryDetails={CategoryDetails}
            history={this.props}
          />

          <SubCategoryModal
            open_inner={this.state.open_inner}
            SubcategoryName={this.state.SubcategoryName}
            // handleInnerOpenDialog={this.handleInnerOpenDialog}
            handleInnerCloseDialog={this.handleInnerCloseDialog}
            handleCloseDialog={this.handleCloseDialog}
            handleOpenDialog={this.handleOpenDialog}
            SubCategoryDetails={this.state.SubCategoryDetails}
            history={this.props.history}
            pageName={this.state.pageName}
            SubSubCategoryDetails={this.state.SubSubCategoryDetails}
            subCategoryLoader={this.state.subCategoryLoader}
            FetchSubCategoryList={(id, level) =>
              this.FetchSubCategoryList(id, level)
            }
            parentIds={this.state.parentIds}
            parent_onlyid={this.state.parent_onlyid}
            parent_level={this.state.parent_level}
          />
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="user_details">
              <div className="user_main_wrapper ">
                <div className="menuOuter">
                  <UserDetailsMenu
                    history={this.props.history}
                    mobileViewHide={this.state.mobileViewHide}
                    openDetailsMenu={() => this.openDetailsMenu()}
                    profileDetails={this.state.profileDetails}
                  />
                </div>
                <div className="user_cust_wrapper">
                  <div className="user_details_desc">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="cust_head_main cust_hide">
                          <TypographyTextColor Color="heading" component="h4">
                            My Profile
                          </TypographyTextColor>
                          <TypographyFontColor
                            border={1}
                            className="new_add d-md-block"
                            onClick={() => this.changeProfileEdit()}
                            role="button"
                            Fonttype="other"
                          >
                            <EditIcon
                              style={{ fontSize: "1rem", marginRight: "5px" }}
                            ></EditIcon>
                            Edit
                          </TypographyFontColor>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="address_user_profile address_rw">
                          <div className="cust_add">
                            <div className="cust_usr_name">
                              <TypographyTextColor
                                Color="heading"
                                component="h5"
                              >
                                {(profileDetails.firstName
                                  ? profileDetails.firstName
                                  : "") +
                                  " " +
                                  (profileDetails.lastName
                                    ? profileDetails.lastName
                                    : "")}
                                {/* <span className="user_defaulit_add">
                                {row.isdefault === true
                                  ? "(Default)"
                                  : ""}
                              </span> */}
                              </TypographyTextColor>
                            </div>
                            <div className="cust_address">
                              <TypographyTextColor
                                Color="other"
                                className="cust_home"
                                component="div"
                              >
                                {profileDetails.address
                                  ? profileDetails.address
                                  : ""}
                                {profileDetails.street_name ? (
                                  <>
                                    {" "}
                                    <br></br>
                                    {"APT/FLOOR - " +
                                      profileDetails.street_name}
                                  </>
                                ) : (
                                  ""
                                )}
                                <br></br>

                                {profileDetails
                                  ? profileDetails.city_name + ", "
                                  : ""}
                                {profileDetails
                                  ? profileDetails.state_name + " "
                                  : ""}
                                {profileDetails.zipcode
                                  ? profileDetails.zipcode
                                  : ""}
                              </TypographyTextColor>
                              <TypographyTextColor
                                Color="other"
                                className="cust_mobile"
                                component="div"
                              >
                                {profileDetails.phone
                                  ? this.formatPhoneNumber(profileDetails.phone)
                                  : ""}
                                {profileDetails.alternate_phone
                                  ? " / " +
                                    this.formatPhoneNumber(
                                      profileDetails.alternate_phone
                                    )
                                  : ""}
                              </TypographyTextColor>
                              <TypographyTextColor
                                Color="other"
                                className="cust_mobile"
                                component="div"
                              >
                                {profileDetails.delivery_remark
                                  ? "Delivery Remark : " +
                                    profileDetails.delivery_remark
                                  : ""}
                              </TypographyTextColor>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------------- edit profile start ----------------------*/}
                      {editProfileDetails === true ? (
                        <div className="col-md-6">
                          <form
                            onSubmit={handleSubmit(
                              profileSubmit.bind(null, this)
                            )}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="user_input">
                                  <Field
                                    name="profile_first_name"
                                    label="First Name"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    type="text"
                                    size="small"
                                    rootClass=""
                                    autoFocus
                                    required={true}
                                    disabled={this.state.desiableTextField}
                                    validate={[
                                      required({
                                        message: "First Name is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="user_input">
                                  <Field
                                    name="profile_last_name"
                                    label="Last Name"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    type="text"
                                    size="small"
                                    rootClass=""
                                    disabled={this.state.desiableTextField}
                                    required={true}
                                    validate={[
                                      required({
                                        message: "Last Name is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="user_input">
                                  <Field
                                    name="address"
                                    label="Address"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    type="text"
                                    size="small"
                                    rootClass="user_main_input"
                                    required={true}
                                    disabled={this.state.desiableTextField}
                                    validate={[
                                      required({
                                        message: "Address is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="user_input">
                                  <Field
                                    name="profile_street_name"
                                    label="APT/FLOOR"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    type="text"
                                    size="small"
                                    rootClass="user_main_input"
                                    disabled={this.state.desiableTextField}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row gutter-2">
                              <div className="col-md-4 mb-4">
                                <div className="user_input">
                                  <Field
                                    name="profile_city_name"
                                    size="small"
                                    label="City"
                                    placeholder="City"
                                    component={sufixIconInputForProfile}
                                    required={true}
                                    isClearable={true}
                                    validate={[
                                      required({
                                        message: "City is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <div className="user_input">
                                  <Field
                                    autoFocus={true}
                                    name="profile_state_name"
                                    size="small"
                                    label="State"
                                    component={renderSelect3Input}
                                    options={this.state.allstate}
                                    onChange={this.handleChnageState}
                                    Value={this.state.seletectedstate}
                                    className="outLine"
                                    type="text"
                                    required={true}
                                    validate={[
                                      required({
                                        message: "State is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 mb-3">
                                <div className="user_input">
                                  <Field
                                    name="profile_zipcode"
                                    label="Zipcode"
                                    id="outlined-basic"
                                    variant="outlined"
                                    component={renderNumberInputForZipCode}
                                    size="small"
                                    rootClass=""
                                    required={true}
                                    disabled={this.state.desiableTextField}
                                    // InputProps={{ inputProps: { min: 0, max: 5 } }}
                                    validate={[
                                      required({
                                        message: "Zipcode is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="user_input">
                                  <Field
                                    name="email_address"
                                    label="Email Address"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    type="text"
                                    size="small"
                                    rootClass=""
                                    required={true}
                                    disabled={this.state.desiableTextField}
                                    validate={[
                                      required({
                                        message: "Email Address is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="user_input">
                                  <Field
                                    component={sufixIconInputForaddressMobile}
                                    name="mobile_number"
                                    variant="outlined"
                                    label="Mobile No."
                                    id="outlined-basic"
                                    color="primary"
                                    position="start"
                                    placeholder="Mobile No."
                                    className="fileds"
                                    size="small"
                                    required={true}
                                    disabled={this.state.desiableTextField}
                                    validate={[
                                      required({
                                        message: "Mobile No. is required.",
                                      }),
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="user_input">
                                  <Field
                                    component={sufixIconInputForaddressMobile}
                                    name="profile_alternate_mobile"
                                    variant="outlined"
                                    id="outlined-basic"
                                    label="Alternate Mobile No."
                                    position="start"
                                    placeholder="Alternate Mobile No."
                                    className="fileds"
                                    size="small"
                                    disabled={this.state.desiableTextField}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="user_input">
                                  <Field
                                    name="profile_delivery_remark"
                                    label="Delivery Remarks"
                                    id="outlined-basic"
                                    component={sufixIconInputForProfile}
                                    disabled={this.state.desiableTextField}
                                    type="text"
                                    size="small"
                                    rootClass=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="cust_head_main cust_mob_head">
                                  <TypographyTextColor
                                    Color="heading"
                                    component="h4"
                                  >
                                    Change Password
                                  </TypographyTextColor>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="user_input change_pwd">
                                  <Field
                                    name="password"
                                    label="Password"
                                    component={sufixIconInputButtonTwo}
                                    type={
                                      this.state.feildTypePassword
                                        ? "password"
                                        : "text"
                                    }
                                    icon={
                                      this.state.feildTypePassword
                                        ? "icon-visibility icn-usr"
                                        : "icon-invisible icn-usr"
                                    }
                                    changeFeildTypeFunction={
                                      this.changeFeildTypePassword
                                    }
                                    rootClass=""
                                    size="small"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="user_input change_pwd">
                                  <Field
                                    name="new_password"
                                    label="New Password"
                                    component={sufixIconInputButtonTwo}
                                    type={
                                      this.state.feildTypeNewPassword
                                        ? "password"
                                        : "text"
                                    }
                                    icon={
                                      this.state.feildTypeNewPassword
                                        ? "icon-visibility icn-usr"
                                        : "icon-invisible icn-usr"
                                    }
                                    changeFeildTypeFunction={
                                      this.changeFeildTypeNewPassword
                                    }
                                    rootClass=""
                                    size="small"
                                    onChange={(e) => this.handleSetPassword(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="user_input change_pwd">
                                  <Field
                                    name="confirm_new_password"
                                    label="Confirm New Password"
                                    component={sufixIconInputButtonTwo}
                                    type={
                                      this.state.feildTypeConfirmPassword
                                        ? "password"
                                        : "text"
                                    }
                                    icon={
                                      this.state.feildTypeConfirmPassword
                                        ? "icon-visibility icn-usr"
                                        : "icon-invisible icn-usr"
                                    }
                                    changeFeildTypeFunction={
                                      this.changeFeildTypeConfirmPassword
                                    }
                                    rootClass=""
                                    size="small"
                                    onChange={(e) =>
                                      this.handleSetConfirmPassword(e)
                                    }
                                  />
                                </div>
                                {msgPassworderror ? (
                                  <div
                                    className="alert alert-danger mt-3"
                                    role="alert"
                                  >
                                    {msgPassworderror}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {msgPasswordsuccess ? (
                                  <div
                                    className="alert alert-success mt-3"
                                    role="alert"
                                  >
                                    {msgPasswordsuccess}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bttn-fill bttn_user">
                                  <SecondaryCancelButton
                                    className="bttn_grn bttn_trans"
                                    onClick={() => this.redirectToPage()}
                                    Fonttype="other"
                                  >
                                    Cancel
                                  </SecondaryCancelButton>
                                  <SecondaryButton
                                    Fonttype="other"
                                    type="submit"
                                    className="bttn_grn"
                                  >
                                    Save Changes
                                  </SecondaryButton>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* -----------------------edit profile end ---------------------*/}
                    </div>
                  </div>

                  {/* --------------------------------Additional delivery address start ------------------------*/}
                  <div className="user_details_desc">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="cust_head_main cust_hide">
                          <TypographyTextColor Color="heading" component="h4">
                            Additional delivery address
                          </TypographyTextColor>
                          <TypographyFontColor
                            border={1}
                            className="new_add d-none d-md-block"
                            onClick={this.addNewaddressOpen.bind()}
                            role="button"
                            Fonttype="other"
                            // onClick={()=>this.MobileAddressOpen()}
                          >
                            <span className="icon-plus"></span>Add New
                          </TypographyFontColor>
                        </div>
                      </div>
                    </div>
                    {addressDetails && addressDetails.length > 0
                      ? addressDetails.map((row, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="address_user address_rw">
                                  <div className="cust_add">
                                    <div className="cust_usr_name">
                                      <TypographyTextColor
                                        Color="heading"
                                        component="h5"
                                      >
                                        {(row.first_name
                                          ? row.first_name
                                          : "") +
                                          " " +
                                          (row.last_name ? row.last_name : "")}
                                        <TypographyFontColor
                                          border={0}
                                          component="span"
                                          className="user_defaulit_add"
                                        >
                                          {row.isdefault === true
                                            ? "(Default)"
                                            : ""}
                                        </TypographyFontColor>
                                      </TypographyTextColor>
                                    </div>
                                    <div className="cust_address">
                                      <div className="cust_home"></div>
                                      <TypographyTextColor
                                        Color="other"
                                        component="div"
                                        className="cust_home"
                                      >
                                        {row.street_name}
                                        {row.appartment_name ? (
                                          <>
                                            <br></br>
                                            {"APT/FLOOR - " +
                                              row.appartment_name}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <br></br>
                                        {row.city_name +
                                          ", " +
                                          row.state_name +
                                          " " +
                                          row.zipcode}
                                      </TypographyTextColor>
                                      <TypographyTextColor
                                        Color="other"
                                        className="cust_mobile"
                                        component="div"
                                      >
                                        {this.formatPhoneNumber(row.phone)}
                                        {row.alternate_phone
                                          ? " / " +
                                            this.formatPhoneNumber(
                                              row.alternate_phone
                                            )
                                          : ""}
                                      </TypographyTextColor>
                                      <TypographyTextColor
                                        Color="other"
                                        className="cust_mobile"
                                        component="div"
                                      >
                                        {row.delivery_remark
                                          ? "Delivery Remark : " +
                                            row.delivery_remark
                                          : ""}
                                      </TypographyTextColor>
                                    </div>
                                  </div>
                                  <div className="add_more_opt">
                                    <span
                                      className="icon-more"
                                      onClick={() =>
                                        this.OnClickMoreOption(row.id)
                                      }
                                    ></span>
                                    {row.moreOption === true ? (
                                      <div class="sub_add_details">
                                        <ul>
                                          <li>
                                            <Link
                                              className="bttn_grn"
                                              onClick={() =>
                                                this.editAddress(row.id)
                                              }
                                            >
                                              Edit
                                            </Link>
                                          </li>
                                          {row.isdefault ? (
                                            <></>
                                          ) : (
                                            <>
                                              <li>
                                                <Link
                                                  className="bttn_grn"
                                                  onClick={() =>
                                                    this.handleOpnDialog(row.id)
                                                  }
                                                >
                                                  Delete
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="bttn_grn"
                                                  onClick={() =>
                                                    this.setDefaultAddress(
                                                      index,
                                                      row.id
                                                    )
                                                  }
                                                >
                                                  Default
                                                </Link>
                                              </li>
                                            </>
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    <div className="cust_head_main d-block d-md-none cust_no_mr bg-white mb-3 border-0">
                      <Link
                        className="new_add w-100 text-center"
                        onClick={() => this.MobileAddressOpen()}
                      >
                        <span className="icon-plus"></span>Add New
                      </Link>
                    </div>
                  </div>

                  {this.state.addressOpne === true ? (
                    <div className="user_details_desc user_detail_new">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="cust_head_main">
                            <TypographyTextColor Color="heading" component="h4">
                              {this.state.edit_address === true
                                ? "Edit Address"
                                : "Add New Address"}
                            </TypographyTextColor>
                          </div>
                        </div>
                      </div>
                      <form
                        onSubmit={handleSubmit(
                          deliveryaddressSubmit.bind(null, this)
                        )}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="user_input">
                              <Field
                                name="address_id"
                                component={renderHiddenField}
                                type="hidden"
                              />
                              <Field
                                name="first_name"
                                label="First Name"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                                autoFocus
                                required={true}
                                validate={[
                                  required({
                                    message: "First Name is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="user_input">
                              <Field
                                name="address_id"
                                component={renderHiddenField}
                                type="hidden"
                              />
                              <Field
                                name="last_name"
                                label="Last Name"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Last Name is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="user_input">
                              <Field
                                name="street_name"
                                label="Address"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Address is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="user_input">
                              <Field
                                name="apartment_name"
                                label="APT/FLOOR"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row gutter-2">
                          <div className="col-md-4 mb-4">
                            <div className="user_input">
                              <Field
                                name="city_id"
                                size="small"
                                label="City"
                                placeholder="City"
                                component={sufixIconInputForProfile}
                                // initialOptions={this.state.city}
                                // fetchOptions={this.fetchCity}
                                // onChange={this.selectCityCountry.bind(
                                //   selector
                                // )}
                                // isClearable={true}
                                required={true}
                                validate={[
                                  required({
                                    message: "City is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mb-4">
                            <div className="user_input">
                              {/* <Field
                              name="hidden_city_id"
                              component={renderHiddenField}
                              type="hidden"
                            /> */}
                              <Field
                                autoFocus={true}
                                name="state_id"
                                size="small"
                                colors="primary"
                                label="State"
                                component={renderSelect3Input}
                                options={this.state.allstate}
                                onChange={this.handleChnageState}
                                Value={this.state.seletectedstate}
                                className="outLine"
                                type="text"
                                required={true}
                                validate={[
                                  required({
                                    message: "State is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <div className="user_input">
                              <Field
                                name="zipcode"
                                label="Zipcode"
                                id="outlined-basic"
                                variant="outlined"
                                component={renderNumberInputForZipCode}
                                size="small"
                                rootClass=""
                                required={true}
                                validate={[
                                  required({
                                    message: "Zipcode is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="user_input">
                              <Field
                                component={sufixIconInputForaddressMobile}
                                name="mobile_no"
                                variant="outlined"
                                label="Mobile No."
                                id="outlined-basic"
                                color="primary"
                                position="start"
                                placeholder="Mobile No."
                                className="fileds"
                                size="small"
                                required={true}
                                validate={[
                                  required({
                                    message: "Mobile No. is required.",
                                  }),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="user_input">
                              <Field
                                component={sufixIconInputForaddressMobile}
                                name="alternate_mobile"
                                variant="outlined"
                                id="outlined-basic"
                                label="Alternate Mobile No."
                                color="primary"
                                position="start"
                                placeholder="Alternate Mobile No."
                                className="fileds"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="user_input">
                              <Field
                                name="delivery_remark"
                                label="Delivery Remarks"
                                id="outlined-basic"
                                component={sufixIconInputForProfile}
                                type="text"
                                size="small"
                                rootClass=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field
                              name="address_type"
                              required={this.state.address_type}
                              onChange={this.handleChange}
                              component={radioButton}
                            ></Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="bttn-fill bttn_user">
                              <SecondaryCancelButton
                                className="bttn_grn bttn_trans"
                                onClick={this.addNewaddressClose.bind()}
                                Fonttype="other"
                              >
                                Cancel
                              </SecondaryCancelButton>
                              <SecondaryButton
                                type="submit"
                                className="bttn_grn"
                                Fonttype="other"
                              >
                                Save
                              </SecondaryButton>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* --------------------------------Additional delivery address end ------------------------*/}
                {success ? (
                  <Snackbar
                    open={this.state.success}
                    onClose={this.handleClose}
                    autoHideDuration={1000}
                    className="success"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    action={
                      <React.Fragment>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={this.handleClose}
                        >
                          <i className="icon-close"></i>
                        </IconButton>
                      </React.Fragment>
                    }
                    message={
                      <React.Fragment>
                        <div className="row gutter-8">
                          <div className="col-auto">
                            <i className="icon-closer"></i>
                          </div>
                          <div className="col">
                            <p>{success}</p>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  />
                ) : (
                  ""
                )}
                {error ? (
                  <Snackbar
                    open={this.state.error}
                    onClose={this.handleClose}
                    autoHideDuration={1000}
                    className="error"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    action={
                      <React.Fragment>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={this.handleClose}
                        >
                          <i className="icon-close"></i>
                        </IconButton>
                      </React.Fragment>
                    }
                    message={
                      <React.Fragment>
                        <div className="row gutter-8">
                          <div className="col-auto">
                            <i className="icon-closer"></i>
                          </div>
                          <div className="col">
                            <p>{error}</p>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  />
                ) : (
                  ""
                )}
                <Dialog
                  open={this.state.openDialog}
                  onClose={() => this.handleCloseOpnDialog()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure want to delete this address?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleCloseOpnDialog()}
                      color="primary"
                    >
                      Disagree
                    </Button>
                    <Button
                      onClick={() => this.deleteAddress(this.state.address_id)}
                      color="primary"
                      autoFocus
                    >
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              {/* ----------------------------For mobile version add address start-----------------------------*/}
              {this.state.MobileaddressOpen === true ? (
                <div className="delivery_add mob-delivery-address show d-md-none">
                  <div className="menu-head-mobile">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <ArrowBackIosIcon
                          onClick={() => this.addMobileaddressClose()}
                        />
                      </li>
                      <li className="list-inline-item font-weight-bold text-uppercase">
                        Additional delivery address
                      </li>
                    </ul>
                  </div>
                  <div className="container-fluid margin-top-mob">
                    <form
                      onSubmit={handleSubmit(
                        deliveryaddressSubmit.bind(null, this)
                      )}
                    >
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="user_input">
                            <Field
                              name="address_id"
                              component={renderHiddenField}
                              type="hidden"
                            />
                            <Field
                              name="first_name"
                              label="Full Name"
                              id="outlined-basic"
                              component={sufixIconInputForProfile}
                              type="text"
                              size="small"
                              rootClass=""
                              required={true}
                              validate={[
                                required({ message: "Name is required." }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="last_name"
                              label="Last Name"
                              id="outlined-basic"
                              component={sufixIconInputForProfile}
                              type="text"
                              size="small"
                              rootClass=""
                              required={true}
                              validate={[
                                required({ message: "Name is required." }),
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="street_name"
                              label="Address"
                              id="outlined-basic"
                              component={sufixIconInputForProfile}
                              type="text"
                              size="small"
                              rootClass=""
                              required={true}
                              validate={[
                                required({
                                  message: "Street Name is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="apartment_name"
                              label="APT/FLOOR"
                              id="outlined-basic"
                              component={sufixIconInputForProfile}
                              type="text"
                              size="small"
                              rootClass=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="user_input">
                            <Field
                              name="city_id"
                              size="small"
                              label="City"
                              placeholder="City"
                              component={sufixIconInputForProfile}
                              // initialOptions={this.state.city}
                              // fetchOptions={this.fetchCity}
                              // onChange={this.selectCityCountry.bind(
                              //   selector
                              // )}
                              // isClearable={true}
                              required={true}
                              validate={[
                                required({
                                  message: "City is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="user_input">
                            {/* <Field
                          name="hidden_city_id"
                          component={renderHiddenField}
                          type="hidden"
                        /> */}
                            <Field
                              autoFocus={true}
                              name="state_id"
                              size="small"
                              colors="primary"
                              label="State"
                              component={renderSelect3Input}
                              options={this.state.allstate}
                              onChange={this.handleChnageState}
                              Value={this.state.seletectedstate}
                              className="outLine"
                              type="text"
                              required={true}
                              validate={[
                                required({
                                  message: "State is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="zipcode"
                              label="Zipcode"
                              id="outlined-basic"
                              component={renderNumberInputForZipCode}
                              size="small"
                              rootClass=""
                              required={true}
                              validate={[
                                required({
                                  message: "Zipcode is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="hidden_mobile_number"
                              component={renderHiddenField}
                              type="hidden"
                            />
                            <Field
                              component={sufixIconInputForaddressMobile}
                              name="mobile_no"
                              variant="outlined"
                              label="Mobile No."
                              id="outlined-basic"
                              color="primary"
                              position="start"
                              placeholder="Mobile No."
                              className="fileds"
                              size="small"
                              required={true}
                              validate={[
                                required({
                                  message: "Mobile No. is required.",
                                }),
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              component={sufixIconInputForaddressMobile}
                              name="alternate_mobile"
                              variant="outlined"
                              id="outlined-basic"
                              label="Alternate Mobile No."
                              color="primary"
                              position="start"
                              placeholder="Alternate Mobile No."
                              className="fileds"
                              size="small"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="user_input">
                            <Field
                              name="delivery_remark"
                              label="Delivery Remarks"
                              id="outlined-basic"
                              component={sufixIconInputForProfile}
                              type="text"
                              size="small"
                              rootClass=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="address_type"
                            required={this.state.address_type}
                            onChange={this.handleChange}
                            component={radioButton}
                          ></Field>
                        </div>
                      </div>
                      <div className="row border-top my-3 mob-margin-bottom">
                        <div className="col-6 mt-3">
                          <div className="empty_cart w-100">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bttn_grn2 w-100"
                              onClick={() => this.addMobileaddressClose()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <div className="empty_cart cont_shop w-100">
                            <Fab
                              className="bttn_grn w-100 text-white"
                              variant="extended"
                              type="submit"
                              color="primary"
                            >
                              Save
                            </Fab>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* ------------------------End mobile version--------------- */}
            </section>

            {/* -----------------------OTP verification Start--------------------  */}
            <Dialog
              open={this.state.openOtpDialog}
              // onClose={() => this.handleCloseOpnDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="login-page">
                    <div className="login-form">
                      <div className="login-head">
                        <h4>Verify Mobile No.</h4>
                        <span className="sub-head">
                          Enter OTP code sent to your number{" "}
                          {this.state.mobile_number}
                        </span>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="user-input verify-mobile">
                          <input
                            type="text"
                            value={this.state.generateOtp[0]}
                            name="otp_1"
                            id="otp_1"
                            className="verify-otp"
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 0)}
                            onChange={(e) =>
                              this.handleOtpChange(e, 0, "otp_1")
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="text"
                            name="otp_2"
                            className="verify-otp"
                            value={this.state.generateOtp[1]}
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 1)}
                            onChange={(e) =>
                              this.handleOtpChange(e, 1, "otp_2")
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="text"
                            name="otp_3"
                            className="verify-otp"
                            value={this.state.generateOtp[2]}
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 2)}
                            onChange={(e) => this.handleOtpChange(e, 2)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="text"
                            name="otp_4"
                            className="verify-otp"
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 3)}
                            value={this.state.generateOtp[3]}
                            onChange={(e) => this.handleOtpChange(e, 3)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="text"
                            name="otp_5"
                            className="verify-otp"
                            value={this.state.generateOtp[4]}
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 4)}
                            onChange={(e) => this.handleOtpChange(e, 4)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            type="text"
                            name="otp_6"
                            className="verify-otp"
                            value={this.state.generateOtp[5]}
                            maxLength={1}
                            onKeyDown={(e) => this.handleKeyUpChange(e, 5)}
                            onChange={(e) => this.handleOtpChange(e, 5)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.handleCloseOtpDialog()}
                  color="primary"
                >
                  Cancel
                </Button>
                {/* <Button
                onClick={() => this.deleteAddress(this.state.address_id)}
                color="primary"
                autoFocus
              >
                Agree
              </Button> */}
              </DialogActions>
            </Dialog>
            {/* -----------------------OTP verification End--------------------  */}
            <Footer emailSubscribe={this.state.emailSubscribe} />
          </div>
        </ScrollColorTypography>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "Address" })(Address)
);
