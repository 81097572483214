import React from "react";
import { Skeleton } from "@mui/lab";
const ItemLoaderCard = () => {
  return (
    <div style={{ margin: "10px" }}>
      {" "}
      <Skeleton variant="rectangular" height={180} />
      {window.screen.availWidth <= 900 ? (
        <></>
      ) : (
        <>
          <Skeleton height={30} />
        </>
      )}
    </div>
  );
};

export default ItemLoaderCard;
