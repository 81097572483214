export const route_url_pages = {
  landing_page: "/",
  new_home_page: "/new-home",
  login: "/login",
  create_account: "/create-account",
  forgetpassword: "/forgot-password",
  verifynumber: "/verify-number",
  successmsg: "/successful",
  listing_page: "/listing",

  delivery_address: "/delivery-address",
  payment: "/payment",
  order_success: "/order-success",
  profile: "/profile",
  address: "/address",
  orders: "/orders",
  settings: "/settings",
  wishlist: "/wishlist",
  favoritelist: '/favouritelist',
  shoppinglist: '/shoppinglist',
  cart: "/cart",
  order_details: "/order-details",
  forget_password: "/new-password",
  after_forget_password: "/successfull-change-password",
  notfound: "/not-found",
  listing_page_Catgory: "/listing/:id",
  details_page: "/product/:id",
  cms_page: "/page/:id",
  category: "/category",
  paymentmethod: "/paymentmethod",
  loginSalesrep: "/loginsalesrep",
  cateringlist: "/catering",
};
