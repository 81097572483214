import React, { useEffect, useState } from "react";
import "../../../assets/css/customLoader.css";
import loader from "../../../assets/images/36605-shopping-cart.webp";
import ApiUrls from "../../../utils/apiUtils/apiUrl";
import LOGO_IMAGE from "../../../assets/images/logo.png";
const CustomPreloader = () => {
  const [logo, setLogo] = useState("");
  // useEffect(()=>{setTimeout(function(){ document.getElementsByClassName('.container').fadeOut(); },1000);},[])
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      setLogo(JSON.parse(accessToken)?.logo);
    }
  }, []);
  return (
    <div className="container">
      {/*    
     <picture>
        <source srcSet={loader} type="image/webp" width="15%"/>
        <img src={fallbackloader} alt="Alt Text" width="15%"/>
      </picture> */}
      <img src={loader} width="15%" alt="Alt Text" />
      {/* <img
        src={
          logo !== "" && logo !== undefined
            ? ApiUrls.BASH_URL + "/media/" + logo
            : LOGO_IMAGE
        }
        width="30%"
        alt="Alt Text"
        className="blink-image"
      /> */}
    </div>
  );
};

export default CustomPreloader;
