import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ApiUrls from "../utils/apiUtils/apiUrl"; 
import LOGO_IMAGE from "../assets/images/logo.png";
import { commonPageTitel } from "../utils/renderUtils/renderUtil";
class Successful extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo:'',
    };
  }
  componentDidMount() {
    //document.title = "Success";
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem('access_token'));
    document.title = commonPageTitel(
      JSON.parse(localStorage.getItem("access_token"))
    );
    logo = accessTokenForLogo ? accessTokenForLogo.logo : '';
    this.setState({logo: logo});
  }
  render() {
    return (
      <div>
        {localStorage.getItem("isLogin") === "true" ? (
          <Redirect to="/" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div className="header-pop">
                  <Link to="/">
                    <img 
                      src={
                            this.state.logo
                              ?this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                              : LOGO_IMAGE
                        }
                      // src={LOGO_IMAGE} 
                      alt="" />
                  </Link>
                </div>
                <div className="login-page">
                  <div className="login-form">
                    <div className="logo_success">
                      <span className="icon-tick"></span>
                    </div>
                    <div className="login-head">
                      <h4>Verify Successful</h4>
                      <span className="sub-head">
                        Your mobile no. has been successfully verified
                      </span>
                    </div>
                    <div className="bttn-fill">
                      <Link to="/login" className="bttn_grn">
                        Back to Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Successful;
