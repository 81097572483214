import { styled, Button, Typography } from "@mui/material";

export const SearchButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));
export const RefineButton = styled(Button)(({ theme }) => ({
  background: "transparent",
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    background: theme.palette.primary.main,
    color: "#fff",
  },
}));

export const TypographyFontColor = styled(Typography)(
  ({ theme, border, Fonttype = "default" }) => ({
    color: `${theme.palette.primary.main} !important`,
    borderStyle: "solid",
    borderWidth: border + "px",
    borderColor: theme.palette.primary.main,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);
export const TypographyHoverFontColor = styled(Typography)(
  ({ theme, Color = "default" }) => ({
    color:
      Color === "category"
        ? `${theme.palette.TextColor?.category}`
        : Color === "heading"
        ? `${theme.palette.TextColor?.heading}`
        : "#000",
    fontFamily:
      Color === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Color === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : "",
    "&:hover": { color: theme.palette.primary.main },
  })
);
export const TypographyBorder = styled(Typography)(({ theme, border }) => ({
  borderStyle: "solid",
  borderWidth: border + "px",
  borderColor: `${theme.palette.primary.main} !important`,
}));

export function lightenColor(color, amount) {
  let rgbColor;

  // Check if the color is in hexadecimal format
  if (color[0] === "#") {
    const hex = color.slice(1); // Remove the "#" character
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    rgbColor = `rgb(${r}, ${g}, ${b})`;
  } else {
    // The color is already in RGB format
    rgbColor = color;
  }

  // Parse the RGB components
  const rgb = rgbColor.match(/\d+/g);
  const r = parseInt(rgb[0]);
  const g = parseInt(rgb[1]);
  const b = parseInt(rgb[2]);

  // Calculate the lighter color components
  const lighterR = r + amount;
  const lighterG = g + amount;
  const lighterB = b + amount;

  // Ensure the color components stay within the valid range (0-255)
  const lightenedR = Math.min(lighterR, 255);
  const lightenedG = Math.min(lighterG, 255);
  const lightenedB = Math.min(lighterB, 255);

  // Construct the lightened color string
  const lightenedColor = `rgb(${lightenedR}, ${lightenedG}, ${lightenedB})`;

  return lightenedColor;
}

export const TypographyPrimaryBgColorDimmed = styled(Typography)(
  ({ theme, border, color, dimmedRatio = 150 }) => ({
    background:
      theme.palette.primary.main !== "#fff"
        ? `${lightenColor(theme.palette.primary.main, dimmedRatio)} !important`
        : "",
    border: `${border}px solid ${theme.palette.primary.main} !important`,
    color: color ? `${theme.palette.primary.main} !important` : "",
  })
);

export const TypographySecondaryBgColorDimmed = styled(Typography)(
  ({ theme, border, color, dimmedRatio = 170 }) => ({
    background: lightenColor(theme.palette.secondary.main, dimmedRatio),
    border: `${border}px solid ${theme.palette.secondary.main}`,
    color: color ? theme.palette.secondary.main : "",
  })
);

export const TypographySecondaryBorder = styled(Typography)(
  ({ theme, border = 1 }) => ({
    border: `${border}px solid ${theme.palette.secondary.main}`,
  })
);

export const TypographySecondaryColor = styled(Typography)(
  ({ theme, size, Fonttype = "" }) => ({
    color: `${theme.palette.secondary.main} !important`,
    fontSize: size + "px !important",
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);
export const TypographySecondaryBgColor = styled(Typography)(({ theme }) => ({
  background: `${theme.palette.secondary.main} !important`,
  border: `1px solid ${theme.palette.secondary.main} !important`,
}));
export const TypographyBgColor = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));
export const TypographyHoverSecondaryBgColor = styled(Typography)(
  ({ theme }) => ({
    "&:hover": {
      background: lightenColor(theme.palette.secondary.main, 50),
    },
  })
);
export const TypographyHoverBgColor = styled(Typography)(({ theme }) => ({
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));
export const TypographyHoverTextColor = styled(Typography)(
  ({ theme, Color = "default" }) => ({
    color: Color === "category" ? theme.palette.TextColor?.category : "#000",
    fontFamily:
      Color === "category" ? theme.palette?.TextFontFamily?.category : "",
    "&:hover": {
      color: theme.palette.primary?.main,
    },
  })
);

export const SaleLabelySecondaryColor = styled(Typography)(
  ({ theme, Fonttype = "" }) => ({
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);
export const LabelPrimaryColor = styled(Typography)(({ theme, border }) => ({
  color: theme.palette.primary.main,
  border: `${border}px solid ${theme.palette.primary.main}`,
}));

export const SecondaryButton = styled(Button)(
  ({ theme, Fonttype = "default" }) => ({
    background: theme.palette.secondary.main,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
    color: "#fff",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  })
);
export const SecondaryCancelButton = styled(Button)(
  ({ theme, Fonttype = "default" }) => ({
    color: `${theme.palette.secondary.main} !important`,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
    border: `1px solid ${theme.palette.secondary.main} !important`,
  })
);
export const PrimaryBorderButton = styled(Button)(
  ({ theme, Fonttype = "default" }) => ({
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);
export const PrimaryButton = styled(Button)(
  ({ theme, Fonttype = "default" }) => ({
    background: theme.palette.primary.main,
    fontFamily:
      Fonttype === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Fonttype === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Fonttype === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Fonttype === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  })
);
export const TextFormating = styled(Typography)(
  ({ theme, Color = "default" }) => ({
    textTransform: theme.palette.TextTransform?.main,
    color:
      Color === "category"
        ? `${theme.palette.TextColor?.category} !important`
        : Color === "heading"
        ? `${theme.palette.TextColor?.heading}`
        : Color === "other"
        ? `${theme.palette.TextColor?.other}`
        : Color === "product"
        ? `${theme.palette.TextColor?.product}`
        : "#000",
    fontFamily:
      Color === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Color === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Color === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Color === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);

export const SuggestedCartSection = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(180deg, ${
    theme.palette.secondary.main
  } 14%,${lightenColor(theme.palette.secondary.main, 170)} 90%)`,
  "@media (max-width: 1600px)": {
    width: "25%",
  },
}));
export const PaymentCardGradient = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(160deg, ${lightenColor(
    theme.palette.primary.main,
    80
  )} 0%,  ${lightenColor(theme.palette.secondary.main, 80)} 100%)`,
}));
export const PaymentCardInputBox = styled(Typography)(({ theme }) => ({
  background: `${lightenColor(theme.palette.primary.main, 70)} `,
}));
export const SubscribePrimaryButton = styled(Button)(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  padding: "10px 24px",
  fontSize: "18px",
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: "34px",
  backgroundColor: "transparent",
  fontWeight: 600,
  transition: "all 0.3s cubic-bezier(0.23, 1, 0.320, 1)",
  overflow: "hidden",
  left: "30%",
  "&::before": {
    content: "",
    position: "absolute",
    inset: 0,
    margin: "auto",
    width: "60px",
    height: "60px",
    borderRadius: "inherit",
    scale: 0,
    zIndex: -1,
    backgroundColor: theme.palette.primary.main,
    transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
  },
}));

export const TypographyTextColor = styled(Typography)(
  ({ theme, Color = "default" }) => ({
    color:
      Color === "category"
        ? `${theme.palette.TextColor?.category} !important`
        : Color === "heading"
        ? `${theme.palette.TextColor?.heading} !important`
        : Color === "other"
        ? `${theme.palette.TextColor?.other} !important`
        : Color === "product"
        ? `${theme.palette.TextColor?.product} !important`
        : "#000",
    fontFamily:
      Color === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : Color === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : Color === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : Color === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);

export const TypographyTextFontFamily = styled(Typography)(
  ({ theme, type = "default" }) => ({
    fontFamily:
      type === "category"
        ? `${theme.palette.TextFontFamily?.category} !important`
        : type === "heading"
        ? `${theme.palette.TextFontFamily?.heading} !important`
        : type === "other"
        ? `${theme.palette.TextFontFamily?.other} !important`
        : type === "product"
        ? `${theme.palette.TextFontFamily?.product} !important`
        : "",
  })
);

export const ScrollColorTypography = styled(Typography)((theme) =>
  // {
  //   console.log(theme);
  // }
  ({
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme?.theme?.palette?.secondary?.main, //'rgba(255,0,0,0.8)',//`${theme.palette?.secondary?.main} !important`,
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: lightenColor(theme?.theme?.palette?.secondary?.main, 200),
    },
    scrollbarWidth: "thin",
    scrollbarColor: `${theme?.theme?.palette?.secondary?.main} ${lightenColor(
      theme?.theme?.palette?.secondary?.main,
      200
    )}`,
  })
);
