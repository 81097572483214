import * as React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { ScrollColorTypography, TypographyFontColor } from "../UiDesign";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function MenuCategoryList(props) {
  const { selectedCategory,tree_data ,storeCategoryId} = props;
  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  return (
    <Dropdown>
      <MenuButton>
        {" "}
        <TypographyFontColor component="span" border={0}>
          {selectedCategory}
        </TypographyFontColor>
        <TypographyFontColor component="span" border={0}>
          <MdKeyboardArrowDown
          // className={`${
          //   Boolean(this.state.anchorEllistCategorySelect)
          //     ? "rotate-icon"
          //     : ""
          // }`}
          />
        </TypographyFontColor>
      </MenuButton>
      <Menu slots={{ listbox: AnimatedListbox }} style={{ zIndex: 9999 }}>
        <ScrollColorTypography component={"div"} style={{maxHeight:'40vh',overflowY:'scroll'}}>
          <MenuItem
            onClick={() => this.storeCategoryId("allCateogory", "All Category")}
          >
            All Category
          </MenuItem>
          {tree_data?.map((val, i) => {
            return (
              <>
                {" "}
                <MenuItem
                  key={i}
                  onClick={() => storeCategoryId(val.id, val.name)}
                  
                >
                  {val.name}
                </MenuItem>
                {val.has_child === true ? (
                  <>
                    {" "}
                    {val.childItem?.map((ele, indx) => {
                      return (
                        <MenuItem
                          key={indx}
                          style={{
                            fontSize: "0.9rem",
                            paddingLeft: "1rem",
                          }}
                          onClick={() => storeCategoryId(ele.id, ele.name)}
                          
                        >
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </ScrollColorTypography>

        {/* <MenuItem onClick={createHandleMenuClick("Profile")}>Profile</MenuItem>
        <MenuItem onClick={createHandleMenuClick("Language settings")}>
          Language settings
        </MenuItem>
        <MenuItem onClick={createHandleMenuClick("Log out")}>Log out</MenuItem> */}
      </Menu>
    </Dropdown>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
   font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
 
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
 font-family:${theme.palette.TextFontFamily?.other} !important;
  transition: all 150ms ease;
  cursor: pointer;
  background:  #fff;
  width:200px;

  

  &:active {
    background:#fff;
  }

  &:focus-visible {
    outline: none;
  }
  `
);
