import React from "react";
import { LabelPrimaryColor, PaymentCardGradient, TypographyBgColor, TypographyTextFontFamily } from "../UiDesign";

const CategorycardNoimg = (props) => {
  const { name } = props;
  return (
    <LabelPrimaryColor border={2} className="newcategory sub-class-card shadow-sm">
      <TypographyTextFontFamily
        type="category"
        className="text-center font-weight-bolder ow-anywhere py-2 px-1"
        style={{fontSize:'1.2rem'}}
      >
        {name}
      </TypographyTextFontFamily>
    </LabelPrimaryColor>
  );
};

export default CategorycardNoimg;
