import { SubmissionError } from "redux-form";
import { CreateNewAccount } from "../../actions/profile/profileAction";

const createaccountSubmit = (e, values, dispatch, props) => {
  let mobile_number;
  let alternate_ph;

  if (typeof values.mobile_no === "number") {
    mobile_number = values.mobile_no;
  } else {
    mobile_number = values.mobile_no.replace(/-/g, "");
   
  }
 
  if (typeof values.alternate_phone === "number") {
    alternate_ph = values.alternate_phone;
  } else {
    alternate_ph = values.alternate_phone?values.alternate_phone===""?0:values.alternate_phone.replace(/-/g, ""):0;
   
  }

 
  var formValues = {
    first_name: values.first_name ? values.first_name : "",
    last_name: values.last_name ? values.last_name : "",
    email: values.email_address,
    callingcode: 1,
    phone: mobile_number,
    password: values.password,
    password_confirm: values.confirm_password,
    address: values.address ? values.address : "",
    city_name: values.city_name ? values.city_name : "",
    state_name: values.state_name ? values.state_name.value : "",
    zipcode: values.zipcode ? values.zipcode : "",
    images: "",
    terms_condition_privacy: values.terms_condition_privacy ? true : false,
    street_name:values.street_name?values.street_name:"",
    alternate_phone:alternate_ph
  };
  
  if (values.zipcode.replace(/_/g,'').length === 5) {
    return CreateNewAccount(formValues, dispatch)
      .then((res) => {
        if (res.errors) {
          if (res.errors.data) {
            e.createSuccessMsg("error", res.errors.data.errors);
          } else {
            throw new SubmissionError(res.errors.data.errors);
          }
        } else {
          let storevalue=JSON.parse(localStorage.getItem("Store_address"))
          if(storevalue.is_user_must_be_activate===false)
          e.createSuccessMsg("success", "Create account successfully");
          // localStorage.setItem('userdata', JSON.stringify(sendValues.username));
          e.redirectToSuccessPage(
            values.mobile_no,
            values.email_address,
            values.password
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    e.createSuccessMsg("error", "Please enter valid zipcode");
  }
};

export default createaccountSubmit;
