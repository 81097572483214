import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { TypographyHoverTextColor } from "../UiDesign";
import DepartmentContent from "./DepartmentContent";
import { Fade, Paper, Popper } from "@mui/material";

export default function Department(props) {
  const {
    store_Data,
    department_type,
    CategoryDetails,
    SubCategoryListDetails,
    categoryLoader,
    FetchSubAndSubsubCategoryList,
    department_breadcumbs,
    handleBreadcumbs,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const fetchCategoryList = (category_id, level) => {
    console.log('department=====',category_id);
    if (category_id.has_child === false) {
      handlePopoverClose()
    }
    FetchSubAndSubsubCategoryList(category_id, level);
   
  };

  const open = Boolean(anchorEl);

  return (
    <div
    
      onMouseLeave={handlePopoverClose}
      aria-owns={anchorEl!==null ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
    >
      <TypographyHoverTextColor Color="category"   onMouseEnter={handlePopoverOpen}>
        DEPARTMENTS
      </TypographyHoverTextColor>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <DepartmentContent
                store_Data={store_Data}
                CategoryDetails={CategoryDetails}
                categoryLoader={categoryLoader}
                FetchSubAndSubsubCategoryList={(category_id, level) =>
                  fetchCategoryList(category_id, level)
                }
                department_breadcumbs={department_breadcumbs}
                handleBreadcumbs={(type, data) => handleBreadcumbs(type, data)}
                department_type={department_type}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
