import React, { Component } from "react";
import { Fab, Snackbar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { required } from "redux-form-validators";
import { sufixIconInput, sweetAlertBox } from "../utils/renderUtils/renderUtil";
import LOGO_IMAGE from "../assets/images/logo.png";
import { ForgetPassword } from "../actions/login/loginAction";
import { Redirect } from "react-router-dom";
import ApiUrls from "../utils/apiUtils/apiUrl";
import {
  SecondaryButton,
  SecondaryCancelButton,
  TypographyFontColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "./common/UiDesign";

const submit = (e, values, dispatch, props) => {
  return ForgetPassword(values, dispatch).then((response) => {
    if (response.status === 200) {
      e.createSuccessMsg("success", "A reset link has been sent your mail id");
    } else {
      e.createSuccessMsg("error", " Please type the register mail id");
    }
  });
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      success: false,
      error: false,
    };
  }

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      // this.errorClick();
      if (msg.username) {
        sweetAlertBox('Attention!!',msg.username[0],"info")
        // this.setState({
        //   error: msg.username[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox('Error!!',msg,"error")
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  componentDidMount() {
    document.title = "Forgot password";
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    this.setState({ logo: logo });
  }

  render() {
    const { handleSubmit } = this.props;
    const { success, error } = this.state;
    return (
      <div>
        {localStorage.getItem("isLogin") === "true" ? (
          <Redirect to="/" />
        ) : (
          <div className="main-content">
            <div className="container-fluid p-0">
              <div className="login-main">
                <div className="header-pop">
                  <Link to="/">
                    <img
                      src={
                        this.state.logo
                          ?this.state.logo[0]==="/"? ApiUrls.BASH_URL + "/media/" + this.state.logo:this.state.logo
                          : LOGO_IMAGE
                      }
                      // src={LOGO_IMAGE}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="login-page">
                  <div className="login-form">
                    <form onSubmit={handleSubmit(submit.bind(null, this))}>
                      <div className="login-head">
                      <TypographyTextColor component={"h4"} Color="heading">Forgot Password</TypographyTextColor>
                      <TypographyTextColor
                            component="span"
                            Color="other"
                            className="sub-head"
                          >
                          We’ll help you reset it and get back on track
                        </TypographyTextColor>
                      </div>
                      <div className="user-input">
                        <Field
                          name="email_address"
                          label={"Email Address"}
                          id="outlined-basic"
                          component={sufixIconInput}
                          type="text"
                          size="small"
                          rootClass=""
                          required={true}
                          validate={[
                            required({ message: "Email Address is required." }),
                          ]}
                        />
                      </div>
                      <div
                        className="bttn-fill"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        {/* <Link to className="bttn_grn">
                      Submit
                    </Link> */}
                        <SecondaryButton
                          className="bttn_grn"
                          variant="extended"
                          type="submit"
                          color="primary"
                          Fonttype="other"
                        >
                          Submit
                        </SecondaryButton>
                        <TypographyTextFontFamily type="other" component="p" className="login-txt">
                          Remember your password?{" "}
                          <TypographyFontColor
                          Fonttype="other"
                            border={0}
                            role="button"
                            className="ml-1"
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/login",
                              })
                            }
                          >
                            Sign in
                          </TypographyFontColor>
                        </TypographyTextFontFamily>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <Snackbar
              open={this.state.error ? this.state.error : this.state.success}
              onClose={this.handleClose}
              autoHideDuration={6000}
              className={this.state.success ? "success" : "error"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <i className="icon-close"></i>
                  </IconButton>
                </React.Fragment>
              }
              message={
                <React.Fragment>
                  <div className="row gutter-8">
                    <div className="col-auto">
                      <i className="icon-closer"></i>
                    </div>
                    <div className="col">
                      <p>
                        {this.state.error
                          ? this.state.error
                          : this.state.success}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(
  reduxForm({ form: "ForgotPassword" })(ForgotPassword)
);
