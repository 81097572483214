import React from "react";
import {
  sendDateFormat,
  postfullTimeFormat,
  formatPhoneNumberCommon,
} from "../../../utils/renderUtils/renderUtil";
const OrderPdf = (props) => {
  const {
    order,
    extraCharges,
    customer_details,
    orderDetails,
    deliveryAddress,
  } = props;
  return (
    <div>
      <table style={{ width: "100%" }}>
        <tr>
          <td>
            {" "}
            <div className="main-head" id="hidden_logo"></div>
          </td>
          <td style={{ textAlign: "right", fontSize: "1.2rem" }}>
            <h5> Order #{order ? "ORD" + order.order_id : ""}</h5>
            <h5>
              {" "}
              Date: {order ? sendDateFormat(order.order_date) : ""}{" "}
              {order ? postfullTimeFormat(order.order_date) : ""}
            </h5>
          </td>
        </tr>
      </table>
      <div style={{ textAlign: "center", marginTop: "25px" }}>
        <h5>Order Summary</h5>
      </div>
      <div
        style={{
          display: " flex",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            border: " 1px solid gray",
            width: "40%",
            borderRadius: "10px",
            padding: "7px",
          }}
        >
          <div>
            <div style={{ borderBottom: "1px solid gray" }}>
              <h6>Customer Details</h6>
            </div>
            {customer_details ? (
              <div className="summ_details">
                <h6>
                  {customer_details
                    ? customer_details?.first_name +
                      " " +
                      customer_details?.last_name
                    : ""}
                </h6>
                {deliveryAddress.delivery_type === "pickup" &&
                props.guest_id !== null ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <span>
                      {customer_details.street_name
                        ? customer_details.street_name
                        : ""}

                      {customer_details.address ? (
                        <>{"APT/FLOOR -  " + customer_details.address}</>
                      ) : (
                        ""
                      )}
                      <br></br>
                      {customer_details.city +
                        ", " +
                        customer_details.state +
                        " " +
                        customer_details.zipcode}
                    </span>
                  </>
                )}
                <br></br>
                <span>
                  {customer_details && customer_details.email
                    ? customer_details.email
                    : ""}
                </span>
                <span className="summ_mobile">
                  {customer_details && customer_details.phone
                    ? formatPhoneNumberCommon(customer_details.phone)
                    : ""}
                  {customer_details.alternate_phone
                    ? " / " +
                      formatPhoneNumberCommon(customer_details.alternate_phone)
                    : ""}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          style={{
            border: "1px solid gray",
            width: "40%",
            borderRadius: "10px",
            padding: " 7px",
          }}
        >
          <div id="delivery_address">
            <div style={{ borderBottom: "1px solid gray" }}>
              {deliveryAddress &&
              deliveryAddress?.delivery_type === "pickup" ? (
                <h6>Pickup Address</h6>
              ) : deliveryAddress?.delivery_type === "delivery" ? (
                <h6>Delivery Address</h6>
              ) : deliveryAddress?.delivery_type === "shipping" ? (
                <h6>Shipping Address</h6>
              ) : (
                <h6>No Delivery/pickup</h6>
              )}
            </div>

            <div style={{ fontSize: "13px", marginTop: "10px" }}>
              {deliveryAddress?.delivery_type === "No Delivery Pickup" ? (
                "No delivery/pickup"
              ) : (
                <div className="summ_details">
                  <h6>
                    {deliveryAddress
                      ? deliveryAddress?.first_name +
                        " " +
                        deliveryAddress?.last_name
                      : ""}
                  </h6>

                  <span>
                    {deliveryAddress &&
                    deliveryAddress?.delivery_type === "pickup" ? (
                      <>
                        {deliveryAddress && deliveryAddress?.appartment_name
                          ? deliveryAddress.appartment_name
                          : ""}
                        {deliveryAddress && deliveryAddress?.street_name ? (
                          <>
                            <br></br>
                            {"APT/FLOOR - " + deliveryAddress.street_name}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        {deliveryAddress && deliveryAddress?.street_name
                          ? deliveryAddress.street_name
                          : ""}
                        {deliveryAddress && deliveryAddress?.appartment_name ? (
                          <>
                            <br></br>
                            {"APT/FLOOR - " + deliveryAddress.appartment_name}
                          </>
                        ) : (
                          ""
                        )}
                        <br></br>
                      </>
                    )}

                    {deliveryAddress && deliveryAddress?.city
                      ? deliveryAddress.city + ", "
                      : ""}
                    {deliveryAddress && deliveryAddress?.state
                      ? deliveryAddress.state + " "
                      : ""}
                    {deliveryAddress && deliveryAddress?.zipcode
                      ? deliveryAddress.zipcode
                      : ""}
                    <br></br>
                  </span>
                  <span className="summ_mobile">
                    {deliveryAddress
                      ? formatPhoneNumberCommon(deliveryAddress.phone)
                      : ""}
                    {deliveryAddress && deliveryAddress.alternate_phone ? (
                      <>
                        {" / " +
                          formatPhoneNumberCommon(
                            deliveryAddress.alternate_phone
                          )}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}

                    {deliveryAddress && deliveryAddress?.delivery_remark ? (
                      <>
                        <br></br>
                        {"Delivery Remark : " +
                          deliveryAddress.delivery_remark}{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </span>

                  {deliveryAddress?.pickup_message === undefined ||
                  deliveryAddress?.pickup_message === "undefined" ||
                  deliveryAddress?.pickup_message === "" ||
                  deliveryAddress?.pickup_message === null ? (
                    ""
                  ) : (
                    <>
                      <span>{deliveryAddress.pickup_message}</span>
                      <br />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid gray",
            width: "40%",
            borderRadius: "10px",
            padding: "7px",
          }}
        >
          <div>
            <div
              style={{ borderBottom: "1px solid gray", fontSize: "0.95rem" }}
            >
              <h6>Payable Amount &amp; Method </h6>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h6>
                ${order && order?.amount ? Number(order.amount).toFixed(2) : ""}
              </h6>
              {order?.ebt_total_price !== 0 ||
              order?.method?.split(",").includes("credit-card") ? (
                <div style={{ fontSize: "11px", fontStyle: "italic" }}>
                  (Your card was temporarily authorized for $
                  {order && order?.amount
                    ? Number(order.amount).toFixed(2)
                    : "0.00"}
                  . You should see the hold removed, and a final charge
                  reflected on your statement within 3-7 business days
                  (depending on your banks policies) of order completion.)
                </div>
              ) : (
                <></>
              )}

              {order?.other_total_price !== 0 ? (
                <>
                  {" "}
                  <span>
                    {order?.method?.split(",").includes("cash_payment")
                      ? "Cash"
                      : order?.method?.split(",").includes("pickup_pay")
                      ? "Pickup & Pay"
                      : order?.method?.split(",").includes("credit-card")
                      ? "Credit Card"
                      : ""}
                  </span>
                  {order?.method?.split(",").includes("card_payment") ||
                  order?.method?.split(",").includes("credit-card") ? (
                    <span>
                      {" "}
                      : {order?.card_type} ({order?.card_number})
                    </span>
                  ) : (
                    <></>
                  )}
                  {order?.ebt_total_price !== 0 ||
                  (order?.customer_credit_price !== 0 &&
                    props.is_show_customer_credit)
                    ? " $" + order?.other_total_price
                    : ""}
                  <br />
                </>
              ) : (
                <></>
              )}
              <span>
                {order?.customer_credit_price !== 0 &&
                props.is_show_customer_credit ? (
                  <>
                    <span>
                      {"Customer Credits"}: $
                      {parseFloat(order?.customer_credit_price).toFixed(2)}
                    </span>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {order?.ebt_total_price !== 0
                  ? "EBT : $" + order?.ebt_total_price
                  : ""}
              </span>
              <br />
              {deliveryAddress?.delivery_type === "No Delivery Pickup" ||
              deliveryAddress?.delivery_type === "shipping" ? (
                ""
              ) : (
                <>
                  <span>
                    {" "}
                    <span className="text-capitalize">
                      {deliveryAddress?.delivery_type
                        ? deliveryAddress.delivery_type
                        : ""}{" "}
                      Date :{" "}
                    </span>
                    {deliveryAddress?.delivery_datetime
                      ? deliveryAddress.delivery_datetime.split("-")[1] +
                        "/" +
                        deliveryAddress.delivery_datetime.split("-")[2] +
                        "/" +
                        deliveryAddress.delivery_datetime.split("-")[0]
                      : ""}
                  </span>
                  <br />
                  <span>
                    {" "}
                    <span className="text-capitalize">
                      {deliveryAddress?.delivery_type
                        ? deliveryAddress.delivery_type
                        : ""}{" "}
                      Time :{" "}
                    </span>
                    {deliveryAddress?.delivery_option
                      ? deliveryAddress?.delivery_option === "instant_delivery"
                        ? postfullTimeFormat(order.order_date)
                        : deliveryAddress.delivery_option
                      : ""}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            border: "1px solid gray",
            width: "100%",
            borderRadius: "10px",
            padding: "15px 5px",
          }}
        >
          <div style={{ padding: "0px 8px" }}>
            <h5>Order Details</h5>
          </div>
          <div>
            <table style={{ width: "100%", padding: "8px" }} cellspacing="0">
              <tbody>
                <tr
                  style={{
                    backgroundColor: "rgb(186, 184, 184)",
                    height: "45px",
                    margin: "0px 8px",
                    width: "98%",
                  }}
                >
                  <td style={{ textTransform: "uppercase", width: "40%" }}>
                    <span>Item Description</span>
                  </td>
                  <td
                    align="center"
                    style={{ textTransform: "uppercase", width: "20%" }}
                  >
                    <span>Quantity</span>
                  </td>
                  <td
                    align="center"
                    style={{ textTransform: "uppercase", width: "20%" }}
                  >
                    <span>Unit Price</span>
                  </td>
                  <td
                    align="center"
                    style={{ textTransform: "uppercase", width: "20%" }}
                  >
                    <span>Subtotal</span>
                  </td>
                </tr>
                {orderDetails && orderDetails.length > 0
                  ? orderDetails.map((row, index) => {
                      return (
                        <tr>
                          <td style={{ width: "40%" }}>
                            <div>
                              <div style={{ display: "flex" }}>
                                <div style={{ alignSelf: "center" }}>
                                  <div
                                    style={{
                                      width: "200px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <span> {row.name}</span>
                                    <h6>
                                      {row.quantity > 0 &&
                                      row.is_by_weight === true &&
                                      row.estimate_weight_per_piece > 0
                                        ? "est. " +
                                          Number(
                                            row.estimate_weight_per_piece *
                                              row.quantity
                                          ).toFixed(2) +
                                          " LB / $" +
                                          Number(
                                            row.estimate_weight_per_piece *
                                              row.quantity *
                                              row.unit_price
                                          ).toFixed(2)
                                        : ""}
                                      {row.container_type
                                        ? row.container_type
                                        : ""}
                                    </h6>
                                  </div>
                                  <p>
                                    {row.note
                                      ? row.note.substring(0, 100) +
                                        `${
                                          row.note.substring(0, 100).length >
                                          100
                                            ? "...."
                                            : ""
                                        }`
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td align="center" style={{ width: "20%" }}>
                            <span>
                              {row.quantity}{" "}
                              {row.is_case === true ? "Case" : ""}
                            </span>
                          </td>
                          <td align="center" style={{ width: "20%" }}>
                            <span>
                              ${row.unit_price.toFixed(2)}
                              {row.is_by_weight === true ? " / LB" : ""}
                            </span>
                          </td>
                          <td align="center" style={{ width: "20%" }}>
                            <span>${row.total.toFixed(2)}</span>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <div style={{ margin: "15px 23px" }}>
          <div style={{ borderBottom: "1px solid gray", fontSize: "1.2rem" }}>
            <h5>Order Summary</h5>
          </div>
          <div>
            <ul style={{ width: "300px", padding: 0, lineHeight: "1.5" }}>
              <li style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Subtotal</span>
                </div>
                <div>
                  <span>
                    {" "}
                    ${order ? Number(order?.subtotal_price).toFixed(2) : ""}
                  </span>
                </div>
              </li>
              {order?.discount === 0 ? (
                <></>
              ) : (
                <li>
                  <div>
                    <span>Discount</span>
                  </div>
                  <div>
                    <span>
                      -$
                      {order ? Number(order.discount).toFixed(2) : ""}
                    </span>
                  </div>
                </li>
              )}
              <li style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Shipping Charge</span>
                </div>
                <div>
                  <span>
                    ${order ? Number(order?.shipping_charges).toFixed(2) : ""}
                  </span>
                </div>
              </li>
              <li style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Estimated Tax</span>
                </div>
                <div>
                  <span> ${order ? Number(order?.tax).toFixed(2) : ""}</span>
                </div>
              </li>
              <li style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Delivery Charges</span>
                </div>
                <div>
                  <span>
                    {" "}
                    ${order ? Number(order?.delivery_charges).toFixed(2) : ""}
                  </span>
                </div>
              </li>
            </ul>

            {extraCharges?.tip_for_packer === false &&
            extraCharges?.tip_for_delivery_boy === false &&
            extraCharges?.donation === false ? (
              <></>
            ) : (
              <ul style={{ width: "300px", padding: 0, lineHeight: "1.5" }}>
                {extraCharges?.tip_for_packer === true ? (
                  <li
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <span>Tip for Packer</span>
                    </div>
                    <div>
                      <span>
                        ${order ? Number(order.tip_for_packer).toFixed(2) : ""}
                      </span>
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {extraCharges?.tip_for_delivery_boy === true ? (
                  <li
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <span>Delivery Tip</span>
                    </div>
                    <div>
                      <span>
                        $
                        {order
                          ? Number(order.tip_for_delivery_boy).toFixed(2)
                          : ""}
                      </span>
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {extraCharges.donation === true ? (
                  <li
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <span>
                        Donation(
                        {extraCharges.donation_name})
                      </span>
                    </div>
                    <div>
                      <span>
                        $
                        {order && order.donation
                          ? Number(order.donation).toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid gray",
              padding: "15px 0px",
              fontWeight: 700,
            }}
          >
            <div>
              <span>Total</span>
            </div>
            <div>
              <span>${order ? Number(order.amount).toFixed(2) : ""}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPdf;
