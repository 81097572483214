import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import {
  generateDeviceId,
  tokenFunctionlity,
} from "../../utils/renderUtils/renderUtil";
var cancel;
const NetworkMsg = "Network error. Please try again.";
var CancelToken = axios.CancelToken;
export async function GuestLoginAthentication() {
  const loginUrl = ApiUrls.GUEST_LOGIN;
  let Device_id = generateDeviceId();
  const formDate = {
    device_id: Device_id,
  };
  return axios
    .post(loginUrl, formDate)
    .then((res) => {
      if (res.data && res.status === 200) {
        localStorage.setItem("DeviceId", JSON.stringify(Device_id));
        localStorage.setItem("isLogin", false);
        localStorage.setItem("listOpen", false);
      }
      return res;
    })
    .catch((error) => {
      let errorMsg = error.response?.data?.errors?.Origin[0];
      if (errorMsg === "Invalid Origin") {
        window.location.href = "/not-found";
      } else if (error) {
        return error;
      }
    });
}
export async function GuestStoreAddress() {
  const loginUrl = ApiUrls.SOTRE_ADDRESS;
  const formDate = {};
  return axios
    .post(loginUrl, formDate)
    .then((res) => {
      if (res.data && res.status === 200) {
      }
      return res;
    })
    .catch((error) => {
      let errorMsg = error.response.data?.errors?.Origin[0];
      if (errorMsg === "Invalid Origin") {
        window.location.href = "/not-found";
      } else if (error) {
        return error;
      }
    });
}
export function CategeriesListView(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.CATEGORIES_LIST +
    "?limit=100&schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function SubCategeriesListView(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.CATEGORIES_LIST +
    "?parent_id=" +
    formValues.ids +
    "&limit=" +
    formValues.limit;

  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function SubSubCategeriesListView(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.SUB_SUBCATEGORY_LIST +
    "?category_id=" +
    formValues.category_id +
    "&subcategory_id=" +
    formValues.subcategory_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function CategoryManagementList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.CATEGORY_MANAGEMENT_LIST +
    "?schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function TodaysDealProductsList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.TODAYS_DEAL +
    "?limit=" +
    formValues.limit +
    "&schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function MostOrderedProductList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.MOST_ORDERED_URL +
    "?recently_ordered=" +
    formValues.recently_ordered;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function PopularProductsList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.POPULAR_PRODUCT +
    "?limit=" +
    formValues.limit +
    "&schedule_type=" +
    formValues.scheduleType +
    "&page=" +
    formValues.page +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;

  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function BestSellingProductsList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.BEST_SELLING_PRODUCT +
    "?limit=" +
    formValues.limit +
    "&schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function BrandProductList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.BRAND_URL +
    "?limit=" +
    formValues.limit +
    "&page=" +
    formValues.page +
    "&order=brand_order";
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function ProductsListListingPage(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  if (formValues.category_id === "undefined") {
    formValues.category_id = "";
  }
  if (formValues.subcategory_id === "undefined") {
    formValues.subcategory_id = "";
  }
  let url =
    ApiUrls.PRODUCT_LIST +
    "?limit=" +
    formValues.limit +
    "&page=" +
    formValues.page +
    "&product_type=" +
    formValues.product_type +
    "&kosher=" +
    formValues.kosher +
    "&flavor=" +
    formValues.flavor +
    "&brand_id=" +
    formValues.brand_id +
    "&category_id=" +
    formValues.category_id +
    "&price_min=" +
    formValues.price_min +
    "&price_max=" +
    formValues.price_max +
    "&product_deal=" +
    formValues.product_deal +
    "&search=" +
    formValues.search +
    "&top_menu=" +
    formValues.top_menu +
    "&schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&order=" +
    formValues.order +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
    if(formValues.is_homepage)
    {
      url+="&is_homepage="+formValues.is_homepage
    }
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function UserListPage(formValues, dispatch) {
  let configToken = tokenFunctionlity();

  const url = ApiUrls.USER_LIST_URL;
  return axios
    .post(url, formValues, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function ProductDetailsApi(formValues, dispatch) {
  let token;
  if (cancel !== undefined) {
    cancel();
  }
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }

  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    };
  }
  const url =
    ApiUrls.PRODUCT_DETAILS +
    "/" +
    formValues.slug +
    "?extra_info=" +
    formValues.extra_info +
    "&next_info=" +
    formValues.next_info +
    "&pre_info=" +
    formValues.pre_info +
    "&special_filter=" +
    formValues.special_filter +
    "&special_filter_value=" +
    formValues.special_filter_value;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function AddToCartFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.ADDTO_CART;
  return axios
    .post(url, formValues, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function UpdateCartFunctionlity(formValues, dispatch) {
  if (cancel !== undefined) {
    cancel();
  }
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    };
  }
  const url = ApiUrls.ADDTO_CART;
  return axios
    .put(url, formValues, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function ViewCartCount(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.CART_COUNT;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function WishListFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.WISHLIST_URL;
  return axios
    .post(url, formValues, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function GetWishListFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.WISHLIST_URL;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function ProductsSearchApi(formValues, dispatch) {
  if (cancel !== undefined) {
    cancel();
  }
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: token,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    };
  }
  if (formValues.category_id === "undefined") {
    formValues.category_id = "";
  }
  if (formValues.subcategory_id === "undefined") {
    formValues.subcategory_id = "";
  }
  const url =
    ApiUrls.PRODUCT_LIST +
    "?search=" +
    formValues.search +
    "&limit=" +
    formValues.limit +
    "&category_id=" +
    formValues.category_id +
    "&schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function FilterListApiByBrandFlavorKosher(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.FILTER_LIST_URL +
    "?filter_name=" +
    formValues.filter_name +
    "&limit=" +
    formValues.limit +
    "&page=" +
    formValues.page +
    "&category_id=" +
    formValues.category_id +
    "&schedule_type=" +
    formValues?.scheduleType +
    "&zipcode=" +
    formValues?.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id +
    "&search=" +
    formValues.search +
    "&product_deal=" +
    formValues.product_deal +
    "&top_menu=" +
    formValues.top_menu;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function CategoryTreeViewApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.TREEVIEW_URL +
    "?schedule_type=" +
    formValues.scheduleType +
    "&zipcode=" +
    formValues.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id +
    "&is_special=" +
    (formValues?.is_special === undefined || formValues?.is_special === false
      ? false
      : true);
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function ProductCashPriseApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.PRODUCT_CASE_URL;
  return axios
    .post(url, formValues, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function LandingPageApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.LANDING_PAGE_URL;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function FooterManagementApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FOOTER_PAGE_URL;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function FooterPageListApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FOOTER_PAGELIST_URL + formValues.slug;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function HeaderMenuListApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url =
    ApiUrls.HEADER_MENU_URL +
    "?schedule_type=" +
    formValues?.scheduleType +
    "&zipcode=" +
    formValues?.zipCode +
    "&pickup_address_id=" +
    formValues.pickup_address_id +
    "&is_sales_rep=" +
    (localStorage.getItem(
      "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
    ) === null
      ? false
      : true);
  return axios
    .get(url, configToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function SubscribeaddApi(formValues, dispatch) {
  let token;
  if (JSON.parse(localStorage.getItem("access_token"))) {
    token = JSON.parse(localStorage.getItem("access_token")).access_token;
  }
  let configToken;
  if (token) {
    configToken = {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: localStorage.getItem("isLogin") === "true" ? token : "",
      },
    };
  }
  const url = ApiUrls.USER_SUBSCRIBE_URL;
  return axios
    .post(url, formValues, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function SubscribeApi(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.SUBSCRIBE_URL;
  return axios
    .get(url, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}

export function GetCustomizationSettings() {
  let configToken = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
  };
  const url = ApiUrls.CUSTOMIZE_SETTING;
  return axios
    .post(url, {}, configToken)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
