import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import Commonsnackbar from "../common/commonsnackbar";
import collect from "../../assets/images/collect.png";
import home from "../../assets/images/home_delivery.png";
import shipping from "../../assets/images/shipping.png";
import {
  GetSlotForDeliveryAndPickup,
  GetSlotVisiblity,
  RestrictDeliveryApi,
} from "../../actions/order/orderAction";
import { GuestStoreAddress } from "../../actions/home/homeAction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckIcon from "@mui/icons-material/Check";
import { RefreshLogin } from "../../actions/login/loginAction";
import CalendarToday from "@mui/icons-material/CalendarToday";
import moment from "moment";
import {
  RefreshApiToken,
  sweetAlertBox,
} from "../../utils/renderUtils/renderUtil";
import {
  PrimaryBorderButton,
  PrimaryButton,
  SecondaryCancelButton,
  TypographyBgColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographySecondaryColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../common/UiDesign";
import AddressViewBlock from "../common/AddressViewBlock";
import StoreMessagePopup from "../common/Cart/StoreMessagePopup";
import { IoMdTime } from "react-icons/io";
const shoppingMethod = [
  {
    imgSrc: collect,
    Title: "PICK UP",
    describe: "Get your goods from store.",
    type: "pickup",
    isActive: true,
  },
  {
    imgSrc: home,
    Title: "Home delivery",
    describe: "Get your goods delivered right to your door.",
    type: "delivery",
    isActive: false,
  },
  {
    imgSrc: shipping,
    Title: "Shipping",
    describe: "Shipping your goods from anywhere at",
    type: "shipping",
    isActive: false,
  },
];
export default class DeliveryPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openZipCodePopup: false,
      openPickupPop: false,
      addressDetails: "",
      scheduleType: "",
      slotData: [],
      zipcode: "",
      time_Slot: [],
      next_rec: true,
      prev_rec: true,
      currData: 0,
      nextData: window.screen.availWidth >= 900 ? 7 : 3,
      isPickup: false,
      isDelivery: false,
      avalibiliyMess: "",
      time_slot_24h: [],
      allSlotData: [],
      MainLoader: false,
      Shopping_method: localStorage.getItem("shoppingmethod"),
      shoppingMethodData: shoppingMethod,
      isZipcodepopupActive: true,
      pickupAddresscount: 0,
      pickupaddressDetails: {},
      addressloader: false,
      openStoreMsg: false,
      storeAddress_data: JSON.parse(localStorage.getItem("Store_address")),
      deliveryOptionType: JSON.parse(localStorage.getItem("Store_address"))
        ?.instant_delivery
        ? "instant"
        : "other",
      slotLoader: false,
    };
  }

  openZipcodePop = (type) => {
    if (
      localStorage.getItem("isLogin") !== "true" &&
      this.props.history.location.pathname !== "/cart"
    ) {
      if (type === "delivery") {
        if (this.state.isZipcodepopupActive)
          this.setState({ openZipCodePopup: true });
        else {
          this.setState({ scheduleType: "Delivery", zipcode: 11111 });
          this.GetDeliveryAndPickupTimeSlot(
            "Delivery",
            11111,
            0,
            this.state.nextData
          );
        }
      } else {
        this.props.setSlotName("Shipping", "11111", 3, "", "");
        localStorage.removeItem("SelectedAddress");
      }
    } else {
      if (
        this.props.history.location.pathname === "/cart" ||
        type === "delivery"
      )
        this.props.CloseDialog("openAddress", type);
      else {
        if (this.props.history.location.pathname !== "/cart")
          localStorage.removeItem("SelectedAddress");
        this.props.CloseDialog("close", type);
        this.props.setSlotName("Shipping", "11111", 3, "", "");
      }
    }
  };

  openPickupPop = () => {
    this.setState({ openPickupPop: true });
  };

  CloseZipCodePopupDialog = () => {
    this.setState({ openZipCodePopup: false, zipcode: "" });
    this.props.openDeliveryPickup();
  };

  ClosePickupPopDialog = () => {
    if (this.props.history.location.pathname !== "/cart") {
      localStorage.removeItem("slot");

      if (this.state.scheduleType === "Delivery")
        this.setState({
          openPickupPop: false,
          currData: 0,
          nextData: window.screen.availWidth >= 900 ? 7 : 3,
        });
      else {
        this.setState({
          openPickupPop: false,
          currData: 0,
          nextData: window.screen.availWidth >= 900 ? 7 : 3,
          openZipCodePopup: false,
        });
        this.props.openDeliveryPickup();
      }
    } else {
      this.setState({ openPickupPop: false });
      if (this.state.time_Slot.length === 0) {
        localStorage.removeItem("SelectedAddress");
        this.props.CloseDialog("close", true);
      }
      this.props.CloseDialog("close");
    }
  };
  componentDidMount() {
    // if (!this.props.setOpenDeliveryPopup) this.FetchAddress();
    this.FetchTimeSlotVisiliblity();
  }
  convertDataintoFineform = (data) => {
    let filterData = [];
    let mainData = [];
    var daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const ele = data[key];
        let currDt = ele.schedule_data[0];
        let temp = [];
        for (let i = 0; i < ele.schedule_data.length; ) {
          if (currDt.day_name === ele.schedule_data[i].day_name) {
            temp.push(ele.schedule_data[i]);
            i++;
          } else {
            filterData.push({ day_name: currDt.day_name, schedule_data: temp });
            temp = [];
            currDt = ele.schedule_data[i];
          }
        }
        if (temp.length > 0) {
          filterData.push({ day_name: currDt.day_name, schedule_data: temp });
          temp = [];
        }
        var loop = new Date(ele.from_date);
        let endDate = new Date(ele.to_date);
        let currDate = new Date();

        for (let i = 0; i < filterData.length; i++) {
          while (loop <= endDate) {
            let currDatedayname = daysOfWeek[moment(loop).day()];
            if (filterData[i]?.day_name === currDatedayname) {
              let fine = {
                date: loop.toLocaleDateString("en-US"),
                day_name: currDatedayname,
                slot_data: filterData[i].schedule_data,
              };
              mainData.push(fine);
            }
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
          loop = new Date(ele.from_date);
        }
        filterData = [];
      }
    }
    mainData.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    let currDate = new Date();
    currDate.setDate(currDate.getDate() - 1);
    let currMainData = [];
    mainData.forEach((element) => {
      if (new Date(element.date) >= currDate) {
        currMainData.push(element);
      }
    });
    return currMainData;
  };
  Fetchtimeslot = (slotData) => {
    
    let timeslotData = [];
    let timeData = [];
    for (const key of Object.keys(slotData)) {
      slotData[key].schedule_data.forEach((element) => {
        timeslotData.push({
          Start_time: moment(element.start_time, ["h:mm A"]).format("LT"),
          Till_time: moment(element.till_time, ["h:mm A"]).format("LT"),
        });
        timeData.push({
          stime: element.start_time,
          etime: element.till_time,
        });
      });
    }
    timeslotData = [
      ...new Map(
        timeslotData.map((item) => [item["Start_time"], item])
      ).values(),
    ];
    timeData = [
      ...new Map(timeData.map((item) => [item["stime"], item])).values(),
    ];

    for (let index = 0; index < timeslotData.length; index++) {
      for (let i = index + 1; i < timeslotData.length; i++) {
        if (
          moment(timeslotData[index].Start_time, ["h:mm A"]).format("HH:mm") >
          moment(timeslotData[i].Start_time, ["h:mm A"]).format("HH:mm")
        ) {
          let temp = timeslotData[index];
          timeslotData[index] = timeslotData[i];
          timeslotData[i] = temp;
          temp = timeData[index];
          timeData[index] = timeData[i];
          timeData[i] = temp;
        }
      }
    }
    console.log("time slot", timeslotData);
    this.setState({ time_Slot: timeslotData, time_slot_24h: timeData });
    
  };
  componentWillReceiveProps(nextprops) {
    if (nextprops.setOpenslotpupup) {
      let instantDelivery = this.state.storeAddress_data?.instant_delivery;
      this.setState({
        zipcode: nextprops.setSelectedAddress?.zipcode,
        deliveryOptionType:
          this.props.selectedslotPopupData === "delivery" && instantDelivery
            ? "instant"
            : "other",
      });
      this.props.closeSlotpupupStatus();
      if (this.props.selectedslotPopupData === "delivery" && instantDelivery) {
        this.setState({
          scheduleType:
            this.props.selectedslotPopupData === "delivery"
              ? "Delivery"
              : "Pickup",
        });
        this.openPickupPop();
      } else
        this.GetDeliveryAndPickupTimeSlot(
          this.props.selectedslotPopupData === "delivery"
            ? "Delivery"
            : "Pickup",
          nextprops.setSelectedAddress.zipcode,
          0,
          this.state.nextData
        );
    }
  }
  GetdeliveryRestriction = (visibility_status) => {
    let shopping = this.state.shoppingMethodData;
    if (localStorage.getItem("isLogin") === "true") {
      this.setState({ MainLoader: true });
      let formValue = { status: true };
      RestrictDeliveryApi(formValue, this.props.dispatch).then((res) => {
        if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.GetdeliveryRestriction(visibility_status);
              }
            );
          }
        }
        if (res.data) {
          console.log(this.state.deliveryOptionType);
          shopping.map((ele) => {
            if (ele.type === "delivery")
              ele.isActive =
                this.state.deliveryOptionType === "instant"
                  ? true
                  : res.data.status && visibility_status.delivery_status;
          });
          this.setState({
            isDelivery: res.data.status && visibility_status.delivery_status,
            MainLoader: false,
          });
        }
      });
    }
  };
  FetchAddress = () => {
    let addressDetails = [];
    let formValue = {};
    this.setState({ addressloader: true });
    GuestStoreAddress(formValue, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        addressDetails = res.data.additional_address[0];

        this.setState({
          pickupaddressDetails: addressDetails,
          addressloader: false,
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddress();
            }
          );
        }
      }
    });
  };
  FetchTimeSlotVisiliblity = () => {
    let shopping = [...this.state.shoppingMethodData];
    this.setState({ MainLoader: true });
    GetSlotVisiblity().then((res) => {
      let mess = "";
      let pickupcount = 0;
      if (res.data && res.data) {
        this.GetdeliveryRestriction(res.data);

        if (!res.data.pickup_status && !res.data.delivery_status) {
          mess =
            "There is no pickup or delivery right now. Please try again later";
          //  this.props.resetAddress();
        }
        for (let index = 0; index < shopping.length; index++) {
          if (shopping[index].type === "delivery") {
            shopping[index].isActive =
              this.state.deliveryOptionType === "instant"
                ? true
                : res.data.delivery_status;
          }
          if (shopping[index].type === "pickup") {
            shopping[index].isActive =
              res.data.pickup_address_count === 0 &&
              res.data.pickup_status === false
                ? false
                : true;
            pickupcount = res.data.pickup_address_count;
            if (pickupcount === 1) this.FetchAddress();
          } else if (shopping[index].type === "shipping") {
            shopping[index].isActive = localStorage
              .getItem("shoppingmethod")
              ?.split(",")
              .some((val) => val === "shipping");
          }
        }

        this.setState({
          isPickup: true,
          isDelivery: Boolean(res.data.delivery_status),
          avalibiliyMess: mess,
          MainLoader: false,
          shoppingMethodData: shopping,
          isZipcodepopupActive: Boolean(res.data.zip_available),
          pickupAddresscount: pickupcount,
        });
      } else if (res.errors && res.errors.status) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchTimeSlotVisiliblity();
            }
          );
        }
      } else {
        this.createSuccessMsg("error", res.errors.data.errors);
      }
    });
  };
  GetDeliveryAndPickupTimeSlot = (
    schedule_type,
    zone,
    currentData,
    nextData
  ) => {
    this.setState({ slotLoader: true });
    let formValue = {};
    const curr = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    
    formValue = {
      schedule_type: schedule_type === "Delivery" ? 1 : 2,
      date: `${curr.getFullYear()}-${curr.getMonth() + 1}-${curr.getDate()}`, //"2022-07-21",
      zipcode: zone === null ? "0" : zone,
      frontend: true,
      current_data: currentData,
      next_data: nextData,
    };

    GetSlotForDeliveryAndPickup(formValue, this.props.dispatch).then((res) => {
      let slotData = [];
      if (res.data && res.data.result) {
        slotData = res.data.result;
        if (Object.keys(slotData).length === 0) this.props?.resetAddress();
        this.Fetchtimeslot(slotData);
        slotData = this.convertDataintoFineform(slotData);
        console.log(res.data, slotData);
        this.setState({
          slotData: slotData,
          scheduleType: schedule_type,
          slotLoader: false,
        });
        this.getallSlotdata(slotData, currentData, nextData);
        this.openPickupPop();
      } else if (res.errors && res.errors._error && res.errors._error.status) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.GetDeliveryAndPickupTimeSlot();
            }
          );
        }
      } else {
        console.log(this.props.setOpenDeliveryPopup);
        if (this.props.setOpenDeliveryPopup)
          this.createSuccessMsg("error", res.errors.data.errors);
        else
          this.props.createSuccessMsg("error", res.errors.data.errors.error[0]);

        if (
          this.props.history &&
          this.props.history.location &&
          this.props.history.location.pathname === "/cart"
        )
          this.props?.resetAddress();
      }
      this.setState({ slotLoader: false });
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      console.log("hitt", msg);
      if (msg !== undefined && msg.zipcode !== undefined && msg.zipcode[0]) {
        sweetAlertBox("Attention!!", msg.zipcode[0], "info");
        // this.setState({
        //   error: msg.zipcode[0],
        //   success: false,
        // });
      } else if (msg && msg.error) {
        sweetAlertBox("Attention!!", msg.error[0], "info");
        // this.setState({
        //   error: msg.error[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    }
  };

  getallSlotdata = (slotData, curr, next) => {
    let allSlot = [];
    console.log(slotData);
    if (slotData.length > 0) {
      for (let index = 0; index < this.state.time_slot_24h.length; index++) {
        let data = [];
        let slot_weak = slotData.slice(curr, next);
        slot_weak.forEach((ele) => {
          return data.push({
            status: this.getSlotStatus(ele.slot_data, index),
            date_time:
              this.getMonthName_Date(ele.date) +
              " " +
              this.state.time_Slot[index].Start_time +
              " - " +
              this.state.time_Slot[index].Till_time,
            Actual_date: ele.date,
          });
        });
        allSlot.push(data);
        this.setState({ allSlotData: allSlot, currData: curr, nextData: next });
      }
    } else {
      this.setState({ allSlotData: allSlot, currData: curr, nextData: next });
    }
    console.log("all slot", allSlot);
  };
  getSlotStatus = (data, type = 0) => {
    if (data.length > 0) {
      let status = "";
      data.map((ele, ind) => {
        let startime = ele.start_time;
        let till_time = ele.till_time;
        let state_stime = this.state.time_slot_24h[type].stime;
        let state_etime = this.state.time_slot_24h[type].etime;
        if (startime >= state_stime && till_time <= state_stime) {
          status = "Open";
        } else if (startime === state_stime && till_time === state_etime) {
          status = "Open";
        }
      });
      return status === "Open" ? status : "Closed";
    } else {
      return "Closed";
    }
  };
  getMonthName_Date = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let ardate = date.split("/");
    const month = monthNames[ardate[0] - 1].slice(0, 3);
    let dt = date.substring(date.indexOf("/") + 1, date.indexOf("/", 3));
    dt = dt.toString().charAt(0) === "0" ? dt.toString().slice(1, 2) : dt;
    return month + " " + dt;
  };
  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
        this.setState({
          openZipCodePopup: false,
          openPickupPop: false,
        });
      }
    });
  };
  handleopenStoreMsgPopup = () => {
    let Store_Data = this.state.storeAddress_data;
    if (
      Store_Data?.store_start_time !== null &&
      Store_Data?.store_end_time !== null &&
      Store_Data?.store_start_time !== "" &&
      Store_Data?.store_end_time !== ""
    ) {
      const timeFormat = "HH:mm:ss"; // Specify the time format
      const time = moment(new Date()).format(timeFormat);
      const beforeTime = moment(new Date(Store_Data?.store_start_time)).format(
        timeFormat
      );
      const afterTime = moment(new Date(Store_Data?.store_end_time)).format(
        timeFormat
      );

      if (
        moment(time, timeFormat).isBetween(
          moment(beforeTime, timeFormat),
          moment(afterTime, timeFormat),
          null,
          "[)"
        )
      ) {
        console.log("No overlaps");
        this.getPickupSlot(window.screen.availWidth >= 900 ? 7 : 3, 0);
      } else {
        this.setState({ openStoreMsg: true });
        console.log("UTC time overlaps with local time.");
      }
    } else {
      this.getPickupSlot(window.screen.availWidth >= 900 ? 7 : 3, 0);
    }
  };

  getPickupSlot = (next_data, curr_data) => {
    
    this.setState({
      scheduleType: "Pickup",
      currData: curr_data,
      nextData: next_data,
    });
    // localStorage.setItem(
    //   "slot",
    //   JSON.stringify({ data: "", zipCode: "", schedule: 2 })
    // );
    if (next_data === this.state.nextData)
      this.props.CloseDialog("openAddress", "pickup");
    else
      this.GetDeliveryAndPickupTimeSlot(
        "Pickup",
        this.props.setSelectedAddress?.zipcode,
        curr_data,
        next_data
      );
  };

  getDeliverySlot = (next_data, curr_data) => {
    this.setState({ nextData: next_data, currData: curr_data });
    if (localStorage.getItem("frontend_animation") === "open") {
      this.setState({ scheduleType: "Delivery" });
      this.GetDeliveryAndPickupTimeSlot(
        "Delivery",
        this.state.zipcode,
        curr_data,
        next_data
      );
    } else {
      if (this.state.zipcode === "") {
        this.setState({
          error: "Please enter zone",
          success: false,
        });
      } else {
        this.setState({ scheduleType: "Delivery" });
        this.GetDeliveryAndPickupTimeSlot(
          "Delivery",
          this.state.zipcode,
          curr_data,
          next_data
        );
      }
    }
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };
  getSelectedSlot = (dataTime, actualdate) => {
    
    const data =
      this.state.scheduleType === "Delivery"
        ? "Store Delivery" + " " + dataTime
        : "Store Pick Up" + " " + dataTime;
    this.ClosePickupPopDialog();
    this.CloseZipCodePopupDialog();
    if (localStorage.getItem("frontend_animation") !== "open")
      this.props.CloseDialog("close", this.props.selectedslotPopupData);
    const zipCode = this.state.zipcode;
    const scheduleType =
      this.state.scheduleType === "Delivery"
        ? 1
        : this.state.scheduleType === "Pickup"
        ? 2
        : 3;
    this.props.setSlotName(data, zipCode, scheduleType, dataTime, actualdate);
  };
  handleNextdata = () => {
    let count_no = window.screen.availWidth >= 900 ? 7 : 3;
    const next = this.state.nextData + count_no;
    const curr = this.state.currData + count_no;
    this.getallSlotdata(this.state.slotData, curr, next);
  };
  handlePrevdata = () => {
    let count_no = window.screen.availWidth >= 900 ? 7 : 3;
    const next = this.state.nextData - count_no;
    const curr = this.state.currData - count_no;
    this.getallSlotdata(this.state.slotData, curr, next);
  };
  handleClickawayDeliveryPopup = () => {
    if (localStorage.getItem("slot")) this.props.CloseDialog("close");
  };

  render() {
    const { slotData, time_Slot, allSlotData, Shopping_method } = this.state;
    const frontend_animation = localStorage.getItem("frontend_animation");
    return (
      <>
        <Dialog
          open={this.props.setOpenDeliveryPopup}
          onClose={() => this.handleClickawayDeliveryPopup()}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp"
        >
          <DialogTitle id="scroll-dialog-title" className="p-0">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={TypographySecondaryColor}
                className="delivery-box-title"
                Fonttype="other"
              >
                Choose your fulfilment method
              </Typography>
              {frontend_animation &&
              frontend_animation === "open" &&
              this.props.history &&
              this.props.history.location &&
              this.props.history.location.pathname === "/cart" ? (
                <div className="header px-2 py-2">
                  <span className="float-right">
                    <a
                      href="javascript:void(0);"
                      onClick={() =>
                        this.props.CloseDialog(
                          "close",
                          this.props.selectedslotPopupData
                        )
                      }
                    >
                      <span class="icon-close"></span>
                    </a>
                  </span>
                </div>
              ) : (localStorage.getItem("slot") === undefined ||
                  localStorage.getItem("slot") === null) &&
                frontend_animation &&
                frontend_animation === "pickup_delivery" ? (
                <></>
              ) : (
                <div className="header px-2 py-2">
                  <span className="float-right">
                    <a
                      href="javascript:void(0);"
                      onClick={() =>
                        this.props.CloseDialog(
                          "close",
                          this.props.selectedslotPopupData
                        )
                      }
                    >
                      <span class="icon-close"></span>
                    </a>
                  </span>
                </div>
              )}
            </div>
          </DialogTitle>
          <DialogContent className="p-0" style={{ overflow: "hidden" }}>
            {this.state.MainLoader ? (
              <Box className="d-flex justify-content-center">
                <CircularProgress />
              </Box>
            ) : (
              <div className="popup">
                <div className="delivery-box">
                  {this.state.shoppingMethodData.map((ele, i) => {
                    return Shopping_method?.split(",").some(
                      (val) => val === ele.type && ele.isActive
                    ) ? (
                      <TypographyPrimaryBgColorDimmed
                        component={"div"}
                        className="delivery-box-tab rounded-lg"
                      >
                        <div>
                          <div className="delivery-box-tab-icon">
                            <img src={ele.imgSrc} />
                          </div>
                          <div className="delivery-box-tab-dec">
                            <TypographyTextColor Color="heading" component="h2">
                              {ele.Title}
                            </TypographyTextColor>
                            <TypographyTextColor Color="other">
                              {ele.describe}
                              {Shopping_method?.split(",").some(
                                (val) => val === "shipping"
                              ) && ele.type === "shipping"
                                ? " $" +
                                  parseFloat(
                                    localStorage.getItem("shipping_price")
                                  ).toFixed(2)
                                : ""}
                              {ele.type === "pickup" &&
                                this.state.pickupAddresscount === 1 &&
                                !this.state.addressloader && (
                                  <div>
                                    <AddressViewBlock
                                      selectedAddress={
                                        this.state.pickupaddressDetails
                                      }
                                    />
                                  </div>
                                )}
                            </TypographyTextColor>
                          </div>
                        </div>
                        <div className="chooseSlotMob">
                          <PrimaryButton
                            style={{ width: "100%" }}
                            Fonttype="other"
                            onClick={() => {
                              ele.type === "delivery"
                                ? this.openZipcodePop("delivery")
                                : ele.type === "pickup"
                                ? this.handleopenStoreMsgPopup()
                                : this.openZipcodePop("shipping");
                            }}
                          >
                            Select &nbsp;
                            <CalendarToday
                              style={{ fontSize: "20px", marginBottom: "5px" }}
                            />
                          </PrimaryButton>
                        </div>
                      </TypographyPrimaryBgColorDimmed>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </div>
            )}
          </DialogContent>
          <Commonsnackbar
            success={this.state.success}
            handleClose={this.handleClose}
            error={this.state.error}
          />
        </Dialog>

        <Dialog
          open={this.state.openZipCodePopup}
          onClose={() => this.CloseZipCodePopupDialog()}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp zip-box"
        >
          <DialogTitle className="p-0" id="scroll-dialog-title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => this.CloseZipCodePopupDialog()}
                style={{ cursor: "pointer" }}
              />
              <TypographyTextColor
                Color="heading"
                className="header px-2 py-2"
                component="div"
              >
                Enter your zipcode
              </TypographyTextColor>
            </div>
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="popup">
              <div className="zip-code-box">
                <TextField
                  value={this.state.zipcode}
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 5 &&
                      e.target.value.length != 6 &&
                      !isNaN(e.target.value)
                    )
                      this.setState({ zipcode: e.target.value });
                  }}
                  label="ZipCode"
                  variant="outlined"
                />
              </div>
            </div>
            <Commonsnackbar
              success={this.state.success}
              handleClose={this.handleClose}
              error={this.state.error}
            />
          </DialogContent>
          <DialogActions>
            {" "}
            <PrimaryButton
              onClick={() =>
                this.getDeliverySlot(window.screen.availWidth >= 900 ? 7 : 3, 0)
              }
            >
              Submit
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openPickupPop}
          onClose={() => this.ClosePickupPopDialog()}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="popUp slotPopup"
        >
          <DialogTitle id="scroll-dialog-title" className="py-0">
            <div style={{ display: "flex", alignItems: "center" }}>
              <KeyboardBackspaceIcon
                onClick={() => {
                  this.setState({ openPickupPop: false });
                  if (this.state.deliveryOptionType === "instant")
                    this.openZipcodePop("delivery");
                  else this.props.CloseDialog("close", true);
                }}
                style={{ cursor: "pointer" }}
              />
              {this.state.deliveryOptionType === "instant" ? (
                <TypographySecondaryColor className="delivery-box-title pl-3">
                  Instant Delivery
                </TypographySecondaryColor>
              ) : (
                <TypographySecondaryColor className="delivery-box-title">
                  {this.state.currData === 0 ? (
                    ""
                  ) : (
                    <div
                      className="leftNav"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handlePrevdata()}
                    >
                      <span class="icon-arrowleft"></span>
                    </div>
                  )}
                  &nbsp;&nbsp;
                  {this.state.scheduleType} Slot &nbsp;&nbsp;
                  {this.state.nextData >= slotData.length ? (
                    <></>
                  ) : (
                    <div
                      className="rightNav"
                      onClick={() => this.handleNextdata()}
                      style={{ cursor: "pointer" }}
                    >
                      <span class="icon-arrowright"></span>
                    </div>
                  )}
                </TypographySecondaryColor>
              )}
            </div>
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="popup">
              {this.state.deliveryOptionType === "instant" ? (
                <div>
                  <TypographyTextColor
                    Color="other"
                    component="div"
                    className="px-5 py-3"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {this.state.storeAddress_data?.instant_delivery_message}
                  </TypographyTextColor>
                  <div className="d-flex justify-content-between mt-2">
                    {this.state.isDelivery ? (
                      <PrimaryBorderButton
                        Fonttype="other"
                        className="d-flex align-items-start"
                        onClick={() => {
                          this.setState({ deliveryOptionType: "other" });
                          this.GetDeliveryAndPickupTimeSlot(
                            "Delivery",
                            this.state.zipcode,
                            0,
                            this.state.nextData
                          );
                        }}
                      >
                        <IoMdTime size={20} /> Schedule Date/Time
                      </PrimaryBorderButton>
                    ) : (
                      <></>
                    )}
                    <PrimaryBorderButton
                      Fonttype="other"
                      onClick={() => {
                        this.getSelectedSlot("Instant", new Date());
                      }}
                    >
                      Yes
                    </PrimaryBorderButton>
                  </div>
                </div>
              ) : this.state.slotLoader === true ? (
                <CircularProgress
                  style={{
                    display: "flex",
                    color: "#3d9b7b",
                    position: "relative",
                    right: "-35%",
                    "& > * + *": {
                      marginLeft: 2,
                    },
                  }}
                />
              ) : this.state.time_Slot.length > 0 && allSlotData.length > 0 ? (
                <div class="pickup-table-box">
                  <div class="pickup_table_wrap">
                    <div class="pickup_table_inner">
                      <TypographyBgColor
                        component={"div"}
                        className="tbl_fixed_head"
                      >
                        {slotData
                          .slice(this.state.currData, this.state.nextData)
                          .map((ele, indx) => {
                            return (
                              <div
                                class={`cell ${
                                  new Date(ele.date).toDateString() ===
                                    new Date().toDateString() && indx === 0
                                    ? "pickupDelivery_Popup_Today_title"
                                    : ""
                                }`}
                              >
                                {new Date(ele.date).toDateString() ===
                                new Date().toDateString() ? (
                                  <TypographyTextFontFamily
                                    type="other"
                                    style={{
                                      fontSize: "1.5rem",
                                      fontWeight: "bold",
                                    }}
                                    component={"span"}
                                  >
                                    {" "}
                                    Today
                                  </TypographyTextFontFamily>
                                ) : (
                                  <>
                                    <p>
                                      {window.screen.availWidth <= 700
                                        ? ele.day_name.slice(0, 3)
                                        : ele.day_name}
                                    </p>
                                    <p>{this.getMonthName_Date(ele.date)} </p>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </TypographyBgColor>
                      <div class="timesec">
                        <table cellpadding="0" cellspacing="0" class="table">
                          <TypographyBgColor component={"thead"}>
                            <tr>
                              <th>&nbsp;</th>
                            </tr>
                          </TypographyBgColor>
                          <tbody>
                            {time_Slot
                              ? time_Slot.map((ele, i) => {
                                  return (
                                    <tr class="ng-star-inserted" key={i}>
                                      <TypographySecondaryBgColor
                                        component={"td"}
                                      >
                                        {" "}
                                        {ele.Start_time} - {ele.Till_time}
                                      </TypographySecondaryBgColor>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      <div class="teblesec">
                        <div class="scrollwrap">
                          <div class="tblcol ">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              class="table"
                            >
                              <TypographyBgColor component={"thead"}>
                                <tr>
                                  <th> &nbsp; </th>
                                </tr>
                              </TypographyBgColor>
                              <tbody>
                                {allSlotData && allSlotData.length > 0 ? (
                                  allSlotData.map((ele, indx) => {
                                    return (
                                      <tr class="ng-star-inserted" key={indx}>
                                        {ele.map((val, ind) => {
                                          return (
                                            <td key={ind}>
                                              <Typography
                                                component={
                                                  SecondaryCancelButton
                                                }
                                                // style={{
                                                //   border:
                                                //     val.status === "Open"
                                                //       ? "1px solid #3d9b7b"
                                                //       : "none",
                                                // }}
                                                className={
                                                  val.status === "Open"
                                                    ? ""
                                                    : `timeclose`
                                                }
                                                onClick={() =>
                                                  val.status === "Open"
                                                    ? this.getSelectedSlot(
                                                        val.date_time,
                                                        val.Actual_date
                                                      )
                                                    : ""
                                                }
                                              >
                                                {val.status === "Open" ? (
                                                  <CheckIcon />
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="delivery-box-tab-dec">
                  <h2>
                    There is no pickup or delivery right now. Please try again
                    later
                  </h2>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
        {this.state.openStoreMsg && (
          <StoreMessagePopup
            openStoreMsg={this.state.openStoreMsg}
            handleClosePopup={() => {
              this.setState({ openStoreMsg: false });
            }}
            handleCloseStorePopup={() => {
              this.setState({ openStoreMsg: false });
              this.getPickupSlot(window.screen.availWidth >= 900 ? 7 : 3, 0);
            }}
          />
        )}
      </>
    );
  }
}
