import React, { Component, createElement } from "react";
import { Link } from "react-router-dom";
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Snackbar,
  Grid,
  Divider,
  IconButton,
  Breadcrumbs,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AiOutlineFieldTime } from "react-icons/ai";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { reduxForm } from "redux-form";
import LOGO_IMAGE from "../../../assets/images/logo.png";
import NO_IMAGE from "../../../assets/images/image-coming-soon.jpg";
import { IoReorderThreeOutline } from "react-icons/io5";
import {
  GuestLoginAthentication,
  ProductsSearchApi,
  AddToCartFunctionlity,
  WishListFunctionlity,
  UpdateCartFunctionlity,
  GuestStoreAddress,
  SubCategeriesListView,
  UserListPage,
  HeaderMenuListApi,
} from "../../../actions/home/homeAction";
import {
  LoginApplication,
  RefreshLogin,
} from "../../../actions/login/loginAction";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DeleteCrtIemFunctionlity,
  RemoveCartFunctionlity,
} from "../../../actions/cart/cartAction";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  AddFavoriteLevelFunctionlity,
  favoriteLevelListFunctionlity,
  AddFavoriteProductFunctionlity,
} from "../../../actions/favorite/favoriteAction";
import {
  commonPageTitel,
  addDefaultImageSrc,
  getSchedule_zip,
  logout,
  convertTodate,
  formatPhoneNumber,
  sweetAlertBox,
  RefreshApiToken,
  slugifyStr,
  checkImageUrls,
  refineImgurl,
} from "../../../utils/renderUtils/renderUtil";
import DeliveryPopup from "../../delivery_popup/delivery_popup";
import ApiUrls from "../../../utils/apiUtils/apiUrl";
import Loginpopup from "../../login/Loginpopup";
import Address from "../../deliveryaddress/Address";
import Commondialog from "../Commondialog";
import PdfViewHeader from "./PdfViewHeader";
import CartlistItem from "../Cart/CartlistItem";
import ToggleLoginMenu from "../ToggleLoginMenu";
import CategoryGrower from "../Category/CategoryGrower";
import FilterButton from "./FilterButton";
import {
  TypographyFontColor,
  SearchButton,
  TypographyBorder,
  SecondaryButton,
  TypographySecondaryColor,
  TypographyHoverTextColor,
  TypographySecondaryBgColor,
  TypographyTextColor,
  TypographyPrimaryBgColorDimmed,
  LabelPrimaryColor,
  TextFormating,
  ScrollColorTypography,
  TypographySecondaryBgColorDimmed,
  TypographyTextFontFamily,
} from "../UiDesign";
import * as Icons from "react-icons/fa";
import SearchInputBox from "./SearchInputBox";
import BottomMobNav from "./Mobile/BottomMobNav";
import SearchPreviewMob from "./Mobile/SearchPreviewMob";
import RecentSearchTab from "./RecentSearchTab";
import MobileHeader from "./Mobile/MobileHeader";
import SideCategoryView from "../SideCategoryView";
import { userDashboardMenu } from "./utils";
import SearchableSelect from "./SearchableSelect";
import CantFinditem from "./CantFinditem";
import { TbZoomQuestion } from "react-icons/tb";
import Marquee from "react-fast-marquee";
import { FaUserCircle } from "react-icons/fa";
import {
  IoIosArrowForward,
  IoMdArrowDropdown,
  IoMdCloseCircle,
} from "react-icons/io";
import MenuCategoryList from "./MenuCategoryList";

import Department from "./Department";
import CommonSkeleton from "../Skeleton/CommonSkeleton";
import SideCateogorySkeleton from "../Skeleton/SideCateogorySkeleton";
import CommonCategoryBgImg from "../CommonCategoryBgImg";
const searchAPIDebounced = AwesomeDebouncePromise(ProductsSearchApi, 500);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      addclass: false,
      open: false,
      open_inner: false,
      open_subDetails: false,
      openSearch: false,
      openCategory: false,
      topFilms: [],
      CategoryListDetails: [],
      userName: "",
      email: "",
      popupVisible: false,
      anchorEllist: false,
      anchorEllistSalesRep: false,
      CartCount: [],
      searchProduct: [],
      productDetails: [],
      loading: false,
      categoryIds: [],
      categoryName: [],
      checkboxChecked: false,
      ProductCartArray: [],
      isLogin: false,
      productSearchLoaer: false,
      disabled: false,
      listOpen: false,
      SearchByCategory: "",
      error: false,
      chack_header_is_case: [],
      forListingBody: true,
      searchTextValue:
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.special_filter_value
          ? this.props.history.location.state.special_filter_value
          : "",
      favoriteLevelListData: [],
      activeClass: "",
      covid19activeClass: "",
      weekendactiveclass: "",
      newItemactiveclass: "",
      openDialog: false,
      product_id: "",
      slug: "",
      accesstoken: "",
      setOpenDeliveryPopup: false,
      slotName:
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.slotName
          ? this.props.history.location.state.slotName
          : localStorage.getItem("slot")
          ? JSON.parse(localStorage.getItem("slot")).data
          : "Choose A Time Slot",
      SlotData: localStorage.getItem("slot")
        ? JSON.parse(localStorage.getItem("slot"))
        : "",
      setOpenLoginPopup: false,
      setOpenAddresspopup: false,
      statusDialogmsg: "",
      setOpenPdfpoup: false,
      togglecartMenu: false,
      mobileCartitemShow: false,
      ToggleMenuType: "",
      max_price: "",
      // TreeViewIteamsHome: [],
      parentIds: [],
      // CategoryDetails: [],
      SideCartlistview: false,
      leftMenuStyledisplay: "none",
      selectedslotPopupData: "",
      searchResultMsg: "",
      searchview: "",
      added_qty_is_case: 0,
      fullcategory_view: false,
      categoryviewdata: "",
      subcategoryviewdata: "",
      openCategorysideslider: true,
      isSelectoptionOpen: false,
      selectedCategory: "By Category",
      humbergerBtn: false,
      pro_details_ind: -1,
      itemDetailsmobModal: false,
      is_department: false,
      department_type: 0,
      SubCategoryListDetails: [],
      department_breadcumbs: [],
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      isStoreClosed: false,
      openSearchCustomer: false,
      customerSearchTxt: "",
      customerSearchList: [],
      AddnewUser: false,
      unListedItem: {
        isOpen: false,
        productDetails: "",
        qty: 1,
      },
      isRemoveCutomer: false,
      customerAddressView: "",
      isOpenSubUserList: false,
      store_Data: JSON.parse(localStorage.getItem("Store_address")),
      top_menu: [],
      topMenuLoader: false,
      categoryBgimageError:false
    };

    this.anchorEllistCategorySelect = React.createRef();
    this.child = React.createRef();
    this.searchClose = this.searchClose.bind(this);
  }
  handleDepartmentContainer = (data = false) => {
    this.setState({
      is_department: data,
      department_type: 0,
      department_breadcumbs: [],
    });
  };
  handleBindAddress = (data) => {
    this.setState({ customerAddressView: data });
  };
  CloseLoginDialog = () => {
    if (localStorage.getItem("frontend_animation") === "must_login_add_to_cart")
      this.setState({ setOpenLoginPopup: false, setOpenDeliveryPopup: true });
    else this.setState({ setOpenLoginPopup: false });
  };

  componentWillReceiveProps(nextprops) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
    let categoryid = this.props.history.location.pathname.split("/")[2];
    if (
      this.props.pageName === "home_page" ||
      this.props.pageName === "details_page" ||
      (this.props.pageName === "listing_page" &&
        isNaN(categoryid) === true &&
        this.props.history.location.pathname
          .split("/")[2]
          ?.includes("SPECIALS") === false) ||
      this.props.history.location.state?.openallCategory === true
    ) {
      this.setState({ openCategorysideslider: true });
    } else if (this.props.humbergerBtn !== true) {
      this.setState({ openCategorysideslider: false });
    }
    if (nextprops.isslotDeliverypopupState_compwill) {
      this.props.closeisslotDeliverypopupState_compwill();
      this.setState({ setOpenDeliveryPopup: true });
    }
    let slots = JSON.parse(localStorage.getItem("slot"));
    if (slots?.data !== undefined && this.state.slotName !== slots?.data) {
      this.setState({ slotName: slots.data });
    }
  }
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  handleClickAway = () => {
    this.setState({ anchorEllist: false });
  };
  handleScrollHeader = () => {
    let header = document.getElementById("search_section");
    let bodyScroll = document.body.getBoundingClientRect().top;
    const searchHeader = document
      .getElementById("search_section")
      .getBoundingClientRect();
    if (searchHeader.top <= 0 && header.style.position !== "fixed") {
      header.style.position = "fixed";
      header.style.top = "0";
      header.style.zIndex = "99";
    } else if (bodyScroll <= 0 && bodyScroll > -100) {
      header.style.position = "relative";
    }
  };
  componentDidMount() {
    let scrollColor = this.state.web_customize?.secondary?.main;
    document.head.insertAdjacentHTML(
      "beforeend",
      "<style>" +
        "::-webkit-scrollbar { width: 9px; }" +
        "::-webkit-scrollbar-thumb { background-color: " +
        scrollColor +
        "}" +
        "</style>"
    );

    // Change scrollbar color for Firefox
    document.head.insertAdjacentHTML(
      "beforeend",
      "<style>" +
        "body { scrollbar-color: " +
        scrollColor +
        "}" +
        "body::-webkit-scrollbar { width: 9px; }" +
        "body::-webkit-scrollbar-thumb { background-color: " +
        scrollColor +
        "}" +
        "</style>"
    );

    if (window.screen.availWidth <= 900) {
      let header = document.getElementById("search_section");
      header.style.position = "relative";
      window.addEventListener("scroll", this.handleScrollHeader);
    }
    let categoryId =
      this.props.history.location.state?.category_id === undefined
        ? this.props.history.location.pathname.split("/")[2]
        : this.props.history.location.state?.category_id;
    let openCategorysideslider = false;

    if (
      (this.props.pageName === "listing_page" && isNaN(categoryId) === false) ||
      this.props.pageName === "payment" ||
      this.props.pageName === "cart_page" ||
      this.props.pageName === "order_success" ||
      this.props.pageName === "myAddressPage" ||
      this.props.pageName === "orders_page" ||
      this.props.pageName === "favoritePage" ||
      this.props.pageName === "settingPage" ||
      (this.props.pageName === "listing_page" &&
        this.props.history.location.pathname
          .split("/")[2]
          ?.includes("SPECIALS")) ||
      window.screen.availWidth <= 900
    )
      openCategorysideslider = false;
    else openCategorysideslider = true;
    this.setState({ openCategorysideslider });
    this.FetchAddress();
    // this.FetchCategoryTreeViewForHome();
    if (window.screen.availWidth <= 900) {
      // this.FetchCategoryTreeViewForHome();
    }
    let user_name;
    let email_id;
    let slotitem = localStorage.getItem("slot")
      ? JSON.parse(localStorage.getItem("slot"))
      : "";

    if (
      slotitem.schedule === "" ||
      slotitem.zipCode === "" ||
      slotitem.data === ""
    ) {
      this.setState({ slotName: "Choose A Time Slot" });
      localStorage.removeItem("slot");
    }

    const frontend =
      localStorage.getItem("frontend_animation") === "pickup_delivery";

    const accessToken = localStorage.getItem("access_token");

    const isLogin = localStorage.getItem("isLogin") === "true";
    if (accessToken !== "undefined") {
      this.setState({
        accesstoken: JSON.parse(localStorage.getItem("access_token")),
      });
    }

    let link = document.querySelector("link[rel~='icon']");
    if (accessToken) {
      const favicon =
        JSON.parse(accessToken)?.favicon_image === ""
          ? JSON.parse(accessToken)?.logo
          : JSON.parse(accessToken)?.favicon_image;
      link.href =
        favicon[0] === "/" ? ApiUrls.BASH_URL + "/media/" + favicon : favicon;
      this.FetchTopMenuDeatils();
    } else {
      this.HitGuestApiToken();
    }

    if (accessToken && isLogin) {
      user_name = JSON.parse(localStorage.getItem("name"))
        ? JSON.parse(localStorage.getItem("name")).name
        : "";
      email_id = JSON.parse(localStorage.getItem("email"))
        ? JSON.parse(localStorage.getItem("email")).email
        : "";
      let listOpen = localStorage.getItem("listOpen");
      if (listOpen && listOpen === "true") {
        this.setState({ listOpen: true });
      } else {
        this.setState({ listOpen: false });
      }

      this.setState({
        userName: user_name,
        email: email_id,
      });
    }
  }

  FetchProductListView = async (searchText, categoryIds) => {
    let formValues = {
      search: encodeURIComponent(searchText),
      limit: 10,
      category_id: categoryIds === "allCateogory" ? "" : categoryIds,
    };
    let zonedata = getSchedule_zip();
    formValues = { ...formValues, ...zonedata };

    this.setState({ productSearchLoaer: true });
    const res = await searchAPIDebounced(formValues);
    let productDetails = [];
    let searchmsg = "";
    if (res.data && res.data.result) {
      productDetails = res.data.result;

      if (productDetails.length === 0) searchmsg = "Try with other word";
      else {
        productDetails.map((val) => {
          val.is_case_check = false;
        });
      }
      // const imagePromises = productDetails.map((ele) => {
      //   let localUrl = ele?.cloudinary_images_local[0];
      //   let globalUrl = ele?.cloudinary_images_global[0];
      //   let currentUrl =
      //     ele.main_image !== null
      //       ? ele.main_image[0] === "/"
      //         ? ApiUrls.BASH_URL + "/media" + ele.main_image
      //         : ele.main_image
      //       : NO_IMAGE;

      //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
      //     console.log(localUrl, globalUrl, currentUrl, url);
      //     ele.main_image = url.url;
      //     ele.valid = url.valid;
      //     return ele; // return the updated product
      //   });
      // });
      // Promise.all(imagePromises).then((updatedProductList) => {
        this.setState({
          productDetails: productDetails,
          searchResultMsg: searchmsg,
        // });
      });
    } else if (res?.errors?._error?.status === 401) {
      const refreshToken = JSON.parse(localStorage.getItem("access_token"));
      let myThis = this;
      if (refreshToken) {
        this.RefreshApiToken(
          refreshToken.refresh_token,
          myThis,
          function (myThis) {
            myThis.FetchProductListView(
              formValues.search,
              formValues.category_id
            );
          }
        );
      }
    }

    this.setState({ productSearchLoaer: false });
  };
  searchText = (searchValue) => {
    let searchText = searchValue;
    console.log(searchText);
    if (searchText.trim() !== "") {
      this.FetchProductListView(searchText, this.state.categoryIds);
      this.setState({ searchTextValue: searchValue, searchview: "" });
    } else {
      this.setState({
        searchTextValue: searchValue,
        searchResultMsg: "",
        productDetails: [],
        searchview: "",
      });
    }
  };

  toggleMenu(type = "") {
    this.setState({ toggleMenu: !this.state.toggleMenu, ToggleMenuType: type });
  }

  loginOptions = (e) => {
    console.log("login func");
    let isLogin = JSON.parse(localStorage.getItem("isLogin"));
    this.setState({
      anchorEllist: !this.state.anchorEllist,
    });
    if (isLogin && isLogin === "true") {
      this.setState({ isLogin: true, listOpen: true });
    } else {
      this.setState({ isLogin: false, popUp: true, listOpen: false });
    }
  };
  allCategoryOptions = (e) => {
    this.setState({
      anchorEllistCategorySelect: e.currentTarget,
      isSelectoptionOpen: !this.state.isSelectoptionOpen,
    });
  };

  searchClose() {
    this.setState({
      searchview: "",
      openSearch: false,
      added_qty_is_case: -1,
    });
  }

  categoryOpen() {
    this.setState({
      openCategory: !this.state.openCategory,
      openSearch: false,
    });
  }

  closeCategory() {
    this.setState({
      openCategory: !this.state.openCategory,
      categoryIds: [],
      SearchByCategory: "",
      categoryName: [],
    });
  }

  HitGuestApiToken = () => {
    let formValues = {};
    GuestLoginAthentication(formValues).then((res) => {
      localStorage.setItem("access_token", JSON.stringify(res.data));
      this.props.FetchCartCount();
      this.FetchTopMenuDeatils();
    });
  };
  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("slot");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    if (
      localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) === null
    )
      localStorage.removeItem("isWebsiteVisited");

    this.setState({ isLogin: false, logo: "", anchorEllist: false });
    this.setState({ listOpen: false });
    this.HitGuestApiToken();
    this.props.dispatch(push("/"));
    window.location.reload();
  };
  handleDropDownCloselist = () => {
    this.setState({
      anchorEllist: false,
    });
  };

  redirecttoUrl = () => {
    this.props.pageName.push({
      pathname: "/card",
    });
  };

  FetchAddress = () => {
    let formValue = {};
    GuestStoreAddress(formValue, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        this.setState({
          addressDetails: res.data.additional_address,
          isStoreClosed: res.data.result[0]?.is_store_close,
        });
        localStorage.setItem(
          "frontend_animation",
          res.data.result[0].frontend_animation
        );
        localStorage.setItem(
          "Store_address",
          JSON.stringify(res.data.result[0])
        );
        if (res.data.result[0]?.allow_guest_user === false)
          localStorage.removeItem("Guest_userid");
        localStorage.setItem(
          "allow_guest_user",
          res.data.result[0]?.allow_guest_user
        );
        localStorage.setItem(
          "shoppingmethod",
          res.data.result[0].shopping_method
        );
        localStorage.setItem(
          "shipping_price",
          res.data.result[0].shipping_price
        );
        localStorage.removeItem("pdf_data_view");
        localStorage.setItem(
          "pdf_data_view",
          JSON.stringify({
            link: res.data.result[0].pdf_frontend,
            name: res.data.result[0].name_pdf_frontend,
          })
        );
        localStorage.setItem(
          "product_no_image",
          res.data.result[0].product_no_image === null
            ? ""
            : res.data.result[0].product_no_image
        );
        localStorage.setItem(
          "PaymentOptionVisibility",
          res.data.result[0].payment_options
        );

        if (
          res.data.result[0].frontend_animation === "pickup_delivery" &&
          (localStorage.getItem("isLogin") === "false" ||
            localStorage.getItem("isLogin") === null) &&
          localStorage.slot === undefined &&
          this.props.history.location.pathname !== "/order-success"
        ) {
          this.openDeliveryPickup();
        }
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddress();
            }
          );
        }
      }
    });
  };
  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));
        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
        // this.FetchProductListView(this.state.searchText, this.state.categoryIds);
      } else {
        logout();
      }
    });
  };

  handleCheckbox = (categoryIds, categoryName) => {
    let CategoryListDetails = this.state.CategoryListDetails;
    let checkBrandID = CategoryListDetails.findIndex(
      (p) => p.id === categoryIds
    );
    CategoryListDetails[checkBrandID]["checkboxChecked"] =
      !CategoryListDetails[checkBrandID]["checkboxChecked"];
    this.setState({ CategoryListDetails: CategoryListDetails });
    const selectedIndex = this.state.categoryIds.indexOf(categoryIds);
    const selectedCatName = this.state.categoryName.indexOf(categoryName);
    let newSelected = [];
    let newSelectedName = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.categoryIds, categoryIds);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.categoryIds.slice(1));
    } else if (selectedIndex === this.state.categoryIds.length - 1) {
      newSelected = newSelected.concat(this.state.categoryIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.categoryIds.slice(0, selectedIndex)
      );
    }
    if (selectedCatName === -1) {
      newSelectedName = newSelectedName.concat(
        this.state.categoryName,
        " " + categoryName
      );
    } else if (selectedCatName === 0) {
      newSelectedName = newSelectedName.concat(
        this.state.categoryName.slice(1)
      );
    } else if (selectedCatName === this.state.categoryName.length - 1) {
      newSelectedName = newSelectedName.concat(
        this.state.categoryName.slice(0, -1)
      );
    } else if (selectedCatName > 0) {
      newSelectedName = newSelectedName.concat(
        this.state.categoryName.slice(0, selectedCatName)
      );
    }
    this.setState({ categoryIds: newSelected, categoryName: newSelectedName });
  };

  storeCategoryId = (value, name) => {
    let searchText = this.state.searchTextValue;
    let categoryIds = value;
    this.FetchProductListView(searchText, categoryIds);
    document.getElementById("search_input").focus();
    this.setState({
      openCategory: !this.state.openCategory,
      categoryIds: categoryIds,
      selectedCategory: name,
      anchorEllistCategorySelect: null,
      openSearch: true,
      isSelectoptionOpen: false,
    });
  };
  openAddCount = (product_id, added_quantity, is_case, addTocartQty = 1) => {
    added_quantity = parseInt(added_quantity);
    addTocartQty = parseInt(addTocartQty);
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.setState({ setOpenLoginPopup: true });
      localStorage.setItem(
        "tempProductData",
        JSON.stringify({
          product_id,
          added_quantity,
          is_case,
          categoryindex: "",
          addTocartQty,
          container_type: "",
        })
      );
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({ setOpenDeliveryPopup: true });
    } else {
      if (added_quantity > 0 && addTocartQty === 0) {
        this.AddToCartFunctionlity(product_id, added_quantity, is_case);
      } else if (added_quantity > 0 && addTocartQty >= 1) {
        this.UpdateCartItemFunctionlity(product_id, added_quantity, is_case);
      } else {
        this.deleteCartItem(product_id, added_quantity, is_case);
      }
    }
  };

  AddToCartFunctionlity = (product_id, added_quantity, is_case, type = "") => {
    let formValue = {};
    if (type === "")
      formValue = {
        product_id: product_id,
        quantity: 1,
        is_case: is_case,
      };
    else
      formValue = {
        quantity: this.state.unListedItem.qty,
        is_case: false,
        unlist_product_name: this.state.unListedItem.productDetails,
        is_unlist_item: true,
      };
    let productDetails = this.state.productDetails;
    let added_qty_is_case = this.state.added_qty_is_case;
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddToCartFunctionlity(
                formValue.product_id,
                formValue.product_id
              );
            }
          );
        }
      } else {
        if (this.props.pageName === "cart_page") {
          this.props.FetchCartOrderSummaryList();
          this.props.FetchCartItemList();
        } else if (this.props.pageName === "details_page") {
          this.props.FetchCartItemList();
        } else if (this.props.pageName === "home_page") {
          this.props.FetchTodaysDealsProductList();
          this.props.FetchPopularProductList();
          this.props.FetchBestSellingProductList();
          this.props.FetchCartCount();
        } else if (this.props.pageName === "listing_page") {
          this.props.FetchCartItemList();
        } else if (this.props.pageName === "orders_page") {
          this.props.FetchCartCount();
        } else if (this.props.pageName === "order_details") {
          this.props.FetchCartCount();
        }

        var Proind = productDetails.findIndex((p) => p.id === product_id);
        if (Proind >= 0 && added_quantity >= 0) {
          var added_qtyInd =
            productDetails[Proind]["added_quantity_is_case"].indexOf(is_case);
          added_qty_is_case = added_qtyInd;
          if (added_qtyInd > -1) {
            productDetails[Proind]["added_quantity"][added_qtyInd] =
              added_quantity;
          } else {
            productDetails[Proind]["added_quantity"].push(added_quantity);
            productDetails[Proind]["added_quantity_is_case"].push(is_case);
          }
        }
        if (type !== "") {
          this.setState({
            unListedItem: {
              isOpen: false,
              productDetails: "",
              qty: 1,
            },
          });
        }
        this.setState({ productDetails, added_qty_is_case });
      }
    });
  };

  UpdateCartItemFunctionlity = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
    };
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.UpdateCartItemFunctionlity(
                formValue.product_id,
                formValue.quantity,
                formValue.product_id
              );
            }
          );
        }
      } else {
        this.commonUpdateAndDeleteAfterRes(product_id, added_quantity, is_case);
      }
      this.setState({ disabled: false });
    });
  };

  HandelChangeTrueWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    let activePathname = this.props.history.location.pathname
      ? this.props.history.location.pathname
      : "";
    this.setState({ isLogin: login_status });
    let productDetails = this.state.productDetails;
    for (let i = 0; i < productDetails.length; i++) {
      if (productDetails[i].id === product_id) {
        productDetails[i].wishlist = value;
      }
    }

    this.setState({ productDetails });
    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      this.props.history.push({
        pathname: "/login/?next=" + activePathname,
      });
    }
  };

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddWishlistFunctionility(
                formValues.product_id,
                formValues.wishlist
              );
            }
          );
        }
      }
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case, index) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: "",
    };
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.commonUpdateAndDeleteAfterRes(product_id, added_quantity, is_case);
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.deleteCartItem(formValue.product_id, formValue.is_case);
            }
          );
        }
      }
    });
  };

  commonUpdateAndDeleteAfterRes = (product_id, added_quantity, is_case) => {
    let productDetails = this.state.productDetails;
    let added_qty_is_case = this.state.added_qty_is_case;
    if (this.props.pageName === "details_page") {
      this.props.FetchCartItemList();
    } else if (this.props.pageName === "cart_page") {
      this.props.FetchCartOrderSummaryList();
      this.props.FetchCartItemList();
    } else if (this.props.pageName === "home_page") {
      this.props.FetchTodaysDealsProductList();
      this.props.FetchPopularProductList();
      this.props.FetchBestSellingProductList();
      this.props.FetchCartCount();
    } else if (this.props.pageName === "listing_page") {
      this.props.FetchCartItemList();
    } else if (this.props.pageName === "orders_page") {
      this.props.FetchCartCount();
    } else if (this.props.pageName === "order_details") {
      this.props.FetchCartCount();
    }

    var Proind = productDetails.findIndex((p) => p.id === product_id);

    let add_qty = added_quantity;
    if (added_quantity <= 0) {
      add_qty = 0;
    }
    if (Proind >= 0 && added_quantity >= 0) {
      var added_qtyInd =
        productDetails[Proind]["added_quantity_is_case"].indexOf(is_case);
      added_qty_is_case = added_qtyInd;
      if (added_qtyInd > -1) {
        productDetails[Proind]["added_quantity"][added_qtyInd] = add_qty;
        productDetails[Proind]["disabled"] = false;
      } else {
        productDetails[Proind]["added_quantity"].push(add_qty);
        productDetails[Proind]["added_quantity_is_case"].push(is_case);
      }
    }

    // this.setState({ productDetails, searchTextValue: "" });
    this.setState({ productDetails, added_qty_is_case });
  };

  redirectToLogin = (AddnewUser = false) => {
    if (AddnewUser) this.setState({ AddnewUser: true });
    this.setState({ anchorEllist: false });
    if (localStorage.getItem("Guest_userid") === null)
      this.props.OpenLoginPoup();
    else this.setState({ setOpenLoginPopup: true });
  };

  redirectToProfile = () => {
    this.props.history.push({
      pathname: "/address",
    });
  };

  reDirectToDetailsPage = (
    product_id,
    product_name,
    slug,
    brand_id,
    category_id,
    addTocart_btn
  ) => {
    let product_name_lowercase = product_name.toLowerCase();
    product_name = product_name_lowercase.replace(/\s/g, "-");
    if (this.state.searchTextValue !== "")
      this.handleRecentSearch(this.state.searchTextValue);
    if (
      this.props.pageName === "details_page" ||
      this.props.pageName === "Home_page"
    ) {
      this.props.history.push({
        pathname: "/product/" + slug,
        state: {
          addTocart_btn: addTocart_btn,
          category_id: category_id,
          slotName: this.state.slotName,
        },
      });
      this.props.FetchProdutDetails(product_id, slug);
      this.setState({
        openSearch: false,
        searchTextValue: "",
        product_id: product_id,
        slug: slug,
      });
    } else {
      this.props.history.push({
        pathname: "/product/" + slug,
        state: {
          addTocart_btn: addTocart_btn,
          category_id: category_id,
          slotName: this.state.slotName,
        },
      });
    }
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  ProductCaseFunctionlity = (product_id, is_case, data) => {
    //  if(data.added_quantity?.every(ele=>ele===0))
    // {
    //   data.added_quantity=[]
    //   data.added_quantity_is_case=[]
    // }

    var check_is_case = this.state.chack_header_is_case[data.id]
      ? this.state.chack_header_is_case[data.id]
      : false;
    var check_added_qty_is_case = data.added_quantity_is_case.indexOf(is_case);
    let chack_header_is_case = this.state.chack_header_is_case;
    chack_header_is_case[product_id] = is_case;
    // data.added_quantity_is_case = [is_case];

    data.is_case_check = is_case;
    if (check_added_qty_is_case === -1) {
      data.added_quantity.push(0);
      data.added_quantity_is_case.push(is_case);
      check_added_qty_is_case = data.added_quantity_is_case.indexOf(is_case);
    }

    this.setState({
      chack_header_is_case: chack_header_is_case,
      added_qty_is_case: check_added_qty_is_case,
      searchview: data,
      is_case_check: is_case,
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else if (msg && msg.error) {
        sweetAlertBox("Attention!!", msg.error[0], "info");
        // this.setState({
        //   error: msg.error[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
    });
  };

  showCart = () => {
    if (
      this.props.pageName === "listing_page" ||
      this.props.pageName === "favoritePage" ||
      this.props.pageName === "shoppingPage"
    ) {
      this.setState({ forListingBody: !this.state.forListingBody });
      this.props.SetBodyClass(this.state.forListingBody);
    }
    if (
      this.props.pageName === "cart_page" ||
      this.props.pageName === "order_success"
    ) {
      this.setState({
        mobileCartitemShow: !this.state.mobileCartitemShow,
      });
      document.getElementsByClassName("hidden")[0].classList.toggle("hide");
    } else if (
      this.props.pageName === "delivery-address" ||
      this.props.pageName === "payment"
    ) {
      this.props.history.push({
        pathname: "/cart",
      });
    } else {
      if (this.state.mobileCartitemShow)
        this.setState({
          mobileCartitemShow: !this.state.mobileCartitemShow,
          leftMenuStyledisplay: "none",
        });
      else {
        this.setState({
          mobileCartitemShow: !this.state.mobileCartitemShow,
          leftMenuStyledisplay: "block",
        });
      }
      document.getElementsByClassName("hidden")[0].classList.toggle("show");
    }
  };

  rediectToSamePageForspacial = (value, pageName) => {
    this.setState({ searchTextValue: value });
    window.scroll(0, 0);
    this.props.history.push({
      pathname: "/listing/campaign=" + value,
      state: {
        selectedCategory: false,
      },
    });
    if (value === "SPECIALS") window.location.reload();
    this.props.callSpacialFunction("", "", value, "", 1);
  };

  activeClass = (value) => {
    let activeClass = "";
    let covid19activeClass = "";
    let weekendactiveclass = "";
    let newItemactiveclass = "";
    if (value === "SPECIALS") {
      activeClass = "active";
      covid19activeClass = "";
      weekendactiveclass = "";
      newItemactiveclass = "";
    } else {
      activeClass = "";
    }
    if (value === "WEEKLY") {
      activeClass = "";
      covid19activeClass = "";
      weekendactiveclass = "active";
      newItemactiveclass = "";
    } else {
      weekendactiveclass = "";
    }

    if (value === "COVID19") {
      activeClass = "";
      covid19activeClass = "active";
      weekendactiveclass = "";
      newItemactiveclass = "";
    } else {
      covid19activeClass = "";
    }

    if (value === "NEW ITEM") {
      activeClass = "";
      covid19activeClass = "";
      weekendactiveclass = "";
      newItemactiveclass = "active";
    } else {
      newItemactiveclass = "";
    }

    this.setState({
      activeClass: activeClass,
      weekendactiveclass: weekendactiveclass,
      covid19activeClass: covid19activeClass,
      newItemactiveclass: newItemactiveclass,
      is_department: false,
      department_type: 0,
      department_breadcumbs: [],
    });
  };
  InactiveClass = (value) => {
    let activeClass = "";
    if (value === "SPECIALS") {
      activeClass = "";
    }
    this.setState({ activeClass: activeClass });
  };

  redirectToDetailsPageAfterClick = (
    product_name,
    slug,
    product_id,
    addTocart_btn
  ) => {
    this.props.history.push({
      pathname: "/product/" + slug,
      state: {
        addTocart_btn: addTocart_btn,
        slotName: this.state.slotName,
      },
    });
  };

  addFavoiteLevelFunctionlity = (favoriteLevel, product_id) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res && res.message) {
          this.FetchFavoriteLevelList(product_id);
        }
      }
    );
  };

  FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 10,
      product_id: product_id ? product_id : "",
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelListData = [];
        if (res.data && res.data.result) {
          favoriteLevelListData = res.data.result;
        }
        this.setState({ favoriteLevelListData: favoriteLevelListData });
      }
    );
  };

  addFavoiteProductFunctionlity = (
    favoritelabel_id,
    product_id,
    quantity,
    added_quantity_is_case
  ) => {
    let formValues = {
      product_id: product_id,
      favoritelabel_id: favoritelabel_id,
      quantity: quantity,
      is_case: added_quantity_is_case,
      note: "",
    };
    let productDetails = this.state.productDetails;
    AddFavoriteProductFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          for (let i = 0; i < productDetails.length; i++) {
            if (productDetails[i].id === product_id) {
              productDetails[i].wishlist = true;
            }
          }
        }
        this.setState({ productDetails: productDetails, searchTextValue: "" });
      }
    );
  };
  handleRecentSearch = (searchTextValue) => {
    let storeName = JSON.parse(
      localStorage.getItem("access_token")
    )?.sname?.replace(/\s/g, "");
    let recentData = localStorage.getItem(`recent${storeName}`);
    if (recentData === null) {
      localStorage.setItem(`recent${storeName}`, searchTextValue);
    } else {
      let arryData = recentData.split("@%");
      let status = false;
      for (let i = 0; i < arryData.length; i++) {
        if (searchTextValue.toLowerCase() === arryData[i].toLowerCase()) {
          status = true;
          break;
        }
      }
      if (status === false) {
        let arryData = recentData.split("@%").splice(0, 9);
        arryData = arryData.join("@%");
        arryData = searchTextValue + "@%" + arryData;
        localStorage.setItem(`recent${storeName}`, arryData);
      }
    }
  };
  searchProductByName = (SearchText = "") => {
    let searchTextValue =
      SearchText === "" ? this.state.searchTextValue : SearchText;
    let productDetails = this.state.productDetails;
    let categoryIds =
      this.state.categoryIds === "allCateogory" ? "" : this.state.categoryIds;

    if (searchTextValue.trim() !== "" && productDetails) {
      this.handleRecentSearch(searchTextValue);
      if (this.props.pageName === "listing_page") {
        this.props.history.push({
          pathname: "/listing/q=" + searchTextValue,
        });
        window.scrollTo(0, 0);
        this.props.callSpacialFunction(searchTextValue, categoryIds, "", "", 1);
        this.setState({ openSearch: false });
      } else {
        this.props.history.push({
          pathname: "/listing/q=" + searchTextValue,
          state: {
            category_id:
              this.state.categoryIds === "allCateogory"
                ? ""
                : this.state.categoryIds,
            slotName: this.state.slotName,
          },
        });
      }
    }
  };

  enterPressed = (event) => {
    let searchTextValue = this.state.searchTextValue;
    let productDetails = this.state.productDetails;
    let categoryIds = this.state.categoryIds;
    var code = event.keyCode || event.which;
    if (
      code === 13 &&
      searchTextValue.trim() !== "" &&
      productDetails &&
      productDetails.length > 0
    ) {
      this.handleRecentSearch(searchTextValue);
      if (this.props.pageName === "listing_page") {
        this.props.history.push({
          pathname: "/listing/q=" + searchTextValue,
        });
        window.scrollTo(0, 0);
        this.props.callSpacialFunction(searchTextValue, categoryIds, "", "", 1);
        this.setState({ openSearch: false });
      } else {
        this.props.history.push({
          pathname: "/listing/q=" + searchTextValue,
          state: {
            category_id: this.state.categoryIds,
            slotName: this.state.slotName,
          },
        });
      }
    }
  };

  showAllFilter = () => {
    let searchTextValue = this.state.searchTextValue;
    let productDetails = this.state.productDetails;
    let categoryIds = this.state.categoryIds;
    if (productDetails && productDetails.length > 0) {
      let urlPayload = "";
      if (searchTextValue === "") urlPayload = "/listing/" + categoryIds;
      else urlPayload = "/listing/q=" + searchTextValue;
      if (this.props.pageName === "listing_page") {
        this.props.history.push({
          pathname: urlPayload,
        });
        this.props.callSpacialFunction(searchTextValue, categoryIds, "", "", 1);
        this.setState({ openSearch: false });
      } else {
        this.props.history.push({
          pathname: urlPayload,
          state: {
            category_id: this.state.categoryIds,

            slotName: this.state.slotName,
          },
        });
      }
    }
  };

  handleOpnDialog = (e) => {
    this.setState({
      openDialog: true,
      statusDialogmsg: "Are you sure want to remove all your added items?",
    });
  };

  handleCloseOpnDialog = () => {
    if (
      localStorage.getItem(
        "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
      ) !== null &&
      this.state.isRemoveCutomer === true
    ) {
      this.logoutCustomerBySr();
    }
    this.setState({ openDialog: false, isRemoveCutomer: false });
  };

  removeCartFunctionlity = (e) => {
    let formValue = {};
    let searchTextValue = this.state.searchTextValue;
    let categoryIds = this.props.category_id;

    RemoveCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res) {
        if (this.props.pageName === "details_page") {
          // e.preventDefault();
          this.props.FetchCartItemList();
          this.props.FetchProdutDetails(
            this.state.product_id,
            this.props.ProductName
          );
        } else if (this.props.pageName === "home_page") {
          this.props.FetchTodaysDealsProductList();
          this.props.FetchPopularProductList();
          this.props.FetchBestSellingProductList();
          this.props.FetchCartCount();
        } else if (this.props.pageName === "listing_page") {
          let categoryid = this.props.history.location?.pathname.split("/")[2];
          this.props.FetchCartItemList();
          window.scrollTo(0, 0);
          console.log(categoryIds, categoryid);
          if (categoryid?.includes("SPECIALS")) {
            window.location.reload();
            // this.props.callProduct_EmptyCart_Function(
            //   "",
            //   categoryIds,

            //   categoryid?.split("=")[1] === undefined
            //     ? ""
            //     : categoryid?.split("=")[1],
            //     "",
            //   1
            // );
          } else if (
            (isNaN(categoryIds) === false && isNaN(categoryid) === false) ||
            categoryid?.includes("brand=")
          ) {
            this.props.callProduct_EmptyCart_Function(
              searchTextValue,
              categoryid,
              "",
              "",
              1
            );
          } else if (categoryid?.includes("q=")) {
            this.props.callProduct_EmptyCart_Function(
              categoryid?.split("=")[1] === undefined
                ? ""
                : categoryid?.split("=")[1],
              categoryIds,
              "",
              "",
              1
            );
          } else if (categoryid.includes("campaign=")) {
            this.props.callProduct_EmptyCart_Function(
              "",
              "",
              categoryid.split("=")[1],
              "",
              1
            );
          } else {
            console.log("else");
            this.props.callProduct_EmptyCart_Function(
              "",
              categoryIds,
              "",
              categoryid?.split("=")[1] === undefined
                ? ""
                : categoryid?.split("=")[1],
              1
            );
          }
        } else if (
          this.props.pageName === "orders_page" ||
          this.props.pageName === "order_details" ||
          this.props.pageName === "shoppingPage" ||
          this.props.pageName === "favoritePage" ||
          this.props.pageName === "myAddressPage" ||
          this.props.pageName === "settingPage"
        ) {
          this.props.FetchCartCount();
        }
        if (this.state.isRemoveCutomer) {
          this.setState({ isRemoveCutomer: false });
          this.logoutCustomerBySr();
        }
        this.setState({
          openDialog: false,
        });
      }
    });
  };

  redirectTodiffPage = (url) => {
    let path = url;
    this.props.history.push({
      pathname: path,
    });
  };

  reDirectToListingWithCatgory = (category_id, parent_id, has_child, data) => {
    this.props.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: "",
        slotName: this.state.slotName,
        customCategoryMsg: data?.category_message,
      },
    });
    if (this.props.pageName !== "details_page") {
      this.props.FetchDataForPriceRange(
        "",
        "",
        category_id,
        parent_id,
        has_child,
        false
      );
      this.props.bindCategorygroupdata(this.props.tree_data);
      this.setState({
        openSearch: false,
      });
    }
  };
  openDeliveryPickup = () => {
    // this.FetchAddress();
    this.setState({ setOpenDeliveryPopup: true });
  };
  CloseDialog = (type, selectedslot) => {
    if (selectedslot === "shipping") this.setState({ selectedAddress: "" });
    if (type === "close") {
      this.setState({ setOpenDeliveryPopup: false });
    } else {
      this.openAddressPopup(selectedslot);
    }
  };
  openAddressPopup = (selectedslot) => {
    this.setState({
      setOpenAddresspopup: true,
      selectedslotPopupData: selectedslot,
    });
  };
  setSlotNamedata = (data, zipCode, scheduleType, dataTime, actualdate) => {
    localStorage.setItem(
      "slot",
      JSON.stringify({
        data: data,
        zipCode: zipCode === null ? "0" : zipCode.toString(),
        schedule: scheduleType,
        datetime: dataTime,
        actualdate: actualdate,
      })
    );
    if (scheduleType !== 3)
      localStorage.setItem(
        "SelectedAddress",
        JSON.stringify(this.state.selectedAddress)
      );
    this.setState({ slotName: data });
    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") !== "no_delivery_pickup"
    ) {
      if (localStorage.getItem("frontend_animation") !== "open") {
        if (
          this.props.history &&
          this.props.history.location.pathname === "/orders"
        ) {
          this.props.reoderFunctionlity(this.props.orderId);
        } else {
          this.setState({ setOpenDeliveryPopup: false });
          if (this.props.history.location.pathname === "/") {
            console.log("--------------");
            this.props?.FetchCallAllAPi();
            // window.location.reload();
          } else if (
            this.props.history.location.pathname === "/cart" ||
            this.props.history.location.pathname === "/payment"
          ) {
            window.location.reload();
          } else this.props.history.push({ pathname: "/" });
        }
      } else {
        this.setState({ setOpenDeliveryPopup: false });
        if (
          (this.props.history &&
            this.props.history.location.pathname === "/cart") ||
          this.props.history.location.pathname === "/payment"
        ) {
          // this.props?.FetchCallAllAPi()
          window.location.reload();
        }
      }
    } else {
      this.setState({ setOpenDeliveryPopup: false });
      this.props.openAddressPopup();
    }
  };
  CloseLoginPopupDialog = (type) => {
    this.setState({ setOpenLoginPopup: false });

    this.props.history.push({ pathname: "/" });

    if (
      localStorage.getItem("frontend_animation") &&
      localStorage.getItem("frontend_animation") === "must_login_add_to_cart" &&
      type !== "close"
    ) {
      let tempData = JSON.parse(localStorage.getItem("tempProductData"));

      if (tempData !== null) {
        this.AddToCartFunctionlity(
          tempData.product_id,
          tempData.added_quantity,
          tempData.is_case
        );
      }
      localStorage.removeItem("tempProductData");
    } else {
      localStorage.removeItem("tempProductData");
    }
  };
  handleCheckout = () => {
    if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") !== "open" &&
        localStorage.getItem("frontend_animation") !== "no_delivery_pickup")
    ) {
      this.setState({ setOpenDeliveryPopup: true });
    } else this.props.history.push({ pathname: "/cart" });
  };
  CloseAddressDialog = () => {
    this.setState({ setOpenAddresspopup: false });
  };
  setAddress = (data) => {
    let selectedaddress = this.state.selectedAddress;
    let setOpentslotpopup = this.state.setOpenslotpupup;
    let setOpenAddresspopup = this.state.setOpenAddresspopup;
    let setOpenDeliveryPopup = this.state.setOpenDeliveryPopup;
    const frontend_animation = localStorage.getItem("frontend_animation");
    let scheduleSlot = this.state.selectedslotPopupData;
    selectedaddress = data;

    if (scheduleSlot === "pickup" || scheduleSlot === "delivery") {
      setOpenAddresspopup = false;
      if (
        data?.pickup_type !== undefined &&
        (data?.pickup_type === "no_date_time" ||
          data?.pickup_type === "one_date_time")
      ) {
        this.setSlotNamedata(
          data?.pickup_type === "no_date_time"
            ? "Store Pick Up "
            : "Store Pick Up " +
                convertTodate(data.pickup_date) +
                " " +
                data.pickup_time,
          data.zipcode,
          2,
          "",
          ""
        );
        localStorage.setItem("SelectedAddress", JSON.stringify(data));

        setOpentslotpopup = false;
        setOpenDeliveryPopup = false;
      } else {
        setOpentslotpopup = true;
        setOpenDeliveryPopup = true;
      }
    } else if (scheduleSlot === "shipping") {
      this.setSlotNamedata("Shipping ", data.zipcode, 3, "", "");

      // if (this.props.history.location.pathname === "/cart")
      localStorage.setItem("SelectedAddress", JSON.stringify(data));
    }
    this.setState({
      selectedAddress: selectedaddress,
      setOpenslotpupup: setOpentslotpopup,
      setOpenAddresspopup: setOpenAddresspopup,
      setOpenDeliveryPopup: setOpenDeliveryPopup,
    });
  };
  closeSlotpupupStatus = () => {
    this.setState({ setOpenslotpupup: false });
  };
  CloseDialogPdfPopup = () => {
    this.setState({ setOpenPdfpoup: false });
  };
  OpenDialogPdfPopup = () => {
    this.setState({ setOpenPdfpoup: true });
  };
  bindCategorygroupdata = (data, categoryid) => {
    let categorydata = structuredClone(data);
    if (categorydata !== undefined) {
      if (categorydata.has_child === false) {
        return { category: [categorydata], parent: categorydata };
      } else if (categorydata.id === +categoryid) {
        if (categorydata.has_child === true)
          return { category: categorydata.childItem, parent: categorydata };
        else return { category: [categorydata], parent: categorydata };
      } else {
        let newdata = categorydata.childItem;
        let parentdata;
        parentdata = categorydata;
        // newdata.push(parentdata);
        let urlcategoryid = categoryid;
        for (let i = 0; i < newdata.length; i++) {
          if (newdata[i].id === +urlcategoryid) {
            let data = newdata[i];
            newdata.splice(i, 1);
            newdata.splice(0, 0, data);
            break;
          }
        }
        return { category: newdata, parent: parentdata };
      }
    }
  };
  FetchProduct = (category_id, data, category_message) => {
    document.body.style.overflow = "hidden";
    let selectedgroupcat = this.bindCategorygroupdata(data, category_id);

    this.setState({ fullcategory_view: false, openCategorysideslider: false });
    this.props.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: "",
        parentIds: "",
        parent_level: "",
        categoryviewdata: data,
        selectedgroupcat: selectedgroupcat,
        customCategoryMsg: category_message,
      },
    });

    if (
      this.props.pageName === "details_page" ||
      this.props.pageName === "home_page"
    ) {
    } else {
      this.props.FetchDataForPriceRange("", "", category_id, "", false, false);
      this.props.bindCategorygroupdata();
    }
  };
  FetchDataForPriceRangeHome = (
    price_min,
    price_max,
    category_id,
    has_child
  ) => {
    let brandIds = [];
    let filter_Kosher = [];
    let filter_Flavor = [];
    if (price_max > 0) {
      brandIds = this.state.brandIds;
      filter_Kosher = this.state.filterByKosher;
      filter_Flavor = this.state.filterByFlavor;
    }
    this.setState({
      min_price: price_min,
      max_price: price_max,
      category_id: category_id,
      ProductList: [],
      brandIds: brandIds,
      brandName: [],
      filterByFlavor: filter_Flavor,
      flavor: [],
      kosher: [],
      filterByKosher: filter_Kosher,
      page: 1,
    });
  };

  handlepreviewproduct = (data) => {
    var check_is_case = this.state.chack_header_is_case[data.id]
      ? this.state.chack_header_is_case[data.id]
      : false;
    var check_added_qty_is_case =
      data.added_quantity_is_case.indexOf(check_is_case);
    if (data.added_quantity?.every((ele) => ele === 0) && data.is_case) {
      data.added_quantity = [];
      data.added_quantity_is_case = [];
      check_added_qty_is_case = -1;
    }

    this.setState({
      searchview: data,
      added_qty_is_case: check_added_qty_is_case,
    });
  };

  openViewfullcategory = (value, data) => {
    this.setState({
      fullcategory_view: value,
      categoryviewdata: data,
      subcategoryviewdata: "",
      categoryBgimageError:false
    });
  };
  closeViewfullcategory = () => {
    this.setState({ openCategorysideslider: false });
  };
  // openViewfullcategory=()=>{
  // console.log('hitt');
  //   this.setState({ openCategorysideslider: true })
  // }
  handleresetAddress = () => {
    this.setState({ selectedAddress: "" });
  };
  handleClosefulldialoague = (ishumbergerBtn = this.props.humbergerBtn) => {
    this.child.current?.handleClearActiveclass();
    this.openViewfullcategory(false);
    let categoryid = this.props.history.location.pathname.split("/")[2];
    let historyState = this.props.history.location?.state;
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      state: { ...historyState, openallCategory: false },
    });

    if (
      this.props.pageName === "listing_page" &&
      isNaN(categoryid) === false &&
      ishumbergerBtn !== true
    )
      this.setState({ openCategorysideslider: false });
  };
  handleHumburgerbtn = (isActive) => {
    // let data = this.props.history.location.state;
    // if (data === undefined) data = { openallCategory: true };
    // else data["openallCategory"] = true;
    // this.props.history.push({
    //   state: data,
    // });
    // this.setState({ humbergerBtn: isActive });
    this.props.handleHumburgerbtn(isActive);
    if (isActive === false) this.handleClosefulldialoague(isActive);
  };
  handleBlurSearchbox = (e) => {
    // let SearchByCategory = this.state.SearchByCategory;
    // e.target.placeholder = SearchByCategory
    //   ? "Search" + SearchByCategory + " "
    //   : "Search " + commonPageTitel(this.state.accesstoken);
  };

  handleFocusSearchbox = (e) => {
    e.target.placeholder = "";
    if (this.state.searchTextValue.trim() !== "") {
      this.FetchProductListView(
        this.state.searchTextValue,
        this.state.categoryIds
      );
    } else {
      this.setState({ productDetails: [] });
    }
    this.setState({ openSearch: true });
  };

  FetchSubAndSubsubCategoryList = (data) => {
    if (data.has_child === false) {
      this.handleDepartmentContainer(false);
      this.reDirectToListingWithCatgory(data.id, data.parent_id, false, data);
    } else {
      let formValues = {
        ids: data.id,
        limit: 100,
      };
      this.setState({ categoryLoader: true });
      SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
        let subCategoryDetails = [];
        let breadcumbs = this.state.department_breadcumbs;
        if (res.data && res.data.result) {
          subCategoryDetails = res.data.result;
          breadcumbs.push(data);
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchSubAndSubsubCategoryList(data);
              }
            );
          }
        }
        console.log(subCategoryDetails);

        this.setState({
          SubCategoryListDetails: subCategoryDetails,
          categoryLoader: false,
          department_type: 1,
          department_breadcumbs: breadcumbs,
        });
      });
    }
  };
  handleBreadcumbs = (type, data) => {
    this.setState({ department_breadcumbs: [] });
    if (type === 0) {
      this.setState({ department_type: 0 });
    } else {
      this.FetchSubAndSubsubCategoryList(data);
    }
  };
  handleCloseMobileSearchPanel = () => {
    this.setState({ searchTextValue: "", openSearch: false });
  };
  onhandleSearchContainer = (state, isClear = false) => {
    if (isClear)
      this.setState({ openSearchCustomer: state, customerSearchTxt: "" });
    else this.setState({ openSearchCustomer: state });
  };
  logoutCustomerBySr = () => {
    let list = JSON.parse(localStorage.getItem("SalesRepUserList"));
    if (list?.length > 1) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (
          JSON.parse(localStorage.getItem("access_token")).email ===
          list[i].email
        ) {
          break;
        }
      }
      list.splice(i, 1);
      localStorage.setItem(
        "access_token",
        JSON.stringify(list[0].data.access_token)
      );
      localStorage.setItem(
        "name",
        JSON.stringify({ name: list[0].data.access_token?.name })
      );
      localStorage.setItem(
        "email",
        JSON.stringify({ email: list[0].data.access_token?.email })
      );
      localStorage.setItem("SalesRepUserList", JSON.stringify(list));
      window.location.reload();
    } else {
      localStorage.removeItem("SalesRepUserList");
      this.logout();
    }
  };
  handleRemoveCustomerBySR = () => {
    console.log(this.props.CartCount);
    if (this.props.CartCount?.item > 0) {
      this.setState({ isRemoveCutomer: true });
      this.handleOpnDialog();
    } else {
      this.logoutCustomerBySr();
    }
  };
  handleLineupCustomer = (phone) => {
    let values = {
      username: phone,
    };
    LoginApplication(values, this.props.dispatch).then((response) => {
      if (response.status === 200) {
        // SubscribeaddApi({ store_id: 0, email: values.username });
        this.props.history.push({
          pathname: "/",
        });
        const items = { ...localStorage };

        let userList = localStorage.getItem("SalesRepUserList");
        let userData = {};

        if (userList === null) {
          userList = [];
          userData = {
            access_token: response.data,
            email: { email: response.data.email },
            name: { name: response.data.name },
          };
          userList.push({
            email: userData?.access_token?.email,
            data: userData,
          });
        } else {
          let accessToken = JSON.parse(localStorage.getItem("access_token"));
          Object.keys(items).map((ele) => {
            if (
              ele === "slot" ||
              ele === "name" ||
              ele === "email" ||
              ele === "SelectedAddress" ||
              ele === "access_token"
            ) {
              userData = {
                ...userData,
                [ele]: JSON.parse(localStorage.getItem(ele)),
              };
              // localStorage.removeItem(ele);
            }
          });
          localStorage.removeItem("SelectedAddress");
          localStorage.removeItem("slot");
          this.setState({ slotName: "Choose A Time Slot" });
          userList = JSON.parse(userList);
          userList.push({
            email: userData?.access_token?.email,
            data: userData,
          });
        }
        localStorage.setItem("SalesRepUserList", JSON.stringify(userList));
        this.props.FetchCartCount();
        this.FetchTopMenuDeatils();
        this.createSuccessMsg("success", "Login Success");
        localStorage.removeItem("Guest_userid");
        // localStorage.setItem("listOpen", false);
        this.setState({ openSearchCustomer: false, customerSearchTxt: "" });
      } else if (response.status === 401 && "code" in response) {
        const refreshtoken = response.data.refresh_token;
        if (response.code === "token_not_valid") {
          return RefreshLogin(refreshtoken).then((res) => {});
        }
      } else {
        if (
          localStorage.getItem(
            "UserDetail" + JSON.parse(localStorage.getItem("Store_address"))?.id
          ) !== null
        ) {
          this.handleDialogueBox();
        } else
          this.createSuccessMsg(
            "error",
            "Login failed, Invalid username or password"
          );
      }
    });
  };
  onhandleChangeCustomer = (e) => {
    let value = e.target.value;
    this.setState({ customerSearchTxt: value });
    if (value.length >= 3) {
      this.fetchCustomerList(value);
    }
    if (value === "") {
      this.onhandleSearchContainer(false);
    }
  };
  fetchCustomerList = (customerSearchTxt) => {
    let formValue = {
      search: customerSearchTxt,
      limit: 100000,
      page: 1,
      order: "-id",
      status: "all",
    };
    UserListPage(formValue, this.props.dispatch).then((res) => {
      if (res.status === 200) {
        let result = res.data.result;
        this.onhandleSearchContainer(true);
        let data = [];
        result.map((ele) => {
          let searchText = "";
          if (isNaN(customerSearchTxt)) {
            for (let key in ele) {
              if (
                key !== "id" &&
                key !== "phone" &&
                ele[key]
                  ?.toLowerCase()
                  ?.includes(customerSearchTxt?.toLowerCase())
              ) {
                searchText = ele[key];
                break;
              }
            }
          }

          data.push({
            label: isNaN(customerSearchTxt)
              ? searchText === ""
                ? ele.name
                : searchText
              : formatPhoneNumber(ele.phone),
            subLabel: !isNaN(customerSearchTxt)
              ? ele.name
              : formatPhoneNumber(ele.phone),
            phone: ele.phone,
            address: ele.address,
            city_name: ele.city_name,
            state_name: ele.state_name,
            zipcode: ele.zipcode,
            street_name: ele.street_name,
            name: ele.name,
          });
        });

        this.setState({ customerSearchList: data });
      }
    });
  };
  handleChangeInput = (e, type) => {
    e.preventDefault();
    const { name, value } = e.target;
    let listItem = this.state.unListedItem;
    if (type === "input") listItem[name] = value;
    else if (type === "plus") {
      listItem.qty += 1;
    } else if (type === "minus" && listItem.qty >= 1) {
      listItem.qty -= 1;
    }
    console.log(listItem, e, type);
    this.setState({ unListedItem: listItem });
  };
  handleSubmitUnlistedItem = () => {
    this.AddToCartFunctionlity("", "", "", "unlisted");
  };
  handleUnlistedModal = () => {
    this.setState({
      unListedItem: {
        isOpen: !this.state.unListedItem.isOpen,
        productDetails: "",
        qty: 1,
      },
    });
  };
  onHandleChangeUser = (data) => {
    const items = { ...localStorage };

    const allUserlist = JSON.parse(localStorage.getItem("SalesRepUserList"));
    const accessToken = JSON.parse(localStorage.getItem("access_token"));
    let userData = {};
    Object.keys(items).map((ele) => {
      if (
        ele === "slot" ||
        ele === "name" ||
        ele === "email" ||
        ele === "SelectedAddress" ||
        ele === "access_token"
      ) {
        userData = {
          ...userData,
          [ele]: JSON.parse(localStorage.getItem(ele)),
        };
        localStorage.removeItem(ele);
      }
    });
    for (let i = 0; i < allUserlist.length; i++) {
      if (accessToken.email === allUserlist[i].data.access_token.email) {
        allUserlist[i].data = userData;
      }
    }
    localStorage.setItem("SalesRepUserList", JSON.stringify(allUserlist));
    Object.keys(data.data).map((ele) => {
      localStorage.setItem(ele, JSON.stringify(data.data[ele]));
    });
    window.location.reload();
  };
  renderUserMneuList = (style = "") => {
    return (
      <MenuList>
        <MenuItem disableRipple={true}>
          <ListItemText>
            <Typography variant="inherit" style={{ flexDirection: "column" }}>
              <TypographyTextColor
                component={"div"}
                Color="heading"
                className="user_after_login"
              >
                {" "}
                {localStorage.getItem("name")
                  ? JSON.parse(localStorage.getItem("name")).name
                  : ""}
              </TypographyTextColor>
              <TypographyTextColor
                component={"div"}
                Color="heading"
                className="user_email"
              >
                {localStorage.getItem("email")
                  ? JSON.parse(localStorage.getItem("email")).email
                  : ""}
              </TypographyTextColor>
            </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        {userDashboardMenu.map((val, i) => {
          return (
            <MenuItem
              // style={{ alignItems: style, display: style === "" ? "" : "flex" }}
              onClick={() =>
                val.url === ""
                  ? localStorage.getItem(
                      "UserDetail" +
                        JSON.parse(localStorage.getItem("Store_address"))?.id
                    ) === null
                    ? this.logout()
                    : this.logoutCustomerBySr()
                  : this.redirectTodiffPage("/" + val.url)
              }
              // className="cat-item"
              key={i}
            >
              <ListItemIcon>
                <TypographySecondaryColor
                  className={val.iconType === "fa" ? val.iconName : ""}
                >
                  {val.iconType === "react"
                    ? createElement(Icons[val.iconName])
                    : ""}
                </TypographySecondaryColor>
              </ListItemIcon>
              <ListItemText>
                {" "}
                <TypographyTextColor component="span" Color="other">
                  {val.name}
                </TypographyTextColor>
              </ListItemText>
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };
  FetchTopMenuDeatils = () => {
    let formValues = getSchedule_zip();
    this.setState({ topMenuLoader: true });
    HeaderMenuListApi(formValues, this.props.dispatch).then((res) => {
      let topMenu = [];
      console.log("res==", res);
      if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchTopMenuDeatils();
            }
          );
        }
      } else {
        topMenu = res.data.result;
      }
      this.setState({ top_menu: topMenu, topMenuLoader: false });
    });
  };
  categoryBgsetImageError=(value)=>{
this.setState({categoryBgimageError:value})
  }
  render() {
    let storeName =
      localStorage.getItem("access_token") !== "undefined" &&
      JSON.parse(localStorage.getItem("access_token")) !== undefined
        ? JSON.parse(localStorage.getItem("access_token"))?.sname?.replace(
            /\s/g,
            ""
          )
        : "";

    const {
      toggleMenu,
      productDetails,
      SearchByCategory,
      error,
      searchview,
      added_qty_is_case,
      categoryviewdata,
      department_type,
      SubCategoryListDetails,
      categoryLoader,
      isStoreClosed,
      web_customize,
      top_menu,
      topMenuLoader,
    } = this.state;

    const { tree_data, CartCount, cartLoader, cartItemList, CategoryDetails } =
      this.props;
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    const hide = () => {
      document.getElementsByClassName("hide")[0].classList.toggle("d-none");
    };
    let recentData =
      storeName !== "" ? localStorage.getItem(`recent${storeName}`) : "";

    return (
      <header>
        {web_customize !== null &&
        web_customize?.top_marquee !== null &&
        web_customize?.top_marquee !== "" ? (
          <TypographyPrimaryBgColorDimmed
            component={"div"}
            dimmedRatio={10}
            className="py-2"
          >
            {web_customize?.top_marquee?.length <= 250 ? (
              <TypographyTextFontFamily
                type="other"
                className="text-center"
                style={{ color: "#fff" }}
              >
                {" "}
                {web_customize?.top_marquee}
              </TypographyTextFontFamily>
            ) : (
              <Marquee>
                <TypographyTextFontFamily
                  type="other"
                  className="text-center"
                  style={{ color: "#fff" }}
                >
                  {" "}
                  {web_customize?.top_marquee}
                </TypographyTextFontFamily>
              </Marquee>
            )}
          </TypographyPrimaryBgColorDimmed>
        ) : (
          <></>
        )}
        {isStoreClosed && <div className="backdrop"></div>}
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={(type) => this.CloseLoginPopupDialog(type)}
          isShowclose={true}
          history={this.props.history}
          AddnewUser={this.state.AddnewUser}
        />
        {this.state.setOpenAddresspopup ? (
          <Address
            setOpenAddresspopup={this.state.setOpenAddresspopup}
            CloseDialog={() => this.CloseAddressDialog()}
            setSelectedAddress={(data) => this.setAddress(data)}
            openSlotpopup={() => this.openSlotpopup}
            selectedslotPopupData={this.state.selectedslotPopupData}
          />
        ) : (
          <></>
        )}

        {window.screen.availWidth >= 900 ? (
          <>
            <div
              className="filter"
              id="no-print"
              onMouseEnter={() => this.handleDepartmentContainer(false)}
            >
              <div className="main-head">
                <a
                  role="button"
                  onClick={() => {
                    if (
                      this.props.history.location.pathname ===
                        "/order-success" &&
                      localStorage.getItem(
                        "UserDetail" +
                          JSON.parse(localStorage.getItem("Store_address"))?.id
                      ) !== null
                    ) {
                      console.log("hitt useer");
                      this.logoutCustomerBySr();
                    }
                    this.props.history.push({ pathname: "/" });
                    window.location.reload();
                  }}
                  className="logo"
                >
                  <img
                    src={
                      this.props.logo
                        ? this.props.logo[0] === "/"
                          ? ApiUrls.BASH_URL + "/media/" + this.props.logo
                          : this.props.logo
                        : LOGO_IMAGE
                    }
                    onError={(e) => addDefaultImageSrc(e)}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="head-content">
                <ClickAwayListener onClickAway={this.searchClose}>
                  <div
                    className="category search-panel bg-white"
                    id="search_section"
                  >
                    {this.props.pageName === "category_page" ? (
                      <ChevronRightIcon
                        onClick={() => {
                          this.props.history.go(-1);
                        }}
                        style={{
                          transform: " rotate(180deg)",
                          fontSize: "2rem",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <div className="searchform">
                      <Typography
                        component={TypographyBorder}
                        className="form-group inputBox d-flex"
                      >
                        <SearchInputBox
                          searchTextValue={this.state.searchTextValue}
                          SearchByCategory={SearchByCategory}
                          commonPageTitel={(accesstoken) =>
                            commonPageTitel(accesstoken)
                          }
                          handleBlurSearchbox={(e) =>
                            this.handleBlurSearchbox(e)
                          }
                          handleFocusSearchbox={(e) =>
                            this.handleFocusSearchbox(e)
                          }
                          enterPressed={(e) => this.enterPressed(e)}
                          searchText={(value) => this.searchText(value)}
                          accesstoken={this.state.accesstoken}
                        />
                        {this.state.searchTextValue !== "" ? (
                          <span
                            onClick={(e) => {
                              this.setState({ searchTextValue: "" });
                            }}
                            className="icon-close mr-1"
                          ></span>
                        ) : (
                          <></>
                        )}

                        {window.screen.availWidth >= 900 ? (
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            className="m-2"
                          />
                        ) : (
                          <></>
                        )}

                        <MenuCategoryList
                          selectedCategory={this.state.selectedCategory}
                          tree_data={this.props.tree_data}
                          storeCategoryId={(id, name) =>
                            this.storeCategoryId(id, name)
                          }
                        />
                        {this.props.pageName === "listing_page" ? (
                          <>
                            {window.screen.availWidth >= 900 ? (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                className="m-2"
                              />
                            ) : (
                              <></>
                            )}
                            {this.props.history.location.pathname?.includes(
                              "brand="
                            ) ? (
                              <></>
                            ) : (
                              <div
                                style={{
                                  alignSelf: "center",
                                  display:
                                    window.screen.availWidth > 900
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <FilterButton
                                  BrandProduct={this.props.BrandProduct}
                                  flavor={this.props.flavor}
                                  kosher={this.props.kosher}
                                  handleChangeCheckbox={(
                                    brand_id,
                                    brand__name
                                  ) =>
                                    this.props.handleChangeCheckbox(
                                      brand_id,
                                      brand__name
                                    )
                                  }
                                  handleChangeCheckboxForFlover={(flavor) =>
                                    this.props.handleChangeCheckboxForFlover(
                                      flavor
                                    )
                                  }
                                  handleChangeCheckboxForKosher={(kosher) =>
                                    this.props.handleChangeCheckboxForKosher(
                                      kosher
                                    )
                                  }
                                  sortingData={this.props.sortingData}
                                  selectedSortedValue={
                                    this.props.selectedSortedValue
                                  }
                                  handleChangeSorting={(value) =>
                                    this.props.handleChangeSorting(value)
                                  }
                                  handleChangeCheckboxForProductType={(value) =>
                                    this.props.handleChangeCheckboxForProductType(
                                      value
                                    )
                                  }
                                  fruitType={this.props.fruitType}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.openSearch === true ? (
                          <div className="search-results d-none d-lg-block d-md-block">
                            <div className="search-body">
                              {!this.state.productSearchLoaer &&
                              productDetails.length === 0 &&
                              (this.state.searchTextValue === "" ||
                                this.state.categoryIds === "") ? (
                                <div>
                                  <RecentSearchTab
                                    searchProductByName={(val) =>
                                      this.searchProductByName(val)
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.productSearchLoaer ? (
                                <div className="d-flex justify-content-center">
                                  <CircularProgress />
                                </div>
                              ) : this.state.searchResultMsg !== "" ? (
                                <TypographyTextColor
                                  component={"div"}
                                  Color="heading"
                                  className="d-flex justify-content-center"
                                  style={{ fontSize: "2rem" }}
                                >
                                  {this.state.searchResultMsg}
                                </TypographyTextColor>
                              ) : (
                                <Grid container>
                                  <ScrollColorTypography
                                    component={Grid}
                                    item
                                    md={6}
                                    style={{
                                      overflowY: "auto",
                                      height: "485px",
                                    }}
                                  >
                                    {this.state.searchTextValue.trim() !== "" ||
                                    this.state.categoryIds !== "" ? (
                                      productDetails?.map((row, index) => {
                                        var check_is_case = this.state
                                          .chack_header_is_case[row.id]
                                          ? this.state.chack_header_is_case[
                                              row.id
                                            ]
                                          : false;
                                        var check_added_qty_is_case =
                                          row.added_quantity_is_case.indexOf(
                                            check_is_case
                                          );
                                        var check_is_case_flag = false;
                                        var category_name =
                                          row.cat_name.slice(-1);
                                        var category_Ids = row.cat_id.slice(-1);
                                        var Parent_id = row.Parent_id.slice(-1);
                                        if (check_added_qty_is_case > -1) {
                                          check_is_case_flag = true;
                                        } else {
                                          check_is_case_flag = true;
                                          row.added_quantity_is_case.push(
                                            check_is_case
                                          );
                                          row.added_quantity.push(0);
                                          check_added_qty_is_case =
                                            row.added_quantity_is_case.indexOf(
                                              check_is_case
                                            );
                                        }

                                        return (
                                          <div
                                            className={"search_content"}
                                            onMouseEnter={() =>
                                              this.handlepreviewproduct(row)
                                            }
                                            onClick={() =>
                                              this.reDirectToDetailsPage(
                                                row.id,
                                                row.name,
                                                row.slug,
                                                row.brand_id,
                                                row.category_id,
                                                true
                                              )
                                            }
                                          >
                                            <Grid
                                              container
                                              spacing={3}
                                              // style={{ height: "80px" }}
                                            >
                                              <Grid
                                                item
                                                md={2}
                                                className="position-relative"
                                              >
                                                <div
                                                  className={
                                                    this.props.extraCharges
                                                      ?.is_show_out_of_stock ===
                                                      true &&
                                                    row.out_of_stock === true
                                                      ? "smll_img_search out_of_stock_content"
                                                      : this.props.extraCharges
                                                          ?.is_show_out_of_stock ===
                                                          true &&
                                                        row.out_of_stock_case ===
                                                          true &&
                                                        row
                                                          .added_quantity_is_case[
                                                          check_added_qty_is_case
                                                        ] === true
                                                      ? "smll_img_search out_of_stock_content"
                                                      : "smll_img_search"
                                                  }
                                                >
                                                 
                                                  <img
                                                  src={refineImgurl(row.main_image, {
                                                    
                                                    w: 50,
                                                  })}
                                                    // src={
                                                    //   row.main_image
                                                    //     ? row.main_image
                                                    //     : NO_IMAGE
                                                    // }
                                                    alt=""
                                                    onError={(e) =>
                                                      addDefaultImageSrc(e)
                                                    }
                                                  />
                                                  {this.props.extraCharges
                                                    ?.is_show_out_of_stock ===
                                                    true &&
                                                  row.out_of_stock === true ? (
                                                    <div className="out-of-stock">
                                                      <LabelPrimaryColor
                                                        className="text"
                                                        border={1}
                                                        component={"span"}
                                                      >
                                                        Out Of Stock
                                                      </LabelPrimaryColor>
                                                    </div>
                                                  ) : row.out_of_stock_case ===
                                                      true &&
                                                    row.added_quantity_is_case[
                                                      check_added_qty_is_case
                                                    ] === true ? (
                                                    <div className="out-of-stock">
                                                      <LabelPrimaryColor
                                                        className="text"
                                                        border={1}
                                                        component={"span"}
                                                      >
                                                        Out Of Stock
                                                      </LabelPrimaryColor>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </Grid>
                                              <Grid item md={10}>
                                                <Link>
                                                  <Grid container>
                                                    <Grid item md={8}>
                                                      <div className="search_cart_description">
                                                        <div
                                                          class="search-pro-name"
                                                          style={{
                                                            whiteSpace:
                                                              "break-spaces",
                                                          }}
                                                        >
                                                          <TextFormating
                                                            Color="product"
                                                            component="span"
                                                          >
                                                            {row.name}
                                                          </TextFormating>
                                                        </div>

                                                        <Breadcrumbs
                                                          separator={
                                                            <IoIosArrowForward
                                                              size={10}
                                                            />
                                                          }
                                                          // maxItems={2}
                                                          className="search_category_name"
                                                          aria-label="breadcrumb"
                                                        >
                                                          {row.cat_name.map(
                                                            (val, i) => {
                                                              return (
                                                                <TypographyFontColor
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  border={0}
                                                                  key={i}
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    this.props
                                                                      .pageName ===
                                                                      "listing_page" ||
                                                                    this.props
                                                                      .pageName ===
                                                                      "details_page"
                                                                      ? this.reDirectToListingWithCatgory(
                                                                          row
                                                                            .cat_id[
                                                                            i
                                                                          ],
                                                                          row
                                                                            .Parent_id[
                                                                            i
                                                                          ],
                                                                          false,
                                                                          row
                                                                        )
                                                                      : this.props.handleInnerOpenDialog(
                                                                          row
                                                                            .cat_id[
                                                                            i
                                                                          ],
                                                                          row.category_name,
                                                                          false,
                                                                          0,
                                                                          Parent_id
                                                                        );
                                                                  }}
                                                                >
                                                                  {val}
                                                                </TypographyFontColor>
                                                              );
                                                            }
                                                          )}
                                                        </Breadcrumbs>
                                                      </div>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md={4}
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <div class="search-pro-price">
                                                        <Typography
                                                          component={
                                                            row.is_special ===
                                                            true
                                                              ? TypographySecondaryColor
                                                              : TypographyTextColor
                                                          }
                                                          Color="other"
                                                        >
                                                          {check_is_case_flag &&
                                                          row
                                                            .added_quantity_is_case[
                                                            check_added_qty_is_case
                                                          ] === true
                                                            ? "$" +
                                                              Number(
                                                                row.case_price
                                                              ).toFixed(2)
                                                            : row.is_special ===
                                                              true
                                                            ? row.special_quantity >
                                                              0
                                                              ? row.special_quantity +
                                                                "/$" +
                                                                Number(
                                                                  row.special_price
                                                                ).toFixed(2)
                                                              : "$" +
                                                                Number(
                                                                  row.special_price
                                                                ).toFixed(2)
                                                            : "$" +
                                                              Number(
                                                                row.today_price
                                                              ).toFixed(2)}{" "}
                                                          {row.is_by_weight ===
                                                          true
                                                            ? "/ LB  "
                                                            : ""}
                                                          <h7>
                                                            {row
                                                              ?.added_quantity[
                                                              check_added_qty_is_case
                                                            ] > 0 &&
                                                            row.estimate_weight_per_piece >
                                                              0 &&
                                                            row.is_by_weight ===
                                                              true
                                                              ? "est. " +
                                                                Number(
                                                                  row.estimate_weight_per_piece *
                                                                    row
                                                                      ?.added_quantity[
                                                                      check_added_qty_is_case
                                                                    ]
                                                                ).toFixed(2) +
                                                                " LB / $" +
                                                                Number(
                                                                  row.estimate_weight_per_piece *
                                                                    row
                                                                      ?.added_quantity[
                                                                      check_added_qty_is_case
                                                                    ] *
                                                                    row.today_price
                                                                ).toFixed(2)
                                                              : ""}
                                                          </h7>
                                                          <TypographyTextColor
                                                            component="del"
                                                            Color="other"
                                                            className="del_class"
                                                          >
                                                            {row.is_offer ===
                                                            true
                                                              ? row
                                                                  ?.added_quantity_is_case[
                                                                  check_added_qty_is_case
                                                                ] === true
                                                                ? "$" +
                                                                  Number(
                                                                    row.case_quantity *
                                                                      row.today_price
                                                                  ).toFixed(2)
                                                                : "$" +
                                                                  Number(
                                                                    row.original_price
                                                                  ).toFixed(2)
                                                              : ""}
                                                          </TypographyTextColor>
                                                          <TypographyTextColor
                                                            component="del"
                                                            Color="other"
                                                            className="del_class"
                                                          >
                                                            {row.is_special ===
                                                            true
                                                              ? "$" +
                                                                Number(
                                                                  row.today_price
                                                                ).toFixed(2)
                                                              : ""}
                                                          </TypographyTextColor>
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </Link>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="try_with_other_word">
                                        <TypographyFontColor border={0}>
                                          {this.state.searchResultMsg}
                                        </TypographyFontColor>
                                      </div>
                                    )}
                                  </ScrollColorTypography>
                                  <Grid
                                    item
                                    md={6}
                                    className={`search-preview-section ${
                                      searchview === "" &&
                                      productDetails.length === 0
                                        ? ""
                                        : " search-preview-section-bg"
                                    }`}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center",
                                        opacity:
                                          searchview === "" ||
                                          productDetails.length === 0
                                            ? 0
                                            : 1,
                                      }}
                                    >
                                      <div
                                        style={{ position: "relative" }}
                                        className={
                                          this.props.extraCharges
                                            ?.is_show_out_of_stock === true &&
                                          searchview.out_of_stock === true
                                            ? " out_of_stock_content"
                                            : this.props.extraCharges
                                                ?.is_show_out_of_stock ===
                                                true &&
                                              searchview.out_of_stock_case ===
                                                true &&
                                              searchview.added_quantity_is_case[
                                                added_qty_is_case
                                              ] === true
                                            ? " out_of_stock_content"
                                            : ""
                                        }
                                      >
                                        
                                        <img
                                          className="search_popover_img"
                                          src={refineImgurl(searchview?.main_image, {
                                            
                                            w: 150,
                                          })}
                                          // src={
                                          //   searchview?.main_image
                                          //     ? searchview?.main_image
                                          //     : NO_IMAGE
                                          // }
                                          alt=""
                                          onError={(e) => addDefaultImageSrc(e)}
                                        />
                                        {this.props.extraCharges
                                          ?.is_show_out_of_stock === true &&
                                        searchview.out_of_stock === true ? (
                                          <div
                                            className="out-of-stock"
                                            style={{ top: "50%", left: 0 }}
                                          >
                                            <LabelPrimaryColor
                                              className="text"
                                              component={"span"}
                                              border={2}
                                              style={{ fontSize: "12px" }}
                                            >
                                              Out Of Stock
                                            </LabelPrimaryColor>
                                          </div>
                                        ) : searchview.out_of_stock_case ===
                                            true &&
                                          searchview.added_quantity_is_case[
                                            added_qty_is_case
                                          ] === true ? (
                                          <div
                                            className="out-of-stock"
                                            style={{ top: "50%", left: 0 }}
                                          >
                                            <LabelPrimaryColor
                                              className="text"
                                              component={"span"}
                                              border={2}
                                              style={{ fontSize: "12px" }}
                                            >
                                              Out Of Stock
                                            </LabelPrimaryColor>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <TextFormating
                                        Color="product"
                                        component={"span"}
                                        className="search-preview-section search-popover-itmname"
                                      >
                                        {searchview?.name}
                                      </TextFormating>
                                      <div
                                        className="search-preview-section"
                                        style={{ background: "transparent" }}
                                      >
                                        <TypographyTextColor
                                          component="p"
                                          Color="other"
                                          className="mb-1 brand-name"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {searchview && searchview.brand_name
                                            ? searchview.brand_name +
                                              `${
                                                searchview.package?.trim() !==
                                                  "" &&
                                                searchview.package !==
                                                  "0.0 0" &&
                                                searchview.package !== "0.0 "
                                                  ? " | "
                                                  : ""
                                              }`
                                            : ""}
                                          {searchview &&
                                          searchview.package !== "0.0 0" &&
                                          searchview.package !== "0.0 "
                                            ? searchview.package
                                            : ""}
                                          {/* {(searchview.package?.trim() !== "" ||
                                          searchview.brand_name !== null) &&
                                        searchview.is_ebt === true
                                          ? " | "
                                          : ""}
                                        {searchview.is_ebt === true
                                          ? " EBT"
                                          : ""} */}
                                        </TypographyTextColor>

                                        <div className="details_pricerow">
                                          {searchview?.original_price !== 0 ? (
                                            <Typography
                                              component={
                                                searchview.is_special === true
                                                  ? TypographySecondaryColor
                                                  : TypographyTextColor
                                              }
                                              Color="other"
                                              style={{ height: "70px" }}
                                              className={
                                                searchview?.is_by_weight ===
                                                  true &&
                                                searchview?.estimate_weight_per_piece >
                                                  0
                                                  ? "details_pricerow_height"
                                                  : ""
                                              }
                                            >
                                              {searchview?.is_case_check ===
                                              true
                                                ? "$" +
                                                  Number(
                                                    searchview?.case_price
                                                  ).toFixed(2)
                                                : searchview?.is_special ===
                                                  true
                                                ? searchview?.special_quantity >
                                                  0
                                                  ? searchview?.special_quantity +
                                                    "/$" +
                                                    Number(
                                                      searchview?.special_price
                                                    ).toFixed(2)
                                                  : "$" +
                                                    Number(
                                                      searchview?.special_price
                                                    ).toFixed(2)
                                                : "$" +
                                                  Number(
                                                    searchview?.today_price
                                                  ).toFixed(2)}{" "}
                                              {searchview &&
                                              searchview?.is_by_weight ===
                                                true &&
                                              searchview?.is_case_check ===
                                                false
                                                ? "/ LB"
                                                : ""}
                                              {searchview?.is_special ===
                                              true ? (
                                                <TypographyTextColor
                                                  component="del"
                                                  Color="other"
                                                  className="del_class"
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {"$" +
                                                    Number(
                                                      searchview?.today_price
                                                    ).toFixed(2)}
                                                </TypographyTextColor>
                                              ) : (
                                                ""
                                              )}
                                              <h6>
                                                <h6>
                                                  {searchview?.is_case_check
                                                    ? "($" +
                                                      (
                                                        searchview?.case_price /
                                                        searchview?.case_quantity
                                                      ).toFixed(2)
                                                    : ""}
                                                  {searchview?.is_by_weight ===
                                                    true &&
                                                  searchview?.is_case_check
                                                    ? "/ LB each)"
                                                    : searchview?.is_by_weight ===
                                                        false &&
                                                      searchview?.is_case_check
                                                    ? " each)"
                                                    : ""}{" "}
                                                </h6>

                                                {searchview?.added_quantity &&
                                                searchview?.added_quantity[
                                                  added_qty_is_case
                                                ] > 0 &&
                                                searchview?.is_by_weight ===
                                                  true &&
                                                searchview?.estimate_weight_per_piece >
                                                  0
                                                  ? "est. " +
                                                    Number(
                                                      searchview?.estimate_weight_per_piece *
                                                        searchview
                                                          ?.added_quantity[
                                                          this.state
                                                            .pro_details_ind
                                                        ]
                                                    ).toFixed(2) +
                                                    " LB / $" +
                                                    Number(
                                                      searchview?.estimate_weight_per_piece *
                                                        searchview
                                                          ?.added_quantity[
                                                          this.state
                                                            .pro_details_ind
                                                        ] *
                                                        searchview.today_price
                                                    ).toFixed(2)
                                                  : ""}
                                              </h6>
                                              {searchview?.is_offer ? (
                                                <>
                                                  <TypographyTextColor
                                                    component="del"
                                                    Color="other"
                                                    className="del_class"
                                                  >
                                                    {searchview?.is_offer
                                                      ? searchview?.unit
                                                      : "$"}
                                                    {searchview?.is_offer
                                                      ? searchview
                                                          ?.added_quantity_is_case[
                                                          this.state
                                                            .pro_details_ind
                                                        ] === true
                                                        ? "$" +
                                                          Number(
                                                            searchview?.case_quantity *
                                                              searchview?.today_price
                                                          ).toFixed(2)
                                                        : "$" +
                                                          Number(
                                                            searchview?.original_price
                                                          ).toFixed(2)
                                                      : ""}
                                                  </TypographyTextColor>
                                                  <span className="price_off">
                                                    {searchview?.is_offer
                                                      ? searchview?.offer_tag +
                                                        "%"
                                                      : ""}
                                                    {searchview &&
                                                    searchview?.special_quantity >
                                                      0 &&
                                                    searchview?.special_price >
                                                      0 &&
                                                    searchview?.offer_msg ===
                                                      "B"
                                                      ? " (Price $ " +
                                                        (
                                                          searchview?.special_price /
                                                          searchview?.special_quantity
                                                        ).toFixed(2) +
                                                        " applied at Minimum 1 quantity"
                                                      : searchview &&
                                                        searchview?.special_quantity >
                                                          0 &&
                                                        searchview?.special_price >
                                                          0 &&
                                                        searchview?.offer_msg ===
                                                          "M"
                                                      ? "(Price $ " +
                                                        searchview?.special_price.toFixed(
                                                          2
                                                        ) +
                                                        " applied at Minimum " +
                                                        searchview?.special_quantity +
                                                        " quantity)"
                                                      : searchview &&
                                                        searchview?.special_quantity >
                                                          0 &&
                                                        searchview?.special_price >
                                                          0 &&
                                                        searchview?.offer_msg ===
                                                          "P"
                                                      ? "(Price $" +
                                                        (
                                                          searchview?.special_price /
                                                          searchview?.special_quantity
                                                        ).toFixed(2) +
                                                        " applied at the Pack of " +
                                                        searchview?.special_quantity +
                                                        " quantity and Maximum " +
                                                        searchview?.special_max +
                                                        " pack)"
                                                      : ""}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Typography>
                                          ) : (
                                            <p className="details_pricerow_height"></p>
                                          )}
                                          {searchview === "" ||
                                          productDetails.length === 0 ? (
                                            <></>
                                          ) : (
                                            <div className="search-preview-section">
                                              {searchview.addtocart_show ===
                                              true ? (
                                                searchview.added_quantity[
                                                  added_qty_is_case
                                                ] === 0 &&
                                                searchview.is_case === false ? (
                                                  <div className="search_content_btn">
                                                    <div className="add_to_cart">
                                                      <SecondaryButton
                                                        Fonttype="other"
                                                        onClick={this.openAddCount.bind(
                                                          this,
                                                          searchview?.id,
                                                          searchview
                                                            ?.added_quantity[
                                                            added_qty_is_case
                                                          ] + 1,
                                                          searchview
                                                            ?.added_quantity_is_case[
                                                            added_qty_is_case
                                                          ],
                                                          searchview
                                                            ?.added_quantity[
                                                            added_qty_is_case
                                                          ]
                                                        )}
                                                      >
                                                        Add
                                                      </SecondaryButton>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <div className="add_to_cart searchAddcard">
                                                      <TypographySecondaryBgColor
                                                        aria-label="outlined primary button group"
                                                        className="d-flex"
                                                        style={{
                                                          borderRadius: "5px",
                                                          width: "160px",
                                                          height: "37px",
                                                        }}
                                                      >
                                                        <span
                                                          className="bg"
                                                          style={{
                                                            minWidth:
                                                              "30px !important",
                                                            padding: 0,
                                                          }}
                                                          onClick={this.openAddCount.bind(
                                                            this,
                                                            searchview.id,
                                                            searchview
                                                              .added_quantity[
                                                              added_qty_is_case
                                                            ] - 1,
                                                            searchview
                                                              .added_quantity_is_case[
                                                              added_qty_is_case
                                                            ],
                                                            searchview
                                                              .added_quantity[
                                                              added_qty_is_case
                                                            ]
                                                          )}
                                                          disabled={
                                                            searchview.disabled
                                                          }
                                                        >
                                                          {searchview.disabled ? (
                                                            "..."
                                                          ) : (
                                                            <span
                                                              className="icon-minus"
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></span>
                                                          )}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            background: "#fff",
                                                            minWidth: "60px",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {searchview
                                                            .added_quantity[
                                                            added_qty_is_case
                                                          ] > 0
                                                            ? searchview
                                                                .added_quantity[
                                                                added_qty_is_case
                                                              ]
                                                            : 0}
                                                        </span>
                                                        <span
                                                          style={{
                                                            minWidth:
                                                              "30px !important",
                                                            padding: 0,
                                                          }}
                                                          className="bg2"
                                                          onClick={this.openAddCount.bind(
                                                            this,
                                                            searchview.id,
                                                            searchview
                                                              .added_quantity[
                                                              added_qty_is_case
                                                            ] > 0
                                                              ? searchview
                                                                  .added_quantity[
                                                                  added_qty_is_case
                                                                ] + 1
                                                              : 1,
                                                            searchview?.is_case_check,
                                                            searchview
                                                              .added_quantity[
                                                              added_qty_is_case
                                                            ]
                                                          )}
                                                          disabled={
                                                            searchview.disabled
                                                          }
                                                        >
                                                          {searchview.disabled ? (
                                                            "..."
                                                          ) : (
                                                            <span
                                                              className="icon-plus"
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></span>
                                                          )}
                                                        </span>
                                                      </TypographySecondaryBgColor>
                                                    </div>
                                                    {searchview.is_case ===
                                                    true ? (
                                                      <TypographyPrimaryBgColorDimmed
                                                        onClick={() =>
                                                          this.ProductCaseFunctionlity(
                                                            searchview.id,
                                                            !searchview?.is_case_check,
                                                            searchview
                                                          )
                                                        }
                                                        component={"div"}
                                                        border={1}
                                                        color="primary"
                                                        role="button"
                                                        className="case-cnt mt-1 d-flex"
                                                        style={{
                                                          width: "160px",
                                                          fontSize: "15px",
                                                          marginTop: 0,
                                                        }}
                                                      >
                                                        <TypographyFontColor
                                                          component={"span"}
                                                          border={0}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            name="vehicle1"
                                                            checked={
                                                              searchview.is_case_check ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            style={{
                                                              flexBasis: "20%",
                                                            }}
                                                          />
                                                          <label
                                                            for="vehicle1"
                                                            className="ml-1"
                                                          >
                                                            Case of
                                                            <span className="ml-1">
                                                              {
                                                                searchview.case_quantity
                                                              }
                                                            </span>
                                                          </label>
                                                        </TypographyFontColor>
                                                      </TypographyPrimaryBgColorDimmed>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )
                                              ) : (
                                                <div className="search_content_btn">
                                                  <div className="add_to_cart  mr-2">
                                                    <SecondaryButton
                                                      Fonttype="other"
                                                      onClick={() =>
                                                        this.reDirectToDetailsPage(
                                                          searchview.id,
                                                          searchview.name,
                                                          searchview.slug,
                                                          searchview.brand_id,
                                                          searchview.category_id,
                                                          true
                                                        )
                                                      }
                                                    >
                                                      Select
                                                    </SecondaryButton>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                            <CircularProgress
                              style={
                                ({
                                  display: "flex",
                                  color: "#3d9b7b",
                                  "& > * + *": {
                                    marginLeft: 2,
                                  },
                                },
                                loadingTextCSS)
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.openSearch === true &&
                        window.screen.availWidth <= 900 ? (
                          <SearchPreviewMob
                            productDetails={productDetails}
                            chack_header_is_case={
                              this.state.chack_header_is_case
                            }
                            reDirectToDetailsPage={(
                              id,
                              name,
                              slug,
                              brand_id,
                              category_id,
                              type
                            ) =>
                              this.reDirectToDetailsPage(
                                id,
                                name,
                                slug,
                                brand_id,
                                category_id,
                                type
                              )
                            }
                            extraCharges={this.props.extraCharges}
                            openAddCount={(
                              id,
                              added_quantity,
                              added_quantity_is_case,
                              type
                            ) =>
                              this.openAddCount.bind(
                                this,
                                id,
                                added_quantity,
                                added_quantity_is_case,
                                type
                              )
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Typography>

                      <SearchButton
                        onClick={() => this.searchProductByName()}
                        className="search-submit"
                        variant="contained"
                      >
                        <span class="icon-search label-icon"></span>
                        {/* )} */}
                      </SearchButton>
                    </div>
                  </div>
                </ClickAwayListener>
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cant find the item?"
                >
                  {" "}
                  <TbZoomQuestion
                    role="button"
                    className="mr-2"
                    size={30}
                    onClick={this.handleUnlistedModal}
                  />
                </span>
                {/* <CantFinditem
                unListedItem={this.state.unListedItem}
                handleChangeInput={this.handleChangeInput}
                handleSubmitUnlistedItem={this.handleSubmitUnlistedItem}
                handleUnlistedModal={this.handleUnlistedModal}
              /> */}

                {localStorage.getItem("frontend_animation") &&
                localStorage.getItem("frontend_animation") !==
                  "no_delivery_pickup" &&
                (localStorage.getItem("isLogin") === "true" ||
                  localStorage.getItem("Guest_userid") !== null) ? (
                  <div className="popup_delivery_btn desk mx-2">
                    <div
                      onClick={() => this.openDeliveryPickup()}
                      className="d-flex"
                      role="button"
                    >
                      <span>
                        <AiOutlineFieldTime
                          className="mr-1"
                          style={{ color: "red", fontSize: "1.5rem" }}
                        />
                      </span>
                      <div>
                        {" "}
                        {localStorage.getItem("slot") !== "undefined" &&
                        JSON.parse(localStorage.getItem("slot")) !==
                          "undefined" &&
                        localStorage.getItem("SelectedAddress") !==
                          "undefined" &&
                        JSON.parse(localStorage.getItem("SelectedAddress")) !==
                          "undefined" &&
                        JSON.parse(localStorage.getItem("slot"))?.schedule ===
                          2 ? (
                          <>
                            <TypographySecondaryColor
                              component={"span"}
                              style={{ whiteSpace: "nowrap" }}
                              Fonttype="other"
                            >
                              {
                                JSON.parse(
                                  localStorage.getItem("SelectedAddress")
                                )?.name
                              }
                            </TypographySecondaryColor>
                            <br />
                          </>
                        ) : (
                          ""
                        )}{" "}
                        <TypographyTextColor
                          component={"span"}
                          Color="other"
                          style={{ display: "block", width: "max-content" }}
                        >
                          {this.state.slotName}
                        </TypographyTextColor>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* {localStorage.getItem(  "UserDetail"+ JSON.parse(localStorage.getItem('Store_address'))?.id) === null?  */}
                <div class="dropdown user_wrap">
                  {/* <span
                  aria-describedby={"simple-popover"}
                  className="user-log dropbtn d-flex justify-content-center"
                  onClick={(e) => this.loginOptions(e)}
                  role="button"
                >
                  <span className="user-icon">
                  <FaUserCircle  size={30}/>
                    
                  </span>

                 
                </span> */}
                  <Tooltip title="Account settings" className="ml-0">
                    <IconButton
                      onClick={(e) => this.loginOptions(e)}
                      size="small"
                      sx={{ ml: 2 }}
                      // aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? 'true' : undefined}
                    >
                      <FaUserCircle size={30} />
                    </IconButton>
                  </Tooltip>
                  {Boolean(this.state.anchorEllist) ? (
                    <div
                      class={` dropdown-content ${
                        localStorage.getItem("listOpen") === "false"
                          ? "p-3"
                          : ""
                      }`}
                    >
                      <ClickAwayListener
                        onClickAway={() => this.handleClickAway()}
                        style={{ borderTop: "none" }}
                      >
                        {localStorage.getItem("listOpen") === "false" ? (
                          <div>
                            {" "}
                            <Typography variant="inherit" noWrap>
                              <span style={{ borderTop: "none" }}>
                                {" "}
                                {localStorage.getItem(
                                  "UserDetail" +
                                    JSON.parse(
                                      localStorage.getItem("Store_address")
                                    )?.id
                                ) !== null ? (
                                  <TypographyTextColor
                                    component={"h5"}
                                    Color="heading"
                                  >
                                    {" Hi @ " +
                                      JSON.parse(
                                        localStorage.getItem(
                                          "UserDetail" +
                                            JSON.parse(
                                              localStorage.getItem(
                                                "Store_address"
                                              )
                                            )?.id
                                        )
                                      )?.name}{" "}
                                  </TypographyTextColor>
                                ) : (
                                  <>
                                    <TypographyTextColor
                                      component={"h5"}
                                      Color="heading"
                                    >
                                      Welcome
                                    </TypographyTextColor>
                                    <TypographyTextColor Color="other">
                                      {localStorage.getItem("Guest_userid") !==
                                      null
                                        ? JSON.parse(
                                            localStorage.getItem("email")
                                          )?.email
                                        : "To access account and manage orders"}
                                    </TypographyTextColor>
                                  </>
                                )}
                              </span>
                            </Typography>
                            {localStorage.getItem("Guest_userid") !== null ||
                            localStorage.getItem(
                              "UserDetail" +
                                JSON.parse(
                                  localStorage.getItem("Store_address")
                                )?.id
                            ) !== null ? (
                              <Link
                                onClick={() => {
                                  localStorage.removeItem(
                                    "UserDetail" +
                                      JSON.parse(
                                        localStorage.getItem("Store_address")
                                      )?.id
                                  );
                                  this.logout();
                                  localStorage.removeItem("SalesRepUserList");
                                  localStorage.removeItem("Guest_userid");
                                  localStorage.removeItem("name");
                                  this.props.history.push({
                                    pathname: "/",
                                  });
                                }}
                              >
                                Log out
                              </Link>
                            ) : (
                              <div className="d-flex">
                                <SecondaryButton
                                  Fonttype="other"
                                  className="login_default"
                                  onClick={() => this.redirectToLogin()}
                                  // to={{ pathname: `/login`, query: { pageName } }}
                                  style={{
                                    width:
                                      localStorage.getItem(
                                        "UserDetail" +
                                          JSON.parse(
                                            localStorage.getItem(
                                              "Store_address"
                                            )
                                          )?.id
                                      ) !== null
                                        ? "100%"
                                        : "",
                                  }}
                                >
                                  {JSON.parse(
                                    localStorage.getItem(
                                      "UserDetail" +
                                        JSON.parse(
                                          localStorage.getItem("Store_address")
                                        )?.id
                                    )
                                  )?.isUserSalesrep
                                    ? "Line up customer"
                                    : "Login"}
                                </SecondaryButton>
                                {JSON.parse(
                                  localStorage.getItem(
                                    "UserDetail" +
                                      JSON.parse(
                                        localStorage.getItem("Store_address")
                                      )?.id
                                  )
                                )?.isUserSalesrep ? (
                                  ""
                                ) : (
                                  <SecondaryButton
                                    Fonttype="other"
                                    // to="/create-account"
                                    className="login_default login_trans"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: "/create-account",
                                      });
                                    }}
                                  >
                                    Sign Up
                                  </SecondaryButton>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>{this.renderUserMneuList()}</div>
                        )}
                      </ClickAwayListener>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* : */}
                {/* <></>} */}

                {localStorage.getItem(
                  "UserDetail" +
                    JSON.parse(localStorage.getItem("Store_address"))?.id
                ) !== null ? (
                  <SearchableSelect
                    openSearchCustomer={this.state.openSearchCustomer}
                    onhandleSearchContainer={this.onhandleSearchContainer}
                    handleLineupCustomer={this.handleLineupCustomer}
                    onhandleChangeCustomer={this.onhandleChangeCustomer}
                    customerSearchTxt={this.state.customerSearchTxt}
                    customerSearchList={this.state.customerSearchList}
                    redirectToLogin={this.redirectToLogin}
                    customerAddressView={this.state.customerAddressView}
                    handleBindAddress={this.handleBindAddress}
                  />
                ) : (
                  <></>
                )}

                <div
                  className="category cart-cnt"
                  onClick={() => this.showCart()}
                >
                  <div className="cart">
                    <a>
                      {" "}
                      <TypographyFontColor
                        border={0}
                        className="icon-supermarket cat_icn"
                      ></TypographyFontColor>
                    </a>
                  </div>
                  <div className="cart-details">
                    {cartLoader ? (
                      <CircularProgress
                        style={{
                          display: "flex",
                          color: "#3d9b7b",
                          position: "relative",
                          right: "-35%",
                          width: "25px",
                          height: "25px",
                          "& > * + *": {
                            marginLeft: 2,
                          },
                        }}
                      />
                    ) : (
                      <ul>
                        <li className="brd-right d-flex align-items-baseline">
                          <TypographyFontColor border={0} Fonttype="other">
                            {CartCount && CartCount.item ? CartCount.item : 0}
                          </TypographyFontColor>{" "}
                          <TypographyTextColor component={"span"} Color="other">
                            {" "}
                            items
                          </TypographyTextColor>
                        </li>

                        <li>
                          <TypographyFontColor border={0} Fonttype="other">
                            $
                            {CartCount && CartCount.price > 0
                              ? Number(CartCount.price).toFixed(2)
                              : "0.00"}
                          </TypographyFontColor>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.props.pageName !== "category_page" ? (
              // <TypographyPrimaryBgColorDimmed
              //   component="div"
              //   dimmedRatio={100}
              //   className="header-menu"
              //  >
              <div className="header-menu">
                <div className="toggleMenu" id="no-print">
                  {this.state.toggleMenu && (
                    <div className="leftmenu-main hide">
                      <div
                        className={`leftmenu ${toggleMenu ? "openmenu" : ""} `}
                      >
                        <ToggleLoginMenu
                          redirectToProfile={() => this.redirectToProfile()}
                          redirectToLogin={() => this.redirectToLogin()}
                          top_menu={top_menu}
                          pageName={this.props.pageName}
                          activeClass={this.state.activeClass}
                          weekendactiveclass={this.state.weekendactiveclass}
                          covid19activeClass={this.state.covid19activeClass}
                          newItemactiveclass={this.state.newItemactiveclass}
                          ActiveCls={(row) => this.activeClass(row)}
                          rediectToSamePageForspacial={(row, pageName) =>
                            this.rediectToSamePageForspacial(row, pageName)
                          }
                          slotName={this.state.slotName}
                          OpenDialogPdfPopup={() => this.OpenDialogPdfPopup()}
                          logout={this.logout()}
                        />
                      </div>
                      <div
                        className="leftmenu_bgblack"
                        onClick={() => this.toggleMenu()}
                      ></div>
                    </div>
                  )}
                  <div
                    className="line"
                    onClick={() => this.toggleMenu("loginMenu")}
                  ></div>
                </div>

                {localStorage.getItem("frontend_animation") &&
                localStorage.getItem("frontend_animation") !==
                  "no_delivery_pickup" &&
                this.props.pageName !== "category_page" ? (
                  <div className="rp-slot">
                    <div className="popup_delivery_btn">
                      <button
                        onClick={() => this.openDeliveryPickup()}
                        className="btn"
                      >
                        {localStorage.getItem("slot") !== "undefined" &&
                        JSON.parse(localStorage.getItem("slot")) !==
                          "undefined" &&
                        localStorage.getItem("SelectedAddress") !==
                          "undefined" &&
                        JSON.parse(localStorage.getItem("SelectedAddress")) !==
                          "undefined" &&
                        JSON.parse(localStorage.getItem("slot"))?.schedule ===
                          2 ? (
                          <>
                            <span style={{ color: "rgb(61, 155, 123)" }}>
                              {
                                JSON.parse(
                                  localStorage.getItem("SelectedAddress")
                                )?.name
                              }
                            </span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <span>
                          <AiOutlineFieldTime
                            style={{ color: "red", fontSize: "1.5rem" }}
                          />
                        </span>{" "}
                        <span>{this.state.slotName}</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <nav id="no-print">
                  {topMenuLoader ? (
                    <div className="d-flex">
                      {[...Array(4)].map((x, i) => (
                        <CommonSkeleton
                          width="180px"
                          height="50px"
                          className="mr-2"
                        />
                      ))}
                    </div>
                  ) : (
                    <ul className="top-nav">
                      {top_menu?.includes("POPULAR PRODUCT") ? (
                        <TypographySecondaryBgColorDimmed
                          component={"li"}
                          style={{ marginLeft: "10px" }}
                        >
                          <TypographyHoverTextColor
                            Color="category"
                            onClick={() => {
                              if (this.props.pageName === "listing_page")
                                this.rediectToSamePageForspacial(
                                  "POPULAR PRODUCT",
                                  this.props.pageName
                                );
                              else {
                                this.props.history.push({
                                  pathname:
                                    "/listing/campaign=" + "POPULAR PRODUCT",
                                  state: {
                                    campaign: "campaign",
                                    special_filter_value: "POPULAR PRODUCT",
                                    slotName: this.state.slotName,
                                  },
                                });
                              }
                            }}
                          >
                            POPULAR PRODUCT
                          </TypographyHoverTextColor>
                        </TypographySecondaryBgColorDimmed>
                      ) : (
                        <></>
                      )}
                      {top_menu?.includes("RECENTLY ORDERED") ? (
                        <TypographySecondaryBgColorDimmed component={"li"}>
                          <TypographyHoverTextColor
                            Color="category"
                            onClick={() => {
                              if (this.props.pageName === "listing_page")
                                this.rediectToSamePageForspacial(
                                  "RECENTLY ORDERED",
                                  this.props.pageName
                                );
                              else {
                                this.props.history.push({
                                  pathname:
                                    "/listing/campaign=" + "RECENTLY ORDERED",
                                  state: {
                                    campaign: "campaign",
                                    special_filter_value: "RECENTLY ORDERED",
                                    slotName: this.state.slotName,
                                  },
                                });
                              }
                            }}
                          >
                            RECENTLY ORDERED
                          </TypographyHoverTextColor>
                        </TypographySecondaryBgColorDimmed>
                      ) : (
                        <></>
                      )}
                      <li>
                        <Department
                          store_Data={this.state.store_Data}
                          CategoryDetails={
                            department_type === 0
                              ? CategoryDetails
                              : SubCategoryListDetails
                          }
                          categoryLoader={categoryLoader}
                          FetchSubAndSubsubCategoryList={(category_id, level) =>
                            this.FetchSubAndSubsubCategoryList(
                              category_id,
                              level
                            )
                          }
                          department_breadcumbs={
                            this.state.department_breadcumbs
                          }
                          handleBreadcumbs={(type, data) =>
                            this.handleBreadcumbs(type, data)
                          }
                          department_type={this.state.department_type}
                        />
                      </li>
                      {top_menu?.length > 0
                        ? top_menu.map((row, index) => {
                            return row !== "POPULAR PRODUCT" &&
                              row !== "RECENTLY ORDERED" ? (
                              <li>
                                <TypographyHoverTextColor
                                  Color="category"
                                  onMouseEnter={() => this.activeClass(row)}
                                  onMouseLeave={() => this.InactiveClass(row)}
                                  className={
                                    row === "SPECIALS"
                                      ? this.state.activeClass
                                      : row === "WEEKLY"
                                      ? this.state.weekendactiveclass
                                      : row === "COVID19"
                                      ? this.state.covid19activeClass
                                      : row === "NEW ITEM"
                                      ? this.state.newItemactiveclass
                                      : ""
                                  }
                                  onClick={() => {
                                    if (this.props.pageName === "listing_page")
                                      this.rediectToSamePageForspacial(
                                        row,
                                        this.props.pageName
                                      );
                                    else {
                                      this.props.history.push({
                                        pathname: "/listing/campaign=" + row,
                                        state: {
                                          campaign: "campaign",
                                          special_filter_value: row,
                                          slotName: this.state.slotName,
                                        },
                                      });
                                      // if (row === "SPECIALS")
                                      //   window.location.reload();
                                    }
                                  }}
                                >
                                  {row}
                                </TypographyHoverTextColor>
                              </li>
                            ) : (
                              <></>
                            );
                          })
                        : ""}
                      {localStorage.getItem("pdf_data_view") &&
                      JSON.parse(localStorage.getItem("pdf_data_view")).link !==
                        null ? (
                        <li>
                          {" "}
                          <TypographySecondaryColor
                            Fonttype="category"
                            onClick={() => {
                              if (window.screen.availWidth >= 900) {
                                this.setState({ setOpenPdfpoup: true });
                              } else {
                                let fileURL =
                                  ApiUrls.BASH_URL +
                                  "/media" +
                                  JSON.parse(
                                    localStorage.getItem("pdf_data_view")
                                  )?.link;
                                window.open(fileURL, "_blank");
                              }
                            }}
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            {localStorage.getItem("pdf_data_view")
                              ? JSON.parse(
                                  localStorage.getItem("pdf_data_view")
                                ).name
                              : ""}
                          </TypographySecondaryColor>
                        </li>
                      ) : (
                        ""
                      )}

                      <li
                        style={{
                          marginLeft: "auto",
                          alignItems: "center",
                          gap: "1.2rem",
                          border:
                            JSON.parse(
                              localStorage.getItem(
                                "UserDetail" +
                                  JSON.parse(
                                    localStorage.getItem("Store_address")
                                  )?.id
                              )
                            )?.isUserSalesrep &&
                            localStorage.getItem("isLogin") === "true"
                              ? "1px solid #b1b1b1"
                              : "",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {JSON.parse(
                          localStorage.getItem(
                            "UserDetail" +
                              JSON.parse(localStorage.getItem("Store_address"))
                                ?.id
                          )
                        )?.isUserSalesrep &&
                        JSON.parse(localStorage.getItem("SalesRepUserList"))
                          ?.length > 0 ? (
                          <>
                            <div
                              style={{ position: "relative" }}
                              onClick={() => {
                                this.setState({
                                  anchorEllistSalesRep: true,
                                });
                              }}
                            >
                              <div>
                                <TypographyHoverTextColor Color="category">
                                  {localStorage.getItem("access_token")
                                    ? JSON.parse(
                                        localStorage.getItem("access_token")
                                      ).name
                                    : ""}
                                  <IoMdArrowDropdown />
                                </TypographyHoverTextColor>
                              </div>

                              {Boolean(this.state.anchorEllistSalesRep) ? (
                                <ClickAwayListener
                                  onClickAway={() =>
                                    this.setState({
                                      anchorEllistSalesRep: false,
                                    })
                                  }
                                  style={{ borderTop: "none" }}
                                >
                                  <div>
                                    <div
                                      class="dropdown-content"
                                      style={{ top: "2rem", right: 0 }}
                                    >
                                      <div class="list-group list-group-flush">
                                        {this.renderUserMneuList("flex-start")}
                                      </div>
                                    </div>
                                  </div>
                                </ClickAwayListener>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {localStorage.getItem("isLogin") === "true" ? (
                          <>
                            {" "}
                            <div
                              onClick={() => this.redirectTodiffPage("/orders")}
                            >
                              {" "}
                              <TypographyHoverTextColor
                                Color="category"
                                onClick={() =>
                                  this.redirectTodiffPage("/orders")
                                }
                              >
                                {JSON.parse(
                                  localStorage.getItem(
                                    "UserDetail" +
                                      JSON.parse(
                                        localStorage.getItem("Store_address")
                                      )?.id
                                  )
                                )?.isUserSalesrep
                                  ? ""
                                  : "MY"}{" "}
                                ORDERS
                              </TypographyHoverTextColor>
                            </div>
                            <div>
                              {" "}
                              <TypographyHoverTextColor
                                onClick={() =>
                                  this.redirectTodiffPage("/favouritelist")
                                }
                                Color="category"
                              >
                                {JSON.parse(
                                  localStorage.getItem(
                                    "UserDetail" +
                                      JSON.parse(
                                        localStorage.getItem("Store_address")
                                      )?.id
                                  )
                                )?.isUserSalesrep
                                  ? ""
                                  : "MY"}{" "}
                                LISTS
                              </TypographyHoverTextColor>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {JSON.parse(
                          localStorage.getItem(
                            "UserDetail" +
                              JSON.parse(localStorage.getItem("Store_address"))
                                ?.id
                          )
                        )?.isUserSalesrep &&
                        JSON.parse(localStorage.getItem("SalesRepUserList"))
                          ?.length > 0 ? (
                          <div className="d-flex align-items-center">
                            <div>
                              <IoMdCloseCircle
                                onClick={() => this.handleRemoveCustomerBySR()}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={"Logout User"}
                                size={25}
                                className="ml-1"
                                style={{ color: "#6c6c6c" }}
                                role="button"
                              />
                            </div>

                            {Boolean(this.state.isOpenSubUserList) ? (
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.setState({ isOpenSubUserList: false })
                                }
                                style={{ borderTop: "none" }}
                              >
                                <div>
                                  <div
                                    class="dropdown-content"
                                    style={{
                                      top: "2rem",
                                      right: 0,
                                      left: "85%",
                                    }}
                                  >
                                    <div class="list-group list-group-flush">
                                      {JSON.parse(
                                        localStorage.getItem("SalesRepUserList")
                                      )?.map((val, i) => {
                                        return (
                                          <TypographyTextColor
                                            key={i}
                                            Color="other"
                                            component="button"
                                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                            onClick={() => {
                                              this.onHandleChangeUser(val);
                                              // localStorage.setItem(
                                              //   "access_token",
                                              //   JSON.stringify(val)
                                              // );
                                              // window.location.reload();
                                            }}
                                          >
                                            <span>
                                              {val?.data?.access_token?.name}
                                            </span>{" "}
                                            {JSON.parse(
                                              localStorage.getItem(
                                                "access_token"
                                              )
                                            )?.email !== val.email ? (
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  console.log("hitt");
                                                  let list = JSON.parse(
                                                    localStorage.getItem(
                                                      "SalesRepUserList"
                                                    )
                                                  );
                                                  list.splice(i, 1);
                                                  localStorage.setItem(
                                                    "SalesRepUserList",
                                                    JSON.stringify(list)
                                                  );
                                                }}
                                                className="icon-close"
                                              ></span>
                                            ) : (
                                              <></>
                                            )}
                                          </TypographyTextColor>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </ClickAwayListener>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </li>

                      {JSON.parse(
                        localStorage.getItem(
                          "UserDetail" +
                            JSON.parse(localStorage.getItem("Store_address"))
                              ?.id
                        )
                      )?.isUserSalesrep &&
                      JSON.parse(localStorage.getItem("SalesRepUserList"))
                        ?.length > 1 ? (
                        <li className="d-flex align-items-center">
                          <div>
                            <IoReorderThreeOutline
                              size={30}
                              role="button"
                              onClick={() => {
                                this.setState({
                                  isOpenSubUserList: true,
                                });
                              }}
                            />
                          </div>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  )}
                </nav>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <MobileHeader
            searchResultMsg={this.state.searchResultMsg}
            SearchByCategory={SearchByCategory}
            productDetails={productDetails}
            cartLoader={cartLoader}
            CartCount={CartCount}
            toggleMenu={toggleMenu}
            history={this.props.history}
            logo={this.props.logo}
            pageName={this.props.pageName}
            searchClose={this.searchClose}
            searchTextValue={this.state.searchTextValue}
            openSearch={this.state.openSearch}
            top_menu={this.props.top_menu}
            activeClass={this.state.activeClass}
            weekendactiveclass={this.state.weekendactiveclass}
            covid19activeClass={this.state.covid19activeClass}
            newItemactiveclass={this.state.newItemactiveclass}
            slotName={this.state.slotName}
            redirectToProfile={() => this.redirectToProfile()}
            redirectToLogin={() => this.redirectToLogin()}
            activeCls={(row) => this.activeClass(row)}
            rediectToSamePageForspacial={(row, pageName) =>
              this.rediectToSamePageForspacial(row, pageName)
            }
            OpenDialogPdfPopup={() => this.OpenDialogPdfPopup()}
            toggleMenufunc={() => this.toggleMenu()}
            openDeliveryPickup={() => this.openDeliveryPickup()}
            productSearchLoaer={this.state.productSearchLoaer}
            chack_header_is_case={this.state.chack_header_is_case}
            accesstoken={this.state.accesstoken}
            handleBlurSearchbox={(e) => this.handleBlurSearchbox(e)}
            handleFocusSearchbox={(e) => this.handleFocusSearchbox(e)}
            enterPressed={(e) => this.enterPressed(e)}
            searchText={(value) => this.searchText(value)}
            openAddCount={(id, added_quantity, added_quantity_is_case, type) =>
              this.openAddCount(
                id,
                added_quantity,
                added_quantity_is_case,
                type
              )
            }
            searchProductByName={(val = "") => this.searchProductByName(val)}
            reDirectToDetailsPage={(
              id,
              name,
              slug,
              brand_id,
              category_id,
              type
            ) =>
              this.reDirectToDetailsPage(
                id,
                name,
                slug,
                brand_id,
                category_id,
                type
              )
            }
            handleCloseMobileSearchPanel={() =>
              this.handleCloseMobileSearchPanel()
            }
            ProductCaseFunctionlity={(product_id, is_case, data) =>
              this.ProductCaseFunctionlity(product_id, is_case, data)
            }
            extraCharges={this.props.extraCharges}
            categoryIds={this.state.categoryIds}
            openSearchCustomer={this.state.openSearchCustomer}
            onhandleSearchContainer={this.onhandleSearchContainer}
            handleLineupCustomer={this.handleLineupCustomer}
            onhandleChangeCustomer={this.onhandleChangeCustomer}
            customerSearchTxt={this.state.customerSearchTxt}
            customerSearchList={this.state.customerSearchList}
          />
        )}

        {this.state.setOpenPdfpoup ? (
          <PdfViewHeader
            setOpenPdfpoup={this.state.setOpenPdfpoup}
            CloseDialogPdfPopup={() => this.CloseDialogPdfPopup()}
          />
        ) : (
          <></>
        )}
        {this.state.setOpenDeliveryPopup === true ? (
          <DeliveryPopup
            setOpenDeliveryPopup={this.state.setOpenDeliveryPopup}
            CloseDialog={(type, selectedslot) =>
              this.CloseDialog(type, selectedslot)
            }
            setSlotName={(data, zipCode, scheduleType, dataTime, actualdate) =>
              this.setSlotNamedata(
                data,
                zipCode,
                scheduleType,
                dataTime,
                actualdate
              )
            }
            openDeliveryPickup={() => this.openDeliveryPickup()}
            history={this.props.history}
            setOpenslotpupup={this.state.setOpenslotpupup}
            setSelectedAddress={this.state.selectedAddress}
            closeSlotpupupStatus={() => this.closeSlotpupupStatus()}
            selectedslotPopupData={this.state.selectedslotPopupData}
            resetAddress={() => this.handleresetAddress()}
            createSuccessMsg={(type, msg) => this.createSuccessMsg(type, msg)}
          />
        ) : (
          ""
        )}

        {error ? (
          <Snackbar
            open={this.state.error}
            onClose={this.handleClose}
            autoHideDuration={3000}
            className="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            message={
              <React.Fragment>
                <div className="row gutter-8">
                  <div className="col-auto">
                    <i className="icon-closer"></i>
                  </div>
                  <div className="col">
                    <p>{error}</p>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        ) : (
          ""
        )}
        <div
          className="new-category-sidebar header-desktop"
          style={{
            width: "auto",
            maxWidth: this.state.fullcategory_view
              ? window.screen.availWidth > 900 &&
                window.screen.availWidth < 1050
                ? "100%"
                : "70%"
              : "280px",

            height: this.state.openCategorysideslider
              ? this.state.fullcategory_view
                ? "98vh"
                : "99vh"
              : 0,
            display: this.state.openCategorysideslider ? "block" : "none",
          }}
          onMouseLeave={() => {
            this.handleClosefulldialoague();

            if (this.props.pageName === "listing_page")
              document.body.style.overflow = "auto";
          }}
          onMouseEnter={() => {
            if (this.props.pageName === "listing_page")
              document.body.style.overflow = "hidden";
            this.setState({ openCategorysideslider: true });
          }}
        >
          <div style={{ display: "flex" }}>
            {this.props.SideCategoryListLoader ? (
              <div>
                {" "}
                {[...Array(20)].map((x, i) => (
                  <SideCateogorySkeleton />
                ))}
              </div>
            ) : tree_data?.length > 0 ? (
              <CategoryGrower
                history={this.props.history}
                pageName={this.state.pageName}
                CategoryDetails={tree_data}
                openViewfullcategory={(value, data) =>
                  this.openViewfullcategory(value, data)
                }
                FetchDataForPriceRange={(
                  price_min,
                  price_max,
                  category_id,
                  subcategory_id,
                  has_child
                ) =>
                  this.FetchDataForPriceRangeHome(
                    price_min,
                    price_max,
                    category_id,
                    subcategory_id,
                    has_child
                  )
                }
                TreeViewIteams={this.props.TreeViewIteamsHome}
                openCategorysideslider={this.state.openCategorysideslider}
                FetchProduct={(
                  id,
                  data,
                  selectedCategory_Custom_Message_Data
                ) =>
                  this.FetchProduct(
                    id,
                    data,
                    selectedCategory_Custom_Message_Data
                  )
                }
                ref={this.child}
              />
            ) : (
              ""
            )}

            <ScrollColorTypography
              component={"div"}
              style={{
                display: this.state.fullcategory_view ? "block" : "none",
               
              }}
              className="category-background-img"
            >
              {categoryviewdata&&categoryviewdata?.has_child===true ? (
                <div>
                  <CommonCategoryBgImg categoryviewdata={categoryviewdata} imageError={this.state.categoryBgimageError} setImageError={(value)=>this.categoryBgsetImageError(value)}/>
                 
                </div>
              ) : (
                <></>
              )}
              <SideCategoryView
                categoryviewdata={categoryviewdata}
                handleClosefulldialoague={() => this.handleClosefulldialoague()}
                FetchProduct={(id, val, selectedCategory_Custom_Message_Data) =>
                  this.FetchProduct(
                    id,
                    val,
                    selectedCategory_Custom_Message_Data
                  )
                }
                web_customize={web_customize}
              />
            </ScrollColorTypography>
          </div>
        </div>

        <div className="new-cart-slide hidden">
          <CartlistItem
            web_customize={web_customize}
            extraCharges={this.props.extraCharges}
            chack_header_is_case={this.state.chack_header_is_case}
            cartItemList={cartItemList}
            AddCountForcartList={(
              product_id,
              qty,
              is_case,
              icon_type,
              id,
              container_type
            ) =>
              this.props.AddCountForcartList(
                product_id,
                qty,
                is_case,
                icon_type,
                id,
                container_type
              )
            }
            redirectToDetailsPageAfterClick={(product_name, slug, product_id) =>
              this.redirectToDetailsPageAfterClick(
                product_name,
                slug,
                product_id
              )
            }
            handleCheckout={() => this.handleCheckout()}
            handleOpnDialog={(e) => this.handleOpnDialog(e)}
            CartCount={CartCount}
          />

          {window.screen.availWidth <= 960 && this.state.mobileCartitemShow ? (
            <div
              className="leftmenu_bgblack"
              style={{
                background: "rgba(0, 0, 0, 0.6)",
                display: this.state.leftMenuStyledisplay,
              }}
              onClick={(e) => {
                this.setState({ leftMenuStyledisplay: "none" });
                this.showCart();
              }}
            ></div>
          ) : (
            <></>
          )}
          <Commondialog
            open={this.state.openDialog}
            handleCloseOpnDialog={() => this.handleCloseOpnDialog()}
            agreeFunctionlity={(value) => this.removeCartFunctionlity(value)}
            statusDialogmsg={this.state.statusDialogmsg}
          />
          <CantFinditem
            unListedItem={this.state.unListedItem}
            handleChangeInput={this.handleChangeInput}
            handleSubmitUnlistedItem={this.handleSubmitUnlistedItem}
            handleUnlistedModal={this.handleUnlistedModal}
            createSuccessMsg={this.createSuccessMsg}
          />
          <BottomMobNav
            history={this.props.history}
            // toggleMenuCategory={() => this.toggleMenuCategory()}
            OpenLoginPoup={() => this.props.OpenLoginPoup()}
            CartCount={CartCount}
            handleUnlistedModal={this.handleUnlistedModal}
          />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  null,
  null
)(reduxForm({ form: "Header", enableReinitialize: true })(Header));
