import { Skeleton } from '@mui/material'
import React from 'react'

const SideCateogorySkeleton = () => {
  return (
    <div className='d-flex align-items-center px-1'>
         <Skeleton
      animation="wave"
      width={30}
      height={30}
      variant='circular'
      className='mr-2'
    />
    <Skeleton
      animation="wave"
      width={210}
      height={50}
    />
    </div>
  )
}

export default SideCateogorySkeleton