import { Grid } from "@mui/material";
import React,{useEffect} from "react";
import { addDefaultImageSrc } from "../../utils/renderUtils/renderUtil";
import { Carousel } from "react-responsive-carousel";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import NO_IMAGE from "../../assets/images/image-coming-soon.jpg";
import { TypographyTextColor } from "../common/UiDesign";
const OfferImage = (props) => {
  const { SettingLandingPage, offerImgView, offerImgLink } = props;
 

  
  const renderCruselOffer=()=>{
    let offerData=offerImgView?.split(",").filter(function(entry) { return entry.trim() !== ''; });
    return(  <Carousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
    >
      {" "}
      {offerData.map((x, i) =>(
        <img
        onClick={() =>
          offerImgLink.split(",")[i] !== ""
            ? window.open(offerImgLink.split(",")[i])
            : ""
        }
          onError={(e) => addDefaultImageSrc(e)}
          src={x ?x[0]==="/"? ApiUrls.BASH_URL + "/media" + x:x : NO_IMAGE}
          alt="offerimg"
          className="Offerimg"
        />
      ))}
    </Carousel>)
  }
 
  return SettingLandingPage && SettingLandingPage.offer_show === true ? (
    <section className="offer-values">
      <div className="header-cnt">
        <div className="header-details">
          <TypographyTextColor component={"h4"} Color="heading">Offers Best Values</TypographyTextColor>
        </div>
      </div>
      <div className="moblieOfferimg">
      {renderCruselOffer()}
      </div>
      <div class="offerImages">
        <Grid container>
          {offerImgView?.split(",").map((x, i) =>
            x !== "" ? (
              <>
                <Grid
                  item
                  sm={4}
                  key={i}
                  onClick={() =>
                    offerImgLink.split(",")[i] !== ""
                      ? window.open(offerImgLink.split(",")[i])
                      : ""
                  }
                >
                  <img
                    onError={(e) => addDefaultImageSrc(e)}
                    src={x ?x[0]==="/"? ApiUrls.BASH_URL + "/media" + x:x : NO_IMAGE}
                    alt="offerimg"
                    style={{ width: "100%", cursor: "pointer" }}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )
          )}
        </Grid>
      </div>
    </section>
  ) : (
    ""
  );
};

export default OfferImage;
