import React, { Component } from "react";
import Header from "../../Header/header";
import SubCategoryModal from "../SubCategoryModal";
import Loginpopup from "../../../login/Loginpopup";
import {
  RefreshApiToken,
  getSchedule_zip,
} from "../../../../utils/renderUtils/renderUtil";
import {
  CategeriesListView,
  CategoryTreeViewApi,
  GuestLoginAthentication,
  SubCategeriesListView,
} from "../../../../actions/home/homeAction";
import {
  CartListFunctionlity,
  GetExtraChargesData,
} from "../../../../actions/cart/cartAction";
import { GetSettingsData } from "../../../../actions/profile/profileAction";
import MoblieViewCategoryListing from "../../MoblieViewCategoryListing";
import CategoriesModal from "../categoriesModal";
import CustomPreloader from "../../Loader/CustomPreloader";

export default class MobileCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CategoryDetails: [],
      extraCharges: [],
      emailSubscribe: "",
      SubCategoryDetails: [],
      CategoryOption: [],
      homecartItemList: [],
      SubTotal: 0,
      CartCount: [],
      cartLoader: false,
      pageName: "category_page",
      open_inner: false,
      SubSubCategoryDetails: [],
      setOpenLoginPopup: false,
      TreeViewIteamsHome: [],
      CategoryDetailsTree: [],
      store_Data: JSON.parse(localStorage.getItem("Store_address")),
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const accessToken = localStorage.getItem("access_token");
    let LogoNav = this.state.logo;
    const isLogin = localStorage.getItem("isLogin");
    const accessTokenForLogo =
      accessToken !== "undefined"
        ? JSON.parse(localStorage.getItem("access_token"))
        : "";
    LogoNav = accessTokenForLogo ? accessTokenForLogo.logo : "";

    if (accessToken === "undefined" || accessToken === null) {
      this.HitGuestApiToken();
    } else if (accessToken && isLogin === "false") {
      this.HitGuestApiToken();
    } else if (accessToken && isLogin === "true") {
      this.FetchSettingData();
      this.FetchCategoryList();
      this.FetchCartItemListForHeader();
      this.FetchCategoryTreeViewForHome();
      this.ExtraChargesFunctiolity();
    } else {
      this.FetchCartItemListForHeader();
    }
    this.setState({ CategoryListLoader: true });
  }

  HitGuestApiToken = () => {
    let formValues = {};
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      
      if (res.data) {
        localStorage.setItem("access_token", JSON.stringify(res.data));
        logo = res.data && res.data.logo ? res.data.logo : "";

        this.setState({ guest_login: res.data ? true : "", logo: logo });
        this.FetchSettingData();
        this.FetchCategoryList();
        this.FetchCartItemListForHeader();
        this.FetchCategoryTreeViewForHome();
        this.ExtraChargesFunctiolity();
      }
    });
  };
  handleOpenDialog() {
    this.setState({ open: true, open_inner: false, parent_onlyid: [] });
  }

  FetchCartItemListForHeader = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      
      let homecartItemList = [];
      let CartCount = [];
      if (res.data && res.data.result) {
        const result = res.data.result;
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            homecartItemList.push(result[i]);
            SubTotal += Number(result[i].total);
          }
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchCartItemListForHeader();
              }
            );
          }
        }
        if (res.data && res.data.total > 0) {
          CartCount.item = res.data.total;
          CartCount.price = Number(res.data.total_subtotal).toFixed(2);
        }
      }
      this.setState({
        homecartItemList: homecartItemList,
        SubTotal: SubTotal.toFixed(2),
        cartListOpen: true,
        CartCount: CartCount,
        cartLoader: false,
      });
    });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, "").then((res) => {
      
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.ExtraChargesFunctiolity();
          });
        }
      }
      this.setState({ extraCharges: extraCharges });
    });
  };
  FetchCategoryList = () => {
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
      } else if (res.errors && res.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({ CategoryDetails: categoryList, CategoryOption });
    });
  };
  handleCloseDialog() {
    this.setState({ open: false, open_inner: false });
  }
  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res?.data?.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(category_id, level);
            }
          );
        }
      }
      
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  FetchCategoryTreeViewForHome = () => {
    let formValues = getSchedule_zip();

    CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
      let TreeViewIteamsHome = [];
      if (res.data && res.data.result) {
        TreeViewIteamsHome = res.data.result;
        var tree_data = this.onbuildForTreeData(TreeViewIteamsHome, null);
        tree_data.sort(function (a, b) {
          return a.cat_order - b.cat_order;
        });
        this.setState({
          CategoryDetailsTree: tree_data,
          TreeViewIteamsHome: TreeViewIteamsHome,
          CategoryListLoader: false,
        });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryTreeViewForHome();
            }
          );
        }
      }
    });
  };
  FetchProduct = (category_id, has_child, data) => {
    let selectedgroupcat = [];
    let categorydata = structuredClone(data);
    if (categorydata !== undefined) {
      if (categorydata.childItem === undefined) {
        selectedgroupcat = { category: [categorydata], parent: categorydata };
      } else if (categorydata.id === +category_id) {
        selectedgroupcat = { category: [categorydata], parent: categorydata };
      } else {
        let newdata = categorydata.childItem;
        let parentdata;
        parentdata = categorydata;
        // newdata.push(parentdata);
        let urlcategoryid = category_id;
        for (let i = 0; i < newdata.length; i++) {
          if (newdata[i].id === +urlcategoryid) {
            let data = newdata[i];
            newdata.splice(i, 1);
            newdata.splice(0, 0, data);
            break;
          }
        }
        selectedgroupcat = { category: newdata, parent: parentdata };
      }
    }
    this.props.history.push({
      pathname: "/listing/" + category_id,
      state: {
        category_id: category_id,
        parent_id: "",
        parentIds: "",
        parent_level: "",
        categoryviewdata: data,
        selectedgroupcat: selectedgroupcat,
      },
    });
    // if (
    //   this.props.pageName === "details_page" ||
    //   this.props.pageName === "home_page"
    // ) {
    // } else {
    //   this.props.FetchDataForPriceRange("", "", category_id, "", false, false);
    //   this.props.bindCategorygroupdata();
    // }
    // this.props.toggleMenuCat();
  };
  render() {
    const {
      CategoryDetails,
      SubCategoryDetails,
      CartCount,
      CategoryDetailsTree,
      CategoryOption,
      store_Data,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          CategoryDetails={CategoryDetails}
          CategoryOption={CategoryOption}
          handleOpenDialog={this.handleOpenDialog}
          pageName={this.state.pageName}
          history={this.props.history}
          CartCount={CartCount}
          cartLoader={this.state.cartLoader}
        />
        <CategoriesModal
          open={this.state.open}
          handleOpenDialog={this.handleOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          CategoryDetails={CategoryDetails}
          history={this.props}
        />
        <SubCategoryModal
          open_inner={this.state.open_inner}
          SubcategoryName={this.state.SubcategoryName}
          // handleInnerOpenDialog={this.handleInnerOpenDialog}
          handleInnerCloseDialog={this.handleInnerCloseDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleOpenDialog={this.handleOpenDialog}
          SubCategoryDetails={this.state.SubCategoryDetails}
          history={this.props.history}
          pageName={this.state.pageName}
          SubSubCategoryDetails={this.state.SubSubCategoryDetails}
          subCategoryLoader={this.state.subCategoryLoader}
          FetchSubCategoryList={(id, level) =>
            this.FetchSubCategoryList(id, level)
          }
          parentIds={this.state.parentIds}
          parent_onlyid={this.state.parent_onlyid}
          parent_level={this.state.parent_level}
        />
        <Loginpopup
          setOpenLoginPopup={this.state.setOpenLoginPopup}
          CloseLoginDialog={(cleardata = false) =>
            this.CloseLoginDialog(cleardata)
          }
          isShowclose={true}
          history={this.props.history}
        />
        <div class="listing_inner_data">
          <div class="list_main_content-new">
            <div
              className="main_containerLoader"
              style={{
                display: this.state.CategoryListLoader ? "flex" : "none",
              }}
            >
              <CustomPreloader />
            </div>
            <div className="main-data">
              <MoblieViewCategoryListing
                CategoryDetails={CategoryDetailsTree}
                FetchProduct={(category_id, has_child, data) =>
                  this.FetchProduct(category_id, has_child, data)
                }
                store_Data={this.state.store_Data}
              />

              {CategoryDetails && CategoryDetails.length > 0 ? (
                <CategoriesModal
                  open={this.state.open}
                  handleOpenDialog={this.handleOpenDialog}
                  handleCloseDialog={this.handleCloseDialog}
                  CategoryDetails={CategoryDetails}
                  SubCategoryDetails={SubCategoryDetails}
                  history={this.props}
                  pageName={this.state.pageName}
                  categoryLoader={this.state.categoryLoader}
                  FetchSubCategoryList={(id, level, parent_id) =>
                    this.FetchSubCategoryList(id, level, parent_id)
                  }
                  parentIds={this.state.parentIds}
                  parent_onlyid={this.state.parent_onlyid}
                  parent_level={this.state.parent_level}
                />
              ) : (
                ""
              )}
              <SubCategoryModal
                open_inner={this.state.open_inner}
                SubcategoryName={this.state.SubcategoryName}
                handleInnerCloseDialog={this.handleInnerCloseDialog}
                handleCloseDialog={this.handleCloseDialog}
                handleOpenDialog={this.handleOpenDialog}
                SubCategoryDetails={this.state.SubCategoryDetails}
                history={this.props.history}
                pageName={this.state.pageName}
                SubSubCategoryDetails={this.state.SubSubCategoryDetails}
                subCategoryLoader={this.state.subCategoryLoader}
                FetchSubCategoryList={(id, level) =>
                  this.FetchSubCategoryList(id, level)
                }
                parentIds={this.state.parentIds}
                parent_onlyid={this.state.parent_onlyid}
                parent_level={this.state.parent_level}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
