// >>>>>>> HEAD
// =======
// <<<<<<< HEAD
const API_URL =  window.location.origin + "/api";

// =======
// <<<<<<< HEAD
// const API_URL = process.env.NODE_ENV==="development"? "http://navsoft.co.in:96/api":window.location.origin + "/api";
//const API_URL = "http://192.168.0.27:8000/api";
// =======
// const API_URL = "http://localhost:8000/api";
//  const API_URL = "http://navsoft.co.in:96/api";
//  const API_URL="https://webapi.poswithlogic.com/api"
// const API_URL = "http://192.168.0.27:8000/api";
// >>>>>>> development
// >>>>>>> development

// const API_URL = "http://192.168.3.42:8000/api";
// >>>>>>> development
// =======
// const API_URL = "http://navsoft.co.in:96/api";
// >>>>>>> development
// alert(window.location.origin + "/api";)
// const API_URL = "http://navsoft.co.in:96/api";


const APIURL_OBJECT = {
  LOGIN_URL: `${API_URL}/customer/account/login`,
  OTP_SALES_URL: `${API_URL}/customer/account/sales-rap-sendotp`,
  LOGIN_SALES_URL: `${API_URL}/customer/account/sales-rap-login`,
  REFRESh_URL: `${API_URL}/customer/account/refresh`,
  GUEST_LOGIN: `${API_URL}/customer/account/guest-login`,
  CATEGORIES_LIST: `${API_URL}/customer/category-management/category-list`,
  SUBCATEGORIES_LIST: `${API_URL}/customer/category-management/subcategory-list`,
  CATEGORY_MANAGEMENT_LIST: `${API_URL}/customer/category-management/category-offer-list`,
  CITY_LOOKUPS: `${API_URL}/basics/address/city-lookups`,
  STATE_LOOKUPS: `${API_URL}/basics/address/state-lookups`,
  TODAYS_DEAL: `${API_URL}/customer/product-management/today-deal`,
  POPULAR_PRODUCT: `${API_URL}/customer/product-management/popular-products`,
  BEST_SELLING_PRODUCT: `${API_URL}/customer/product-management/best-selling-products`,
  CREATE_ACCOUNT: `${API_URL}/customer/account/signup`,
  OTP_VERIFY: `${API_URL}/customer/account/verify-otp`,
  OTP_VERIFY_PHONE: `${API_URL}/customer/account/verify-phone-otp`,
  RESEND_OTP: `${API_URL}/customer/account/resend-otp`,
  SEND_OTP: `${API_URL}/customer/account/send-otp`,
  BRAND_URL: `${API_URL}/customer/brand-management/brand-list`,
  PRODUCT_LIST: `${API_URL}/customer/product-management/product-list`,
  FORGET_PASSWORD: `${API_URL}/customer/account/forget-password`,
  PRODUCT_DETAILS: `${API_URL}/customer/product-management/product-details`,
  ADDTO_CART: `${API_URL}/customer/cart-management/addtocart`,
  CART_LIST: `${API_URL}/customer/cart-management/cart-list`,
  CHECKOUT_URL: `${API_URL}/customer/account/address`,
  CARD_ORDER_SUMMARY: `${API_URL}/customer/cart-management/cart-order-summary`,
  DELETE_CART_ITEM: `${API_URL}/customer/cart-management/addtocart`,
  EMPTY_CART: `${API_URL}/customer/cart-management/cart-list`,
  ADD_CUSTOMER_ADDRESS: `${API_URL}/customer/account/address`,
  SUB_SUBCATEGORY_LIST: `${API_URL}/customer/category-management/sub-subcategory-list`,
  ORDER_ADDRSS_AND_ADD_DELIVERY: `${API_URL}/customer/order-management/order-address-and-delivery`,
  CART_COUNT: `${API_URL}/customer/cart-management/cart-count`,
  ORDER_URL: `${API_URL}/customer/order-management/order`,
  WISHLIST_URL: `${API_URL}/customer/cart-management/wishlist`,
  ORDER_DETAILS_URL: `${API_URL}/customer/order-management/order-details`,
  GET_PROFILE: `${API_URL}/customer/account/profile`,
  FILTER_LIST_URL: `${API_URL}/customer/product-management/filter-list`,
  SETTING_URL: `${API_URL}/customer/account/setting`,
  TREEVIEW_URL: `${API_URL}/customer/category-management/category-list-treeview`,
  RESETPASSWORD_URL: `${API_URL}/customer/account/reset-password`,
  CHECK_RESETPASSWORD_URL: `${API_URL}/customer/account/reset-password-check-link`,
  PRODUCT_CASE_URL: `${API_URL}/customer/product-management/product-case-price`,
  REORDER_URL: `${API_URL}/customer/order-management/reorder/`,
  LANDING_PAGE_URL: `${API_URL}/customer/settings-management/homepage`,
  FAVOURITE_URL: `${API_URL}/customer/cart-management/`,
  ORDER_COUNT_URL: `${API_URL}/customer/order-management/order-count`,
  TIMESLOT_URL: `${API_URL}/customer/order-management/slot`,
  RESTRICT_DELIVERY_URL: `${API_URL}/customer/order-management/slot-check`,
  FOOTER_PAGE_URL: `${API_URL}/customer/settings-management/footer`,
  FOOTER_PAGELIST_URL: `${API_URL}/customer/footer/pages/`,
  CHECK_MINIMUM_ORDER: `${API_URL}/customer/order-management/check-minimum-order`,
  CARD_PAYMENT_DATA: `${API_URL}/customer/order-management/card-payment-data`,
  EXTRA_CHARGE: `${API_URL}/customer/settings-management/extra-charge`,
  HEADER_MENU_URL: `${API_URL}/customer/product-management/top-menu`,
  BASH_URL: API_URL,
  SOTRE_ADDRESS: `${API_URL}/customer/account/store-address`,
  TIMESLOT_VISILITY: `${API_URL}/customer/order-management/slot-available`,
  TIME_SLOT_URL: `${API_URL}/customer/order-management/slot-time`,
  SUBSCRIBE_URL: `${API_URL}/customer/account/subscribe`,
  USER_LIST_URL: `${API_URL}/customer/account/user-list`,
  USER_SUBSCRIBE_URL: `${API_URL}/customer/account/user_subscribe`,
  Guest_USER_SINGUP:`${API_URL}/customer/account/guest-user-signup`,
  Guest_USER_ADDRESS:`${API_URL}/customer/account/guest-user-address`,
  CUSTOMIZE_SETTING:`${API_URL}/customer/settings-management/dynamic-setting`,
  ORDER_MANAGEMENT: `${API_URL}/customer/order-management/`,
  MOST_ORDERED_URL: `${API_URL}/customer/product-management/most-ordered`,
};

export default APIURL_OBJECT;
