import { Skeleton } from "@mui/material";
import React from "react";
const CategoryCardLoader = () => {
  return (
    <div
      style={{
        margin: "5px",
        display: "flex",
        width: window.screen.availWidth <= 900 ? "45%" : "",
      }}
    >
      {" "}
      <Skeleton
        animation="wave"
        className="cat-img"
        variant="rounded"
        width={window.screen.availWidth <= 900 ? "100%" : 150}
        height={150}
      />
    </div>
  );
};

export default CategoryCardLoader;
