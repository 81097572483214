import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { TypographyTextColor } from "../UiDesign";
import { capitalize } from "../../../utils/renderUtils/renderUtil";

const FilterLIstSideBar = (props) => {
  const { FilterTypeData, Label, type } = props;

  
  return (
    <div className="list_category list-category-filter">
      <div className="list_head">
        <TypographyTextColor component="h4" Color="heading">{Label}</TypographyTextColor>
      </div>
      <div className="list_filter_data">
        <ul className="cat_data">
          {FilterTypeData && FilterTypeData.length > 0
            ? FilterTypeData.map((row, index) => {
                return (
                  <li>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          checked={
                            "checkboxChecked" in row
                              ? row.checkboxChecked
                              : false
                          }
                          onChange={() => {
                            type === "product_type"
                              ? props.handleChangeCheckbox(row.product_type)
                              : type === "brand__name"
                              ? props.handleChangeCheckbox(
                                  row.brand_id,
                                  row.brand__name
                                )
                              : type === "flavor"
                              ? props.handleChangeCheckbox(row.flavor)
                              : props.handleChangeCheckbox(row.kosher);
                          }}
                        />
                      }
                      label={
                        <TypographyTextColor component="span" Color="other">
                          {capitalize(row[type])}
                        </TypographyTextColor>
                      }
                    />
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
    </div>
  );
};

export default FilterLIstSideBar;
