import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import CategoriesModal from "./common/Category/categoriesModal";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import ItemDetailsModal from "./common/ProductDetail/item-details-modal";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import ApiUrls from "../utils/apiUtils/apiUrl";
import "../assets/css/new-home.css";
import {
  CategeriesListView,
  GuestLoginAthentication,
  SubCategeriesListView,
  CategoryManagementList,
  TodaysDealProductsList,
  PopularProductsList,
  BestSellingProductsList,
  ProductDetailsApi,
  BrandProductList,
  WishListFunctionlity,
  AddToCartFunctionlity,
  UpdateCartFunctionlity,
  LandingPageApi,
  CategoryTreeViewApi,
  ProductsListListingPage,
} from "../actions/home/homeAction";
import {
  DeleteCrtIemFunctionlity,
  CartListFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import { OrderCountApi } from "../actions/order/orderAction";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Zoom,
  MenuItem,
} from "@mui/material";
import {
  CommonAddQtyFunction,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  commonPageTitel,
  RefreshApiToken,
  getSchedule_zip,
  addDefaultImageSrc,
  sweetAlertBox,
  slugifyStr,
  checkImageUrls,
  SinglecheckImageUrls,
} from "../utils/renderUtils/renderUtil";
import Commonsnackbar from "./common/commonsnackbar";
import {
  favoriteLevelListFunctionlity,
  AddFavoriteProductFunctionlity,
  RemoveFavouriteProduct,
} from "../actions/favorite/favoriteAction";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Loginpopup from "./login/Loginpopup";
import {
  GetallAddress,
  GetSettingsData,
} from "../actions/profile/profileAction";
import BannerHome from "./HomePage/BannerHome";
import CategoryHome from "./HomePage/CategoryHome";
import OfferImage from "./HomePage/OfferImage";
import CommonProductview from "./HomePage/CommonProductview";
import CommonAdvertisementDialoag from "./HomePage/CommonAdvertisementDialoag";
import { BiSolidRightArrow, BiSolidLeftArrow } from "react-icons/bi";
import { ScrollColorTypography, TypographyTextColor } from "./common/UiDesign";
import CustomPreloader from "./common/Loader/CustomPreloader";
import ItemLoaderCard from "./common/Loader/ItemLoaderCard";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const UpdateAPIDebounced = AwesomeDebouncePromise(UpdateCartFunctionlity, 50);
const brandlistLimit_screen =
  window.screen.availWidth <= 760 ? 3 : window.screen.availWidth <= 900 ? 4 : 6;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 0,
  },
}));
class NewHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      addclass: false,
      isLogin: false,
      open: false,
      open_inner: false,
      open_subDetails: false,
      openSearch: false,
      openCategory: false,
      addTocart: true,
      addCount: false,
      itemCount: 1,
      itemDetails: false,
      extra_info: false,
      pageName: "home_page",
      CategoryDetails: [],
      SubCategoryDetails: [],
      CategoryManagementList: [],
      TodayDealsProductList: [],
      PopularProductList: [],
      BestSellingProducts: [],
      ProductDetails: [],
      BrandProduct: [],
      SubcategoryName: "",
      CartCount: [],
      ProductCartArray: [],
      next_info: false,
      pre_info: false,
      categoryLoader: false,
      todayDealLoder: true,
      propularProductLoader: true,
      bestsellingProductLoader: true,
      brandProductLoader: true,
      cartLoader: false,
      productDetailsLoader: false,
      categoryNames: [],
      disabled: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      listOpen: false,
      next_rec: false,
      prev_rec: false,
      error: false,
      chack_is_case: [],
      homecartItemList: [],
      SubTotal: 0,
      CategoryOption: [],
      SettingLandingPage: [],
      favoriteLevelData: [],
      bannerImage: false,
      offerImgView: "",
      offerImgLink: "",

      bannerImage1: "",
      bannerImageLink1: "",
      hours: 11,
      minutes: 60,
      seconds: 0,
      setopen0: localStorage.getItem("isWebsiteVisited") !== "true",
      setopen1: localStorage.getItem("isWebsiteVisited") !== "true",
      setopen2: localStorage.getItem("isWebsiteVisited") !== "true",
      ordercount_message: "",
      orderCountSlag: "",
      container: [],
      favaritelevel_id: "",
      containerLength: 0,
      containerValue: "",
      container_type: "",
      pro_item_details_ind: -1,
      AddtoCartBtn: true,
      inputField: false,
      headerinputField: false,
      pageTitel: "",
      special_filter: "all",
      special_filter_value: "",
      guest_login: false,
      logo: "",
      extraCharges: [],
      TreeViewIteamsHome: [],
      categoryIds: [],
      flavor: [],
      kosher: [],
      fruitType: [],
      slotName: "",
      setOpenLoginPopup: false,
      isslotDeliverypopupState_compwill: false,
      CategoryListLoader: true,
      BrandListPage: 1,
      brandListTotal: 0,
      toggleMenuCategory: false,
      emailSubscribe: "",
      mobileView: false,
      SideCategoryListLoader: false,
      restrictByOnhand: JSON.parse(localStorage.getItem("Store_address"))
        ?.restrict_by_onhand,
      is_store_close: JSON.parse(localStorage.getItem("Store_address"))
        ?.is_store_close,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
      categoryWiseProductList: [],
      CategoryproductLoader: false,
      currentsubCategory: [],
      isMobile: !(window.screen.availWidth > 900),
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.isLogin = this.loginOptions.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
    this.handleSubInnerOpenDialog = this.handleSubInnerOpenDialog.bind(this);
    this.handleSubInnerCloseDialog = this.handleSubInnerCloseDialog.bind(this);
    this.searchOpen = this.searchOpen.bind(this);
    this.categoryOpen = this.categoryOpen.bind(this);
    this.handlePopOpen = this.handlePopOpen.bind(this);
  }
  FetchAddress = () => {
    let formValue = { store_address: "" };
    GetallAddress(formValue, this.props.dispatch).then((res) => {
      let addressDetails = [];
      let defaultAddress = "";
      if (res.data && res.data.result) {
        addressDetails = res.data.result;
        for (let index = 0; index < addressDetails.length; index++) {
          if (addressDetails[index].isdefault) {
            defaultAddress = addressDetails[index];
            break;
          }
        }
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchAddress();
            }
          );
        }
      }
    });
  };

  CloseLoginDialog = (type) => {
    if (type === "close") {
      this.setState({ setOpenLoginPopup: false });
      localStorage.removeItem("tempProductData");
    } else {
      if (
        localStorage.getItem("frontend_animation") &&
        localStorage.getItem("frontend_animation") === "must_login_add_to_cart"
      ) {
        let tempData = JSON.parse(localStorage.getItem("tempProductData"));
        if (tempData !== null) {
          this.AddToCartFunc(
            tempData.product_id,
            tempData.added_quantity,
            tempData.is_case
          );
        }
        localStorage.removeItem("tempProductData");
        if (localStorage.getItem("slot")) {
          this.setState({ setOpenLoginPopup: false });
        } else {
          this.setState({
            setOpenLoginPopup: false,
            isslotDeliverypopupState_compwill: true,
          });
        }
      } else this.setState({ setOpenLoginPopup: false });
      if (localStorage.getItem("isLogin") === "true") {
        this.FetchSettingData();
        this.FetchAddress();
        this.LandingPagFunctionlity();
        this.FetchCategoryList();
        this.FetchCartItemListForHeader();
        this.FetchOrerCount();
        this.FetchCategoryTreeViewForHome();
        this.ExtraChargesFunctiolity();
      }
    }
  };
  OpenLoginPoup = () => {
    this.setState({ setOpenLoginPopup: true });
  };
  FetchSettingData = () => {
    let formValues = {};
    GetSettingsData(formValues, "").then((res) => {
      let settingData = [];
      if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSettingData();
            }
          );
        }
      } else if (res.data && res.data.result) {
        settingData = res.data.result;
      }
      this.setState({ emailSubscribe: settingData });
    });
  };
  resize = () => {
    this.setState({ mobileView: window.innerWidth <= 760 });
  };
  handleResize = (cardWidthData = "") => {
    let size = document.getElementById("allproduct").offsetWidth;
    const cardWidth = 185; // Fixed card width in pixels
    const gutter = 10; // Adjust as needed (space between cards)
    let actualcardWidth = this.state.cardWidth;
    const noOfCards = Math.floor(size / (cardWidth + gutter));

    // Adjust the card width to fit evenly in the container
    const adjustedCardWidth = `calc((100% - ${
      gutter * (noOfCards - 1)
    }px) / ${noOfCards})`;

    console.log(size);
    if (
      this.state.currentScreenSize !== size &&
      this.state.ParentcategoryIds !== undefined &&
      this.state.SettingLandingPage?.show_category_with_item === true
    ) {
      actualcardWidth = `calc(100%/${noOfCards})`;
      console.log(cardWidth);
      if (noOfCards !== 0) {
        if (document.getElementById("allproduct")?.offsetWidth <= 2500) {
          this.FetchProductList(
            this.state.ParentcategoryIds,
            true,
            1,
            noOfCards
          );
          this.setState({
            actualcardWidth,
            currentScreenSize:
              document.getElementById("allproduct")?.offsetWidth,
          });
        } else {
          this.setState({
            cardWidth: "185px",
            currentScreenSize:
              document.getElementById("allproduct")?.offsetWidth,
          });
          this.FetchProductList(
            this.state.ParentcategoryIds,
            true,
            1,
            noOfCards
          );
        }
      }
    }
  };
  componentDidMount(index) {
    window.scrollTo(0, 0);
    if (window.screen.availWidth >= 900)
      window.addEventListener("resize", this.handleResize);
    localStorage.removeItem("tipAmount");
    this.resize();
    const accessToken = localStorage.getItem("access_token");
    let LogoNav = this.state.logo;
    const isLogin = localStorage.getItem("isLogin");
    const accessTokenForLogo =
      accessToken !== "undefined"
        ? JSON.parse(localStorage.getItem("access_token"))
        : "";
    LogoNav = accessTokenForLogo ? accessTokenForLogo.logo : "";

    if (accessToken === "undefined" || accessToken === null) {
      this.HitGuestApiToken();
    } else if (accessToken && isLogin === "false") {
      this.HitGuestApiToken();
    } else if (accessToken && isLogin === "true") {
      this.FetchSettingData();
      this.LandingPagFunctionlity();
      this.FetchCategoryList();
      this.FetchCartItemListForHeader();
      this.FetchOrerCount();
      this.FetchCategoryTreeViewForHome();
      this.ExtraChargesFunctiolity();
    } else {
      this.FetchCartItemListForHeader();
    }

    this.setState({ logo: LogoNav });
    this.myInterval = setInterval(() => {
      const { seconds, minutes, hours } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(this.myInterval);
          } else {
            this.setState(({ minutes, hours }) => ({
              hours: hours - 1,
              minutes: minutes - 1,
              seconds: 59,
            }));
          }
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
    let web_customize = JSON.parse(
      localStorage.getItem("web_customize")
    )?.HomePage_setting;

    document.title =
      web_customize?.meta_title === "" || web_customize?.meta_title === null
        ? commonPageTitel(JSON.parse(localStorage.getItem("access_token")))
        : web_customize?.meta_title;
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.ExtraChargesFunctiolity();
          });
        }
      }
      this.setState({ extraCharges: extraCharges });
    });
  };
  onbuildForTreeData(arr, parent) {
    if (!Array.isArray(arr)) return;
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id === parent) {
        var childItem = this.onbuildForTreeData(arr, arr[i].id);

        if (childItem.length) {
          arr[i].childItem = childItem;
        }
        out.push(arr[i]);
      }
    }
    out.sort((a, b) => a.name.localeCompare(b.name));
    return out;
  }
  FetchCategoryTreeViewForHome = (loader = true) => {
    let formValues = getSchedule_zip();
    this.setState({ SideCategoryListLoader: loader });
    CategoryTreeViewApi(formValues, this.props.dispatch).then((res) => {
      let TreeViewIteamsHome = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        TreeViewIteamsHome = res.data.result;
        var tree_data = this.onbuildForTreeData(TreeViewIteamsHome, null);
        tree_data.sort(function (a, b) {
          return a.cat_order - b.cat_order;
        });

        CategoryOption.push({ label: "All Categories", value: "" });
        for (let i = 0; i < tree_data.length; i++) {
          CategoryOption.push({
            label: tree_data[i].name,
            value: tree_data[i].id,
          });
        }
        this.setState({
          TreeViewIteamsHome: TreeViewIteamsHome,
          CategoryListLoader: false,
          SideCategoryListLoader: false,
          tree_data: tree_data,
          CategoryOption,
        });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryTreeViewForHome();
            }
          );
        }
      }
    });
  };

  FetchDataForPriceRangeHome = (
    price_min,
    price_max,
    category_id,
    has_child
  ) => {
    let brandIds = [];
    let filter_Kosher = [];
    let filter_Flavor = [];
    if (price_max > 0) {
      brandIds = this.state.brandIds;
      filter_Kosher = this.state.filterByKosher;
      filter_Flavor = this.state.filterByFlavor;
    }
    this.setState({
      min_price: price_min,
      max_price: price_max,
      category_id: category_id,
      ProductList: [],
      brandIds: brandIds,
      brandName: [],
      filterByFlavor: filter_Flavor,
      flavor: [],
      kosher: [],
      filterByKosher: filter_Kosher,
      page: 1,
    });
  };

  handleAdvertiseModalClose = (i) => {
    localStorage.setItem("isWebsiteVisited", true);
    this.setState({ [`setopen${i}`]: false });
  };

  handleOrderCountModalClose = () => {
    this.setState({ orderCountSlag: false });
  };

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  FetchOrerCount = () => {
    let formValues = {};
    OrderCountApi(formValues, this.props.dispatch).then((res) => {
      let ordercount_message = "";
      let orderCountSlag = "";
      if (res.data) {
        ordercount_message = res.data.message;
        orderCountSlag = res.data.flag;
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchOrerCount();
            }
          );
        }
      }
      this.setState({
        ordercount_message: ordercount_message,
        orderCountSlag: orderCountSlag,
      });
    });
  };

  FetchCartItemListForHeader = (loader = true) => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: loader });

    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let homecartItemList = [];
      let CartCount = [];
      if (res.data && res.data.result) {
        const result = res.data.result;

        if (result.length > 0) {
             result.forEach((item) => {
            homecartItemList.push(item);
            SubTotal += Number(item.total_price);
          });
        }

          if (res.data.total > 0) {
            CartCount.item = res.data.total;
            CartCount.price = Number(res.data.total_subtotal).toFixed(2);
          }
         
          this.setState({
            homecartItemList: homecartItemList,
            SubTotal: SubTotal.toFixed(2),
            cartListOpen: true,
            CartCount: CartCount,
            cartLoader: false,
          });
          // });
        } else if (res.errors && res.errors._error.status === 401) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          if (refreshToken) {
            RefreshApiToken(refreshToken.refresh_token, this, (myThis) => {
              myThis.FetchCartItemListForHeader();
            });
          }
        }
        this.setState({ cartLoader: false });
      
     
    });
  };

  LandingPagFunctionlity = () => {
    let formValues = {};
    this.setState({ CategoryListLoader: true });
    LandingPageApi(formValues, this.props.dispatch).then((res) => {
      let SettingLandingPage = [];
      let bannerImage1 = "";
      let bannerImageLink1 = "";
      let isMobile = this.state.isMobile;

      if (res.data && res.data.result) {
        SettingLandingPage = res.data.result;
        if (
          SettingLandingPage &&
          SettingLandingPage.category_offer_show === true &&
          isMobile === false
        ) {
          this.FetchCategeoryManagementList();
        } else if (
          SettingLandingPage.mobile_category_offer_show === true &&
          isMobile === true
        ) {
          this.FetchCategeoryManagementList();
        }
        if (
          (SettingLandingPage?.today_deals_show === true &&
            isMobile === false) ||
          (SettingLandingPage?.mobile_today_deals_show === true &&
            isMobile === true)
        ) {
          this.FetchTodaysDealsProductList();
        }
        if (
          (SettingLandingPage?.popular_products_show === true &&
            isMobile === false) ||
          (SettingLandingPage?.moblie_popular_products_show === true &&
            isMobile === true)
        ) {
          this.FetchPopularProductList();
        }
        if (
          (SettingLandingPage?.best_selling_products_show === true &&
            isMobile === false) ||
          (SettingLandingPage?.mobile_best_selling_products_show === true &&
            isMobile === true)
        ) {
          this.FetchBestSellingProductList();
        }
        if (
          (SettingLandingPage.brand_show === true && isMobile === false) ||
          (SettingLandingPage.mobile_brand_show === true && isMobile === true)
        ) {
          this.FetchBrandList(brandlistLimit_screen, this.state.BrandListPage);
        }

        let FinalBannerImage =
          SettingLandingPage && SettingLandingPage.banner_image
            ? SettingLandingPage.banner_image.split(",")
            : "";
        let FinalBannerLink =
          SettingLandingPage && SettingLandingPage.banner_image_link
            ? SettingLandingPage.banner_image_link.split(",")
            : "";

        var FinalBannerFilter =
          FinalBannerImage?.length > 0
            ? FinalBannerImage.filter(function (e) {
                return e !== "";
              })
            : [];

        bannerImage1 = FinalBannerFilter;
        bannerImageLink1 = FinalBannerLink;

        SettingLandingPage.popup_data = SettingLandingPage?.popup_data
          .sort((a, b) => a.id - b.id)
          .slice(0, 3);
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.LandingPagFunctionlity();
            }
          );
        }
      }
      this.setState({
        guest_login: true,
        SettingLandingPage: SettingLandingPage,
        offerImgView: SettingLandingPage?.offer_image,
        offerImgLink: SettingLandingPage?.offer_image_link,
        bannerImage1: bannerImage1,
        bannerImageLink1: bannerImageLink1,
        CategoryListLoader: false,
      });
    });
  };

  toggleMenuCategory() {
    this.setState({ toggleMenuCategory: !this.state.toggleMenuCategory });
  }
  loginOptions() {
    this.setState({ isLogin: !this.state.isLogin });
  }
  handleOpenDialog() {
    this.setState({ open: true, open_inner: false, parent_onlyid: [] });
  }
  handleInnerOpenDialogHome(
    e,
    subcategory_name,
    has_child,
    level,
    parent_id,
    data
  ) {
    if (has_child === true) {
      this.props.history.push({
        ...this.props.history.location.state,
        state: {
          parentCategorydata: data,
          category_id: e,
          parent_id: parent_id,
        },
      });
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      if (data === undefined) {
        this.props.history.push({
          pathname: "/listing/" + e,
          state: {
            category_id: e,
            parent_id: parent_id,
            parentIds: this.state.parentIds,
            parent_onlyid: this.state.parent_onlyid,
            parent_level: this.state.parent_level,
            slotName: this.state.slotName,
          },
        });
      } else
        this.props.history.push({
          pathname: "/listing/" + e,
          state: {
            category_id: e,
            parent_id: parent_id,
            parentIds: this.state.parentIds,
            parent_onlyid: this.state.parent_onlyid,
            parent_level: this.state.parent_level,
            slotName: this.state.slotName,
            categoryviewdata: data,
            selectedgroupcat: { category: [data], parent: data },
          },
        });
    }
  }
  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  handleCloseDialog() {
    this.setState({ open: false, open_inner: false });
  }
  handleSubInnerOpenDialog() {
    this.setState({ open_subDetails: true });
  }
  handleSubInnerCloseDialog() {
    this.setState({ open_subDetails: true });
  }
  searchOpen() {
    this.setState({ openSearch: !this.state.openSearch });
  }
  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }

  openAddCount = (
    product_id,
    added_quantity,
    is_case,
    addTocartQty = 1,
    container_type
  ) => {
    const frontend_animation = localStorage.getItem("frontend_animation");
    if (
      frontend_animation &&
      frontend_animation === "must_login_add_to_cart" &&
      localStorage.getItem("isLogin") === "false"
    ) {
      this.OpenLoginPoup();
      localStorage.setItem(
        "tempProductData",
        JSON.stringify({
          product_id,
          added_quantity,
          is_case,
          categoryindex: "",
          addTocartQty,
          container_type,
        })
      );
    } else if (
      localStorage.getItem("slot") === undefined ||
      (localStorage.getItem("slot") === null &&
        frontend_animation &&
        frontend_animation !== "open" &&
        frontend_animation !== "no_delivery_pickup")
    ) {
      this.setState({ isslotDeliverypopupState_compwill: true });
    } else {
      if (added_quantity === "") {
        this.setState({ inputField: true });
        var el = document.getElementById(`${product_id}`);
        el.value = "";
        var event = new Event("input", { target: el, bubbles: true });
        return el ? el.dispatchEvent(event) : false;
      }

      added_quantity = parseInt(added_quantity);
      addTocartQty = parseInt(addTocartQty);
      if (added_quantity > 0 && addTocartQty === 0) {
        this.AddToCartFunc(product_id, added_quantity, is_case);
      } else if (added_quantity > 0 && addTocartQty >= 1) {
        this.UpdateCartItemFunctionlity(
          product_id,
          added_quantity,
          is_case,
          container_type
        );
      } else {
        this.deleteCartItem(product_id, added_quantity, is_case);
      }
      this.setState({ inputField: false });
    }
  };

  changeInputBoxValue = () => {
    this.setState({ inputField: false });
  };

  AddToCartFunc = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: 1,
      is_case: is_case,
    };
    let TodayDealsProductList = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    let homecartItemList = this.state.homecartItemList;
    AddToCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.AddToCartFunc(product_id, added_quantity, is_case);
              // myThis.commonFunctionLity(product_id, added_quantity, is_case);
            }
          );
        }
      } else {
        // this.FetchCartCount();
        this.FetchCartItemListForHeader();
        this.commonFunctionLity(product_id, added_quantity, is_case);
      }
      this.setState({
        PopularProductList,
        TodayDealsProductList,
        BestSellingProducts,
        homecartItemList,
      });
    });
  };

  UpdateCartItemFunctionlity = async (
    product_id,
    added_quantity,
    is_case,
    container_type,
    index = 0
  ) => {
    let formValue = {};
    if (product_id !== null)
      formValue = {
        product_id: product_id,
        quantity: added_quantity,
        is_case: is_case,
        container_type:
          typeof container_type === "string" ? container_type : "",
      };
    else {
      let homecartItemList = this.state.homecartItemList;
      formValue = {
        cart_id: homecartItemList[index].id,
        is_unlist_item: true,
        quantity: added_quantity,
        is_case: is_case,
        container_type:
          typeof container_type === "string" ? container_type : "",
      };
    }
    let TodayDealsProductList = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    let homecartItemList = this.state.homecartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    await UpdateAPIDebounced(formValue, this.props.dispatch).then((res) => {
      if (res?.errors?.status === 400) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else if (res?.errors?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.UpdateCartItemFunctionlity(
              product_id,
              added_quantity,
              is_case,
              container_type,
              index
            );
          });
        }
      } else {
        this.commonFunctionLity(product_id, added_quantity, is_case);
        for (let i = 0; i < homecartItemList.length; i++) {
          if (homecartItemList[i].product_id === product_id) {
            homecartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(homecartItemList[i].total);
        }
        this.FetchCartItemListForHeader();
      }
      this.setState({
        disabled: false,
        PopularProductList,
        TodayDealsProductList,
        BestSellingProducts,
        homecartItemList,
        SubTotal: SubTotal,
      });
    });
  };

  commonFunctionLity = (product_id, added_quantity, is_case) => {
    let TodayDealsProductList = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    let categoryWiseProductList = this.state.categoryWiseProductList;
    var Proind = PopularProductList.findIndex((p) => p.id === product_id);
    var TodayProind = TodayDealsProductList.findIndex(
      (p) => p.id === product_id
    );
    var BesSellProind = BestSellingProducts.findIndex(
      (p) => p.id === product_id
    );

    let Categoryindx = 0;
    let Productindx = 0;
    let ckey = "";
    for (let i = 0; i < categoryWiseProductList.length; i++) {
      for (let key in categoryWiseProductList[i]) {
        let itemList = categoryWiseProductList[i][key].result;
        for (let j = 0; j < itemList.length; j++) {
          if (itemList[j].id === product_id) {
            ckey = key;
            Productindx = j;
            Categoryindx = i;

            break;
          }
        }
      }
    }
    console.log(Categoryindx, Productindx, ckey);
    if (ckey !== "") {
      var added_qtyInd =
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity_is_case"
        ].indexOf(is_case);
      if (added_qtyInd > -1) {
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity"
        ][added_qtyInd] = added_quantity;
      } else {
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity"
        ].push(added_quantity);
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity_is_case"
        ].push(is_case);
      }
    }
    if (Proind > -1 && added_quantity > -1) {
      var added_qtyInd =
        PopularProductList[Proind]["added_quantity_is_case"].indexOf(is_case);
      if (added_qtyInd > -1) {
        PopularProductList[Proind]["added_quantity"][added_qtyInd] =
          added_quantity;
      } else {
        PopularProductList[Proind]["added_quantity"].push(added_quantity);
        PopularProductList[Proind]["added_quantity_is_case"].push(is_case);
      }
    }
    if (TodayProind > -1 && added_quantity > -1) {
      var added_TodayqtyInd =
        TodayDealsProductList[TodayProind]["added_quantity_is_case"].indexOf(
          is_case
        );
      if (added_TodayqtyInd > -1) {
        TodayDealsProductList[TodayProind]["added_quantity"][
          added_TodayqtyInd
        ] = added_quantity;
      } else {
        TodayDealsProductList[TodayProind]["added_quantity"].push(
          added_quantity
        );
        TodayDealsProductList[TodayProind]["added_quantity_is_case"].push(
          is_case
        );
      }
    }
    if (BesSellProind > -1 && added_quantity > -1) {
      var added_BestSellqtyInd =
        BestSellingProducts[BesSellProind]["added_quantity_is_case"].indexOf(
          is_case
        );
      if (added_BestSellqtyInd > -1) {
        BestSellingProducts[BesSellProind]["added_quantity"][
          added_BestSellqtyInd
        ] = added_quantity;
      } else {
        BestSellingProducts[BesSellProind]["added_quantity"].push(
          added_quantity
        );
        BestSellingProducts[BesSellProind]["added_quantity_is_case"].push(
          is_case
        );
      }
    }
  };

  deleteCartItem = (
    product_id,
    added_quantity,
    is_case,
    container_type,
    index = 0
  ) => {
    let formValue = {};
    console.log(product_id);
    if (product_id !== null)
      formValue = {
        product_id: product_id,
        is_case: is_case,
        container_type: container_type ? container_type : "",
      };
    else {
      let homecartItemList = this.state.homecartItemList;
      formValue = {
        cart_id: homecartItemList[index].id,
        is_case: is_case,
        container_type:
          typeof container_type === "string" ? container_type : "",
      };
    }
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.commonDeleteCartItem(
          product_id,
          added_quantity,
          is_case,
          container_type
        );
       
        // this.FetchCartCount();
        this.FetchCartItemListForHeader();
       
      } else if (res.errors && res.errors.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.deleteCartItem(
                product_id,
                added_quantity,
                is_case,
                container_type,
                index
              );
            }
          );
        }
      }
    });
  };

  AddCountForHomecartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let homecartItemList = this.state.homecartItemList;
    if (icons === "icon-plus") {
      for (let i = 0; i < homecartItemList.length; i++) {
        if (homecartItemList[i].id === index) {
          // homecartItemList[i].qty = homecartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            homecartItemList[i].qty + 1,
            homecartItemList[i].is_case,
            homecartItemList[i].container_type,
            i
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < homecartItemList.length; i++) {
        if (homecartItemList[i].id === index) {
          homecartItemList[i].qty = homecartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              homecartItemList[i].qty,
              homecartItemList[i].is_case,
              homecartItemList[i].container_type,
              i
            );
          } else {
            this.deleteCartItem(
              product_id,
              homecartItemList[i].qty,
              homecartItemList[i].is_case,
              homecartItemList[i].container_type,
              i
            );
          }
        }
      }
    } else {
      for (let i = 0; i < homecartItemList.length; i++) {
        if (homecartItemList[i].id === index) {
          homecartItemList[i].qty = added_quantity
            ? added_quantity
            : homecartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              homecartItemList[i].qty,
              homecartItemList[i].is_case,
              homecartItemList[i].container_type,
              i
            );
          } else {
            this.deleteCartItem(
              product_id,
              homecartItemList[i].qty,
              homecartItemList[i].is_case,
              homecartItemList[i].container_type,
              i
            );
          }
        }
      }
      // this.setState({headerinputField: false})
    }
    this.setState({
      homecartItemList: homecartItemList,
      headerinputField: false,
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  commonDeleteCartItem = (
    product_id,
    added_quantity,
    is_case,
    container_type
  ) => {
    let TodayDealsProductList = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    let homecartItemList = this.state.homecartItemList;
    let categoryWiseProductList = this.state.categoryWiseProductList;
    let SubTotal = 0;
    var Proind = PopularProductList.findIndex((p) => p.id === product_id);
    var TodayProind = TodayDealsProductList.findIndex(
      (p) => p.id === product_id
    );
    var BesSellProind = BestSellingProducts.findIndex(
      (p) => p.id === product_id
    );
    var CartListProind = homecartItemList.findIndex(
      (p) => p.product_id === product_id
    );
    let Categoryindx = 0;
    let Productindx = 0;
    let ckey = "";
    for (let i = 0; i < categoryWiseProductList.length; i++) {
      for (let key in categoryWiseProductList[i]) {
        let itemList = categoryWiseProductList[i][key].result;
        for (let j = 0; j < itemList.length; j++) {
          if (itemList[j].id === product_id) {
            ckey = key;
            Productindx = j;
            Categoryindx = i;

            break;
          }
        }
      }
    }
    if (CartListProind > -1 && added_quantity < 1) {
      SubTotal += Number(homecartItemList[CartListProind]["total"]);
      homecartItemList.splice(CartListProind, 1);
    }
    if (ckey !== "") {
      var added_qtyInd =
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity_is_case"
        ].indexOf(is_case);
      if (added_qtyInd > -1) {
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity"
        ][added_qtyInd] = added_quantity;
      } else {
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity"
        ].push(0);
        categoryWiseProductList[Categoryindx][ckey].result[Productindx][
          "added_quantity_is_case"
        ].push(is_case);
      }
    }
    if (Proind > -1 && added_quantity > -1) {
      var added_qtyInd =
        PopularProductList[Proind]["added_quantity_is_case"].indexOf(is_case);
      if (added_qtyInd > -1) {
        PopularProductList[Proind]["added_quantity"][added_qtyInd] =
          added_quantity;
      } else {
        PopularProductList[Proind]["added_quantity"].push(0);
        PopularProductList[Proind]["added_quantity_is_case"].push(is_case);
      }
    }
    if (TodayProind > -1 && added_quantity > -1) {
      var added_TodayqtyInd =
        TodayDealsProductList[TodayProind]["added_quantity_is_case"].indexOf(
          is_case
        );
      if (added_TodayqtyInd > -1) {
        TodayDealsProductList[TodayProind]["added_quantity"][
          added_TodayqtyInd
        ] = added_quantity;
      } else {
        TodayDealsProductList[TodayProind]["added_quantity"].push(0);
        TodayDealsProductList[TodayProind]["added_quantity_is_case"].push(
          is_case
        );
      }
    }
    if (BesSellProind > -1 && added_quantity > -1) {
      var added_BestSellqtyInd =
        BestSellingProducts[BesSellProind]["added_quantity_is_case"].indexOf(
          is_case
        );
      if (added_BestSellqtyInd > -1) {
        BestSellingProducts[BesSellProind]["added_quantity"][
          added_BestSellqtyInd
        ] = added_quantity;
      } else {
        BestSellingProducts[BesSellProind]["added_quantity"].push(0);
        BestSellingProducts[BesSellProind]["added_quantity_is_case"].push(
          is_case
        );
      }
    }
    this.setState({
      TodayDealsProductList,
      PopularProductList,
      BestSellingProducts,
      ProductCartArray: this.state.ProductCartArray,
      SubTotal,
    });
  };

  addQuentityForaddTocart = (
    targetValue,
    added_qty_is_case,
    container_type
  ) => {
    targetValue = parseInt(targetValue);
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    if (!targetValue) {
      this.setState({ inputField: true });
      var el = document.getElementById(`${ProductDetails.id}`);
      el.value = "";
      var event = new Event("input", { target: el, bubbles: true });
      return el ? el.dispatchEvent(event) : false;
    }
    let result;

    if (targetValue > 0) {
      result = CommonAddQtyFunction(
        ProductDetails,
        targetValue,
        added_qty_is_case
      );
      if (result) {
        ProductDetails = result;
        pro_item_details_ind = 0;
        this.UpdateCartItemFunctionlity(
          ProductDetails.id,
          targetValue,
          added_qty_is_case,
          container_type
        );
      }
    } else {
      this.deleteCartItem(
        ProductDetails.id,
        targetValue,
        added_qty_is_case,
        container_type
      );
    }

    this.setState({ ProductDetails: ProductDetails, pro_item_details_ind });
  };

  increaseItemCount = () => {
    this.setState({ itemCount: this.state.itemCount + 1 });
  };

  handlePopOpen(value, slug, next_info, pre_info, index) {
    console.log(value, slug, next_info, pre_info, index);

    if (this.state.mobileView) {
      this.props.history.push({
        pathname: "/product/" + slug,
        state: {
          // addTocart_btn: this.state.AddtoCartBtn,
          special_filter: this.state.special_filter,
          special_filter_value: this.state.special_filter_value,
          category_id: this.state.category_id,
          selectedChilditem: this.state.selectedChilditem,
          listingFormValues: this.state.listingFormValues,
          ProductListData: this.state.ProductList,
          selectedProductindex: index,
          total: this.state.total,
        },
      });
    } else {
      console.log("hitt");
      this.setState({
        itemDetails: true,
        selectedProductindex: index,
        is_prev_rec: pre_info,
        is_next_rec: next_info,
      });
      this.FetchProdutDetails(
        value,
        slug,
        next_info,
        pre_info,
        this.state.special_filter,
        this.state.special_filter_value
      );
    }
  }
  handlePopClose(product_id, added_quantity, added_quantity_is_case, is_case) {
    this.setState({ AddtoCartBtn: true });
    let TodayDealsProductList = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;

    if (added_quantity > 0) {
      for (let i = 0; i < TodayDealsProductList.length; i++) {
        if (TodayDealsProductList[i].id === product_id) {
          TodayDealsProductList[i].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          TodayDealsProductList[i].added_quantity_is_case[
            this.state.pro_item_details_ind
          ] = added_quantity_is_case;
        }
      }
      for (let j = 0; j < PopularProductList.length; j++) {
        if (PopularProductList[j].id === product_id) {
          PopularProductList[j].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          PopularProductList[j].added_quantity_is_case[
            this.state.pro_item_details_ind
          ] = added_quantity_is_case;
        }
      }
      for (let k = 0; k < BestSellingProducts.length; k++) {
        if (BestSellingProducts[k].id === product_id) {
          BestSellingProducts[k].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          BestSellingProducts[k].added_quantity_is_case[
            this.state.pro_item_details_ind
          ] = added_quantity_is_case;
        }
      }
    } else {
      for (let i = 0; i < TodayDealsProductList.length; i++) {
        if (TodayDealsProductList[i].id === product_id) {
          TodayDealsProductList[i].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          TodayDealsProductList[i].is_case = is_case;
        }
      }
      for (let j = 0; j < PopularProductList.length; j++) {
        if (PopularProductList[j].id === product_id) {
          PopularProductList[j].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          PopularProductList[j].is_case = is_case;
        }
      }
      for (let k = 0; k < BestSellingProducts.length; k++) {
        if (BestSellingProducts[k].id === product_id) {
          BestSellingProducts[k].added_quantity[
            this.state.pro_item_details_ind
          ] = added_quantity;
          BestSellingProducts[k].is_case = is_case;
        }
      }
    }
    this.FetchOrerCount();
    this.FetchCartItemListForHeader();
    this.setState({
      itemDetails: false,
      TodayDealsProductList,
      PopularProductList,
      BestSellingProducts,
      chack_is_case: [],
    });
  }

  FetchTodaysDealsProductList = () => {
    let formValues = getSchedule_zip();
    formValues.limit = window.screen.availWidth <= 1200 ? 5 : 8;
    this.setState({
      todayDealLoder: true,
    });
    TodaysDealProductsList(formValues, this.props.dispatch).then((res) => {
      let todaysDealProducts = [];
      if (res.data && res.data.result) {
        todaysDealProducts = res.data.result;
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchTodaysDealsProductList();
            }
          );
        }
      }
      this.setState({
        TodayDealsProductList: todaysDealProducts,
        todayDealLoder: false,
      });
    });
  };

  FetchPopularProductList = () => {
    let formValues = getSchedule_zip();

    formValues.limit = window.screen.availWidth <= 1200 ? 5 : 8;
    formValues.page = 1;
    this.setState({ propularProductLoader: true });
    PopularProductsList(formValues, this.props.dispatch).then((res) => {
      let popularProducts = [];
      if (res.data && res.data.result) {
        popularProducts = res.data.result;
        // popularProducts.forEach((ele) => {
        //   let localUrl =
        //     CLOUD_URL +
        //     "local/" +
        //     JSON.parse(localStorage.getItem("Store_address"))?.id +
        //     "/product/" +
        //     slugifyStr(ele.item_code);
        //   let globalUrl =
        //     CLOUD_URL + "global/product/" + slugifyStr(ele.item_code);
        //   let currentUrl =
        //     ele.main_image !== null
        //       ? ele.main_image[0] === "/"
        //         ? ApiUrls.BASH_URL + "/media" + ele.main_image
        //         : ele.main_image
        //       : NO_IMAGE;
        //   console.log(localUrl, globalUrl);
        //   checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
        //     ele.main_image = url.url;
        //     ele.valid = url.valid;
        //   });
        // });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchPopularProductList();
            }
          );
        }
      }
      this.setState({ PopularProductList: popularProducts });
      this.setState({ propularProductLoader: false });
    });
  };

  FetchBestSellingProductList = () => {
    let formValues = getSchedule_zip();
    formValues.limit = window.screen.availWidth <= 1200 ? 5 : 8;
    this.setState({ bestsellingProductLoader: true });
    BestSellingProductsList(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        // res.data.result.map((ele) => {});
        let bestSellingProducts = [];
        if (res.data && res.data.result) {
          bestSellingProducts = res.data.result;
        }
        this.setState({ BestSellingProducts: bestSellingProducts });
        this.setState({ bestsellingProductLoader: false });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchBestSellingProductList();
            }
          );
        }
      }
    });
  };

  FetchCategoryList = (loader = true) => {
    this.setState({
      categoryLoader: loader,
    });
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => a.cat_order - b.cat_order);
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      let ParentcategoryIds = categoryList.map((x) => x.id).join(",");
      if (
        (this.state.SettingLandingPage?.show_category_with_item === true &&
          this.state.isMobile === false) ||
        (this.state.SettingLandingPage?.mobile_show_category_with_item ===
          true &&
          this.state.isMobile === true)
      ) {
        this.FetchProductList(ParentcategoryIds, true, 1, 8, loader);
        this.handleResize();
      }
      this.setState({
        CategoryDetails: categoryList,
        ParentcategoryIds: ParentcategoryIds,
        categoryLoader: false,
        CategoryOption,
      });
    });
  };
  nextProductItemCategorywise = (productData) => {
    console.log("hitt");
    this.FetchProductList(
      productData.cat_data[0].category_id,
      false,
      productData.page + 1
    );
  };
  prevProductItemCategorywise = (productData) => {
    console.log("hitt");
    this.FetchProductList(
      productData.cat_data[0].category_id,
      false,
      productData.page - 1
    );
  };
  FetchProductList = (
    categoryIds,
    is_homepage,
    page = 1,
    limit = 8,
    loader = false
  ) => {
    let formValues = {
      limit: limit,
      page: page,
      product_type: "",
      kosher: "",
      flavor: "",
      brand_id: "",
      price_min: "",
      price_max: "",
      category_id: categoryIds,
      search: "",
      product_deal: "",
      top_menu: "",
      order: "name",
      is_homepage: is_homepage,
    };
    this.setState({
      CategoryproductLoader: loader,
      CategoryListLoader: loader,
    });
    let scheduledata = getSchedule_zip();
    formValues = { ...formValues, ...scheduledata };
    let currentList = this.state.categoryWiseProductList;
    if (currentList.length > 0) {
      for (let i = 0; i < currentList.length; i++) {
        for (let key in currentList[i]) {
          if (parseInt(key) === categoryIds) {
            // currentList[i][key].Loading = true;
            break;
          }
        }
      }
      this.setState({ categoryWiseProductList: currentList });
    }
    ProductsListListingPage(formValues, this.props.dispatch).then((res) => {
      let productList = [];
      let productlistLength = 0;
      let total = undefined;

      if (res.data && res.data.result_multiple_category) {
        productList = res.data.result_multiple_category;
        for (let i = 0; i < productList.length; i++) {
          Object.keys(productList[i]).forEach((ele) => {
            productList[i][ele].limit = limit;
            productList[i][ele].page = 1;
            productList[i][ele].Loading = false;
          });
        }

        this.setState({
          categoryWiseProductList: productList,
          CategoryproductLoader: false,
          CategoryListLoader: false,
        });
      } else if (res.data && res.data.result) {
        productList = res.data.result;
        for (let i = 0; i < currentList.length; i++) {
          for (let key in currentList[i]) {
            if (parseInt(key) === categoryIds) {
              currentList[i][key].page = page;
              currentList[i][key].result = productList;
              currentList[i][key].Loading = false;
              currentList[i][key].limit = limit;
              // currentList[i][key].result.map((item) => {
              //   let localUrl =
              //     CLOUD_URL +
              //     "local/" +
              //     JSON.parse(localStorage.getItem("Store_address"))?.id +
              //     "/product/" +
              //     slugifyStr(item.item_code);
              //   let globalUrl =
              //     CLOUD_URL + "global/product/" + slugifyStr(item.item_code);
              //   let currentUrl =
              //     item.main_image !== null
              //       ? item.main_image[0] === "/"
              //         ? ApiUrls.BASH_URL + "/media" + item.main_image
              //         : item.main_image
              //       : NO_IMAGE;

              //   return checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
              //     console.log(url);
              //     item.main_image = url.url;
              //     item.valid = url.valid;

              //   });
              // });
              break;
            }
          }
        }
        this.setState({ categoryWiseProductList: currentList });
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          // this.setState({ productList: "", productlistLength: 0, total: 0 });
          RefreshApiToken(refreshToken.refresh_token, myThis, (myThis) => {
            myThis.FetchProductList(categoryIds, is_homepage, page, limit);
          });
        }
      }
    });
  };

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res?.data?.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res?.errors?._error?.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(category_id, level);
            }
          );
        }
      }

      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parentIds,
      category_id,
      level
    );

    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchProdutDetails = (
    product_id,
    slug,
    next_info,
    pre_info,
    special_filter = "all",
    special_filter_value = ""
  ) => {
    let formValues = {
      product_id: product_id,
      slug: slug,
      extra_info: this.state.extra_info,
      next_info: "",
      pre_info: "",
      special_filter: special_filter,
      special_filter_value: special_filter_value ? special_filter_value : "",
    };
    this.setState({ productDetailsLoader: true });
    ProductDetailsApi(formValues, this.props.dispatch).then((res) => {
      let next_rec = false;
      let prev_rec = false;
      let ProductDetails = [];
      let categoryNames = [];
      let container = [];
      let containerVal = "";
      var is_checked = false;
      let AddtoCartBtn = this.state.AddtoCartBtn;
      let parseContainerVal = "";
      let pro_item_details_ind = this.state.pro_item_details_ind;
      if (res.data && res.data.result) {
        next_rec = res.data.next_rec;
        prev_rec = res.data.prev_rec;
        ProductDetails = res.data.result;
        categoryNames = res.data.category;

        containerVal = ProductDetails.container
          ? ProductDetails.container.split(",")
          : "";
        if (containerVal) {
          parseContainerVal = JSON.parse(containerVal);
          if (containerVal.length > 3) {
            container.push({ label: "Select", value: "" });
          }

          for (const property in parseContainerVal) {
            if (containerVal.length > 3) {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
            } else {
              container.push({
                label: property,
                value: parseContainerVal[property],
              });
            }

            if (property === ProductDetails.container_type[0]) {
              this.setState({
                containerValue: parseContainerVal[property],
                container_type: property,
              });
            }
          }
        }

        if (
          ProductDetails.added_quantity &&
          ProductDetails.added_quantity.length > 0
        ) {
          if (ProductDetails.added_quantity[0] !== 0) {
            AddtoCartBtn = false;
            pro_item_details_ind = 0;
            is_checked = ProductDetails.added_quantity_is_case[0];
          } else {
            ProductDetails.added_quantity = [];
            ProductDetails.added_quantity_is_case = [];
            ProductDetails.container_type = [];
            pro_item_details_ind = -1;
          }
        }
        this.setState({
          AddtoCartBtn: AddtoCartBtn,
          ProductDetails: ProductDetails,
          pro_item_details_ind: pro_item_details_ind,
          chack_is_case: is_checked,
          prev_rec,
          next_rec,
          categoryNames,
          container: container,
          containerLength: containerVal.length,
        });
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProdutDetails(
                product_id,
                slug,
                next_info,
                pre_info,
                special_filter,
                special_filter_value
              );
            }
          );
        }
      }
      this.setState({ productDetailsLoader: false });
    });
  };

  UpdateCartItemFunctionlityForCase = (product_id, quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: quantity,
      is_case: is_case,
    };
    let ProductDetails = this.state.ProductDetails;
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        ProductDetails.added_quantity = quantity;
      }
    });
  };

  FetchCategeoryManagementList = () => {
    let formValues = getSchedule_zip();
    CategoryManagementList(formValues, this.props.dispatch).then((res) => {
      let categeoryManagementList = [];
      if (res.data && res.data.result) {
        categeoryManagementList = res.data.result;
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategeoryManagementList();
            }
          );
        }
      }
      this.setState({ CategoryManagementList: categeoryManagementList });
    });
  };

  HitGuestApiToken = () => {
    let formValues = {};
    let logo = this.state.logo;
    GuestLoginAthentication(formValues).then((res) => {
      if (res.data) {
        localStorage.setItem("access_token", JSON.stringify(res.data));
        logo = res.data && res.data.logo ? res.data.logo : "";
        let web_customize = JSON.parse(
          localStorage.getItem("web_customize")
        )?.HomePage_setting;
        console.log(
          web_customize?.meta_title === "" || web_customize?.meta_title === null
            ? commonPageTitel(JSON.parse(localStorage.getItem("access_token")))
            : web_customize?.meta_title
        );
        document.title =
          web_customize?.meta_title === "" || web_customize?.meta_title === null
            ? commonPageTitel(JSON.parse(localStorage.getItem("access_token")))
            : web_customize?.meta_title;
        //document.title = commonPageTitel(
        //   JSON.parse(localStorage.getItem("access_token"))
        // );
        this.setState({ guest_login: res.data ? true : "", logo: logo });
        this.LandingPagFunctionlity();
        this.FetchOrerCount();
        this.ExtraChargesFunctiolity();
        this.FetchCategoryTreeViewForHome();
        if (
          localStorage.getItem("frontend_animation") &&
          localStorage.getItem("frontend_animation") !== "pickup_delivery"
        ) {
          this.FetchCartItemListForHeader();
          this.FetchPopularProductList();
        } else if (
          localStorage.getItem("frontend_animation") &&
          localStorage.getItem("frontend_animation") === "pickup_delivery" &&
          localStorage.getItem("slot")
        ) {
          this.FetchCartItemListForHeader();
          this.FetchPopularProductList();
        }
      }

      this.FetchCategoryList();
    });
  };
  FetchBrandList = (limit, page) => {
    let formValues = { limit: limit, page: page };
    this.setState({ brandProductLoader: true });
    BrandProductList(formValues, this.props.dispatch).then((res) => {
      let brandList = [];
      let brandListTotal = "";
      if (res.data && res.data.result) {
        brandList = res.data.result;
        brandListTotal = res.data.total;
        brandList.forEach((ele) => {
          // let localUrl =
          //   CLOUD_URL +
          //   "local/" +
          //   JSON.parse(localStorage.getItem("Store_address"))?.id +
          //   "/brand/" +
          //   slugifyStr(ele.name);
          let globalUrl = ele?.cloudinary_images_global;
          // let currentUrl =
          //   ele.icon !== null
          //     ? ele.icon[0] === "/"
          //       ? ApiUrls.BASH_URL + "/media" + ele.icon
          //       : ele.icon
          //     : NO_IMAGE;

          // checkImageUrls(localUrl, globalUrl, currentUrl).then((url) => {
          //   ele.icon = url.url;
          //   ele.valid = url.valid;
          // });
          if (ele.icon === "" || ele.icon === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.icon = ele?.cloudinary_images_global;
                ele.valid = true;
              } else {
                ele.icon = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.icon =
              ele.icon[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.icon
                : ele.icon;
            ele.valid = false;
          }
        });
      } else {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchBrandList(limit, page);
            }
          );
        }
      }
      this.setState({
        BrandProduct: brandList,
        brandProductLoader: false,
        BrandListPage: page,
        brandListTotal: brandListTotal,
      });
    });
  };

  AddWishlistFunctionility = (product_id, wishlist) => {
    let formValues = { product_id: product_id, wishlist: wishlist };
    WishListFunctionlity(formValues, this.props.dispatch).then((res) => {});
  };

  HandelChangeTrueWishList = (product_id, value) => {
    let login_status = JSON.parse(localStorage.getItem("isLogin"));
    this.setState({ isLogin: login_status });
    let TodayDealsProducts = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    for (let i = 0; i < TodayDealsProducts.length; i++) {
      if (TodayDealsProducts[i].id === product_id) {
        TodayDealsProducts[i].wishlist = value;
      }
    }
    for (let i = 0; i < PopularProductList.length; i++) {
      if (PopularProductList[i].id === product_id) {
        PopularProductList[i].wishlist = value;
      }
    }
    for (let i = 0; i < BestSellingProducts.length; i++) {
      if (BestSellingProducts[i].id === product_id) {
        BestSellingProducts[i].wishlist = value;
      }
    }

    this.setState({
      TodayDealsProducts,
      PopularProductList,
      BestSellingProducts,
    });
    if (login_status === true) {
      this.AddWishlistFunctionility(product_id, value);
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  handleChangeForShopByBrand = (brand_id, brand_name) => {
    this.props.history.push({
      pathname: "/listing/brand=" + brand_id,
      state: {
        brand_id: brand_id,
        brand_name: brand_name,
        slotName: this.state.slotName,
      },
    });
  };

  handleChangeRouteForDeal = (deal_name) => {
    if (isNaN(deal_name))
      this.props.history.push({
        pathname: "/listing/productdeal=" + deal_name,
        state: {
          deal_name: deal_name,
          slotName: this.state.slotName,
        },
      });
    else {
      this.props.history.push({
        pathname: "/listing/" + deal_name,
        state: {
          category_id: deal_name,
          parent_id: deal_name,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
          slotName: this.state.slotName,
        },
      });
    }
  };

  preProductItem = (product_id, slug, pre_info, next_info) => {
    this.setState({ AddtoCartBtn: true });
    if (product_id > 0) {
      this.FetchProdutDetails(
        product_id,
        slug,
        next_info,
        pre_info,
        this.state.special_filter,
        this.state.special_filter_value
      );
    }
  };

  nextProductItem = (product_id, slug, pre_info, next_info) => {
    this.setState({ AddtoCartBtn: true });

    this.FetchProdutDetails(
      product_id,
      slug,
      next_info,
      pre_info,
      this.state.special_filter,
      this.state.special_filter_value
    );
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");
        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      }
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
    });
  };

  ProductCaseFunctionlity = (product_id, is_case) => {
    let chack_is_case = this.state.chack_is_case;
    chack_is_case[product_id] = is_case;
    this.setState({ chack_is_case: chack_is_case });
  };

  addDefaultSubCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  FetchFavoriteLevelList = (product_id) => {
    let formValues = {
      lookup: true,
      page: 1,
      limit: 100,
      product_id: product_id ? product_id : "",
    };
    favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favoriteLevelData = [];
        if (res.data && res.data.result) {
          favoriteLevelData = res.data.result;
        } else {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchFavoriteLevelList(product_id);
              }
            );
          }
        }
        this.setState({ favoriteLevelData: favoriteLevelData });
      }
    );
  };

  addFavoiteProductFunctionlity = (
    favoritelabel_id,
    product_id,
    quantity,
    added_quantity_is_case
  ) => {
    let formValues = {
      product_id: product_id,
      favoritelabel_id: favoritelabel_id ? favoritelabel_id.toString() : "",
      quantity: quantity,
      is_case: added_quantity_is_case,
      note: "",
    };

    let TodayDealsProducts = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    AddFavoriteProductFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          for (let i = 0; i < TodayDealsProducts.length; i++) {
            if (
              TodayDealsProducts[i].id === product_id &&
              favoritelabel_id != ""
            ) {
              TodayDealsProducts[i].wishlist = true;
              TodayDealsProducts[i].favoriot_id = res.favorite_ids;
            }
          }
          for (let i = 0; i < PopularProductList.length; i++) {
            if (
              PopularProductList[i].id === product_id &&
              favoritelabel_id != ""
            ) {
              PopularProductList[i].wishlist = true;
              PopularProductList[i].favoriot_id = res.favorite_ids;
            }
          }
          for (let i = 0; i < BestSellingProducts.length; i++) {
            if (
              BestSellingProducts[i].id === product_id &&
              favoritelabel_id != ""
            ) {
              BestSellingProducts[i].wishlist = true;
              BestSellingProducts[i].favoriot_id = res.favorite_ids;
            }
          }
        }
        this.setState({
          TodayDealsProductList: TodayDealsProducts,
          PopularProductList: PopularProductList,
          BestSellingProducts,
        });
      }
    );
  };
  removeFromfavouriteProduct = (favoriteproduct_id, product_id) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.join(","),
    };
    let TodayDealsProducts = this.state.TodayDealsProductList;
    let PopularProductList = this.state.PopularProductList;
    let BestSellingProducts = this.state.BestSellingProducts;
    RemoveFavouriteProduct(formValues, this.props.dispatch).then((res) => {
      if (res) {
        for (let i = 0; i < TodayDealsProducts.length; i++) {
          if (TodayDealsProducts[i].id === product_id) {
            TodayDealsProducts[i].wishlist = false;
            TodayDealsProducts[i].favoriot_id = [];
          }
        }
        for (let i = 0; i < PopularProductList.length; i++) {
          if (PopularProductList[i].id === product_id) {
            PopularProductList[i].wishlist = false;
            PopularProductList[i].favoriot_id = [];
          }
        }
        for (let i = 0; i < BestSellingProducts.length; i++) {
          if (BestSellingProducts[i].id === product_id) {
            BestSellingProducts[i].wishlist = false;
            BestSellingProducts[i].favoriot_id = [];
          }
        }
      }
      this.setState({
        TodayDealsProductList: TodayDealsProducts,
        PopularProductList: PopularProductList,
        BestSellingProducts,
      });
    });
  };

  addDefaultBannerSrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  changeConatinerType = (event, type, myContainer = null) => {
    let text = "";
    var el = document.getElementById("exampleFormControlSelect1");

    if (type === "select") {
      text = el.options[el.selectedIndex].innerHTML;
    } else {
      text = myContainer;
    }
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    let chack_is_case = this.state.chack_is_case;
    let AddtoCart = this.state.AddtoCartBtn;
    var container_ind = ProductDetails.container_type.indexOf(text);
    if (container_ind > -1) {
      if (
        ProductDetails.added_quantity_is_case[container_ind] === chack_is_case
      ) {
        pro_item_details_ind = container_ind;
      }
      AddtoCart = false;
    } else {
      pro_item_details_ind = -1;
      AddtoCart = true;
    }

    this.setState({
      containerValue: event.target.value,
      ProductDetails,
      container_type: text,
      pro_item_details_ind: pro_item_details_ind,
      AddtoCartBtn: AddtoCart,
    });
  };

  commonFunctionForContainer = (
    added_qtyInd,
    added_quantity,
    added_quantity_is_case
  ) => {
    let ProductDetails = this.state.ProductDetails;
    let container_type = this.state.container_type;
    let pro_item_details = this.state.pro_item_details_ind;

    if (added_qtyInd > -1) {
      let added_ind = added_qtyInd;
      if (container_type) {
        var container_typeInd =
          ProductDetails["container_type"].indexOf(container_type);
        if (container_typeInd > -1) {
          ProductDetails["added_quantity"][container_typeInd] = added_quantity;
          pro_item_details = container_typeInd;
        } else {
          ProductDetails["container_type"].push(container_type);
          ProductDetails["added_quantity"].push(added_quantity);
          ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
          pro_item_details = ProductDetails["container_type"].length - 1;
        }
      } else {
        ProductDetails["added_quantity"][added_ind] = added_quantity;
        pro_item_details = added_qtyInd;
      }
    } else {
      ProductDetails["added_quantity"].push(added_quantity);
      ProductDetails["added_quantity_is_case"].push(added_quantity_is_case);
      if (container_type !== "") {
        ProductDetails["container_type"].push(container_type);
      }
      pro_item_details = ProductDetails["added_quantity"].length - 1;
    }
    this.setState({
      pro_item_details_ind: pro_item_details,
      AddtoCartBtn: false,
      ProductDetails,
    });
  };

  ProductCaseFunctionlityForItem = (added_qty_is_case, note) => {
    let ProductDetails = this.state.ProductDetails;
    let pro_item_details_ind = this.state.pro_item_details_ind;
    var check_ind =
      ProductDetails.added_quantity_is_case.indexOf(added_qty_is_case);
    if (check_ind > -1) {
      pro_item_details_ind = check_ind;
    } else {
      pro_item_details_ind = -1;
    }
    this.setState({ chack_is_case: added_qty_is_case, pro_item_details_ind });
  };

  hendleAddtoCartBtn = () => {
    this.setState({ AddtoCartBtn: true });
  };
  handleSlotName = (data) => {
    this.LandingPagFunctionlity();
    this.FetchCartItemListForHeader();
    this.FetchCategoryList();
    this.FetchPopularProductList();
    this.FetchCategoryTreeViewForHome();
    this.setState({ slotName: data });
  };
  closeisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: false });
  };
  openisslotDeliverypopupState_compwill = () => {
    this.setState({ isslotDeliverypopupState_compwill: true });
  };
  handleBrandList = (type) => {
    this.setState({ BrandProduct: [] });
    if (type === "next") {
      this.FetchBrandList(brandlistLimit_screen, this.state.BrandListPage + 1);
    } else if (type === "prev" && this.state.BrandListPage > 1) {
      this.FetchBrandList(brandlistLimit_screen, this.state.BrandListPage - 1);
    }
  };
  toggleMenu() {
    this.setState({ toggleMenu: !this.state.toggleMenu });
  }
  componentWillReceiveProps(nextprops) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  renderItem = (data) => {
    let tempData = [];
    data.map((val, i) => {
      tempData.push(
        <TypographyTextColor
          role="button"
          component={"span"}
          Color="heading"
          className="ml-2 home-category-menu"
          aria-describedby={`popover${i}`}
          onClick={() => this.handleChangeRouteForDeal(val.id)}
        >
          {val.name}
          <div className="sub-category-menu">
            {val.has_child ? (
              val.childItem.map((val) => {
                return (
                  <MenuItem
                    style={{ margin: 0 }}
                    onClick={() => this.handleChangeRouteForDeal(val.id)}
                  >
                    <TypographyTextColor component={"span"} Color="heading">
                      {" "}
                      {val.name}
                    </TypographyTextColor>
                  </MenuItem>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </TypographyTextColor>
      );
    });
    return tempData;
  };
  renderCategoryWiseProduct = () => {
    if (this.state.CategoryproductLoader)
      return (
        <div
          className="items-details item_listing_details item_listing_homepage"
          style={{ flexWrap: "wrap" }}
        >
          {[...Array(window.screen.availWidth <= 760 ? 6 : 24)].map((x, i) => (
            <div
              className="items"
              style={{ minWidth: "calc(100%/9)", flex: 1 }}
            >
              <ItemLoaderCard />
            </div>
          ))}
        </div>
      );
    else
      return this.state.categoryWiseProductList?.map((val, i) => {
        return Object.keys(val).map((ele, i) => {
          let category_id = val[ele].cat_data[0]?.category_id;
          let treeData = this.state.tree_data;
          let category = treeData.filter((x) => x.id === category_id);
          let subItem = category[0]?.has_child
            ? this.renderItem(category[0]?.childItem)
            : [];
          return (
            <>
              <CommonProductview
                subItem={subItem}
                cardWidth={this.state.cardWidth}
                nextProductItemCategorywise={(data) =>
                  this.nextProductItemCategorywise(data)
                }
                prevProductItemCategorywise={(data) =>
                  this.prevProductItemCategorywise(data)
                }
                productData={val[ele]}
                showNavigationBtn={true}
                viewTitle={val[ele].cat_data[0].category_name}
                handleChangeRouteForDeal={() =>
                  this.handleChangeRouteForDeal(
                    val[ele].cat_data[0].category_id
                  )
                }
                ProductLoader={val[ele].Loading}
                ProductList={val[ele].result}
                chack_is_case={this.state.chack_is_case}
                extraCharges={this.state.extraCharges}
                removeFromfavouriteProduct={(favoriot_id, product_id) =>
                  this.removeFromfavouriteProduct(favoriot_id, product_id)
                }
                addFavoiteLevelFunctionlity={(value, product_id) =>
                  this.addFavoiteLevelFunctionlity(value, product_id)
                }
                history={this.props.history}
                activePageName={this.props.location.pathname}
                addFavoiteProductFunctionlity={(
                  favoritelabel_id,
                  id,
                  added_quantity,
                  added_quantity_is_case
                ) =>
                  this.addFavoiteProductFunctionlity(
                    favoritelabel_id,
                    id,
                    added_quantity,
                    added_quantity_is_case
                  )
                }
                FetchFavoriteLevelList={(product_id) =>
                  this.FetchFavoriteLevelList(product_id)
                }
                HandelChangeTrueWishList={() => this.HandelChangeTrueWishList()}
                handlePopOpen={(id, slug, next, prev, index) =>
                  this.handlePopOpen(id, slug, next, prev, index)
                }
                openAddCount={(
                  id,
                  added_quantity,
                  added_quantity_is_case,
                  containertype
                ) =>
                  this.openAddCount(
                    id,
                    added_quantity,
                    added_quantity_is_case,
                    containertype
                  )
                }
                disabled={this.state.disabled}
                inputField={this.state.inputField}
                changeInputBoxValue={() => this.changeInputBoxValue()}
                ProductCaseFunctionlity={(id, event) =>
                  this.ProductCaseFunctionlity(id, event)
                }
                restrictByOnhand={this.state.restrictByOnhand}
                tree_data={this.state.tree_data}
                productCategory={val[ele].cat_data[0]}
                SettingLandingPage={this.state.SettingLandingPage}
                categoryData={category}
              />
            </>
          );
        });
      });
  };

  FetchCallAllAPi = () => {
    this.FetchCartItemListForHeader(false);
    this.FetchCategoryList(false);
    this.FetchCategoryTreeViewForHome(false);
  };

  render() {
    const {
      CategoryDetails,
      SubCategoryDetails,
      CategoryManagementList,
      TodayDealsProductList,
      PopularProductList,
      BestSellingProducts,
      ProductDetails,
      categoryNames,
      BrandProduct,
      CartCount,
      homecartItemList,
      SubTotal,
      CategoryOption,
      SettingLandingPage,
      favoriteLevelData,
      web_customize,
      isMobile,
    } = this.state;

    return (
      <>
        <div
          className="main_containerLoader"
          style={{ display: this.state.CategoryListLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>

        <div
          className="main-content"
          style={{
            display: !this.state.CategoryListLoader ? "block" : "none",
            overflow: "hidden",
          }}
        >
          <Header
            FetchCallAllAPi={() => this.FetchCallAllAPi()}
            CategoryDetails={
              CategoryDetails && CategoryDetails.length > 0
                ? CategoryDetails
                : ""
            }
            SideCategoryListLoader={this.state.SideCategoryListLoader}
            CategoryOption={CategoryOption}
            cartItemList={homecartItemList}
            handleOpenDialog={this.handleOpenDialog}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            FetchCartCount={() => this.FetchCartItemListForHeader()}
            cartLoader={this.state.cartLoader}
            FetchTodaysDealsProductList={() =>
              this.FetchTodaysDealsProductList()
            }
            FetchPopularProductList={() => this.FetchPopularProductList()}
            FetchBestSellingProductList={() =>
              this.FetchBestSellingProductList()
            }
            headerinputField={this.state.headerinputField}
            AddCountForcartList={(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            ) =>
              this.AddCountForHomecartList(
                product_id,
                added_quantity,
                is_case,
                icons,
                index,
                container_type
              )
            }
            SubTotal={SubTotal}
            changeheaderinputFieldValue={() =>
              this.changeheaderinputFieldValue()
            }
            logo={this.state.logo}
            extraCharges={this.state.extraCharges}
            handleInnerOpenDialog={(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            ) =>
              this.handleInnerOpenDialogHome(
                catId,
                subcategory_name,
                has_child,
                level,
                parent_id
              )
            }
            handleSlotNameData={(data) => this.handleSlotName(data)}
            OpenLoginPoup={() => this.OpenLoginPoup()}
            isslotDeliverypopupState_compwill={
              this.state.isslotDeliverypopupState_compwill
            }
            closeisslotDeliverypopupState_compwill={() =>
              this.closeisslotDeliverypopupState_compwill()
            }
            TreeViewIteamsHome={this.state.TreeViewIteamsHome}
            tree_data={this.state.tree_data}
          />
          <Loginpopup
            setOpenLoginPopup={this.state.setOpenLoginPopup}
            CloseLoginDialog={(type) => this.CloseLoginDialog(type)}
            isShowclose={true}
            history={this.props.history}
          />

          <div class="listing_inner_data">
            <div className="listing_main"></div>
            <div class="list_main_content-new" id="allproduct">
              <ScrollColorTypography
                component={"div"}
                className="main-data new-home"
                style={{
                  paddingTop:
                    web_customize !== null &&
                    web_customize?.top_marquee !== null &&
                    web_customize?.top_marquee !== "" &&
                    window.screen.availWidth > 900
                      ? "11rem"
                      : "",
                }}
              >
                {(SettingLandingPage?.banner_image_show === true &&
                  isMobile === false) ||
                (SettingLandingPage?.moblie_banner_image_show === true &&
                  isMobile === true) ? (
                  <BannerHome
                    bannerImage1={this.state.bannerImage1}
                    addDefaultBannerSrc={(e) => this.addDefaultBannerSrc(e)}
                    bannerImageLink1={this.state.bannerImageLink1}
                  />
                ) : (
                  ""
                )}

                {(SettingLandingPage?.category_show === true &&
                  isMobile === false) ||
                (SettingLandingPage?.mobile_category_show === true &&
                  isMobile === true) ? (
                  <CategoryHome
                    categoryLoader={this.state.categoryLoader}
                    CategoryDetails={CategoryDetails}
                    handleInnerOpenDialogHome={(
                      id,
                      name,
                      has_child,
                      level,
                      parent_id,
                      data
                    ) =>
                      this.handleInnerOpenDialogHome(
                        id,
                        name,
                        has_child,
                        level,
                        parent_id,
                        data
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {CategoryDetails && CategoryDetails.length > 0 ? (
                  <CategoriesModal
                    open={this.state.open}
                    handleOpenDialog={this.handleOpenDialog}
                    handleCloseDialog={this.handleCloseDialog}
                    CategoryDetails={CategoryDetails}
                    SubCategoryDetails={SubCategoryDetails}
                    history={this.props}
                    pageName={this.state.pageName}
                    categoryLoader={this.state.categoryLoader}
                    FetchSubCategoryList={(id, level, parent_id) =>
                      this.FetchSubCategoryList(id, level, parent_id)
                    }
                    parentIds={this.state.parentIds}
                    parent_onlyid={this.state.parent_onlyid}
                    parent_level={this.state.parent_level}
                    SubSubCategoryDetails={this.state.SubSubCategoryDetails}
                    subCategoryLoader={this.state.subCategoryLoader}
                    tree_data={this.state.tree_data}
                  />
                ) : (
                  ""
                )}
                <SubCategoryModal
                  open_inner={this.state.open_inner}
                  SubcategoryName={this.state.SubcategoryName}
                  handleInnerCloseDialog={this.handleInnerCloseDialog}
                  handleCloseDialog={this.handleCloseDialog}
                  handleOpenDialog={this.handleOpenDialog}
                  SubCategoryDetails={this.state.SubCategoryDetails}
                  history={this.props.history}
                  pageName={this.state.pageName}
                  SubSubCategoryDetails={this.state.SubSubCategoryDetails}
                  subCategoryLoader={this.state.subCategoryLoader}
                  FetchSubCategoryList={(id, level) =>
                    this.FetchSubCategoryList(id, level)
                  }
                  parentIds={this.state.parentIds}
                  parent_onlyid={this.state.parent_onlyid}
                  parent_level={this.state.parent_level}
                  tree_data={this.state.tree_data}
                />

                {(this.state.SettingLandingPage.show_category_with_item ===
                  true &&
                  isMobile === false) ||
                (this.state.SettingLandingPage
                  .mobile_show_category_with_item === true &&
                  isMobile === true) ? (
                  this.renderCategoryWiseProduct()
                ) : (
                  <></>
                )}
                {(SettingLandingPage.category_offer_show === true &&
                  isMobile === false) ||
                (SettingLandingPage.mobile_category_offer_show === true &&
                  isMobile === true) ? (
                  <section className="discount-offer">
                    <div className="header-cnt header-color">
                      <div className="header-details ">
                        <h4>Upto 50% - 60% Off</h4>
                      </div>
                      <div className="show_all">
                        <TypographyTextColor
                          to="/listing"
                          component={Link}
                          Color="other"
                        >
                          See All
                        </TypographyTextColor>
                      </div>
                    </div>
                    <div className="discount-section">
                      {CategoryManagementList &&
                      CategoryManagementList.length > 0
                        ? CategoryManagementList.map((row, index) => {
                            return (
                              <div className="discount-items">
                                <div className="discount-products"></div>
                                <div className="discound-details">
                                  <p>{row.name}</p>
                                  <span>{row.message}</span>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </section>
                ) : (
                  ""
                )}
                {((SettingLandingPage?.today_deals_show === true &&
                  isMobile === false) ||
                  (SettingLandingPage?.mobile_today_deals_show === true &&
                    isMobile === true)) &&
                TodayDealsProductList?.length > 0 ? (
                  <CommonProductview
                    cardWidth={
                      window.screen.availWidth <= 1200
                        ? "calc(95%/4)"
                        : "calc(95%/8)"
                    }
                    viewTitle={"  Today's Deal"}
                    handleChangeRouteForDeal={() =>
                      this.handleChangeRouteForDeal("today_deals")
                    }
                    ProductLoader={this.state.todayDealLoder}
                    ProductList={TodayDealsProductList}
                    chack_is_case={this.state.chack_is_case}
                    extraCharges={this.state.extraCharges}
                    favoriteLevelData={favoriteLevelData}
                    removeFromfavouriteProduct={(favoriot_id, product_id) =>
                      this.removeFromfavouriteProduct(favoriot_id, product_id)
                    }
                    addFavoiteLevelFunctionlity={(value, product_id) =>
                      this.addFavoiteLevelFunctionlity(value, product_id)
                    }
                    history={this.props.history}
                    activePageName={this.props.location.pathname}
                    addFavoiteProductFunctionlity={(
                      favoritelabel_id,
                      id,
                      added_quantity,
                      added_quantity_is_case
                    ) =>
                      this.addFavoiteProductFunctionlity(
                        favoritelabel_id,
                        id,
                        added_quantity,
                        added_quantity_is_case
                      )
                    }
                    FetchFavoriteLevelList={(product_id) =>
                      this.FetchFavoriteLevelList(product_id)
                    }
                    HandelChangeTrueWishList={() =>
                      this.HandelChangeTrueWishList()
                    }
                    handlePopOpen={(id, slug, next, prev, index) =>
                      this.handlePopOpen(id, slug, next, prev, index)
                    }
                    openAddCount={(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      containertype
                    ) =>
                      this.openAddCount(
                        id,
                        added_quantity,
                        added_quantity_is_case,
                        containertype
                      )
                    }
                    disabled={this.state.disabled}
                    inputField={this.state.inputField}
                    changeInputBoxValue={() => this.changeInputBoxValue()}
                    ProductCaseFunctionlity={(id, event) =>
                      this.ProductCaseFunctionlity(id, event)
                    }
                    restrictByOnhand={this.state.restrictByOnhand}
                  />
                ) : (
                  ""
                )}
                {(SettingLandingPage?.offer_show === true &&
                  isMobile === false) ||
                (SettingLandingPage?.mobile_offer_show === true &&
                  isMobile === true) ? (
                  <OfferImage
                    SettingLandingPage={SettingLandingPage}
                    offerImgView={this.state.offerImgView}
                    offerImgLink={this.state.offerImgLink}
                  />
                ) : (
                  <></>
                )}

                {((SettingLandingPage?.popular_products_show === true &&
                  isMobile === false) ||
                  (SettingLandingPage?.mobile_popular_products_show === true &&
                    isMobile === true)) &&
                PopularProductList.length > 0 ? (
                  <CommonProductview
                    cardWidth={
                      window.screen.availWidth <= 1200
                        ? "calc(95%/4)"
                        : "calc(95%/8)"
                    }
                    viewTitle={"Popular Products"}
                    handleChangeRouteForDeal={() =>
                      this.handleChangeRouteForDeal("popular_products")
                    }
                    ProductLoader={this.state.propularProductLoader}
                    ProductList={PopularProductList}
                    chack_is_case={this.state.chack_is_case}
                    extraCharges={this.state.extraCharges}
                    favoriteLevelData={favoriteLevelData}
                    removeFromfavouriteProduct={(favoriot_id, product_id) =>
                      this.removeFromfavouriteProduct(favoriot_id, product_id)
                    }
                    addFavoiteLevelFunctionlity={(value, product_id) =>
                      this.addFavoiteLevelFunctionlity(value, product_id)
                    }
                    history={this.props.history}
                    activePageName={this.props.location.pathname}
                    addFavoiteProductFunctionlity={(
                      favoritelabel_id,
                      id,
                      added_quantity,
                      added_quantity_is_case
                    ) =>
                      this.addFavoiteProductFunctionlity(
                        favoritelabel_id,
                        id,
                        added_quantity,
                        added_quantity_is_case
                      )
                    }
                    FetchFavoriteLevelList={(product_id) =>
                      this.FetchFavoriteLevelList(product_id)
                    }
                    HandelChangeTrueWishList={() =>
                      this.HandelChangeTrueWishList()
                    }
                    handlePopOpen={(id, slug, next, prev, index) =>
                      this.handlePopOpen(id, slug, next, prev, index)
                    }
                    openAddCount={(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      containertype
                    ) =>
                      this.openAddCount(
                        id,
                        added_quantity,
                        added_quantity_is_case,
                        containertype
                      )
                    }
                    disabled={this.state.disabled}
                    inputField={this.state.inputField}
                    changeInputBoxValue={() => this.changeInputBoxValue()}
                    ProductCaseFunctionlity={(id, event) =>
                      this.ProductCaseFunctionlity(id, event)
                    }
                    restrictByOnhand={this.state.restrictByOnhand}
                  />
                ) : (
                  ""
                )}
                {((SettingLandingPage.best_selling_products_show === true &&
                  isMobile === false) ||
                  (SettingLandingPage.mobile_best_selling_products_show ===
                    true &&
                    isMobile === true)) &&
                BestSellingProducts.length > 0 ? (
                  <CommonProductview
                    cardWidth={
                      window.screen.availWidth <= 1200
                        ? "calc(95%/4)"
                        : "calc(95%/8)"
                    }
                    viewTitle={"Best Selling Products"}
                    handleChangeRouteForDeal={() =>
                      this.handleChangeRouteForDeal("best_selling_products")
                    }
                    ProductLoader={this.state.bestsellingProductLoader}
                    ProductList={BestSellingProducts}
                    chack_is_case={this.state.chack_is_case}
                    extraCharges={this.state.extraCharges}
                    favoriteLevelData={favoriteLevelData}
                    removeFromfavouriteProduct={(favoriot_id, product_id) =>
                      this.removeFromfavouriteProduct(favoriot_id, product_id)
                    }
                    addFavoiteLevelFunctionlity={(value, product_id) =>
                      this.addFavoiteLevelFunctionlity(value, product_id)
                    }
                    history={this.props.history}
                    activePageName={this.props.location.pathname}
                    addFavoiteProductFunctionlity={(
                      favoritelabel_id,
                      id,
                      added_quantity,
                      added_quantity_is_case
                    ) =>
                      this.addFavoiteProductFunctionlity(
                        favoritelabel_id,
                        id,
                        added_quantity,
                        added_quantity_is_case
                      )
                    }
                    FetchFavoriteLevelList={(product_id) =>
                      this.FetchFavoriteLevelList(product_id)
                    }
                    HandelChangeTrueWishList={() =>
                      this.HandelChangeTrueWishList()
                    }
                    handlePopOpen={(id, slug, next, prev, index) =>
                      this.handlePopOpen(id, slug, next, prev, index)
                    }
                    openAddCount={(
                      id,
                      added_quantity,
                      added_quantity_is_case,
                      containertype
                    ) =>
                      this.openAddCount(
                        id,
                        added_quantity,
                        added_quantity_is_case,
                        containertype
                      )
                    }
                    disabled={this.state.disabled}
                    inputField={this.state.inputField}
                    changeInputBoxValue={() => this.changeInputBoxValue()}
                    ProductCaseFunctionlity={(id, event) =>
                      this.ProductCaseFunctionlity(id, event)
                    }
                    restrictByOnhand={this.state.restrictByOnhand}
                  />
                ) : (
                  ""
                )}
                {ProductDetails && this.state.itemDetails && (
                  <ItemDetailsModal
                    open={this.state.itemDetails}
                    handleOpenDialog={this.handleOpenDialog}
                    handlePopClose={(
                      product_id,
                      added_quantity,
                      added_quantity_is_case,
                      is_case
                    ) =>
                      this.handlePopClose(
                        product_id,
                        added_quantity,
                        added_quantity_is_case,
                        is_case
                      )
                    }
                    ProductDetails={ProductDetails}
                    categoryNames={categoryNames}
                    prev_rec={this.state.prev_rec}
                    next_rec={this.state.next_rec}
                    preProductItem={(product_id, slug, pre_info, next_info) =>
                      this.preProductItem(product_id, slug, pre_info, next_info)
                    }
                    nextProductItem={(product_id, slug, pre_info, next_info) =>
                      this.nextProductItem(
                        product_id,
                        slug,
                        pre_info,
                        next_info
                      )
                    }
                    productDetailsLoader={this.state.productDetailsLoader}
                    history={this.props}
                    addQuentityForaddTocart={(
                      targetValue,
                      added_qty_is_case,
                      container_type
                    ) =>
                      this.addQuentityForaddTocart(
                        targetValue,
                        added_qty_is_case,
                        container_type
                      )
                    }
                    FetchProdutDetails={(
                      product_id,
                      slug,
                      next_info,
                      pre_info
                    ) =>
                      this.FetchProdutDetails(
                        product_id,
                        slug,
                        next_info,
                        pre_info
                      )
                    }
                    parentIds={this.state.parentIds}
                    parent_onlyid={this.state.parent_onlyid}
                    parent_level={this.state.parent_level}
                    RefreshApiToken={(refreshToken, myThis, callback) =>
                      RefreshApiToken(refreshToken, myThis, callback)
                    }
                    special_filter={this.state.special_filter}
                    special_filter_value={this.state.special_filter_value}
                    ProductCaseFunctionlityForItem={(added_qty_is_case) =>
                      this.ProductCaseFunctionlityForItem(added_qty_is_case)
                    }
                    chack_is_case={this.state.chack_is_case}
                    container={this.state.container}
                    containerLength={this.state.containerLength}
                    containerValue={this.state.containerValue}
                    pro_item_details_ind={this.state.pro_item_details_ind}
                    container_type={this.state.container_type}
                    changeConatinerType={(event, type, myContainer) =>
                      this.changeConatinerType(event, type, myContainer)
                    }
                    AddtoCartBtn={this.state.AddtoCartBtn}
                    changeInputBoxValue={() => this.changeInputBoxValue()}
                    inputField={this.state.inputField}
                    commonFunctionForContainer={(
                      added_qtyInd,
                      added_quantity,
                      added_quantity_is_case
                    ) =>
                      this.commonFunctionForContainer(
                        added_qtyInd,
                        added_quantity,
                        added_quantity_is_case
                      )
                    }
                    activePageName=""
                    hendleAddtoCartBtn={() => this.hendleAddtoCartBtn()}
                    CategoryDetails={CategoryDetails}
                    FetchCartCount={() => this.FetchCartItemListForHeader()}
                    extraCharges={this.state.extraCharges}
                    openisslotDeliverypopupState_compwill={() =>
                      this.openisslotDeliverypopupState_compwill()
                    }
                  />
                )}
                {(SettingLandingPage.brand_show === true &&
                  isMobile === false) ||
                (SettingLandingPage.mobile_brand_show === true &&
                  isMobile === true) ? (
                  <section className="brand-content">
                    <div className="header-cnt">
                      <div className="header-details">
                        {/* {React.createElement(Icons['FaRegUser'])} */}
                        <TypographyTextColor component={"h4"} Color="heading">
                          Shop By Brand
                        </TypographyTextColor>
                      </div>
                    </div>
                    <div className="brands-logo">
                      {this.state.BrandListPage !== 1 ? (
                        <div
                          style={{
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleBrandList("prev")}
                        >
                          <BiSolidLeftArrow
                            style={{
                              color: "#696969",
                              fontSize:
                                window.screen.availWidth < 900
                                  ? "18px"
                                  : "1.5rem",
                            }}
                          ></BiSolidLeftArrow>
                        </div>
                      ) : (
                        ""
                      )}
                      <div class="brand-home-container">
                        {BrandProduct && BrandProduct.length > 0
                          ? BrandProduct.map((row, index) => {
                              return (
                                <>
                                  {" "}
                                  <Zoom
                                    style={{
                                      transitionDelay: `${80 * (index + 1)}ms`,
                                    }}
                                    in={true}
                                  >
                                    <Tooltip
                                      title={row.name}
                                      arrow
                                      onClick={() =>
                                        this.handleChangeForShopByBrand(
                                          row.id,
                                          row.name
                                        )
                                      }
                                    >
                                      <img
                                        className="img-responsive"
                                        src={
                                          row.icon
                                            ? row.icon[0] === "/"
                                              ? ApiUrls.BASH_URL +
                                                "/media" +
                                                row.icon
                                              : row.icon
                                            : ""
                                        }
                                        onError={(e) => addDefaultImageSrc(e)}
                                        alt=""
                                      />
                                    </Tooltip>
                                  </Zoom>
                                </>
                              );
                            })
                          : ""}
                      </div>
                      {this.state.BrandListPage * 6 <
                      this.state.brandListTotal ? (
                        <div
                          style={{
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleBrandList("next")}
                        >
                          <BiSolidRightArrow
                            style={{
                              color: "#696969",
                              fontSize:
                                window.screen.availWidth < 900
                                  ? "18px"
                                  : "1.5rem",
                            }}
                          ></BiSolidRightArrow>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {this.state.success === false && this.state.error === false ? (
                  ""
                ) : (
                  <Commonsnackbar
                    success={this.state.success}
                    handleClose={this.handleClose}
                    error={this.state.error}
                  />
                )}

                <Dialog
                  open={this.state.orderCountSlag}
                  onClose={() => this.handleOrderCountModalClose("Cancel")}
                  aria-labelledby="form-dialog-title"
                  maxWidth="md"
                  className="popUp"
                >
                  <DialogContent className="p-0">
                    <div className="popup">
                      <div className="header px-2 py-2">
                        Alert message
                        <span className="float-right">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.handleOrderCountModalClose("Cancel")
                            }
                          >
                            <span class="icon-close"></span>
                          </a>
                        </span>
                      </div>
                      <div className="boxshadow rounded-0 py-3 px-3">
                        <div className="form-group mb-4"></div>

                        <h5 className="font-weight-bold">
                          {this.state.ordercount_message}
                        </h5>
                        <p></p>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      className="homepopbtncancel"
                      onClick={() => this.handleOrderCountModalClose("Cancel")}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>

                {SettingLandingPage?.popup_data?.length > 0 ? (
                  SettingLandingPage.popup_data.map((ele, i) => {
                    return ele.popup_notification_show ? (
                      <CommonAdvertisementDialoag
                        open={this.state[`setopen${i}`]}
                        handleCloseOpnDialog={() =>
                          this.handleAdvertiseModalClose(i)
                        }
                        Content={
                          <h6
                            className="font-weight-bold"
                            dangerouslySetInnerHTML={{
                              __html: ele.popup_content,
                            }}
                          ></h6>
                        }
                      />
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <></>
                )}
                <div
                  style={{
                    paddingBottom: window.screen.availWidth < 900 ? "7rem" : "",
                  }}
                >
                  <Footer emailSubscribe={this.state.emailSubscribe} />
                </div>
              </ScrollColorTypography>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default NewHome;
