// import { Provider } from "react-redux";
// import { HashRouter } from "react-router-dom";
// import RouteComponents from "./routes/Routes";
// import { store } from "./store/store";
import React from "react";
import ReactDOM from "react-dom";
// import { createMuiTheme } from "@mui/material/styles";
import * as serviceWorker from "./serviceWorker";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/main.css";
import App from "./App";


ReactDOM.hydrate(<App />, document.getElementById("root"));

serviceWorker.unregister();
