import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import {
  favoriteProductListFunctionlity,
  AddFavoriteAddToCartFunctionlity,
  UpdateFavouriteProductQty,
  RemoveFavouriteProduct,
  RemoveFavouriteList,
} from "../actions/favorite/favoriteAction";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
  SubCategeriesListView,
} from "../actions/home/homeAction";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import {
  commonSelectedId,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  getSchedule_zip,
  sweetAlertBox,
} from "../utils/renderUtils/renderUtil";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RefreshLogin } from "../actions/login/loginAction";
import SubCategoryModal from "./common/Category/SubCategoryModal";
import {
  Fab,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";
import { GetProfileDetails } from "../actions/profile/profileAction";
import CustomPreloader from "./common/Loader/CustomPreloader";
import {
  ScrollColorTypography,
  SecondaryButton,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographySecondaryBgColor,
  TypographyTextColor,
} from "./common/UiDesign";
import Commonsnackbar from "./common/commonsnackbar";
class FavoriteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: "shoppingPage",
      isLogin: false,
      openCategory: false,
      CategoryDetails: [],
      CategoryOption: [],
      cartLoader: true,
      mobileViewHide: true,
      ShoppingCartCount: [],
      favouriteProductDetails: [],
      selected: [],
      selectedIds: [],
      favouriteLevel_id: 0,
      successMsg: false,
      errorMsg: false,
      shoppingCartItemList: [],
      SubTotal: 0,
      Total: 0,
      openDialog: false,
      headerinputField: false,
      modalSuccessMsg: "",
      bodyInnerClass: false,
      logo: "",
      profileDetails: [],
      showHideAddtoCart: false,
      parentIds: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      MainLoader: true,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
     
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleInnerCloseDialog = this.handleInnerCloseDialog.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleOpenDialog() {
    this.setState({ open: true });
  }
  handleCloseDialog() {
    this.setState({ open: false });
  }

  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }

  FetchProfileDetails = () => {
    let formValues = {};
    let profileDetails = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profileDetails = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetails();
            }
          );
        }
      }
      this.setState({ profileDetails: profileDetails });
    });
  };

  componentDidMount() {
    //;
    window.scrollTo(0, 0);
    if (window.innerWidth > 900) document.body.style.overflow = "hidden";
    let favouriteLevel_id = 0;
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    if (this.props && this.props.location && this.props.location.state) {
      favouriteLevel_id = this.props.location.state.favourite_id;
    }
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    if (accessToken && isLogin === "true") {
      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchfavouriteProductList(favouriteLevel_id);
      this.FetchProfileDetails();
      this.ExtraChargesFunctiolity();
    }
    this.setState({ favouriteLevel_id: favouriteLevel_id, logo: logo });
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };


  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };

  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    // this.HitGuestApiToken();

    this.props.history.push({
      pathname: "/",
    });
  };

  FetchfavouriteProductList = (favouriteLevel_id) => {
    let formValues = {
      favoritelabel_id: favouriteLevel_id,
    };
    favoriteProductListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favouriteProductDetails = [];
        let Total = 0;
        if (res.data && res.data.result) {
          favouriteProductDetails = res.data.result;
          Total = Number(res.data.total).toFixed(2);
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchfavouriteProductList(formValues.favoritelabel_id);
              }
            );
          }
        } else {
          this.props.history.go(-1);
        }
        let selectedIds = [];
        for (let i = 0; i < favouriteProductDetails.length; i++) {
          if (favouriteProductDetails[i].out_of_stock === true) {
            selectedIds.push(favouriteProductDetails[i].id);
          }
          // else if(favouriteProductDetails[i].out_of_stock_case === true && favouriteProductDetails[i].is_case === true){
          //   selectedIds.push(favouriteProductDetails[i].id);
          // }
          else {
            selectedIds = [];
          }
        }
        this.setState({
          favouriteProductDetails: favouriteProductDetails,
          selectedIds,
          favouriteLevel_id: favouriteLevel_id,
          Total,
          MainLoader: false,
        });
      }
    );
  };

  selectAll = (e) => {
    let selected = []; //this.state.selectedIds;
    let showHideAddtoCart = this.state.showHideAddtoCart;
    const tempData = this.state.favouriteProductDetails.map((el) => ({
      ...el,
      checked: e,
    }));

    for (let i = 0; i < tempData.length; i++) {
      if (
        this.state.extraCharges.is_show_out_of_stock === true &&
        tempData[i].out_of_stock === true
      ) {
        // selected = [];
      } else {
        selected.push(tempData[i].id);
      }
    }
    if (e === false) {
      selected = [];
    }
    let OSitem = 0;
    let item = 0;
    this.state.favouriteProductDetails.forEach((element) => {
      if (element.out_of_stock && this.state.extraCharges.is_show_out_of_stock)
        OSitem += 1;
      else item += 1;
    });
    if (selected && selected.length === item) {
      showHideAddtoCart = true;
    } else {
      showHideAddtoCart = false;
    }
    this.setState({
      favouriteProductDetails: tempData,
      selected: selected,
      selectedIds: selected,
      showHideAddtoCart,
    });
  };

  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  FetchCategoryList = () => {
    this.setState({
      categoryLoader: true,
    });
    let formValues = getSchedule_zip();
    CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({
        CategoryDetails: categoryList,
        categoryLoader: false,
        CategoryOption,
      });
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox('Attention!!',msg.quantity[0],"info")
        // this.setState({
        //   error: msg.quantity[0],
        //   successMsg: false,
        // });
      } else {
        sweetAlertBox('Error!!',msg,"error")
        // this.setState({
        //   errorMsg: msg,
        //   successMsg: false,
        // });
      }
    } else {
      sweetAlertBox('Success!!',msg,"success",2000,false)
      this.setState({
        // errorMsg: false,
        // successMsg: msg,
        selected: [],
        openDialog: false,
      });
    }
  };

  FetchCartItemList = () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let ShoppingCartCount = [];
      let shoppingCartItemList = [];
      if (res.data && res.data.result.length > 0) {
        const result = res.data.result;
        for (let i = 0; i < result.length; i++) {
          shoppingCartItemList.push(result[i]);
          SubTotal += Number(result[i].total_price);
        }
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }
      if (res.data && res.data.total > 0) {
        ShoppingCartCount.item = res.data.total;
        ShoppingCartCount.price = Number(res.data.total_subtotal).toFixed(2);
      }
      this.setState({
        cartListOpen: true,
        CartCount: ShoppingCartCount,
        cartLoader: false,
        shoppingCartItemList,
        SubTotal,
      });
    });
  };

  addDefaultCategorySrc(ev) {
    ev.target.src = NO_IMAGE;
  }

  redirectTofavourite = () => {
    this.props.history.push("/favouritelist");
  };

  updateQtyFavouriteList = (favouriteDetails_id, quantity, pretotal) => {
    let formValues = {
      id: favouriteDetails_id,
      quantity: quantity,
    };
    let favouriteProductDetails = this.state.favouriteProductDetails;
    let Total = this.state.Total;
    UpdateFavouriteProductQty(formValues, this.props.dispatch).then((res) => {
      if (res) {
        for (let i = 0; i < favouriteProductDetails.length; i++) {
          if (favouriteProductDetails[i].id === favouriteDetails_id) {
            favouriteProductDetails[i].total = res.subtotal;
          }
          Total = this.state.Total - pretotal + res.subtotal;
        }
      }
      this.setState({
        favouriteProductDetails: favouriteProductDetails,
        Total,
      });
    });
  };

  countFavouriteItemDecrease = (index) => {
    let favouriteProductDetails = this.state.favouriteProductDetails;
    for (let i = 0; i < favouriteProductDetails.length; i++) {
      if (favouriteProductDetails[i].id === index) {
        if (favouriteProductDetails[i].qty > 0) {
          favouriteProductDetails[i].qty = favouriteProductDetails[i].qty - 1;
          this.updateQtyFavouriteList(
            index,
            favouriteProductDetails[i].qty,
            favouriteProductDetails[i].total
          );
        }
        break;
      }
    }
    this.setState({
      favouriteProductDetails,
    });
  };

  countFavouriteItemIncrease = (index) => {
    let favouriteProductDetails = this.state.favouriteProductDetails;
    for (let i = 0; i < favouriteProductDetails.length; i++) {
      if (favouriteProductDetails[i].id === index) {
        favouriteProductDetails[i].qty = favouriteProductDetails[i].qty + 1;
        if (favouriteProductDetails[i].qty > 0) {
          this.updateQtyFavouriteList(
            index,
            favouriteProductDetails[i].qty,
            favouriteProductDetails[i].total
          );
        }
        break;
      }
    }
    this.setState({
      favouriteProductDetails,
    });
  };

  inputQtyChange = (value, index) => {
    let favouriteProductDetails = this.state.favouriteProductDetails;
    for (let i = 0; i < favouriteProductDetails.length; i++) {
      if (favouriteProductDetails[i].id === index) {
        favouriteProductDetails[i].qty = value;
        if (favouriteProductDetails[i].qty > 0) {
          this.updateQtyFavouriteList(
            index,
            favouriteProductDetails[i].qty,
            favouriteProductDetails[i].total
          );
        }
      }
    }
    this.setState({
      favouriteProductDetails,
    });
  };

  handleClick = (id, out_of_stock, out_of_stock_case, is_case) => {
    console.log("hitt");
    const { selected } = this.state;
    let showHideAddtoCart = this.state.showHideAddtoCart;
    let favouriteProductDetails = this.state.favouriteProductDetails;
    let newSelected = [];
    const selectedIndex = selected.indexOf(id);
    let resultSelected;
    if (
      this.state.extraCharges.is_show_out_of_stock === true &&
      out_of_stock === true
    ) {
      resultSelected = [];
    }
    //  else if(out_of_stock_case === true && is_case === false){
    //   resultSelected = '';
    // }
    else {
      resultSelected = commonSelectedId(
        newSelected,
        selectedIndex,
        selected,
        id
      );
    }
    var filtered = resultSelected.filter(function (el) {
      return el != "";
    });

    let checkBrandID = favouriteProductDetails.findIndex((p) => p.id === id);
    favouriteProductDetails[checkBrandID]["checked"] =
      !favouriteProductDetails[checkBrandID]["checked"];
    
    if (filtered && filtered.length > 0) {
      if (favouriteProductDetails[checkBrandID]["checked"] === true) {
        showHideAddtoCart = true;
      }
    } else {
      showHideAddtoCart = false;
    }

    this.setState({
      selected: filtered,
      showHideAddtoCart,
      favouriteProductDetails: favouriteProductDetails,
    });
  };

  FavoiteAddToCartFunctionlity = (create_new_cart) => {
    let data = this.state.favouriteProductDetails.map((x) => {
      if (this.state.selected.includes(x.id))
        return x?.added_quantity_is_case ? "true" : "false";
    });
    let cleanArray = data.filter((x) => x !== undefined);
    let formValues = {
      favoritelabel_id: this.state.favouriteLevel_id,
      create_new_cart: create_new_cart,
      favorite_product_ids: this.state.selected.toString(),
      favorite_product_is_cases: cleanArray.join(","),
    };
    let status = false;
    this.state.favouriteProductDetails.forEach((element) => {
      if (this.state.selected.includes(element.id)) {
        if (element.qty === 0) {
          status = true;
        }
      }
    });
    if (status) {
      this.createSuccessMsg(
        "error",
        "Select item should have more than 1 quantity"
      );
    } else if (this.state.selected && this.state.selected.length > 0) {
      AddFavoriteAddToCartFunctionlity(formValues, this.props.dispatch).then(
        (res) => {
          if (res && res.message) {
            // this.setState({openDialog : true, modalSuccessMsg: res.message})
            // this.createSuccessMsg('success', res.message)
            this.FetchfavouriteProductList(this.state.favouriteLevel_id);
            this.FetchCartItemList();
            this.setState({ selected: [] });
          } else {
            this.setState({ openDialog: false });
            this.createSuccessMsg("error", "Network issue");
          }
        }
      );
    } else {
      this.createSuccessMsg("error", "Please select at least one Item");
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      errorMsg: false,
      successMsg: false,
    });
  };

  UpdateCartItemFunctionlity = (product_id, added_quantity, is_case) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
    };
    let shoppingCartItemList = this.state.shoppingCartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < shoppingCartItemList.length; i++) {
          if (shoppingCartItemList[i].product_id === product_id) {
            shoppingCartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(shoppingCartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({
        disabled: false,
        shoppingCartItemList,
        SubTotal: SubTotal,
      });
    });
  };

  deleteCartItem = (
    product_id,
    added_quantity,
    is_case,
    index,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    let shoppingCartItemList = this.state.shoppingCartItemList;

    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        for (let i = 0; i < shoppingCartItemList.length; i++) {
          if (shoppingCartItemList[i].id === index) {
            shoppingCartItemList[i].total_price = res.subtotal;
            shoppingCartItemList[i].qty = res.quantity;
            shoppingCartItemList.splice(i, 1);
          }
        }
        if (isNaN(added_quantity)) {
          added_quantity = 0;
        }
        this.FetchCartItemList();
        this.setState({
          shoppingCartItemList: shoppingCartItemList,
        });
      }
    });
  };

  AddCountForShoppingcartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let shoppingCartItemList = this.state.shoppingCartItemList;

    if (icons === "icon-plus") {
      for (let i = 0; i < shoppingCartItemList.length; i++) {
        if (shoppingCartItemList[i].id === index) {
          shoppingCartItemList[i].qty = shoppingCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            shoppingCartItemList[i].qty,
            shoppingCartItemList[i].is_case,
            shoppingCartItemList[i].note,
            shoppingCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < shoppingCartItemList.length; i++) {
        if (shoppingCartItemList[i].id === index) {
          shoppingCartItemList[i].qty = shoppingCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              shoppingCartItemList[i].qty,
              shoppingCartItemList[i].is_case,
              shoppingCartItemList[i].note,
              shoppingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              shoppingCartItemList[i].qty,
              added_qty_is_case,
              index,
              shoppingCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < shoppingCartItemList.length; i++) {
        if (shoppingCartItemList[i].id === index) {
          shoppingCartItemList[i].qty = added_quantity
            ? added_quantity
            : shoppingCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              shoppingCartItemList[i].qty,
              shoppingCartItemList[i].is_case,
              shoppingCartItemList[i].note,
              shoppingCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              shoppingCartItemList[i].qty,
              added_qty_is_case,
              index,
              shoppingCartItemList[i].container_type
            );
          }
        }
      }
    }
    this.setState({ shoppingCartItemList: shoppingCartItemList });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };
  removeFromfavouriteProduct = (favoriteproduct_id) => {
    let formValues = {
      favoriteproduct_id: favoriteproduct_id.toString(),
    };
    RemoveFavouriteProduct(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.message) {
        this.createSuccessMsg("success", res.data.message);
        this.FetchCartItemList();
        this.FetchfavouriteProductList(this.props.location.state.favourite_id);
      }
      this.setState({ errorMsg: true });
    });
  };

  removeItemAlert = (favoriteproduct_id, type) => {
    if (type === "delete") {
      this.setState({ openDialog: true, dialogType: "delete" });
    } else {
      if (favoriteproduct_id && favoriteproduct_id.length > 0) {
        this.setState({ openDialog: true, dialogType: "remove" });
      } else {
        this.createSuccessMsg("error", "Please select at least one Item");
        this.setState({ openDialog: false });
      }
    }
  };

  handleCloseOpnDialog = () => {
    this.setState({ openDialog: false, modalSuccessMsg: "" });
  };

  redirectToDetailsPageAfterClick = (
    product_name,
    slug,
    product_id,
    addTocart_btn
  ) => {
    this.props.history.push({
      pathname: "/product/" + slug,
      state: {
        addTocart_btn: addTocart_btn,
      },
    });
  };

  SetBodyClass = (value) => {
    this.setState({ bodyInnerClass: value });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parentIds.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parentIds,
      category_id,
      level
    );
    this.setState({
      parentIds: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(formValues.ids, level);
            }
          );
        }
      }
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parentIds: this.state.parentIds,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialog() {
    this.setState({ open_inner: false });
  }
  deleteFavourite = () => {
    this.removeFromfavouriteList(this.props.location.state.favourite_id);
    this.setState({ openDialog: false });
  };
  removeFromfavouriteList = (favoritelabel_id) => {
    let formValues = {
      favoritelabel_id: favoritelabel_id,
    };
    RemoveFavouriteList(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.message) {
        this.createSuccessMsg("success", res.data.message);
        this.redirectTofavourite();
      }
    });
  };
  ProductCaseFunctionlity = (id, is_case, index) => {
    let favouriteProductDetails = this.state.favouriteProductDetails;
    favouriteProductDetails[index].added_quantity_is_case = is_case;
    this.setState({ favouriteProductDetails });
  };
  render() {
    const {
      CategoryDetails,
      shoppingCartItemList,
      SubTotal,
      CartCount,
      CategoryOption,
      favouriteProductDetails,
      web_customize
    } = this.state;
    return (
      <>
        <div
          className="main_containerLoader"
          style={{ display: this.state.MainLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>
        <ScrollColorTypography component="div" className="main-content">
          <Header
             CategoryDetails={CategoryDetails}
            CategoryOption={CategoryOption}
            cartItemList={shoppingCartItemList}
            SubTotal={SubTotal}
            handleOpenDialog={this.handleOpenDialog}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            cartLoader={this.state.cartLoader}
            FetchCartCount={() => this.FetchCartItemList()}
            AddCountForcartList={(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            ) =>
              this.AddCountForShoppingcartList(
                product_id,
                added_quantity,
                is_case,
                icons,
                index,
                container_type
              )
            }
            changeheaderinputFieldValue={() =>
              this.changeheaderinputFieldValue()
            }
            headerinputField={this.state.headerinputField}
            SetBodyClass={(value) => this.SetBodyClass(value)}
            logo={this.state.logo}
            extraCharges={this.state.extraCharges}
            handleInnerOpenDialog={(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            ) =>
              this.handleInnerOpenDialog(
                catId,
                subcategory_name,
                has_child,
                level,
                parent_id
              )
            }
          />
          <CategoriesModal
            open={this.state.open}
            handleOpenDialog={this.handleOpenDialog}
            handleCloseDialog={this.handleCloseDialog}
            CategoryDetails={CategoryDetails}
            history={this.props}
          />

          <SubCategoryModal
            open_inner={this.state.open_inner}
            SubcategoryName={this.state.SubcategoryName}
            // handleInnerOpenDialog={this.handleInnerOpenDialog}
            handleInnerCloseDialog={this.handleInnerCloseDialog}
            handleCloseDialog={this.handleCloseDialog}
            handleOpenDialog={this.handleOpenDialog}
            SubCategoryDetails={this.state.SubCategoryDetails}
            history={this.props.history}
            pageName={this.state.pageName}
            SubSubCategoryDetails={this.state.SubSubCategoryDetails}
            subCategoryLoader={this.state.subCategoryLoader}
            FetchSubCategoryList={(id, level) =>
              this.FetchSubCategoryList(id, level)
            }
            parentIds={this.state.parentIds}
            parent_onlyid={this.state.parent_onlyid}
            parent_level={this.state.parent_level}
          />
          <div className="main-data cart_data"  style={{paddingTop:web_customize !== null &&
                  web_customize?.top_marquee !== null &&
                  web_customize?.top_marquee !== ""&&   window.screen.availWidth > 900? "11rem":''}}
           >
            <section className="user_details user_order">
              <div className="user_main_wrapper">
                <UserDetailsMenu
                  history={this.props.history}
                  profileDetails={this.state.profileDetails}
                />

                <div
                  className={`shoppinglist-mob ${
                    this.state.bodyInnerClass === true
                      ? "row w-100 shoppingListPage show-slide"
                      : "row w-100"
                  }`}
                >
                  <div className="col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-6  d-flex align-items-center">
                        <TypographyTextColor
                          Color="heading"
                          component="h4"
                          className="mb-0 py-4"
                          style={{fontSize:window.innerWidth<=900?"14px":""}}
                        >
                          {" "}
                          <ArrowBackIosIcon
                            style={{ cursor: "pointer" ,fontSize:window.innerWidth<=900?"14px":""}}
                            onClick={() => this.redirectTofavourite()}
                          />{" "}
                          {this.props.location && this.props.location.state
                            ? this.props.location.state.favourite_name
                            : ""}{" "}
                          {/* Total: ${Number(this.state.Total).toFixed(2)} */}
                        </TypographyTextColor>
                      </div>

                      <div className="col-6">
                        {this.state.showHideAddtoCart ? (
                          <div className="d-flex align-items-center justify-content-end py-3 shop-btn">
                            <SecondaryButton
                              variant="contained"
                              onClick={() =>
                                this.FavoiteAddToCartFunctionlity(false)
                              }
                              className="text-white mx-2"
                              Fonttype="other"
                            >
                              Add to Cart
                            </SecondaryButton>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="tbl-main shoplist">
                      <div className="table-box lx">
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <FormControlLabel
                                  color="primary"
                                  control={
                                    <Checkbox
                                      name="checkedC"
                                      checked={favouriteProductDetails.every(
                                        (el) => el.checked
                                      )}
                                      onChange={(e) =>
                                        this.selectAll(e.target.checked)
                                      }
                                    />
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {" "}
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Quantity
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Name
                                </TypographyTextColor>
                              </TableCell>

                              <TableCell>
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Price
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Total
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Comments
                                </TypographyTextColor>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {favouriteProductDetails &&
                            favouriteProductDetails.length > 0
                              ? favouriteProductDetails.map((row, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell data-label="action">
                                        <FormControlLabel
                                          color="primary"
                                          control={
                                            <Checkbox
                                              name="checkedC"
                                              color="primary"
                                              checked={
                                                this.state.extraCharges
                                                  .is_show_out_of_stock ===
                                                  true &&
                                                row.out_of_stock === true
                                                  ? false
                                                  : "checked" in row
                                                  ? row.checked
                                                  : false
                                              }
                                              onChange={() =>
                                                this.handleClick(
                                                  row.id,
                                                  row.out_of_stock,
                                                  row.out_of_stock_case,
                                                  row.is_case
                                                )
                                              }
                                            />
                                          }
                                        />
                                      </TableCell>
                                      <TableCell data-label="Quantity">
                                        <div
                                          className="casecard-shoplist"
                                          style={{ alignItems: "baseline" }}
                                        >
                                          <TypographySecondaryBgColor className="count_box">
                                            <button
                                              class="count_btn"
                                              onClick={() =>
                                                this.countFavouriteItemDecrease(
                                                  row.id
                                                )
                                              }
                                            >
                                              <span class="icon-minus"></span>
                                            </button>
                                            <div class="count_no">
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) =>
                                                  this.inputQtyChange(
                                                    e.target.value,
                                                    row.id
                                                  )
                                                }
                                                value={
                                                  row.qty > 0 ? row.qty : 0
                                                }
                                              ></input>
                                            </div>
                                            <button
                                              class="count_btn"
                                              onClick={() =>
                                                this.countFavouriteItemIncrease(
                                                  row.id
                                                )
                                              }
                                            >
                                              <span class="icon-plus"></span>
                                            </button>
                                          </TypographySecondaryBgColor>
                                          {/* {row.is_case === true ? (
                                            <div className="case">
                                              <span className="casetextincart">
                                                Case
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )} */}
                                          {row.product_is_case ? (
                                            <div className="ml-1">
                                              <TypographyPrimaryBgColorDimmed
                                                component={"div"}
                                                border={1}
                                                color="primary"
                                                role="button"
                                                className="button-addtocart button-plusminus case-box p-1 rounded"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  this.ProductCaseFunctionlity(
                                                    row.id,
                                                    !row?.added_quantity_is_case,
                                                    index
                                                  );
                                                }}
                                              >
                                                <TypographyFontColor
                                                  component={"span"}
                                                  border={0}
                                                  className="d-flex"
                                                >
                                                  <div className="case-line">
                                                    <input
                                                      type="checkbox"
                                                      className="case-checkbox"
                                                      checked={
                                                        row?.added_quantity_is_case ===
                                                        true
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                    Case of {row.case_quantity}
                                                  </div>
                                                </TypographyFontColor>
                                              </TypographyPrimaryBgColorDimmed>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell
                                        data-label="Name"
                                        className="tname"
                                        onClick={() =>
                                          this.redirectToDetailsPageAfterClick(
                                            row.product_name,
                                            row.slug,
                                            row.product_id
                                          )
                                        }
                                      >
                                        <div class="wh-list">
                                          <img
                                            onError={(e) =>
                                              this.addDefaultCategorySrc(e)
                                            }
                                            className="img-responsive mr-3"
                                            style={{ width: "50px" }}
                                            src={
                                              row.image ? row.image : NO_IMAGE
                                            }
                                            alt=""
                                            onClick={() =>
                                              this.redirectToDetailsPageAfterClick(
                                                row.product_name,
                                                row.slug,
                                                row.product_id
                                              )
                                            }
                                          />
                                          {this.state.extraCharges
                                            .is_show_out_of_stock === true &&
                                          row.out_of_stock === true ? (
                                            <div className="out-of-stock-user">
                                              <span className="text">
                                                Out Of Stock
                                              </span>
                                            </div>
                                          ) : this.state.extraCharges
                                              .is_show_out_of_stock === true &&
                                            row.out_of_stock_case === true &&
                                            row.is_case === true ? (
                                            <div className="out-of-stock-user">
                                              <span className="text">
                                                Out Of Stock
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <TypographyTextColor
                                            Color="product"
                                            component="div"
                                            className={
                                              this.state.extraCharges
                                                .is_show_out_of_stock ===
                                                true &&
                                              row.out_of_stock === true
                                                ? "out-stock-title"
                                                : ""
                                            }
                                          >
                                            {row.product_name}
                                          </TypographyTextColor>
                                        </div>
                                      </TableCell>

                                      <TableCell
                                        data-label="Price"
                                        style={{ width: "150px" }}
                                      >
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          $
                                          {row?.added_quantity_is_case === true
                                            ? Number(row.case_price).toFixed(2)
                                            : Number(row.unit_price).toFixed(2)}
                                        </TypographyTextColor>
                                      </TableCell>
                                      <TableCell
                                        data-label="Total"
                                        style={{ width: "150px" }}
                                      >
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {" "}
                                          $
                                          {row?.added_quantity_is_case === true
                                            ? Number(
                                                row.case_price * row.qty
                                              ).toFixed(2)
                                            : Number(row.total).toFixed(2)}
                                        </TypographyTextColor>
                                      </TableCell>
                                      <TableCell data-label="Comments">
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {" "}
                                          {row.note ? row.note : ""}
                                        </TypographyTextColor>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              : ""}
                          </TableBody>
                        </Table>
                      </div>
                      <div className="m-2 shop-btn">
                        <Button
                          className="bttn_grn"
                          variant="extended"
                          color="primary"
                          onClick={() => this.removeItemAlert("", "delete")}
                        >
                          <TypographyTextColor Color="other" component="span">
                            {" "}
                            Delete List
                          </TypographyTextColor>
                        </Button>
                        {this.state.showHideAddtoCart ? (
                          <Button
                            onClick={() =>
                              this.removeItemAlert(
                                this.state.selected,
                                "remove"
                              )
                            }
                            className="bttn_grn"
                            variant="extended"
                            color="primary"
                          >
                            <TypographyTextColor Color="other" component="span">
                              {" "}
                              Remove SELECTED
                            </TypographyTextColor>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.successMsg === false &&
              this.state.errorMsg === false ? (
                ""
              ) : (
                <Commonsnackbar
                  success={this.state.successMsg}
                  handleClose={this.handleClose}
                  error={this.state.errorMsg}
                />
              )}

              <Dialog
                open={this.state.openDialog}
                onClose={() => this.handleCloseOpnDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {this.state.modalSuccessMsg ? (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {this.state.modalSuccessMsg !== ""
                          ? this.state.modalSuccessMsg
                          : ""}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.handleCloseOpnDialog()}
                        color="primary"
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {this.state.dialogType === "remove"
                          ? "Are you sure want to remove this items?"
                          : "Do you want to delete this favoruite list?"}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.handleCloseOpnDialog()}
                        color="primary"
                      >
                        Disagree
                      </Button>
                      <Button
                        onClick={() => {
                          this.state.dialogType === "remove"
                            ? this.removeFromfavouriteProduct(
                                this.state.selected
                              )
                            : this.deleteFavourite();
                        }}
                        color="primary"
                        autoFocus
                      >
                        Agree
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </section>
            <Footer emailSubscribe={this.state.emailSubscribe} />
          </div>
        </ScrollColorTypography>
      </>
    );
  }
}

export default FavoriteList;
