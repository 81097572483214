import React from "react";
import CategoryCard from "../../HomePage/CategoryCard";
import { Breadcrumbs, CircularProgress, Fade, Link } from "@mui/material";
import { ScrollColorTypography, TypographyTextColor } from "../UiDesign";
import CategorycardNoimg from "../Category/CategorycardNoimg";

const DepartmentContent = (props) => {
  const {
    CategoryDetails,
    categoryLoader,
    department_breadcumbs,
    store_Data,
  } = props;
  return (
  
      <ScrollColorTypography component={"div"} className="deparment-results">
        <div className="search-body">
          {categoryLoader ? (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <CircularProgress
                style={{
                  display: "flex",
                  color: "#3d9b7b",
                  position: "relative",
                  margin: "0 auto",
                  "& > * + *": {
                    marginLeft: 2,
                  },
                }}
              />
            </div>
          ) : (
            <>
              {department_breadcumbs.length > 0 ? (
                <div className="ml-4 mt-2">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      role="button"
                      underline="hover"
                      color="inherit"
                      onClick={() => props.handleBreadcumbs(0, "")}
                    >
                      <TypographyTextColor component={"span"} Color="other">
                        {" "}
                        All
                      </TypographyTextColor>
                    </Link>
                    {department_breadcumbs.map((val, i) => {
                      return i === department_breadcumbs.length - 1 ? (
                        <TypographyTextColor component={"span"} Color="heading">
                          {val.name}
                        </TypographyTextColor>
                      ) : (
                        <Link
                          underline="hover"
                          color="inherit"
                          role="button"
                          onClick={() => props.handleBreadcumbs(1, val)}
                        >
                          <TypographyTextColor component={"span"} Color="other">
                            {" "}
                            {val.name}
                          </TypographyTextColor>
                        </Link>
                      );
                    })}
                  </Breadcrumbs>
                </div>
              ) : (
                ""
              )}
              <div
                className="product-category-container"
                style={{
                  // justifyContent:
                  //   props.department_type === 0 ? "center" : "left",
                  gap: "15px",
                }}
              >
                {" "}
                {CategoryDetails?.length > 0
                  ? CategoryDetails.map((row, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            props.FetchSubAndSubsubCategoryList(row)
                          }
                          className="product-category-item"
                        >
                          {row.level === 0 ||
                         (row.level !== 0&& store_Data?.is_sub_class_img === true )? (
                            <CategoryCard category={row} />
                          ) : (
                            <CategorycardNoimg name={row.name} />
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </>
          )}
        </div>
      </ScrollColorTypography>
    
  );
};

export default DepartmentContent;
