import axios from "axios";
import ApiUrls from "../../utils/apiUtils/apiUrl";
import {tokenFunctionlity} from "../../utils/renderUtils/renderUtil";
const NetworkMsg="Network error. Please try again."
export function AddFavoriteLevelFunctionlity(formValues, dispatch) {
    let configToken = tokenFunctionlity();
    const url = ApiUrls.FAVOURITE_URL+'favourite';
    return axios
      .post(url, formValues, configToken)
      .then((res) =>{
        return res.data;
      })
      .catch((error) =>{
        if (error.response) {
          return {
            errors: error.response,
          };
        } else {
          return {
            errors: {
              _error: NetworkMsg,
            },
          };
        }
    });
}
export function favoriteLevelListFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL + 
    'favourite?&user_id=0&login_status=true&lookup=' + 
    formValues.lookup+'&page='+
    formValues.page +'&limit='+
    formValues.limit+'&product_id='+
    formValues.product_id+'&order=-id';
  return axios
    .get(url, configToken)
    .then((res) =>{
      return res;
    })
    .catch((error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function AddFavoriteProductFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'favourite-product';
  return axios
    .post(url, formValues, configToken)
    .then( (res)=> {
      return res.data;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
  });
}
export function favoriteProductListFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL + 'favourite-product?favoritelabel_id=' + formValues.favoritelabel_id;
  return axios
    .get(url, configToken)
    .then((res) =>{
      return res;
    })
    .catch((error) =>{
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function AddFavoriteAddToCartFunctionlity(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'favourite-addtocart';
  return axios
    .post(url, formValues, configToken)
    .then((res) =>{
      return res.data;
    })
    .catch((error) =>{
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
  });
}
export function RemoveFavouriteList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'favourite?&favoritelabel_id=' + formValues.favoritelabel_id
  return axios
    .delete(url, configToken)
    .then((res) =>{
      return res;
    })
    .catch((error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function UpdateFavouriteProductQty(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'favourite-product';
  return axios
    .patch(url, formValues, configToken)
    .then( (res)=> {
      return res.data;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
  });
}
export function RemoveFavouriteProduct(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'favourite-product?favoriteproduct_id=' + formValues.favoriteproduct_id;
  return axios
    .delete(url, configToken)
    .then( (res) =>{
      return res;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: {
            _error: error.response,
          },
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}
export function SaveAddtoCartShoopingList(formValues, dispatch) {
  let configToken = tokenFunctionlity();
  const url = ApiUrls.FAVOURITE_URL+'addtocart-save';
  return axios
    .post(url, formValues, configToken)
    .then( (res) =>{
      return res.data;
    })
    .catch( (error)=> {
      if (error.response) {
        return {
          errors: error.response,
        };
      } else {
        return {
          errors: {
            _error: NetworkMsg,
          },
        };
      }
    });
}