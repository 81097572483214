import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TypographyTextColor, TypographyTextFontFamily } from "../../UiDesign";
import { Link } from "react-router-dom";
const FooterMobile = (props) => {
  const { footerDetails } = props;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => {
    setExpanded((prev) => (prev === panel ? false : panel));
  };

  return (
    <>
      {footerDetails && footerDetails.length > 0
        ? footerDetails.map(function (row, index) {
            return (
              <>
                {row.title !== "social_media" ? (
                  <Accordion
                    expanded={expanded === "panel" + (index + 1)}
                    onClick={() => {
                      handleChange("panel" + (index + 1));
                    }}
                    className="w-100"
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon style={{ color: "#3e3e3ee8" }} />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <TypographyTextColor
                            component="div"
                            Color="heading">
                        {row.title === "about_company"
                          ? "ABOUT COMPANY"
                          : row.title === "news_media"
                          ? "NEWS & MEDIA"
                          : row.title === "our_policies"
                          ? "OUR POLICIES"
                          : row.title === "gift_cards"
                          ? "GIFT CARDS"
                          : ""}
                      </TypographyTextColor>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className="footer-links">
                        {row.items && row.items.length > 0
                          ? row.items.map(function (item, key) {
                              return item.page_type === "external_page" ? (
                                <li>
                                   <Link to={`${item.url}`}>
                                            <TypographyTextFontFamily type="other" component={"span"} >
                                            {item.name}
                                            </TypographyTextFontFamily>
                                            </Link>
                                 
                                </li>
                              ) : (
                                <li>
                                    <Link to={`/page/${item.url}`}>
                                            <TypographyTextFontFamily type="other" component={"span"} >
                                            {item.name}
                                            </TypographyTextFontFamily>
                                            </Link>
                                 
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
              </>
            );
          })
        : ""}
      {/* <Accordion
        expanded={expanded === "panel" + 10}
        onClick={() => {
          handleChange("panel" + 10);
        }}
        className="w-100"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#3e3e3ee8" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div>Contact Information</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <li>
              <a style={{ color: "#878787" }}>
                Email Address :{" "}
                <span>
                  <a
                    href={
                      props.storeData ? "email: " + props.storeData.email : ""
                    }
                  >
                    {props.storeData ? props.storeData.email : ""}
                  </a>
                </span>
              </a>
            </li>

            <li>
              <a style={{ color: "#878787" }}>
                Telephone :{" "}
                <span>
                  <a
                    href={
                      props.storeData
                        ? "tel: +1 " + props.phoneFormat(props.storeData.phone)
                        : ""
                    }
                  >
                    {props.storeData
                      ? "+1 " + props.phoneFormat(props.storeData.phone)
                      : ""}
                  </a>
                </span>
              </a>
            </li>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
};

export default FooterMobile;
